import {
  LeadStatus,
  PlanCondition,
  TransactionStatus,
} from "../../graphql/types";
//styles
import { StatusContainer, useStylesStatus } from "../../styles/Statuts__styles";
interface StatusProps {
  status:
    | TransactionStatus
    | PlanCondition
    | LeadStatus
    | undefined
    | null
    | "INACTIVE";
  statusText: string | undefined | null;
}

function getStatusClass(status: string, StatusClasses: any) {
  if (
    status === "ACTIVE" ||
    status === "CREATED" ||
    status === "ACTIVATED" ||
    status === "WILL_BE_SWITCHED" ||
    status === "CONTACTED" ||
    status === "DELIVERED"
  ) {
    return StatusClasses.active_status;
  } else if (
    status === "FROZEN" ||
    status === "WILL_BE_FROZEN" ||
    status === "SWITCHED" ||
    status === "SCHEDULED_PAYMENT" ||
    status === "PAUSED" ||
    status === "INFORMATION_REQUIRED"
  ) {
    return StatusClasses.frozen_status;
  } else if (
    status === "UPCOMING" ||
    status === "UPCOMMING" ||
    status === "SUCCESS" ||
    status === "CONVERTED"
  ) {
    return StatusClasses.success_status;
  } else if (
    status === "EXPIRING_SOON" ||
    status === "CANCELED" ||
    status === "CANCLED" ||
    status === "WILL_BE_CANCELED" ||
    status === "WILL_BE_DESACTIVATED" ||
    status === "INACTIVE" ||
    status === "WAITING"
  ) {
    return StatusClasses.inactive_status;
  } else if (
    status === "FAILED" ||
    status === "UNCOMPLETED" ||
    status === "PENDING" ||
    status === "DELETED"
  ) {
    return StatusClasses.failed_status;
  } else if (
    status === "EXPIRED" ||
    status === "DESACTIVATED" ||
    status === "NOT_DELIVERED" ||
    status === "CLOSED"
  ) {
    return StatusClasses.finished_status;
  } else if (status === "PACKED") {
    return StatusClasses.green_status;
  } else {
    return "";
  }
}

function Status({ status, statusText }: StatusProps) {
  const StatusClasses = useStylesStatus();
  const statusClass = getStatusClass(status ?? "", StatusClasses);
  return (
    <StatusContainer className={statusClass}>
      <span>{statusText}</span>
    </StatusContainer>
  );
}

export default Status;
