function DeliveredIcon() {
  return (
    <svg
      width="25"
      height="34"
      viewBox="0 0 50 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.89 67.57H8.11C3.64 67.57 0 63.93 0 59.46V12.16H10.14V14.86H2.7V59.46C2.7 62.44 5.12 64.87 8.11 64.87H41.89C44.87 64.87 47.3 62.45 47.3 59.46V14.86H36.49V18.91H37.84V21.61H32.43V18.91H33.78V11.48C33.78 6.64 29.84 2.7 25 2.7C20.16 2.7 16.22 6.64 16.22 11.48V12.16H30.41V14.86H16.22V18.91H17.57V21.61H12.16V18.91H13.51V11.48C13.51 5.15 18.67 0 25 0C31.33 0 36.49 5.15 36.49 11.49V12.17H50V59.46C50 63.93 46.36 67.57 41.89 67.57ZM41.21 59.46H8.78V56.76H41.22V59.46H41.21Z"
        fill="#fff"
      />
    </svg>
  );
}

export default DeliveredIcon;
