import { Controller, useFormContext } from "react-hook-form";
//Material UI
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AutoCompleteProps } from "./AutoComplete.types";
//Types

function AutoComplete<T>(props: AutoCompleteProps<T>): JSX.Element {
  const {
    name,
    label,
    data,
    margin,
    fullWidth,
    rules,
    getOptionLabel,
    getOptionValue,
    sx,
    size,
    placeholder,
    multiple,
    defaultValue,
    disableClearable,
    onFocus,
    onChangeExtra,
    loading,
    disabled,
  } = props;
  const { control } = useFormContext();
  //render
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, value, ...rest },
        fieldState: { error },
      }) => (
        <Autocomplete
          disabled={disabled}
          disableClearable={disableClearable}
          multiple={multiple ?? false}
          isOptionEqualToValue={(option, value) =>
            getOptionValue(option, value)
          }
          style={{ margin, padding: 0 }}
          sx={sx}
          id={label}
          loading={loading}
          options={loading ? [] : data}
          value={value}
          defaultValue={defaultValue}
          getOptionLabel={getOptionLabel}
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: any
          ) => {
            onChange(value);
            onChangeExtra?.(value);
          }}
          onFocus={onFocus}
          ChipProps={{ color: "primary", style: { borderRadius: 4 } }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth={fullWidth ?? true}
              label={label}
              multiline
              maxRows={2}
              minRows={2}
              variant="outlined"
              placeholder={placeholder}
              helperText={error?.message}
              error={error?.message !== undefined}
              size={size ?? "medium"}
            />
          )}
        />
      )}
    />
  );
}
export { AutoComplete };
