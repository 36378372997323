import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import moment from "moment";
// React Hook Form
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  useWatch,
} from "react-hook-form";
// Material-UI components
import {
  Box,
  TextField,
  MenuItem,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
} from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
// Custom styles
import { useStylesLeads } from "../../../../styles/Leads__styles";
import {
  useStylesContainers,
  ContainerFullWidth,
  ContainerLeft,
  LeftElement,
  ContainersContent,
} from "../../../../styles/Containers__styles";
// Custom components
import PageAddHeader from "../../../pageAddHeader/pageAddHeader";
import PhoneInput from "../../../layout/PhoneInput";
import TabContentHeader from "../../../TabContentHeader/TabContentHeader";
import AddressInputForTranferBox from "./AddressInputForTranferBox";
//utils
import { countries } from "../../../../staticData/staticData";
import {
  goalsOptions,
  weightOptions,
  heightOptions,
  calculateRecommendedCalories,
  ActivityOptions,
} from "../../Utils";
// GraphQL types and queries
import { customersProps } from "../../Types";
import {
  Weight,
  Height,
  Gender,
  AdressType,
  WeightInput,
  HeightInput,
  CreateCustomerWithoutPlanFromDashboard,
} from "../../../../graphql/types";
// Custom hooks
import { useCountryPrefix } from "../../../../utils/Hooks/useCountryPrefix";

interface CreateNewCustomerModalProps {
  newCustomerData: (data: CreateCustomerWithoutPlanFromDashboard) => void;
  onCancelNewCustomerModal: () => void;
  newCustomerDetails: CreateCustomerWithoutPlanFromDashboard | undefined;
}

const CreateNewCustomerModal: React.FC<CreateNewCustomerModalProps> = ({
  newCustomerData,
  onCancelNewCustomerModal,
  newCustomerDetails,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const ContainersClasses = useStylesContainers();
  const LeadsClasses = useStylesLeads();
  const countryPrefix = useCountryPrefix();

  const methods = useForm<customersProps>({
    defaultValues: {
      first__name: newCustomerDetails?.fname || "",
      last__name: newCustomerDetails?.lname || "",
      email: newCustomerDetails?.email || "",
      phone__number: newCustomerDetails?.phone.number || "",
      phone__prefix: countryPrefix?.callingCode,
      nationality: newCustomerDetails?.nationality || "",
      gender: newCustomerDetails?.gender || null,
      birth__date: newCustomerDetails?.dob || null,
      weight: Number(newCustomerDetails?.weight) || 0,
      weight__unit: "KG",
      height: Number(newCustomerDetails?.height) || 0,
      height__unit: "CM",
      influencer: newCustomerDetails?.influencer || false,
      activity: null,
      protein: 0,
      carbs: 0,
      fat: 0,
      addresses: [
        {
          address__name: "",
          area__name: "",
          building__name: "",
          street__name: "",
          aprt__number: "",
          floor__number: "",
          address__type: "",
          address__kind: [],
          delivery__number: "",
          delivery__prefix: countryPrefix?.callingCode,
          delivery__days: [
            {
              value: "monday",
              label: "Monday",
            },
            {
              value: "tuesday",
              label: "Tuesday",
            },
            {
              value: "wednesday",
              label: "Wednesday",
            },
            {
              value: "thursday",
              label: "Thursday",
            },
            {
              value: "Friday",
              label: "Friday",
            },
            {
              value: "saturday",
              label: "Saturday",
            },
            {
              value: "sunday",
              label: "Sunday",
            },
          ],
          zone: "",
          delivery__time: null,
        },
      ],
      address__name: "",
      area__name: "",
      building__name: "",
      street__name: "",
      aprt__number: "",
      floor__number: "",
      address__type: [],
      address__kind: [],
      delivery__number: "",
      delivery__prefix: countryPrefix?.callingCode,
      preferences: [],
      goals: null,
      quantity: "0",
      quantity__unit: "KG",
      is__free: false,
      program__name: null,
      plan__type: null,
      meals__duration: [],
      dayPerWeek: null,
      meals: [],
      start__date: null,
      paymentMethod: null,
      recommended__calories: "",
      promoCode: "",
      daysOff: [],
      isCustomPackage: false,
      customPackagesQuantities: {
        breakfast: 0,
        lunchDinner: 0,
        snack: 0,
      },
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  const ActivityWatch = useWatch({
    control,
    name: "activity",
  });

  const onSubmit: SubmitHandler<customersProps> =  (
    data: customersProps
  ) => {
    const phone = data?.phone__number?.replace(/\s/g, "") ?? "";
    const createCustomerInput: CreateCustomerWithoutPlanFromDashboard = {
      fname: data?.first__name,
      lname: data?.last__name,
      email: data?.email,
      dob: new Date(data?.birth__date),
      gender: data?.gender as Gender,
      calories: data?.recommended__calories,
      height: {
        unit: data?.height__unit,
        value: Number(data?.height),
      },
      weight: {
        unit: data?.weight__unit,
        value: Number(data?.weight),
      },
      phone: {
        prefix: `+${data.phone__prefix}`,
        number: phone,
      },
      influencer: data?.influencer,
      nationality: data?.nationality,
      addresses: {
        nick: data.addresses[0].address__name,
        build: data.addresses[0]?.building__name,
        nbrApt: data.addresses[0]?.aprt__number,
        street: data.addresses[0]?.street__name,
        area: data.addresses[0]?.area__name,
        floor: data.addresses[0]?.floor__number,
        type: data.addresses[0]?.address__type as AdressType,
        daysOfTheWeek: [],
        deliveryPhone: {
          prefix: `+${data.addresses[0]?.delivery__prefix}`,
          number: data.addresses[0]?.delivery__number.replace(/\s/g, ""),
        },
        zone: data.addresses[0]?.zone,
        deliveryTime: data.addresses[0]?.delivery__time,
      },
      goal: data.goals,
    };
     newCustomerData(createCustomerInput);
    enqueueSnackbar("Customer details saved", {
      variant: "success",
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
    });
    setTimeout(() => closeSnackbar(), 3000);
    onCancelNewCustomerModal();
  };

  useEffect(() => {
    if (
      dataWatch.gender &&
      dataWatch.activity &&
      dataWatch.weight &&
      dataWatch.weight__unit &&
      dataWatch.height &&
      dataWatch.height__unit &&
      dataWatch.birth__date &&
      dataWatch.goals &&
      dataWatch.program__name
    ) {
      const { calories, carb, fat, prot } = calculateRecommendedCalories(
        dataWatch.gender,
        {
          unit: dataWatch.weight__unit,
          value: dataWatch.weight,
        } as WeightInput,
        {
          unit: dataWatch.height__unit,
          value: dataWatch.height,
        } as HeightInput,
        moment().diff(moment(dataWatch.birth__date), "years"),
        dataWatch.activity.menuValue,
        dataWatch.program__name?.name?.EN || "",
        dataWatch.goals
      );

      setValue("protein", +prot);
      setValue("carbs", +carb);
      setValue("fat", +fat);
      setValue("recommended__calories", calories);
    }
  }, [
    dataWatch.gender,
    dataWatch.activity,
    dataWatch.weight,
    dataWatch.weight__unit,
    dataWatch.height,
    dataWatch.height__unit,
    dataWatch.birth__date,
    dataWatch.goals,
    dataWatch.program__name,
  ]);

  return (
    <FormProvider {...methods}>
      <TabContentHeader
        buttonText="Cancel"
        buttonFnc={onCancelNewCustomerModal}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader title="Create Customer" buttonText="Create Customer" />
        <ContainersContent>
          <ContainerFullWidth>
            <FormProvider {...methods}>
              <ContainerLeft>
                {/* Personal Informations */}
                <LeftElement>
                  <p className={clsx(ContainersClasses.section__title)}>
                    Personal Informations
                  </p>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        First Name*
                      </p>
                      <TextField
                        placeholder="First Name"
                        sx={{ width: "100%" }}
                        {...register("first__name", {
                          required: "Enter Customer First Name",
                          minLength: {
                            value: 2,
                            message: "Please enter a least 2 characters",
                          },
                        })}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.first__name?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Last Name*
                      </p>
                      <TextField
                        placeholder="Last Name"
                        sx={{ width: "100%" }}
                        {...register("last__name", {
                          required: "Enter Customer Last Name",
                          minLength: {
                            value: 2,
                            message: "Please enter a least 2 characters",
                          },
                        })}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.last__name?.message}
                      </p>
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Email
                      </p>
                      <TextField
                        placeholder="Email"
                        sx={{ width: "100%" }}
                        {...register("email", {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Enter a valid Email",
                          },
                          required: "Email is Mandatory",
                        })}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.email?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Phone Number*
                      </p>
                      <PhoneInput
                        labelFormPrefix="phone__prefix"
                        labelFormNumber="phone__number"
                        placeholder="Phone Number"
                      />
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Nationality
                      </p>
                      <Controller
                        name="nationality"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            {...rest}
                            freeSolo
                            options={countries}
                            value={dataWatch.nationality}
                            onChange={(_, data) => Change(data)}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputRef={ref}
                                variant="outlined"
                                sx={{ width: "100%" }}
                                placeholder="Nationality"
                              />
                            )}
                          />
                        )}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.nationality?.message}
                      </p>
                    </Box>

                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Birth Date
                      </p>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <Controller
                          name="birth__date"
                          control={control}
                          rules={{
                            validate: (value) =>
                              value === null ||
                              moment(value).isValid() ||
                              "Enter a valid Birth Date",
                          }}
                          render={({ field: { ref, ...rest } }) => (
                            <DesktopDatePicker
                              {...rest}
                              inputFormat="DD/MM/YYYY"
                              disablePast={false}
                              disableFuture={true}
                              renderInput={(params) => (
                                <TextField {...params} sx={{ width: "100%" }} />
                              )}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.birth__date?.message}
                      </p>
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Weight
                      </p>

                      <TextField
                        placeholder="Weight"
                        sx={{ width: "70%" }}
                        type="number"
                        {...register("weight", {
                          pattern: {
                            value: /^\d*$/,
                            message: "Enter a valid Weight",
                          },
                        })}
                      />
                      <Controller
                        name="weight__unit"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "28%", marginLeft: "3px" }}
                            className={LeadsClasses.select_container}
                          >
                            {weightOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue(
                                    "weight__unit",
                                    option.menuValue as Weight
                                  )
                                }
                                value={option.menuValue}
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Height
                      </p>
                      <TextField
                        placeholder="Height"
                        sx={{ width: "70%" }}
                        type="number"
                        {...register("height", {
                          pattern: {
                            value: /^\d*$/,
                            message: "Enter a valid Height",
                          },
                        })}
                      />
                      <Controller
                        name="height__unit"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "28%", marginLeft: "3px" }}
                            className={LeadsClasses.select_container}
                          >
                            {heightOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue(
                                    "height__unit",
                                    option.menuValue as Height
                                  )
                                }
                                value={option.menuValue}
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Gender
                      </p>
                      <Controller
                        name="gender"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                        }) => (
                          <RadioGroup value={dataWatch?.gender} row>
                            <FormControlLabel
                              value="MALE"
                              control={<Radio />}
                              label="Male"
                              onClick={() => setValue("gender", "MALE")}
                            />
                            <FormControlLabel
                              value="FEMALE"
                              control={<Radio />}
                              label="Female"
                              onClick={() => setValue("gender", "FEMALE")}
                            />
                          </RadioGroup>
                        )}
                      />

                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.gender?.message}
                      </p>
                    </Box>

                    <FormControlLabel
                      sx={{ width: "100%", paddingTop: "35px" }}
                      control={
                        <Checkbox
                          checked={dataWatch.influencer}
                          color="secondary"
                          {...register("influencer")}
                        />
                      }
                      label="Influencer"
                    />
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Activity
                      </p>
                      <Controller
                        name="activity"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            {...rest}
                            freeSolo
                            options={ActivityOptions}
                            value={ActivityWatch}
                            onChange={(_, data) => Change(data)}
                            getOptionLabel={(option) => option.menuText}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputRef={ref}
                                variant="outlined"
                                sx={{ width: "100%" }}
                                placeholder="Activity"
                              />
                            )}
                          />
                        )}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.activity?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Goals
                      </p>
                      <Controller
                        name="goals"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "100%" }}
                            className={LeadsClasses.select_container}
                          >
                            {goalsOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue("goals", option.menuValue)
                                }
                                value={option.menuValue}
                                className={
                                  dataWatch.goals === option.menuValue
                                    ? clsx(
                                        LeadsClasses.option_item,
                                        LeadsClasses.option_item_selected
                                      )
                                    : LeadsClasses.option_item
                                }
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.nationality?.message}
                      </p>
                    </Box>
                  </Box>
                </LeftElement>
                {/* Location Details */}
                <LeftElement>
                  <p className={clsx(ContainersClasses.section__title)}>
                    Location Details
                  </p>
                  <AddressInputForTranferBox />
                </LeftElement>
              </ContainerLeft>
              <LeftElement>
                <Box width="70%">
                  {/* Meal Plan */}

                  {/* Recommanded */}
                  <Box marginTop={5}>
                    <p className={clsx(ContainersClasses.section__title)}>
                      Recommanded
                    </p>
                    <Box
                      display="grid"
                      gridTemplateColumns="1fr 1fr 1fr 1fr"
                      gap={2}
                    >
                      <Box>
                        <p
                          className={clsx(ContainersClasses.section__subtitle)}
                        >
                          Recommeded Calories <span>Kcals</span>
                        </p>
                        <TextField
                          placeholder="Recommeded Calories"
                          sx={{ width: "100%" }}
                          {...register("recommended__calories")}
                        />
                        <p
                          className={clsx(
                            ContainersClasses.section__error__helper
                          )}
                        >
                          {errors?.recommended__calories?.message}
                        </p>
                      </Box>
                      <Box>
                        <p
                          className={clsx(ContainersClasses.section__subtitle)}
                        >
                          Recommeded Protein <span>g</span>
                        </p>
                        <TextField
                          placeholder="Recommeded Protein"
                          sx={{ width: "100%" }}
                          {...register("protein")}
                        />
                        <p
                          className={clsx(
                            ContainersClasses.section__error__helper
                          )}
                        >
                          {errors?.recommended__calories?.message}
                        </p>
                      </Box>
                      <Box>
                        <p
                          className={clsx(ContainersClasses.section__subtitle)}
                        >
                          Recommeded Carbs <span>g</span>
                        </p>
                        <TextField
                          placeholder="Recommeded Carbs"
                          sx={{ width: "100%" }}
                          {...register("carbs")}
                        />
                        <p
                          className={clsx(
                            ContainersClasses.section__error__helper
                          )}
                        >
                          {errors?.recommended__calories?.message}
                        </p>
                      </Box>
                      <Box>
                        <p
                          className={clsx(ContainersClasses.section__subtitle)}
                        >
                          Recommeded Fat <span>g</span>
                        </p>
                        <TextField
                          placeholder="Recommeded Fat"
                          sx={{ width: "100%" }}
                          {...register("fat")}
                        />
                        <p
                          className={clsx(
                            ContainersClasses.section__error__helper
                          )}
                        >
                          {errors?.recommended__calories?.message}
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </LeftElement>
            </FormProvider>
          </ContainerFullWidth>
        </ContainersContent>
      </form>
    </FormProvider>
  );
};

export default CreateNewCustomerModal;
