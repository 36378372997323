import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { TabPanel } from "../Notifications/NotificationsView";
import Preparations from "./Preparations";
import { a11yProps } from "../../Utils";
import IngredientTracking from "./IngredientTracking";
import TimeTracking from "./TimeTracking";
import moment from "moment";
import { CreateDaysForMonthBasedOnMonth } from "../../calendar/Utils";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useStylesContainers } from "../../../styles/Containers__styles";
import {
  ProgramPayload,
  PublishedMonths,
  useGetAllProgramsQuery,
  useGetPublishedMonthsQuery,
} from "../../../graphql/types";

const KitchenAppDashboard = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [value, setValue] = useState(0);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [daysList, setdaysList] = useState<Date[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<PublishedMonths | null>();
  const [program, setProgram] = useState<ProgramPayload | null>();
  const ContainersClasses = useStylesContainers();

  const dataPrograms = useGetAllProgramsQuery({
    variables: { input: { page: 1, documentsPerPage: 1000 } },
  });

  const publishedMonth = useGetPublishedMonthsQuery({
    variables: { programId: program?._id || "" },
    skip: !program,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onDaySelectedHandler = (item: string) => {
    setCurrentDate(moment(item, "DD-MM-YYYY").toDate());
  };

  useEffect(() => {
    if (selectedMonth) {
      setdaysList(
        CreateDaysForMonthBasedOnMonth(selectedMonth.month, selectedMonth.year)
      );
    }
  }, [selectedMonth]);

  return (
    <Box>
      <Box display="flex">
        <Box width="30%" marginTop={3} marginRight={3}>
          <p className={ContainersClasses.section__subtitle}>Select Program</p>
          <Autocomplete
            id="chosenProgram"
            options={dataPrograms?.data?.getAllPrograms?.data || []}
            onChange={(_, value) => {
              setProgram(value);
            }}
            value={program}
            getOptionLabel={(option) => option.name?.EN || ""}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  color="secondary"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option?.name?.EN}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select a program"
                label="Program"
              />
            )}
          />
        </Box>
        <Box width="30%" marginTop={3}>
          <p className={ContainersClasses.section__subtitle}>
            Select published month
          </p>
          <Autocomplete
            id="selected"
            options={publishedMonth.data?.getPublishedMonths || []}
            onChange={(_, value) => {
              setSelectedMonth(value);
            }}
            value={selectedMonth}
            getOptionLabel={(option) =>
              option.month + " / " + option.year || ""
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  color="secondary"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.month + " / " + option.year}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select a month"
                label="Published month"
              />
            )}
          />
        </Box>
      </Box>
      <Box>
        <Box display="flex" flexDirection="row" marginTop={3} marginBottom={10}>
          {daysList?.map((item, index) => {
            return (
              <Box
                key={item.toDateString()}
                sx={{
                  marginRight: "7px",
                  height: "30px",
                  width: "30px",
                  borderRadius: "15px",
                  display: "flex",
                  fontSize: 12,
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "4px 8px 6px rgba(208,202,205,0.8)",
                  cursor: "pointer",
                  backgroundColor:
                    moment(currentDate).format("DD-MM-YYYY") ===
                    moment(item).format("DD-MM-YYYY")
                      ? "#232222"
                      : "#fff",
                  color:
                    moment(currentDate).format("DD-MM-YYYY") ===
                    moment(item).format("DD-MM-YYYY")
                      ? "#fff"
                      : "#000",
                }}
                onClick={() =>
                  onDaySelectedHandler(moment(item).format("DD-MM-YYYY"))
                }
              >
                {moment(item, "YYYY-MM").format("D")}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Preparation" sx={{ fontSize: 25 }} {...a11yProps(0)} />
            <Tab
              label="Ingredient tracking"
              sx={{ fontSize: 25 }}
              {...a11yProps(1)}
            />
            <Tab
              label="Time Tracking"
              sx={{ fontSize: 25 }}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <Preparations date={currentDate} program={program?._id || ""} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <IngredientTracking date={currentDate} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TimeTracking date={currentDate} />
      </TabPanel>
    </Box>
  );
};

export default KitchenAppDashboard;
