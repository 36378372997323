import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import ReactPlayer from "react-player";
//material
import {
  Box,
  Chip,
  CircularProgress,
  Avatar,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { Rating } from "@mui/lab";
import Typography from "@mui/material/Typography";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileCopyIcon from "@mui/icons-material/FileCopy";
//styles
import { useStylesRecipe } from "../../../styles/Recipe__styles";
import {
  ContainerFullWidth,
  useStylesContainers,
} from "../../../styles/Containers__styles";
import { useStylesForms } from "../../../styles/Forms__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//queries & mutations
import {
  DishByIdV2Payload,
  DishPayload,
  useGetDishByIdv2Query,
} from "../../../graphql/types";
//components
import Macros from "../FormDish/components/Macros";
import IngredientDetailsForm from "../FormDish/components/IngredientDetailsForm";
import DishReportPrint from "./DishReportPrint";
//types
import { DishProps, HeaderProps } from "../Types";
//utils
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//context
import { useAuth } from "../../../contextAPI";


const ViewDish: React.FC = () => {
  const printRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const params = useParams();
  const isAuthorizedToUpdate = useIsAuthorized("FOOD_DISHS_UPDATE");
  const isAuthorizedToCreateComplaint = useIsAuthorized(
    "MANAGEMENT_COMPLAIN_CREATE"
  );

  //state
  const [caloriesSmall, setCaloriesSmall] = useState(0);
  const [caloriesMedium, setCaloriesMedium] = useState(0);
  const [caloriesLarge, setCaloriesLarge] = useState(0);

  const [proteinSmall, setProteinSmall] = useState(0);
  const [proteinMedium, setProteinMedium] = useState(0);
  const [proteinLarge, setProteinLarge] = useState(0);

  const [carbsSmall, setCarbsSmall] = useState(0);
  const [carbsMedium, setCarbsMedium] = useState(0);
  const [carbsLarge, setCarbsLarge] = useState(0);

  const [fatSmall, setFatSmall] = useState(0);
  const [fatMedium, setFatMedium] = useState(0);
  const [fatLarge, setFatLarge] = useState(0);

  const [fiberSmall, setFiberSmall] = useState(0);
  const [fiberMedium, setFiberMedium] = useState(0);
  const [fiberLarge, setFiberLarge] = useState(0);

  const [videoUrl, setVideoUrl] = useState("");
  //react-hook-form
  const methods = useForm<DishProps>({
    defaultValues: {
      ingredients: [
        {
          id: "",
          name: "",
          quantitySmall: "",
          quantityMedium: "",
          quantityLarge: "",
          costSmall: "",
          costMedium: "",
          costLarge: "",
        },
      ],
    },
  });

  const { control, getValues, reset } = methods;

  //styles
  const FormsClasses = useStylesForms();
  const ButtonClasses = useStylesButtons();
  const ContainersClasses = useStylesContainers();

  //queries
  const { data, loading } = useGetDishByIdv2Query({
    variables: {
      dishId: params.id || "",
    },
    skip: !params.id,
    fetchPolicy: "no-cache",
  });

  const dish = useMemo(
    () => data?.getDishByIdv2 || null,
    [data?.getDishByIdv2]
  );

  useEffect(() => {
    if (data?.getDishByIdv2) {
      const { ingredients } = data?.getDishByIdv2 ?? null;
      reset({
        ingredients: ingredients,
      });
    }
  }, [data?.getDishByIdv2]);

  useEffect(() => {
    if (dish?.videoUrl) {
      setVideoUrl(dish?.videoUrl || "");
    }
  }, [dish?.videoUrl]);

  useEffect(() => {
    if (data?.getDishByIdv2) {
      setCaloriesSmall(
        Math.round(
          dish?.small?.nutritionDetails?.find((el) => el.name === "Energy")
            ?.quantity || 0
        )
      );
      setCaloriesMedium(
        Math.round(
          dish?.medium?.nutritionDetails?.find((el) => el.name === "Energy")
            ?.quantity || 0
        )
      );
      setCaloriesLarge(
        Math.round(
          dish?.large?.nutritionDetails?.find((el) => el.name === "Energy")
            ?.quantity || 0
        )
      );
      setProteinSmall(
        dish?.small?.nutritionDetails?.find((el) => el.name === "Protein")
          ?.quantity || 0
      );
      setProteinMedium(
        dish?.medium?.nutritionDetails?.find((el) => el.name === "Protein")
          ?.quantity || 0
      );
      setProteinLarge(
        dish?.large?.nutritionDetails?.find((el) => el.name === "Protein")
          ?.quantity || 0
      );
      setCarbsSmall(
        dish?.small?.nutritionDetails?.find((el) => el.name === "Carbohydrate")
          ?.quantity || 0
      );
      setCarbsMedium(
        dish?.medium?.nutritionDetails?.find((el) => el.name === "Carbohydrate")
          ?.quantity || 0
      );
      setCarbsLarge(
        dish?.large?.nutritionDetails?.find((el) => el.name === "Carbohydrate")
          ?.quantity || 0
      );
      setFatSmall(
        dish?.small?.nutritionDetails?.find((el) => el.name === "Total Fat")
          ?.quantity || 0
      );
      setFatMedium(
        dish?.medium?.nutritionDetails?.find((el) => el.name === "Total Fat")
          ?.quantity || 0
      );
      setFatLarge(
        dish?.large?.nutritionDetails?.find((el) => el.name === "Total Fat")
          ?.quantity || 0
      );
      setFiberSmall(
        dish?.small?.nutritionDetails?.find(
          (el) => el.name === "Fiber, total dietary"
        )?.quantity || 0
      );
      setFiberMedium(
        dish?.medium?.nutritionDetails?.find(
          (el) => el.name === "Fiber, total dietary"
        )?.quantity || 0
      );
      setFiberLarge(
        dish?.large?.nutritionDetails?.find(
          (el) => el.name === "Fiber, total dietary"
        )?.quantity || 0
      );
    }
  }, [data?.getDishByIdv2]);

  const onClickEdit = () => {
    navigate(`/food/dishes/update_dish/${params.id}`);
  };

  const handleGoToComplaint = () => {
    navigate(`/admin/complains/add_complain`, {
      state: { CustomerId: null, dishId: params.id },
    });
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(videoUrl || "");
  };

  //functions
  const renderTags = () => {
    return dish?.tags?.map((item) => (
      <Chip
        key={item}
        label={`${item}`}
        variant="outlined"
        className={FormsClasses.chips__tags}
      />
    ));
  };

  const renderPrograms = () => {
    return dish?.medicalConditions?.map((item) => (
      <Chip
        key={item}
        label={item}
        variant="outlined"
        className={FormsClasses.chips__tags}
      />
    ));
  };

  const renderSuitability = () => {
    return (
      <Chip
        label={dish?.dishSuitability}
        variant="outlined"
        className={FormsClasses.chips__tags}
      />
    );
  };

  const renderPackaging = () => {
    return dish?.packagingName?.map((item) => (
      <Chip
        key={item}
        label={item}
        variant="outlined"
        className={FormsClasses.chips__tags}
      />
    ));
  };

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    documentTitle:
      `${dish?.name?.EN} Report generated on` +
      moment().format("DD/MM/YYYY dddd"),
  });

  //render
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "150px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" marginBottom={2}>
            <Box flex={1} />
            <Button
              style={{ marginRight: 10 }}
              onClick={handlePrint}
              className={ButtonClasses.button_export_excel}
              startIcon={<PictureAsPdfIcon />}
            >
              Export PDF
              {false ? <CircularProgress size={14} color="inherit" /> : ""}
            </Button>
            <Box display="flex" flexDirection="column">
              {isAuthorizedToUpdate && (
                <Button
                  variant="contained"
                  onClick={onClickEdit}
                  className={ButtonClasses.button_submit_form}
                >
                  Update Dish
                </Button>
              )}
              {isAuthorizedToCreateComplaint && (
                <Button
                  variant="contained"
                  onClick={handleGoToComplaint}
                  className={ButtonClasses.button_submit_form}
                  sx={{ marginTop: 2 }}
                >
                  Create a complaint
                </Button>
              )}
            </Box>
          </Box>
          <ContainerFullWidth>
            <Header
              dishImageURL={dish?.pictureUrl || ""}
              dishNameEnglish={dish?.name?.EN || ""}
              dishRef={dish?.ref || ""}
              retailSellingPrice={dish?.retailsSellingPrice || 0}
              description={dish?.description?.EN || ""}
              tags={renderTags}
              medicalCondition={renderPrograms}
              suitability={renderSuitability}
              microWaveTime={dish?.reheatingInstruction || ""}
              rating={dish?.rating?.average || 0}
              ratingCount={dish?.rating?.count || 0}
              costSmall={dish?.small?.price || 0}
              costMedium={dish?.medium?.price || 0}
              costLarge={dish?.large?.price || 0}
              sauces={dish?.sauces as DishPayload[]}
            />
            <Box marginTop={42}>
              <Typography
                variant="h3"
                sx={{ color: "secondary.main", pl: 1, mt: 1, mb: 1 }}
              >
                Macros
              </Typography>
              <Macros
                caloriesSmall={caloriesSmall}
                caloriesMedium={caloriesMedium}
                caloriesLarge={caloriesLarge}
                proteinSmall={proteinSmall}
                proteinMedium={proteinMedium}
                proteinLarge={proteinLarge}
                carbsSmall={carbsSmall}
                carbsMedium={carbsMedium}
                carbsLarge={carbsLarge}
                fatSmall={fatSmall}
                fatMedium={fatMedium}
                fatLarge={fatLarge}
                fiberSmall={fiberSmall}
                fiberMedium={fiberMedium}
                fiberLarge={fiberLarge}
              />
            </Box>
            <Box>
              <Typography
                variant="h3"
                sx={{ color: "secondary.main", pl: 1, mt: 1, mb: 1 }}
              >
                Ingredient Details
              </Typography>
              <IngredientDetailsForm
                control={control}
                getValues={getValues}
                reset={reset}
                ingredientsList={[]}
                mode="VIEW"
              />
            </Box>
            <Box>
              <Typography
                variant="h3"
                sx={{ color: "secondary.main", pl: 1, mt: 1, mb: 1 }}
              >
                Dish Details
              </Typography>
              <Box
                display="grid"
                gridTemplateColumns="2fr 1fr"
                border={1}
                borderRadius={2}
                padding={2}
                paddingTop={2}
              >
                <Box>
                  <p className={ContainersClasses.section__subtitle}>
                    Cooking instruction
                  </p>
                  <Typography>{dish?.cookingInstruction}</Typography>
                  <p className={ContainersClasses.section__subtitle}>
                    Plating instruction
                  </p>
                  <Typography>{dish?.platingInstruction}</Typography>
                  <p className={ContainersClasses.section__subtitle}>
                    Packaging Name
                  </p>
                  <Typography>{renderPackaging()}</Typography>
                  {videoUrl && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      marginTop={4}
                      width="100%"
                      maxWidth={500}
                    >
                      <ReactPlayer
                        url={dish?.videoUrl || ""}
                        controls
                        light={dish?.videoPictureUrl || ""}
                        width="100%"
                        height={300}
                      />

                      <Box display="flex" flexDirection="column" width="100%">
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={videoUrl}
                          onChange={(e) => setVideoUrl(e.target.value)}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <IconButton
                                onClick={handleCopyClick}
                                aria-label="Copy"
                              >
                                <FileCopyIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Box>
                    <p className={ContainersClasses.section__subtitle}>
                      Plating Image
                    </p>
                    <Avatar
                      alt={dish?.platingPhotoUrl || ""}
                      src={dish?.platingPhotoUrl || ""}
                      variant="rounded"
                      style={{ width: 200, height: 200 }}
                    />
                  </Box>
                  <Box>
                    <p className={ContainersClasses.section__subtitle}>
                      Packaging Image
                    </p>
                    <Avatar
                      alt={dish?.platingPhotoUrl || ""}
                      src={dish?.platingPhotoUrl || ""}
                      variant="rounded"
                      style={{ width: 200, height: 200 }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </ContainerFullWidth>
        </>
      )}
      <div style={{ display: "none" }}>
        <DishReportPrint dishData={dish as DishByIdV2Payload} ref={printRef} />
      </div>
    </>
  );
};

export default ViewDish;

const Header: React.FC<HeaderProps> = ({
  dishImageURL,
  dishNameEnglish,
  dishRef,
  retailSellingPrice,
  description,
  tags,
  medicalCondition,
  suitability,
  microWaveTime,
  rating,
  costSmall,
  costMedium,
  costLarge,
  sauces,
  ratingCount,
}) => {
  const navigate = useNavigate();
  const auth = useAuth();

  //styles
  const RecipeClasses = useStylesRecipe();

  const navigateToSauceDetails = (sauceId: string) => {
    navigate(`/food/dishes/view_dish/${sauceId}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box height={200}>
        <Box display="flex" gap="20px">
          <Avatar
            alt={dishImageURL || ""}
            src={dishImageURL || ""}
            variant="rounded"
            style={{ width: 200, height: 200 }}
          />
          <Box>
            <p className={RecipeClasses.recipe__name__title}>
              {dishNameEnglish}
              <span className={RecipeClasses.recipe__id}>{dishRef}</span>
            </p>
            <Typography color="grey" fontSize={16}>
              {description}
            </Typography>
            <Box display="flex" gap="10px">
              <Rating
                name="half-rating"
                defaultValue={Math.round(rating)}
                precision={0.1}
                size="small"
                readOnly
              />
              <Typography fontWeight="bold">
                ({ratingCount ? Math.round(ratingCount) : 0})
              </Typography>
            </Box>
            {retailSellingPrice ? (
              <Box display="flex">
                <Typography>{retailSellingPrice}</Typography>
                &nbsp;
                <Typography>{auth.countryCurrency}</Typography>
              </Box>
            ) : null}
          </Box>
        </Box>

        <Box>
          <>
            <p className={RecipeClasses.recipe__title}>Tags</p>
            <Box className={RecipeClasses.recipe__tags__container}>
              {tags()}
            </Box>
          </>
          <>
            <p className={RecipeClasses.recipe__title}>Program</p>
            <Box className={RecipeClasses.recipe__tags__container}>
              {medicalCondition()}
            </Box>
          </>
          <>
            <p className={RecipeClasses.recipe__title}>Dishs Details</p>
            <Typography color="grey" fontSize={16}>
              DISH suitability
            </Typography>
            <Box className={RecipeClasses.recipe__tags__container}>
              {suitability()}
            </Box>
          </>
          <Typography color="grey" fontSize={16}>
            Micro wave Time
          </Typography>
          <Typography color="grey" fontSize={16}>
            {microWaveTime}
          </Typography>
        </Box>
      </Box>
      <Box height={200} width={200}>
        <Box style={{ marginLeft: 70 }}>
          <Typography fontWeight="bold">Cost</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography>small</Typography>
          <Typography color="#EC7474">
            {costSmall} {auth.countryCurrency}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography>Medium</Typography>
          <Typography color="#EC7474">
            {costMedium} {auth.countryCurrency}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography>Large</Typography>
          <Typography color="#EC7474">
            {costLarge} {auth.countryCurrency}
          </Typography>
        </Box>
        {sauces.length > 0 && (
          <>
            <Box style={{ marginLeft: 70, marginTop: 10 }}>
              <Typography fontWeight="bold">Sauces</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              {sauces.map((el) => {
                return (
                  <Box
                    key={el._id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>{el.name?.EN}</Typography>
                    <Button
                      onClick={() => navigateToSauceDetails(el._id || "")}
                    >
                      <VisibilityIcon />
                    </Button>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        marginRight={10}
        height={200}
      >
        {retailSellingPrice ? (
          <>
            <Typography fontWeight="bold">Add on price</Typography>
            <Typography color="#C7893E">
              {retailSellingPrice} {auth.countryCurrency}
            </Typography>
          </>
        ) : null}
      </Box>
    </Box>
  );
};
