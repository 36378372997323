import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useSnackbar } from "notistack";
//material-ui
import { Hidden, Toolbar, Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ThemeProvider } from "@mui/material/styles";
//styles
import { useStylesMainContainer } from "../../../styles/MainContainer__styles";
import { useStylesNavigation } from "../../../styles/Navigation__styles";
//components
import Navigation from "../Navigation/Navigation";
import AppMenu from "../AppMenu/AppMenu";
import { Drawer } from "../Drawer/Drawer";
//icons
import DietureLogo from "../../../public/icons/dietureLogo";
//context
import { useAuth } from "../../../contextAPI";
//queries&mutations
import {
  CountryPayload,
  useSwitchCountryMutation,
} from "../../../graphql/types";
//utils
import { getMessageError } from "../../Utils";
//CustomTheme
import { useCustomTheme } from "../../../theme/useCustomTheme";

const MainContainer = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const auth = useAuth();
  const location = useLocation();

  //styles
  const MainContainerClasses = useStylesMainContainer();
  const navigationClasses = useStylesNavigation();

  //state

  const [country, setCountry] = useState<CountryPayload>(
    //@ts-ignore
    auth.countriesList.find((el) => el.code === auth.countryCode)
  );
  const [disableCountrySelector, setDisableCountrySelector] = useState(false);

  //queries&mutations
  const [switchCountry, { loading }] = useSwitchCountryMutation();

  const handleChange = (event: SelectChangeEvent) => {
    const currentCountry = auth.countriesList.find(
      (el) => el._id === event.target.value
    );
    setCountry(currentCountry as CountryPayload);
    switchCountryHandler(event.target.value);
  };

  useEffect(() => {
    if (auth.countryCode) {
      const currentCountry = auth.countriesList.find(
        (el) => el.code === auth.countryCode
      );
      if (currentCountry) {
        setCountry(currentCountry);
      }
    }
  }, [auth.countryCode, auth.countriesList]);

  useEffect(() => {
    if (location.pathname) {
      const insideViewPage = location.pathname.includes("view");
      const insideUpdatePage = location.pathname.includes("update");
      if (insideViewPage || insideUpdatePage) {
        setDisableCountrySelector(true);
      } else {
        setDisableCountrySelector(false);
      }
    }
  }, [location.pathname]);

  const switchCountryHandler = async (countryId: string) => {
    await switchCountry({
      variables: {
        countryId: countryId,
      },
      onCompleted(data) {
        const { token } = data.switchCountry;
        auth.signIn({
          token,
          code: data.switchCountry.currentCountry.code || "",
          currency: data.switchCountry.currentCountry.currency || "",
          primary: data.switchCountry.currentCountry.primaryColor || "",
          secondary: data.switchCountry.currentCountry.secondaryColor || "",
        });
        localStorage.setItem("token", token);
        enqueueSnackbar("Country switched succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        window.location.reload();
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const { theme } = useCustomTheme();

  //render
  return (
    <ThemeProvider theme={theme}>
      <>
        <Navigation />
        <Hidden xsDown implementation="css">
          <Drawer variant="permanent">
            <div className={navigationClasses.container__logo}>
              <a href={`/dashboard/home/list`}>
                <DietureLogo />
              </a>
            </div>
            <div className={navigationClasses.custom__container__menu}>
              <AppMenu />
            </div>

            <Box sx={{ paddingBottom: 2, paddingLeft: 2, paddingRight: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={country?._id || ""}
                  label="Country"
                  onChange={handleChange}
                  disabled={loading || disableCountrySelector}
                >
                  {auth.countriesList.map((el) => {
                    return (
                      <MenuItem
                        value={el?._id || ""}
                        key={el?._id}
                        sx={{ bgcolor: "#D3D3D3" }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          color="white"
                          width={"100%"}
                        >
                          <Avatar
                            alt={el.name?.EN || ""}
                            src={el.flag || ""}
                            sx={{ width: 30, height: 30 }}
                          />
                          <Box>{el.name?.EN}</Box>
                          <Box>({el.currency})</Box>
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box textAlign="center">V 3.5.6</Box>
          </Drawer>
        </Hidden>
      </>
      <Toolbar sx={{ height: 80 }} />
      <div className={clsx(MainContainerClasses.main__container)}>
        <Outlet />
      </div>
    </ThemeProvider>
  );
};

export default MainContainer;
