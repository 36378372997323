import React, { useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
//material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//types
import { printProgramMenuReportProps } from "./Types";

const ProgramMenuReportPrint = React.forwardRef<
  HTMLDivElement,
  printProgramMenuReportProps
>(({ generateReport }, ref) => {
  const programMenu = useMemo(
    () => generateReport?.findProgramMenuByMonth.mealPlans,
    [generateReport?.findProgramMenuByMonth]
  );

  const Table = styled.table`
    border: 1px solid black;
    border-collapse: collapse;
    min-width: 90%;
  `;

  const Td = styled.td`
    border: 1px solid black;
  `;
  //render
  return (
    <div ref={ref}>
      <Box display="flex" justifyContent="center" alignItems="center" p={2}>
        <Box></Box>
        <Typography style={{ fontSize: 18 }} fontWeight={600}>
          Program menu-{" "}
          {generateReport?.findProgramMenuByMonth.program.name?.EN}
        </Typography>
      </Box>

      <Box>
        <Table style={{ borderCollapse: "collapse" }}>
          <tr>
            <Td style={{ backgroundColor: "#DCEAFF" }}>
              <div></div>
            </Td>
            <Td style={{ backgroundColor: "#DCEAFF" }}>
              <div
                style={{
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                {generateReport?.findProgramMenuByMonth.program.displayMeals
                  ?.breakfast?.EN || "BREAKFAST"}
              </div>
            </Td>
            <Td style={{ backgroundColor: "#DCEAFF" }}>
              <div
                style={{
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                {generateReport?.findProgramMenuByMonth.program.displayMeals
                  ?.lunch?.EN || "LUNCH"}
              </div>
            </Td>
            <Td style={{ backgroundColor: "#DCEAFF" }}>
              <div
                style={{
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                {generateReport?.findProgramMenuByMonth.program.displayMeals
                  ?.dinner?.EN || "DINNER"}
              </div>
            </Td>
            <Td style={{ backgroundColor: "#DCEAFF" }}>
              <div
                style={{
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                {generateReport?.findProgramMenuByMonth.program.displayMeals
                  ?.snack?.EN || "SNACKS"}
              </div>
            </Td>
          </tr>

          {programMenu?.map((element, index) => {
            return (
              <>
                <tr
                  style={{
                    backgroundColor: index % 2 != 0 ? "#FFF7DC" : "#fff",
                  }}
                >
                  <Td>
                    <div
                      style={{
                        fontSize: 12,
                        paddingLeft: 6,
                        fontFamily: "Poppins",
                      }}
                    >
                      {moment(element.date).format("D MMMM -YYYY     dddd")}
                    </div>
                  </Td>
                  <Td>
                    <div
                      style={{
                        fontSize: 12,
                        paddingLeft: 6,
                        fontFamily: "Poppins",
                      }}
                    >
                      {element.meals
                        .find((el) => el.typeOfMeal === "BREAKFAST")
                        ?.dishes.map((elem) => {
                          return (
                            <div key={elem._id}>
                              <Typography style={{ fontSize: 11 }}>
                                -{elem.code + "  : "}
                                {elem.name?.EN}
                              </Typography>
                              <Typography
                                style={{ fontSize: 9, color: "grey" }}
                              >
                                S-
                                {elem?.small?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                                , M-
                                {elem?.medium?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                                , L-
                                {elem?.large?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                              </Typography>
                            </div>
                          );
                        })}
                    </div>
                  </Td>
                  <Td>
                    <div
                      style={{
                        fontSize: 12,
                        paddingLeft: 6,
                        fontFamily: "Poppins",
                      }}
                    >
                      {element.meals
                        .find((el) => el.typeOfMeal === "LUNCH")
                        ?.dishes.map((elem) => {
                          return (
                            <div key={elem._id}>
                              <Typography style={{ fontSize: 11 }}>
                                -{elem.code + "  : "}
                                {elem.name?.EN}
                              </Typography>
                              <Typography
                                style={{ fontSize: 9, color: "grey" }}
                              >
                                S-
                                {elem?.small?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                                , M-
                                {elem?.medium?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                                , L-
                                {elem?.large?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                              </Typography>
                            </div>
                          );
                        })}
                    </div>
                  </Td>
                  <Td>
                    <div
                      style={{
                        fontSize: 12,
                        paddingLeft: 6,
                        fontFamily: "Poppins",
                      }}
                    >
                      {element.meals
                        .find((el) => el.typeOfMeal === "DINNER")
                        ?.dishes.map((elem) => {
                          return (
                            <div key={elem._id}>
                              <Typography style={{ fontSize: 11 }}>
                                -{elem.code + "  : "}
                                {elem.name?.EN}
                              </Typography>
                              <Typography
                                style={{ fontSize: 9, color: "grey" }}
                              >
                                S-
                                {elem?.small?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                                , M-
                                {elem?.medium?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                                , L-
                                {elem?.large?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                              </Typography>
                            </div>
                          );
                        })}
                    </div>
                  </Td>
                  <Td>
                    <div
                      style={{
                        fontSize: 12,
                        paddingLeft: 6,
                        fontFamily: "Poppins",
                      }}
                    >
                      {element.meals
                        .find((el) => el.typeOfMeal === "SNACKS")
                        ?.dishes.map((elem) => {
                          return (
                            <div key={elem._id}>
                              <Typography style={{ fontSize: 11 }}>
                                -{elem.code + "  : "}
                                {elem.name?.EN}
                              </Typography>
                              <Typography
                                style={{ fontSize: 9, color: "grey" }}
                              >
                                S-
                                {elem?.small?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                                , M-
                                {elem?.medium?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                                , L-
                                {elem?.large?.nutritionDetails
                                  ?.find((el) => el.name === "Energy")
                                  ?.quantity.toFixed(2)}
                              </Typography>
                            </div>
                          );
                        })}
                    </div>
                  </Td>
                </tr>
              </>
            );
          })}
        </Table>
      </Box>
    </div>
  );
});

export default ProgramMenuReportPrint;
