import { useState } from "react";
import { Controller } from "react-hook-form";
//Styles
import { useStylesButtons } from "../../styles/Buttons__styles";
import { useStylesInput } from "../../styles/Input__styles";
import { useStylesContainers } from "../../styles/Containers__styles";
//utils
import { meals } from "../Utils";
//components
import UpdatePackImageDropZone from "./UpdatePackImageDropZone";
//material ui
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
//context
import { useAuth } from "../../contextAPI";

const Pack = ({
  disabledPack,
  mode,
  control,
  index,
  onSave,
  image,
}: {
  disabledPack: boolean;
  mode: "UPDATE" | "ADD" | "VIEW";
  control: any;
  index: number;
  onSave?: any;
  image?: string;
}) => {
  //state
  const [disabled, setDisableEdit] = useState<boolean>(disabledPack);
  //styles
  const ButtonsClasses = useStylesButtons();
  const InputClasses = useStylesInput();
  const ContainersClasses = useStylesContainers();
  let auth = useAuth();
  return (
    <Box
      width="80%"
      borderColor="black"
      borderRadius={2}
      border={1}
      padding={5}
      display="flex"
      flexDirection="column"
    >
      <Box>
        <Controller
          name={`packs.${index}.title`}
          control={control}
          render={({
            field: { ref, onChange, ...rest },
            fieldState: { error },
          }) => (
            <>
              <TextField
                {...rest}
                sx={{ width: "30%" }}
                type="text"
                disabled={disabled}
                onChange={onChange}
                placeholder="Title ex Classic - 3 meals"
              />
            </>
          )}
        />
      </Box>
      {mode === "UPDATE" && (
        <Button
          variant="contained"
          className={ButtonsClasses.button_submit_form}
          sx={{ alignSelf: "end" }}
          onClick={() => setDisableEdit((oldState) => !oldState)}
        >
          Edit
        </Button>
      )}

      <Box display="flex" flexDirection="column" flexGrow={1}>
        <p className={ContainersClasses.section__subtitle}>Meals</p>
        <Controller
          name={`packs.${index}.meals`}
          control={control}
          render={({
            field: { onChange: Change, ref, ...rest },
            fieldState: { error },
          }) => (
            <>
              <Autocomplete
                {...rest}
                multiple
                disabled={disabled}
                id="meals"
                options={meals}
                renderInput={(params) => (
                  <TextField {...params} inputRef={ref} variant="outlined" />
                )}
                isOptionEqualToValue={() => false}
                onChange={(_, data) => {
                  Change(data);
                }}
              />
              <p className={InputClasses.input__error__helper}>
                {error?.message}
              </p>
            </>
          )}
        />
      </Box>
      <Box>
        <Controller
          name={`packs.${index}.active`}
          control={control}
          render={({ field: { ref, value, ...rest } }) => (
            <FormControlLabel
              {...rest}
              value={value}
              disabled={disabled}
              control={<Switch checked={value} />}
              label={value ? "Active" : "Inactive"}
            />
          )}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={2}>
        <Box></Box>
        <Box className={ContainersClasses.section__subtitle}>Breakfast</Box>
        <Box className={ContainersClasses.section__subtitle}>Lunch/Dinner</Box>
        <Box className={ContainersClasses.section__subtitle}>Snacks</Box>

        <Box className={ContainersClasses.section__subtitle}>Weekly</Box>
        <Box>
          <Controller
            name={`packs.${index}.weeklyBreakfast`}
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <>
                <TextField
                  {...rest}
                  disabled={disabled}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {auth.countryCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          />
        </Box>
        <Box>
          <Controller
            name={`packs.${index}.weeklyLunchDinner`}
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <>
                <TextField
                  {...rest}
                  disabled={disabled}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {auth.countryCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          />
        </Box>
        <Box>
          <Controller
            name={`packs.${index}.weeklySnacks`}
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <>
                <TextField
                  {...rest}
                  disabled={disabled}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {auth.countryCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          />
        </Box>

        <Box className={ContainersClasses.section__subtitle}>Monthly</Box>
        <Box>
          <Controller
            name={`packs.${index}.monthlyBreakfast`}
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <>
                <TextField
                  {...rest}
                  disabled={disabled}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {auth.countryCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          />
        </Box>
        <Box>
          <Controller
            name={`packs.${index}.monthlyLunchDinner`}
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <>
                <TextField
                  {...rest}
                  disabled={disabled}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {auth.countryCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          />
        </Box>
        <Box>
          <Controller
            name={`packs.${index}.monthlySnacks`}
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <>
                <TextField
                  {...rest}
                  disabled={disabled}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {auth.countryCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          />
        </Box>

        <Box className={ContainersClasses.section__subtitle}>Three Months</Box>
        <Box>
          <Controller
            name={`packs.${index}.threeMonthsBreakfast`}
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <>
                <TextField
                  {...rest}
                  disabled={disabled}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {auth.countryCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          />
        </Box>
        <Box>
          <Controller
            name={`packs.${index}.threeMonthsLunchDinner`}
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <>
                <TextField
                  {...rest}
                  disabled={disabled}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {auth.countryCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          />
        </Box>
        <Box>
          <Controller
            name={`packs.${index}.threeMonthsSnacks`}
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <>
                <TextField
                  {...rest}
                  disabled={disabled}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {auth.countryCurrency}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap={2}
        marginTop={4}
      >
        {mode === "VIEW" ? (
          <Box>
            <Avatar
              alt={image || ""}
              src={image || ""}
              variant="rounded"
              style={{ width: 150, height: 150 }}
            />
          </Box>
        ) : (
          <Box>
            <Controller
              name={`packs.${index}.photoUrl`}
              control={control}
              render={({ field: { onChange, ref } }) => {
                return (
                  <UpdatePackImageDropZone
                    onChange={(e) => {
                      onChange(e.target.files[0]);
                    }}
                    label="Drop File"
                    inputRef={ref}
                    name={`packs.${index}.photoUrl`}
                    errors={{}}
                    multiple={false}
                    disabled={disabled}
                    accept="image/*"
                    message="Recommended resolution for image 400px*400px"
                  />
                );
              }}
            />
          </Box>
        )}

        <Box
          className={ContainersClasses.section__container}
          sx={{ alignSelf: "flex-end" }}
        >
          <Box>
            <p className={ContainersClasses.section__subtitle}>
              Pack Name<span>(English)</span>
            </p>
            <Controller
              name={`packs.${index}.nameEN`}
              control={control}
              render={({
                field: { ref, onChange, ...rest },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    {...rest}
                    sx={{ width: "100%" }}
                    type="text"
                    disabled={disabled}
                    onChange={onChange}
                    placeholder="Breakfast, Lunch & Dinner"
                  />
                </>
              )}
            />
            <p className={ContainersClasses.section__error__helper}>
              {/* {errors?.program__name__english?.message} */}
            </p>
          </Box>
        </Box>
        <Box
          className={ContainersClasses.section__container}
          sx={{ alignSelf: "flex-end" }}
        >
          <Box>
            <p className={ContainersClasses.section__subtitle}>
              Pack Name<span>(Arabic)</span>
            </p>
            <Controller
              name={`packs.${index}.nameAR`}
              control={control}
              render={({
                field: { ref, onChange, ...rest },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    {...rest}
                    sx={{ width: "100%" }}
                    type="text"
                    disabled={disabled}
                    onChange={onChange}
                    placeholder="فطور, غداء & عشاء"
                  />
                </>
              )}
            />
            <p className={ContainersClasses.section__error__helper}>
              {/* {errors?.program__name__english?.message} */}
            </p>
          </Box>
        </Box>
        {mode == "UPDATE" && (
          <Box sx={{ alignSelf: "flex-end" }}>
            <Button
              variant="contained"
              className={ButtonsClasses.button_submit_form}
              onClick={onSave}
              disabled={disabled}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Pack;
