import { useApolloClient } from "@apollo/client";
import React, { useEffect, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { CountryPayload, Permissions_Enum, Roles } from "../../graphql/types";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "../../firebaseConfig";
import {
  AuthContextType,
  AuthProviderProps,
  DecodedToken,
  RequireAuthProps,
  SignInParams,
} from "./types";
import jwtDecode from "jwt-decode";
import { useSnackbar } from "notistack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { useStylesDialog } from "../../styles/Dialog__styles";
import { useStylesButtons } from "../../styles/Buttons__styles";

const checkTokenExpiration = (expirationTimestamp: number) => {
  const currentTimestamp = Date.now() / 1000;
  if (expirationTimestamp && currentTimestamp > expirationTimestamp) {
    return true;
  } else {
    return false;
  }
};

let AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const client = useApolloClient();

  //state
  const [token, setToken] = React.useState<string | null>(
    localStorage.getItem("token")
  );
  const [permissions, setPermissions] = React.useState<Permissions_Enum[]>([]);

  //QA for qatar| SA for saudi arabia| AE for UAE
  const [countryCode, setCountryCode] = React.useState<string | null>(
    localStorage.getItem("code")
  );
  //QAR for qatqri riyal and SAR for saudi riyal
  const [countryCurrency, setCountryCurrency] = React.useState<string | null>(
    localStorage.getItem("currency")
  );

  const [primaryColor, setPrimaryColor] = React.useState<string | null>(
    localStorage.getItem("primary")
  );
  const [secondaryColor, setSecondaryColor] = React.useState<string | null>(
    localStorage.getItem("secondary")
  );

  const [countriesList, setCountriesList] = React.useState<CountryPayload[]>(
    []
  );

  const [deactivateCountrySelector, setDeactivateCountrySelector] =
    React.useState<boolean>(false);

  //functions
  const signIn = (params: SignInParams) => {
    localStorage.setItem("token", params.token);
    setToken(params.token);

    //get the permissions list ans set it to the permissions state
    const decodedToken: DecodedToken = jwtDecode(
      params.token?.toString() || ""
    );
    const permissionsList: string = decodedToken?.permissions;
    const countriesListData: string = decodedToken?.countriesList;

    setPermissions(JSON.parse(permissionsList));
    setCountriesList(JSON.parse(countriesListData));
    localStorage.setItem("code", params.code || "");
    setCountryCode(params.code || "");
    localStorage.setItem("currency", params.currency || "");
    setCountryCurrency(params.currency || "");
    localStorage.setItem("primary", params.primary || "");
    setPrimaryColor(params.primary || "");
    localStorage.setItem("secondary", params.secondary || "");
    setSecondaryColor(params.secondary || "");
  };

  const signOut = async () => {
    localStorage.clear();
    await client.clearStore();
    setToken(null);
    setPermissions([]);
    setCountryCode("");
    setCountryCurrency("");
    setCountriesList([]);
    setPrimaryColor("");
    setSecondaryColor("");
  };

  useEffect(() => {
    if (token) {
      const decodedToken: DecodedToken = jwtDecode(token.toString() || "");
      const permissionsList: string = decodedToken?.permissions;
      const countriesListData: string = decodedToken?.countriesList;
      if (permissionsList) {
        setPermissions(JSON.parse(permissionsList));
      }
      if (countriesListData) {
        setCountriesList(JSON.parse(countriesListData));
      }
    }
  }, [token]);

  //firebase events
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  //render
  const value = useMemo(
    () => ({
      token,
      //role,
      permissions,
      signIn,
      signOut,
      analytics,
      countryCode,
      countryCurrency,
      countriesList,
      primaryColor,
      secondaryColor,
    }),
    [
      token,
      //role,
      permissions,
      signIn,
      signOut,
      analytics,
      countryCode,
      countryCurrency,
      countriesList,
      primaryColor,
      secondaryColor,
    ]
  );

  //@ts-ignore
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const RequireAuth: React.FC<RequireAuthProps> = (props) => {
  const { children } = props;
  let auth = useAuth();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  // styles
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  const onClickLogoutHandler = () => {
    auth.signOut();
  };

  if (auth.token) {
    try {
      const decodedToken: any = jwtDecode(auth.token?.toString() || "");
      const expirationTimestamp: number = decodedToken?.exp;
      const isTokenExpired = checkTokenExpiration(expirationTimestamp);
      if (isTokenExpired) {
        enqueueSnackbar("Session expired", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });

        return (
          <Dialog
            open={true}
            onClose={() => null}
            className={DialogClasses.dialog_container}
          >
            <DialogTitle className={DialogClasses.alert_dialog_title}>
              <WarningIcon />
              <span className="alert_dialog_title_text">
                Your Session Expired
              </span>
            </DialogTitle>
            <DialogContent className={DialogClasses.alert_dialog_content}>
              <DialogContentText>
                Reconnect to continue using the dashboard
              </DialogContentText>
            </DialogContent>
            <DialogActions className={DialogClasses.alert_dialog_actions}>
              <Button
                className={ButtonsClasses.GreyButton}
                onClick={onClickLogoutHandler}
              >
                Log out
              </Button>
            </DialogActions>
          </Dialog>
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (!auth.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
