interface  SeparatorProps  {
    className?: string
  };

function Separator({className}: SeparatorProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="32"
      fill="none"
      viewBox="0 0 2 32"
      className={className}
    >
      <path stroke="#C7893E" strokeLinecap="round" d="M1 1v30"></path>
    </svg>
  );
}

export default Separator;