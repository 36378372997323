import { useAuth } from "../../contextAPI";

function DataManagementIcon() {
  const auth = useAuth();
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0.279297V5.7793C10 6.17712 10.158 6.55865 10.4393 6.83996C10.7206 7.12126 11.1022 7.2793 11.5 7.2793H17V14.2793C17 14.8097 16.7893 15.3184 16.4142 15.6935C16.0391 16.0686 15.5304 16.2793 15 16.2793H13V18.2793C13 18.8097 12.7893 19.3184 12.4142 19.6935C12.0391 20.0686 11.5304 20.2793 11 20.2793H2C1.46957 20.2793 0.960859 20.0686 0.585786 19.6935C0.210714 19.3184 0 18.8097 0 18.2793V6.2793C0 5.74886 0.210714 5.24016 0.585786 4.86508C0.960859 4.49001 1.46957 4.2793 2 4.2793H4V2.2793C4 1.74886 4.21071 1.24016 4.58579 0.865083C4.96086 0.49001 5.46957 0.279297 6 0.279297H10ZM4 6.2793H2V18.2793H11V16.2793H6C5.46957 16.2793 4.96086 16.0686 4.58579 15.6935C4.21071 15.3184 4 14.8097 4 14.2793V6.2793ZM12 0.322297C12.3789 0.402652 12.7263 0.591288 13 0.865297L16.414 4.2793C16.688 4.55302 16.8766 4.90042 16.957 5.2793H12V0.322297Z"
        fill={auth.countryCode === "SA" ? "#008000" : "#fff"}
      />
    </svg>
  );
}

export default DataManagementIcon;
