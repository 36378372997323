import { useEffect, useMemo, useState } from "react";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  useWatch,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import moment from "moment";
//material_ui
import {
  Box,
  TextField,
  MenuItem,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  Switch,
} from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Carousel from "react-carousel-mui";
//styles
import { useStylesLeads } from "../../../styles/Leads__styles";
import {
  useStylesContainers,
  ContainerFullWidth,
  ContainerLeft,
  LeftElement,
  ContainersContent,
} from "../../../styles/Containers__styles";
import { useStylesInput } from "../../../styles/Input__styles";
//components
import PageAddHeader from "../../pageAddHeader/pageAddHeader";
import PhoneInput from "../../layout/PhoneInput";
import AddressInputs from "../CustomerCommon/AddressInputs";
import PackCard from "../CustomerActions/PackCard";
//utils
import { countries } from "../../../staticData/staticData";
import {
  goalsOptions,
  weightOptions,
  heightOptions,
  weekDays,
  handleDays,
  ActivityOptions,
  calculateRecommendedCalories,
  isDayOff,
} from "../Utils";
import { PaymentList } from "../CustomerTransactions/Utils";
import { countryList } from "../../layout/PhoneInput/CountryList";
import { CustomPackageMeals, getMessageError } from "../../Utils";
import { numberOfDays } from "../../dataManagement/promoCode/Utils";
//types
import { customersProps } from "../Types";
import { ApolloError } from "@apollo/client";
//queries&mutations
import {
  GetAllCustomersProfileDocument,
  Gender,
  ConvertLeadToCustomerInput,
  PaymentMethod,
  useConvertLeadToCustomerMutation,
  useGetLeadByIdQuery,
  Weight,
  Height,
  LunchMeal,
  AdressType,
  WeightInput,
  HeightInput,
  PackPayload,
  useGetActiveProgramsQuery,
} from "../../../graphql/types";
//firebase analytics
import { logEvent } from "firebase/analytics";
//context api
import { useAuth } from "../../../contextAPI";
//utils
import { useCountryPrefix } from "../../../utils/Hooks/useCountryPrefix";

const ConvertedCustomer = () => {
  const navigate = useNavigate();
  const params = useParams();
  let auth = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const idLeadConverted = params.id || null;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //styles
  const ContainersClasses = useStylesContainers();
  const LeadsClasses = useStylesLeads();
  const InputClasses = useStylesInput();

  //state
  const [selectedPack, setSelectedPack] = useState<PackPayload>();

  //mutations & queries
  const [convertLead, { loading }] = useConvertLeadToCustomerMutation();
  const { data: dataLeadConverted, loading: loadingLead } = useGetLeadByIdQuery(
    {
      variables: {
        leadId: idLeadConverted || "",
      },
      skip: !idLeadConverted,
    }
  );

  const countryPrefix = useCountryPrefix();

  const { data: activeProgramsData } = useGetActiveProgramsQuery();

  const programs = useMemo(
    () => activeProgramsData?.getActivePrograms || [],
    [activeProgramsData?.getActivePrograms]
  );

  //react-hook-form
  const methods = useForm<customersProps>({
    defaultValues: {
      first__name: "",
      last__name: "",
      email: "",
      phone__number: "",
      phone__prefix: countryPrefix?.callingCode,
      nationality: "",
      gender: null,
      birth__date: null,
      weight: 0,
      weight__unit: "KG",
      height: 0,
      height__unit: "CM",
      influencer: false,
      activity: null,
      protein: 0,
      carbs: 0,
      fat: 0,
      addresses: [
        {
          address__name: "",
          area__name: "",
          building__name: "",
          street__name: "",
          aprt__number: "",
          floor__number: "",
          address__type: "",
          address__kind: [],
          delivery__number: "",
          delivery__prefix: countryPrefix?.callingCode,
          delivery__days: [
            {
              value: "monday",
              label: "Monday",
            },
            {
              value: "tuesday",
              label: "Tuesday",
            },
            {
              value: "wednesday",
              label: "Wednesday",
            },
            {
              value: "thursday",
              label: "Thursday",
            },
            {
              value: "Friday",
              label: "Friday",
            },
            {
              value: "saturday",
              label: "Saturday",
            },
            {
              value: "sunday",
              label: "Sunday",
            },
          ],
          zone: "",
          delivery__time: null,
        },
      ],
      address__name: "",
      area__name: "",
      building__name: "",
      street__name: "",
      aprt__number: "",
      floor__number: "",
      address__type: [],
      address__kind: [],
      delivery__number: "",
      delivery__prefix: countryPrefix?.callingCode,
      preferences: [],
      goals: null,
      quantity: "0",
      quantity__unit: "KG",
      is__free: false,
      program__name: null,
      plan__type: null,
      meals__duration: [],
      dayPerWeek: null,
      meals: [],
      start__date: null,
      paymentMethod: null,
      recommended__calories: "",
      promoCode: "",
      daysOff: [],
      isCustomPackage: false,
      customPackagesQuantities: {
        breakfast: 0,
        lunchDinner: 0,
        snack: 0,
      },
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  const NationalityWatch = useWatch({
    control,
    name: "nationality",
  });

  const ActivityWatch = useWatch({
    control,
    name: "activity",
  });

  //useEffect
  useEffect(() => {
    if (dataLeadConverted) {
      const {
        fname,
        lname,
        gender,
        email,
        nationality,
        phone,
        dob,
        influencer,
        height,
        weight,
        targetWeight,
        goal,
        calories,
      } = dataLeadConverted?.getLeadById ?? null;
      setValue("first__name", fname || "");
      setValue("last__name", lname || "");
      setValue("email", email || "");
      setValue("influencer", influencer || false);
      setValue("birth__date", dob || "");
      setValue("gender", gender || "MALE");
      if (nationality) {
        setValue(
          "nationality",
          countries?.find((el) => el === nationality) ?? ""
        );
      } else {
        const countryName = countryList.find(
          (el) => el.callingCode === phone?.prefix?.substring(1)
        );
        setValue("nationality", countryName?.name.common ?? "");
      }
      setValue("recommended__calories", calories || "");
      setValue("goals", goal || "");
      setValue("height", height?.value || "");
      setValue("height__unit", height?.unit || "CM");
      setValue("weight", weight?.value || "");
      setValue("weight__unit", weight?.unit || "KG");
      if (targetWeight?.value) {
        setValue("quantity", (targetWeight?.value || 0).toString() || "0");
      }
      setValue("quantity__unit", targetWeight?.unit || "KG");
      setValue("phone__number", phone?.number || "");
      setValue("phone__prefix", phone?.prefix?.replace("+", "") || "");
    }
  }, [dataLeadConverted, setValue]);

  //empty the list of meals when isCustomPackage is true
  useEffect(() => {
    if (dataWatch.isCustomPackage) {
      setSelectedPack(undefined);
    }
  }, [dataWatch.isCustomPackage]);

  //setting the payment method to null whenever isPaid is checked
  useEffect(() => {
    if (dataWatch.is__free) {
      setValue("paymentMethod", null);
    }
  }, [dataWatch.is__free]);

  //functions
  const updateMealsQuantityHandler = (
    config: "INCREMENT" | "DECREMENT",
    mealType: string,
    currentQuantity: number
  ) => {
    if (config === "INCREMENT") {
      currentQuantity++;
    } else {
      currentQuantity--;
    }
    if (mealType === "Breakfast") {
      setValue("customPackagesQuantities.breakfast", currentQuantity);
    } else if (mealType === "Lunch/Dinner") {
      setValue("customPackagesQuantities.lunchDinner", currentQuantity);
    } else if (mealType === "Snack") {
      setValue("customPackagesQuantities.snack", currentQuantity);
    }
  };

  const onSubmit: SubmitHandler<customersProps> = async (
    data: customersProps
  ) => {
    //if no pack was selected
    if (!selectedPack && !dataWatch.isCustomPackage) {
      enqueueSnackbar("Select a pack", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      return;
    }
    //restriction for create a new plan
    let startDateIsDayOff = false;
    if (data.start__date) {
      const daysOff = dataWatch.daysOff || [];
      startDateIsDayOff = isDayOff(data.start__date, daysOff);
    }
    if (startDateIsDayOff) {
      enqueueSnackbar(
        "Selected start date is a day off. Please choose another date.",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
      return;
    }
    //format custom package input
    let mealsArray: LunchMeal[] = [];
    let checkIsCustom = false;
    if (dataWatch.isCustomPackage) {
      checkIsCustom = true;
      if (data.customPackagesQuantities?.breakfast > 0) {
        mealsArray.push(
          ...[...Array(dataWatch.customPackagesQuantities?.breakfast)].fill(
            "BREAKFAST"
          )
        );
      }
      if (data.customPackagesQuantities?.lunchDinner > 0) {
        for (
          let i = 1;
          i < data.customPackagesQuantities?.lunchDinner + 1;
          i++
        ) {
          if (i % 2 === 0) {
            mealsArray.push("DINNER");
          } else {
            mealsArray.push("LUNCH");
          }
        }
      }
      if (data.customPackagesQuantities?.snack > 0) {
        for (let i = 1; i < data.customPackagesQuantities?.snack + 1; i++) {
          if (i % 2 === 0) {
            mealsArray.push("EVENING_SNACK");
          } else {
            mealsArray.push("MORNING_SNACK");
          }
        }
      }
    } else {
      mealsArray = [...(selectedPack?.meals || [])];
    }
    try {
      //test that the custom plan should have at least 3 meals
      if (mealsArray.length < 3 && checkIsCustom) {
        enqueueSnackbar(
          "Your need to have at least 3 meals for a custom plan",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }
        );
        return;
      }
      //test whether the combination between days per week and days off is correct
      if (
        (dataWatch.dayPerWeek === "FIVE_DAYS" &&
          dataWatch?.daysOff?.length != 2) ||
        (dataWatch.dayPerWeek === "SIX_DAYS" &&
          dataWatch?.daysOff?.length != 1) ||
        (dataWatch.dayPerWeek === "SEVEN_DAYS" &&
          dataWatch?.daysOff?.length != 0)
      ) {
        enqueueSnackbar(
          "Please Verify the combination between Days per week and Days off",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }
        );
        return;
      }

      const phone = data?.phone__number?.replace(/\s/g, "") ?? "";
      const customerInputdata: ConvertLeadToCustomerInput = {
        _id: idLeadConverted || "",
        fname: data?.first__name,
        lname: data?.last__name,
        email: data?.email,
        dob: new Date(data?.birth__date),
        gender: data?.gender as Gender,
        calories: data?.recommended__calories,
        height: {
          unit: data?.height__unit,
          value: Number(data?.height),
        },
        weight: {
          unit: data?.weight__unit,
          value: Number(data?.weight),
        },
        phone: {
          prefix: `+${data.phone__prefix}`,
          number: phone,
        },
        influencer: data?.influencer,
        nationality: data?.nationality,
        addresses: data.addresses.map((el) => ({
          nick: el?.address__name,
          build: el?.building__name,
          nbrApt: el?.aprt__number,
          street: el?.street__name,
          area: el?.area__name,
          floor: el?.floor__number,
          type: el?.address__type as AdressType,
          daysOfTheWeek: [],
          deliveryPhone: {
            prefix: `+${el?.delivery__prefix}`,
            number: el?.delivery__number.replace(/\s/g, ""),
          },
          zone: el.zone,
          deliveryTime: el.delivery__time,
        })),

        plan: {
          meals: mealsArray,
          program: data?.program__name?._id || "",
          paymentPeriod: data?.meals__duration,
          nbrOfDays: data?.dayPerWeek,
          startDate: new Date(data.start__date),
          paymentMethod: data?.paymentMethod as PaymentMethod,
          withDelivery: true,
          isFree: data?.is__free,
          promoCode: data.promoCode,
          daysOff: data.daysOff,
          isCustom: data.isCustomPackage,
        },
        goal: data.goals,
      };

      //send firebase analytics event
      logEvent(auth.analytics, "Selected_Pack", {
        packName: checkIsCustom ? "Custom package" : mealsArray.toString(),
        meals: mealsArray.toString(),
      });

      await convertLead({
        variables: {
          convertLeadToCustomerInput: customerInputdata,
        },
        refetchQueries: [
          {
            query: GetAllCustomersProfileDocument,
            variables: { input: { page: 1, documentsPerPage: 10 } },
          },
        ],
        onCompleted: () => {
          navigate("/admin/customers/list_customers");
          enqueueSnackbar("Lead succuessfully converted to Customer", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  //set the recommanded values
  useEffect(() => {
    if (
      dataWatch.gender &&
      dataWatch.activity &&
      dataWatch.weight &&
      dataWatch.weight__unit &&
      dataWatch.height &&
      dataWatch.height__unit &&
      dataWatch.birth__date &&
      dataWatch.goals &&
      dataWatch.program__name
    ) {
      const { calories, carb, fat, prot } = calculateRecommendedCalories(
        dataWatch.gender,
        {
          unit: dataWatch.weight__unit,
          value: dataWatch.weight,
        } as WeightInput,
        {
          unit: dataWatch.height__unit,
          value: dataWatch.height,
        } as HeightInput,
        moment().diff(moment(dataWatch.birth__date), "years"),
        dataWatch.activity.menuValue,
        dataWatch?.program__name?.name?.EN || "",
        dataWatch.goals
      );

      setValue("protein", +prot);
      setValue("carbs", +carb);
      setValue("fat", +fat);
      setValue("recommended__calories", calories);
    }
  }, [
    dataWatch.gender,
    dataWatch.activity,
    dataWatch.weight,
    dataWatch.weight__unit,
    dataWatch.height,
    dataWatch.height__unit,
    dataWatch.birth__date,
    dataWatch.goals,
    dataWatch.program__name,
  ]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title="Convert Lead to  Customer"
          buttonText="Convert Lead"
          loading={loading}
          cancelButtonLink="/admin/customers/list_customers"
        />
        <ContainersContent>
          <ContainerFullWidth>
            <FormProvider {...methods}>
              <ContainerLeft>
                {/* Personal Informations */}
                <LeftElement>
                  <p className={clsx(ContainersClasses.section__title)}>
                    Personal Informations
                  </p>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        First Name*
                      </p>
                      <TextField
                        placeholder="First Name"
                        sx={{ width: "100%" }}
                        {...register("first__name", {
                          required: "Enter Customer First Name",
                          minLength: {
                            value: 2,
                            message: "Please enter a least 2 characters",
                          },
                        })}
                        disabled={loadingLead}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.first__name?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Last Name*
                      </p>
                      <TextField
                        placeholder="Last Name"
                        sx={{ width: "100%" }}
                        {...register("last__name", {
                          required: "Enter Customer Last Name",
                          minLength: {
                            value: 2,
                            message: "Please enter a least 2 characters",
                          },
                        })}
                        disabled={loadingLead}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.last__name?.message}
                      </p>
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Email
                      </p>
                      <TextField
                        placeholder="Email"
                        sx={{ width: "100%" }}
                        {...register("email", {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Enter a valid Email",
                          },
                          required: "Email is Mandatory",
                        })}
                        disabled={loadingLead}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.email?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Phone Number*
                      </p>
                      <PhoneInput
                        labelFormPrefix="phone__prefix"
                        labelFormNumber="phone__number"
                        placeholder="Phone Number"
                        loading={loadingLead}
                      />
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Nationality
                      </p>
                      <Controller
                        name="nationality"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            {...rest}
                            freeSolo
                            options={countries}
                            value={NationalityWatch}
                            onChange={(_, data) => Change(data)}
                            getOptionLabel={(option) => option}
                            disabled={loadingLead}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputRef={ref}
                                variant="outlined"
                                sx={{ width: "100%" }}
                                placeholder="Nationality"
                              />
                            )}
                          />
                        )}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.nationality?.message}
                      </p>
                    </Box>

                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Birth Date
                      </p>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <Controller
                          name="birth__date"
                          control={control}
                          rules={{
                            validate: (value) =>
                              value === null ||
                              moment(value).isValid() ||
                              "Enter a valid Birth Date",
                          }}
                          render={({ field: { ref, ...rest } }) => (
                            <DesktopDatePicker
                              disabled={loadingLead}
                              {...rest}
                              inputFormat="DD/MM/YYYY"
                              disablePast={false}
                              disableFuture={true}
                              renderInput={(params) => (
                                <TextField {...params} sx={{ width: "100%" }} />
                              )}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.birth__date?.message}
                      </p>
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Weight
                      </p>

                      <TextField
                        placeholder="Weigth"
                        sx={{ width: "70%" }}
                        type="number"
                        {...register("weight", {
                          pattern: {
                            value: /^\d*$/,
                            message: "Enter a valid Weigth",
                          },
                        })}
                        disabled={loadingLead}
                      />
                      <Controller
                        name="weight__unit"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "28%", marginLeft: "3px" }}
                            className={LeadsClasses.select_container}
                            disabled={loadingLead}
                          >
                            {weightOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue(
                                    "weight__unit",
                                    option.menuValue as Weight
                                  )
                                }
                                value={option.menuValue}
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Height
                      </p>
                      <TextField
                        placeholder="Height"
                        sx={{ width: "70%" }}
                        type="number"
                        {...register("height", {
                          pattern: {
                            value: /^\d*$/,
                            message: "Enter a valid Height",
                          },
                        })}
                        disabled={loadingLead}
                      />
                      <Controller
                        name="height__unit"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "28%", marginLeft: "3px" }}
                            className={LeadsClasses.select_container}
                            disabled={loadingLead}
                          >
                            {heightOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue(
                                    "height__unit",
                                    option.menuValue as Height
                                  )
                                }
                                value={option.menuValue}
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Gender
                      </p>
                      <Controller
                        name="gender"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                        }) => (
                          <RadioGroup value={dataWatch?.gender} row>
                            <FormControlLabel
                              disabled={loadingLead}
                              value="MALE"
                              control={<Radio />}
                              label="Male"
                              onClick={() => setValue("gender", "MALE")}
                            />
                            <FormControlLabel
                              disabled={loadingLead}
                              value="FEMALE"
                              control={<Radio />}
                              label="Female"
                              onClick={() => setValue("gender", "FEMALE")}
                            />
                          </RadioGroup>
                        )}
                      />

                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.gender?.message}
                      </p>
                    </Box>

                    <FormControlLabel
                      sx={{ width: "100%", paddingTop: "35px" }}
                      control={
                        <Checkbox
                          disabled={loadingLead}
                          checked={dataWatch.influencer}
                          color="secondary"
                          {...register("influencer")}
                        />
                      }
                      label="Influencer"
                    />
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Activity
                      </p>
                      <Controller
                        name="activity"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            {...rest}
                            freeSolo
                            options={ActivityOptions}
                            value={ActivityWatch}
                            onChange={(_, data) => Change(data)}
                            getOptionLabel={(option) => option.menuText}
                            disabled={loadingLead}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputRef={ref}
                                variant="outlined"
                                sx={{ width: "100%" }}
                                placeholder="Activity"
                              />
                            )}
                          />
                        )}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.activity?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Goals
                      </p>
                      <Controller
                        name="goals"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "100%" }}
                            disabled={loadingLead}
                            className={LeadsClasses.select_container}
                          >
                            {goalsOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue("goals", option.menuValue)
                                }
                                value={option.menuValue}
                                className={
                                  dataWatch.goals === option.menuValue
                                    ? clsx(
                                        LeadsClasses.option_item,
                                        LeadsClasses.option_item_selected
                                      )
                                    : LeadsClasses.option_item
                                }
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.nationality?.message}
                      </p>
                    </Box>
                  </Box>
                </LeftElement>
                {/* Location Details */}
                <LeftElement>
                  <p className={clsx(ContainersClasses.section__title)}>
                    Location Details
                  </p>
                  <AddressInputs />
                </LeftElement>
              </ContainerLeft>
              <Box width="70%">
                {/* Meal Plan */}
                <Box>
                  <p className={clsx(ContainersClasses.section__title)}>
                    Meal plan
                  </p>
                  <Box display="grid" gridTemplateColumns="1fr 2fr" gap={4}>
                    {/* Program */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Program
                      </p>
                      <Controller
                        name="program__name"
                        rules={{
                          required: "Select Program",
                        }}
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <Autocomplete
                              ref={ref}
                              id="program__name"
                              options={programs || []}
                              value={dataWatch?.program__name as any}
                              getOptionLabel={(option) => option.name?.EN || ""}
                              isOptionEqualToValue={(option, value) =>
                                option._id === value._id
                              }
                              filterSelectedOptions
                              onChange={(_, data) => Change(data)}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    color="secondary"
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name?.EN}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Dish Program"
                                />
                              )}
                            />
                            <p
                              className={
                                ContainersClasses.section__error__helper
                              }
                            >
                              {error?.message}
                            </p>
                          </>
                        )}
                      />
                    </Box>
                  </Box>

                  {/* PACKS */}
                  {!dataWatch.isCustomPackage && (
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Packs
                      </p>
                      <Carousel
                        items={
                          dataWatch.program__name?.packs?.filter((el) => {
                            return el.isActive;
                          }) || []
                        }
                        itemsPerPage={{
                          xs: 2,
                          sm: 2,
                          tablet: 2,
                          md: 3,
                          lg: 3,
                          xl: 3,
                        }}
                        itemRenderer={(item) => (
                          <PackCard
                            pack={item}
                            selected={selectedPack}
                            setSelectedPack={setSelectedPack as any}
                          />
                        )}
                      />
                    </Box>
                  )}

                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr"
                    rowGap={0}
                    columnGap={4}
                  >
                    {/* Number of days */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Number of days
                      </p>
                      <Controller
                        name="dayPerWeek"
                        rules={{
                          required: "Select number of days",
                        }}
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <Autocomplete
                              ref={ref}
                              id="dayPerWeek"
                              options={numberOfDays}
                              value={value}
                              filterSelectedOptions
                              onChange={(_, data) => Change(data)}
                              getOptionLabel={(option) => option}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    color="secondary"
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Number of days"
                                />
                              )}
                            />
                            <p
                              className={
                                ContainersClasses.section__error__helper
                              }
                            >
                              {error?.message}
                            </p>
                          </>
                        )}
                      />
                    </Box>
                    {/* Days off */}
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Days off
                      </p>
                      <Controller
                        name="daysOff"
                        control={control}
                        render={({
                          field: { onChange: Change, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <Autocomplete
                              {...rest}
                              multiple
                              id="daysOff"
                              value={dataWatch.daysOff || []}
                              options={weekDays}
                              getOptionLabel={(option) =>
                                handleDays(option) ?? ""
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputRef={ref}
                                  variant="outlined"
                                  sx={{ minWidth: "400px" }}
                                />
                              )}
                              onChange={(_, data) => {
                                Change(data);
                              }}
                            />
                            <p className={InputClasses.input__error__helper}>
                              {error?.message}
                            </p>
                          </>
                        )}
                      />
                    </Box>
                    {/* Custom Package details */}
                    {dataWatch.isCustomPackage ? (
                      <Box width="70%">
                        {CustomPackageMeals?.map((elem) => {
                          let quantity = 1;
                          if (elem === "Breakfast") {
                            quantity =
                              dataWatch?.customPackagesQuantities?.breakfast ||
                              0;
                          } else if (elem === "Lunch/Dinner") {
                            quantity =
                              dataWatch?.customPackagesQuantities
                                ?.lunchDinner || 0;
                          } else if (elem === "Snack") {
                            quantity =
                              dataWatch?.customPackagesQuantities?.snack || 0;
                          }
                          return (
                            <Box
                              key={elem}
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              marginTop={1}
                            >
                              <Box
                                fontFamily="Poppins"
                                fontSize={14}
                                fontWeight="500"
                                width={200}
                              >
                                {elem}
                              </Box>
                              <Box
                                height={25}
                                width={35}
                                borderRadius={12}
                                bgcolor={
                                  !dataWatch.isCustomPackage
                                    ? "#C4C4C4"
                                    : "#000"
                                }
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                color="#fff"
                                sx={{
                                  cursor: !dataWatch.isCustomPackage
                                    ? "default"
                                    : "pointer",
                                  "&:hover": {
                                    backgroundColor: "#C4C4C4",
                                    transition: "0.3s",
                                  },
                                }}
                                onClick={() =>
                                  quantity === 0
                                    ? () => null
                                    : updateMealsQuantityHandler(
                                        "DECREMENT",
                                        elem,
                                        quantity
                                      )
                                }
                              >
                                -
                              </Box>
                              <Box
                                height={25}
                                width={40}
                                borderRadius={1}
                                bgcolor="#C4C4C4"
                                marginLeft={1}
                                marginRight={1}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                {quantity}
                              </Box>
                              <Box
                                height={25}
                                width={35}
                                borderRadius={12}
                                bgcolor={
                                  !dataWatch.isCustomPackage
                                    ? "#C4C4C4"
                                    : "#000"
                                }
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                color="#fff"
                                sx={{
                                  cursor: !dataWatch.isCustomPackage
                                    ? "default"
                                    : "pointer",
                                  "&:hover": {
                                    backgroundColor: "#C4C4C4",
                                    transition: "0.3s",
                                  },
                                }}
                                onClick={() =>
                                  updateMealsQuantityHandler(
                                    "INCREMENT",
                                    elem,
                                    quantity
                                  )
                                }
                              >
                                +
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                    {/* Duration */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Duration
                      </p>
                      <Controller
                        name="meals__duration"
                        rules={{
                          required: "Select Duration",
                        }}
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <Autocomplete
                              ref={ref}
                              id="meals__duration"
                              options={
                                dataWatch.program__name?.paymentPeriods || []
                              }
                              value={value}
                              filterSelectedOptions
                              onChange={(_, data) => Change(data)}
                              getOptionLabel={(option) => option}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    color="secondary"
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Duration" />
                              )}
                            />
                            <p
                              className={
                                ContainersClasses.section__error__helper
                              }
                            >
                              {error?.message}
                            </p>
                          </>
                        )}
                      />
                    </Box>
                    {/* Start date */}
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Start date
                      </p>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <Controller
                          name="start__date"
                          control={control}
                          render={({
                            field: { ref, ...rest },
                            fieldState: { error },
                          }) => (
                            <>
                              <DesktopDatePicker
                                {...rest}
                                inputFormat="DD/MM/YYYY"
                                disablePast={false}
                                disableFuture={false}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ minWidth: "400px" }}
                                  />
                                )}
                              />
                              <p
                                className={clsx(
                                  ContainersClasses.section__error__helper
                                )}
                              >
                                {error?.message}
                              </p>
                            </>
                          )}
                          rules={{
                            required: {
                              message: "Field Required",
                              value: true,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    {/* is custom Package */}
                    <Box>
                      <Controller
                        name="isCustomPackage"
                        control={control}
                        render={({ field: { onChange, ref } }) => {
                          return (
                            <FormControlLabel
                              checked={dataWatch?.isCustomPackage}
                              onChange={(_, value) => {
                                onChange(value);
                              }}
                              value="start"
                              control={<Checkbox color="secondary" />}
                              label="Custom Package"
                              labelPlacement="start"
                              sx={{ marginLeft: 5.5, marginTop: "auto" }}
                            />
                          );
                        }}
                      />
                    </Box>
                    {/* Promo code */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Promo code
                      </p>
                      <TextField
                        id="demo-helper-text-misaligned"
                        placeholder="Promo code"
                        fullWidth
                        {...register("promoCode")}
                      />
                    </Box>
                    {/* Payment Method */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Payment method
                      </p>
                      <Controller
                        name="paymentMethod"
                        rules={{
                          required: !dataWatch.is__free
                            ? "Select Payment method"
                            : undefined,
                        }}
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <Autocomplete
                              ref={ref}
                              id="paymentMethod"
                              options={PaymentList}
                              disabled={dataWatch.is__free}
                              value={value}
                              filterSelectedOptions
                              onChange={(_, data) => Change(data)}
                              getOptionLabel={(option) => option}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    color="secondary"
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Payment Method"
                                />
                              )}
                            />
                            <p
                              className={
                                ContainersClasses.section__error__helper
                              }
                            >
                              {error?.message}
                            </p>
                          </>
                        )}
                      />
                    </Box>
                    {/* is free */}
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <FormControlLabel
                        style={{ marginTop: 30 }}
                        control={
                          <Switch
                            {...register("is__free")}
                            checked={dataWatch.is__free}
                            color="secondary"
                          />
                        }
                        label="Is Free"
                      />
                    </Box>
                  </Box>
                </Box>
                {/* Recommanded */}
                <Box marginTop={5}>
                  <p className={clsx(ContainersClasses.section__title)}>
                    Recommanded
                  </p>
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr 1fr"
                    gap={2}
                  >
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Recommeded Calories <span>Kcals</span>
                      </p>
                      <TextField
                        placeholder="Recommeded Calories"
                        sx={{ width: "100%" }}
                        disabled={loadingLead}
                        {...register("recommended__calories", {})}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.recommended__calories?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Recommeded Protein <span>g</span>
                      </p>
                      <TextField
                        placeholder="Recommeded Protein"
                        sx={{ width: "100%" }}
                        disabled={loadingLead}
                        {...register("protein", {})}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.recommended__calories?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Recommeded Carbs <span>g</span>
                      </p>
                      <TextField
                        placeholder="Recommeded Carbs"
                        sx={{ width: "100%" }}
                        disabled={loadingLead}
                        {...register("carbs", {})}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.recommended__calories?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Recommeded Fat <span>g</span>
                      </p>
                      <TextField
                        placeholder="Recommeded Fat"
                        sx={{ width: "100%" }}
                        disabled={loadingLead}
                        {...register("fat", {})}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.recommended__calories?.message}
                      </p>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </FormProvider>
          </ContainerFullWidth>
        </ContainersContent>
      </form>
    </FormProvider>
  );
};

export default ConvertedCustomer;
