import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
//material-ui
import { Button, Chip, Avatar, Box, Rating } from "@mui/material";
//Utils
import { SIDE_FILTER_OPTIONS_DISHES, columns } from "./Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//components
import Table from "../../layout/commun/Table";
import FilterHeader from "../../pageHeader/FilterHeader";
//mutation & querys
import {
  LunchMeal,
  useGetListFavouritesBycustomerDashQuery,
} from "../../../graphql/types";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesForms } from "../../../styles/Forms__styles";

const CustomerFavourites = () => {
  const { id } = useParams();
  const isAuthorizedToSeeDetails = useIsAuthorized("FOOD_DISHS_DETAILS");

  //state
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [dishSuitability, setDishSuitability] = useState<LunchMeal[]>([]);
  const [idsProgram, setIdsProgram] = useState<string[]>([]);

  //styles
  const FormsClasses = useStylesForms();

  //queries
  const favouritesData = useGetListFavouritesBycustomerDashQuery({
    variables: {
      favouriteInput: {
        customerId: id ?? "",
        name: search,
        dishSuitability: dishSuitability,
      },
    },
    fetchPolicy: "network-only",
  });
  const favourites = useMemo(
    () => favouritesData.data?.getListFavouritesBycustomerDash.dishes || [],
    [favouritesData.data]
  );
  const count = useMemo(() => favourites?.length || 0, [favourites]);

  //functions
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  //render
  const renderTableRows = () => {
    return favourites.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        id: item.ref || "--",
        photoUrl: (
          <Avatar
            alt={item?.name?.EN || ""}
            src={item?.pictureUrl || ""}
            variant="rounded"
            style={{ width: 50, height: 50, margin: "0 auto" }}
          />
        ),
        name: isAuthorizedToSeeDetails ? (
          <a
            style={{ textDecoration: "none" }}
            href={`/food/dishes/view_dish/${item._id}`}
          >
            <Button>{item?.name?.EN || ""}</Button>
          </a>
        ) : (
          item.name?.EN
        ),
        tags:
          item?.tags && item?.tags.length
            ? item?.tags
                .filter((_, index: number) => index < 3)
                .map((elem, i) => (
                  <React.Fragment key={elem}>
                    <Chip
                      key={elem}
                      label={elem.toUpperCase()}
                      variant="outlined"
                      className={FormsClasses.chips__tags}
                    />
                    {item?.tags && item?.tags?.length - 1 > 2 && i === 2 && (
                      <Chip
                        key={elem}
                        label={`+ ${item?.tags?.length}`}
                        variant="outlined"
                        className={FormsClasses.chips__tags}
                      />
                    )}
                  </React.Fragment>
                ))
            : "--",
        rating: (
          <Box>
            <Rating
              name="half-rating"
              defaultValue={item?.rating?.average ? item.rating.average : 0}
              precision={0.1}
              size="small"
              readOnly
            />
            <Box>({item?.rating?.count ? item.rating.count : 0})</Box>
          </Box>
        ),
        mealType: item.dishSuitability,
        description: (
          <span
            style={{
              width: "20px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item?.description?.EN || "No description"}
          </span>
        ),
      };
    });
  };

  return (
    <>
      <Box margin={2} marginTop={5}>
        <FilterHeader
          setSearchValue={setSearch}
          showSearch={true}
          setIdsProgram={setIdsProgram}
          programIdData={idsProgram}
          filterConfig={SIDE_FILTER_OPTIONS_DISHES}
          hideProgram={true}
          hideDateAndTitle={true}
          hideExcel={true}
          dishSuitability={dishSuitability}
          setDishSuitability={setDishSuitability}
        />
      </Box>
      <TableContainer>
        <Table
          columns={columns}
          loading={favouritesData.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No favorites here yet!"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
    </>
  );
};

export default CustomerFavourites;
