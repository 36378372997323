import { ColumnsProps } from "../layout/commun/Table";
import {
  Activity,
  BreakReason,
  DeliveryStatus,
  FreezHistoryPayload,
  Gender,
  Height,
  HeightInput,
  LeadStatus,
  Meals,
  NumberOfDays,
  PackedStatus,
  PlanCondition,
  ReminderStatus,
  RewardsActionEnum,
  Weight,
  WeightInput,
} from "../../graphql/types";
import moment from "moment";
import { URLFILE } from "../../utils/config";
import saveAs from "file-saver";
/* Add/Update Customer */
export const days = [
  {
    value: "monday",
    label: "Monday",
  },
  {
    value: "tuesday",
    label: "Tuesday",
  },
  {
    value: "wednesday",
    label: "Wednesday",
  },
  {
    value: "thursday",
    label: "Thursday",
  },
  {
    value: "Friday",
    label: "Friday",
  },
  {
    value: "saturday",
    label: "Saturday",
  },
  {
    value: "sunday",
    label: "Sunday",
  },
];
export const weekDays = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];
export const paymentMethod = [
  {
    menuValue: "DEBIT_CARD",
    menuText: "Debit Card",
  },
  {
    menuValue: "CREDIT_CARD",
    menuText: "Credit Card",
  },
  {
    menuValue: "CASH",
    menuText: "Cash",
  },
];

export const handleDays = (day: string) => {
  return day[0].toUpperCase() + day.slice(1).toLowerCase();
};

export const preferences = [
  {
    menuValue: "classic",
    menuText: "Classic",
  },
  {
    menuValue: "keto",
    menuText: "Keto",
  },
  {
    menuValue: "gluten_free",
    menuText: "Gluten free",
  },
  {
    menuValue: "dairy_free",
    menuText: "Dairy Free",
  },
  {
    menuValue: "diabetic",
    menuText: "Diabetic",
  },
  {
    menuValue: "hypertension",
    menuText: "Hypertension",
  },
  {
    menuValue: "pregnant",
    menuText: "Pregnant",
  },
  {
    menuValue: "maternity",
    menuText: "Maternity",
  },
  {
    menuValue: "vegetarian",
    menuText: "Vegetarian",
  },
  {
    menuValue: "no_Preferences",
    menuText: "No Preferences",
  },
];
export const goalsOptions = [
  {
    menuText: "Weight Loss",
    menuValue: "LOSE_WEIGHT",
    selected: false,
  },
  {
    menuText: "Maintain weight",
    menuValue: "MAINTAIN_WEIGHT",
    selected: false,
  },
  {
    menuText: "Build Muscle",
    menuValue: "BUILD_MUSCLE",
    selected: false,
  },
];
export const kindOptions = [
  {
    menuText: "Home",
    menuValue: "HOME",
    selected: false,
  },
  {
    menuText: "Office",
    menuValue: "OFFICE",
    selected: false,
  },
];
export const typeOptions = [
  {
    menuText: "Apartment",
    menuValue: "APARTMENT",
    selected: false,
  },
  {
    menuText: "House",
    menuValue: "HOUSE",
    selected: false,
  },
  {
    menuText: "Industrial",
    menuValue: "INDUSTRIAL",
    selected: false,
  },
];
export const mealsOptions: {
  meal: string;
  value:
    | "ADD_MAIN"
    | "ADD_SNACK"
    | "BREAKFAST"
    | "DINNER"
    | "EVENING_SNACK"
    | "LUNCH"
    | "MORNING_SNACK";
  active: false;
}[] = [
  {
    meal: "Breakfast",
    value: "BREAKFAST",
    active: false,
  },
  {
    meal: "First Snack",
    value: "MORNING_SNACK",
    active: false,
  },
  {
    meal: "Lunch",
    value: "LUNCH",
    active: false,
  },
  {
    meal: "Second Snack",
    value: "EVENING_SNACK",
    active: false,
  },
  {
    meal: "Dinner",
    value: "DINNER",
    active: false,
  },
  {
    meal: "Add Main",
    value: "ADD_MAIN",
    active: false,
  },
  {
    meal: "Add Snack",
    value: "ADD_SNACK",
    active: false,
  },
];
export const durationOptions = [
  {
    menuText: "Weekly",
    menuValue: "WEEKLY",
    selected: false,
  },
  {
    menuText: "Monthly",
    menuValue: "MONTHLY",

    selected: false,
  },
  {
    menuText: "One Day",
    menuValue: "ONE_DAY",
    selected: false,
  },
  {
    menuText: "Two Weeks",
    menuValue: "TWO_WEEKS",
    selected: false,
  },
  {
    menuText: "Three Weeks",
    menuValue: "THREE_WEEKS",
    selected: false,
  },
  {
    menuText: "Two Months",
    menuValue: "TWO_MONTHS",
    selected: false,
  },
  {
    menuText: "Three Months",
    menuValue: "THREE_MONTHS",
    selected: false,
  },
  {
    menuText: "Three Months + one free month",
    menuValue: "FOUR_MONTHS",
    selected: false,
  },
];
export const nbrDaysOptions = [
  {
    menuText: "5 Days",
    menuValue: "FIVE_DAYS",
    selected: false,
  },
  {
    menuText: "6 Days",
    menuValue: "SIX_DAYS",
    selected: false,
  },
  {
    menuText: "7 Days",
    menuValue: "SEVEN_DAYS",
    selected: false,
  },
];

export const weightOptions = [
  {
    menuText: "Kg",
    menuValue: "KG",
    selected: false,
  },
  {
    menuText: "Lbs",
    menuValue: "LBS",
    selected: false,
  },
];
export const heightOptions = [
  {
    menuText: "cm",
    menuValue: "CM",
    selected: false,
  },
  {
    menuText: "feet",
    menuValue: "FEET",
    selected: false,
  },
];

/* List Customers */
export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Customer Name",
    accessor: "name",
  },
  {
    header: "Plan Details",
    accessor: "details",
  },
  {
    header: "Duration",
    accessor: "duration",
  },
  {
    header: "Start Date / End Date",
    accessor: "start__date",
  },
  {
    header: "Assigned to",
    accessor: "assignedTo",
  },
  {
    header: "No of boxes",
    accessor: "NumberOfBoxes",
  },
  {
    header: "Mobile No",
    accessor: "mobile",
  },
  {
    header: "OTP",
    accessor: "otp__number",
  },
  {
    header: "Plan Status",
    accessor: "customer__status",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
export const handleStatus = (
  kind:
    | PlanCondition
    | LeadStatus
    | undefined
    | null
    | "CUSTOMER"
    | DeliveryStatus
    | PackedStatus
    | ReminderStatus
) => {
  return kind
    ? kind
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/\b/, (l) => l.toUpperCase())
    : null;
};

/* Customer List Allergies */
export const allColumns: ColumnsProps = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Allergy",
    accessor: "name",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
/* Customer List Appointment */
export const appColumns: ColumnsProps = [
  {
    header: "Appointment Date",
    accessor: "date",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Note",
    accessor: "note",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
export const filterApp = [
  {
    menuText: "Upcoming Appointment",
    menuValue: "Upcoming Appointment",
    selected: false,
  },
  {
    menuText: "Previous Appointment",
    menuValue: "Previous Appointment",
    selected: false,
  },
];
/*Customer List Freeze */
export const freezeColumns: ColumnsProps = [
  {
    header: "Appointment Date",
    accessor: "date",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
export const filterFreeze = [
  {
    menuText: "Upcoming Freezing",
    menuValue: "Upcoming Freezing",
    selected: false,
  },
  {
    menuText: "Previous Freezing",
    menuValue: "Previous Freezing",
    selected: false,
  },
];
/* Customer List Complaints */
export const compColumns: ColumnsProps = [
  {
    header: "Complaint Date",
    accessor: "date",
  },
  {
    header: "Complaint Type",
    accessor: "type",
  },
  {
    header: "Action Taken",
    accessor: "action",
  },
  {
    header: "Authorized Reviewers",
    accessor: "review",
  },
  {
    header: "Time",
    accessor: "time",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
export const filterComp = [
  {
    menuText: "Complaint Date",
    menuValue: "Complaint Date",
    selected: false,
  },
  {
    menuText: "Complaint Type",
    menuValue: "Complaint Type",
    selected: false,
  },
  {
    menuText: "Complaint Status",
    menuValue: "Complaint Status",
    selected: false,
  },
];
/* Customer List Feedback */
export const feedColumns: ColumnsProps = [
  {
    header: "Dish",
    accessor: "dish",
  },
  {
    header: "Feedback",
    accessor: "feedback",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "Time",
    accessor: "time",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
/* Customer List Progess */
export const progessColumns: ColumnsProps = [
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "Weight",
    accessor: "weight",
  },
  {
    header: "Skeletal Muscle Mass",
    accessor: "muscle",
  },
  {
    header: "Body Fat Mass",
    accessor: "body",
  },
  {
    header: "Visceral Fat level",
    accessor: "level",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
export const filterProgess = [
  {
    menuText: "Progress Date",
    menuValue: "Progress Date",
    selected: false,
  },
  {
    menuText: "Customer Weight",
    menuValue: "Customer Weight",
    selected: false,
  },
];
/* Customer Payment*/
export const payColumns: ColumnsProps = [
  {
    header: "Start Date",
    accessor: "startDate",
  },
  {
    header: "End Date",
    accessor: "endDate",
  },
  {
    header: "Program Name",
    accessor: "plan",
  },
  {
    header: "Number of boxes",
    accessor: "nbrOfBoxes",
  },
  {
    header: "Pricing Plan",
    accessor: "duration",
  },
  {
    header: "Plan Status",
    accessor: "status",
  },
  {
    header: "Nbr of Days/Week",
    accessor: "days",
  },
  {
    header: "Plan Price",
    accessor: "price",
  },

  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
/* Customer Delivery Address */
export const addColumns: ColumnsProps = [
  {
    header: "Area",
    accessor: "area",
  },
  {
    header: "Address Name",
    accessor: "nick",
  },
  {
    header: "Delivery Phone",
    accessor: "phone",
  },
  {
    header: "Delivery Time",
    accessor: "time",
  },
  {
    header: "Friday Delivery Driver",
    accessor: "fridayDriver",
  },
  {
    header: "Delivery Driver",
    accessor: "driver",
    cellProps: { align: "center", width: "280px" },
  },
  {
    header: "Days",
    accessor: "days",
    cellProps: { align: "center", width: "30px" },
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
/*Customer Renewals*/
export const renewColumns: ColumnsProps = [
  {
    header: "Plan",
    accessor: "plan__status",
  },
  {
    header: "Plan Name",
    accessor: "plan__name",
  },
  {
    header: "Plan Price",
    accessor: "plan__price",
  },
  {
    header: "Expiration Date",
    accessor: "plan__expiration",
  },
  {
    header: "Renewal",
    accessor: "plan__action",
  },
];

/* Customer Transactions*/
export const transactions: ColumnsProps = [
  {
    header: "Id Transaction",
    accessor: "idTransaction",
  },
  {
    header: "Plan Name",
    accessor: "planName",
  },
  {
    header: "Meals",
    accessor: "meals",
  },
  {
    header: "Frequency",
    accessor: "frequency",
  },
  {
    header: "Duration",
    accessor: "duration",
  },
  {
    header: "Price",
    accessor: "price",
  },
  {
    header: "Promo code",
    accessor: "promoCode",
  },
  {
    header: "Second Promo code",
    accessor: "secondpromoCode",
  },
  {
    header: "Balance",
    accessor: "balance",
  },
  {
    header: "Method",
    accessor: "method",
  },
  {
    header: "P.start Date",
    accessor: "startDate",
  },
  {
    header: "Trans. Date",
    accessor: "transDate",
  },
  {
    header: "Trasferred",
    accessor: "transferred",
  },
  {
    header: "Kind",
    accessor: "kind",
  },
  {
    header: "Status",
    accessor: "status",
  },

  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];

/* List Customers */
export const columnsPauses: ColumnsProps = [
  {
    header: "Starting Date",
    accessor: "starting_date",
  },
  {
    header: "Ending Date",
    accessor: "ending_date",
  },
  {
    header: "Resuming Date",
    accessor: "resuming_date",
  },
  {
    header: "Reason",
    accessor: "reason",
  },
  {
    header: "Status",
    accessor: "status",
    cellProps: { align: "center", width: "30px" },
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
export const getCustomerLoggerToExport = async (
  customerId: string,
  authToken: string
) => {
  try {
    const href = `${URLFILE}/report/customerlog/${customerId}`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    await fetch(href, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((blobData) => {
        saveAs(blobData, "customer.xlsx");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  } catch (error) {
    throw new Error("Error when get customerlog");
  }
};

export const convertNumberOfDaysEnum = (numberOfDays: NumberOfDays) => {
  if (numberOfDays === "FIVE_DAYS") {
    return "5 days";
  } else if (numberOfDays === "SIX_DAYS") {
    return "6 days";
  } else {
    return "7 days";
  }
};

export const isDayAFreeze = (
  period: FreezHistoryPayload[] | undefined,
  currentDate: Date
) => {
  let freezeFound = false;
  let freezeId = "";
  period?.map((el) => {
    const result = moment(currentDate).isBetween(
      moment(el.from),
      moment(el.to),
      "hour",
      "[]"
    );
    if (result) {
      freezeFound = true;
      freezeId = el._id;
    }
  });
  return { freezeFound: freezeFound, freezeId: freezeId };
};

export enum Goal {
  LOSE_WEIGHT = "LOSE_WEIGHT",
  BUILD_MUSCLE = "BUILD_MUSCLE",
  MAINTAIN_WEIGHT = "MAINTAIN_WEIGHT",
}

export const getMacros = (cal: number, goal: Goal, isKeto?: boolean) => {
  let prot = 0;
  let carb = 0;
  let fat = 0;

  if (isKeto) {
    prot = (cal * 0.25) / 4;
    carb = (cal * 0.05) / 4;
    fat = (cal * 0.7) / 9;
  } else {
    switch (goal) {
      case "LOSE_WEIGHT":
        prot = (cal * 0.4) / 4;
        carb = (cal * 0.3) / 4;
        fat = (cal * 0.3) / 9;
        break;
      case "BUILD_MUSCLE":
        prot = (cal * 0.3) / 4;
        carb = (cal * 0.4) / 4;
        fat = (cal * 0.3) / 9;
        break;
      case "MAINTAIN_WEIGHT":
        prot = (cal * 0.3) / 4;
        carb = (cal * 0.4) / 4;
        fat = (cal * 0.3) / 9;
        break;
      default:
        prot = (cal * 0.3) / 4;
        carb = (cal * 0.4) / 4;
        fat = (cal * 0.3) / 9;
    }
  }
  let macros = {
    prot: prot.toFixed(0).toString(),
    carb: carb.toFixed(0).toString(),
    fat: fat.toFixed(0).toString(),
  };

  return macros;
};

export const handleMealsNumber = (numberMeals: number): Meals | null => {
  switch (numberMeals) {
    case 1:
      return "ONE_MEAL";
    case 2:
      return "TWO_MEALS";
    case 3:
      return "THREE_MEALS";
    case 4:
      return "FOUR_MEALS";
    case 5:
      return "FIVE_MEALS";
    case 6:
      return "SIX_MEALS";
    case 7:
      return "SEVEN_MEALS";
    default:
      return null;
  }
};

/* export const ActivityOptions =[
  'Inactive','1-2 times a week','3-4 times a week','5-7 times a week','Twice a day,everyday'
] */
export const ActivityOptions = [
  {
    menuText: "Inactive",
    menuValue: "INACTIVE",
    selected: false,
  },
  {
    menuText: "1-2 times a week",
    menuValue: "FEW_TIME",
    selected: false,
  },
  {
    menuText: "3-4 times a week",
    menuValue: "LIFT_THREE",
    selected: false,
  },
  {
    menuText: "5-7 times a week",
    menuValue: "LIFT_SEVEN",
    selected: false,
  },
  {
    menuText: "Twice a day , everyday",
    menuValue: "TWICE",
    selected: false,
  },
];

export const weightConverter = (unit: Weight, value: number): number => {
  switch (unit) {
    case "KG":
      return value;
    case "LBS":
      return value * 0.453592;
    default:
      return value;
  }
};

export const heightConverter = (unit: Height, value: number): number => {
  switch (unit) {
    case "CM":
      return value;
    case "FEET":
      return value * 30.48;
    default:
      return value;
  }
};

const calculateKetoCals = (
  gender: Gender,
  weight: WeightInput,
  height: HeightInput,
  age: number,
  activity: Activity
) => {
  let calories = 0;
  if (gender === "MALE") {
    let varibale = 0.3;
    switch (activity) {
      case "INACTIVE":
        varibale = 0.3;
        break;
      case "FEW_TIME":
        varibale = 0.6;
        break;
      case "LIFT_THREE":
        varibale = 0.7;
        break;
      case "LIFT_SEVEN":
        varibale = 1.1;
        break;
      case "TWICE":
        varibale = 1.4;
    }
    calories =
      10 * weightConverter(weight.unit!, weight.value!) +
      6.25 * heightConverter(height.unit!, height.value!) -
      5 * age +
      5 +
      (10 * weightConverter(weight.unit!, weight.value!) +
        6.25 * heightConverter(height.unit!, height.value!) -
        5 * age +
        5) *
        varibale +
      (10 * weightConverter(weight.unit!, weight.value!) +
        6.25 * heightConverter(height.unit!, height.value!) -
        5 * age +
        5 +
        (10 * weightConverter(weight.unit!, weight.value!) +
          6.25 * heightConverter(height.unit!, height.value!) -
          5 * age +
          5) *
          varibale) /
        10;
  } else if (gender === "FEMALE") {
    let varibale = 0.3;
    switch (activity) {
      case "INACTIVE":
        varibale = 0.3;
        break;
      case "FEW_TIME":
        varibale = 0.5;
        break;
      case "LIFT_THREE":
        varibale = 0.6;
        break;
      case "LIFT_SEVEN":
        varibale = 0.9;
        break;
      case "TWICE":
        varibale = 1.2;
    }
    calories =
      10 * weightConverter(weight.unit!, weight.value!) +
      6.25 * heightConverter(height.unit!, height.value!) -
      5 * age -
      161 +
      (10 * weightConverter(weight.unit!, weight.value!) +
        6.25 * heightConverter(height.unit!, height.value!) -
        5 * age -
        161) *
        varibale +
      (10 * weightConverter(weight.unit!, weight.value!) +
        6.25 * heightConverter(height.unit!, height.value!) -
        5 * age -
        161 +
        (10 * weightConverter(weight.unit!, weight.value!) +
          6.25 * heightConverter(height.unit!, height.value!) -
          5 * age -
          161) *
          varibale) /
        10;
  }
  return calories;
};

export const calculateRecommendedCalories = (
  gender: Gender,
  weight: WeightInput,
  height: HeightInput,
  age: number,
  activity: Activity,
  planType: string,
  goal: Goal
) => {
  let calories = 0;
  let prot = 0;
  let carb = 0;
  let fat = 0;
  if (planType.toLowerCase().includes("keto")) {
    calories = calculateKetoCals(gender, weight, height, age, activity);
  } else {
    calories =
      10 * weightConverter(weight.unit!, weight.value!) +
      6.25 * heightConverter(height.unit!, height.value!) -
      age * 5;

    if (gender === "FEMALE") calories = calories - 161;
    if (gender === "MALE") calories = calories + 5;
    switch (activity) {
      case "FEW_TIME":
        calories = calories * 1.1;
        break;
      case "LIFT_THREE":
        calories = calories * 1.2;
        break;
      case "LIFT_SEVEN":
        calories = calories * 1.4;
        break;
      case "TWICE":
        calories = calories * 1.6;
    }
  }

  if (planType.toLowerCase().includes("keto")) {
    switch (goal) {
      case Goal.LOSE_WEIGHT:
        calories = calories - calories * 0.3;
        break;
      case Goal.BUILD_MUSCLE:
        calories = calories + calories * 0.25;
        break;
    }
    prot = (calories * 0.25) / 4;
    carb = (calories * 0.05) / 4;
    fat = (calories * 0.7) / 9;
  } else {
    switch (goal) {
      case Goal.LOSE_WEIGHT:
        calories = calories - calories * 0.2;
        prot = (calories * 0.3) / 4;
        carb = (calories * 0.4) / 4;
        fat = (calories * 0.3) / 9;
        break;
      case Goal.BUILD_MUSCLE:
        calories = calories + calories * 0.2;
        prot = (calories * 0.3) / 4;
        carb = (calories * 0.4) / 4;
        fat = (calories * 0.3) / 9;
        break;
      case Goal.MAINTAIN_WEIGHT:
        prot = (calories * 0.3) / 4;
        carb = (calories * 0.4) / 4;
        fat = (calories * 0.3) / 9;
        break;
    }
  }

  return {
    calories: calories.toString(),
    prot: prot.toFixed(1).toString(),
    carb: carb.toFixed(1).toString(),
    fat: fat.toFixed(1).toString(),
  };
};

export const isDayOff = (startDate: Date, daysOff: string[]) => {
  const dayOfWeek = moment(startDate).format("dddd").toUpperCase();
  return daysOff.some((day) => day === dayOfWeek);
};

export const getPluralizedBoxText = (count: number) => {
  if (count === 0) {
    return "Zero boxes";
  } else {
    return count === 1 ? count + " Box" : count + " Boxes";
  }
};
export interface CustomerPausesFormType {
  start_date: Date | null;
  freezing_date: Date | null;
  adminEnforced: boolean;
  customReason: string;
  preDefinedReason: BreakReason | null;
}

export const RewardsAction: Array<{
  menuText: string;
  menuValue: RewardsActionEnum;
}> = [
  {
    menuText: "Purchasing A New Plan",
    menuValue: "PURCHASING_A_NEW_PLAN",
  },
  {
    menuText: "Renewal Of Subscription",
    menuValue: "RENEWAL_OF_SUBSCRIPTION",
  },
  {
    menuText: "Add On Purchase",
    menuValue: "ADD_ON_PURCHASE",
  },
  {
    menuText: "Email Verification",
    menuValue: "EMAIL_VERIFICATION",
  },
  {
    menuText: "Inviting A Friend",
    menuValue: "INVITING_A_FRIEND",
  },
];
