import { useAuth } from "../../contextAPI";

function Arrow() {
  const auth = useAuth();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="7"
      fill="none"
      viewBox="0 0 4 7"
    >
      <path
        fill={auth.countryCode === "SA" ? "#fff" : "#C7893E"}
        d="M0 .588v5.823c0 .524.62.786.982.416l2.85-2.911a.594.594 0 000-.83L.981.175C.62-.197 0 .065 0 .588z"
      ></path>
    </svg>
  );
}

export default Arrow;
