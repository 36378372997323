import { useAuth } from "../../contextAPI";

function FoodIcon() {
  const auth = useAuth();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill={auth.countryCode === "SA" ? "#008000" : "#fff"}
        fillRule="evenodd"
        d="M8.723.03c-.842.166-1.4 1.023-1.228 1.884.031.152.066.301.078.331.018.045-.03.067-.251.118a8.132 8.132 0 00-4.517 2.818c-.745.924-1.352 2.206-1.574 3.323-.065.327-.145.886-.145 1.01 0 .033-.086.045-.327.045-.27 0-.354.014-.481.08-.36.184-.372.624-.023.885l.097.073h17.296l.098-.073c.24-.18.32-.456.197-.684-.108-.2-.31-.281-.702-.281-.241 0-.326-.012-.326-.045 0-.106-.076-.663-.127-.922-.59-3.068-2.985-5.542-6.022-6.225-.237-.053-.287-.076-.269-.12.013-.031.048-.18.078-.333C10.8.796 9.832-.187 8.723.03zm.56 1.088c.157.08.28.283.28.462 0 .261-.22.527-.479.578-.247.048-.576-.278-.576-.572a.57.57 0 01.273-.466c.17-.09.328-.09.502-.002zM5.627 11.513c-.71.154-1.15.386-1.315.697l-.078.145-.01 2.822L4.214 18h3.141c1.92 0 3.263-.015 3.456-.037a4.27 4.27 0 002.146-.87c.117-.088.956-.905 1.867-1.816 1.854-1.855 1.827-1.822 1.826-2.271 0-.32-.083-.524-.298-.739a.935.935 0 00-.721-.298c-.477-.002-.403-.06-2.304 1.828-1.673 1.663-1.712 1.698-2.005 1.843-.437.215-.733.257-1.7.243-.705-.01-.792-.018-.9-.081-.32-.19-.33-.68-.015-.886.085-.057.212-.066 1.118-.082.967-.018 1.032-.023 1.201-.099.21-.094.433-.31.547-.533.063-.123.082-.216.082-.422.001-.33-.119-.604-.347-.79-.281-.231-.339-.243-1.258-.264l-.832-.02-.17-.15c-.688-.608-1.346-.942-2.088-1.06-.318-.052-1.063-.042-1.333.017zm-4.334 1.291c-.252.168-.242.07-.242 2.538 0 2.163.001 2.217.072 2.363a.43.43 0 00.222.223c.138.066.214.072.983.072h.833v-5.276h-.874c-.85 0-.878.002-.994.08z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FoodIcon;
