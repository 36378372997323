import { useEffect, useMemo, useState } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import clsx from "clsx";
//material ui
import {
  Box,
  TextField,
  IconButton,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button/Button";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesInput } from "../../../styles/Input__styles";
//mutations&queries
import { useGetNutritionsQuery } from "../../../graphql/types";
//types
import { IngredientProps } from "../../ingredients/Types";
//icons
import AddCircle from "../../../public/icons/AddCircle";

const NutritionInput = ({ updateMode }: { updateMode?: boolean }) => {
  const InputClasses = useStylesInput();
  const ButtonClasses = useStylesButtons();
  //react-hook-form
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext<IngredientProps>();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "nutritions",
  });
  const dataWatch = useWatch({ control });

  //queries and mutations
  const dataGetNutritions = useGetNutritionsQuery();
  const nutrutionsList = useMemo(
    () => dataGetNutritions.data?.getNutritions || [],
    [dataGetNutritions]
  );

  //states
  const [ListNutritionsFiltered, setListNutritionsFiltered] = useState<any[]>(
    []
  );

  //useEffects
  useEffect(() => {
    const result =
      nutrutionsList.filter(
        (el) =>
          el._id !==
          dataWatch.nutritions?.find((elem) => elem.nutrition?._id === el._id)
            ?.nutrition?._id
      ) || [];
    setListNutritionsFiltered(result);
  }, [dataWatch, nutrutionsList]);

  //functions
  const onClickRemove = (index: number) => () => {
    remove(index);
  };

  const onClickAdd = () => {
    append({
      nutrition: null,
      quantity: "",
    });
  };

  return (
    <>
      <p className={clsx(InputClasses.input__subtitle)}>
        Nutrition Information*<span>(for 100g)</span>
      </p>
      <Box>
        {fields.map((item, index) => {
          return (
            <Box
              key={item.id}
              style={{ position: "relative", padding: "10px 0" }}
            >
              {fields.length > 1 && (
                <IconButton
                  onClick={onClickRemove(index)}
                  style={{ position: "absolute" }}
                  className={clsx(InputClasses.input__clear__icon)}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <Box
                sx={{
                  alignItems: "center",
                  width: "100%",
                }}
                className={clsx(InputClasses.input__container)}
              >
                <Box width="49%">
                  <Controller
                    name={`nutritions.${index}.nutrition`}
                    control={control}
                    render={({
                      fieldState: { error },
                      field: { onChange: Change, value, ref, ...rest },
                    }) => {
                      return (
                        <>
                          <Autocomplete
                            {...rest}
                            disablePortal
                            defaultValue={updateMode ? item : undefined}
                            id="combo-box-demo"
                            options={ListNutritionsFiltered}
                            getOptionLabel={(option) => option.name || ""}
                            onChange={(_, data) => Change(data)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Nutrition Information"
                              />
                            )}
                          />
                          <p
                            className={clsx(InputClasses.input__error__helper)}
                          >
                            {error?.message}
                          </p>
                        </>
                      );
                    }}
                    rules={{
                      required: "Select Nutrition Information",
                    }}
                  />
                </Box>
                <Box width="49%">
                  <TextField
                    type="text"
                    id="demo-helper-text-misaligned"
                    sx={{ minWidth: "100%" }}
                    placeholder="Quantity"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {dataWatch.nutritions?.[index]?.nutrition?.unit && (
                            <span>
                              {dataWatch.nutritions[index]?.nutrition?.unit}
                            </span>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`nutritions.${index}.quantity`, {
                      onChange: (e) => {
                        const inputValue = e.target.value.replace(",", ".");
                        if (
                          /^\d*\.?\d*$/.test(inputValue) ||
                          inputValue === ""
                        ) {
                          setValue(`nutritions.${index}.quantity`, inputValue);
                        }
                      },
                      required: "Information Quantity are Mandatory",
                    })}
                  />

                  {errors?.nutritions && errors?.nutritions.length > 0 && (
                    <p className={clsx(InputClasses.input__error__helper)}>
                      {errors?.nutritions[index]?.quantity?.message}
                    </p>
                  )}
                </Box>
                {/* <Box width="5%">
                  {dataWatch.nutritions &&
                    dataWatch.nutritions[index]?.nutrition?.unit && (
                      <p className={clsx(InputClasses.input__unit)}>
                        <strong>{dataWatch.nutritions[index]?.nutrition?.unit}</strong>
                      </p>
                    )}
                </Box> */}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box className={clsx(InputClasses.input__add_container)}>
        <Button
          startIcon={<AddCircle />}
          className={ButtonClasses.button_plus_add_new}
          onClick={onClickAdd}
        >
          add new nutrition information
        </Button>
      </Box>
    </>
  );
};

export default NutritionInput;
