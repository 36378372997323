import React, { ReactNode, useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
//context api
import { useAuth } from "../../contextAPI";
//material
import { AppBar, Tab, Tabs, Box } from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
//styles
import {
  ContainerCustomerView,
  useStylesCustomers,
} from "../../styles/Customers__styles";
//utils
import { TabPanelProps, a11yProps } from "../Utils";
//components
import DriverReport from "./DriverReport/DriverReport";
import BoxesReport from "./BoxesReport/BoxesReport";
import PackagingForBagsReport from "./PackagingForBagsReport/PackagingForBagsReport";
import BulkCookingReport from "./BulkCookingReport/BulkCookingReport";
import DishReport from "./DishReport/DishReport";
import GroceryReport from "./GroceryReport/GroceryReport";
import DishSelectionsReport from "./DishSelectionsReport/DishSelectionsReport";
import ActiveCustomerFrozenCustomerDailyReport from "./ActiveCustomerFrozenCustomerDailyReport/ActiveCustomerFrozenCustomerDailyReport";
import OutOfRangeReport from "./OutOfRangeReport/OutOfRangeReport";
import TrainerReport from "./TrainerReport/TrainerReport";
import LabelReport from "./LabelReport/LabelReport";
import SalesReport from "./SalesReport/SalesReport";
import SaucesReport from "./SaucesReport/SaucesReport";
import BirthdayReport from "./BirthdayReport/BirthdayReport";
import ComplaintsReport from "./ComplaintsReport/ComplaintsReport";
import AddonReport from "./AddonReport/AddonReport";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const ViewReport = () => {
  let auth = useAuth();

  //styles
  const CustomerClasses = useStylesCustomers();

  //state
  const [value, setValue] = React.useState(0);
  const [CustomerTabs, setCustomerTabs] = useState<CustomerTabsTypes[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  interface CustomerTabsTypes {
    labelTab: string;
    Comp: ReactNode;
  }

  useEffect(() => {
    if (CustomerTabs.length !== 0) {
      return;
    }
    const CustomerTabsCopy = [...CustomerTabs];

    if (auth.permissions.includes("ADMIN_REPORTS_LABEL")) {
      CustomerTabsCopy.push({
        labelTab: "Label Report",
        Comp: <LabelReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_LABEL")) {
      CustomerTabsCopy.push({
        labelTab: "Sauces Report",
        Comp: <SaucesReport />,
      });
    }

    if (auth.permissions.includes("ADMIN_REPORTS_LABEL")) {
      CustomerTabsCopy.push({
        labelTab: "Addons Report",
        Comp: <AddonReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_BIRTHDAY_LABEL")) {
      CustomerTabsCopy.push({
        labelTab: "Birthday Report",
        Comp: <BirthdayReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_PACKAGING")) {
      CustomerTabsCopy.push({
        labelTab: "Packaging for bags Report",
        Comp: <PackagingForBagsReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_BOXES")) {
      CustomerTabsCopy.push({
        labelTab: "Report For Boxes",
        Comp: <BoxesReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_DRIVERS")) {
      CustomerTabsCopy.push({
        labelTab: "Report For Drivers",
        Comp: <DriverReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_GROCERY")) {
      CustomerTabsCopy.push({
        labelTab: "Grocery Report",
        Comp: <GroceryReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_DISHS")) {
      CustomerTabsCopy.push({
        labelTab: "Dishes Report",
        Comp: <DishReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_BULK_COOKING")) {
      CustomerTabsCopy.push({
        labelTab: "Bulk cooking Report",
        Comp: <BulkCookingReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_DISH_SELECTIONS")) {
      CustomerTabsCopy.push({
        labelTab: "Dish Selections Report",
        Comp: <DishSelectionsReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_ACTIVE_CUSTOMERS")) {
      CustomerTabsCopy.push({
        labelTab: "Active / Frozen Customers Report",
        Comp: <ActiveCustomerFrozenCustomerDailyReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_OUT_OF_RANGE")) {
      CustomerTabsCopy.push({
        labelTab: "Out of Range Report",
        Comp: <OutOfRangeReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_TRAINER")) {
      CustomerTabsCopy.push({
        labelTab: "Trainer Report",
        Comp: <TrainerReport />,
      });
    }
    if (auth.permissions.includes("ADMIN_REPORTS_SALES")) {
      CustomerTabsCopy.push({
        labelTab: "Sales Report",
        Comp: <SalesReport />,
      });
    }
    if (auth.permissions.includes("MANAGEMENT_COMPLAIN_LIST")) {
      CustomerTabsCopy.push({
        labelTab: "Complaints Report",
        Comp: <ComplaintsReport />,
      });
    }
    setCustomerTabs(CustomerTabsCopy);
  }, [auth.permissions, CustomerTabs]);

  return (
    <Box className={CustomerClasses.report_view_container}>
      <Box className={CustomerClasses.report_tabs_container}>
        <AppBar
          position="static"
          sx={{ maxWidth: "100%" }}
          style={{ backgroundColor: "#FFFFFF", marginBlockEnd: 40 }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
            style={{ color: "#20262E" }}
          >
            {CustomerTabs.map((el, index) => (
              <Tab
                label={el.labelTab}
                {...a11yProps(index)}
                key={el.labelTab}
              />
            ))}
          </Tabs>
        </AppBar>
      </Box>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ maxWidth: "100%" }}
        className={CustomerClasses.swipeable_views_container}
      >
        {CustomerTabs.map((el, i) => (
          <TabPanel value={value} index={i} key={el.labelTab}>
            <ContainerCustomerView>{el.Comp}</ContainerCustomerView>
          </TabPanel>
        ))}
      </SwipeableViews>
    </Box>
  );
};

export default ViewReport;
