import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles, withStyles, createStyles } from "@mui/styles";
import InputBase from "@mui/material/InputBase";

type MacrosColorProps = {
  bg: string;
};

export const MacrosColor = styled("div")(({ bg }: MacrosColorProps) => ({
  height: "8px",
  width: "37px",
  background: bg,
  borderRadius: "35px",
}));

export const useStylesMacrosDetails = makeStyles((theme: Theme) => {
  return {
    macros__value: {
      display: "flex",
      height: "40px",
      width: "100%",
      alignItems: "center",
      borderRadius: "5px",
      border: "3px solid",
      boxSizing: "border-box",
    },
    macros__box: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      borderRight: "3px solid",
    },
    macros__label: {
      fontSize: "11px !important",
    },
  };
});

export const InputBaseStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "1px solid #C4C4C4",
      borderRadius: "5px",
      margin: "12px 0 16px",
      "& .MuiInputBase-input": {
        textAlign: "center",
        padding: "8px 4px 10px;",
        fontSize: theme.typography.h4.fontSize,
        color: theme.palette.primary.main,
      },
    },
  })
)(InputBase);
