import { useRef, useState, memo } from "react";
import { useReactToPrint } from "react-to-print";
import moment, { Moment } from "moment";
//material
import { Box, Button, TextField, Autocomplete, Checkbox } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
//queries and mutations
import {
  DeliveryDataPayload,
  DriverPayload,
  DriverReportPayload,
  useGetDriverReportLazyQuery,
  useGetDriversQuery,
} from "../../../graphql/types";
//components
import DriverReportPrint from "./DriverReportPrint";

const DriverReport = () => {
  const printRef = useRef<HTMLDivElement>(null);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  //state
  const [driver, setDriver] = useState<DriverPayload | null>();
  const [startDate, setStartDate] = useState<Moment>(moment.utc());
  const [showGenerate, setShowGenerate] = useState<boolean>(true);

  //styles
  const ButtonClasses = useStylesButtons();

  //queries and mutations
  const dataDriver = useGetDriversQuery();
  const [getLabel, { data: driverReport }] = useGetDriverReportLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted() {
      handlePrint();
      setShowGenerate(true);
    },
  });

  //functions
  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    documentTitle:
      "Report For Drivers " +
      moment(startDate).format("DD/MM/YYYY dddd") +
      " generated on " +
      moment().format("DD/MM/YYYY dddd"),
  });

  const onGenerateHandler = async () => {
    setShowGenerate(false);
    await getLabel({
      variables: {
        driverReportInput: {
          date: startDate.toDate(),
          driverId: driver?._id,
        },
      },
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={800}
    >
      <Box
        width="40%"
        height="70%"
        bgcolor="#ffffff73"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection="column"
        borderRadius={5}
        border={1}
        borderColor="#e7e7e773"
      >
        <Box
          fontFamily="Poppins"
          fontSize={20}
          fontWeight="600"
          alignSelf="flex-start"
          marginLeft="15%"
        >
          Report For Drivers
        </Box>
        <Box width="70%">
          <Box width="100%">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                value={startDate}
                inputFormat="DD/MM/YYYY"
                disablePast={false}
                disableFuture={false}
                onChange={(newValue) => {
                  const utcDate = moment.utc(newValue);
                  setStartDate(utcDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Delivery Date"
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box width="100%" marginTop={3}>
            <Autocomplete
              id="chosenProgram"
              options={dataDriver?.data?.getDrivers || []}
              onChange={(_, value) => {
                setDriver(value);
              }}
              value={driver}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="secondary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a driver"
                  label="Driver"
                />
              )}
            />
          </Box>
        </Box>
        <Box
          width="70%"
          height={100}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            type="submit"
            disabled={!showGenerate || !driver}
            onClick={onGenerateHandler}
            sx={{ width: "100%", height: "50%" }}
          >
            Generate Reports
          </Button>
          <Box
            fontFamily="Poppins"
            fontSize={10}
            fontWeight="500"
            alignSelf="flex-start"
          >
            Use this table to generate reports for drivers.
          </Box>
        </Box>
      </Box>

      <div style={{ display: "none" }}>
        <DriverReportPrint
          morning={
            driverReport?.getDriverReport.deliveries?.filter(
              (el) => el.deliveryAddress?.deliveryTime === "MORNING"
            ) as DeliveryDataPayload[]
          }
          evening={
            driverReport?.getDriverReport.deliveries?.filter(
              (el) => el.deliveryAddress?.deliveryTime === "EVENING"
            ) as DeliveryDataPayload[]
          }
          ref={printRef}
          deliveryData={driverReport?.getDriverReport as DriverReportPayload}
        />
      </div>
    </Box>
  );
};

export default memo(DriverReport);
