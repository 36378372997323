import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStylesContainers = makeStyles((theme: Theme) => {
  return {
    section__container: {
      display: "flex",
      flexDirection: "row",
      gap: "15px",
      width: "100%",
      "& > div": {
        width: "100%",
      },
    },
    section__title: {
      color: `${theme.palette.secondary.main} !important`,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
      margin: "0px 0 20px !important",
    },
    section__subtitle: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      marginBottom: "8px",
      "& > span": {
        color: `${theme.palette.text.secondary} !important`,
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.subtitle2.fontWeight,
      },
    },
    section__label: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      margin: "0px",
      "& > span": {
        color: `${theme.palette.success.contrastText} !important`,
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.subtitle2.fontWeight,
      },
    },
    section__value: {
      backgroundColor: `${theme.palette.warning.main}`,
      color: `white !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      padding: "4px 12px",
      borderRadius: "8px",
    },
    section__error__helper: {
      color: `${theme.palette.error.main}`,
      fontSize: `${theme.typography.subtitle2.fontSize} !important`,
      marginLeft: "12px",
    },
    section__add__container: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "10px",
    },
    section__add__subtitle: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle1.fontWeight,
      textTransform: "capitalize",
    },
  };
});

export const ContainerStyled = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "16px",
  border: "0.5px solid #000000",
  boxShadow: "0px 4px 0px rgba(219, 216, 213, 0.1)",
  borderRadius: "10px",
  flexWrap: "wrap",
  // gap: "8px",
}));

export const Container = styled("div")(({ theme }) => ({
  position: "absolute",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.primary.contrastText,
  minWidth: "30vw",
  height: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "30px",
  boxSizing: "border-box",
}));

export const ContainerLeft = styled("div")(({ theme }) => ({
  width: "70%",
  height: "fit-content",
  boxSizing: "border-box",
}));

export const LeftElement = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.primary.contrastText,
  width: "100%",
  height: "fit-content",
  padding: "20px",
  boxSizing: "border-box",
  marginBottom: "18px",
  position: "relative",
}));

export const ContainerRight = styled("div")(({ theme }) => ({
  width: "30%",
  height: "fit-content",
  boxSizing: "border-box",
}));

export const RightElement = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.primary.contrastText,
  width: "100%",
  height: "fit-content",
  padding: "20px",
  boxSizing: "border-box",
  marginBottom: "18px",
}));

export const ContainersContent = styled("div")(({ theme }) => ({
  paddingTop: "0px !important",
}));

export const ContainerFullWidth = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  gap: "8px",
}));

export const ElementFullWidth = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.primary.contrastText,
  marginBottom: "18px",
  // padding: "20px",
}));

export const LeadContainerLeft = styled("div")(({ theme }) => ({
  width: "55%",
  height: "fit-content",
  boxSizing: "border-box",
}));

export const LeadContainerRight = styled("div")(({ theme }) => ({
  width: "45%",
  height: "fit-content",
  boxSizing: "border-box",
}));
