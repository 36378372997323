import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import { Box, TextField } from "@mui/material";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../styles/Recipe__styles";
//components
import PageAddHeader from "../../pageAddHeader/pageAddHeader";
//utils
import { getMessageError } from "../../Utils";
//queries&mutations
import { useCreatePackerMutation } from "../../../graphql/types";
//types
import { PackingAgent } from "../Types";
import { ApolloError } from "@apollo/client";

const AddPackingAgent = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const ContainersClasses = useStylesContainers();
  const RecipeClasses = useStylesRecipe();

  //react-hook-form
  const methods = useForm<PackingAgent>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  // queries and mutations
  const [createPacker, { loading }] = useCreatePackerMutation();

  const onSubmit: SubmitHandler<PackingAgent> = async (data) => {
    try {
      await createPacker({
        variables: {
          createPackerInput: {
            name: data.name,
            email: data.email,
            password: data.password,
          },
        },
        onCompleted: () => {
          enqueueSnackbar("New Packing Agent added succuessfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/admin/packingTeam/list_packing_team");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title={"Create Packing Agent"}
          buttonText={"Create Packing Agent"}
          cancelButtonLink="/admin/packingTeam/list_packing_team"
          loading={loading}
        />
        <Box display="grid" gridTemplateColumns="2fr 1fr" columnGap={10}>
          <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={4}>
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>
                Packing Agent Name
              </p>
              <TextField
                id="demo-helper-text-misaligned"
                placeholder="Packing Agent Name"
                fullWidth
                {...register("name", {
                  required: "Packing Agent Name is Mandatory",
                })}
              />
              {errors?.name?.message && (
                <p className={RecipeClasses.recipe__error__helper}>
                  {errors?.name?.message}
                </p>
              )}
            </Box>
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>Email</p>
              <TextField
                id="demo-helper-text-misaligned"
                placeholder="Email"
                fullWidth
                {...register("email", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Enter a valid Email",
                  },
                  required: "Email is Mandatory",
                })}
              />
              {errors?.email?.message && (
                <p className={RecipeClasses.recipe__error__helper}>
                  {errors?.email?.message}
                </p>
              )}
            </Box>
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>Password</p>
              <TextField
                id="demo-helper-text-misaligned"
                placeholder="Password"
                fullWidth
                {...register("password", {
                  required: "Password is Mandatory",
                })}
              />
              {errors?.password?.message && (
                <p className={RecipeClasses.recipe__error__helper}>
                  {errors?.password?.message}
                </p>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddPackingAgent;
