import {
  UseFormRegister,
  Control,
  Controller,
  useWatch,
} from "react-hook-form";
//material ui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//components
import DropZoneInput from "../../../layout/commun/DropZoneInput";
//styled
import {
  useStylesContainers,
  ContainerStyled,
} from "../../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../../styles/Recipe__styles";
//Types
import { DishProps } from "../../Types";

type DetailsFromProps = {
  register: UseFormRegister<DishProps>;
  control: Control<DishProps, object>;
  errors?: any;
  packages: string[];
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DetailsFrom = ({
  control,
  register,
  errors,
  packages,
}: DetailsFromProps) => {
  //styles
  const ContainersClasses = useStylesContainers();
  const RecipeClasses = useStylesRecipe();
  const dataWatch = useWatch({ control });

  return (
    <ContainerStyled>
      <Box
        display="grid"
        width="100%"
        gridTemplateColumns="2fr 1fr"
        columnGap={4}
      >
        <Box width="100%">
          <p className={ContainersClasses.section__subtitle}>
            Plating Instructions
          </p>
          <TextField
            id="demo-helper-text-misaligned"
            placeholder="Plating Instructions"
            multiline
            rows={9.5}
            fullWidth
            {...register(
              "dish__plating" /* , {
              required: "Plating Instructions are Mandatory",
            } */
            )}
          />
          {/* <p className={clsx(RecipeClasses.recipe__error__helper)}>
            {errors?.dish__plating?.message}
          </p> */}
        </Box>
        <Box width="100%">
          <p className={ContainersClasses.section__subtitle}>
            Upload Plating Image
          </p>

          <Controller
            name="dish__plating__picture"
            control={control}
            render={({ field: { onChange, ref } }) => {
              return (
                <DropZoneInput
                  onChange={(e) => {
                    onChange(e.target.files[0]);
                  }}
                  label="Drop File"
                  inputRef={ref}
                  name="dish__plating__picture"
                  errors={errors}
                  multiple={false}
                  disabled={false}
                  accept="image/*"
                  message="Recommended resolution for image 400px*400px"
                />
              );
            }}
          />
        </Box>
        <Box>
          <Box>
            <p className={ContainersClasses.section__subtitle}>
              Packaging Name
            </p>
            <Controller
              name="dish__package"
              control={control}
              render={({ field: { ref, onChange, ...rest } }) => (
                <Autocomplete
                  ref={ref}
                  multiple
                  onChange={(_, value) => {
                    onChange(value);
                  }}
                  id="dish__package"
                  value={dataWatch?.dish__package}
                  options={packages || []}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        color="secondary"
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Dish Package" />
                  )}
                />
              )}
            />
            <p className={RecipeClasses.recipe__error__helper}>
              {errors?.dish__package?.message}
            </p>
          </Box>
          <Box width="100%" mt={3}>
            <p className={ContainersClasses.section__subtitle}>
              Cooking instruction
            </p>
            <TextField
              id="demo-helper-text-misaligned"
              placeholder="Cooking Instructions"
              multiline
              rows={9}
              fullWidth
              {...register(
                "dish__cooking__instructions" /* , {
                required: "Plating Instructions are Mandatory",
              } */
              )}
            />
            {/* <p className={clsx(RecipeClasses.recipe__error__helper)}>
              {errors?.dish__plating?.message}
            </p> */}
          </Box>
        </Box>
        <Box>
          <p className={ContainersClasses.section__subtitle}>
            Upload Packaging Image
          </p>

          <Controller
            name="dish__package__picture"
            control={control}
            render={({ field: { onChange, ref } }) => {
              return (
                <DropZoneInput
                  onChange={(e) => {
                    onChange(e.target.files[0]);
                  }}
                  label="Drop File"
                  inputRef={ref}
                  name="dish__package__picture"
                  errors={errors}
                  multiple={false}
                  disabled={false}
                  accept="image/*"
                  message="Recommended resolution for image 400px*400px"
                />
              );
            }}
          />
        </Box>
      </Box>

      <Box
        display="grid"
        width="100%"
        gridTemplateColumns="2fr 1fr"
        gap={4}
      ></Box>
    </ContainerStyled>
  );
};

export default DetailsFrom;
