import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useSnackbar } from "notistack";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//styles
import {
  ContainerFullWidth,
  ContainersContent,
  useStylesContainers,
} from "../../../../styles/Containers__styles";
//components
import PageAddHeader from "../../../pageAddHeader/pageAddHeader";
//types
import { roleProps } from "../Types";
import { ApolloError } from "@apollo/client";
//mutations&queries
import {
  Permissions_Enum,
  useFindPermissionsByRoleQuery,
  useUpdatePermissionMutation,
} from "../../../../graphql/types";
//utils
import { PermissionsList } from "../Utils";
import { getMessageError } from "../../../Utils";

const UpdateRole = () => {
  const { role } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //react-hook-form
  const ContainersClasses = useStylesContainers();
  const methods = useForm<roleProps>({
    defaultValues: {
      role__name: "",
      permissions: PermissionsList,
    },
  });

  const { register, handleSubmit, control, setValue } = methods;

  const { fields } = useFieldArray({
    control,
    name: "permissions",
  });

  //queries&mutations
  const [updateRolePermissions, { loading }] = useUpdatePermissionMutation();
  const { data } = useFindPermissionsByRoleQuery({
    variables: {
      role: role || "",
    },
    skip: !role,
    fetchPolicy: "no-cache",
  });

  const permissions = useMemo(
    () => data?.findPermissionsByRole.permissions || null,
    [data?.findPermissionsByRole]
  );

  useEffect(() => {
    if (data?.findPermissionsByRole) {
      setValue("role__name", data?.findPermissionsByRole.role || "");
      //reflect user permissions on the UI
      const updatedPermissions = PermissionsList.map((el) => {
        const updatePerm = el.categoryPermissions.map((ele) => {
          if (permissions?.includes(ele.id as Permissions_Enum)) {
            return { id: ele.id, name: ele.name, isChecked: true };
          } else {
            return { id: ele.id, name: ele.name, isChecked: ele.isChecked };
          }
        });
        return {
          categoryName: el.categoryName,
          categoryPermissions: updatePerm,
        };
      });
      setValue("permissions", updatedPermissions);
    }
  }, [data?.findPermissionsByRole]);

  const onSubmit: SubmitHandler<roleProps> = async (formData: roleProps) => {
    //extract permissions list
    const permissions: Permissions_Enum[] = [];
    formData.permissions.forEach((el) => {
      el.categoryPermissions.forEach((ele) => {
        if (ele.isChecked) {
          permissions.push(ele.id as Permissions_Enum);
        }
      });
    });

    try {
      await updateRolePermissions({
        variables: {
          input: {
            role: data?.findPermissionsByRole.role,
            permissions: permissions,
          },
        },
        onCompleted: () => {
          enqueueSnackbar("Permissions succuessfully updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/data/users_and_roles/list_users_roles");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title="Update Role"
          buttonText="Update Role"
          cancelButtonLink="/data/users_and_roles/list_users_roles"
          loading={loading}
        />
        <ContainersContent>
          <ContainerFullWidth>
            <FormProvider {...methods}>
              <p className={ContainersClasses.section__title}>
                Role Information
              </p>
              <Box width="30%">
                {/* Role Name */}
                <Box>
                  <p className={ContainersClasses.section__subtitle}>
                    Role Name*
                  </p>
                  <TextField
                    placeholder="Role Name"
                    disabled
                    sx={{ width: "100%" }}
                    {...register("role__name")}
                  />
                </Box>
              </Box>
              <p
                className={ContainersClasses.section__title}
                style={{ marginTop: "20px" }}
              >
                Role Permissions
              </p>
              {fields.map((item, index) => (
                <Accordion key={item.categoryName} sx={{ width: "30%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{item.categoryName}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item.categoryPermissions.map((el, ind) => (
                      <Box
                        key={el.id}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width={"100%"}
                        marginBottom={4}
                      >
                        <Typography>{el.name}</Typography>
                        <Controller
                          name={`permissions.${index}.categoryPermissions.${ind}.isChecked`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                              checked={value}
                              label=""
                              onChange={(e, value) => onChange(value)}
                              control={<Checkbox color="secondary" />}
                            />
                          )}
                        />
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </FormProvider>
          </ContainerFullWidth>
        </ContainersContent>
      </form>
    </FormProvider>
  );
};

export default UpdateRole;
