import { ColumnsProps } from "../../layout/commun/Table";

export const columnsRewards: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "Points",
    accessor: "points",
  },
  {
    header: "",
    accessor: "redeemButton",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];
