import { ColumnsProps } from "../layout/commun/Table";
import { AllergenGroupPayload, GetAllergenQuery } from "../../graphql/types";
import { URLFILE } from "../../utils/config";
import saveAs from "file-saver";

export const filterListAllergens = (
  currentAllergens: AllergenGroupPayload[],
  data?: GetAllergenQuery
) => {
  if (data?.getAllergen) {
    return data?.getAllergen?.filter(
      (item) =>
        item._id !== currentAllergens.find((row) => row._id === item._id)?._id
    );
  }
  return [];
};

export const columns: ColumnsProps = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Ingredient Name",
    accessor: "name",
  },
  {
    header: "Nutrition Information",
    accessor: "nutrition",
  },
  {
    header: "Allergy Categories",
    accessor: "category",
  },
  {
    header: "Cost",
    accessor: "cost",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];

export const getIngrediantforExport = async () => {
  try {
    const href = `${URLFILE}/ingrediants`;
    const requestOptions = {
      method: "GET",
    };
    await fetch(href, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((blobData) => {
        saveAs(blobData, "ingrediants.xlsx");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  } catch (error) {
    throw new Error("Error when get ingrediants");
  }
};
