import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Menu,
  Button,
  MenuItem,
  IconButton,
  Box,
  Avatar,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete } from "@mui/icons-material";
//components
import PageHeader from "../../pageHeader/PageHeader";
import Table from "../../layout/commun/Table";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//utils
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { columns } from "./Utils";
import { getMessageError } from "../../Utils";
//queries
import {
  GetAllCountriesListDocument,
  useDeleteCountryMutation,
  useGetAllCountriesListQuery,
} from "../../../graphql/types";

const CountriesList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [countryId, setCountryId] = useState<string | null>(null);

  //queries&mutations
  const [deleteCountry] = useDeleteCountryMutation();
  const AllCountriesData = useGetAllCountriesListQuery({
    fetchPolicy: "no-cache",
  });

  const AllCountries = useMemo(
    () => AllCountriesData.data?.getCountriesList || [],
    [AllCountriesData.data]
  );

  //functions
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (countryId) {
      navigate(`/data/countries/update_country/${countryId}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const DeleteCountryHandler = async () => {
    setOpen(false);
    await deleteCountry({
      variables: { country: countryId || "" },
      refetchQueries: [GetAllCountriesListDocument],
      onCompleted() {
        enqueueSnackbar("Country deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const handleClickMenu =
    (item: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setCountryId(item);
    };

  const renderTableRows = () => {
    return (
      AllCountries?.map((el, index) => {
        return {
          index: index + 1,
          country: el.name?.EN,
          countryCode: el.code,
          currency: el.currency,
          palette: (
            <Box sx={{ display: "flex", gap: 2 }}>
              p
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  backgroundColor: el.primaryColor || "black",
                }}
              />
              S
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  backgroundColor: el.secondaryColor || "black",
                }}
              />
            </Box>
          ),
          actions: (
            <IconButton onClick={handleClickMenu(el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
          flag: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar
                alt={el?.flag || ""}
                src={el?.flag || ""}
                variant="rounded"
                style={{ width: 35, height: 35 }}
              />
            </div>
          ),
        };
      }) || []
    );
  };

  return (
    <>
      {useIsAuthorized("MANAGEMENT_CREATE_COUNTRY") && (
        <PageHeader
          buttonLink={`/data/countries/add_country`}
          buttonText={"Add Country"}
        />
      )}

      <br />
      <TableContainer>
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No countries found !"
          loading={AllCountriesData.loading}
          numberOfFakeRow={30}
        />
      </TableContainer>

      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("MANAGEMENT_UPDATE_COUNTRY") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}

          {useIsAuthorized("MANAGEMENT_DELETE_COUNTRY") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <Box onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </Box>
            </MenuItem>
          )}
        </Menu>

        {/* DELETE COUNTRY */}
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Country ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Country ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={DeleteCountryHandler}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default CountriesList;
