import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import {
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//Utils
import { columns } from "./Utils";
import { filterElements } from "../../Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//componenets
import Table from "../../layout/commun/Table";
import PageHeader from "../../pageHeader/PageHeader";
//styles
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { TableContainer } from "../../../styles/Table__styles";
//mutation & querys
import {
  AllergenGroupPayload,
  SortBy,
  useDeleteAllergenMutation,
  useGetAllergenWithPaginationQuery,
} from "../../../graphql/types";
//icons
import Delete from "../../../public/icons/delete";

const ListAllergens = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isAuthorizedToSeeDetails = useIsAuthorized("FOOD_ALLERGENS_DETAILS");
  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<AllergenGroupPayload | null>(null);
  const [sortBy, setSortBy] = useState<SortBy | null>(null);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);

  //querys and muations
  const dataGetAllergen = useGetAllergenWithPaginationQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        name: search,
        sort: sortBy,
      },
    },
    fetchPolicy: "network-only",
  });

  const [deleteAllergen, dataDeleteAllergen] = useDeleteAllergenMutation();

  const allergens = useMemo(
    () => dataGetAllergen.data?.getAllergenWithPagination.data || [],
    [dataGetAllergen.data]
  );

  const count = useMemo(
    () => dataGetAllergen.data?.getAllergenWithPagination.count || 0,
    [dataGetAllergen.data]
  );

  //functions
  const handleClickMenu =
    (item: AllergenGroupPayload) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDetails = () => {
    setAnchorEl(null);
    if (selected) {
      navigate(`/food/allergens/view_allergen/${selected._id}`);
    }
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (selected) {
      navigate(`/food/allergens/update_allergen/${selected._id}`);
    }
  };

  const handleCloseDelete = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const onDeleteAllegren = async () => {
    setOpen(false);
    if (selected) {
      try {
        await deleteAllergen({
          variables: { allergenId: selected._id || "" },
          onCompleted: () => {
            dataGetAllergen.refetch();
            enqueueSnackbar("Allergen succuessfully deleted", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setSelected(null);
          },
        });
      } catch (error) {
        setSelected(null);
        console.log("#error", error);
      }
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  //render
  const renderTableRows = () => {
    return allergens.map((item) => {
      const isLoading =
        selected?._id === item._id && dataDeleteAllergen.loading;
      return {
        id: item?.ref || "--",
        name: isAuthorizedToSeeDetails ? (
          <a
            style={{ textDecoration: "none" }}
            href={`/food/allergens/view_allergen/${item._id}`}
          >
            <Button>{item?.name?.EN}</Button>
          </a>
        ) : (
          item.name?.EN
        ),
        ingredients:
          item?.ingredients && item?.ingredients.length > 0 ? (
            <>
              {item?.ingredients
                .filter((el, index) => index < 5)
                .map((row) => row?.name?.EN)
                .join()}{" "}
              {item?.ingredients.length >= 5 && "..."}{" "}
            </>
          ) : (
            "--"
          ),
        actions: isLoading ? (
          <CircularProgress size={20} color="secondary" />
        ) : (
          <IconButton onClick={handleClickMenu(item)}>
            <MoreVertIcon />
          </IconButton>
        ),
      };
    });
  };

  return (
    <div>
      {useIsAuthorized("FOOD_ALLERGENS_CREATE") ? (
        <PageHeader
          buttonLink={`/food/allergens/add_allergen`}
          buttonText={"Add Allergen"}
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      ) : (
        <PageHeader
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      )}

      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetAllergen.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Allergy Category found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("FOOD_ALLERGENS_DETAILS") && (
            <MenuItem
              onClick={handleCloseDetails}
              className={classes.menu_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Details</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("FOOD_ALLERGENS_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Update</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("FOOD_ALLERGENS_DELETE") && (
            <MenuItem
              onClick={handleCloseDelete}
              className={classes.menu_delete_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">
              Delete Allergen Category ?
            </span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete {selected?.name?.EN} Category ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={onDeleteAllegren}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default React.memo(ListAllergens);
