import { Theme, lighten } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useStylesHeader: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "100%",
      marginBottom: "20px",
      //   padding: "8px 12px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      //   backgroundColor: "rgba(241, 245, 249, 1)",
      //   borderRadius: "8px",
      position: "sticky",
      // top: "68px",
      "&::before": {
        content: '""',
        display: "block",
        height: "20px",
        width: "100%",
        // backgroundColor: "rgba(241, 245, 249, 1)",
        position: "absolute",
        top: "-19px",
      },
      "&::after": {
        content: '""',
        display: "block",
        height: "10px",
        width: "100%",
        // backgroundColor: "rgba(241, 245, 249, 1)",
        position: "absolute",
        top: "41px",
      },
    },
    distant_elements: {},
    title: {
      fontSize: "18px",
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    title_gold: {
      color: theme.palette.secondary.main,
    },
    main_button: {
      // margin: "0 !important",
      // fontSize: "16px",
      // "& path": {
      //   fill: "white",
      // },
      // paddingRight: "26px",
      // paddingLeft: "20px",
    },
    filter_menu: {
      "& .MuiPaper-root": {
        minWidth: "160px !important",
      },
      borderRadius: "10px !important",
      top: "6px !important",
    },
    advanced_filter_menu: {
      "& .MuiPaper-root": {
        minWidth: "260px !important",
      },
      borderRadius: "10px !important",
      top: "6px !important",
    },
    filter_menu_item: {
      "&:hover": {
        backgroundColor: "#fff !important",
      },
      padding: "0px !important",
      "& > div": {
        padding: "8px 10px",
        width: "100%",
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: `${lighten(
            theme.palette.secondary.main,
            0.7
          )} !important`,
        },
        "& .menu_title": {
          fontWeight: `${500} !important`,
        },
      },
    },
    filter_menu_item_selected: {
      color: `${theme.palette.secondary.main} !important`,
      "& > div": {
        backgroundColor: `${lighten(
          theme.palette.secondary.main,
          0.85
        )} !important`,
      },
    },
  })
);
