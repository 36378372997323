//material ui
import { createTheme } from "@mui/material/styles";
//context api
import { useAuth } from "../contextAPI";

export const useCustomTheme = () => {
  const auth = useAuth();
  const primaryColor = auth?.primaryColor || "#232222";
  const secondaryColor = auth?.secondaryColor || "#C7893E";

  const theme = createTheme({
    palette: {
      primary: { main: primaryColor, contrastText: "#fff" },
      secondary: { main: secondaryColor, contrastText: "#232222" },
      text: { primary: "#888888", secondary: "#C4C4C4" },
      error: { main: "#C72D2D", contrastText: "#fff" },
      warning: { main: "#1BA9A5", contrastText: "#C72D2D" },
      success: { main: "#4CD964", contrastText: "#DDDEDF" },
      common: { white: "#F9F9F9", black: "" },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      h1: {
        fontSize: "28px",
        fontWeight: 500,
      },
      h2: {
        fontSize: "20px",
        fontWeight: 500,
      },
      h3: {
        fontSize: "18px",
        fontWeight: 500,
      },
      h4: {
        fontSize: "16px",
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: "14px",
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: "12px",
        fontWeight: 500,
      },
      body1: {
        fontSize: "14px",
      },
      body2: {
        fontSize: "12px",
      },
      button: {
        textTransform: "capitalize",
      },
    },
    shape: {
      borderRadius: 5,
    },
    components: {
      MuiList: {
        styleOverrides: {
          root: {
            padding: "6px",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "#C7893E40",
              color: secondaryColor,
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            height: "50px",
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: "darkgray",
          },
          root: {
            width: 30,
            height: 16,
            padding: 0,
            margin: 0,
            "&:active": {
              "& .MuiSwitch-thumb": {
                width: 15,
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                transform: "translateX(9px)",
              },
            },
            "& .MuiSwitch-switchBase": {
              padding: 2,
              "&.Mui-checked": {
                transform: "translateX(14px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                  opacity: 1,
                },
              },
            },
            "& .MuiSwitch-thumb": {
              boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
              width: 12,
              height: 12,
              borderRadius: 6,
            },
            "& .MuiSwitch-track": {
              borderRadius: 16 / 2,
              opacity: 1,
              boxSizing: "border-box",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 5,
            "& .MuiChip-label": {
              textTransform: "lowercase",
              "&:first-letter": {
                textTransform: "uppercase",
              },
            },
          },
          label: {
            padding: "0 6px",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            "&:hover": {
              background: "#C7893E40",
              color: secondaryColor,
            },
          },
          tag: {
            background: "#232222",
            color: "#fff",
            "& .MuiSvgIcon-root": {
              fill: "#fff",
              marginLeft: "1px",
            },
          },
        },
      },
    },
  });

  return { theme };
};
