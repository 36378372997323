import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
//material ui
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//styles
import {
  HeaderSearch,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../styles/Navigation__styles";
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
//compoments
import Table from "../../layout/commun/Table";
//icons
import { FiSearch } from "react-icons/fi";
//utils
import { columns } from "./Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//queries&mutations
import {
  CustomerPayload,
  GetWhatsappBlackListDocument,
  useGetWhatsappBlackListQuery,
  useUpdateCustomerWhatsappInfoMutation,
} from "../../../graphql/types";
import { useSnackbar } from "notistack";

const BlackListedCustomers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");
  //styles
  const classes = useStylesMenu();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [search, setSearch] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<CustomerPayload | null>(null);

  //queries&mutations
  const blackListedCustomersData = useGetWhatsappBlackListQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        nameCustomer: search,
      },
    },
    fetchPolicy: "network-only",
  });
  const [removeCustomerFromWhatsappBlackList] =
    useUpdateCustomerWhatsappInfoMutation();

  const blackListedCustomers = useMemo(
    () => blackListedCustomersData.data?.getWhatsappBlackList.data || [],
    [blackListedCustomersData.data]
  );

  const count = useMemo(
    () => blackListedCustomersData.data?.getWhatsappBlackList.count || 0,
    [blackListedCustomersData.data]
  );

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderTableRows = () => {
    return blackListedCustomers.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        id: item.ref,
        name: item.fname + " " + item.lname,
        mobile: item.phone?.prefix + " " + item.phone?.number,
        email: item.email,
        dob: moment(item.dob).format("DD-MMM-YYYY"),
        actions: (
          <IconButton onClick={handleClickMenu(item)}>
            <MoreVertIcon />
          </IconButton>
        ),
      };
    });
  };

  const debounced = useCallback(
    _.debounce((event) => {
      setSearch(event.target.value);
    }, 800),
    []
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu =
    (item: CustomerPayload) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const goToCustomerProfileHandler = () => {
    navigate(`/admin/customers/view_customer/${selected?._id}`);
  };

  const removeFromWhatsappBlackList = async () => {
    setAnchorEl(null);
    if (selected) {
      try {
        await removeCustomerFromWhatsappBlackList({
          variables: {
            input: {
              id: selected._id || "",
              whatsappAutomation: true,
            },
          },
          refetchQueries: [
            {
              query: GetWhatsappBlackListDocument,
              variables: {
                input: {
                  page: 1,
                  documentsPerPage: 100,
                  nameCustomer: search,
                },
              },
            },
          ],
        });
        enqueueSnackbar("Customer removed from Whatsapp blacklist", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setSelected(null);
      } catch (error) {
        setSelected(null);
        console.log("#error", error);
      }
    }
  };

  return (
    <>
      <Box marginLeft={40} marginRight={40} marginTop={3} marginBottom={3}>
        <HeaderSearch>
          <SearchIconWrapper>
            <FiSearch />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search..."
            inputProps={{ "aria-label": "search" }}
            onChange={(event) => debounced(event)}
          />
        </HeaderSearch>
      </Box>

      <TableContainer>
        <Table
          columns={columns}
          loading={blackListedCustomersData.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No BlackListed customers to display !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          <MenuItem
            onClick={removeFromWhatsappBlackList}
            className={classes.menu_container}
            disableRipple
          >
            <div>
              <span className="menu_title">Remove from Whatsapp blacklist</span>
            </div>
          </MenuItem>
          {isAuthorizedToSeeProfile && (
            <MenuItem
              onClick={goToCustomerProfileHandler}
              className={classes.menu_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Go to customer profile</span>
              </div>
            </MenuItem>
          )}
        </Menu>
      </div>
    </>
  );
};

export default BlackListedCustomers;
