import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import clsx from "clsx";
//material
import {
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//icons
import Delete from "../../../public/icons/delete";
//components
import PageHeader from "../../pageHeader/PageHeader";
import Table from "../../layout/commun/Table";
//Utils
import { columns, getIngrediantforExport } from "../Utils";
import { filterElements } from "../../Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//styles
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { TableContainer } from "../../../styles/Table__styles";
//query and mutation
import {
  SortBy,
  useDeleteIngredientMutation,
  useGetIngredientsQuery,
} from "../../../graphql/types";
//context
import { useAuth } from "../../../contextAPI";

const ListIngredients = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isAuthorizedToSeeDetails = useIsAuthorized("FOOD_INGREDIENTS_DETAILS");
  const auth = useAuth();
  //state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [idIngredient, setIdIngredient] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [sortBy, setSortBy] = useState<SortBy | null>(null);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();
  const ButtonClasses = useStylesButtons();

  //queries & mutations
  const [deleteIngredient, { loading }] = useDeleteIngredientMutation();

  const dataListIngredient = useGetIngredientsQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        name: search,
        sort: sortBy,
      },
    },
    fetchPolicy: "network-only",
  });

  const listIngredient = useMemo(
    () => dataListIngredient.data?.getIngredients.data || [],
    [dataListIngredient.data]
  );

  const count = React.useMemo(
    () => dataListIngredient.data?.getIngredients.count || 0,
    [dataListIngredient.data]
  );

  //functions
  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setIdIngredient(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDetails = () => {
    setAnchorEl(null);
    if (idIngredient) {
      navigate(`/food/ingredients/view_ingredient/${idIngredient}`);
    }
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (idIngredient) {
      navigate(`/food/ingredients/update_ingredient/${idIngredient}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteIngredient = () => {
    setOpen(false);
    idIngredient &&
      deleteIngredient({
        variables: {
          input: idIngredient,
        },
        onCompleted: () => {
          dataListIngredient.refetch();
          enqueueSnackbar("Ingredient succuessfully deleted", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleExport = async () => {
    setIsLoading(true);
    await getIngrediantforExport();
    setIsLoading(false);
  };

  //render
  const renderTableRows = () => {
    return (
      listIngredient.map((el) => {
        const isLoading = idIngredient === el._id && loading;
        return {
          id: el.ref,
          name: isAuthorizedToSeeDetails ? (
            <a
              style={{ textDecoration: "none" }}
              href={`/food/ingredients/view_ingredient/${el?._id}`}
            >
              <Button>{el?.name?.EN}</Button>
            </a>
          ) : (
            el.name?.EN
          ),
          nutrition: el?.nutritions?.length
            ? el?.nutritions?.map((element, i) =>
                i < (el?.nutritions?.length ? el?.nutritions?.length - 1 : 0)
                  ? `${element?.nutrition?.name}, `
                  : element?.nutrition?.name
              )
            : "--",
          category: el?.allergenGroups?.length
            ? el?.allergenGroups?.map((element, i) =>
                i <
                (el?.allergenGroups?.length
                  ? el?.allergenGroups?.length - 1
                  : 0)
                  ? `${element?.name.EN}, `
                  : element?.name.EN
              )
            : "--",

          cost: el?.cost
            ? el.cost + " " + auth.countryCurrency
            : "0" + " " + auth.countryCurrency,
          actions: isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <IconButton
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleClickMenu(e, el._id || "")
              }
            >
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <div>
      {useIsAuthorized("FOOD_INGREDIENTS_CREATE") ? (
        <PageHeader
          buttonLink={`/food/ingredients/add_ingredient`}
          buttonText={"Add Ingredient"}
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      ) : (
        <PageHeader
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      )}

      <TableContainer>
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No Ingredients found !"
          loading={dataListIngredient.loading}
          numberOfFakeRow={30}
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <Box sx={{ p: 2 }}>
        <Button
          onClick={handleExport}
          className={clsx(ButtonClasses.button_open_list_page)}
        >
          Export Excel&nbsp;&nbsp;
          {isLoading ? <CircularProgress size={14} color="inherit" /> : ""}
        </Button>
      </Box>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("FOOD_INGREDIENTS_DETAILS") && (
            <MenuItem
              onClick={handleCloseDetails}
              className={classes.menu_container}
              disableRipple={true}
            >
              <div
                onClick={() =>
                  navigate(`/food/ingredients/view_ingredient/${idIngredient}`)
                }
              >
                <span className="menu_title">Details</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("FOOD_INGREDIENTS_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <div>
                <span className="menu_title">Update</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("FOOD_INGREDIENTS_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Ingredient ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Ingredient ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={() => handleDeleteIngredient()}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ListIngredients;
