function DeactivatedCustomersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 0 350 350"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(40.000000,300.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path d="M1250 2590 c-184 -23 -329 -121 -381 -258 -27 -71 -36 -221 -20 -316 11 -62 10 -69 -9 -93 -16 -21 -20 -40 -20 -106 0 -44 5 -98 12 -120 13 -43 69 -107 93 -107 8 0 17 -12 21 -27 19 -88 37 -135 69 -175 19 -26 40 -48 47 -50 9 -3 13 -27 12 -79 0 -60 -6 -84 -27 -126 -56 -109 -116 -147 -348 -224 -173 -57 -325 -129 -435 -208 -99 -70 -126 -116 -122 -206 l3 -60 283 -3 282 -2 0 25 0 25 -260 0 -260 0 0 30 c0 59 25 96 100 149 114 79 252 145 435 206 94 31 190 68 214 82 65 38 124 101 156 168 25 50 29 70 29 145 l1 87 -40 40 c-50 48 -70 89 -86 174 -12 66 -13 67 -51 79 -55 16 -77 67 -78 173 0 42 3 77 8 77 4 0 15 7 25 14 16 13 16 18 2 85 -8 40 -15 109 -15 155 0 135 37 228 120 299 164 140 563 138 723 -3 104 -91 141 -254 101 -446 -14 -72 -14 -77 3 -90 10 -7 22 -14 27 -14 5 0 7 -39 4 -88 -5 -107 -25 -147 -80 -164 -34 -10 -38 -15 -44 -57 -11 -79 -42 -146 -87 -190 l-42 -42 0 -88 c0 -78 4 -94 33 -154 41 -82 100 -136 205 -186 l79 -38 -35 -22 c-92 -57 -167 -179 -188 -308 l-12 -73 -468 0 -469 0 0 -25 0 -25 474 0 474 0 7 -41 c16 -93 106 -215 205 -279 96 -61 250 -78 368 -42 219 69 355 310 298 531 -61 238 -293 382 -534 331 -44 -10 -57 -8 -131 19 -173 63 -261 172 -261 324 0 40 5 53 28 75 55 52 102 148 102 208 0 15 11 28 38 41 23 12 47 37 62 64 22 39 25 56 25 145 0 85 -3 105 -19 122 -16 19 -17 27 -7 85 43 248 -34 436 -213 521 -39 19 -94 39 -123 45 -74 15 -227 21 -303 11z m1064 -1733 c251 -115 312 -445 119 -641 -85 -86 -178 -121 -307 -114 -378 20 -509 520 -190 724 77 50 135 64 235 60 66 -2 100 -9 143 -29z" />
        <path d="M1942 707 c-29 -29 -52 -59 -52 -68 0 -13 44 -59 111 -115 21 -17 25 -28 21 -57 -4 -32 -8 -37 -31 -37 -29 0 -101 -64 -101 -91 0 -18 91 -109 109 -109 7 0 43 29 79 65 36 36 68 65 72 65 4 0 36 -29 71 -65 35 -36 71 -65 79 -65 20 0 110 91 110 111 0 8 -29 44 -65 79 -36 35 -65 67 -65 71 0 4 29 36 65 71 36 36 65 71 65 79 0 8 -25 39 -55 69 l-55 54 -75 -74 -75 -74 -73 72 c-40 39 -75 72 -78 72 -2 0 -28 -24 -57 -53z m135 -89 l73 -72 75 74 74 74 26 -24 25 -23 -70 -73 c-39 -39 -70 -76 -70 -80 0 -5 32 -41 72 -81 l72 -72 -24 -26 -24 -26 -71 71 c-39 38 -77 70 -85 70 -8 0 -46 -32 -85 -70 l-71 -71 -24 26 -24 26 73 73 c85 85 85 79 -8 171 l-61 59 22 23 c12 13 24 23 27 23 3 0 38 -33 78 -72z" />
        <path d="M726 461 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
      </g>
    </svg>
  );
}

export default DeactivatedCustomersIcon;
