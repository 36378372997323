import { useState } from "react";
import { Navigate, useNavigate, Link } from "react-router-dom";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  useWatch,
} from "react-hook-form";
import { useSnackbar } from "notistack";
//material
import {
  Box,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
  Avatar,
  Autocomplete,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
//contextAPI
import { useAuth } from "../../../contextAPI";
//mutations and querys
import {
  useGetActiveCountriesQuery,
  useLoginMutation,
} from "../../../graphql/types";
//Utils
import { getMessageError } from "../../Utils";
//logos
import DietureLogoLogin from "../../../public/icons/dietureLogoLogin";
import DietureMiniLogo from "../../../public/icons/dietureMiniLogo";
//styles
import {
  Container,
  RightContainer,
  ButtonContainer,
  ForgetPassword,
  useStylesAuth,
} from "../../../styles/Auth__styles";
//types
import { loginProps } from "../Types";
import { ApolloError } from "@apollo/client";

const Login = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const from = "/food/dishes/list_dishes";

  //state
  const [showPassword, setShowPassword] = useState<boolean>(false);

  //style
  const AuthClasses = useStylesAuth();

  //react-hook-form
  const methods = useForm<loginProps>({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  //querys and mutations
  const [login, dataLogin] = useLoginMutation();
  const dataCountries = useGetActiveCountriesQuery();

  //functions
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<loginProps> = async (data) => {
    try {
      const res = await login({
        variables: {
          input: {
            email: data.username,
            password: data.password,
            country: data.country._id,
          },
        },
      });
      if (res.data?.loginUserFromDashboard.token) {
        const { token } = res.data.loginUserFromDashboard ?? {
          token: null,
        };
        auth.signIn({
          token,
          code: res.data.loginUserFromDashboard.currentCountry.code || "",
          currency:
            res.data.loginUserFromDashboard.currentCountry.currency || "",
          primary:
            res.data.loginUserFromDashboard.currentCountry.primaryColor || "",
          secondary:
            res.data.loginUserFromDashboard.currentCountry.secondaryColor || "",
        });
        localStorage.setItem("token", token);
        navigate(from, { replace: true });
      }
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  if (auth.token) {
    return <Navigate to="/dashboard/home" replace />;
  }

  return (
    <Box display="flex">
      <Container>
        <DietureLogoLogin className={AuthClasses.auth__logo} />
      </Container>
      <RightContainer>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="150px"
          width="150px"
          bgcolor="secondary.main"
          borderRadius="5px"
          boxShadow="#C7893E 0px 5px 15px"
        >
          <DietureMiniLogo />
        </Box>
        <FormProvider {...methods}>
          <form style={{ width: "50%" }} onSubmit={handleSubmit(onSubmit)}>
            <p className={AuthClasses.auth__title}>Username</p>
            <TextField
              id="demo-helper-text-misaligned"
              placeholder="username"
              autoComplete="off"
              size="medium"
              fullWidth
              {...register("username", {
                required: "Username is Mandatory",
              })}
            />
            <p className={AuthClasses.auth__error__helper}>
              {errors?.username?.message}
            </p>
            <p className={AuthClasses.auth__title}>Password</p>
            <TextField
              id="outlined-adornment-password"
              autoComplete="off"
              placeholder="password"
              fullWidth
              {...register("password", {
                required: "Password is Mandatory",
              })}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors?.password?.message && (
              <p className={AuthClasses.auth__error__helper}>
                {errors?.password?.message}
              </p>
            )}

            <Box width="100%">
              <p className={AuthClasses.auth__title}>Country</p>
              <Controller
                name="country"
                rules={{
                  required: "Select Country",
                }}
                control={control}
                render={({
                  field: { onChange: Change, value, ref, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      ref={ref}
                      id="country"
                      options={dataCountries.data?.getActiveCountries || []}
                      value={value}
                      getOptionLabel={(option) => option.name?.EN || ""}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      filterSelectedOptions
                      onChange={(_, data) => Change(data)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Avatar
                            alt={option.name?.EN || ""}
                            src={option.flag || ""}
                            sx={{ width: 30, height: 30 }}
                          />
                          <Typography style={{ marginLeft: "10%" }}>
                            {option.name?.EN}
                          </Typography>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Country" />
                      )}
                    />
                    <p className={AuthClasses.auth__error__helper}>
                      {error?.message}
                    </p>
                  </>
                )}
              />
            </Box>

            <ForgetPassword>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "text.primary" }}
                  >
                    Remember me
                  </Typography>
                }
              />
              <Link
                to="/forget_password"
                className={AuthClasses.auth__text__link}
              >
                Forget Password
              </Link>
            </ForgetPassword>
            <ButtonContainer>
              <Button
                variant="contained"
                className={AuthClasses.auth__button__submit}
                type="submit"
                disabled={dataLogin.loading}
              >
                {dataLogin.loading && (
                  <CircularProgress
                    size={20}
                    style={{ marginRight: 10, color: "white" }}
                  />
                )}
                Log In
              </Button>
            </ButtonContainer>
          </form>
        </FormProvider>
      </RightContainer>
    </Box>
  );
};

export default Login;
