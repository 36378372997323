import { ColumnsProps } from "../layout/commun/Table";

export const PackingGroupsListColumns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "First Packer",
    accessor: "firstPacker",
  },
  {
    header: "Second Packer",
    accessor: "secondPacker",
  },
  {
    header: "Other Packers",
    accessor: "otherPackers",
  },
  {
    header: "Deliveries number",
    accessor: "number",
  },
  {
    header: "Drivers",
    accessor: "drivers",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
