import React from "react";

function Delete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="49"
      fill="none"
      viewBox="0 0 42 49"
    >
      <path
        fill="#EC7474"
        d="M2.946 43.556C2.946 46.55 5.598 49 8.84 49h23.572c3.24 0 5.893-2.45 5.893-5.444V10.889H2.946v32.667zM41.25 2.722H30.937L27.991 0H13.26l-2.947 2.722H0v5.445h41.25V2.722z"
      ></path>
    </svg>
  );
}

export default Delete;
