import { useEffect } from "react";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  useWatch,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import moment from "moment";
//material_ui
import {
  Box,
  TextField,
  MenuItem,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
} from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";

//styles
import { useStylesLeads } from "../../../../styles/Leads__styles";
import {
  useStylesContainers,
  ContainerFullWidth,
  ContainerLeft,
  LeftElement,
  ContainersContent,
} from "../../../../styles/Containers__styles";

//components
import PageAddHeader from "../../../pageAddHeader/pageAddHeader";
import PhoneInput from "../../../layout/PhoneInput";

//utils
import { countries } from "../../../../staticData/staticData";
import {
  goalsOptions,
  weightOptions,
  heightOptions,
  ActivityOptions,
  calculateRecommendedCalories,
} from "../../Utils";
import { countryList } from "../../../layout/PhoneInput/CountryList";
//types
import { customersProps } from "../../Types";
//queries&mutations
import {
  Gender,
  useGetLeadByIdQuery,
  Weight,
  Height,
  AdressType,
  WeightInput,
  HeightInput,
  ConvertLeadToCustomerWitoutPlanInput,
  LeadPayload,
} from "../../../../graphql/types";

//context api

//utils
import { useCountryPrefix } from "../../../../utils/Hooks/useCountryPrefix";
import TabContentHeader from "../../../TabContentHeader/TabContentHeader";
import AddressInputForTranferBox from "./AddressInputForTranferBox";
interface ConvertCustomerModelProps {
  lead: LeadPayload | null;
  setLeadDetails: (details: ConvertLeadToCustomerWitoutPlanInput) => void;
  onCancelLeadModal: () => void;
}
const ConvertCustomerModel: React.FC<ConvertCustomerModelProps> = ({
  lead,
  setLeadDetails,
  onCancelLeadModal,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const idLeadConverted = lead?._id || null;

  //styles
  const ContainersClasses = useStylesContainers();
  const LeadsClasses = useStylesLeads();

  const { data: dataLeadConverted, loading: loadingLead } = useGetLeadByIdQuery(
    {
      variables: {
        leadId: idLeadConverted || "",
      },
      skip: !idLeadConverted,
    }
  );

  const countryPrefix = useCountryPrefix();

  //react-hook-form
  const methods = useForm<customersProps>({
    defaultValues: {
      first__name: "",
      last__name: "",
      email: "",
      phone__number: "",
      phone__prefix: countryPrefix?.callingCode,
      nationality: "",
      gender: null,
      birth__date: null,
      weight: 0,
      weight__unit: "KG",
      height: 0,
      height__unit: "CM",
      influencer: false,
      activity: null,
      protein: 0,
      carbs: 0,
      fat: 0,
      addresses: [
        {
          address__name: "",
          area__name: "",
          building__name: "",
          street__name: "",
          aprt__number: "",
          floor__number: "",
          address__type: "",
          address__kind: [],
          delivery__number: "",
          delivery__prefix: countryPrefix?.callingCode,
          delivery__days: [
            {
              value: "monday",
              label: "Monday",
            },
            {
              value: "tuesday",
              label: "Tuesday",
            },
            {
              value: "wednesday",
              label: "Wednesday",
            },
            {
              value: "thursday",
              label: "Thursday",
            },
            {
              value: "Friday",
              label: "Friday",
            },
            {
              value: "saturday",
              label: "Saturday",
            },
            {
              value: "sunday",
              label: "Sunday",
            },
          ],
          zone: "",
          delivery__time: null,
        },
      ],
      address__name: "",
      area__name: "",
      building__name: "",
      street__name: "",
      aprt__number: "",
      floor__number: "",
      address__type: [],
      address__kind: [],
      delivery__number: "",
      delivery__prefix: countryPrefix?.callingCode,
      preferences: [],
      goals: null,
      quantity: "0",
      quantity__unit: "KG",
      is__free: false,
      program__name: null,
      plan__type: null,
      meals__duration: [],
      dayPerWeek: null,
      meals: [],
      start__date: null,
      paymentMethod: null,
      recommended__calories: "",
      promoCode: "",
      daysOff: [],
      isCustomPackage: false,
      customPackagesQuantities: {
        breakfast: 0,
        lunchDinner: 0,
        snack: 0,
      },
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  const NationalityWatch = useWatch({
    control,
    name: "nationality",
  });

  const ActivityWatch = useWatch({
    control,
    name: "activity",
  });

  //useEffect
  useEffect(() => {
    if (dataLeadConverted) {
      const {
        fname,
        lname,
        gender,
        email,
        nationality,
        phone,
        dob,
        influencer,
        height,
        weight,
        targetWeight,
        goal,
        calories,
      } = dataLeadConverted?.getLeadById ?? null;
      setValue("first__name", fname || "");
      setValue("last__name", lname || "");
      setValue("email", email || "");
      setValue("influencer", influencer || false);
      setValue("birth__date", dob || "");
      setValue("gender", gender || "MALE");
      if (nationality) {
        setValue(
          "nationality",
          countries?.find((el) => el === nationality) ?? ""
        );
      } else {
        const countryName = countryList.find(
          (el) => el.callingCode === phone?.prefix?.substring(1)
        );
        setValue("nationality", countryName?.name.common ?? "");
      }
      setValue("recommended__calories", calories || "");
      setValue("goals", goal || "");
      setValue("height", height?.value || "");
      setValue("height__unit", height?.unit || "CM");
      setValue("weight", weight?.value || "");
      setValue("weight__unit", weight?.unit || "KG");
      if (targetWeight?.value) {
        setValue("quantity", (targetWeight?.value || 0).toString() || "0");
      }
      setValue("quantity__unit", targetWeight?.unit || "KG");
      setValue("phone__number", phone?.number || "");
      setValue("phone__prefix", phone?.prefix?.replace("+", "") || "");
    }
  }, [dataLeadConverted, setValue]);

  const onSubmit: SubmitHandler<customersProps> =  (
    data: customersProps
  ) => {
    const phone = data?.phone__number?.replace(/\s/g, "") ?? "";
    const customerInputdata: ConvertLeadToCustomerWitoutPlanInput = {
      _id: idLeadConverted || "",
      fname: data?.first__name,
      lname: data?.last__name,
      email: data?.email,
      dob: new Date(data?.birth__date),
      gender: data?.gender as Gender,
      calories: data?.recommended__calories,
      height: {
        unit: data?.height__unit,
        value: Number(data?.height),
      },
      weight: {
        unit: data?.weight__unit,
        value: Number(data?.weight),
      },
      phone: {
        prefix: `+${data.phone__prefix}`,
        number: phone,
      },
      influencer: data?.influencer,
      nationality: data?.nationality,
      addresses: {
        nick: data.addresses[0].address__name,
        build: data.addresses[0]?.building__name,
        nbrApt: data.addresses[0]?.aprt__number,
        street: data.addresses[0]?.street__name,
        area: data.addresses[0]?.area__name,
        floor: data.addresses[0]?.floor__number,
        type: data.addresses[0]?.address__type as AdressType,
        daysOfTheWeek: [],
        deliveryPhone: {
          prefix: `+${data.addresses[0]?.delivery__prefix}`,
          number: data.addresses[0]?.delivery__number.replace(/\s/g, ""),
        },
        zone: data.addresses[0]?.zone,
        deliveryTime: data.addresses[0]?.delivery__time,
      },

      goal: data.goals,
    };

    setLeadDetails(customerInputdata);
    enqueueSnackbar("Customer details saved", {
      variant: "success",
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
    });
    setTimeout(() => closeSnackbar(), 3000);
    onCancelLeadModal();
  };

  useEffect(() => {
    if (
      dataWatch.gender &&
      dataWatch.activity &&
      dataWatch.weight &&
      dataWatch.weight__unit &&
      dataWatch.height &&
      dataWatch.height__unit &&
      dataWatch.birth__date &&
      dataWatch.goals &&
      dataWatch.program__name
    ) {
      const { calories, carb, fat, prot } = calculateRecommendedCalories(
        dataWatch.gender,
        {
          unit: dataWatch.weight__unit,
          value: dataWatch.weight,
        } as WeightInput,
        {
          unit: dataWatch.height__unit,
          value: dataWatch.height,
        } as HeightInput,
        moment().diff(moment(dataWatch.birth__date), "years"),
        dataWatch.activity.menuValue,
        dataWatch?.program__name?.name?.EN || "",
        dataWatch.goals
      );

      setValue("protein", +prot);
      setValue("carbs", +carb);
      setValue("fat", +fat);
      setValue("recommended__calories", calories);
    }
  }, [
    dataWatch.gender,
    dataWatch.activity,
    dataWatch.weight,
    dataWatch.weight__unit,
    dataWatch.height,
    dataWatch.height__unit,
    dataWatch.birth__date,
    dataWatch.goals,
    dataWatch.program__name,
  ]);

  return (
    <FormProvider {...methods}>
      <TabContentHeader buttonText="Cancel" buttonFnc={onCancelLeadModal} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title="Convert Lead to  Customer"
          buttonText="Convert Lead"
        />

        <ContainersContent>
          <ContainerFullWidth>
            <FormProvider {...methods}>
              <ContainerLeft>
                {/* Personal Informations */}
                <LeftElement>
                  <p className={clsx(ContainersClasses.section__title)}>
                    Personal Informations
                  </p>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        First Name*
                      </p>
                      <TextField
                        placeholder="First Name"
                        sx={{ width: "100%" }}
                        {...register("first__name", {
                          required: "Enter Customer First Name",
                          minLength: {
                            value: 2,
                            message: "Please enter a least 2 characters",
                          },
                        })}
                        disabled={loadingLead}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.first__name?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Last Name*
                      </p>
                      <TextField
                        placeholder="Last Name"
                        sx={{ width: "100%" }}
                        {...register("last__name", {
                          required: "Enter Customer Last Name",
                          minLength: {
                            value: 2,
                            message: "Please enter a least 2 characters",
                          },
                        })}
                        disabled={loadingLead}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.last__name?.message}
                      </p>
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Email
                      </p>
                      <TextField
                        placeholder="Email"
                        sx={{ width: "100%" }}
                        {...register("email", {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Enter a valid Email",
                          },
                          required: "Email is Mandatory",
                        })}
                        disabled={loadingLead}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.email?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Phone Number*
                      </p>
                      <PhoneInput
                        labelFormPrefix="phone__prefix"
                        labelFormNumber="phone__number"
                        placeholder="Phone Number"
                        loading={loadingLead}
                      />
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Nationality
                      </p>
                      <Controller
                        name="nationality"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            {...rest}
                            freeSolo
                            options={countries}
                            value={NationalityWatch}
                            onChange={(_, data) => Change(data)}
                            getOptionLabel={(option) => option}
                            disabled={loadingLead}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputRef={ref}
                                variant="outlined"
                                sx={{ width: "100%" }}
                                placeholder="Nationality"
                              />
                            )}
                          />
                        )}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.nationality?.message}
                      </p>
                    </Box>

                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Birth Date
                      </p>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <Controller
                          name="birth__date"
                          control={control}
                          rules={{
                            validate: (value) =>
                              value === null ||
                              moment(value).isValid() ||
                              "Enter a valid Birth Date",
                          }}
                          render={({ field: { ref, ...rest } }) => (
                            <DesktopDatePicker
                              disabled={loadingLead}
                              {...rest}
                              inputFormat="DD/MM/YYYY"
                              disablePast={false}
                              disableFuture={true}
                              renderInput={(params) => (
                                <TextField {...params} sx={{ width: "100%" }} />
                              )}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.birth__date?.message}
                      </p>
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Weight
                      </p>

                      <TextField
                        placeholder="Weigth"
                        sx={{ width: "70%" }}
                        type="number"
                        {...register("weight", {
                          pattern: {
                            value: /^\d*$/,
                            message: "Enter a valid Weigth",
                          },
                        })}
                        disabled={loadingLead}
                      />
                      <Controller
                        name="weight__unit"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "28%", marginLeft: "3px" }}
                            className={LeadsClasses.select_container}
                            disabled={loadingLead}
                          >
                            {weightOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue(
                                    "weight__unit",
                                    option.menuValue as Weight
                                  )
                                }
                                value={option.menuValue}
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Height
                      </p>
                      <TextField
                        placeholder="Height"
                        sx={{ width: "70%" }}
                        type="number"
                        {...register("height", {
                          pattern: {
                            value: /^\d*$/,
                            message: "Enter a valid Height",
                          },
                        })}
                        disabled={loadingLead}
                      />
                      <Controller
                        name="height__unit"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "28%", marginLeft: "3px" }}
                            className={LeadsClasses.select_container}
                            disabled={loadingLead}
                          >
                            {heightOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue(
                                    "height__unit",
                                    option.menuValue as Height
                                  )
                                }
                                value={option.menuValue}
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Gender
                      </p>
                      <Controller
                        name="gender"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                        }) => (
                          <RadioGroup value={dataWatch?.gender} row>
                            <FormControlLabel
                              disabled={loadingLead}
                              value="MALE"
                              control={<Radio />}
                              label="Male"
                              onClick={() => setValue("gender", "MALE")}
                            />
                            <FormControlLabel
                              disabled={loadingLead}
                              value="FEMALE"
                              control={<Radio />}
                              label="Female"
                              onClick={() => setValue("gender", "FEMALE")}
                            />
                          </RadioGroup>
                        )}
                      />

                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.gender?.message}
                      </p>
                    </Box>

                    <FormControlLabel
                      sx={{ width: "100%", paddingTop: "35px" }}
                      control={
                        <Checkbox
                          disabled={loadingLead}
                          checked={dataWatch.influencer}
                          color="secondary"
                          {...register("influencer")}
                        />
                      }
                      label="Influencer"
                    />
                  </Box>

                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Activity
                      </p>
                      <Controller
                        name="activity"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            {...rest}
                            freeSolo
                            options={ActivityOptions}
                            value={ActivityWatch}
                            onChange={(_, data) => Change(data)}
                            getOptionLabel={(option) => option.menuText}
                            disabled={loadingLead}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputRef={ref}
                                variant="outlined"
                                sx={{ width: "100%" }}
                                placeholder="Activity"
                              />
                            )}
                          />
                        )}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.activity?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Goals
                      </p>
                      <Controller
                        name="goals"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "100%" }}
                            disabled={loadingLead}
                            className={LeadsClasses.select_container}
                          >
                            {goalsOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue("goals", option.menuValue)
                                }
                                value={option.menuValue}
                                className={
                                  dataWatch.goals === option.menuValue
                                    ? clsx(
                                        LeadsClasses.option_item,
                                        LeadsClasses.option_item_selected
                                      )
                                    : LeadsClasses.option_item
                                }
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.nationality?.message}
                      </p>
                    </Box>
                  </Box>
                </LeftElement>
                {/* Location Details */}
                <LeftElement>
                  <p className={clsx(ContainersClasses.section__title)}>
                    Location Details
                  </p>
                  <AddressInputForTranferBox />
                </LeftElement>
              </ContainerLeft>
              <Box width="70%">
                {/* Meal Plan */}

                {/* Recommanded */}
                <Box marginTop={5}>
                  <p className={clsx(ContainersClasses.section__title)}>
                    Recommanded
                  </p>
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr 1fr"
                    gap={2}
                  >
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Recommeded Calories <span>Kcals</span>
                      </p>
                      <TextField
                        placeholder="Recommeded Calories"
                        sx={{ width: "100%" }}
                        disabled={loadingLead}
                        {...register("recommended__calories", {})}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.recommended__calories?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Recommeded Protein <span>g</span>
                      </p>
                      <TextField
                        placeholder="Recommeded Protein"
                        sx={{ width: "100%" }}
                        disabled={loadingLead}
                        {...register("protein", {})}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.recommended__calories?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Recommeded Carbs <span>g</span>
                      </p>
                      <TextField
                        placeholder="Recommeded Carbs"
                        sx={{ width: "100%" }}
                        disabled={loadingLead}
                        {...register("carbs", {})}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.recommended__calories?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Recommeded Fat <span>g</span>
                      </p>
                      <TextField
                        placeholder="Recommeded Fat"
                        sx={{ width: "100%" }}
                        disabled={loadingLead}
                        {...register("fat", {})}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.recommended__calories?.message}
                      </p>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </FormProvider>
          </ContainerFullWidth>
        </ContainersContent>
      </form>
    </FormProvider>
  );
};

export default ConvertCustomerModel;
