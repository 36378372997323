import { URLFILE } from "../utils/config";

export const fileUploadXHR = (file: any, callback: any) => {
  const xhr = new XMLHttpRequest();
  xhr.upload.onprogress = (event) => {
    const percentage = (event.loaded / event.total) * 100;
    callback(null, percentage);
  };
  xhr.onreadystatechange = (e: any) => {
    if (xhr.readyState !== 4) return;
    if (xhr.status !== 200) {
      /*handle error*/
    }
    /*handle success*/
    const response = JSON.parse(e.target?.response);

    if (response?.url) {
      callback(response, null);
    }
  };
  xhr.open("post", `${URLFILE}/file/upload-image`);
  const formData = new FormData();
  formData.append("file", file);
  xhr.send(formData);
};
