import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { useSnackbar } from "notistack";
//material ui
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Clear";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

//icons
import UploadIcon from "../../../../public/icons/UploadIcon";
import FoldersIllustration from "../../../../public/icons/FoldersIllustration";
import Info from "../../../../public/icons/Info";
//styles
import { useStylesButtons } from "../../../../styles/Buttons__styles";
import { useStylesForms } from "../../../../styles/Forms__styles";
//utils
import { URLFILE } from "../../../../utils/config";

type props = {
  onChange: any;
  inputRef: any;
};

const VideoUploaderForDishes: React.FC<props> = ({ onChange, inputRef }) => {
  const { setValue } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  //styles
  const FormClasses = useStylesForms();
  const ButtonClasses = useStylesButtons();

  //state
  const [progressBar, setProgressBar] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);

  useEffect(() => {
    if (progressBar == 100 && !uploadFailed) {
      setUploadCompleted(true);
    }
    if (uploadFailed) {
      resetVideo();
    }
  }, [progressBar, uploadFailed]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const reader = new FileReader();
      const file = acceptedFiles[0];
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const formData = new FormData();
        formData.append("file", file);
        try {
          axios
            .post(`${URLFILE}/file/upload-dishes-video`, formData, {
              onUploadProgress(progressEvent) {
                setProgressBar(
                  Math.round(100 * progressEvent.loaded) / progressEvent.total
                );
              },
            })
            .then((res) => {
              const videoUrl = res.data.url;
              console.log("videoUrl", videoUrl);
              setValue("dish__video__link", videoUrl);
              enqueueSnackbar("Video uploaded successfully", {
                variant: "success",
                anchorOrigin: { vertical: "bottom", horizontal: "center" },
              });
            })
            .catch((err) => {
              setUploadFailed(true);
              console.log("err", err);
              enqueueSnackbar("Failed to upload the video", {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "center" },
              });
            });
        } catch (error) {
          console.error("Video upload failed:", error);
        }
      };
    },
    [setValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    disabled: false,
    accept: "video/*",
    onDrop,
  });

  const resetVideo = () => {
    setUploadCompleted(false);
    setProgressBar(0);
  };

  return (
    <>
      <Box className={FormClasses.message__dropzone}>
        <Info className={FormClasses.icon} />
        Recommanded video resolution is 720p
      </Box>
      <Box
        style={{
          border: "2px dashed rgb(161 161 161)",
          backgroundColor: "rgb(245 244 255)",

          padding: "20px",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          ref={inputRef}
          {...getRootProps()}
          style={{ padding: "20px", display: "flex" }}
        >
          <FoldersIllustration />
          <Box marginLeft="40px">
            <input {...getInputProps({ onChange })} />
            <Box textAlign="center">
              <Typography
                width={180}
                align="center"
                sx={{ mt: "5px", mb: 2, color: "text.primary" }}
              >
                Drag & drop your Video or
              </Typography>
              <Button
                variant="contained"
                onClick={() => {}}
                style={{ minWidth: "200px !important" }}
                className={ButtonClasses.button_dropzone}
                startIcon={<UploadIcon />}
              >
                Select
              </Button>
            </Box>
          </Box>
        </Box>
        <LinearProgressWithLabel value={progressBar} />
      </Box>
      {uploadCompleted && (
        <Box position="relative" marginTop={1}>
          <IconButton
            onClick={resetVideo}
            style={{
              position: "absolute",
              left: 100,
              top: 0,

              backgroundColor: "#cbcbcb",
              zIndex: 1000,
            }}
          >
            <CancelIcon />
          </IconButton>
          <VideoCameraBackIcon sx={{ height: 150, width: 150 }} />
        </Box>
      )}
    </>
  );
};

export default VideoUploaderForDishes;

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{ backgroundColor: "#C7893E", borderRadius: 20, height: 7 }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="#C7893E">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
