import { memo, useState, useRef } from "react";
import * as React from "react";
import { useReactToPrint } from "react-to-print";
import { FiFilter } from "react-icons/fi";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
//material-ui
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Box,
  Button,
  TextField,
  Menu,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesHeader } from "../../../styles/Header__styles";
//queries and mutations
import {
  Kitchen_Type,
  useGetAllProgramsQuery,
  useGetBulkCookingReportLazyQuery,
  LunchMeal,
} from "../../../graphql/types";
//components
import BulkCookingReportPrint from "./BulkCookingReportPrint";
//utils
import { FilterItemProps, getMessageError } from "../../Utils";

const BulkCookingReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const printRef = useRef<HTMLDivElement>(null);
  //state
  const [dateReport, setDateReport] = useState<Moment>(moment.utc());
  const [showGenerate, setShowGenerate] = useState<boolean>(true);
  const [idsProgram, setIdsProgram] = useState<string[]>([]);
  const [meals, setMeals] = useState<LunchMeal[]>([]);
  const [kitchen, setKitchen] = useState<Kitchen_Type[]>([]);

  //styles
  const ButtonClasses = useStylesButtons();

  //functions
  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    documentTitle:
      "Bulk Cooking Report " +
      moment(dateReport).format("DD/MM/YYYY dddd") +
      " generated on " +
      moment().format("DD/MM/YYYY dddd"),
  });

  const [getBulkCookingReport, { data: dataBulkCookingReport }] =
    useGetBulkCookingReportLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted() {
        handlePrint();
        setShowGenerate(true);
      },
      onError(error) {
        const err = getMessageError(error);
        enqueueSnackbar(err, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setShowGenerate(true);
      },
    });

  const onGenerateHandler = async () => {
    setShowGenerate(false);
    await getBulkCookingReport({
      variables: {
        input: {
          isoDate: dateReport.toISOString(),
          programIds: idsProgram,
          meals: meals,
          kitchens: kitchen,
        },
      },
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={600}
    >
      <Box
        width="40%"
        height="70%"
        bgcolor="#ffffff73"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection="column"
        borderRadius={5}
        border={1}
        borderColor="#e7e7e773"
      >
        <Box
          fontFamily="Poppins"
          fontSize={20}
          fontWeight="600"
          alignSelf="flex-start"
          marginLeft="15%"
          width="70%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          Bulk Cooking Report
          <FilterHeader
            idsPrograms={idsProgram}
            meals={meals}
            onPrograms={setIdsProgram}
            onMeals={setMeals}
            kitchen={kitchen}
            setKitchen={setKitchen}
          />
        </Box>
        <Box width="70%">
          <Box width="100%">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                value={dateReport}
                inputFormat="DD/MM/YYYY"
                disablePast={false}
                disableFuture={false}
                onChange={(newValue) => {
                  const utcDate = moment.utc(newValue);
                  setDateReport(utcDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=" Date"
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box
          width="70%"
          height={100}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            type="submit"
            disabled={!dateReport || !showGenerate}
            onClick={onGenerateHandler}
            sx={{ width: "100%", height: "50%" }}
          >
            Generate Reports
          </Button>
          <Box
            fontFamily="Poppins"
            fontSize={10}
            fontWeight="500"
            alignSelf="flex-start"
          >
            Use this table to generate the bulk cooking report .
          </Box>
        </Box>
        <div style={{ display: "none" }}>
          <BulkCookingReportPrint
            generateCookingReport={dataBulkCookingReport}
            ref={printRef}
            date={dateReport.toDate()}
          />
        </div>
      </Box>
    </Box>
  );
};

type FilterHeaderProps = {
  idsPrograms: string[];
  onPrograms?: (ids: string[]) => void;
  meals: LunchMeal[];
  kitchen: Kitchen_Type[];
  onMeals?: (meals: LunchMeal[]) => void;
  setKitchen?: (meals: Kitchen_Type[]) => void;
};

const FilterHeader: React.FC<FilterHeaderProps> = ({
  onPrograms,
  idsPrograms,
  meals,
  kitchen,
  setKitchen,
  onMeals,
}) => {
  const ButtonClasses = useStylesButtons();
  const classesHeader = useStylesHeader();

  const listMeals = [
    "BREAKFAST",
    "LUNCH",
    "DINNER",
    "SNACK",
    "SNACKS",
    "MORNING_SNACK",
    "EVENING_SNACK",
    "ADD_MAIN",
    "ADD_SNACK",
    "ADDON",
  ];

  const KITCHEN = ["MAIN", "PASTRY"];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterDetails, setFilterDetails] = useState<any[]>([]);

  //queries
  const dataPrograms = useGetAllProgramsQuery({
    variables: { input: { page: 1, documentsPerPage: 1000 } },
  });

  React.useEffect(() => {
    setFilterDetails(
      dataPrograms?.data?.getAllPrograms?.data?.map((el) => ({
        name: el.name,
        id: el._id,
      })) || []
    );
  }, [dataPrograms?.data?.getAllPrograms?.data]);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectPrograms = (id: string) => {
    const newArrayIds = [...idsPrograms];
    const pos = newArrayIds?.findIndex((o) => o === id);
    if (pos === -1) {
      newArrayIds.push(id);
    } else {
      newArrayIds.splice(pos, 1);
    }
    if (onPrograms) {
      onPrograms(newArrayIds);
    }
  };

  const selecMeals = (meal: string) => {
    const newArrayMeal = [...meals];
    const pos = newArrayMeal?.findIndex((o) => o === meal);
    if (pos === -1) {
      newArrayMeal.push(meal as LunchMeal);
    } else {
      newArrayMeal.splice(pos, 1);
    }
    if (onMeals) {
      onMeals(newArrayMeal);
    }
  };

  const selecKitchen = (kitchenElment: string) => {
    const newArrayKitchens = [...kitchen];
    const pos = newArrayKitchens?.findIndex((o) => o === kitchenElment);
    if (pos === -1) {
      newArrayKitchens.push(kitchenElment as Kitchen_Type);
    } else {
      newArrayKitchens.splice(pos, 1);
    }
    if (setKitchen) {
      setKitchen(newArrayKitchens);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<FiFilter />}
        onClick={handleClickMenu}
        className={ButtonClasses.filter_button}
      >
        Filter
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classesHeader.advanced_filter_menu}
      >
        <FilterItem
          title="Programs"
          data={filterDetails}
          selectFilter={selectPrograms}
          idsSelect={idsPrograms}
        />
        <FilterItem
          title="Meals"
          data={listMeals}
          selectFilter={selecMeals}
          idsSelect={meals}
        />
        <FilterItem
          title="Kitchen"
          data={KITCHEN}
          selectFilter={selecKitchen}
          idsSelect={kitchen}
        />
      </Menu>
    </>
  );
};

const FilterItem: React.FC<FilterItemProps> = ({
  title,
  data,
  selectFilter,
  idsSelect,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListSubheader
        onClick={handleClick}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListSubheader>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {data?.map((elem: any, index) => {
            return (
              <ListItemButton
                key={elem?.id || index}
                onClick={() => selectFilter(elem?.id ? elem?.id : elem)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Checkbox
                  color="secondary"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginLeft: -20 }}
                  checked={
                    idsSelect?.findIndex(
                      (o) => o === (elem?.id ? elem?.id : elem)
                    ) !== -1
                  }
                />
                <ListItemText
                  primary={elem?.name?.EN || elem?.replace("_", " ")}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
export default memo(BulkCookingReport);
