import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export const useStylesProgramMenu = makeStyles((theme: Theme) =>
  createStyles({
    calendar_containerV2: {
      width: "82px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    calendar_containerV3: {
      width: "82px",
      height: "289px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dish_containerV2: {
      height: "71px",
      marginBottom: "2px",
      display: "flex",
    },
    calendar_container: {
      width: "82px",
      height: "123px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dish_container: {
      height: "29px",
      marginBottom: "2px",
      display: "flex",
    },
    last_dish_container: {
      height: "29px",
      display: "flex",
    },
    meal_container: {
      fontFamily: "Poppins",
      width: "135px",
      height: "100%",
      backgroundColor: "#fff",
      color: "#C7893E",
      fontWeight: "600",
      fontSize: "16px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "9px",
    },
    auto_fill_container: {
      width: "155px",
      height: "17px",
    },
    auto_inputV2: {
      height: 65,
      background: "#FAFAFA",
      width: "182px",
      color: "#444444",
      border: "solid 1px",
      borderRadius: "5px",
      borderWidth: ".25px",
      borderColor: "#d3d3d3",
    },
    auto_containerV2: {
      border: "solid 1px",
      borderRadius: "5px",
      borderWidth: ".25px",
      borderColor: "#444444",
    },
    auto_input: {
      height: 18,
      background: "#FAFAFA",
      width: "138px",
      border: "none",
      marginLeft: "7px",
      color: "#444444",
    },
    auto_container: {
      border: "solid 1px",
      height: 27,
      width: "155px",
      borderWidth: ".25px",
      borderColor: "#444444",
    },
    chip_container: {
      display: "flex",
      listStyle: "none",
      marginLeft: "2px",
      alignItems: "center",
    },
    date_container_odd: {
      color: "#1C2843",
      fontWeight: "500",
      fontSize: "14px",
    },
    date_container_even: {
      color: "#29FCE0",
      fontWeight: "500",
      fontSize: "12px",
    },
    date_button: {
      marginRight: "7px",
      height: "24px",
      backgroundColor: "red",
    },
  })
);
