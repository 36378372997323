import { useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
//material ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TableContainer,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete } from "@mui/icons-material";
//utils
import { columnsRewards } from "./utils";
import { getMessageError } from "../../Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//mutations&queries
import {
  GetAllRewardsListDocument,
  useDeleteRewardMutation,
  useGetAllRewardsListQuery,
} from "../../../graphql/types";
//styles
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
//componenets
import PageHeader from "../../pageHeader/PageHeader";
import Table from "../../layout/commun/Table";

const RewardsList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [openDelete, setOpenDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rewardId, setRewardId] = useState<string>();

  //querys and muations
  const rewardsData = useGetAllRewardsListQuery();
  const AllRewards = useMemo(
    () => rewardsData.data?.getAllRewardsList || [],
    [rewardsData.data?.getAllRewardsList]
  );
  const [deleteReward] = useDeleteRewardMutation();

  //functions
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickMenu =
    (item: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setRewardId(item);
    };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (rewardId) {
      navigate(`/data/rewards/edit_reward/${rewardId}`);
    }
  };

  const DeleteRewardHandler = async () => {
    setOpenDelete(false);
    await deleteReward({
      variables: { reward: rewardId || "" },
      refetchQueries: [GetAllRewardsListDocument],
      onCompleted() {
        enqueueSnackbar("Reward deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  //render
  const renderTableRows = () => {
    return (
      AllRewards?.map((el, index) => {
        return {
          index: index + 1,
          name: el.name?.EN,
          points: el.pointsRequired,
          actions: (
            <IconButton onClick={handleClickMenu(el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <>
      {useIsAuthorized("MANAGEMENT_REWARD_CREATE") && (
        <PageHeader
          buttonLink={`/data/rewards/add_reward`}
          buttonText={"Add Reward"}
        />
      )}

      <br />
      <TableContainer>
        <Table
          columns={columnsRewards}
          data={renderTableRows()}
          emptyMessage="No rewards found !"
          loading={rewardsData.loading}
          numberOfFakeRow={30}
        />
      </TableContainer>

      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("MANAGEMENT_REWARD_EDIT") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}

          {useIsAuthorized("MANAGEMENT_REWARD_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <Box onClick={() => setOpenDelete(true)}>
                <span className="menu_title">Delete</span>
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>

      {/* DELETE rewards */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <Delete />
          <span className="alert_dialog_title_text">Delete Reward ?</span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to delete this Reward ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseDelete}
          >
            Cancel
          </Button>
          <Button
            className={ButtonsClasses.RedButton}
            onClick={DeleteRewardHandler}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default RewardsList;
