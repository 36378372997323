import React, { useMemo } from "react";
import moment from "moment";
import styled from "styled-components";
//material ui
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box/Box";
//logo
import dieture_logo_black from "../../../public/images/dieture_logo_black.png";
//types
import { GroceryReportProps } from "../Types";
//context
import { useAuth } from "../../../contextAPI";

const GroceryReportPrint = React.forwardRef<HTMLDivElement, GroceryReportProps>(
  ({ generateGroceryReport, deliveryDate, startDate, endDate }, ref) => {
    let auth = useAuth();
    //queries
    const groceryData = useMemo(
      () => generateGroceryReport,
      [generateGroceryReport]
    );
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const curTime =
      current.getHours() +
      ":" +
      current.getMinutes() +
      ":" +
      current.getSeconds();
    const Table = styled.table`
      border-radius: 5px;
    `;

    const Th = styled.th`
      border: 1px solid black;
      border-radius: 5px;
    `;

    const Td = styled.td`
      border: 1px solid black;
      border-radius: 5px;
    `;

    //render
    return (
      <div ref={ref}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Typography style={{ fontSize: 14, marginLeft: 40 }} fontWeight={600}>
            Grocery Report
          </Typography>
          <img
            src={dieture_logo_black}
            height="50px"
            alt="dieture_logo_black"
          />
          <Box>
            {startDate && endDate ? (
              <Typography variant="body2" fontWeight={600}>
                From: {moment(startDate).format("DD/MM/YYYY-dddd")} <br />
                To: {moment(endDate).format("DD/MM/YYYY-dddd")}
              </Typography>
            ) : (
              <Typography variant="body2" fontWeight={600}>
                Delivery on: {moment(deliveryDate).format("DD/MM/YYYY-dddd")}
              </Typography>
            )}
            <Typography fontSize={8.5}>
              Generated on : {date}-{curTime}
            </Typography>
          </Box>
        </Box>
        <div
          className="table_GroceryReport"
          style={{
            flexDirection: "column",
            display: "flex",
            borderColor: "#232222",
            marginLeft: 55,
            borderRadius: 50,
            marginRight: 50,
          }}
        >
          <Table
            style={{
              borderWidth: 1,
              borderColor: "#232222",
              borderCollapse: "collapse",

              width: "100%",
              marginBlockEnd: 5,
            }}
          >
            <tr
              style={{
                color: "#000000",
                borderColor: "black",
              }}
            >
              <Th
                style={{
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                  fontSize: 10,
                  fontWeight: 600,
                }}
              ></Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Name
              </Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Weight
              </Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Price
              </Th>
            </tr>
            {groceryData?.ingredients?.map((item: any, index: number) => (
              <tr key={item.ingredient?.name?.EN}>
                <Td style={{ fontSize: 10, textAlign: "center" }}>
                  {index + 1}
                </Td>
                <Td
                  style={{
                    fontWeight: 500,

                    fontSize: 8,
                  }}
                >
                  {item?.ingredient?.name?.EN}
                </Td>
                <Td style={{ fontWeight: 500, textAlign: "left" }}>
                  <p style={{ fontSize: 7, marginInlineStart: 2 }}>
                    {item?.weight?.toFixed(3)} g
                  </p>
                </Td>
                <Td style={{ fontWeight: 500 }}>
                  <p style={{ fontSize: 8 }}>
                    {auth.countryCurrency}
                    {item?.price?.toFixed(3)}
                  </p>
                </Td>
              </tr>
            ))}
            <Th colSpan={4}>
              <div style={{ flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                >
                  {" "}
                  Total cost
                </div>

                <div
                  style={{ alignItems: "end", fontWeight: 500, fontSize: 14 }}
                >
                  {auth.countryCurrency} {groceryData?.totalPrice?.toFixed(3)}
                </div>
              </div>
            </Th>
          </Table>
        </div>
      </div>
    );
  }
);

export default GroceryReportPrint;
