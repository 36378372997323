export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Alland Islands",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Curacao",
  "Cyprus",
  "Czech Republic",
  "Democratic People's Republic of Korea",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Federated States of Micronesia",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iraq",
  "Ireland",
  "Islamic Republic of Iran",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, the Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of Korea",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthelemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "US Virgin Islands",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United Republic of Tanzania",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const countriesFR = [
  { code: "AF", name: "Afghanistan" },
  { code: "ZA", name: "Afrique du Sud" },
  { code: "AL", name: "Albanie" },
  { code: "DZ", name: "Algérie" },
  { code: "DE", name: "Allemagne" },
  { code: "AD", name: "Andorre" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AG", name: "Antigua-et-Barbuda" },
  { code: "SA", name: "Arabie saoudite" },
  { code: "AR", name: "Argentine" },
  { code: "AM", name: "Arménie" },
  { code: "AW", name: "Aruba" },
  { code: "AU", name: "Australie" },
  { code: "AT", name: "Autriche" },
  { code: "AZ", name: "Azerbaïdjan" },
  { code: "BS", name: "Bahamas" },
  { code: "BH", name: "Bahreïn" },
  { code: "BD", name: "Bangladesh" },
  { code: "BB", name: "Barbade" },
  { code: "BE", name: "Belgique" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Bénin" },
  { code: "BM", name: "Bermudes" },
  { code: "BT", name: "Bhoutan" },
  { code: "BY", name: "Biélorussie" },
  { code: "BO", name: "Bolivie" },
  { code: "BA", name: "Bosnie-Herzégovine" },
  { code: "BW", name: "Botswana" },
  { code: "BR", name: "Brésil" },
  { code: "BN", name: "Brunéi Darussalam" },
  { code: "BG", name: "Bulgarie" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "KH", name: "Cambodge" },
  { code: "CM", name: "Cameroun" },
  { code: "CA", name: "Canada" },
  { code: "CV", name: "Cap-Vert" },
  { code: "CL", name: "Chili" },
  { code: "CN", name: "Chine" },
  { code: "CY", name: "Chypre" },
  { code: "CO", name: "Colombie" },
  { code: "KM", name: "Comores" },
  { code: "CG", name: "Congo-Brazzaville" },
  { code: "CD", name: "Congo-Kinshasa" },
  { code: "KP", name: "Corée du Nord" },
  { code: "KR", name: "Corée du Sud" },
  { code: "CR", name: "Costa Rica" },
  { code: "CI", name: "Côte d’Ivoire" },
  { code: "HR", name: "Croatie" },
  { code: "CU", name: "Cuba" },
  { code: "CW", name: "Curaçao" },
  { code: "DK", name: "Danemark" },
  { code: "DJ", name: "Djibouti" },
  { code: "DM", name: "Dominique" },
  { code: "EG", name: "Égypte" },
  { code: "AE", name: "Émirats arabes unis" },
  { code: "EC", name: "Équateur" },
  { code: "ER", name: "Érythrée" },
  { code: "ES", name: "Espagne" },
  { code: "EE", name: "Estonie" },
  { code: "SZ", name: "Eswatini" },
  { code: "VA", name: "État de la Cité du Vatican" },
  { code: "FM", name: "États fédérés de Micronésie" },
  { code: "US", name: "États-Unis" },
  { code: "ET", name: "Éthiopie" },
  { code: "FJ", name: "Fidji" },
  { code: "FI", name: "Finlande" },
  { code: "FR", name: "France" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambie" },
  { code: "GE", name: "Géorgie" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Grèce" },
  { code: "GD", name: "Grenade" },
  { code: "GL", name: "Groenland" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GG", name: "Guernesey" },
  { code: "GN", name: "Guinée" },
  { code: "GQ", name: "Guinée équatoriale" },
  { code: "GW", name: "Guinée-Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "GF", name: "Guyane française" },
  { code: "HT", name: "Haïti" },
  { code: "HN", name: "Honduras" },
  { code: "HU", name: "Hongrie" },
  { code: "CX", name: "Île Christmas" },
  { code: "IM", name: "Île de Man" },
  { code: "NF", name: "Île Norfolk" },
  { code: "AX", name: "Îles Åland" },
  { code: "KY", name: "Îles Caïmans" },
  { code: "CC", name: "Îles Cocos" },
  { code: "CK", name: "Îles Cook" },
  { code: "FO", name: "Îles Féroé" },
  { code: "FK", name: "Îles Malouines" },
  { code: "MP", name: "Îles Mariannes du Nord" },
  { code: "MH", name: "Îles Marshall" },
  { code: "SB", name: "Îles Salomon" },
  { code: "TC", name: "Îles Turques-et-Caïques" },
  { code: "VG", name: "Îles Vierges britanniques" },
  { code: "VI", name: "Îles Vierges des États-Unis" },
  { code: "IN", name: "Inde" },
  { code: "ID", name: "Indonésie" },
  { code: "IQ", name: "Irak" },
  { code: "IR", name: "Iran" },
  { code: "IE", name: "Irlande" },
  { code: "IS", name: "Islande" },
  { code: "IL", name: "Israël" },
  { code: "IT", name: "Italie" },
  { code: "JM", name: "Jamaïque" },
  { code: "JP", name: "Japon" },
  { code: "JE", name: "Jersey" },
  { code: "JO", name: "Jordanie" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "KE", name: "Kenya" },
  { code: "KG", name: "Kirghizistan" },
  { code: "KI", name: "Kiribati" },
  { code: "XK", name: "Kosovo" },
  { code: "KW", name: "Koweït" },
  { code: "RE", name: "La Réunion" },
  { code: "LA", name: "Laos" },
  { code: "LS", name: "Lesotho" },
  { code: "LV", name: "Lettonie" },
  { code: "LB", name: "Liban" },
  { code: "LR", name: "Libéria" },
  { code: "LY", name: "Libye" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lituanie" },
  { code: "LU", name: "Luxembourg" },
  { code: "MK", name: "Macédoine" },
  { code: "MG", name: "Madagascar" },
  { code: "MY", name: "Malaisie" },
  { code: "MW", name: "Malawi" },
  { code: "MV", name: "Maldives" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malte" },
  { code: "MA", name: "Maroc" },
  { code: "MQ", name: "Martinique" },
  { code: "MU", name: "Maurice" },
  { code: "MR", name: "Mauritanie" },
  { code: "YT", name: "Mayotte" },
  { code: "MX", name: "Mexique" },
  { code: "MD", name: "Moldavie" },
  { code: "MC", name: "Monaco" },
  { code: "MN", name: "Mongolie" },
  { code: "ME", name: "Monténégro" },
  { code: "MS", name: "Montserrat" },
  { code: "MZ", name: "Mozambique" },
  { code: "MM", name: "Myanmar" },
  { code: "NA", name: "Namibie" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Népal" },
  { code: "NI", name: "Nicaragua" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigéria" },
  { code: "NU", name: "Niue" },
  { code: "NO", name: "Norvège" },
  { code: "NC", name: "Nouvelle-Calédonie" },
  { code: "NZ", name: "Nouvelle-Zélande" },
  { code: "OM", name: "Oman" },
  { code: "UG", name: "Ouganda" },
  { code: "UZ", name: "Ouzbékistan" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palaos" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papouasie-Nouvelle-Guinée" },
  { code: "PY", name: "Paraguay" },
  { code: "NL", name: "Pays-Bas" },
  { code: "BQ", name: "Pays-Bas caribéens" },
  { code: "PE", name: "Pérou" },
  { code: "PH", name: "Philippines" },
  { code: "PL", name: "Pologne" },
  { code: "PF", name: "Polynésie française" },
  { code: "PR", name: "Porto Rico" },
  { code: "PT", name: "Portugal" },
  { code: "QA", name: "Qatar" },
  { code: "HK", name: "R.A.S. chinoise de Hong Kong" },
  { code: "MO", name: "R.A.S. chinoise de Macao" },
  { code: "CF", name: "République centrafricaine" },
  { code: "DO", name: "République dominicaine" },
  { code: "RO", name: "Roumanie" },
  { code: "GB", name: "Royaume-Uni" },
  { code: "RU", name: "Russie" },
  { code: "RW", name: "Rwanda" },
  { code: "EH", name: "Sahara occidental" },
  { code: "BL", name: "Saint-Barthélemy" },
  { code: "KN", name: "Saint-Christophe-et-Niévès" },
  { code: "SM", name: "Saint-Marin" },
  { code: "MF", name: "Saint-Martin" },
  { code: "SX", name: "Saint-Martin" },
  { code: "PM", name: "Saint-Pierre-et-Miquelon" },
  { code: "VC", name: "Saint-Vincent-et-les-Grenadines" },
  { code: "SH", name: "Sainte-Hélène" },
  { code: "LC", name: "Sainte-Lucie" },
  { code: "SV", name: "Salvador" },
  { code: "WS", name: "Samoa" },
  { code: "AS", name: "Samoa américaines" },
  { code: "ST", name: "Sao Tomé-et-Principe" },
  { code: "SN", name: "Sénégal" },
  { code: "RS", name: "Serbie" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapour" },
  { code: "SK", name: "Slovaquie" },
  { code: "SI", name: "Slovénie" },
  { code: "SO", name: "Somalie" },
  { code: "SD", name: "Soudan" },
  { code: "SS", name: "Soudan du Sud" },
  { code: "LK", name: "Sri Lanka" },
  { code: "SE", name: "Suède" },
  { code: "CH", name: "Suisse" },
  { code: "SR", name: "Suriname" },
  { code: "SJ", name: "Svalbard et Jan Mayen" },
  { code: "SY", name: "Syrie" },
  { code: "TJ", name: "Tadjikistan" },
  { code: "TW", name: "Taïwan" },
  { code: "TZ", name: "Tanzanie" },
  { code: "TD", name: "Tchad" },
  { code: "CZ", name: "Tchéquie" },
  { code: "IO", name: "Territoire britannique de l’océan Indien" },
  { code: "PS", name: "Territoires palestiniens" },
  { code: "TH", name: "Thaïlande" },
  { code: "TL", name: "Timor oriental" },
  { code: "TG", name: "Togo" },
  { code: "TK", name: "Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trinité-et-Tobago" },
  { code: "TN", name: "Tunisie" },
  { code: "TM", name: "Turkménistan" },
  { code: "TR", name: "Turquie" },
  { code: "TV", name: "Tuvalu" },
  { code: "UA", name: "Ukraine" },
  { code: "UY", name: "Uruguay" },
  { code: "VU", name: "Vanuatu" },
  { code: "VE", name: "Venezuela" },
  { code: "VN", name: "Vietnam" },
  { code: "WF", name: "Wallis-et-Futuna" },
  { code: "YE", name: "Yémen" },
  { code: "ZM", name: "Zambie" },
  { code: "ZW", name: "Zimbabwe" },
];

export const countriesFR2 = [
  { code: "AF", nationality: "Afghanistan" },
  { code: "ZA", nationality: "Afrique du Sud" },
  { code: "AL", nationality: "Albanie" },
  { code: "DZ", nationality: "Algérie" },
  { code: "DE", nationality: "Allemagne" },
  { code: "AD", nationality: "Andorre" },
  { code: "AO", nationality: "Angola" },
  { code: "AI", nationality: "Anguilla" },
  { code: "AG", nationality: "Antigua-et-Barbuda" },
  { code: "SA", nationality: "Arabie saoudite" },
  { code: "AR", nationality: "Argentine" },
  { code: "AM", nationality: "Arménie" },
  { code: "AW", nationality: "Aruba" },
  { code: "AU", nationality: "Australie" },
  { code: "AT", nationality: "Autriche" },
  { code: "AZ", nationality: "Azerbaïdjan" },
  { code: "BS", nationality: "Bahamas" },
  { code: "BH", nationality: "Bahreïn" },
  { code: "BD", nationality: "Bangladesh" },
  { code: "BB", nationality: "Barbade" },
  { code: "BE", nationality: "Belgique" },
  { code: "BZ", nationality: "Belize" },
  { code: "BJ", nationality: "Bénin" },
  { code: "BM", nationality: "Bermudes" },
  { code: "BT", nationality: "Bhoutan" },
  { code: "BY", nationality: "Biélorussie" },
  { code: "BO", nationality: "Bolivie" },
  { code: "BA", nationality: "Bosnie-Herzégovine" },
  { code: "BW", nationality: "Botswana" },
  { code: "BR", nationality: "Brésil" },
  { code: "BN", nationality: "Brunéi Darussalam" },
  { code: "BG", nationality: "Bulgarie" },
  { code: "BF", nationality: "Burkina Faso" },
  { code: "BI", nationality: "Burundi" },
  { code: "KH", nationality: "Cambodge" },
  { code: "CM", nationality: "Cameroun" },
  { code: "CA", nationality: "Canada" },
  { code: "CV", nationality: "Cap-Vert" },
  { code: "CL", nationality: "Chili" },
  { code: "CN", nationality: "Chine" },
  { code: "CY", nationality: "Chypre" },
  { code: "CO", nationality: "Colombie" },
  { code: "KM", nationality: "Comores" },
  { code: "CG", nationality: "Congo-Brazzaville" },
  { code: "CD", nationality: "Congo-Kinshasa" },
  { code: "KP", nationality: "Corée du Nord" },
  { code: "KR", nationality: "Corée du Sud" },
  { code: "CR", nationality: "Costa Rica" },
  { code: "CI", nationality: "Côte d’Ivoire" },
  { code: "HR", nationality: "Croatie" },
  { code: "CU", nationality: "Cuba" },
  { code: "CW", nationality: "Curaçao" },
  { code: "DK", nationality: "Danemark" },
  { code: "DJ", nationality: "Djibouti" },
  { code: "DM", nationality: "Dominique" },
  { code: "EG", nationality: "Égypte" },
  { code: "AE", nationality: "Émirats arabes unis" },
  { code: "EC", nationality: "Équateur" },
  { code: "ER", nationality: "Érythrée" },
  { code: "ES", nationality: "Espagne" },
  { code: "EE", nationality: "Estonie" },
  { code: "SZ", nationality: "Eswatini" },
  { code: "VA", nationality: "État de la Cité du Vatican" },
  { code: "FM", nationality: "États fédérés de Micronésie" },
  { code: "US", nationality: "États-Unis" },
  { code: "ET", nationality: "Éthiopie" },
  { code: "FJ", nationality: "Fidji" },
  { code: "FI", nationality: "Finlande" },
  { code: "FR", nationality: "France" },
  { code: "GA", nationality: "Gabon" },
  { code: "GM", nationality: "Gambie" },
  { code: "GE", nationality: "Géorgie" },
  { code: "GH", nationality: "Ghana" },
  { code: "GI", nationality: "Gibraltar" },
  { code: "GR", nationality: "Grèce" },
  { code: "GD", nationality: "Grenade" },
  { code: "GL", nationality: "Groenland" },
  { code: "GP", nationality: "Guadeloupe" },
  { code: "GU", nationality: "Guam" },
  { code: "GT", nationality: "Guatemala" },
  { code: "GG", nationality: "Guernesey" },
  { code: "GN", nationality: "Guinée" },
  { code: "GQ", nationality: "Guinée équatoriale" },
  { code: "GW", nationality: "Guinée-Bissau" },
  { code: "GY", nationality: "Guyana" },
  { code: "GF", nationality: "Guyane française" },
  { code: "HT", nationality: "Haïti" },
  { code: "HN", nationality: "Honduras" },
  { code: "HU", nationality: "Hongrie" },
  { code: "CX", nationality: "Île Christmas" },
  { code: "IM", nationality: "Île de Man" },
  { code: "NF", nationality: "Île Norfolk" },
  { code: "AX", nationality: "Îles Åland" },
  { code: "KY", nationality: "Îles Caïmans" },
  { code: "CC", nationality: "Îles Cocos" },
  { code: "CK", nationality: "Îles Cook" },
  { code: "FO", nationality: "Îles Féroé" },
  { code: "FK", nationality: "Îles Malouines" },
  { code: "MP", nationality: "Îles Mariannes du Nord" },
  { code: "MH", nationality: "Îles Marshall" },
  { code: "SB", nationality: "Îles Salomon" },
  { code: "TC", nationality: "Îles Turques-et-Caïques" },
  { code: "VG", nationality: "Îles Vierges britanniques" },
  { code: "VI", nationality: "Îles Vierges des États-Unis" },
  { code: "IN", nationality: "Inde" },
  { code: "ID", nationality: "Indonésie" },
  { code: "IQ", nationality: "Irak" },
  { code: "IR", nationality: "Iran" },
  { code: "IE", nationality: "Irlande" },
  { code: "IS", nationality: "Islande" },
  { code: "IL", nationality: "Israël" },
  { code: "IT", nationality: "Italie" },
  { code: "JM", nationality: "Jamaïque" },
  { code: "JP", nationality: "Japon" },
  { code: "JE", nationality: "Jersey" },
  { code: "JO", nationality: "Jordanie" },
  { code: "KZ", nationality: "Kazakhstan" },
  { code: "KE", nationality: "Kenya" },
  { code: "KG", nationality: "Kirghizistan" },
  { code: "KI", nationality: "Kiribati" },
  { code: "XK", nationality: "Kosovo" },
  { code: "KW", nationality: "Koweït" },
  { code: "RE", nationality: "La Réunion" },
  { code: "LA", nationality: "Laos" },
  { code: "LS", nationality: "Lesotho" },
  { code: "LV", nationality: "Lettonie" },
  { code: "LB", nationality: "Liban" },
  { code: "LR", nationality: "Libéria" },
  { code: "LY", nationality: "Libye" },
  { code: "LI", nationality: "Liechtenstein" },
  { code: "LT", nationality: "Lituanie" },
  { code: "LU", nationality: "Luxembourg" },
  { code: "MK", nationality: "Macédoine" },
  { code: "MG", nationality: "Madagascar" },
  { code: "MY", nationality: "Malaisie" },
  { code: "MW", nationality: "Malawi" },
  { code: "MV", nationality: "Maldives" },
  { code: "ML", nationality: "Mali" },
  { code: "MT", nationality: "Malte" },
  { code: "MA", nationality: "Maroc" },
  { code: "MQ", nationality: "Martinique" },
  { code: "MU", nationality: "Maurice" },
  { code: "MR", nationality: "Mauritanie" },
  { code: "YT", nationality: "Mayotte" },
  { code: "MX", nationality: "Mexique" },
  { code: "MD", nationality: "Moldavie" },
  { code: "MC", nationality: "Monaco" },
  { code: "MN", nationality: "Mongolie" },
  { code: "ME", nationality: "Monténégro" },
  { code: "MS", nationality: "Montserrat" },
  { code: "MZ", nationality: "Mozambique" },
  { code: "MM", nationality: "Myanmar" },
  { code: "NA", nationality: "Namibie" },
  { code: "NR", nationality: "Nauru" },
  { code: "NP", nationality: "Népal" },
  { code: "NI", nationality: "Nicaragua" },
  { code: "NE", nationality: "Niger" },
  { code: "NG", nationality: "Nigéria" },
  { code: "NU", nationality: "Niue" },
  { code: "NO", nationality: "Norvège" },
  { code: "NC", nationality: "Nouvelle-Calédonie" },
  { code: "NZ", nationality: "Nouvelle-Zélande" },
  { code: "OM", nationality: "Oman" },
  { code: "UG", nationality: "Ouganda" },
  { code: "UZ", nationality: "Ouzbékistan" },
  { code: "PK", nationality: "Pakistan" },
  { code: "PW", nationality: "Palaos" },
  { code: "PA", nationality: "Panama" },
  { code: "PG", nationality: "Papouasie-Nouvelle-Guinée" },
  { code: "PY", nationality: "Paraguay" },
  { code: "NL", nationality: "Pays-Bas" },
  { code: "BQ", nationality: "Pays-Bas caribéens" },
  { code: "PE", nationality: "Pérou" },
  { code: "PH", nationality: "Philippines" },
  { code: "PL", nationality: "Pologne" },
  { code: "PF", nationality: "Polynésie française" },
  { code: "PR", nationality: "Porto Rico" },
  { code: "PT", nationality: "Portugal" },
  { code: "QA", nationality: "Qatar" },
  { code: "HK", nationality: "R.A.S. chinoise de Hong Kong" },
  { code: "MO", nationality: "R.A.S. chinoise de Macao" },
  { code: "CF", nationality: "République centrafricaine" },
  { code: "DO", nationality: "République dominicaine" },
  { code: "RO", nationality: "Roumanie" },
  { code: "GB", nationality: "Royaume-Uni" },
  { code: "RU", nationality: "Russie" },
  { code: "RW", nationality: "Rwanda" },
  { code: "EH", nationality: "Sahara occidental" },
  { code: "BL", nationality: "Saint-Barthélemy" },
  { code: "KN", nationality: "Saint-Christophe-et-Niévès" },
  { code: "SM", nationality: "Saint-Marin" },
  { code: "MF", nationality: "Saint-Martin" },
  { code: "SX", nationality: "Saint-Martin" },
  { code: "PM", nationality: "Saint-Pierre-et-Miquelon" },
  { code: "VC", nationality: "Saint-Vincent-et-les-Grenadines" },
  { code: "SH", nationality: "Sainte-Hélène" },
  { code: "LC", nationality: "Sainte-Lucie" },
  { code: "SV", nationality: "Salvador" },
  { code: "WS", nationality: "Samoa" },
  { code: "AS", nationality: "Samoa américaines" },
  { code: "ST", nationality: "Sao Tomé-et-Principe" },
  { code: "SN", nationality: "Sénégal" },
  { code: "RS", nationality: "Serbie" },
  { code: "SC", nationality: "Seychelles" },
  { code: "SL", nationality: "Sierra Leone" },
  { code: "SG", nationality: "Singapour" },
  { code: "SK", nationality: "Slovaquie" },
  { code: "SI", nationality: "Slovénie" },
  { code: "SO", nationality: "Somalie" },
  { code: "SD", nationality: "Soudan" },
  { code: "SS", nationality: "Soudan du Sud" },
  { code: "LK", nationality: "Sri Lanka" },
  { code: "SE", nationality: "Suède" },
  { code: "CH", nationality: "Suisse" },
  { code: "SR", nationality: "Suriname" },
  { code: "SJ", nationality: "Svalbard et Jan Mayen" },
  { code: "SY", nationality: "Syrie" },
  { code: "TJ", nationality: "Tadjikistan" },
  { code: "TW", nationality: "Taïwan" },
  { code: "TZ", nationality: "Tanzanie" },
  { code: "TD", nationality: "Tchad" },
  { code: "CZ", nationality: "Tchéquie" },
  { code: "IO", nationality: "Territoire britannique de l’océan Indien" },
  { code: "PS", nationality: "Territoires palestiniens" },
  { code: "TH", nationality: "Thaïlande" },
  { code: "TL", nationality: "Timor oriental" },
  { code: "TG", nationality: "Togo" },
  { code: "TK", nationality: "Tokelau" },
  { code: "TO", nationality: "Tonga" },
  { code: "TT", nationality: "Trinité-et-Tobago" },
  { code: "TN", nationality: "Tunisie" },
  { code: "TM", nationality: "Turkménistan" },
  { code: "TR", nationality: "Turquie" },
  { code: "TV", nationality: "Tuvalu" },
  { code: "UA", nationality: "Ukraine" },
  { code: "UY", nationality: "Uruguay" },
  { code: "VU", nationality: "Vanuatu" },
  { code: "VE", nationality: "Venezuela" },
  { code: "VN", nationality: "Vietnam" },
  { code: "WF", nationality: "Wallis-et-Futuna" },
  { code: "YE", nationality: "Yémen" },
  { code: "ZM", nationality: "Zambie" },
  { code: "ZW", nationality: "Zimbabwe" },
];
