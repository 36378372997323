import { Meals, PackPayload, PacksInputDto } from "../../graphql/types";
import { ColumnsProps } from "../layout/commun/Table";
import { MealsProps, PackProps } from "./Types";

export const columns: ColumnsProps = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "Photo",
    accessor: "photo",
    cellProps: { align: "center", width: "30px" },
  },
  {
    header: "Description",
    accessor: "description",
  },
  {
    header: "Status",
    accessor: "status",
    cellProps: { align: "center", width: "30px" },
  },
  {
    header: "Status",
    accessor: "statusToggle",
    cellProps: { align: "center", width: "30px" },
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];

export const DurationOptions = [
  {
    menuText: "1 Day",
    menuValue: "1_days",
  },
  {
    menuText: "1 Week",
    menuValue: "7_days",
  },
  {
    menuText: "2 Weeks",
    menuValue: "14_days",
  },
  {
    menuText: "3 Weeks",
    menuValue: "21_days",
  },
  {
    menuText: "1 Month",
    menuValue: "1_months",
  },
  {
    menuText: "2 Months",
    menuValue: "2_months",
  },
  {
    menuText: "3 Months",
    menuValue: "3_months",
  },
];

export const MealOptions: Array<MealsProps> = [
  {
    label: "1 Meal",
    value: "ONE_MEAL",
  },
  {
    label: "2 Meals",
    value: "TWO_MEALS",
  },
  {
    label: "3 Meals",
    value: "THREE_MEALS",
  },
  {
    label: "4 Meals",
    value: "FOUR_MEALS",
  },
  {
    label: "5 Meals",
    value: "FIVE_MEALS",
  },
  {
    label: "6 Meals",
    value: "SIX_MEALS",
  },
  {
    label: "7 Meals",
    value: "SEVEN_MEALS",
  },
];

export const AllMealOptions: Array<{ value: Meals; label: string }> = [
  {
    label: "1 Meal",
    value: "ONE_MEAL",
  },
  {
    label: "2 Meals",
    value: "TWO_MEALS",
  },
  {
    label: "3 Meals",
    value: "THREE_MEALS",
  },
  {
    label: "4 Meals",
    value: "FOUR_MEALS",
  },
  {
    label: "5 Meals",
    value: "FIVE_MEALS",
  },
  {
    label: "6 Meals",
    value: "SIX_MEALS",
  },
  {
    label: "7 Meals",
    value: "SEVEN_MEALS",
  },
];

export const medicalConditionsOptions = [
  {
    menuValue: "classic",
    menuText: "Classic",
  },
  {
    menuValue: "keto",
    menuText: "Keto",
  },
  {
    menuValue: "gluten_free",
    menuText: "Gluten free",
  },
  {
    menuValue: "dairy_free",
    menuText: "Dairy Free",
  },
  {
    menuValue: "diabetic",
    menuText: "Diabetic",
  },
  {
    menuValue: "hypertension",
    menuText: "Hypertension",
  },
  {
    menuValue: "pregnant",
    menuText: "Pregnant",
  },
  {
    menuValue: "maternity",
    menuText: "Maternity",
  },
  {
    menuValue: "vegetarian",
    menuText: "Vegetarian",
  },
];

export const NUMBER_OF_MEALS = [
  {
    menuValue: "PM_MEALS",
    menuText: "PM_MEALS",
  },
  {
    menuValue: "REGULAR_MEALS",
    menuText: "REGULAR_MEALS",
  },
  {
    menuValue: "THREE_MEALS",
    menuText: "THREE_MEALS",
  },
  {
    menuValue: "FOUR_MEALS",
    menuText: "FOUR_MEALS",
  },
  {
    menuValue: "FIVE_MEALS",
    menuText: "FIVE_MEALS",
  },
  {
    menuValue: "BUSNIESS_LUNCH",
    menuText: "BUSNIESS_LUNCH",
  },
];

export const formatPacks = (packs: PackProps[]) => {
  let formattedArray: PacksInputDto[] = [];
  formattedArray = packs.map((pack) => {
    const pricing = {
      monthly: {
        breakfast: parseFloat(pack.monthlyBreakfast || ""),
        lunchDinner: parseFloat(pack.monthlyLunchDinner || ""),
        snack: parseFloat(pack.monthlySnacks || ""),
      },
      weekly: {
        breakfast: parseFloat(pack.weeklyBreakfast || ""),
        lunchDinner: parseFloat(pack.weeklyLunchDinner || ""),
        snack: parseFloat(pack.weeklySnacks || ""),
      },
      threeMonths: {
        breakfast: parseFloat(pack.threeMonthsBreakfast || ""),
        lunchDinner: parseFloat(pack.threeMonthsLunchDinner || ""),
        snack: parseFloat(pack.threeMonthsSnacks || ""),
      },
    };

    return {
      isActive: pack.active ? pack.active : true,
      title: pack.title,
      name: {
        EN: pack.nameEN,
        AR: pack.nameAR,
      },
      packImg: pack.photoUrl || "",
      meals: pack.meals,
      pricing: pricing,
    };
  });
  return formattedArray;
};

export const formatPacksData = (packs: PackPayload[]) => {
  let formattedArray: PackProps[] = [];
  formattedArray = packs.map((pack) => {
    return {
      _id: pack._id,
      active: pack.isActive || false,
      meals: pack.meals,
      title: pack.title || "",
      nameAR: pack?.name?.AR || "",
      nameEN: pack?.name?.EN || "",
      photoUrl: pack.packImg,
      monthlyBreakfast: pack?.pricing?.monthly?.breakfast?.toString() || "",
      monthlyLunchDinner: pack?.pricing?.monthly?.lunchDinner?.toString() || "",
      monthlySnacks: pack?.pricing?.monthly?.snack?.toString() || "",
      weeklyBreakfast: pack?.pricing?.weekly?.breakfast?.toString() || "",
      weeklyLunchDinner: pack?.pricing?.weekly?.lunchDinner?.toString() || "",
      weeklySnacks: pack?.pricing?.weekly?.snack?.toString() || "",
      threeMonthsBreakfast:
        pack?.pricing?.threeMonths?.breakfast?.toString() || "",
      threeMonthsLunchDinner:
        pack?.pricing?.threeMonths?.lunchDinner?.toString() || "",
      threeMonthsSnacks: pack?.pricing?.threeMonths?.snack?.toString() || "",
    };
  });
  return formattedArray;
};
