import { REACT_APP_FIREBASE_API_KEY } from "./utils/config";

export const firebaseConfig = {
  apiKey: `${REACT_APP_FIREBASE_API_KEY}`,
  authDomain: "dieture.firebaseapp.com",
  databaseURL: "https://dieture.firebaseio.com",
  projectId: "dieture",
  storageBucket: "dieture.appspot.com",
  messagingSenderId: "786539529748",
  appId: "1:786539529748:web:657793bb617cdf5e174901",
  measurementId: "G-YZSJFMN3Q7",
};
