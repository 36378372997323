import {
  UseFormRegister,
  Control,
  Controller,
  useWatch,
} from "react-hook-form";
//material ui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Chip, Switch } from "@mui/material";
//components
import DropZoneInput from "../../../layout/commun/DropZoneInput";
import VideoUploaderForDishes from "./VideoUploaderForDishes";
//Types
import { DishProps, DishTagsProps } from "../../Types";
//queries&mutations
import {
  DishPayload,
  GetListCategoryQuery,
  ProgramPayload,
} from "../../../../graphql/types";
//utils
import {
  filterDishTags,
  tagsOptions,
  medicalConditionsOptions,
} from "../../Utils";
//styled
import {
  useStylesContainers,
  ContainerStyled,
} from "../../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../../styles/Recipe__styles";
//context api
import { useAuth } from "../../../../contextAPI";

type DishFormProps = {
  register: UseFormRegister<DishProps>;
  control: Control<DishProps, object>;
  errors?: any;
  dataCatgories?: GetListCategoryQuery | null;
  programs?: ProgramPayload[];
  sauces?: DishPayload[];
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DishForm = ({
  register,
  control,
  errors,
  dataCatgories,
  programs,
  sauces,
}: DishFormProps): JSX.Element => {
  //styles
  const ContainersClasses = useStylesContainers();
  const RecipeClasses = useStylesRecipe();
  const auth = useAuth();

  const dataWatch = useWatch({ control });
  const dataWatchDishTags = useWatch({
    control,
    name: "dish__tags",
  });

  const dataWatchSauces = useWatch({
    control,
    name: "sauces",
  });

  const dishTagsList = filterDishTags(
    dataWatch.dish__tags as DishTagsProps[],
    tagsOptions
  );

  return (
    <ContainerStyled>
      <Box display="grid" width="100%" gridTemplateColumns="2fr 1fr" gap={4}>
        <Box
          display="grid"
          width="100%"
          gridTemplateColumns="1fr 1fr"
          columnGap={2}
        >
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>
              Dish Name* <span>(English)</span>
            </p>
            <TextField
              id="demo-helper-text-misaligned"
              placeholder="Dish Name English"
              fullWidth
              {...register("name__english", {
                required: "Name is Mandatory",
              })}
            />
            {errors?.name__english?.message && (
              <p className={RecipeClasses.recipe__error__helper}>
                {errors?.name__english?.message}
              </p>
            )}
          </Box>
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>
              Dish Name <span>(Arabic)</span>
            </p>
            <TextField
              id="demo-helper-text-misaligned"
              placeholder="Dish Name Arabic"
              fullWidth
              {...register("name__arabic")}
            />
            {errors?.name__arabic?.message && (
              <p className={RecipeClasses.recipe__error__helper}>
                {errors?.name__arabic?.message}
              </p>
            )}
          </Box>
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>
              Dish Description <span>(English)</span>
            </p>
            <TextField
              id="demo-helper-text-misaligned"
              placeholder="Dish Description English"
              fullWidth
              {...register("description__english")}
            />
          </Box>

          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>
              Dish Description <span>(Arabic)</span>
            </p>
            <TextField
              id="demo-helper-text-misaligned"
              placeholder="Dish Description Arabic"
              fullWidth
              {...register("description__arabic")}
            />
          </Box>
          {/* Medical creteria */}
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>
              Medical creteria*
            </p>
            <Controller
              name="medical__creteria"
              rules={{
                required: "Select Category",
              }}
              control={control}
              render={({
                field: { onChange: Change, value, ref, ...rest },
                fieldState: { error },
              }) => (
                <>
                  <Autocomplete
                    ref={ref}
                    multiple
                    id="medical__creteria"
                    options={medicalConditionsOptions}
                    getOptionLabel={(option) => option.menuText}
                    value={value}
                    isOptionEqualToValue={(option, value) =>
                      option.menuText === value.menuText
                    }
                    filterSelectedOptions
                    onChange={(_, data) => Change(data)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          color="secondary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.menuText}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Medical creteria" />
                    )}
                  />
                  <p className={ContainersClasses.section__error__helper}>
                    {error?.message}
                  </p>
                </>
              )}
            />
          </Box>
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>
              Microwave Time <span>(sec)</span>
            </p>
            <TextField
              id="demo-helper-text-misaligned"
              placeholder="Microwave Time in seconds"
              fullWidth
              {...register("dish__reheat")}
            />
          </Box>
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>
              Dish category*
            </p>
            <Controller
              name="dish__category"
              rules={{
                required: "Select Category",
              }}
              control={control}
              render={({ field: { ref, onChange }, fieldState: { error } }) => (
                <>
                  <Autocomplete
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    ref={ref}
                    disablePortal
                    id="dish_category"
                    options={dataCatgories?.getListCategory ?? []}
                    value={dataWatch?.dish__category}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          color="secondary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Dish Category" />
                    )}
                  />
                  {error?.message && (
                    <p className={RecipeClasses.recipe__error__helper}>
                      {error?.message}
                    </p>
                  )}
                </>
              )}
            />
          </Box>
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>Tags*</p>
            <Controller
              name="dish__tags"
              control={control}
              rules={{ required: "Select Tags" }}
              render={({
                field: { onChange: Change, value, ref, ...rest },
                fieldState: { error },
              }) => (
                <>
                  <Autocomplete
                    multiple
                    id="dish_tags"
                    options={dishTagsList || []}
                    value={dataWatchDishTags}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          color="secondary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Tags" />
                    )}
                    onChange={(_, data) => Change(data)}
                  />
                  {error?.message && (
                    <p className={RecipeClasses.recipe__error__helper}>
                      {error?.message}
                    </p>
                  )}
                </>
              )}
            />
          </Box>
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>
              DISH suitability
            </p>
            <Controller
              name="dish__suitability"
              control={control}
              rules={{ required: "Select Suitability" }}
              render={({
                field: { onChange: Change, value, ref, ...rest },
                fieldState: { error },
              }) => (
                <>
                  <Autocomplete
                    id="dish_suitability"
                    options={dishSuitability}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(_, value) => {
                      Change(value);
                    }}
                    value={dataWatch?.dish__suitability}
                    getOptionLabel={(option) => option.title || ""}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          color="secondary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.title}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="DISH suitability" />
                    )}
                  />
                  {error?.message && (
                    <p className={RecipeClasses.recipe__error__helper}>
                      {error?.message}
                    </p>
                  )}
                </>
              )}
            />
          </Box>
          {dataWatch.isCustomMeal && (
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>
                Custom meal suitability
              </p>
              <Controller
                name="custom__meal__suitability"
                control={control}
                render={({
                  field: { onChange: Change, value, ref, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      id="custom__meal__suitability"
                      options={customMealSuitability}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(_, value) => {
                        Change(value);
                      }}
                      value={dataWatch?.custom__meal__suitability}
                      getOptionLabel={(option) => option.title}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            color="secondary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.title}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="DISH suitability" />
                      )}
                    />
                    {error?.message && (
                      <p className={RecipeClasses.recipe__error__helper}>
                        {error?.message}
                      </p>
                    )}
                  </>
                )}
              />
            </Box>
          )}
          {/* Kitchen */}
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>Kitchen*</p>
            <Controller
              name="kitchen"
              rules={{
                required: "Select a Kitchen",
              }}
              control={control}
              render={({ field: { ref, onChange }, fieldState: { error } }) => (
                <>
                  <Autocomplete
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    ref={ref}
                    disablePortal
                    id="kitchen"
                    options={KITCHEN}
                    value={dataWatch?.kitchen}
                    getOptionLabel={(option) => option.title || ""}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          color="secondary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.title}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Kitchen" />
                    )}
                  />
                  {error?.message && (
                    <p className={RecipeClasses.recipe__error__helper}>
                      {error?.message}
                    </p>
                  )}
                </>
              )}
            />
          </Box>
          {/* Programs */}
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>Dish Program*</p>
            <Controller
              name="dish__program"
              rules={{
                required: "Select Program",
              }}
              control={control}
              render={({
                field: { onChange: Change, value, ref, ...rest },
                fieldState: { error },
              }) => (
                <>
                  <Autocomplete
                    multiple
                    ref={ref}
                    id="dish__program"
                    options={programs ?? []}
                    value={dataWatch?.dish__program || []}
                    getOptionLabel={(option) => option.name?.EN || ""}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    filterSelectedOptions
                    onChange={(_, data) => Change(data)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          color="secondary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name?.EN}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Dish Program" />
                    )}
                  />
                  <p className={ContainersClasses.section__error__helper}>
                    {error?.message}
                  </p>
                </>
              )}
            />
          </Box>
          {/* Sauce */}
          {dataWatch.withSauce && (
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>Sauce</p>
              <Controller
                name="sauces"
                rules={{
                  required: "Select Sauce",
                }}
                control={control}
                render={({
                  field: { onChange: Change, value, ref, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      ref={ref}
                      id="sauces"
                      multiple
                      options={sauces ?? []}
                      value={dataWatchSauces as DishPayload[]}
                      getOptionLabel={(option) => option.name?.EN || ""}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      filterSelectedOptions
                      onChange={(_, data) => Change(data)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            color="secondary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name?.EN}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Sauces" />
                      )}
                    />
                    <p className={ContainersClasses.section__error__helper}>
                      {error?.message}
                    </p>
                  </>
                )}
              />
            </Box>
          )}
          {/* COUNTRIES */}
          <Box width="100%">
            <p className={ContainersClasses.section__subtitle}>Countries*</p>
            <Controller
              name="countries"
              rules={{
                required: "Select Country",
              }}
              control={control}
              render={({
                field: { onChange: Change, value, ref, ...rest },
                fieldState: { error },
              }) => (
                <>
                  <Autocomplete
                    multiple
                    ref={ref}
                    id="countries"
                    options={auth.countriesList ?? []}
                    value={dataWatch?.countries}
                    getOptionLabel={(option) => option.name?.EN || ""}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    filterSelectedOptions
                    onChange={(_, data) => Change(data)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          color="secondary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name?.EN}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Country" />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.name?.EN}
                          {...getTagProps({ index })}
                          disabled={
                            !!!auth.countriesList.find(
                              (el) => el.code === option.code
                            )
                          }
                        />
                      ))
                    }
                  />
                  <p className={ContainersClasses.section__error__helper}>
                    {error?.message}
                  </p>
                </>
              )}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="start">
          <p className={ContainersClasses.section__subtitle}>Upload Image</p>

          <Controller
            name="dish__picture"
            control={control}
            render={({ field: { onChange, ref } }) => {
              return (
                <DropZoneInput
                  onChange={(e) => {
                    onChange(e.target.files[0]);
                  }}
                  label="Drop File"
                  inputRef={ref}
                  name="dish__picture"
                  errors={errors}
                  multiple={false}
                  disabled={false}
                  accept="image/*"
                  message="Recommended resolution for image 400px*400px"
                />
              );
            }}
          />
          {dataWatch.isAddOn ? (
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>AddOn price</p>
              <TextField
                type="number"
                id="demo-helper-text-misaligned"
                placeholder="Add on price"
                fullWidth
                {...register("retail__selling__price")}
              />
            </Box>
          ) : (
            <Box></Box>
          )}
          <Controller
            name="isAddOn"
            control={control}
            render={({ field: { onChange, ref } }) => {
              return (
                <FormControlLabel
                  checked={dataWatch?.isAddOn}
                  onChange={(_, value) => {
                    onChange(value);
                  }}
                  value="start"
                  control={<Checkbox color="secondary" />}
                  label="Add ons"
                  labelPlacement="start"
                  sx={{ marginLeft: 5.5, marginTop: "auto" }}
                />
              );
            }}
          />
          {!dataWatch.withSauce && (
            <Controller
              name="isSauce"
              control={control}
              render={({ field: { onChange, ref } }) => {
                return (
                  <FormControlLabel
                    checked={dataWatch?.isSauce}
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    value="start"
                    control={<Checkbox color="secondary" />}
                    label="Is Sauce"
                    labelPlacement="start"
                    sx={{
                      marginLeft: 5.5,
                    }}
                  />
                );
              }}
            />
          )}
          {!dataWatch.isSauce && (
            <Controller
              name="withSauce"
              control={control}
              render={({ field: { onChange, ref } }) => {
                return (
                  <FormControlLabel
                    checked={dataWatch?.withSauce}
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    value="start"
                    control={<Checkbox color="secondary" />}
                    label="With Sauce"
                    labelPlacement="start"
                    sx={{
                      marginLeft: 3,
                    }}
                  />
                );
              }}
            />
          )}
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box display="flex" flexDirection="row" alignItems="center">
            <p className={ContainersClasses.section__subtitle}>Upload Video</p>
            <Controller
              name={`videoType`}
              control={control}
              render={({ field: { ref, value, ...rest } }) => (
                <FormControlLabel
                  {...rest}
                  sx={{ marginLeft: 2 }}
                  value={value}
                  control={<Switch checked={value} />}
                  label=""
                />
              )}
            />
            <p className={ContainersClasses.section__subtitle}>Video Link</p>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={2}
            width="100%"
          >
            {dataWatch.videoType ? (
              <Box display="flex" flexDirection="column" width="50%">
                <p className={ContainersClasses.section__subtitle}>
                  Add video link of dish
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Youtube video link"
                  fullWidth
                  {...register("dish__video__link")}
                />
                {errors?.trainerVideoLink?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.trainerVideoLink?.message}
                  </p>
                )}
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" width="50%">
                <p className={ContainersClasses.section__subtitle}>
                  Upload Dish video
                </p>
                <Controller
                  name="dish__video__link"
                  control={control}
                  render={({ field: { onChange, ref } }) => {
                    return (
                      <VideoUploaderForDishes
                        onChange={(e: any) => {
                          onChange(e.target.files[0]);
                        }}
                        inputRef={ref}
                      />
                    );
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="start">
          <p className={ContainersClasses.section__subtitle}>
            Upload Dish Video Loading Image *
          </p>
          <Controller
            name="loadingImage"
            control={control}
            render={({ field: { onChange, ref } }) => {
              return (
                <DropZoneInput
                  onChange={(e) => {
                    onChange(e.target.files[0]);
                  }}
                  label="Drop File"
                  inputRef={ref}
                  name="loadingImage"
                  errors={errors}
                  multiple={false}
                  disabled={false}
                  accept="image/*"
                  message="Recommended resolution for image 400px*400px"
                />
              );
            }}
          />
        </Box>
      </Box>
    </ContainerStyled>
  );
};

const dishSuitability = [
  { id: "BREAKFAST", title: "Breakfast" },
  { id: "LUNCH", title: "Lunch" },
  { id: "DINNER", title: "Dinner" },
  { id: "SNACKS", title: "Snacks" },
];
const customMealSuitability = [
  { id: "BASE", title: "Base" },
  { id: "PROTEIN", title: "Protein" },
  { id: "SIDES", title: "Sides" },
  { id: "TOPPINGS", title: "Toppings" },
  { id: "SAUCE", title: "Sauce" },
];
const KITCHEN = [
  { id: "MAIN", title: "Main Kitchen" },
  { id: "PASTRY", title: "Pastry kitchen" },
];
export default DishForm;
