import { Theme, lighten } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export const useStylesPhoneInput = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex !important",
      gap: "3px",
      maxWidth: "50wv",
    },
    input: {
      "& .MuiTextField-root": {
        width: "25% !important",
      },
      "& .MuiSelect-select": {
        display: "flex !important",
        padding: "15px !important",
        "& span": {
          marginRight: "3px",
        },
      },
    },
    imageIcon: {
      display: "flex",
      height: "inherit",
      width: "inherit",
      objectFit: "contain",
    },
    iconRoot: {
      textAlign: "center",
      marginRight: "6px",
    },

    option__item: {
      "&:hover": {
        backgroundColor: `${lighten(
          theme.palette.secondary.main,
          0.7
        )} !important`,
      },
      "& .menu_title": {
        textAlign: "center !important",
        margin: "0px !important",
        padding: "0px !important",
      },
    },
  })
);
