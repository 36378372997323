import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import {
  useForm,
  useWatch,
  FormProvider,
  SubmitHandler,
} from "react-hook-form";
//material
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// utils
import { tagsOptions, medicalConditionsOptions } from "../Utils";
import { formatIngredientsForAPI } from "./Utils";
import { getMessageError } from "../../Utils";
//components
import PageAddHeader from "../../pageAddHeader/pageAddHeader";
import DishForm from "./components/DishForm";
import Macros from "./components/Macros";
import IngredientDetailsForm from "./components/IngredientDetailsForm";
import DetailsFrom from "./components/DetailsForm";
//styles
import { ContainerFullWidth } from "../../../styles/Containers__styles";
//querys and  mutations
import {
  useCreateDishMutation,
  useGetListCategoryQuery,
  useGetPackageSizeQuery,
  useUpdateDishMutation,
  useGetDishByIdv2Query,
  useGetAllIngredientsQuery,
  useGetAllProgramsQuery,
  Kitchen_Type,
  LunchMeal,
  useGetSaucesWithPaginationQuery,
} from "../../../graphql/types";
//types
import { DishProps, sauce } from "../Types";
import { ApolloError } from "@apollo/client";

const FormDish = () => {
  const { id, search = "" } = useParams();
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //state
  const [caloriesSmall, setCaloriesSmall] = useState(0);
  const [caloriesMedium, setCaloriesMedium] = useState(0);
  const [caloriesLarge, setCaloriesLarge] = useState(0);

  const [proteinSmall, setProteinSmall] = useState(0);
  const [proteinMedium, setProteinMedium] = useState(0);
  const [proteinLarge, setProteinLarge] = useState(0);

  const [carbsSmall, setCarbsSmall] = useState(0);
  const [carbsMedium, setCarbsMedium] = useState(0);
  const [carbsLarge, setCarbsLarge] = useState(0);

  const [fatSmall, setFatSmall] = useState(0);
  const [fatMedium, setFatMedium] = useState(0);
  const [fatLarge, setFatLarge] = useState(0);

  const [fiberSmall, setFiberSmall] = useState(0);
  const [fiberMedium, setFiberMedium] = useState(0);
  const [fiberLarge, setFiberLarge] = useState(0);

  // react hook form
  const methods = useForm<DishProps>({
    defaultValues: {
      name__english: "",
      name__arabic: "",
      dish__distribution: [],
      dish__price: "",
      dish__suitability: { id: "", title: "" },
      kitchen: { id: "", title: "" },
      custom__meal__suitability: { id: "BASE", title: "Base" },
      dish__reheat: "",
      dish__program: [],
      sauces: [],
      recipes: [
        {
          recipe__item: null,
          recipe__quantity: "",
        },
      ],
      dish__plating: "",
      dish__plating__picture: "",
      dish__package: [],
      dish__tags: [],
      dish__picture: "",
      dish__category: { name: "" },
      dish__package__picture: "",
      retail__selling__price: 0,
      medical__creteria: [],
      dish__cooking__instructions: "",
      ingredients: [
        {
          id: "",
          name: "",
          quantitySmall: "",
          quantityMedium: "",
          quantityLarge: "",
          costSmall: "",
          costMedium: "",
          costLarge: "",
        },
      ],
      isAddOn: false,
      isCustomMeal: false,
      isSauce: false,
      withSauce: false,
      countries: [],
      videoType: false,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
  } = methods;

  const dataWatch = useWatch({ control });

  //querys & mutations

  const { data: dataDish, loading } = useGetDishByIdv2Query({
    variables: { dishId: id || "" },
    fetchPolicy: "no-cache",
    skip: !id,
  });

  const { data: dataCatgories } = useGetListCategoryQuery({});

  const { data: ingredientsList, loading: ingredientsLoading } =
    useGetAllIngredientsQuery({
      variables: { input: "" },
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    if (
      dataDish?.getDishByIdv2 &&
      dataCatgories?.getListCategory &&
      medicalConditionsOptions
    ) {
      const {
        name,
        description,
        medicalConditions,
        tags,
        category,
        reheatingInstruction,
        dishSuitability,
        platingInstruction,
        packagingName,
        cookingInstruction,
        isAddon,
        ingredients,
        packagingPhotoUrl,
        pictureUrl,
        platingPhotoUrl,
        retailsSellingPrice,
        kitchen,
        program,
        isSauce,
        withSauce,
        sauces,
        country,
        videoPictureUrl,
        videoUrl,
      } = dataDish?.getDishByIdv2 ?? null;
      reset({
        name__arabic: name?.AR || "",
        name__english: name?.EN || "",
        description__english: description?.EN || "",
        description__arabic: description?.AR || "",
        medical__creteria:
          medicalConditionsOptions?.filter((el) =>
            medicalConditions?.find((elem) => elem === el.menuValue)
          ) || [],
        dish__tags: tagsOptions?.filter((el) =>
          tags?.find((elem) => elem === el.value)
        ),
        dish__category: dataCatgories?.getListCategory?.find(
          (el) => category === el._id
        ),
        dish__reheat: reheatingInstruction || "",
        dish__suitability: DISH_SUITABILITY?.find(
          (el) => el.id === dishSuitability
        ),

        kitchen: KITCHEN?.find((el) => el.id === kitchen),
        ingredients: ingredients,
        dish__picture:
          pictureUrl ||
          "https://dieture-dev-app.s3.amazonaws.com/images/59de269b-65fe-4729-9983-c7975886e18e.png",
        dish__package__picture:
          packagingPhotoUrl ||
          "https://dieture-dev-app.s3.amazonaws.com/images/59de269b-65fe-4729-9983-c7975886e18e.png",
        dish__plating: platingInstruction || "",
        dish__cooking__instructions: cookingInstruction || "",
        dish__package: packagingName || [],
        dish__program: program || [],
        isAddOn: isAddon || false,
        isSauce: isSauce || false,
        withSauce: withSauce || false,
        videoType: true,
        sauces: sauces as sauce[],
        //There will be a test on whether the meal is a part of custom meal components
        isCustomMeal: false,
        countries: country || [],
        custom__meal__suitability: { id: "BASE", title: "Base" },
        retail__selling__price: retailsSellingPrice || 0, //sending add on price either way but backend take it into consideration only if isAddon is true
        dish__plating__picture:
          platingPhotoUrl ||
          "https://dieture-dev-app.s3.amazonaws.com/images/59de269b-65fe-4729-9983-c7975886e18e.png",
        dish__video__link: videoUrl || "",
        loadingImage: videoPictureUrl || "",
      });
    }
  }, [
    dataDish?.getDishByIdv2,
    dataCatgories?.getListCategory,
    medicalConditionsOptions,
    ingredientsList?.getAllIngredients,
  ]);

  const [createDish, dataCreateDish] = useCreateDishMutation();

  const [updateDish, dataUpdateDish] = useUpdateDishMutation();

  const dataPackages = useGetPackageSizeQuery();

  const dataPrograms = useGetAllProgramsQuery({
    variables: { input: { page: 1, documentsPerPage: 1000 } },
  });

  const dataSauces = useGetSaucesWithPaginationQuery({
    variables: { input: { page: 1, documentsPerPage: 1000 } },
  });

  const programs = useMemo(
    () => dataPrograms.data?.getAllPrograms.data || [],
    [dataPrograms.data]
  );

  const sauces = useMemo(
    () => dataSauces.data?.getSaucesWithPagination.data || [],
    [dataSauces.data]
  );

  const packages = useMemo(
    () => dataPackages.data?.getPackageSize || [],
    [dataPackages.data]
  );

  const onSubmit: SubmitHandler<DishProps> = async (data) => {
    const packagingPhotoUrl =
      data.dish__package__picture ||
      "https://dieture-dev-app.s3.amazonaws.com/images/59de269b-65fe-4729-9983-c7975886e18e.png";
    const pictureUrl =
      data.dish__picture ||
      "https://dieture-dev-app.s3.amazonaws.com/images/59de269b-65fe-4729-9983-c7975886e18e.png";
    if (
      packagingPhotoUrl &&
      pictureUrl &&
      (packagingPhotoUrl.includes("http") || pictureUrl.includes("http"))
    ) {
      try {
        if (id) {
          await updateDish({
            variables: {
              updateInput: {
                _id: id || "",
                name: {
                  EN: data.name__english,
                  AR: data.name__arabic,
                },
                description: {
                  EN: data.description__english,
                  AR: data.description__arabic,
                },
                reheatingInstruction: data.dish__reheat,
                tags: data.dish__tags.map((item) => item.value),
                dishSuitability: data.dish__suitability.id as LunchMeal,
                medicalConditions: data.medical__creteria.map(
                  (el) => el.menuValue
                ),
                category: data.dish__category._id,
                program: data.dish__program.map((el) => el?._id || ""),
                platingInstruction: data.dish__plating,
                cookingInstruction: data.dish__cooking__instructions,
                packagingName: data.dish__package,
                small: {
                  ingredients: formatIngredientsForAPI(
                    data.ingredients,
                    "SMALL"
                  ),
                },
                medium: {
                  ingredients: formatIngredientsForAPI(
                    data.ingredients,
                    "MEDIUM"
                  ),
                },
                large: {
                  ingredients: formatIngredientsForAPI(
                    data.ingredients,
                    "LARGE"
                  ),
                },
                isAddon: data.isAddOn,
                pictureUrl: data.dish__picture,
                packagingPhotoUrl: data.dish__package__picture,
                platingPhotoUrl: data.dish__plating__picture,
                retailsSellingPrice: +data.retail__selling__price,
                kitchen: data.kitchen.id as Kitchen_Type,
                isSauce: data.isSauce,
                withSauce: data.withSauce,
                country: data?.countries
                  ?.map((el) => el?._id)
                  .filter(Boolean) as string[],
                sauces: data.sauces.map((el) => el._id),
                videoUrl: data.dish__video__link,
                videoPictureUrl: data.loadingImage,
              },
            },
            onCompleted(data) {
              enqueueSnackbar("Dish updated successfully", {
                variant: "success",
                anchorOrigin: { vertical: "bottom", horizontal: "center" },
              });
              navigate(`/food/dishes/view_dish/${id}`);
            },
            onError(err) {
              console.log(err);
              const error = getMessageError(err as ApolloError);
              enqueueSnackbar(error, {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "center" },
              });
              setTimeout(() => closeSnackbar(), 5000);
            },
          });
        } else {
          await createDish({
            variables: {
              createInput: {
                name: {
                  EN: data.name__english,
                  AR: data.name__arabic,
                },
                description: {
                  EN: data.description__english,
                  AR: data.description__arabic,
                },
                reheatingInstruction: data.dish__reheat,
                tags: data.dish__tags.map((item) => item.value),
                dishSuitability: data.dish__suitability.id as LunchMeal,
                medicalConditions: data.medical__creteria.map(
                  (el) => el.menuValue
                ),
                category: data.dish__category._id,
                program: data.dish__program.map((el) => el._id || ""),
                platingInstruction: data.dish__plating,
                cookingInstruction: data.dish__cooking__instructions,
                packagingName: data.dish__package,
                isAddon: data.isAddOn,
                pictureUrl: data.dish__picture,
                packagingPhotoUrl: data.dish__package__picture,
                platingPhotoUrl: data.dish__plating__picture,
                retailsSellingPrice: +data.retail__selling__price,
                kitchen: data.kitchen.id as Kitchen_Type,
                isSauce: data.isSauce,
                withSauce: data.withSauce,
                country: data?.countries
                  ?.map((el) => el?._id)
                  .filter(Boolean) as string[],
                sauces: data.sauces.map((el) => el._id),
                small: {
                  ingredients: formatIngredientsForAPI(
                    data.ingredients,
                    "SMALL"
                  ),
                },
                medium: {
                  ingredients: formatIngredientsForAPI(
                    data.ingredients,
                    "MEDIUM"
                  ),
                },
                large: {
                  ingredients: formatIngredientsForAPI(
                    data.ingredients,
                    "LARGE"
                  ),
                },
                videoUrl: data.dish__video__link,
                videoPictureUrl: data.loadingImage,
              },
            },
            onCompleted(data) {
              enqueueSnackbar("Dish created successfully", {
                variant: "success",
                anchorOrigin: { vertical: "bottom", horizontal: "center" },
              });
              navigate(`/food/dishes/list_dishes/${search}`);
            },
            onError(error) {
              enqueueSnackbar("Creating Dish failed", {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "center" },
              });
            },
          });
        }
      } catch (error) {
        enqueueSnackbar("operation failed", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      }
    }
  };

  useEffect(() => {
    if (ingredientsList?.getAllIngredients) {
      let totalCaloriesSmall = 0;
      let totalCaloriesMedium = 0;
      let totalCaloriesLarge = 0;
      let totalProteinSmall = 0;
      let totalProteinMedium = 0;
      let totalProteinLarge = 0;
      let totalCarbsSmall = 0;
      let totalCarbsMedium = 0;
      let totalCarbsLarge = 0;
      let totalFatSmall = 0;
      let totalFatMedium = 0;
      let totalFatLarge = 0;
      let totalFiberSmall = 0;
      let totalFiberMedium = 0;
      let totalFiberLarge = 0;
      for (let i = 0; i < dataWatch.ingredients.length; i++) {
        const { quantitySmall, quantityMedium, quantityLarge } =
          dataWatch.ingredients[i];
        let caloriesSmall = 0;
        let caloriesMedium = 0;
        let caloriesLarge = 0;
        let proteinSmall = 0;
        let proteinMedium = 0;
        let proteinLarge = 0;
        let carbsSmall = 0;
        let carbsMedium = 0;
        let carbsLarge = 0;
        let fatSmall = 0;
        let fatMedium = 0;
        let fatLarge = 0;
        let fiberSmall = 0;
        let fiberMedium = 0;
        let fiberLarge = 0;

        const ingredient = ingredientsList?.getAllIngredients.find(
          (el) => dataWatch.ingredients[i]?.ingredient?._id === el._id
        );

        const nutritionMap = new Map();
        ingredient?.nutritions?.forEach((el) => {
          nutritionMap.set(el.nutrition.name, el.quantity);
        });
        const CaloriesFor100G = nutritionMap.get("Energy");
        const proteinFor100G = nutritionMap.get("Protein");
        const carbsFor100G = nutritionMap.get("Carbohydrate");
        const fatFor100G = nutritionMap.get("Total Fat");
        const fiberFor100G = nutritionMap.get("Fiber, total dietary");

        if (CaloriesFor100G) {
          if (quantitySmall) {
            caloriesSmall = (CaloriesFor100G / 100) * +quantitySmall;
          }
          if (quantityMedium) {
            caloriesMedium = (CaloriesFor100G / 100) * +quantityMedium;
          }
          if (quantityLarge) {
            caloriesLarge = (CaloriesFor100G / 100) * +quantityLarge;
          }
        }
        totalCaloriesSmall += caloriesSmall;
        totalCaloriesMedium += caloriesMedium;
        totalCaloriesLarge += caloriesLarge;
        if (proteinFor100G) {
          if (quantitySmall) {
            proteinSmall = (proteinFor100G / 100) * +quantitySmall;
          }
          if (quantityMedium) {
            proteinMedium = (proteinFor100G / 100) * +quantityMedium;
          }
          if (quantityLarge) {
            proteinLarge = (proteinFor100G / 100) * +quantityLarge;
          }
        }
        totalProteinSmall += proteinSmall;
        totalProteinMedium += proteinMedium;
        totalProteinLarge += proteinLarge;
        if (carbsFor100G) {
          if (quantitySmall) {
            carbsSmall = (carbsFor100G / 100) * +quantitySmall;
          }
          if (quantityMedium) {
            carbsMedium = (carbsFor100G / 100) * +quantityMedium;
          }
          if (quantityLarge) {
            carbsLarge = (carbsFor100G / 100) * +quantityLarge;
          }
        }
        totalCarbsSmall += carbsSmall;
        totalCarbsMedium += carbsMedium;
        totalCarbsLarge += carbsLarge;
        if (fatFor100G) {
          if (quantitySmall) {
            fatSmall = (fatFor100G / 100) * +quantitySmall;
          }
          if (quantityMedium) {
            fatMedium = (fatFor100G / 100) * +quantityMedium;
          }
          if (quantityLarge) {
            fatLarge = (fatFor100G / 100) * +quantityLarge;
          }
        }
        totalFatSmall += fatSmall;
        totalFatMedium += fatMedium;
        totalFatLarge += fatLarge;
        if (fiberFor100G) {
          if (quantitySmall) {
            fiberSmall = (fiberFor100G / 100) * +quantitySmall;
          }
          if (quantityMedium) {
            fiberMedium = (fiberFor100G / 100) * +quantityMedium;
          }
          if (quantityLarge) {
            fiberLarge = (fiberFor100G / 100) * +quantityLarge;
          }
        }
        totalFiberSmall += fiberSmall;
        totalFiberMedium += fiberMedium;
        totalFiberLarge += fiberLarge;
      }
      setCaloriesSmall(Math.round(totalCaloriesSmall));
      setCaloriesMedium(Math.round(totalCaloriesMedium));
      setCaloriesLarge(Math.round(totalCaloriesLarge));

      setProteinSmall(totalProteinSmall);
      setProteinMedium(totalProteinMedium);
      setProteinLarge(totalProteinLarge);

      setCarbsSmall(totalCarbsSmall);
      setCarbsMedium(totalCarbsMedium);
      setCarbsLarge(totalCarbsLarge);

      setFatSmall(totalFatSmall);
      setFatMedium(totalFatMedium);
      setFatLarge(totalFatLarge);

      setFiberSmall(totalFiberSmall);
      setFiberMedium(totalFiberMedium);
      setFiberLarge(totalFiberLarge);
    }
  }, [dataWatch]);

  // render
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContainerFullWidth>
          <PageAddHeader
            title={id ? "Update Dish" : "Create Dish"}
            buttonText={id ? "Update Dish" : "Create Dish"}
            cancelButtonLink="/food/dishes/list_dishes"
            loading={dataCreateDish.loading || dataUpdateDish.loading}
          />

          <DishForm
            register={register}
            control={control}
            errors={errors}
            dataCatgories={dataCatgories}
            programs={programs}
            sauces={sauces}
          />

          <Typography
            variant="h3"
            sx={{ color: "secondary.main", pl: 1, mt: 1, mb: 1 }}
          >
            Macros
          </Typography>
          <Macros
            caloriesSmall={caloriesSmall}
            caloriesMedium={caloriesMedium}
            caloriesLarge={caloriesLarge}
            proteinSmall={proteinSmall}
            proteinMedium={proteinMedium}
            proteinLarge={proteinLarge}
            carbsSmall={carbsSmall}
            carbsMedium={carbsMedium}
            carbsLarge={carbsLarge}
            fatSmall={fatSmall}
            fatMedium={fatMedium}
            fatLarge={fatLarge}
            fiberSmall={fiberSmall}
            fiberMedium={fiberMedium}
            fiberLarge={fiberLarge}
          />

          <Typography
            variant="h3"
            sx={{ color: "secondary.main", pl: 1, mt: 1, mb: 1 }}
          >
            Ingredient Details
          </Typography>
          <IngredientDetailsForm
            control={control}
            getValues={getValues}
            reset={reset}
            ingredientsList={ingredientsList?.getAllIngredients ?? []}
          />
          <Typography
            variant="h3"
            sx={{ color: "secondary.main", pl: 1, mt: 1, mb: 1 }}
          >
            Details
          </Typography>
          <DetailsFrom
            register={register}
            control={control}
            errors={errors}
            packages={packages}
          />
        </ContainerFullWidth>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={ingredientsLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </FormProvider>
  );
};

export default FormDish;

const DISH_SUITABILITY = [
  { id: "BREAKFAST", title: "Breakfast" },
  { id: "LUNCH", title: "Lunch" },
  { id: "DINNER", title: "Dinner" },
  { id: "SNACKS", title: "Snacks" },
];
const KITCHEN = [
  { id: "MAIN", title: "Main Kitchen" },
  { id: "PASTRY", title: "Pastery kitchen" },
];
