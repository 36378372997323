import { ApolloError } from "@apollo/client";
import {
  DishBoxPayload,
  Gender,
  Height,
  LeadStatus,
  LunchMeal,
  Meals,
  NumberOfDays,
  PaymentMethod,
  PaymentPeriod,
  PlanCondition,
  PlanStatus,
  SortBy,
  TransactionStatus,
  Weight,
} from "../graphql/types";

export const getMessageError = (error?: ApolloError) => {
  if (!error?.graphQLErrors || error.graphQLErrors.length === 0) {
    return null;
  }
  const message = error.graphQLErrors[0].message;

  return message;
};

export const handleGender = (kind: Gender | undefined | null) => {
  if (kind === "MALE") {
    return "Male";
  } else {
    return "Female";
  }
};

export const handleUnits = (kind: Weight | Height | undefined | null) => {
  switch (kind) {
    case "KG":
      return "kg";
    case "LBS":
      return "lb";
    case "CM":
      return "cm";
    case "FEET":
      return "feet";
    default:
      return "kg";
  }
};
export const handleMeals = (
  kind?:
    | LunchMeal
    | "breakfast"
    | "lunch"
    | "dinner"
    | "am_snack"
    | "pm_snack"
    | null
) => {
  switch (kind) {
    case "BREAKFAST":
    case "breakfast":
      return "Breakfast";
    case "LUNCH":
    case "lunch":
      return "Lunch";
    case "DINNER":
    case "dinner":
      return "Dinner";
    case "MORNING_SNACK":
    case "am_snack":
      return "Snack";
    case "EVENING_SNACK":
    case "pm_snack":
      return "Snack";
    case "SNACKS":
      return "Snack";
    case "SNACK":
      return "Snack";
    default:
      return null;
  }
};
export const handleMealsEditMealPlan = (
  kind?:
    | LunchMeal
    | "breakfast"
    | "lunch"
    | "dinner"
    | "am_snack"
    | "pm_snack"
    | null
) => {
  switch (kind) {
    case "BREAKFAST":
    case "breakfast":
      return "Breakfast";
    case "LUNCH":
    case "lunch":
      return "Lunch";
    case "DINNER":
    case "dinner":
      return "Dinner";
    case "MORNING_SNACK":
    case "am_snack":
      return "Morning snack";
    case "EVENING_SNACK":
    case "pm_snack":
      return "Evening snack";
    case "SNACKS":
      return "Snack";
    case "SNACK":
      return "Snack";
    case "ADD_SNACK":
      return "Add snack";
    case "ADD_MAIN":
      return "Add main";
    default:
      return null;
  }
};

export const handleNbrMeals = (kind: Meals) => {
  return kind
    ? kind
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/\b/, (l) => l.toUpperCase())
    : "Kids Meals";
};

export const handleNbrDays = (
  kind: NumberOfDays | null | undefined,
  type: "string" | "number" = "string"
) => {
  switch (kind) {
    case "FIVE_DAYS":
      return type === "number" ? "5 Days" : "Five Days";
    case "SIX_DAYS":
      return type === "number" ? "6 Days" : "Six Days";
    default:
      return type === "number" ? "7 Days" : "Seven Days";
  }
};
export const handlePaymentPeriod = (kind?: PaymentPeriod) => {
  switch (kind) {
    case "WEEKLY":
      return "1 Week";
    case "MONTHLY":
      return "1 Month";
    case "ONE_DAY":
      return "1 Day";
    case "TWO_WEEKS":
      return "2 Weeks";
    case "THREE_WEEKS":
      return "3 Weeks";
    case "TWO_MONTHS":
      return "2 Months";
    case "THREE_MONTHS":
      return "3 Months";
    case "FOUR_MONTHS":
      return "4 Months";
  }
};
//List meals
export const meals: LunchMeal[] = [
  "BREAKFAST",
  "MORNING_SNACK",
  "LUNCH",
  "EVENING_SNACK",
  "DINNER",
];
//List meals
export const CustomPackageMeals: string[] = [
  "Breakfast",
  "Lunch/Dinner",
  "Snack",
];

export const chunk = (chunkSize: number, array: DishBoxPayload[]) => {
  let R = [];
  for (let i = 0; i < array.length; i += chunkSize)
    R.push(array.slice(i, i + chunkSize));
  return R;
};

export type FilterElementsPausesProp = {
  menuText: string;
  menuValue: string;
  selected: boolean;
};
export type PausesSort = "UPCOMING_PAUSES"|"PREVIOUS_PAUSES"

export const filterElementsPauses: Array<FilterElementsPausesProp> = [
  {
    menuText: "Upcoming Pauses",
    menuValue: "UPCOMING_PAUSES",
    selected: false,
  },
  {
    menuText: "Previous Pauses",
    menuValue: "PREVIOUS_PAUSES",
    selected: false,
  },
];

export const handlePlanStatus = (status: PlanStatus) => {
  switch (status) {
    case "COMPLETED":
      return { text: "Completed", color: "#18A2A0" };
    case "CREATED":
      return { text: "Created", color: "#3CB371" };
    case "DISHS_MISSING":
      return { text: "Dishs missing", color: "#C72D2D" };
    case "START_DATE_MISSING":
      return { text: "Start date missing", color: "#b27d3f" };

    default:
      return { text: "Unknown status", color: "#b27d3f" };
  }
};

export const handlePayment = (kind: PaymentMethod | "UNSPECIFIED") => {
  return kind
    ? kind
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/\b/, (l) => l.toUpperCase())
    : "Unspecified";
};

export const handlePlanCondition = (
  kind: PlanCondition | LeadStatus | undefined | null
) => {
  switch (kind) {
    case "FROZEN":
      return { text: "Frozen", color: "#18A2A0" };
    case "UPCOMING":
      return { text: "Upcoming", color: "#18A2A0" };
    case "ACTIVE":
      return { text: "Active", color: "#18A2A0" };
    case "CANCLED":
      return { text: "Canceld", color: "#18A2A0" };
    case "DELETED":
      return { text: "Deleted", color: "#18A2A0" };
    case "UNCOMPLETED":
      return { text: "Uncompleted", color: "#18A2A0" };
    case "EXPIRING_SOON":
      return { text: "Expiring Soon", color: "#18A2A0" };
    case "EXPIRED":
      return { text: "Expired", color: "#18A2A0" };
    case "WILL_BE_FROZEN":
      return { text: "Will Be Frozen", color: "#18A2A0" };
    case "SWITCHED":
      return { text: "Switched", color: "#18A2A0" };
    case "WILL_BE_CANCELED":
      return { text: "Will be cancel", color: "#18A2A0" };
    case "WILL_BE_DESACTIVATED":
      return { text: "Will be desactivated", color: "#18A2A0" };
    case "DESACTIVATED":
      return { text: "Desactivated", color: "#18A2A0" };
    case "WILL_BE_SWITCHED":
      return { text: "Will be switched", color: "#18A2A0" };
    default:
      return { text: "Switched", color: "#18A2A0" };
  }
};

export const handleDaysPerWeek = (
  numberOfDays: NumberOfDays | undefined | null
) => {
  switch (numberOfDays) {
    case "FIVE_DAYS":
      return "5 Days";
    case "SIX_DAYS":
      return "6 Days";
    case "SEVEN_DAYS":
      return "7 Days";

    default:
      break;
  }
};

export const handleTransactionStatus = (
  TransactionStatus: TransactionStatus | null | undefined
): { text: string; color: string } => {
  switch (TransactionStatus) {
    case "CANCELED":
      return { text: "Canceled", color: "#C72D2D" };
    case "CREATED":
      return { text: "Created", color: "#18A2A0" };
    case "FAILED":
      return { text: "Failed", color: "#C72D2D" };
    case "ACTIVATED":
      return { text: "Activated", color: "#cfc802" };
    case "PENDING":
      return { text: "Pending", color: "#b27d3f" };
    case "SCHEDULED_PAYMENT":
      return { text: "Scheduled payment", color: "#b27d3f" };
    case "SUCCESS":
      return { text: "Success", color: "#3CB371" };
    default:
      return { text: "Success", color: "#18A2A0" };
  }
};

export interface DialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  onClose: () => void;
}
export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export type FilterItemProps = {
  title: string;
  data: any[];
  selectFilter: (id: string) => void;
  idsSelect?: string[];
};
export type FilterData = {
  id: string | null | undefined;
  name: string | null | undefined;
  isSelected?: boolean;
};
export type FilterBar = {
  id: string;
  title: string;
  data: FilterData[];
};
export type FilterHeaderProps = {
  buttonLink: string;
  buttonText: string;
};
export type FilterElementsProps = {
  menuText: string;
  menuValue: string;
  selected: boolean;
};

export const filterElements: Array<FilterElementsProps> = [
  {
    menuText: "Name A-Z",
    menuValue: "NAME_A_Z",
    selected: false,
  },
  {
    menuText: "Name Z-A",
    menuValue: "NAME_Z_A",
    selected: false,
  },
  {
    menuText: "New - Old",
    menuValue: "CREATE_ASC",
    selected: false,
  },
  {
    menuText: "Old - New",
    menuValue: "CREATE_DESC",
    selected: false,
  },
  {
    menuText: "Status",
    menuValue: "STATUS",
    selected: false,
  },
];
export const options = [
  "Today",
  "Yesterday",
  "Last 7 days",
  "Last 30 days",
  "This month",
  "Last month",
  "Custom range",
];
