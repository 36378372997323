import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { InputBase, Theme } from "@mui/material";

export const useStylesNavigation = makeStyles((theme: Theme) => {
  return {
    container__appBar: {
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.common.white} !important`,
      height: "80px",
      justifyContent: "center",
      boxShadow: "none !important",
      width: "calc(100vw - 260px) !important",
      "& > .MuiToolbar-root ": {
        paddingLeft: "0px !important",
      },
    },
    logo: {
      height: "42px",
      marginRight: "15px",
      width: "fit-content !important",
    },
    container__logo: {
      display: "flex",
      height: 80,
      alignItems: "center",
      justifyContent: "center",
      padding: 16,
      boxSizing: "border-box",
    },
    custom__container__menu: {
      height: "calc(100vh - 80px) !important",
      maxHeight: "calc(100vh - 80px) !important",
      maxWidth: "260px !important",
      overflowX: "auto",
      boxSizing: "border-box",
    },
    container__icons: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      borderRadius: `20px !important`,
      margin: `${"0 5px"} !important`,
      minHeight: "38px",
      minWidth: "55px",

      "& svg": {
        width: "20px !important",
        height: "20px !important",
      },
    },

    breadcrumb_container: {
      marginLeft: "20px",
    },
    breadcrumb_value: {
      padding: "6px 4px",
      borderRadius: "4px",
      fontWeight: 400,
      color: theme.palette.primary.contrastText,
      textTransform: "capitalize",
      fontSize: theme.typography.h4.fontSize,
    },
  };
});

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: `${"300px"} !important`,
    fontsize: theme.typography.subtitle2,
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.main,
  fontsize: theme.typography.subtitle2,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

export const HeaderSearch = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "5px",
  // backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.contrastText,
  fontsize: theme.typography.subtitle2,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  flexGrow: 1,
  padding: "3px 0 !important",
  boxShadow:
    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "50%",
  // left : "50%",
  transform: "translateY(-50%)",
}));
