import { useState, memo } from "react";
import moment, { Moment } from "moment";
//material
import { Box, Button, TextField } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
//utils
import { generateRapport } from "../Utils";
//context
import { useAuth } from "../../../contextAPI";

const BoxesReport = () => {
  let auth = useAuth();
  //state
  const [startDate, setStartDate] = useState<Moment>(moment.utc());
  const [loading, setLoading] = useState<boolean>(false);

  //styles
  const ButtonClasses = useStylesButtons();

  //functions
  const onGenerateHandler = async () => {
    setLoading(true);
    try {
      await generateRapport(
        auth.token || "",
        "BOXES_REPORT",
        startDate.toDate()
      );
    } catch (error) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={600}
    >
      <Box
        width="40%"
        height="70%"
        bgcolor="#ffffff73"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection="column"
        borderRadius={5}
        border={1}
        borderColor="#e7e7e773"
      >
        <Box
          fontFamily="Poppins"
          fontSize={20}
          fontWeight="600"
          alignSelf="flex-start"
          marginLeft="15%"
        >
          Report For Boxes
        </Box>
        <Box width="70%">
          <Box width="100%">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                value={startDate}
                inputFormat="DD/MM/YYYY"
                disablePast={false}
                disableFuture={false}
                onChange={(newValue) => {
                  const utcDate = moment.utc(newValue);
                  setStartDate(utcDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Delivery Date"
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box
          width="70%"
          height={100}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            type="submit"
            disabled={!startDate || loading}
            onClick={onGenerateHandler}
            sx={{ width: "100%", height: "50%" }}
          >
            Generate Reports
          </Button>
          <Box
            fontFamily="Poppins"
            fontSize={10}
            fontWeight="500"
            alignSelf="flex-start"
          >
            Use this table to generate boxes report .
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default memo(BoxesReport);
