import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Info,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
//components
import ReminderCard from "./ReminderCard";
import FilterHeader from "../../pageHeader/FilterHeader";
//mutations&querries
import {
  GetRemindersListDocument,
  ReminderStatus,
  useCloseReminderMutation,
  useDeleteReminderMutation,
  useGetRemindersListQuery,
} from "../../../graphql/types";
//utils
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { getMessageError } from "../../Utils";
import { SIDE_FILTER_OPTIONS_REMINDERS } from "../../Reminders/Utils";
//Style
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
//icons
import Delete from "../../../public/icons/delete";

const CARD_NUMBER = 4;

function CustomerReminders() {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //params
  const { id } = useParams();

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [mine, setMine] = useState<boolean | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [idReminder, setIdReminder] = useState<string | null>(null);
  const [selectedReminderStatus, setSelectedReminderStatus] = useState("");
  const [reminderStatus, setReminderStatus] = useState<ReminderStatus[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  //queries&mutations
  const dataGetReminders = useGetRemindersListQuery({
    variables: {
      input: {
        page: 1,
        documentPerPage: 100,
        mine: mine ?? false,
        customer: id,
        status: reminderStatus,
      },
    },
    fetchPolicy: "network-only",
  });
  const Reminders = useMemo(
    () => dataGetReminders.data?.getRemindersList.data || [],
    [dataGetReminders.data]
  );

  const [deleteReminder] = useDeleteReminderMutation();
  const [closeReminder] = useCloseReminderMutation();

  //functions
  const handleClickMenu =
    (item: string, status: string) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setIdReminder(item);
      setSelectedReminderStatus(status);
    };

  const handleCloseModal = () => {
    setOpenDelete(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (idReminder) {
      navigate(`/admin/reminders/update_reminder/${idReminder}`);
    }
  };

  const handleCloseDelete = () => {
    setAnchorEl(null);
    setOpenDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDelete(false);
  };

  const DeleteReminderHandler = async () => {
    setOpenDelete(false);
    await deleteReminder({
      variables: { input: idReminder || "" },
      refetchQueries: [GetRemindersListDocument],
      onCompleted() {
        enqueueSnackbar("Reminder deleted successfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const CloseReminderHandler = async () => {
    setOpen(false);
    await closeReminder({
      variables: { closeReminderInput: { _id: idReminder || "" } },
      refetchQueries: [GetRemindersListDocument],
      onCompleted() {
        enqueueSnackbar("Reminder closed successfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const handleNext = () => {
    if (currentIndex + CARD_NUMBER < Reminders.length) {
      setCurrentIndex(currentIndex + CARD_NUMBER);
    }
  };

  const handleBack = () => {
    if (currentIndex - CARD_NUMBER >= 0) {
      setCurrentIndex(currentIndex - CARD_NUMBER);
    }
  };

  return (
    <Box m={2}>
      <FilterHeader
        buttonLink={
          useIsAuthorized("MANAGEMENT_REMINDER_CREATE")
            ? `/admin/reminders/add_reminder/?customerid=${id}`
            : undefined
        }
        buttonText={
          useIsAuthorized("MANAGEMENT_REMINDER_CREATE")
            ? "Create Reminder"
            : undefined
        }
        showSearch={false}
        pageTitle="Reminders"
        pageInfo="This is the list of customer reminders"
        setIdsProgram={() => null}
        hideProgram={true}
        reminderStatus={reminderStatus}
        setReminderStatus={setReminderStatus}
        filterConfig={SIDE_FILTER_OPTIONS_REMINDERS}
        hideExcel
        hideDateAndTitle
      />
      <Box display="flex" justifyContent="space-between" mb={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={mine ?? false}
              onChange={(e) => setMine(e.target.checked)}
            />
          }
          label="Mine"
        />
      </Box>
      <Grid container spacing={2}>
        {Reminders.slice(currentIndex, currentIndex + CARD_NUMBER).map(
          (item) => (
            <ReminderCard
              key={item._id}
              item={item}
              handleClickMenu={handleClickMenu}
            />
          )
        )}
      </Grid>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button
          onClick={handleBack}
          disabled={currentIndex === 0}
          variant="contained"
        >
          {<KeyboardArrowLeft />}
          back
        </Button>
        <Button
          onClick={handleNext}
          disabled={currentIndex + CARD_NUMBER >= Reminders.length}
          variant="contained"
        >
          Next
          {<KeyboardArrowRight />}
        </Button>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorPosition={{
          left: anchorEl?.getBoundingClientRect().left || 0,
          top: anchorEl?.getBoundingClientRect().top || 0,
        }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorReference="anchorPosition"
      >
        {useIsAuthorized("MANAGEMENT_REMINDER_UPDATE") &&
          selectedReminderStatus == "PENDING" && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}
        {useIsAuthorized("MANAGEMENT_REMINDER_CLOSE") &&
          selectedReminderStatus == "PENDING" && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_container}
              disableRipple
            >
              <Box>
                <span className="menu_title">Close</span>
              </Box>
            </MenuItem>
          )}
        {useIsAuthorized("MANAGEMENT_REMINDER_DELETE") && (
          <MenuItem
            onClick={handleCloseDelete}
            className={classes.menu_delete_container}
            disableRipple
          >
            <Box>
              <span className="menu_title">Delete</span>
            </Box>
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={openDelete}
        onClose={handleCloseModalDelete}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <Delete />
          <span className="alert_dialog_title_text">Delete Reminder ?</span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to delete this reminder?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseModal}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={ButtonsClasses.RedButton}
            onClick={DeleteReminderHandler}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <Info />
          <span className="confirm_dialog_title_text">
            close this reminder ?
          </span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to close this reminder ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseModal}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={ButtonsClasses.BlueButton}
            onClick={CloseReminderHandler}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CustomerReminders;
