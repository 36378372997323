//Material Ui
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import DeleteIcon from "@mui/icons-material/Delete";

//Types
import { NotePiority } from "../../../graphql/types";
export type NoteCardProps = {
  id?: string;
  text?: string | null;
  date?: string;
  priority?: NotePiority;
  isCompleted?: boolean;
  onClickDelete?: () => void;
  onClick?: () => void;
};

const NoteCard = (props: NoteCardProps) => {
  const { date, text, priority, isCompleted, onClickDelete, onClick } = props;
  //render
  const getStyleButtonSelected = (prop: NotePiority) => () => {
    if (priority === prop) {
      return {
        border: "1px solid #000",
        width: 20,
        height: 20,
        borderRadius: 10,
      };
    }
    return {};
  };
  const getBackgroundColor = () => {
    if (isCompleted) {
      return "#D9D9D9";
    } else if (priority === "LOW") {
      return "#008026";
    } else if (priority === "NORMAL") {
      return "#FEED01";
    } else if (priority === "HIGH") {
      return "#FF8B00";
    }
  };
  const renderListPriority = () => {
    if (isCompleted) {
      return;
    }
    return (
      <Box display="flex" gap={1}>
        <Box sx={getStyleButtonSelected("LOW")}>
          <CircleRoundedIcon fontSize="small" sx={{ color: "#008026" }} />
        </Box>
        <Box sx={getStyleButtonSelected("NORMAL")}>
          <CircleRoundedIcon fontSize="small" sx={{ color: "#FEED01" }} />
        </Box>
        <Box sx={getStyleButtonSelected("HIGH")}>
          <CircleRoundedIcon fontSize="small" sx={{ color: "#FF8B00" }} />
        </Box>
      </Box>
    );
  };
  return (
    <Card
      onClick={onClick}
      sx={{
        position: "relative",
        minWidth: 220,
        maxWidth: 220,
        borderRadius: "0px",
        minHeight: 220,
        backgroundColor: getBackgroundColor(),
      }}
    >
      <Box sx={{ position: "absolute", right: 0, top: 0, zIndex: 99 }}>
        <IconButton aria-label="delete" onClick={onClickDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>

      <CardContent>
        {renderListPriority()}
        <Box mt={1} minHeight={140}>
          <Typography
            variant="body2"
            fontSize={14}
            color={isCompleted ? "#888888" : "#444444"}
          >
            {text}
          </Typography>
        </Box>
      </CardContent>
      <CardActions>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
        >
          <Typography fontSize={8} color="#444444">
            {date}
          </Typography>
          {!isCompleted && (
            <CalendarMonthOutlinedIcon sx={{ color: "#1C2843" }} />
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default NoteCard;
