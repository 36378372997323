import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete } from "@mui/icons-material";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//mutations&queries
import {
  GetTemplatesListDocument,
  useDeleteTemplateMutation,
  useGetTemplatesListQuery,
} from "../../../graphql/types";
//components
import Table from "../../layout/commun/Table";
//utils
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { columns } from "./Utils";
import { getMessageError } from "../../Utils";

function NotificationTemplatesList() {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();
  const ButtonClasses = useStylesButtons();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [templateId, setTemplateId] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  //mutations&queries
  const [deleteTemplate] = useDeleteTemplateMutation();
  const TemplatesData = useGetTemplatesListQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
      },
    },
    fetchPolicy: "no-cache",
  });

  const TemplatesList = useMemo(
    () => TemplatesData.data?.getTemplatesList.data || [],
    [TemplatesData.data]
  );

  const count = useMemo(
    () => TemplatesData.data?.getTemplatesList.count || 0,
    [TemplatesData.data]
  );

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (templateId) {
      navigate(
        `/data/notification_templates/update_notification_templates/${templateId}`
      );
    }
  };

  const goToAddTemplate = () => {
    navigate(`/data/notification_templates/add_notification_templates`);
  };

  const handleClickMenu =
    (item: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setTemplateId(item);
    };

  const DeleteTemplateHandler = async () => {
    setOpen(false);
    await deleteTemplate({
      variables: { input: templateId || "" },
      refetchQueries: [GetTemplatesListDocument],
      onCompleted() {
        enqueueSnackbar("Template deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const renderTableRows = () => {
    return (
      TemplatesList.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          nameEN: el.title?.EN,
          nameAR: el.title?.AR,
          type: el.type,
          actions: (
            <IconButton onClick={handleClickMenu(el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <>
      {useIsAuthorized("MANAGEMENT_TEMPLATE_CREATE") ? (
        <Box display="flex" justifyContent="end">
          <Button
            variant="contained"
            onClick={goToAddTemplate}
            className={ButtonClasses.button_open_list_page}
          >
            Create Template
          </Button>
        </Box>
      ) : (
        <></>
      )}

      <br />
      <TableContainer>
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No Templates found !"
          loading={TemplatesData.loading}
          numberOfFakeRow={30}
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>

      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("MANAGEMENT_TEMPLATE_EDIT") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}

          {useIsAuthorized("MANAGEMENT_TEMPLATE_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <Box onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </Box>
            </MenuItem>
          )}
        </Menu>

        {/* DELETE TEMPLATE */}
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Template ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Template ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={DeleteTemplateHandler}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default NotificationTemplatesList;
