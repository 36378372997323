import { useRef, useState } from "react";
import dayjs from "dayjs";
//material ui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grow from "@mui/material/Grow";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
//components
import CardStatistical from "../../layout/CardStatistical/CardStatistical";
import DateRangePicker from "../../layout/commun/DateRangePicker";
//icon
import DeliveredIcon from "../../../public/icons/deliveredIcon";
import SignedUpCustomersIcon from "../../../public/icons/SignedUpCustomersIcon";
import UnattendedIcon from "../../../public/icons/unattendedIcon";
import RemindersIcon from "../../../public/icons/RemindersIcon";
import ExpiringCustomersIcon from "../../../public/icons/ExpiringCustomersIcon";
import AppointmentRequested from "../../../public/icons/appointmentRequested";
import UnfrozenCustomers from "../../../public/icons/unfrozenCustomers";
import ComplaintsIcon from "../../../public/icons/complaintsIcon";
import TotalRenewedCustomer from "../../../public/icons/totalRenewedCustomer";
import TotalNumberOfCustomers from "../../../public/icons/totalNumberOfCustomers";
import CashOnDelivery from "../../../public/icons/cashOnDelivery";
import BirthdaysIcon from "../../../public/icons/BirthdaysIcon";
import RenewingCustomerIcon from "../../../public/icons/RenewingCustomerIcon";
import SwitchingCustomerIcon from "../../../public/icons/SwitchingCustomerIcon";
import DeactivatedCustomersIcon from "../../../public/icons/DeactivatedCustomersIcon";
import BlacklistedCustomersIcon from "../../../public/icons/BlacklistedCustomersIcon";
import ConvertedLeadsIcon from "../../../public/icons/ConvertedLeadsIcon";
import ActivatedPaymentIcon from "../../../public/icons/ActivatedPaymentIcon";
//queries&mutations
import { useGetDashboardStatsQuery } from "../../../graphql/types";
//utils
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { options } from "../../Utils";
import PendingIcon from "../../../public/icons/PendingIcon";
import { IconButton } from "@mui/material";
const DashboardHome = (): JSX.Element => {
  const anchorRef = useRef<HTMLDivElement>(null);
  //state
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(6);
  //queries&mutations
  const dashStats = useGetDashboardStatsQuery({
    variables: {
      input: {
        from: from,
        to: to,
      },
    },
    fetchPolicy: "network-only",
  });
  //functions
  //clear date range search
  const handleclear = () => {
    if (setFrom || setTo) {
      setFrom?.(null);
      setTo?.(null);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" flexDirection="column" gap={8}>
        <Box display="flex" gap={3}>
          <DesktopDatePicker
            label="From"
            value={from}
            onChange={(newValue) => {
              if (setFrom) {
                setFrom(newValue?.toDate() || new Date());
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  width: "70%",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                  border: 0,
                }}
              />
            )}
            disabled={options[selectedIndex] !== "Custom range"}
            maxDate={dayjs(to)}
          />
          <DesktopDatePicker
            label="To"
            value={to}
            onChange={(newValue) => {
              if (setTo) {
                setTo(newValue?.toDate() || new Date());
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  width: "70%",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                  border: 0,
                }}
              />
            )}
            disabled={options[selectedIndex] !== "Custom range"}
            minDate={dayjs(from)}
          />
          {to && options[selectedIndex] == "Custom range" ? (
            <IconButton
              onClick={handleclear}
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "50%",
                height: 40,
                margin: "auto",
              }}
            >
              <DeleteIcon />
            </IconButton>
          ) : null}
          <ButtonGroup
            variant="outlined"
            ref={anchorRef}
            aria-label="split button"
            color="secondary"
            disableElevation
            sx={{ ml: "auto" }}
          >
            <Button disableElevation sx={{ width: 145 }}>
              {options[selectedIndex]}
            </Button>
            <Button
              variant="contained"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              disableElevation
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </Box>
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={3}>
          {useIsAuthorized("DASHBOARD_EXPIRING_CUSTOMER") && (
            <CardStatistical
              title="Expiring Customers"
              icon={<ExpiringCustomersIcon />}
              count={dashStats.data?.getDashboardStats.ExpiringCustomer || 0}
              bgcolorIcon="#00425A"
              path="/dashboard/expiring_customers/list_expiring_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_FIRSTBOX_DELIVERED") && (
            <CardStatistical
              title="1st box delivered"
              icon={<DeliveredIcon />}
              count={dashStats.data?.getDashboardStats.firstBoxDelivered || 0}
              bgcolorIcon="#5D3891"
              path="/dashboard/first_box_delivered/list_first_box_delivered_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_UNFROZEN_CUSTOMER") && (
            <CardStatistical
              title="Unfrozen Customers"
              icon={<UnfrozenCustomers />}
              count={dashStats.data?.getDashboardStats.unfrozenCustomer || 0}
              bgcolorIcon="#BFDB38"
              path="/dashboard/unfrozen_customers/list_unfrozen_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_RENEWED_CUSTOMER") && (
            <CardStatistical
              title="Total Renewed customer"
              icon={<TotalRenewedCustomer />}
              count={dashStats.data?.getDashboardStats.renewedCustomer || 0}
              bgcolorIcon="#301E67"
              path="/dashboard/renewed_customers/list_renewed_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_SWITCHED_CUSTOMER") && (
            <CardStatistical
              title="Switching customers"
              icon={<SwitchingCustomerIcon />}
              count={dashStats.data?.getDashboardStats.switchedCustomers || 0}
              bgcolorIcon="#01579B"
              path="/dashboard/switched_customers/list_switched_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_SCHEDULED_RENEWED") && (
            <CardStatistical
              title="Renewing customer-cash on delivery"
              icon={<RenewingCustomerIcon />}
              count={
                dashStats.data?.getDashboardStats.scheduledRenewalCustomers || 0
              }
              bgcolorIcon="#f45c24"
              path="/dashboard/scheduled_reneweal_customers/list_scheduled_reneweal_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_TOTAL_CUSTOMER") && (
            <CardStatistical
              title="Total number of customers"
              icon={<TotalNumberOfCustomers />}
              count={dashStats.data?.getDashboardStats.totalCustomers || 0}
              bgcolorIcon="#0081C9"
              path="/dashboard/total_customers/list_total_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_SCHEDULED_PAYMENT") && (
            <CardStatistical
              title="New customer-cash on delivery"
              icon={<CashOnDelivery />}
              count={dashStats.data?.getDashboardStats.scheduledPayment || 0}
              bgcolorIcon="#439A97"
              path="/dashboard/scheduled_customers/list_scheduled_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_PENDING_PAYMENT") && (
            <CardStatistical
              title="Pending Payment"
              icon={<PendingIcon />}
              count={dashStats.data?.getDashboardStats.pendingPayment || 0}
              path="/dashboard/pending_customers/list_pending_customers"
              bgcolorIcon={""}
            />
          )}
          {useIsAuthorized("DASHBOARD_PROCESSING_PAYMENT") && (
            <CardStatistical
              title="Activated Payments"
              icon={<ActivatedPaymentIcon />}
              count={dashStats.data?.getDashboardStats.activatedPayment || 0}
              bgcolorIcon="#d9cf04"
              path="/dashboard/activated_customers/list_activated_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_BIRTHDAYS") && (
            <CardStatistical
              title="Birthdays"
              icon={<BirthdaysIcon />}
              count={dashStats.data?.getDashboardStats.birthdays || 0}
              bgcolorIcon="#42032C"
              path="/dashboard/birthdays/list_birthdays"
            />
          )}
          {useIsAuthorized("DASHBOARD_NEW_SIGNED_UP_CUSTOMERS") && (
            <CardStatistical
              title="Signed up customers"
              icon={<SignedUpCustomersIcon />}
              count={dashStats.data?.getDashboardStats.signedUpCustomers || 0}
              bgcolorIcon="#D61355"
              path="/dashboard/new_signed_up_customers/list_new_signed_up_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_UNATTENDED_LEADS") && (
            <CardStatistical
              title="Unattented Leads"
              icon={<UnattendedIcon />}
              count={dashStats.data?.getDashboardStats.unattentedLeads || 0}
              bgcolorIcon="#A61F69"
              path="/dashboard/unattended_leads/list_unattended_leads"
            />
          )}
          {useIsAuthorized("DASHBOARD_DEACTIVATED_CUSTOMERS") && (
            <CardStatistical
              title="Deactivated customers"
              icon={<DeactivatedCustomersIcon />}
              count={
                dashStats.data?.getDashboardStats.deactivatedCustomers || 0
              }
              bgcolorIcon="#8ADAB2"
              path="/dashboard/deactivated_customer/list_customer"
            />
          )}
          {useIsAuthorized("DASHBOARD_BLACKLISTED_CUSTOMERS") && (
            <CardStatistical
              title="Blacklisted customers"
              icon={<BlacklistedCustomersIcon />}
              count={
                dashStats.data?.getDashboardStats
                  .whatsAppBlackListedCustomers || 0
              }
              bgcolorIcon="#A94064"
              path="/dashboard/blackListed_customers/list_blackListed_customers"
            />
          )}
          {useIsAuthorized("DASHBOARD_DRIVER_BLACKLISTED_CUSTOMERS") && (
            <CardStatistical
              title="Blacklisted drivers"
              icon={<BlacklistedCustomersIcon />}
              count={
                dashStats.data?.getDashboardStats
                  .whatsAppDriverBlackListedCustomers || 0
              }
              bgcolorIcon="#A94064"
              path="/dashboard/blackListed_drivers/list_blackListed_drivers"
            />
          )}
          {useIsAuthorized("ADMIN_CONVERT_LIST") && (
            <CardStatistical
              title="Converted leads"
              icon={<ConvertedLeadsIcon />}
              count={dashStats.data?.getDashboardStats.convertedLeads || 0}
              bgcolorIcon="#00559A"
              path="/dashboard/converted_leads/list_converted_leads"
            />
          )}
          <CardStatistical
            title="Appointment requested"
            icon={<AppointmentRequested />}
            count={dashStats.data?.getDashboardStats.appointmentRequested || 0}
            bgcolorIcon="#1F8A70"
            path="/dashboard/expiring_customers/list_expiring_customers"
          />
          <CardStatistical
            title="Complaints"
            icon={<ComplaintsIcon />}
            count={dashStats.data?.getDashboardStats.complaints || 0}
            bgcolorIcon="#FF0032"
            path="/admin/complains/list_complains"
          />
          {useIsAuthorized("DASHBOARD_RENEWED_CUSTOMER") && (
            <CardStatistical
              title="Reminders"
              icon={<RemindersIcon />}
              count={dashStats.data?.getDashboardStats.reminders || 0}
              bgcolorIcon="#d9cf04"
              path="/admin/reminders/list_reminders"
            />
          )}
        </Box>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <DateRangePicker
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  setOpen={setOpen}
                  setFrom={setFrom}
                  setTo={setTo}
                />
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </LocalizationProvider>
  );
};
export default DashboardHome;
