function UnattendedIcon() {
  return (
    <svg
      width="28"
      height="38"
      viewBox="0 0 54 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_394_60866)">
        <path
          d="M25.896 37.1258C25.896 33.9412 23.1647 31.3516 19.8048 31.3516C16.4449 31.3516 13.7101 33.9412 13.7101 37.1258C13.7101 37.8308 14.2864 38.4017 15.0024 38.4017C15.7184 38.4017 16.2947 37.8308 16.2947 37.1258C16.2947 35.3478 17.8699 33.8999 19.8013 33.8999C21.7327 33.8999 23.3044 35.3478 23.3044 37.1258C23.3044 38.0922 22.784 39.0105 21.855 39.6983L14.2375 45.1734C14.2131 45.1905 14.1956 45.2112 14.1711 45.2318C14.1362 45.2628 14.0978 45.2903 14.0664 45.3247C14.0349 45.3556 14.0105 45.39 13.9825 45.4244C13.9581 45.4554 13.9336 45.4863 13.9127 45.5173C13.8882 45.5551 13.8673 45.5964 13.8463 45.6376C13.8289 45.6686 13.8114 45.703 13.7974 45.7374C13.78 45.7821 13.766 45.8302 13.752 45.8749C13.745 45.9059 13.7346 45.9368 13.7276 45.9712C13.7171 46.0297 13.7136 46.0847 13.7101 46.1432C13.7101 46.1638 13.7031 46.1845 13.7031 46.2051C13.7031 46.212 13.7031 46.2154 13.7031 46.2188C13.7031 46.2842 13.7136 46.3461 13.7241 46.4114C13.7241 46.4321 13.7276 46.4527 13.7311 46.4699C13.745 46.5284 13.7625 46.5834 13.7835 46.6419C13.7904 46.6659 13.7974 46.6866 13.8079 46.7106C13.8289 46.7588 13.8568 46.8035 13.8813 46.8482C13.8987 46.8757 13.9127 46.9067 13.9301 46.9307C13.9371 46.9376 13.9406 46.9479 13.9441 46.9582C13.9616 46.9789 13.979 46.9961 13.9965 47.0133C14.0349 47.0614 14.0768 47.1027 14.1222 47.144C14.1502 47.168 14.1781 47.1921 14.2096 47.2162C14.255 47.2506 14.3039 47.2815 14.3563 47.309C14.3877 47.3262 14.4156 47.3434 14.4471 47.3572C14.5064 47.3847 14.5693 47.4053 14.6322 47.4225C14.6566 47.4294 14.6811 47.4397 14.709 47.4466C14.7998 47.4672 14.8941 47.4776 14.9884 47.4776H24.5862C25.3022 47.4776 25.8785 46.9067 25.8785 46.2016C25.8785 45.4966 25.3022 44.9257 24.5862 44.9257H18.9596L23.3848 41.7446C24.9739 40.5718 25.882 38.8867 25.882 37.1258H25.896Z"
          fill="#fff"
        />
        <path
          d="M39.0049 41.324H38.4705V32.623C38.4705 32.09 38.1352 31.6154 37.6288 31.4296C37.1258 31.2439 36.5495 31.3884 36.2003 31.7908L27.4407 41.7642C27.1089 42.1425 27.032 42.6755 27.2416 43.1295C27.4546 43.5835 27.9122 43.8758 28.4186 43.8758H35.8859V46.2006C35.8859 46.9056 36.4622 47.4765 37.1782 47.4765C37.8942 47.4765 38.4705 46.9056 38.4705 46.2006V43.8758H39.0049C39.7209 43.8758 40.2971 43.3049 40.2971 42.5999C40.2971 41.8949 39.7209 41.324 39.0049 41.324ZM35.8824 41.324H31.2477L35.8824 36.0484V41.324Z"
          fill="#fff"
        />
        <path
          d="M27.0402 12.8242C12.13 12.8242 0 24.7683 0 39.4498C0 54.1314 12.13 65.9998 27.0402 65.9998C41.9503 65.9998 54.0035 54.0901 54.0035 39.4498C54.0035 24.8095 41.9084 12.8242 27.0402 12.8242ZM27.0402 63.4514C13.5585 63.4514 2.58806 52.6835 2.58806 39.4498C2.58806 26.2161 13.555 15.376 27.0402 15.376C40.5253 15.376 51.4154 26.1783 51.4154 39.4498C51.4154 52.7214 40.4799 63.4514 27.0402 63.4514Z"
          fill="#fff"
        />
        <path
          d="M20.7923 7.5007H25.7449V9.74988C25.7449 10.4549 26.3212 11.0258 27.0372 11.0258C27.7532 11.0258 28.3294 10.4549 28.3294 9.74988V7.5007H33.2087C33.9247 7.5007 34.501 6.92981 34.501 6.22479V1.27591C34.501 0.570893 33.9247 0 33.2087 0H20.7923C20.0763 0 19.5 0.570893 19.5 1.27591V6.22479C19.5 6.92981 20.0763 7.5007 20.7923 7.5007ZM22.0846 2.55182H31.9129V4.95232H22.0846V2.55182Z"
          fill="#fff"
        />
        <path
          d="M1.90079 18.451C2.23259 18.451 2.56439 18.3271 2.81586 18.0761L3.14068 17.7562L4.7019 19.3382C4.95686 19.5962 5.29216 19.7269 5.63095 19.7269C5.96973 19.7269 6.28058 19.6065 6.53205 19.3658C7.04547 18.8774 7.05595 18.0692 6.55999 17.5637L4.97083 15.9507L5.32708 15.5999C5.83352 15.1013 5.83352 14.2965 5.32708 13.7978C4.81716 13.2992 3.99987 13.2992 3.49693 13.7978L2.49454 14.7883C2.39674 14.8433 2.30244 14.9052 2.21862 14.9843C2.12432 15.0737 2.05446 15.1735 1.99509 15.2801L0.989202 16.274C0.482766 16.7727 0.482766 17.5774 0.989202 18.0761C1.24417 18.3271 1.57597 18.451 1.90428 18.451H1.90079Z"
          fill="#fff"
        />
        <path
          d="M48.3697 19.727C48.7085 19.727 49.0438 19.5963 49.2987 19.3384L50.8914 17.722L51.2756 18.09C51.5271 18.3307 51.8519 18.4511 52.1767 18.4511C52.5015 18.4511 52.8508 18.3204 53.1057 18.0625C53.6052 17.5569 53.5912 16.7487 53.0778 16.2604L51.9567 15.1874C51.9078 15.1152 51.8519 15.0464 51.7855 14.9845C51.7192 14.9226 51.6493 14.8676 51.576 14.8228L50.4897 13.7842C49.9763 13.2924 49.1555 13.3062 48.6596 13.8117C48.1601 14.3173 48.1741 15.1255 48.6875 15.6138L49.0368 15.9474L47.4441 17.5638C46.9447 18.0694 46.9587 18.8776 47.4721 19.3659C47.7236 19.6067 48.0484 19.727 48.3732 19.727H48.3697Z"
          fill="#fff"
        />
      </g>
      {/* <defs>
          <clipPath id="clip0_394_60866">
            <rect width="54" height="66" fill="white" />
          </clipPath>
        </defs> */}
    </svg>
  );
}

export default UnattendedIcon;
