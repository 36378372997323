import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "CUSTOMER NAME",
    accessor: "name",
  },
  {
    header: "MOBILE",
    accessor: "mobile",
  },
  {
    header: "EMAIL",
    accessor: "email",
  },
  {
    header: "PLAN",
    accessor: "plan",
  },

  {
    header: "Deactivation Date",
    accessor: "deactivationDate",
  },

  {
    header: "Deactivation Reason",
    accessor: "deactivationReason",
  },

  {
    header: "Actions",
    accessor: "actions",
  },
];
