import { styled } from "@mui/material/styles";
import { Drawer as DrawerMui } from "@mui/material";

export const Drawer = styled(DrawerMui)(({ theme, color }) => ({
  "& .MuiPaper-root": {
    width: "260px",
    borderRight: "none",
    backgroundColor: color ? color : theme.palette.primary.main,
    maxHeight: "100vh",
    color: "white",
  },
}));
