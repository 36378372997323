import { forwardRef } from "react";
import QRCode from "react-qr-code";
//material ui
import { Box, Typography } from "@mui/material";
//types
import { EntryGiftVoucherProps, voucherComponentProps } from "../Types";
//logo&icons
import DietureLogoBlack from "../../../public/icons/dietureLogoSvg.svg";
import AndroidIcon from "../../../public/icons/google-play.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AppleIcon from "@mui/icons-material/Apple";
import HeyIcon from "../../../public/icons/heyIcon";
import VoucherIconOne from "../../../public/icons/VoucherIconOne";
import VoucherIconTwo from "../../../public/icons/VoucherIconTwo";
import VoucherIconThree from "../../../public/icons/VoucherIconThree";
import VoucherIconFour from "../../../public/icons/VoucherIconFour";
import VoucherIconFive from "../../../public/icons/VoucherIconFive";
import VoucherIconSix from "../../../public/icons/VoucherIconSix";

const DIETURE_ADDRESS =
  "Dieture, #389, Al khafji st. | T: +974 333 44 679 | dieture.com";
const EntryGiftVoucherPrint = forwardRef<HTMLDivElement, EntryGiftVoucherProps>(
  ({ customerEvents }, ref) => {
    return (
      <div ref={ref} style={{ height: "100vh", width: "100vw" }}>
        <Box
          width="100%"
          height="49.5%"
          display="flex"
          flexDirection="row"
          maxHeight="49.5%"
          minHeight="49.5%"
        >
          <Box
            width="100%"
            paddingTop={2}
            borderRight={1}
            borderColor="black"
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box paddingLeft={2}>
              <img src={DietureLogoBlack} />
            </Box>

            <Box
              display="flex"
              paddingLeft="20%"
              paddingRight="21%"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <HeyIcon />
                <Typography fontFamily="Poppins">
                  <strong>Welcome to dieture !</strong>
                </Typography>
                <Typography fontFamily="Poppins">
                  We are excited for the journey ahead of you. Delighted to be
                  part of your efforts in achieving your nutrition and wellbeing
                  goals.
                </Typography>
                <Typography fontFamily="Poppins" marginTop={1}>
                  Again{" "}
                  <strong>welcome to the tasty side of healthy living</strong>
                </Typography>
                <Typography fontFamily="Poppins" marginTop={1}>
                  IT'S NOT <strong>DIET.IT'S LIFE</strong>
                </Typography>
                <Typography fontFamily="Poppins">Dieture,</Typography>
              </Box>
            </Box>
            <Footer />
          </Box>
          <Box
            width="100%"
            paddingTop={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Header />
            <table
              style={{
                width: "60%",
                alignSelf: "center",
                borderCollapse: "collapse",
              }}
            >
              <tr>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  border={0.5}
                  borderColor="black"
                  paddingLeft={1}
                  paddingTop={1}
                >
                  <Box>
                    <VoucherIconOne />
                  </Box>
                  <Box marginLeft={1}>
                    <Typography fontSize={10} fontFamily="Poppins">
                      <li> New menu every month</li>
                    </Typography>
                    <Typography fontSize={10} fontFamily="Poppins">
                      <li> Select your Meals 48 hrs in </li>
                    </Typography>
                    <Typography
                      fontSize={10}
                      fontFamily="Poppins"
                      marginLeft={1.8}
                    >
                      advance
                    </Typography>
                  </Box>
                </Box>
              </tr>
              <tr>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  border={0.5}
                  borderColor="black"
                  paddingLeft={1}
                  paddingTop={1}
                >
                  <Box>
                    <VoucherIconTwo />
                  </Box>
                  <Box marginLeft={1}>
                    <Typography fontSize={10} fontFamily="Poppins">
                      <li>Pause / Restart 48 hrs in</li>
                    </Typography>
                    <Typography
                      fontSize={10}
                      fontFamily="Poppins"
                      marginLeft={1.8}
                    >
                      advance
                    </Typography>
                  </Box>
                </Box>
              </tr>
              <tr>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  border={0.5}
                  borderColor="black"
                  paddingLeft={1}
                  paddingTop={1}
                >
                  <Box>
                    <VoucherIconThree />
                  </Box>
                  <Box marginLeft={1}>
                    <Typography fontSize={10} fontFamily="Poppins">
                      <li>No Cancellation</li>
                    </Typography>
                  </Box>
                </Box>
              </tr>
              <tr>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  border={0.5}
                  borderColor="black"
                  paddingLeft={1}
                  paddingTop={1}
                >
                  <Box>
                    <VoucherIconFour />
                  </Box>
                  <Box marginLeft={1}>
                    <Typography fontSize={10} fontFamily="Poppins">
                      <li>Connect with your diet</li>
                    </Typography>
                    <Typography
                      fontSize={10}
                      fontFamily="Poppins"
                      marginLeft={1.8}
                    >
                      advisor once a week
                    </Typography>
                  </Box>
                </Box>
              </tr>
              <tr>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  border={0.5}
                  borderColor="black"
                  paddingLeft={1}
                  paddingTop={1}
                >
                  <Box>
                    <VoucherIconFive />
                  </Box>
                  <Box marginLeft={1}>
                    <Typography fontSize={10} fontFamily="Poppins">
                      <li>Keep refrigerated</li>
                    </Typography>
                  </Box>
                </Box>
              </tr>
              <tr>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  border={0.5}
                  borderColor="black"
                  paddingLeft={1}
                  paddingTop={1}
                >
                  <Box>
                    <VoucherIconSix />
                  </Box>
                  <Box marginLeft={1}>
                    <Typography fontSize={10} fontFamily="Poppins">
                      <li>Consume within 24 hrs</li>
                    </Typography>
                  </Box>
                </Box>
              </tr>
            </table>
            <Footer />
          </Box>
        </Box>
        <Box
          width="100%"
          height="50.5%"
          display="flex"
          flexDirection="row"
          maxHeight="50.5%"
          minHeight="50.5%"
        >
          <Box width="100%" height="100%">
            {customerEvents?.codes?.map((el) => {
              return (
                <VoucherCard
                  key={el}
                  code={el}
                  name={
                    customerEvents.customer?.fname +
                    " " +
                    customerEvents.customer?.lname
                  }
                />
              );
            })}
          </Box>
          <Box width="100%" height="100%" borderLeft={1} borderColor="black">
            <Box marginTop={1}>
              <Header />
            </Box>
            <Box height="80%">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                height="100%"
              >
                <QRCode
                  level="L"
                  bgColor="white"
                  fgColor="black"
                  size={100}
                  value={"https://www.youtube.com/@dieture4325"}
                />
                <Box marginTop={2}>
                  <YouTubeIcon />
                </Box>
                <Typography>
                  Visit <strong> Dieture</strong> YouTube channel
                </Typography>
                <Typography>
                  for more <strong>app features</strong>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
);

export default EntryGiftVoucherPrint;

export const Footer = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      paddingLeft={1}
      paddingRight={1}
      borderTop={1}
      borderBottom={1}
      borderColor="black"
      paddingTop={1}
      paddingBottom={1}
    >
      <Typography fontSize={8}>{DIETURE_ADDRESS}</Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography fontSize={8}>Download the app</Typography>
        <AppleIcon style={{ width: 12, height: 12 }} />
        <img src={AndroidIcon} style={{ width: 9, height: 10 }}/>
      </Box>
    </Box>
  );
};

export const Header = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      paddingLeft={1}
      paddingRight={1}
    >
      <img src={DietureLogoBlack} />

      <Typography fontFamily="Poppins">
        IT'S NOT <strong>DIET.IT'S LIFE</strong>
      </Typography>
    </Box>
  );
};

export const VoucherCard: React.FC<voucherComponentProps> = ({
  name,
  code,
}) => {
  return (
    <Box
      style={{
        borderBottomStyle: "dashed",
      }}
      display="flex"
      flexDirection="row"
      height="10vh"
      borderBottom={1}
      borderColor="black"
    >
      <Box
        bgcolor="black"
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box
          bgcolor="white"
          height={60}
          width={60}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <QRCode
            level="L"
            bgColor="white"
            fgColor="black"
            size={50}
            value={"http://onelink.to/a3gnsa"}
          />
        </Box>
        <Typography
          color="white"
          fontFamily="Poppins"
          fontSize={6}
          marginTop={0.5}
        >
          DOWNLOAD
        </Typography>
        <Typography color="white" fontFamily="Poppins" fontSize={6}>
          Dieture app
        </Typography>
      </Box>
      <Box paddingLeft={2} paddingRight={1}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography fontFamily="Poppins" fontSize={18} marginTop={1.5}>
            <strong>GIFT VOUCHER</strong>
          </Typography>
          <img src={DietureLogoBlack} width={80} />
        </Box>
        <Typography fontFamily="Poppins" fontSize={10}>
          Enter the code <strong>{code}</strong> in the "checkout page" to avail
          5 complimentary meals for a day from any of our programs
        </Typography>
        <Typography fontFamily="Poppins" fontSize={10}>
          With love from <strong>{name}</strong>
        </Typography>
        <Typography fontFamily="Poppins" fontSize={8} textAlign="right">
          dieture.com|333 44 679
        </Typography>
      </Box>
    </Box>
  );
};
