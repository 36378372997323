import { ColumnsProps } from "../layout/commun/Table";

export const PackingAgentsListColumns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "Email",
    accessor: "email",
  },
  {
    header: "Password",
    accessor: "password",
  },
  {
    header: "Role",
    accessor: "role",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
