import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStylesMainContainer = makeStyles((theme: Theme) => {
  return {
    main__container: {
      height: "calc(100vh - 80px)",
      maxHeight: "calc(100vh - 80px)",
      background: "#fff",
      overflowX: "auto",
      padding: "24px 20px 20px 284px",
      boxSizing: "border-box",
    },
  };
});
