import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import { Autocomplete, Box, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../styles/Recipe__styles";
//components
import PageAddHeader from "../../pageAddHeader/pageAddHeader";
//utils
import { getMessageError } from "../../Utils";
//queries&mutations
import {
  useCheckIfDriverAssignedToGroupLazyQuery,
  useCreatePackerGroupMutation,
  useGetDriversQuery,
  useGetUnAssignedPackersQuery,
} from "../../../graphql/types";
//types
import { PackingGroup } from "../Types";
import { ApolloError } from "@apollo/client";

const AddPackingGroup = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //styles
  const ContainersClasses = useStylesContainers();
  const RecipeClasses = useStylesRecipe();

  //react-hook-form
  const methods = useForm<PackingGroup>({
    defaultValues: {
      name: "",
      firstChecker: null,
      secondChecker: null,
      other: [],
      drivers: [],
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  //queries&mutations
  const dataDriver = useGetDriversQuery();
  const [isDriverAssignedToGroup, { data: isAssignedTo }] =
    useCheckIfDriverAssignedToGroupLazyQuery();
  const unassignedPackersList = useGetUnAssignedPackersQuery({
    fetchPolicy: "no-cache",
  });
  const [createGroup, { loading }] = useCreatePackerGroupMutation();

  const onSubmit: SubmitHandler<PackingGroup> = async (data) => {
    try {
      await createGroup({
        variables: {
          createPackerGroupInput: {
            name: data.name,
            firstPacker: data.firstChecker?._id,
            secondPacker: data.secondChecker?._id,
            otherPackers: data.other.map((el) => el._id) as any,
            drivers: data.drivers.map((el) => el._id) as any,
          },
        },
        onCompleted: () => {
          enqueueSnackbar("New Group added succuessfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/admin/packingGroups/list_packing_groups");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  useEffect(() => {
    if ((dataWatch?.drivers || []).length > 0) {
      isDriverAssignedToGroup({
        variables: {
          input: { driverIds: dataWatch.drivers?.map((el) => el._id) as any },
        },
      });
    }
  }, [dataWatch.drivers]);

  useEffect(() => {
    if ((isAssignedTo?.checkIfDriverAssignedToGroup || [])?.length > 0) {
      isAssignedTo?.checkIfDriverAssignedToGroup.map((el) => {
        enqueueSnackbar(el, {
          variant: "warning",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      });
    }
  }, [isAssignedTo?.checkIfDriverAssignedToGroup]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title={"Create Group"}
          buttonText={"Create Group"}
          cancelButtonLink="/admin/packingGroups/list_packing_groups"
          loading={loading}
        />
        <Box display="grid" gridTemplateColumns="2fr 1fr" columnGap={10}>
          <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={4}>
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>Group Name</p>
              <TextField
                id="demo-helper-text-misaligned"
                placeholder="Group Name"
                fullWidth
                {...register("name", {
                  required: "Group Name is Mandatory",
                })}
              />
              {errors?.name?.message && (
                <p className={RecipeClasses.recipe__error__helper}>
                  {errors?.name?.message}
                </p>
              )}
            </Box>

            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>
                First Checker
              </p>
              <Controller
                name="firstChecker"
                control={control}
                rules={{
                  required: "Select first checker",
                }}
                render={({
                  field: { onChange: Change, value, ref, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      ref={ref}
                      id="firstChecker"
                      options={
                        unassignedPackersList.data?.getUnAssignedPackers || []
                      }
                      value={dataWatch?.firstChecker}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      filterSelectedOptions
                      onChange={(_, data) => Change(data)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            color="secondary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="First Checker" />
                      )}
                    />
                    <p className={ContainersClasses.section__error__helper}>
                      {error?.message}
                    </p>
                  </>
                )}
              />
            </Box>

            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>
                Other Group members
              </p>
              <Controller
                name="other"
                control={control}
                render={({
                  field: { onChange: Change, value, ref, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      ref={ref}
                      id="other"
                      multiple
                      options={
                        unassignedPackersList.data?.getUnAssignedPackers || []
                      }
                      value={dataWatch?.other}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      filterSelectedOptions
                      onChange={(_, data) => Change(data)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            color="secondary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Other members" />
                      )}
                    />
                    <p className={ContainersClasses.section__error__helper}>
                      {error?.message}
                    </p>
                  </>
                )}
              />
            </Box>

            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>
                Second Checker
              </p>
              <Controller
                name="secondChecker"
                control={control}
                rules={{
                  required: "Select second checker",
                }}
                render={({
                  field: { onChange: Change, value, ref, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      ref={ref}
                      id="secondChecker"
                      options={
                        unassignedPackersList.data?.getUnAssignedPackers || []
                      }
                      value={dataWatch?.secondChecker}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      filterSelectedOptions
                      onChange={(_, data) => Change(data)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            color="secondary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Second Checker" />
                      )}
                    />
                    <p className={ContainersClasses.section__error__helper}>
                      {error?.message}
                    </p>
                  </>
                )}
              />
            </Box>

            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>
                Affected Drivers
              </p>
              <Controller
                name="drivers"
                control={control}
                render={({
                  field: { onChange: Change, value, ref, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      ref={ref}
                      id="drivers"
                      multiple
                      options={dataDriver?.data?.getDrivers || []}
                      value={dataWatch?.drivers}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      filterSelectedOptions
                      onChange={(_, data) => Change(data)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            color="secondary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Affected Drivers" />
                      )}
                    />
                    <p className={ContainersClasses.section__error__helper}>
                      {error?.message}
                    </p>
                  </>
                )}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddPackingGroup;
