import { CSSProperties, useEffect, useMemo, useState } from "react";
import {
  DndContext,
  closestCenter,
  DragEndEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete } from "@mui/icons-material";
//mutations&queries
import {
  GetAllProgramsDocument,
  ProgramPayload,
  SortBy,
  useDeleteProgramMutation,
  useEditProgramV2Mutation,
  useGetAllProgramsQuery,
  useUpdateProgramsOrderMutation,
} from "../../../graphql/types";
import { ApolloError } from "@apollo/client";
//styles
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import PageHeader from "../../pageHeader/PageHeader";
import Status from "../../Status/Status";
//utils
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { filterElements, getMessageError } from "../../Utils";

// Cell Component
const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    <button {...attributes} {...listeners}>
      🟰
    </button>
  );
};

// Row Component
const DraggableRow = ({ row }: { row: Row<ProgramPayload> }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original._id || "",
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };

  return (
    <tr ref={setNodeRef} style={style}>
      {row.getVisibleCells().map((cell) => (
        <td
          key={cell.id}
          style={{
            width: cell.column.getSize(),
            padding: "8px 16px",
            borderBottom: "1px solid #ddd",
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};

const ProgramsList = () => {
  let navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [sortBy, setSortBy] = useState<SortBy | null>(null);
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<ProgramPayload | null>(null);
  const [data, setData] = useState<ProgramPayload[]>([]);

  //mutations&queries
  const [editProgram] = useEditProgramV2Mutation();
  const [reorderProgram] = useUpdateProgramsOrderMutation();
  const [deleteProgram, { loading }] = useDeleteProgramMutation();
  const dataListPrograms = useGetAllProgramsQuery({
    variables: {
      input: {
        page: 1,
        documentsPerPage: 10,
        name: search,
        sort: sortBy,
      },
    },
    fetchPolicy: "network-only",
  });

  const listPrograms = useMemo(
    () => dataListPrograms.data?.getAllPrograms.data || [],
    [dataListPrograms.data]
  );

  const ToggleProgramActivity = async (programId: string, status: boolean) => {
    try {
      await editProgram({
        variables: {
          input: { id: programId, isActif: !status },
        },
        onCompleted: () => {
          enqueueSnackbar("Program successfully updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleClickMenu =
    (item: ProgramPayload) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (selected) {
      navigate(`/food/programs/update_program/${selected._id}`);
    }
  };

  const handleCloseDetails = () => {
    setAnchorEl(null);
    if (selected) {
      navigate(`/food/programs/view_program/${selected._id}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteProgram = async () => {
    setOpen(false);
    if (selected) {
      try {
        await deleteProgram({
          variables: { input: selected._id! },
          refetchQueries: [
            {
              query: GetAllProgramsDocument,
              variables: { input: { page: 1, documentsPerPage: 10 } },
            },
          ],
          onCompleted: () => {
            enqueueSnackbar("Program successfully deleted", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setSelected(null);
          },
        });
      } catch (error) {
        setSelected(null);
      }
    }
  };

  useEffect(() => {
    setData(listPrograms);
  }, [listPrograms]);

  const columns = useMemo<ColumnDef<ProgramPayload>[]>(
    () => [
      {
        id: "drag-handle",
        header: "Move",
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
        size: 60,
      },
      {
        accessorKey: "Name",
        header: "name",
        cell: ({ row }) => (
          <Button
            onClick={() =>
              navigate(`/food/programs/view_program/${row?.original._id}`)
            }
          >
            {row?.original.name?.EN}
          </Button>
        ),
      },
      {
        accessorKey: "Photo",
        accessorFn: (row) => row.photoUrl,
        id: "photo",
        cell: ({ row }) => (
          <Avatar
            alt={row?.original.photoUrl || ""}
            src={row?.original.photoUrl || ""}
            variant="rounded"
            style={{ width: 55, height: 55 }}
          />
        ),
      },
      {
        accessorKey: "Description",
        accessorFn: (row) => row.description?.EN,
        id: "description",
        cell: ({ row }) => (
          <Box fontFamily="Poppins" fontSize={14}>
            {row.original.description
              ? row.original?.description?.EN?.length || 0 > 35
                ? row?.original?.description?.EN?.slice(0, 35) + " ..."
                : row.original?.description?.EN
              : "---"}
          </Box>
        ),
      },
      {
        accessorKey: "isActif",
        header: "Status",
        cell: ({ row }) => (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row.original.isActif ? (
              <Status status="ACTIVE" statusText="Active" />
            ) : (
              <Status status="INACTIVE" statusText="Inactive" />
            )}
          </div>
        ),
      },
      {
        accessorKey: "statusToggle",
        header: "Status",
        cell: ({ row }) => (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Switch
              style={{ alignSelf: "center" }}
              defaultChecked={row.original?.isActif || false}
              onChange={() =>
                ToggleProgramActivity(
                  row.original?._id || "",
                  row.original?.isActif || false
                )
              }
            />
          </div>
        ),
      },
      {
        header: "Actions",
        cell: ({ row }) => (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={handleClickMenu(row.original)}>
              <MoreVertIcon />
            </IconButton>
          </div>
        ),
      },
    ],
    []
  );

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => data?.map(({ _id }) => _id || ""),
    [data]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row._id || "",
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setData((data) => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);
        const newData = arrayMove(data, oldIndex, newIndex);
        reorderProgramsHandler(newData);
        return newData;
      });
    }
  }

  const reorderProgramsHandler = async (updatedData: ProgramPayload[]) => {
    const programIdsOrder = updatedData
      .map((el) => el._id)
      .filter((id): id is string => !!id);
    try {
      await reorderProgram({
        variables: { input: { programsIds: programIdsOrder } },
        onCompleted: () => {
          enqueueSnackbar("Program list successfully reordered", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <div>
      {useIsAuthorized("FOOD_PROGRAMS_CREATE") ? (
        <PageHeader
          buttonLink={`/food/programs/add_program`}
          buttonText={"Create Program"}
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      ) : (
        <PageHeader
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      )}
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <div>
          <table
            style={{
              borderSpacing: "0px",
              fontFamily: "Poppins, sans-serif",
              width: "100%",
            }}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        borderTopLeftRadius: index === 0 ? "6px" : "0px",
                        borderTopRightRadius: index === 6 ? "6px" : "0px",
                        fontSize: "12px",
                        fontWeight: 500,
                        lineHeight: "1.5rem",
                        display: "table-cell",
                        verticalAlign: "inherit",
                        padding: "6px 16px",
                        color: "rgb(136, 136, 136)",
                        background: "rgb(244, 233, 219)",
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              <SortableContext
                items={dataIds}
                strategy={verticalListSortingStrategy}
              >
                {table.getRowModel().rows.map((row) => (
                  <DraggableRow key={row.id} row={row} />
                ))}
              </SortableContext>
            </tbody>
          </table>
        </div>
      </DndContext>

      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("FOOD_PROGRAMS_DETAILS") && (
            <MenuItem
              onClick={handleCloseDetails}
              className={classes.menu_container}
              disableRipple={true}
            >
              <div>
                <span className="menu_title">Details</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("FOOD_PROGRAMS_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <div>
                <span className="menu_title">Update</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("FOOD_PROGRAMS_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Program ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Program ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={() => handleDeleteProgram()}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
export default ProgramsList;
