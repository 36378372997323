import { Button, Menu, MenuItem } from "@mui/material";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStylesButtons } from "../../styles/Buttons__styles";
import { useStylesHeader } from "../../styles/Header__styles";
import {
  StyledInputBase,
  SearchIconWrapper,
  HeaderSearch,
} from "../../styles/Navigation__styles";
import { FiSearch, FiFilter } from "react-icons/fi";
interface menuElementsTypes {
  menuText: string;
  menuValue: string;
  selected: boolean;
}
type PageHeaderProps = {
  // main button text
  buttonText?: string;
  // main button link
  buttonLink?: string;
  // upload button text
  buttonUploadText?: string;
  // filter items list
  filterElements?: menuElementsTypes[];
  // setter state in the parent component to set the selected filter item
  setSelectedFilter?: any;
  // state in the parent component to save the selected filter item
  selectedFilter?: any;
  // setter state in the parent component to set the searched value
  setSearchValue?: any;
  // cancel button Link
  cancelButtonLink?: any;
  // condition on the main button to show a menu list when clicked
  multipleButtonActions?: any;
  //params to pass with the button link
  params?: any;
};

const PageHeader: React.FC<PageHeaderProps> = ({
  buttonText,
  buttonLink,
  children,
  buttonUploadText,
  filterElements,
  selectedFilter,
  setSelectedFilter,
  setSearchValue,
  cancelButtonLink,
  multipleButtonActions,
  params,
}) => {
  const classesHeader = useStylesHeader();
  const ButtonClasses = useStylesButtons();

  let navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElMainButton, setAnchorElMainButton] =
    useState<null | HTMLElement>(null);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuValue?: string) => {
    if (menuValue) {
      setSelectedFilter(menuValue);
    }
    setAnchorEl(null);
  };

  const handleClickMenuMainButton = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setAnchorElMainButton(event.currentTarget);
  };

  const handleCloseMainButton = () => {
    setAnchorElMainButton(null);
  };
  const debounced = useCallback(
    _.debounce(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchValue(event.target.value);
      },
      800
    ),
    []
  );

  return (
    <div className={classesHeader.root}>
      {setSelectedFilter && (
        <Button
          variant="outlined"
          startIcon={<FiFilter />}
          onClick={handleClickMenu}
          className={ButtonClasses.filter_button}
        >
          Sort
        </Button>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        className={classesHeader.filter_menu}
      >
        {filterElements?.map((menu) => (
          <MenuItem
            key={menu.menuValue}
            onClick={() => handleClose(menu.menuValue)}
            disableRipple={true}
            className={
              selectedFilter === menu.menuValue
                ? clsx(
                    classesHeader.filter_menu_item,
                    classesHeader.filter_menu_item_selected
                  )
                : classesHeader.filter_menu_item
            }
          >
            <div>
              <span className="menu_title">{menu.menuText}</span>
            </div>
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={anchorElMainButton}
        keepMounted
        open={Boolean(anchorElMainButton)}
        onClose={handleCloseMainButton}
        className={classesHeader.filter_menu}
      >
        <MenuItem
          onClick={() => navigate("/admin/leads/add_lead")}
          disableRipple={true}
          className={classesHeader.filter_menu_item}
        >
          <div>
            <span className="menu_title"> Lead </span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/admin/customers/add_customer")}
          disableRipple={true}
          className={classesHeader.filter_menu_item}
        >
          <div>
            <span className="menu_title"> Customer </span>
          </div>
        </MenuItem>
      </Menu>

      {setSearchValue && (
        <HeaderSearch>
          <SearchIconWrapper>
            <FiSearch />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search..."
            inputProps={{ "aria-label": "search" }}
            onChange={(event) => debounced(event)}
          />
        </HeaderSearch>
      )}

      {buttonUploadText && (
        <Button
          className={clsx(ButtonClasses.button_upload_csv)}
          startIcon={children}
        >
          {buttonUploadText}
        </Button>
      )}

      {cancelButtonLink && (
        <Button
          className={clsx(ButtonClasses.button_upload_csv)}
          startIcon={children}
        >
          Cancel
        </Button>
      )}

      {buttonLink && buttonText ? (
        <Button
          variant="contained"
          onClick={
            !multipleButtonActions
              ? () => {
                  params ? navigate(buttonLink, params) : navigate(buttonLink);
                }
              : handleClickMenuMainButton
          }
          className={clsx(ButtonClasses.button_open_list_page)}
          startIcon={children}
        >
          {buttonText}
        </Button>
      ) : (
        <div style={{ width: "190px" }}></div>
      )}
    </div>
  );
};

export default PageHeader;
