import { useCallback, useState } from "react";
import _ from "lodash";
import moment from "moment";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//styles
import {
  HeaderSearch,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../styles/Navigation__styles";
//icons
import { FiSearch } from "react-icons/fi";
//queries&mutations
import { useGetTimeTrackingDetailsQuery } from "../../../graphql/types";

type Props = {
  date?: Date;
};
const TimeTracking: React.FC<Props> = ({ date }) => {
  //state
  const [search, setSearch] = useState<string>("");

  const debounced = useCallback(
    _.debounce(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearch(event.target.value);
      },
      800
    ),
    []
  );

  const timeTrackingDetails = useGetTimeTrackingDetailsQuery({
    variables: { date: date?.toDateString() || "", query: search },
    fetchPolicy: "no-cache",
  });

  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom={2}
        borderColor="lightgrey"
        paddingBottom={3}
      >
        <Typography fontSize={20}>Time Tracking</Typography>
        <Typography>{moment(date).format("Do MMMM YYYY, dddd")}</Typography>
      </Box>
      <Box width="30%" marginTop={5} marginBottom={5}>
        <HeaderSearch sx={{ borderRadius: 50 }}>
          <SearchIconWrapper>
            <FiSearch />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search by Name"
            inputProps={{ "aria-label": "search" }}
            onChange={(event) => debounced(event)}
          />
        </HeaderSearch>
      </Box>
      {timeTrackingDetails.data?.getTimeTrackingDetails.map((el) => {
        return (
          <Box marginTop={2}>
            <Accordion style={{ marginTop: 10 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  color: "black",
                  background: "lightgrey",
                }}
              >
                <Typography fontSize={20}>{el.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {el.dishes.map((elem) => {
                  return (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginTop={2}
                    >
                      <Typography
                        color="black"
                        width="50%"
                      >{`${elem.dishName.EN} (${elem.code})`}</Typography>
                      <Typography color="black" width="30%">
                        Start Time-
                        {`${elem.startTime ? elem.startTime : "---"}`}
                      </Typography>
                      <Typography color="black" width="30%">
                        End Time-
                        {`${elem.endTime ? elem.endTime : "---"}`}
                      </Typography>
                      <Typography color="black" width="30%">
                        Duration-
                        {`${elem.duration ? elem.duration : "---"}`}
                      </Typography>
                    </Box>
                  );
                })}
                <Box
                  width="100%"
                  borderBottom={2}
                  borderColor="black"
                  marginTop={2}
                ></Box>
                <Box
                  color="black"
                  marginTop={2}
                  display="flex"
                  justifyContent={"end"}
                >
                  <Typography>{`Total time worked :${
                    el.totalTime ? el.totalTime : "----"
                  }`}</Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
    </div>
  );
};

export default TimeTracking;
