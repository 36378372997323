import React, { useMemo, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
//material ui
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TableContainer,
} from "@mui/material";
//components
import FilterHeader from "../../pageHeader/FilterHeader";
import Table from "../../layout/commun/Table";
import StatsCard from "../../reusable/Cards/StatsCard";
//queries&mutations
import {
  useGetConvertedLeadsLazyQuery,
  useGetConvertedLeadsQuery,
  useGetConvertedLeadsAmountQuery,
} from "../../../graphql/types";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
//utils
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { SIDE_FILTER_OPTIONS_CONVERTED_LEADS, columns } from "./Utils";
//context
import { useAuth } from "../../../contextAPI";

const ConvertedLeads = () => {
  const navigate = useNavigate();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");
  const isAuthorizedToSeeFilter = useIsAuthorized(
    "MANAGEMENT_ROLES_LIST_SALESANDSUPPORTS"
  );
  const isAuthorizedToSeeAmount = useIsAuthorized("ADMIN_CONVERTED_AMMOUNT");
  const auth = useAuth();
  //styles
  const ButtonClasses = useStylesButtons();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [idsSalesSupport, setIdsSalesSupport] = useState<string[]>([]);
  const [mine, setMine] = useState<boolean | null>(null);

  //queries&mutations
  const dataGetLead = useGetConvertedLeadsQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        from: from,
        to: to,
        assignedTo: idsSalesSupport,
        assignedToMe: mine,
        forExcel: false,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
    fetchPolicy: "network-only",
  });

  const { data: convertedLeadsAmountData } = useGetConvertedLeadsAmountQuery({
    variables: {
      input: {
        from: from!,
        to: to!,
        assignedTo: idsSalesSupport,
      },
    },
    skip: !from || !to,
  });

  const convertedLeadsAmount = useMemo(
    () => convertedLeadsAmountData?.getConvertedLeadsAmount,
    [convertedLeadsAmountData?.getConvertedLeadsAmount]
  );

  const convertedLeads = useMemo(
    () => dataGetLead.data?.getConvertedLeads.data || [],
    [dataGetLead.data]
  );

  const count = useMemo(
    () => dataGetLead.data?.getConvertedLeads.count || 0,
    [dataGetLead.data]
  );

  //excel export
  const [getConvertedLeadsData, { loading: convertedLeadsListLoading }] =
    useGetConvertedLeadsLazyQuery();

  const handleExportExcel = async () => {
    await getConvertedLeadsData({
      variables: {
        input: {
          page: 1,
          documentsPerPage: count,
          from: from,
          to: to,
          assignedTo: idsSalesSupport,
          assignedToMe: mine,
          forExcel: true,
        },
      },
    })
      .then((data) => {
        if (count != 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const fileExtension = ".xlsx";
          const filteredConvertedLeads =
            data?.data?.getConvertedLeads?.data?.map((el, index) => {
              return {
                id: (index + 1).toString(),
                Name: el.fname + " " + el.lname,

                Mobile: el?.phone?.prefix + " " + el?.phone?.number,
                ConvertedAt: moment(el.convertedAt).format("DD-MMM-YYYY"),
                CalledAt: el.calledAt
                  ? moment(el.calledAt).format("DD-MMM-YYYY")
                  : "------",
                "Amount (QR)": el.amount,
                ConvertedBy: el.convertedBy,
              };
            });
          const ws = XLSX.utils.json_to_sheet(filteredConvertedLeads || []);
          ws["!cols"] = [
            { wpx: 40 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 200 },
            { wpx: 120 },
            { wpx: 70 },
            { wpx: 90 },
            { wpx: 250 },
          ];
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const res = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            res,
            "Converted Leads report" +
              " generated on " +
              moment().format("DD/MM/YYYY dddd LT") +
              fileExtension
          );
          return "Done";
        }
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderTableRows = () => {
    return convertedLeads.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        id: item?.ref,
        name: (
          <Box display="flex" alignItems="center" justifyContent="center">
            {isAuthorizedToSeeProfile ? (
              <a
                style={{ textDecoration: "none" }}
                href={`/admin/customers/view_customer/${item?._id}`}
              >
                <Button>{`${item?.fname} ${item?.lname}`}</Button>
              </a>
            ) : (
              item.fname + " " + item.lname
            )}
          </Box>
        ),
        mobile: item?.phone?.prefix + " " + item?.phone?.number,
        convertedAt: moment(item.convertedAt).format("DD-MMM-YYYY"),
        calledAt: item.calledAt
          ? moment(item.calledAt).format("DD-MMM-YYYY")
          : "------",
        amount: item.amount + " " + auth.countryCurrency,
        convertedBy: item.convertedBy,
        actions: isAuthorizedToSeeProfile && (
          <Button
            variant="contained"
            size="medium"
            className={ButtonClasses.button_cancel_form}
            onClick={() =>
              navigate(`/admin/customers/view_customer/${item?._id}`)
            }
          >
            Profile
          </Button>
        ),
      };
    });
  };

  return (
    <>
      <Box>
        <FilterHeader
          count={count}
          showSearch={false}
          hideFilter={!isAuthorizedToSeeFilter}
          showSalesSupport={true}
          pageTitle="Converted Leads"
          pageInfo="This is the list of leads who were successfully converted"
          setIdsProgram={() => null}
          setFrom={setFrom}
          from={from}
          setTo={setTo}
          to={to}
          setIdsSalesSupport={setIdsSalesSupport}
          salesSupportId={idsSalesSupport}
          filterConfig={SIDE_FILTER_OPTIONS_CONVERTED_LEADS}
          hideProgram={true}
          loadExcel={convertedLeadsListLoading}
          generateExcel={handleExportExcel}
        />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom="30px"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={mine || false}
              onChange={(e) => setMine(e.target.checked)}
            />
          }
          label="Mine"
        />
        {isAuthorizedToSeeAmount && (
          <StatsCard
            title={`Total Amount (${auth.countryCurrency})`}
            startDate={new Date()}
            endDate={new Date()}
            number={convertedLeadsAmount ?? 0}
            total={true}
            styles={{
              height: 85,
              minWidth: 90,
              borderWidth: 1,
              borderRadius: 5,
              borderColor: "black",
            }}
          />
        )}
      </Box>
      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetLead.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No converted leads found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
    </>
  );
};

export default ConvertedLeads;
