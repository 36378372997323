import { useState } from "react";
//material ui
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
//components
import TrainersList from "./TrainersList";
import InfluencersList from "./Influencers/InfluencersList";
//utils
import { a11yProps, TabPanelProps } from "../../Utils";

const TrainerView = () => {
  //state
  const [value, setValue] = useState(0);

  //function
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Trainers" {...a11yProps(0)} />
            <Tab label="influencers" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <TrainersList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InfluencersList />
      </TabPanel>
    </>
  );
};
export default TrainerView;

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
