import React, { useMemo } from "react";
import QRCode from "react-qr-code";
//types
import { LabelReportProps } from "../Types";
import moment from "moment";
const DISH_DETAILS_PAGE_URL = "https://dish.dieture.com/";

const LabelReportPrint = React.forwardRef<HTMLDivElement, LabelReportProps>(
  ({ generateLabel, date }, ref) => {
    const mealData = useMemo(
      () => generateLabel?.getLabelReportV2,
      [generateLabel?.getLabelReportV2]
    );

    //render
    return (
      <div ref={ref}>
        {mealData?.dishsReport?.map((el, index) => (
          <div key={`${el._id}-${index}`}>
            <div
              style={{
                width: 302,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: 10,
                      lineBreak: "strict",
                      fontFamily: "Poppins",
                      width: 260,
                      maxHeight: 110,
                      minHeight: 110,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        marginRight: 10,
                      }}
                    >
                      {el?.code}-
                      {el.meal === "ADDON" ? "S" : el?.size?.charAt(0)}
                    </div>
                    <QRCode
                      level="L"
                      bgColor="white"
                      fgColor="black"
                      size={70}
                      value={
                        el.meal === "ADDON"
                          ? DISH_DETAILS_PAGE_URL + "S" + el?.dish?._id
                          : DISH_DETAILS_PAGE_URL +
                            el?.size?.charAt(0) +
                            "" +
                            el?.dish?._id
                      }
                    />
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      lineHeight: 1,
                      minHeight: 15,
                      maxHeight: 15,
                    }}
                  >
                    {el?.dish?.name?.EN}
                  </div>
                </div>
              </div>

              {/* TABLE FIRST ROW CAL PROT CARBS FAT */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 58,
                  // paddingLeft:5,
                  paddingRight: 5,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {Math.round(
                    el?.dish?.nutritionDetails?.find((el) => {
                      return el?.name === "Energy";
                    })?.quantity || 0
                  )}
                  kcal
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "Poppins",
                    marginRight: 8,
                    fontWeight: "bold",
                  }}
                >
                  {Math.round(
                    el?.dish?.nutritionDetails?.find((el) => {
                      return el?.name === "Protein";
                    })?.quantity || 0
                  )}
                  g
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "Poppins",
                    marginRight: 8,
                    fontWeight: "bold",
                  }}
                >
                  {Math.round(
                    el?.dish?.nutritionDetails?.find((el) => {
                      return el?.name === "Carbohydrate";
                    })?.quantity || 0
                  )}
                  g
                </div>
                <div
                  style={{
                    fontSize: 14,
                    marginRight: 8,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {Math.round(
                    el?.dish?.nutritionDetails?.find((el) => {
                      return el?.name === "Total Fat";
                    })?.quantity || 0
                  )}
                  g
                </div>
              </div>

              {/* TABLE SECOND ROW MICRO MEALS PDATE EDATE */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 30,
                  // paddingLeft:5,
                  paddingRight: 5,
                }}
              >
                <div
                  style={{
                    fontSize: 10,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {(() => {
                    const [time, power] = el?.dish?.reheatingInstruction?.split(
                      "/"
                    ) || ["", ""];
                    if ((!time && !power) || time === "0") {
                      return "N/A";
                    }
                    return `${time} sec/${power ? power : "N/A"}`;
                  })()}{" "}
                </div>
                <div
                  style={{
                    fontSize: 10,
                    fontFamily: "Poppins",
                    marginRight: 8,
                    fontWeight: "bold",
                  }}
                >
                  {el?.meal === "EVENING_SNACK" ||
                  el?.meal === "MORNING_SNACK" ||
                  el?.meal === "ADD_SNACK"
                    ? "SNACK"
                    : el.meal === "LUNCH" || el.meal === "DINNER"
                    ? "LUNCH/DINNER"
                    : el?.meal}
                </div>
                <div
                  style={{
                    fontSize: 10,
                    fontFamily: "Poppins",
                    marginRight: 8,
                    fontWeight: "bold",
                  }}
                >
                  {moment(date).format("YYYY-MM-DD")}
                </div>
                <div
                  style={{
                    fontSize: 10,
                    marginRight: 8,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {moment(date).add(1, "day").format("YYYY-MM-DD")}
                </div>
              </div>

              {/* INGREDIENTS LIST */}
              <div
                style={{
                  fontSize: 10,
                  marginTop: 30,
                  lineBreak: "strict",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  width: 260,
                  lineHeight: 1.3,
                }}
              >
                {el?.dish?.ingredients?.map((elem) => {
                  return elem?.name?.EN + " ,";
                })}
              </div>
            </div>
            {/* LINE BREAK */}
            <div style={{ pageBreakAfter: "always" }}></div>
          </div>
        ))}
      </div>
    );
  }
);

export default LabelReportPrint;
