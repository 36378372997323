import {Outlet} from "react-router-dom"
const Ingredients = () => {
    return (
        <div>
            <Outlet/>
        </div>
    )
}

export default Ingredients
