import { ColumnsProps } from "../layout/commun/Table";
import { DishTagsProps, Column, RColumn } from "./Types";
export const filterDishTags = (
  currentTags: Array<DishTagsProps>,
  data: Array<DishTagsProps>
) => {
  return data.filter(
    (item) =>
      item.value !== currentTags?.find((row) => row.value === item.value)?.value
  );
};
export const rColumns: RColumn[] = [
  { id: "prep", label: "Prep" },
  { id: "weight", label: "Weight(gr)" },
];
export const columns: Column[] = [
  { id: "nutrition", label: "Nutrition Information" },
  { id: "quantity", label: "Quantity(gr)" },
];
export const tagsOptions = [
  {
    label: "Spicy",
    value: "spicy",
  },
  {
    label: "Salty",
    value: "salty",
  },
  {
    label: "Qatari",
    value: "qatari",
  },
  {
    label: "Arabic",
    value: "arabic",
  },
  {
    label: "Mediterranean",
    value: "mediterranean",
  },
  {
    label: "Asian",
    value: "asian",
  },
  {
    label: "Indian",
    value: "indian",
  },
];
export const medicalConditionsOptions = [
  {
    menuValue: "classic",
    menuText: "Classic",
  },
  {
    menuValue: "keto",
    menuText: "Keto",
  },
  {
    menuValue: "gluten_free",
    menuText: "Gluten free",
  },
  {
    menuValue: "dairy_free",
    menuText: "Dairy Free",
  },
  {
    menuValue: "diabetic",
    menuText: "Diabetic",
  },
  {
    menuValue: "hypertension",
    menuText: "Hypertension",
  },
  {
    menuValue: "pregnant",
    menuText: "Pregnant",
  },
  {
    menuValue: "maternity",
    menuText: "Maternity",
  },
  {
    menuValue: "vegetarian",
    menuText: "Vegetarian",
  },
];

export const ComplainsColumn: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Customer",
    accessor: "customer",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Image",
    accessor: "image",
  },
];

export interface DialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  onClose: () => void;
}
export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
