import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
import CountUp from "react-countup";
//material ui
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import FileCopy from "@mui/icons-material/FileCopy";
//style
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import Table from "../../layout/commun/Table";
import FilterHeader from "../../pageHeader/FilterHeader";
import SplitButton from "../../reusable/SplitButton/SplitButton";
//mutations&querries
import {
  GetUnattendedLeadsDocument,
  LeadStatus,
  useEditLeadMutation,
  useGetLeadsCountByStatusQuery,
  useGetUnattendedLeadsLazyQuery,
  useGetUnattendedLeadsQuery,
} from "../../../graphql/types";
//utils
import { columns, SIDE_FILTER_OPTIONS_UNATTENDED_LEADS } from "./Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { getMessageError } from "../../Utils";

function UnattendedLeads() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");
  //styles
  const ButtonClasses = useStylesButtons();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [status, setStatus] = useState<LeadStatus[]>([]);
  const [linkCopied, setLinkCopied] = useState(false);

  //queries&mutations
  const dataGetLead = useGetUnattendedLeadsQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        from: from,
        to: to,
        status: status,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
    fetchPolicy: "network-only",
  });
  const leadsCountByStatus = useGetLeadsCountByStatusQuery({
    variables: { input: { from: from, to: to } },
  });
  const [updateLead] = useEditLeadMutation();

  const leads = useMemo(
    () => dataGetLead.data?.getUnattendedLeads.data || [],
    [dataGetLead.data]
  );

  const count = useMemo(
    () => dataGetLead.data?.getUnattendedLeads.count || 0,
    [dataGetLead.data]
  );

  const [getUnattendedLeadsData, { loading: unattendedLeadsListLoading }] =
    useGetUnattendedLeadsLazyQuery();

  const handleExportExcel = async () => {
    await getUnattendedLeadsData({
      variables: {
        input: {
          page: 1,
          documentsPerPage: count,
          from: from,
          to: to,
          status: status,
        },
      },
    })
      .then((data) => {
        if (count != 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const fileExtension = ".xlsx";
          const filteredCustomers = data?.data?.getUnattendedLeads?.data?.map(
            (el, index) => {
              return {
                id: (index + 1).toString(),
                name: el.fname + " " + el.lname,
                phone: el.phone?.prefix + " " + el.phone?.number,
                date: moment(el?.signedUpDate).format("DD-MMM-YYYY"),
                status: el?.leadStatus,
              };
            }
          );
          const ws = XLSX.utils.json_to_sheet(filteredCustomers || []);
          ws["!cols"] = [
            { wpx: 40 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 150 },
          ];
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const res = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            res,
            "unattended leads report" +
              " generated on " +
              moment().format("DD/MM/YYYY dddd LT") +
              fileExtension
          );
          return "Done";
        }
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const updateStatusHandler = async (status: LeadStatus, id: string) => {
    await updateLead({
      variables: {
        editLeadInput: {
          id: id,
          leadStatus: status,
        },
      },
      refetchQueries: [GetUnattendedLeadsDocument],
      onCompleted() {
        enqueueSnackbar("Lead status changed successfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const handleCopyClick = (name: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      setLinkCopied(true);
      window.focus();
      navigator.clipboard.writeText(name);
      setTimeout(() => {
        setLinkCopied(false);
      }, 1000);
    }
  };

  const renderTableRows = () => {
    return leads.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        id: item?.ref,
        name: (
          <Box display="flex" alignItems="center" justifyContent="center">
            {isAuthorizedToSeeProfile ? (
              <a
                style={{ textDecoration: "none" }}
                href={`/admin/leads/view_lead/${item?._id}`}
              >
                <Button>{`${item?.fname} ${item?.lname}`}</Button>
              </a>
            ) : (
              item.fname + " " + item.lname
            )}
            <Tooltip
              title={linkCopied ? "copied" : "Click here to copy the name"}
            >
              <IconButton
                onClick={() => handleCopyClick(item?.fname + " " + item?.lname)}
                aria-label="Copy"
              >
                <FileCopy style={{ color: "#000", width: "15px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
        mobile: item?.phone?.prefix + " " + item?.phone?.number,
        otp: item?.otp,
        calories: item?.calories,
        date: moment(item?.signedUpDate).format("DD-MMM-YYYY"),
        status: (
          <SplitButton
            defaultValue={item?.leadStatus || "INFORMATION_REQUIRED"}
            onStatuschange={updateStatusHandler}
            id={item._id}
          />
        ),
        actions: isAuthorizedToSeeProfile && (
          <Button
            variant="contained"
            size="medium"
            className={ButtonClasses.button_cancel_form}
            onClick={() => navigate(`/admin/leads/view_lead/${item?._id}`)}
          >
            Profile
          </Button>
        ),
      };
    });
  };

  return (
    <>
      <Box marginBottom={5}>
        <FilterHeader
          count={count}
          showSearch={false}
          pageTitle="Unattended Leads"
          pageInfo="This is the list of leads of installed the app and put their names and mobile numbers"
          setIdsProgram={() => null}
          setStatus={setStatus}
          statusdata={status}
          setFrom={setFrom}
          from={from}
          setTo={setTo}
          to={to}
          filterConfig={SIDE_FILTER_OPTIONS_UNATTENDED_LEADS}
          hideProgram={true}
          loadExcel={unattendedLeadsListLoading}
          generateExcel={handleExportExcel}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          marginBottom={5}
        >
          {leadsCountByStatus.data?.getLeadsCountByStatus.map((el, index) => {
            return (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                fontFamily="Poppins"
                fontWeight="400"
                fontSize={16}
                height="80px"
                width="150px"
                marginRight={2}
                borderRadius={2.5}
                boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
                textAlign="center"
              >
                {el.leadStatus === "INFORMATION_REQUIRED"
                  ? "INFO_REQUIRED"
                  : el.leadStatus}
                <Typography variant="h2" fontSize={22} fontWeight="600">
                  <CountUp end={el.count || 0} duration={0.7} />
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetLead.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No unattended leads found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
    </>
  );
}

export default UnattendedLeads;
