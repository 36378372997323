function UnfrozenCustomers() {
  return (
    <svg
      width="38"
      height="36"
      viewBox="0 0 63 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_394_60857)">
        <path
          d="M13.5256 16.8266C9.84167 16.8266 6.84375 13.8265 6.84375 10.1399C6.84375 6.45324 9.84167 3.45312 13.5256 3.45312C17.2095 3.45312 20.2083 6.45324 20.2083 10.1399C20.2083 13.8265 17.2104 16.8266 13.5256 16.8266ZM13.5256 5.36375C10.8938 5.36375 8.75298 7.50618 8.75298 10.1399C8.75298 12.7735 10.8938 14.916 13.5256 14.916C16.1574 14.916 18.2991 12.7735 18.2991 10.1399C18.2991 7.50618 16.1574 5.36375 13.5256 5.36375Z"
          fill="#fff"
        />
        <path
          d="M18.1208 60.9999C15.9577 60.9999 14.1972 59.2114 14.1972 57.0119V43.4806C14.1972 43.1088 13.8952 42.8066 13.5237 42.8066C13.1522 42.8066 12.8511 43.1088 12.8502 43.4806V57.0119C12.8502 59.2105 11.0906 60.9999 8.92659 60.9999C6.76256 60.9999 5.25781 59.2854 5.25781 57.0119V40.427C5.25781 40.4207 5.25781 40.4154 5.25781 40.4092V26.3001C5.25781 25.7723 5.68545 25.3453 6.21198 25.3453C6.73851 25.3453 7.16615 25.7732 7.16615 26.3001V40.3949C7.16615 40.4011 7.16615 40.4074 7.16615 40.4127V57.0128C7.16615 58.0131 7.71673 59.0901 8.9257 59.0901C10.0367 59.0901 10.9401 58.1584 10.9401 57.0128V43.4815C10.9401 42.0568 12.0982 40.8977 13.5219 40.8977C14.9456 40.8977 16.1038 42.0568 16.1038 43.4815V57.0128C16.1038 58.1584 17.0072 59.0901 18.1181 59.0901C19.3271 59.0901 19.8777 58.0131 19.8777 57.0128V40.4127C19.8777 40.4074 19.8777 40.4011 19.8777 40.3949V29.5561C19.8777 27.5002 20.6795 25.5655 22.1353 24.1087L28.1525 18.0861C28.5249 17.7135 29.1298 17.7135 29.5022 18.0861C29.8746 18.4588 29.8746 19.0642 29.5022 19.4369L23.485 25.4594C22.3901 26.5551 21.7869 28.0102 21.7869 29.5552V40.4083C21.7869 40.4145 21.7869 40.4199 21.7869 40.4261V57.011C21.7869 59.2845 20.21 60.999 18.1181 60.999L18.1208 60.9999Z"
          fill="#fff"
        />
        <path
          d="M3.46743 42.0994C1.4905 42.0994 0 40.4821 0 38.337V25.4245C0 21.6719 3.05049 18.6192 6.80034 18.6192H18.898C19.7293 18.6192 20.5097 18.2955 21.0968 17.708L23.9958 14.8068C24.3682 14.4342 24.9732 14.4342 25.3456 14.8068C25.718 15.1795 25.718 15.7849 25.3456 16.1576L22.4465 19.0587C21.4986 20.0073 20.238 20.5298 18.8972 20.5298H6.80034C4.10354 20.5298 1.90923 22.7257 1.90923 25.4245V38.337C1.90923 39.2286 2.39655 40.1888 3.46743 40.1888C4.45635 40.1888 5.26084 39.3579 5.26084 38.337C5.26084 37.8092 5.68848 37.3822 6.21501 37.3822C6.74154 37.3822 7.16917 37.8101 7.16917 38.337C7.16917 40.4117 5.50851 42.0994 3.46654 42.0994H3.46743Z"
          fill="#fff"
        />
        <path
          d="M49.4796 16.8266C45.7948 16.8266 42.7969 13.8265 42.7969 10.1399C42.7969 6.45324 45.7948 3.45312 49.4796 3.45312C53.1644 3.45312 56.1615 6.45324 56.1615 10.1399C56.1615 13.8265 53.1644 16.8266 49.4796 16.8266ZM49.4796 5.36375C46.8479 5.36375 44.7061 7.50618 44.7061 10.1399C44.7061 12.7735 46.8479 14.916 49.4796 14.916C52.1114 14.916 54.2522 12.7735 54.2522 10.1399C54.2522 7.50618 52.1114 5.36375 49.4796 5.36375Z"
          fill="#fff"
        />
        <path
          d="M54.0764 61.0009C51.9132 61.0009 50.1528 59.2124 50.1528 57.013V43.4817C50.1528 43.1099 49.8508 42.8085 49.4792 42.8085C49.1077 42.8085 48.8057 43.1108 48.8057 43.4817V57.013C48.8057 59.2115 47.0453 61.0009 44.8821 61.0009C42.719 61.0009 41.2134 59.2864 41.2134 57.013V40.428C41.2134 40.4218 41.2134 40.4165 41.2134 40.4102V29.5572C41.2134 28.0121 40.6102 26.5571 39.5153 25.4613L33.4981 19.4388C33.1256 19.0661 33.1256 18.4608 33.4981 18.0881C33.8705 17.7154 34.4754 17.7154 34.8478 18.0881L40.865 24.1106C42.3208 25.5674 43.1226 27.5021 43.1226 29.5581V40.3968C43.1226 40.4031 43.1226 40.4084 43.1226 40.4147V57.0147C43.1226 58.0151 43.6732 59.0921 44.8821 59.0921C45.9931 59.0921 46.8965 58.1604 46.8965 57.0147V43.4834C46.8965 42.0587 48.0547 40.8997 49.4792 40.8997C50.9038 40.8997 52.0611 42.0587 52.0611 43.4834V57.0147C52.0611 58.1604 52.9645 59.0921 54.0755 59.0921C55.2844 59.0921 55.835 58.0151 55.835 57.0147V40.4147C55.835 40.4084 55.835 40.4031 55.835 40.3968V26.3021C55.835 25.7743 56.2627 25.3472 56.7892 25.3472C57.3157 25.3472 57.7434 25.7752 57.7434 26.3021V40.4111C57.7434 40.4174 57.7434 40.4227 57.7434 40.4289V57.0138C57.7434 59.2873 56.1664 61.0018 54.0746 61.0018L54.0764 61.0009Z"
          fill="#fff"
        />
        <path
          d="M59.5351 42.0994C57.4931 42.0994 55.8325 40.4117 55.8325 38.337C55.8325 37.8092 56.2601 37.3822 56.7867 37.3822C57.3132 37.3822 57.7408 37.8101 57.7408 38.337C57.7408 39.3579 58.5453 40.1888 59.5342 40.1888C60.6051 40.1888 61.0924 39.2286 61.0924 38.337V25.4245C61.0924 22.7257 58.8981 20.5298 56.2013 20.5298H44.1027C42.7619 20.5298 41.5013 20.0073 40.5533 19.0587L37.6543 16.1576C37.2819 15.7849 37.2819 15.1795 37.6543 14.8068C38.0267 14.4342 38.6316 14.4342 39.004 14.8068L41.9031 17.708C42.4902 18.2955 43.2715 18.6201 44.1018 18.6201H56.2004C59.9503 18.6201 63.0008 21.6728 63.0008 25.4254V38.3379C63.0008 40.483 61.5103 42.1003 59.5333 42.1003L59.5351 42.0994Z"
          fill="#fff"
        />
        <path
          d="M31.5034 17.225C31.3315 17.225 31.1595 17.1787 31.0072 17.086L30.7934 16.9558C30.5546 16.8114 28.3977 15.482 26.2542 13.4814C23.3177 10.7407 21.8281 8.08917 21.8281 5.6017C21.8281 2.51243 24.3396 0 27.4257 0C28.9902 0 30.4584 0.652626 31.5025 1.76441C32.5467 0.653517 34.0158 0 35.5794 0C38.6664 0 41.177 2.51332 41.177 5.6017C41.177 8.08917 39.6883 10.7398 36.7509 13.4814C34.6074 15.482 32.4505 16.8105 32.2117 16.9558L31.997 17.086C31.8447 17.1787 31.6727 17.225 31.5017 17.225H31.5034ZM27.4266 1.91062C25.3927 1.91062 23.7382 3.56626 23.7382 5.6017C23.7382 9.87141 30.0833 14.2231 31.5043 15.1441C32.9271 14.2222 39.2704 9.87052 39.2704 5.6017C39.2704 3.56626 37.616 1.91062 35.582 1.91062C34.2323 1.91062 32.9913 2.64884 32.3427 3.83641C32.1752 4.1431 31.8545 4.3339 31.5052 4.3339C31.156 4.3339 30.8353 4.1431 30.6678 3.83641C30.0192 2.64795 28.7781 1.91062 27.4284 1.91062H27.4266Z"
          fill="#fff"
        />
        <path
          d="M37.3672 6.55848C36.8398 6.55848 36.4131 6.13053 36.4131 5.60361C36.4131 5.14356 36.0389 4.76911 35.5792 4.76911C35.0517 4.76911 34.625 4.34116 34.625 3.81424C34.625 3.28733 35.0526 2.85938 35.5792 2.85938C37.091 2.85938 38.3214 4.09063 38.3214 5.60361C38.3214 6.13142 37.8938 6.55848 37.3672 6.55848Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_394_60857">
          <rect width="63" height="61" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UnfrozenCustomers;
