import { useMemo, useState } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
//material UI
import { Delete } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
//components
import Table from "../../layout/commun/Table";
import CustomSplitButton from "../../reusable/SplitButton/CustomSpiltButton";
//mutations&queries
import {
  ComplainsStatus,
  useDeleteComplainMutation,
  useGetComplainByIdQuery,
  useGetComplainsListQuery,
  useUpdateComplainStatusMutation,
} from "../../../graphql/types";
//utils
import { columns } from "./Utils";
import { ComplainStatus } from "../../complains/Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { getMessageError } from "../../Utils";
//types
import { ApolloError } from "@apollo/client";

const CustomerComplains = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //params
  const { id } = useParams();

  //styles
  const ButtonClasses = useStylesButtons();
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [idComplain, setIdComplain] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false);

  //mutations&queries
  const [updateStatus] = useUpdateComplainStatusMutation();
  const [deleteComplain] = useDeleteComplainMutation();
  const complainsData = useGetComplainsListQuery({
    variables: {
      getComplainsInput: {
        page: page,
        documentPerPage: rowsPerPage,
        customer: id,
      },
    },
    fetchPolicy: "no-cache",
  });

  const complainData = useGetComplainByIdQuery({
    variables: {
      id: idComplain || "",
    },
    fetchPolicy: "no-cache",
    skip: !openDetailsModal,
  });

  const ComplainsList = useMemo(
    () => complainsData.data?.getComplainsList.data || [],
    [complainsData.data]
  );

  const count = useMemo(
    () => complainsData.data?.getComplainsList.count || 0,
    [complainsData.data]
  );

  const handleSubmitChangeStatus = async (status: string, id: string) => {
    try {
      await updateStatus({
        variables: {
          updateComplainStatusInput: {
            _id: id,
            status: status as ComplainsStatus,
          },
        },
        onCompleted: () => {
          complainsData.refetch();
          setAnchorEl(null);
          enqueueSnackbar("Complain Status succuessfully Updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 10000);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const DeleteComplainHandler = async () => {
    setOpen(false);
    await deleteComplain({
      variables: { id: idComplain || "" },
      onCompleted() {
        complainsData.refetch();
        enqueueSnackbar("Complain deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClickMenu =
    (item: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setIdComplain(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCreateComplain = () => {
    navigate(`/admin/complains/add_complain`, {
      state: { CustomerId: id, dishId: null },
    });
  };

  const openDetailsModalHandler = (id: string) => {
    setOpenDetailsModal(true);
    setIdComplain(id);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
  };

  const renderTableRows = () => {
    return (
      ComplainsList?.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          title: (
            <Button
              onClick={() => openDetailsModalHandler(el._id || "")}
              sx={{
                width: "100%",
                textAlign: "left",
                justifyContent: "flex-start",
              }}
            >
              <Box textAlign="left" fontSize={14}>
                {el.title}
              </Box>
            </Button>
          ),
          customer: el.customer?.fname
            ? el.customer?.fname + " " + el.customer?.lname
            : "---",
          date: moment(el?.date).format("DD/MM/YYYY"),
          type: el.type?.name,
          image: el.photo ? (
            <a href={el.photo} target="_blank" download>
              <Button
                variant="contained"
                size="medium"
                className={ButtonClasses.button_cancel_form}
              >
                Download
              </Button>
            </a>
          ) : (
            "---"
          ),
          status: (
            <CustomSplitButton
              defaultValue={el.status || "PENDING"}
              onStatuschange={handleSubmitChangeStatus}
              id={el._id || ""}
              optionsList={ComplainStatus}
            />
          ),
          actions: (
            <IconButton onClick={handleClickMenu(el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <Box marginTop={5}>
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <Button variant="contained" onClick={handleCreateComplain}>
          Create new Complaint
        </Button>
      </Box>
      <TableContainer>
        <Table
          columns={columns}
          loading={complainsData.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Complaints for this customer !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("MANAGEMENT_COMPLAIN_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>

        {/* DELETE COMPLAINT */}
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Complain ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Complain ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={DeleteComplainHandler}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* COMPLAIN DETAILS MODAL */}
        <Dialog
          open={openDetailsModal}
          onClose={handleCloseDetailsModal}
          className={DialogClasses.dialog_renew_container}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            paddingLeft={"10%"}
            paddingRight={"10%"}
          >
            <Box marginTop={10}>
              <Box>
                Title :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.title}
                </Typography>
              </Box>
              <Box>
                Description :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.description}
                </Typography>
              </Box>
              <Box>
                Type :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.type?.name}
                </Typography>
              </Box>
              <Box>
                Date :{" "}
                <Typography color="black">
                  {moment(complainData.data?.getComplainById.date).format(
                    "DD/MM/YYYY"
                  )}
                </Typography>
              </Box>
              <Box>
                Customer :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.customer
                    ? complainData.data?.getComplainById.customer?.fname +
                      " " +
                      complainData.data?.getComplainById.customer?.lname
                    : "---"}
                </Typography>
              </Box>
              <Box>
                Dish :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.dish?.name
                    ? complainData.data?.getComplainById.dish?.name.EN
                    : "---"}
                </Typography>
              </Box>
              <Box>
                Driver :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.driver?.name
                    ? complainData.data?.getComplainById.driver?.name
                    : "---"}
                </Typography>
              </Box>
              <Box>
                Reported By :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.createdBy?.fname +
                    " " +
                    complainData.data?.getComplainById.createdBy?.lname}
                </Typography>
              </Box>
              <Box>
                Status :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.status}
                </Typography>
              </Box>
            </Box>
            <Avatar
              alt={complainData.data?.getComplainById.photo || ""}
              src={complainData.data?.getComplainById.photo || ""}
              variant="rounded"
              style={{ width: 400, height: 400, marginTop: "8%" }}
            />
          </Box>
        </Dialog>
      </div>
    </Box>
  );
};

export default CustomerComplains;
