import { NotificationType } from "../../../graphql/types";
import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Name (EN)",
    accessor: "nameEN",
  },
  {
    header: "Name (AR)",
    accessor: "nameAR",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

export const notificationTypesList: NotificationType[] = [
  "IMPORTANT",
  "NEW_CONTENT",
  "PROMOTION",
  "PURCHASE_UPDATE",
  "USER_ENGAGEMENT",
  "CUSTOMER_SUPPORT",
  "APP_UPDATE",
  "LOCATION_BASED",
  "HEALTH_TRACKING",
  "OTHER",
];
