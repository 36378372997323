import { useParams } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useState } from "react";
//material ui
import {
  Box,
  Button,
  CircularProgress,
  TableContainer,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//utils
import { RewardsType, columnsRewards, WalletTableColumns } from "./utils";
import Table from "../../layout/commun/Table";
import { getMessageError, DialogTitleProps } from "../../Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//styles
import { FullContainer } from "../../../styles/Customers__styles";
import { useStylesHeader } from "../../../styles/Header__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
//queries&mutations
import {
  GetRewardsHistoryByCustomerDocument,
  useGetRewardsHistoryByCustomerQuery,
  useGetRewardsListByCustomerDashQuery,
  useMakeRewardsRedemptionMutation,
} from "../../../graphql/types";
//context
import { useAuth } from "../../../contextAPI";

const CustomerRewards = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classesHeader = useStylesHeader();
  const params = useParams();
  const auth = useAuth();
  const isAuthorized = useIsAuthorized("MANAGEMENT_REWARDREDEMPTION_CREATE");
  const DialogClasses = useStylesDialog();

  //state
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [open, setOpen] = useState(false);
  const [selectedRewardId, setSelectedRewardId] = useState<string | null>(null);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const { data: rewardsData } = useGetRewardsHistoryByCustomerQuery({
    variables: {
      input: {
        customerID: params.id || "",
      },
    },
  });

  const { data: CustomerRewardsData, refetch: refetchRewards } =
    useGetRewardsListByCustomerDashQuery({
      variables: {
        input: params.id || "",
      },
    });

  const count = rewardsData?.getRewardsHistoryByCustomer?.length || 0;

  const handleOpen = (rewardId: string) => {
    setSelectedRewardId(rewardId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRewardId(null);
  };

  const CustomerRewards = CustomerRewardsData?.getRewardsListByCustomerDash;

  const Rewards = rewardsData?.getRewardsHistoryByCustomer;

  const [makeRedeem] = useMakeRewardsRedemptionMutation();

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              height: "fit-content",
            }}
          >
            <CloseIcon
              style={{
                minHeight: "22px",
                height: "22px",
                width: "22px",
                marginBottom: "0px",
              }}
            />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const makeRewardsRedemptionHandler = async (rewardId: string) => {
    setLoadingStates((prev) => ({ ...prev, [rewardId]: true }));
    await makeRedeem({
      variables: {
        input: {
          customerID: params.id,
          rewardID: rewardId,
        },
      },
      refetchQueries: [GetRewardsHistoryByCustomerDocument],
      onCompleted() {
        enqueueSnackbar("redemption reward successfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
        refetchRewards();
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    }).finally(() => {
      setLoadingStates((prev) => ({ ...prev, [rewardId]: false }));
    });
  };

  const renderTableRows = () => {
    return (
      Rewards?.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          date: moment(el.date).format("DD/MM/YYYY"),
          points: el.points,
          remaining_balance: el.remainingBalance,
          status: el.status,
          expired_date: moment(el.expiredDate).format("DD/MM/YYYY"),
          RewardsActionType: el.RewardsActionType
            ? RewardsType(el.RewardsActionType)
            : "",
          wallet: (
            <Button variant="outlined" onClick={() => handleOpen(el._id ?? "")}>
              View Wallet
            </Button>
          ),
        };
      }) || []
    );
  };

  const renderWalletDetailsTableRows = () => {
    return (
      Rewards?.find(
        (reward) => reward?._id === selectedRewardId
      )?.rewardsRedemption?.map((redeemedReward, index) => ({
        index: index + 1,
        pointsUsed: redeemedReward.pointsUsed,
        remainingAmount: redeemedReward.remainingAmount,
        walletBalance: redeemedReward.walletBalance?.balanceAfter,
        walletStatus: redeemedReward.walletStatus,
      })) || []
    );
  };

  return (
    <>
      <FullContainer>
        <span className={classesHeader.title}>
          Total Points&nbsp; : &nbsp;{CustomerRewards?.points}
        </span>
        <TableContainer>
          <Table
            columns={columnsRewards}
            data={renderTableRows()}
            loading={false}
            numberOfFakeRow={30}
            emptyMessage="No Rewards found !"
            tablePaginationProps={{
              rowsPerPageOptions: [30, 60, 90],
              count: count,
              page: page - 1,
              onPageChange,
              rowsPerPage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            paginationProps={{
              page: page,
              count: Math.ceil(count / rowsPerPage),
              showFirstButton: true,
              showLastButton: true,
              boundaryCount: 1,
              siblingCount: 2,
              onChange: onPageChange,
            }}
          />
        </TableContainer>

        <Box style={{ flexWrap: "wrap" }}>
          {CustomerRewards?.rewards?.map((reward) => (
            <Box
              key={reward._id}
              style={{
                border: "1px solid #ccc",
                borderRadius: "10px",
                padding: "10px",
                margin: "10px",
                display: "flex",
                width: "40%",
                justifyContent: "space-between",
              }}
            >
              <Box>Points&nbsp; : &nbsp;{reward.pointsRequired}</Box>
              <Box>
                {parseFloat(
                  reward.value ? reward.value.toString() : "0"
                ).toFixed(2)}
                &nbsp;
                {auth.countryCurrency}
              </Box>
              {isAuthorized && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => makeRewardsRedemptionHandler(reward._id || "")}
                  disabled={
                    loadingStates[reward._id || ""] || !reward.claimable
                  }
                >
                  {loadingStates[reward._id || ""] && (
                    <CircularProgress
                      size={20}
                      style={{ marginRight: 10, color: "white" }}
                    />
                  )}
                  Claim
                </Button>
              )}
            </Box>
          ))}
        </Box>
      </FullContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        className={DialogClasses.dialog_renew_container}
      >
        <BootstrapDialogTitle
          className={DialogClasses.alert_dialog_title}
          onClose={handleClose}
        >
          <span
            className="confirm_dialog_title_text"
            style={{ alignSelf: "flex-start" }}
          >
            Wallet Details
          </span>
        </BootstrapDialogTitle>
        <DialogContent>
          <TableContainer>
            <Table
              columns={WalletTableColumns}
              data={renderWalletDetailsTableRows()}
              numberOfFakeRow={30}
            />
          </TableContainer>
        </DialogContent>
      </Dialog>
      ;
    </>
  );
};
export default CustomerRewards;
