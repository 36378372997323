import { memo, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
//material ui
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { Box, Button, TextField, Autocomplete, Checkbox } from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
//queries and mutations
import {
  DriverPayload,
  ProgramPayload,
  useGetDriversQuery,
  usePackingBagsReportLazyQuery,
  useGetAllProgramsQuery,
} from "../../../graphql/types";
//component
import PackingForBagsReportPrint from "./PackagingForBagsReportPrint";
//utils
import { getMessageError } from "../../Utils";

const PackagingForBagsReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  //style
  const ButtonClasses = useStylesButtons();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //state
  const [startDate, setStartDate] = useState<Moment>(moment.utc());
  const [showGenerate, setShowGenerate] = useState<boolean>(true);
  const [program, setProgram] = useState<ProgramPayload | null>();
  const [driver, setDriver] = useState<DriverPayload | null>();
  const printRef = useRef<HTMLDivElement>(null);

  //queries
  const dataDriver = useGetDriversQuery();
  const dataPrograms = useGetAllProgramsQuery({
    variables: { input: { page: 1, documentsPerPage: 1000 } },
  });

  const [getReportsData, { data: reportData }] = usePackingBagsReportLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted() {
      handlePrint();
      setShowGenerate(true);
    },
    onError(error) {
      const err = getMessageError(error);
      enqueueSnackbar(err, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setShowGenerate(true);
    },
  });

  //functions
  const onGenerateHandler = async () => {
    setShowGenerate(false);
    await getReportsData({
      variables: {
        input: {
          from: startDate.toDate(),
          to: startDate.toDate(),
          program: program?._id ? [program._id] : [],
          driver: driver?._id ? [driver._id] : [],
        },
      },
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    documentTitle:
      "Packaging For Bags Report " +
      moment(startDate).format("DD/MM/YYYY dddd") +
      " generated on " +
      moment().format("DD/MM/YYYY dddd"),
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={800}
    >
      <Box
        width="40%"
        height="70%"
        bgcolor="#ffffff73"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection="column"
        borderRadius={5}
        border={1}
        borderColor="#e7e7e773"
      >
        <Box
          fontFamily="Poppins"
          fontSize={20}
          fontWeight="600"
          alignSelf="flex-start"
          marginLeft="15%"
        >
          Packaging For Bags Report
        </Box>
        <Box width="70%">
          <Box width="100%">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                value={startDate}
                inputFormat="DD/MM/YYYY"
                disablePast={false}
                disableFuture={false}
                onChange={(newValue) => {
                  const utcDate = moment.utc(newValue);
                  setStartDate(utcDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Delivery Date"
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box width="100%" marginTop={3}>
            <Autocomplete
              id="chosenProgram"
              options={dataPrograms?.data?.getAllPrograms?.data || []}
              onChange={(_, value) => {
                setProgram(value);
              }}
              value={program}
              getOptionLabel={(option) => option.name?.EN || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="secondary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.name?.EN}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a program"
                  label="Program"
                />
              )}
            />
          </Box>
          <Box width="100%" marginTop={3}>
            <Autocomplete
              id="chosenProgram"
              options={dataDriver?.data?.getDrivers || []}
              onChange={(_, value) => {
                setDriver(value);
              }}
              value={driver}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="secondary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a driver"
                  label="Driver"
                />
              )}
            />
          </Box>
        </Box>
        <Box
          width="70%"
          height={100}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            type="submit"
            disabled={!showGenerate}
            onClick={onGenerateHandler}
            sx={{ width: "100%", height: "50%" }}
          >
            Generate Reports
          </Button>
          <Box
            fontFamily="Poppins"
            fontSize={10}
            fontWeight="500"
            alignSelf="flex-start"
          >
            Use this table to generate the packaging label for bags .
          </Box>
        </Box>
      </Box>
      <div style={{ display: "none" }}>
        <PackingForBagsReportPrint
          startDate={startDate.toDate()}
          generateLabel={reportData}
          ref={printRef}
        />
      </div>
    </Box>
  );
};
export default memo(PackagingForBagsReport);
