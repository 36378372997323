import { useMemo, useState } from "react";
import moment from "moment";
//material ui
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import {
  Box,
  Button,
  TextField,
  Autocomplete,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//icons
import ExcelIcon from "../../../public/icons/ExcelIcon";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { TableContainer } from "../../../styles/Table__styles";
//queries&mutations
import {
  AreaPayload,
  DriverPayload,
  DriverSortBy,
  useGetAreasQuery,
  useGetDeliveriesListByDriverQuery,
  useGetDriversQuery,
} from "../../../graphql/types";
//components
import Table from "../../layout/commun/Table";
import Status from "../../Status/Status";
import PageHeader from "../../pageHeader/PageHeader";
//utils
import { DELIVERY_TIME, columns, filterElements } from "./Utils";
import { handleStatus } from "../../customer/Utils";
import { generateRapport } from "../../reports/Utils";
//context api
import { useAuth } from "../../../contextAPI";

const DriversAppDashboard = () => {
  let auth = useAuth();

  //style
  const ButtonClasses = useStylesButtons();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //state
  const [date, setDate] = useState<Date | null>(new Date());
  const [driver, setDriver] = useState<DriverPayload | null>();
  const [deliveryTime, setDeliveryTime] = useState<any | null>();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<DriverSortBy>("CUSTOMER_NAME");
  const [search, setSearch] = useState<string>("");
  const [location, setLocation] = useState<AreaPayload[]>([]);

  //queries&mutations
  const dataDriver = useGetDriversQuery();

  const { data } = useGetAreasQuery();
  
  const deliveriesData = useGetDeliveriesListByDriverQuery({
    variables: {
      deliveriesInput: {
        page: page,
        documentPerPage: rowsPerPage,
        deliverDate: date,
        deliveryTime: deliveryTime === "ALL" ? null : deliveryTime,
        driver: driver?._id,
        sort: sortBy,
        customerName: search,
        locations: location?.map((el) => el?._id),
      },
    },
  });

  const count = useMemo(
    () => deliveriesData.data?.getDeliveriesListByDriver.count || 0,
    [deliveriesData.data]
  );

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderTableRows = () => {
    return (
      deliveriesData?.data?.getDeliveriesListByDriver.data.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          customer: el.customerName,
          delivery: el.deliveryTime?.from + " to " + el.deliveryTime?.to,
          status: (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Status
                status={el?.status as any}
                statusText={handleStatus(el?.status)}
              />
            </Box>
          ),
          deliveredTime: el.deliveredAt
            ? moment(el.deliveredAt).format("h:mm a")
            : "---",
          location: el.location,
          driver: el.driverName,
          receivedBy: el.recivedBy ? el.recivedBy : "---",
          photo: el.photo ? (
            <a href={el.photo} target="_blank" download>
              <Button
                variant="contained"
                size="medium"
                className={ButtonClasses.button_cancel_form}
              >
                Download
              </Button>
            </a>
          ) : (
            "---"
          ),
          reason: el.reason ? el.reason : "---",
        };
      }) || []
    );
  };

  const onExportExcelHandler = async () => {
    setLoadingReport(true);
    try {
      await generateRapport(
        auth.token || "",
        "DRIVERS_APP_REPORT",
        date || new Date(),
        new Date(),
        deliveryTime,
        driver?._id
      );
    } catch (error) {
      console.log("error");
    } finally {
      setLoadingReport(false);
    }
  };

  return (
    <>
      <Box marginBottom={5}>
        <PageHeader
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />

        <Box display="flex" alignItems="center">
          <Button
            onClick={onExportExcelHandler}
            startIcon={<ExcelIcon />}
            disabled={!date || loadingReport}
          >
            Export Excel
            {loadingReport ? (
              <CircularProgress size={14} color="inherit" />
            ) : (
              ""
            )}
          </Button>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center">
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              value={date}
              inputFormat="DD/MM/YYYY"
              disablePast={false}
              disableFuture={false}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Date"
                  sx={{
                    backgroundColor: "#fff",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          alignSelf="center"
          paddingLeft={42}
          paddingRight={42}
        >
          <Box marginTop={3} display="flex" alignItems="center" minWidth="30%">
            <Autocomplete
              id="driver"
              sx={{ width: "100%" }}
              options={dataDriver?.data?.getDrivers || []}
              onChange={(_, value) => {
                setDriver(value);
              }}
              value={driver}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="secondary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a driver"
                  label="Driver"
                />
              )}
            />
          </Box>
          <Box marginTop={3} display="flex" alignItems="center" minWidth="30%">
            <Autocomplete
              id="driver"
              multiple
              sx={{ width: "100%" }}
              options={data?.getAreas || []}
              onChange={(_, value) => {
                setLocation(value);
              }}
              value={location as any}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="secondary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a location"
                  label="Location"
                />
              )}
            />
          </Box>
          <Box marginTop={3} display="flex" alignItems="center" minWidth="30%">
            <Autocomplete
              id="chosenDeliveryTime"
              sx={{ width: "100%" }}
              options={DELIVERY_TIME}
              onChange={(_, value) => {
                setDeliveryTime(value);
              }}
              value={deliveryTime}
              getOptionLabel={(option) => option || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="secondary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a Delivery Time"
                  label="Delivery Time"
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <TableContainer>
        <Table
          columns={columns}
          loading={deliveriesData.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No packs found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
    </>
  );
};

export default DriversAppDashboard;
