import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//styles
import { useStylesForgetPassword } from "../../../styles/Auth__styles";

const ForgetPassword = (): JSX.Element => {
  //style
  const ForgetClasses = useStylesForgetPassword();
  return (
    <Box className={ForgetClasses.foget__box}>
      <Typography variant="h1">Forgot password? </Typography>
      <Typography variant="h3" color="text.primary" fontWeight={400}>
        No worries. we’ll send you reset instructions.
      </Typography>
      <form className={ForgetClasses.forget__form}>
        <Typography width="100%" variant="subtitle1" lineHeight={2}>
          Email
        </Typography>
        <TextField
          id="demo-helper-text-misaligned"
          placeholder="Enter your email"
          autoComplete="off"
          size="medium"
          fullWidth
        />
        <Button
          variant="contained"
          className={ForgetClasses.forget__btn_reset_password}
        >
          Reset password
        </Button>
        <Link to="/login" className={ForgetClasses.forget__go__back__login}>
          Back to login
        </Link>
      </form>
    </Box>
  );
};

export default ForgetPassword;
