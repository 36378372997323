import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStylesIngredient = makeStyles((theme: Theme) => {
  return {
    ingredient__container: {
      display: "flex",
      flexDirection: "row",
      gap: "15px",
    },
    ingredient__title: {
      color: `${theme.palette.secondary.main} !important`,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
      margin: "0px 0 10px !important",
    },
    ingredient__subtitle: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      "& > span": {
        color: `${theme.palette.success.contrastText} !important`,
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.subtitle2.fontWeight,
      },
    },
    ingredient__error__helper: {
      color: `${theme.palette.error.main}`,
      fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    },
    ingredient__container__tags: {
      display: "flex",
      flexWrap: "wrap",
      gap: "5px",
      width: "500px",
    },
    ingredient__tags: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      marginBottom: "5px",
    },
    ingredient__tags__icon: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      fill: `${theme.palette.primary.contrastText} !important`,
      fontSize: `18px !important`,
    },
    ingredient__add_container: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "10px",
    },
    ingredient__add__subtitle: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle1.fontWeight,
      textTransform: "capitalize",
      cursor: "pointer",
    },
    button_container: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
    },
    ingredient__tags__container: {
      maxWidth: "750px",
      marginBottom: "12px",
      display: "flex",
      flexWrap: "wrap",
    },
    ingredient__view__titles: {
      display: "flex",
      alignItems: "center",
    },
    ingredient__name__title: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.h1.fontSize} !important`,
      fontWeight: theme.typography.h1.fontWeight,
      margin: "0px !important",
    },
    ingredient__id: {
      color: `${theme.palette.warning.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle1.fontWeight,
      marginLeft: "15px",
    },
    ingredient__cell: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle2.fontWeight,
      padding: "12px 0 !important",
    },
    alergen_cost_container: {
      display: "flex",
      justifyContent: "space-between",
    },
    cost_font: {
      fontWeight: "Bolder",
    },
  };
});
export const Container = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.primary.contrastText,
  maxWidth: "100%",
  height: "auto",
  minHeight: "30vh",
  margin: "25px auto",
  padding: "30px",
  boxSizing: "border-box",
}));

export const ContainerForm = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const ContainerRight = styled("div")(({ theme }) => ({
  //backgroundColor: theme.palette.primary.contrastText,
  width: "30%",
}));

export const ContainerLeft = styled("div")(({ theme }) => ({
  //backgroundColor: theme.palette.primary.contrastText,
  width: "60%",
}));
