import { useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
//material ui
import { Box, FormControlLabel, Switch, TextField } from "@mui/material";
//styles
import { useStylesContainers } from "../../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../../styles/Recipe__styles";
//components
import PageAddHeader from "../../../pageAddHeader/pageAddHeader";
import PhoneInput from "../../../layout/PhoneInput";
import DropZoneInput from "../../../layout/commun/DropZoneInput";
import VideoUploader from "../../../layout/commun/VideoUploader";
//querries&mutations
import { useCreateTrainerMutation } from "../../../../graphql/types";
//types
import { TrainerProps } from "../Types";
import { ApolloError } from "@apollo/client";
//utils
import { getMessageError } from "../../../Utils";
import { useCountryPrefix } from "../../../../utils/Hooks/useCountryPrefix";

const AddTrainer = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const countryPrefix = useCountryPrefix();
  const navigate = useNavigate();

  //styles
  const ContainersClasses = useStylesContainers();
  const RecipeClasses = useStylesRecipe();

  //state
  const [characterCount, setCharacterCount] = useState(0);

  //react-hook-form
  const methods = useForm<TrainerProps>({
    defaultValues: {
      firstNameEnglish: "",
      lastNameEnglish: "",
      firstNameArabic: "",
      lastNameArabic: "",
      email: "",
      phoneNumber: "",
      phonePrefix: countryPrefix?.callingCode,
      description: "",
      descriptionAR: "",
      isActive: false,
      videoType: true,
      isAcceptingFreeSessions: false,
      trainerImage: "",
      trainerVideoLink: "",
      loadingImage: "",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;
  const dataWatch = useWatch({ control });

  //queries&mutations
  const [createTrainer, { loading }] = useCreateTrainerMutation();

  //functions
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setCharacterCount(input.length);
  };

  const onSubmit: SubmitHandler<TrainerProps> = async (data) => {
    const phone = data?.phoneNumber?.replace(/\s/g, "") || "";
    try {
      await createTrainer({
        variables: {
          input: {
            fname: {
              EN: data.firstNameEnglish,
              AR: data.firstNameArabic,
            },
            lname: {
              EN: data.lastNameEnglish,
              AR: data.lastNameArabic,
            },
            descriptions: {
              EN: data.description,
              AR: data.descriptionAR,
            },
            email: data.email,
            isActive: data.isActive,
            freeSession: data.isAcceptingFreeSessions,
            phone: {
              prefix: `+${data.phonePrefix}`,
              number: phone,
            },
            picture: data.trainerImage,
            videoPicture: data.loadingImage,
            video: data.trainerVideoLink,
          },
        },
        onCompleted: () => {
          enqueueSnackbar("New Trainer succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/data/trainers/list_trainers");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title={"Create Trainer"}
          buttonText={"Create Trainer"}
          cancelButtonLink="/data/trainers/list_trainers"
          loading={loading}
        />

        <Box display="grid" gridTemplateColumns="2fr 1fr" columnGap={10}>
          <Box width="100%">
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={4}>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  First Name* <span>(English)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="First Name English"
                  fullWidth
                  {...register("firstNameEnglish", {
                    required: "Name is Mandatory",
                  })}
                />
                {errors?.firstNameEnglish?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.firstNameEnglish?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Last Name <span>(English)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Last Name English"
                  fullWidth
                  {...register("lastNameEnglish")}
                />
                {errors?.lastNameEnglish?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.lastNameEnglish?.message}
                  </p>
                )}
              </Box>

              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  First Name <span>(Arabic)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="First Name Arabic"
                  fullWidth
                  {...register("firstNameArabic", {
                    required: "Name is Mandatory",
                  })}
                />
                {errors?.firstNameArabic?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.firstNameArabic?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Last Name <span>(Arabic)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Last Name Arabic"
                  fullWidth
                  {...register("lastNameArabic")}
                />
                {errors?.lastNameArabic?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.lastNameArabic?.message}
                  </p>
                )}
              </Box>
              <Box>
                <p className={ContainersClasses.section__subtitle}>Email</p>
                <TextField
                  placeholder="Email"
                  sx={{ width: "100%" }}
                  {...register("email", {
                    required: "Enter Lead Email",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Enter a valid Email",
                    },
                  })}
                />

                <p className={ContainersClasses.section__error__helper}>
                  {errors?.email?.message}
                </p>
              </Box>
              <Box>
                <p className={ContainersClasses.section__subtitle}>
                  Phone Number*
                </p>
                <PhoneInput
                  labelFormPrefix="phonePrefix"
                  labelFormNumber="phoneNumber"
                  placeholder="Phone Number"
                />
                <Box textAlign="right" fontFamily="Poppins" fontSize={10}>
                  *This should be a Whatsapp number
                </Box>
              </Box>
            </Box>
            <Box marginTop={5}>
              <p className={ContainersClasses.section__subtitle}>
                Trainer Personal description
              </p>
              <TextField
                multiline
                rows={10}
                sx={{ width: "100%" }}
                {...register("description")}
                onChange={handleInputChange}
              />
              <Box
                textAlign="right"
                fontFamily="Poppins"
                fontSize={10}
                color={characterCount > 99 ? "red" : "black"}
              >
                {characterCount}/100 character
              </Box>
            </Box>
            <Box marginTop={5}>
              <p className={ContainersClasses.section__subtitle}>
                Trainer Personal description (AR)
              </p>
              <TextField
                multiline
                rows={10}
                sx={{ width: "100%" }}
                {...register("descriptionAR")}
                onChange={handleInputChange}
              />
              <Box
                textAlign="right"
                fontFamily="Poppins"
                fontSize={10}
                color={characterCount > 99 ? "red" : "black"}
              >
                {characterCount}/100 character
              </Box>
            </Box>
          </Box>
          <Box width="100%" marginTop={4}>
            <Box>
              <Controller
                name={`isActive`}
                control={control}
                render={({ field: { ref, value, ...rest } }) => (
                  <FormControlLabel
                    {...rest}
                    value={value}
                    control={<Switch checked={value} />}
                    label={value ? "Active" : "Inactive"}
                    labelPlacement="top"
                  />
                )}
              />
            </Box>
            <Box>
              <p className={ContainersClasses.section__subtitle}>
                Upload Trainer image
              </p>
              <Controller
                name="trainerImage"
                control={control}
                render={({ field: { onChange, ref } }) => {
                  return (
                    <DropZoneInput
                      onChange={(e) => {
                        onChange(e.target.files[0]);
                      }}
                      label="Drop File"
                      inputRef={ref}
                      name="trainerImage"
                      errors={errors}
                      multiple={false}
                      disabled={false}
                      accept="image/*"
                      message="Recommended resolution for image 400px*400px"
                    />
                  );
                }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <p className={ContainersClasses.section__subtitle}>
                Upload Video
              </p>
              <Controller
                name={`videoType`}
                control={control}
                render={({ field: { ref, value, ...rest } }) => (
                  <FormControlLabel
                    {...rest}
                    sx={{ marginLeft: 2 }}
                    value={value}
                    control={<Switch checked={value} />}
                    label=""
                  />
                )}
              />
              <p className={ContainersClasses.section__subtitle}>Video Link</p>
            </Box>
            {dataWatch.videoType ? (
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Add video link of personal trainer
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Youtube video link"
                  fullWidth
                  {...register("trainerVideoLink")}
                />
                {errors?.trainerVideoLink?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.trainerVideoLink?.message}
                  </p>
                )}
              </Box>
            ) : (
              <Box>
                <p className={ContainersClasses.section__subtitle}>
                  Upload Trainer video
                </p>
                <Controller
                  name="trainerVideoLink"
                  control={control}
                  render={({ field: { onChange, ref } }) => {
                    return (
                      <VideoUploader
                        onChange={(e: any) => {
                          onChange(e.target.files[0]);
                        }}
                        inputRef={ref}
                      />
                    );
                  }}
                />
              </Box>
            )}
            <Box>
              <p className={ContainersClasses.section__subtitle}>
                Upload Trainer Video Loading Image *
              </p>
              <Controller
                name="loadingImage"
                control={control}
                render={({ field: { onChange, ref } }) => {
                  return (
                    <DropZoneInput
                      onChange={(e) => {
                        onChange(e.target.files[0]);
                      }}
                      label="Drop File"
                      inputRef={ref}
                      name="loadingImage"
                      errors={errors}
                      multiple={false}
                      disabled={false}
                      accept="image/*"
                      message="Recommended resolution for image 400px*400px"
                    />
                  );
                }}
              />
            </Box>
            <Box marginTop={4} marginLeft={4}>
              <Controller
                name={`isAcceptingFreeSessions`}
                control={control}
                render={({ field: { ref, value, ...rest } }) => (
                  <FormControlLabel
                    {...rest}
                    value={value}
                    control={<Switch checked={value} />}
                    label={
                      value ? "Available free session" : " No free session"
                    }
                    labelPlacement="end"
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddTrainer;
