import { LunchMeal } from "../../../graphql/types";

export const PaymentList = [
  "CASH",
  "CREDIT_CARD",
  "DEBIT_CARD",
  "APPLE_PAY",
  "POS_MACHINE",
  "GOOGLE_PAY",
  "NAPS",
  "WALLET",
];

export const mealsConverter = (meals: LunchMeal[]) => {
  const formattedMeals = meals.map((el) => el.replace(/_/g, " ").toLowerCase());
  return formattedMeals;
};
