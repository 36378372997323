import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import _ from "lodash";
import {
  useForm,
  useWatch,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import moment from "moment";
//material
import {
  Box,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete,
  Chip,
  Checkbox,
  IconButton,
  Dialog,
} from "@mui/material";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { LocalizationProvider } from "@mui/lab";
import DateFnsAdapter from "@mui/lab/AdapterDateFns";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import { DropzoneArea } from "material-ui-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
//types
import { ApolloError } from "@apollo/client";
import { NotificationProps } from "../types";
//mutations&quries
import {
  NotificationType,
  ProgramPayload,
  useCreateNotificationFromDashboardMutation,
  useGetActiveProgramsLazyQuery,
  useGetSpecificCustomerListQuery,
  useGetTemplatesListLazyQuery,
} from "../../../../graphql/types";
//Component
import DropZoneInput from "../../../layout/commun/DropZoneInput";
import PageAddHeader from "../../../pageAddHeader/pageAddHeader";
//Styles
import {
  ContainerStyled,
  useStylesContainers,
} from "../../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../../styles/Recipe__styles";
import { useStylesSelect } from "../../../../styles/Select__styles";
//Utils
import { getMessageError } from "../../../Utils";
import { typeOptions, durations, planConditions } from "../Utils";
import { numberOfDays } from "../../promoCode/Utils";

const AddNotification = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const navigate = useNavigate();
  const RecipeClasses = useStylesRecipe();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const ContainersClasses = useStylesContainers();
  const SelectClasses = useStylesSelect();

  //state
  const [selectedOption, setSelectedOption] = useState("isUnique");
  const [search, setSearch] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [openDialogEn, setOpenDialogEn] = useState(false);
  const [openDialogAr, setOpenDialogAr] = useState(false);

  // react hook form
  const methods = useForm<NotificationProps>({
    defaultValues: {
      title__english: "",
      title__arabic: "",
      shortdescription__arabic: "",
      shortdescription__english: "",
      type: undefined,
      sendAt: null,
      picture: "",
      isUnique: true,
      isPushNotification: false,
      customersList: [],
      program: [],
      planCondition: [],
      numberOfDays: [],
      paymentPeriod: [],
      bodyEn: "",
      bodyAr: "",
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  //querys & mutations
  const [getTemplates, { data: templatesData }] =
    useGetTemplatesListLazyQuery();

  useEffect(() => {
    if (dataWatch.type) {
      getTemplates({
        variables: {
          input: {
            page: 1,
            documentsPerPage: 100,
            type: dataWatch.type,
          },
        },
      });
    }
  }, [dataWatch.type]);

  const TemplatesList = useMemo(
    () => templatesData?.getTemplatesList.data || [],
    [templatesData?.getTemplatesList]
  );

  const [addNotification, dataAddNotification] =
    useCreateNotificationFromDashboardMutation();

  const customersList = useGetSpecificCustomerListQuery({
    variables: {
      input: {
        page: 1,
        documentsPerPage: 10,
        nameCustomer: search,
      },
    },
  });

  const debounced = useCallback(
    _.debounce(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearch(event.target.value);
      },
      800
    ),
    []
  );

  const customers = useMemo(
    () => customersList?.data?.getSpecificCustomerList.data || [],
    [customersList?.data]
  );

  const [getActivePrograms, { data: activeProgramsData }] =
    useGetActiveProgramsLazyQuery();

  const activePrograms = useMemo(
    () => activeProgramsData?.getActivePrograms || [],
    [activeProgramsData]
  );

  const handleRemoveFileEn = () => {
    setValue("bodyEn", "");
  };

  const handleRemoveFileAr = () => {
    setValue("bodyAr", "");
  };

  const ChooseRadioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "isUnique") {
      setValue("isUnique", true);
      setValue("program", []);
      setValue("planCondition", []);
      setValue("numberOfDays", []);
      setValue("paymentPeriod", []);
    } else {
      setValue("isUnique", false);
      setValue("customersList", []);
    }
  };

  const onSubmit: SubmitHandler<NotificationProps> = async (data) => {
    const programs = data?.program?.map((el) => {
      return el._id!;
    });

    try {
      await addNotification({
        variables: {
          input: {
            title: {
              EN: data.title__english,
              AR: data.title__arabic,
            },
            shortDescription: {
              EN: data.shortdescription__english,
              AR: data.shortdescription__arabic,
            },
            htmlBody: {
              EN: data.bodyEn,
              AR: data.bodyAr,
            },
            type: data.type as NotificationType,
            sendAt: !data.isPushNotification
              ? moment(data.sendAt).add(1, "hour").toDate()
              : null,
            isUnique: data.isUnique,
            isPushNotification: data.isPushNotification,
            photo: data.picture,
            customersList: data.customersList.map((c) => {
              return c._id || "";
            }),
            filter: {
              program: programs,
              planCondition: data.planCondition,
              numberOfDays: data.numberOfDays,
              paymentPeriod: data.paymentPeriod,
            },
          },
        },
        onCompleted: () => {
          enqueueSnackbar("New Notification succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/data/notifications/list_notifications");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title={"Create Notification"}
          buttonText={"Create Notification"}
          cancelButtonLink="/data/notifications/list_notifications"
          loading={dataAddNotification.loading}
        />

        <ContainerStyled>
          <Box
            display="grid"
            width="100%"
            gridTemplateColumns="2fr 1fr"
            gap={4}
          >
            <Box
              display="grid"
              width="100%"
              gridTemplateColumns="1fr 1fr"
              columnGap={2}
            >
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Title* <span>(English)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Title English"
                  fullWidth
                  {...register("title__english", {
                    required: "Name is Mandatory",
                  })}
                />
                {errors?.title__english?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.title__english?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Title<span>(Arabic)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Title Arabic"
                  fullWidth
                  {...register("title__arabic")}
                />
                {errors?.title__arabic?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.title__arabic?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Short Description <span>(English)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Short Description English"
                  fullWidth
                  {...register("shortdescription__english")}
                />
                {errors?.shortdescription__english?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.shortdescription__english?.message}
                  </p>
                )}
              </Box>

              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Short Description <span>(Arabic)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Short Description Arabic"
                  fullWidth
                  {...register("shortdescription__arabic")}
                />
                {errors?.shortdescription__arabic?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.shortdescription__arabic?.message}
                  </p>
                )}
              </Box>

              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  SendTo ({selectedOption})
                </p>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={ChooseRadioHandler}
                >
                  <FormControlLabel
                    value="isUnique"
                    control={
                      <Radio
                        color="secondary"
                        checked={selectedOption === "isUnique"}
                      />
                    }
                    label="isUnique"
                  />
                  <FormControlLabel
                    value="isCommon"
                    control={
                      <Radio
                        color="secondary"
                        checked={selectedOption === "isCommon"}
                        onClick={() => getActivePrograms()}
                      />
                    }
                    label="isCommon"
                  />
                </RadioGroup>
              </Box>

              {selectedOption === "isUnique" ? (
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Select Customers
                  </p>
                  <Controller
                    name="customersList"
                    control={control}
                    render={({ field: { onChange: Change } }) => (
                      <>
                        <Autocomplete
                          multiple
                          id="customersList"
                          options={customers}
                          getOptionLabel={(option) =>
                            option.fname + " " + option.lname
                          }
                          filterSelectedOptions
                          disableCloseOnSelect
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.fname + " " + option.lname}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          onChange={(_, data) => Change(data)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(event) => debounced(event)}
                            />
                          )}
                        />
                      </>
                    )}
                  />

                  <p className={ContainersClasses.section__error__helper}>
                    {errors?.type?.message}
                  </p>
                </Box>
              ) : (
                <Box width="100%">
                  {/* PROGRAM */}
                  <Box width="100%">
                    <p className={ContainersClasses.section__subtitle}>
                      Program
                    </p>
                    <Controller
                      name="program"
                      control={control}
                      render={({
                        field: { onChange: Change, value, ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            ref={ref}
                            multiple
                            id="program"
                            options={activePrograms || []}
                            value={dataWatch.program as ProgramPayload[]}
                            getOptionLabel={(option) => option.name?.EN || ""}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            filterSelectedOptions
                            onChange={(_, data) => Change(data)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  color="secondary"
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name?.EN}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Program" />
                            )}
                          />
                          <p
                            className={ContainersClasses.section__error__helper}
                          >
                            {error?.message}
                          </p>
                        </>
                      )}
                    />
                  </Box>

                  {/* PLAN CONDITION*/}
                  <Box width="100%">
                    <p className={ContainersClasses.section__subtitle}>
                      Plan Condition
                    </p>
                    <Controller
                      name="planCondition"
                      control={control}
                      render={({
                        field: { onChange: Change, value, ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            ref={ref}
                            multiple
                            id="planCondition"
                            options={planConditions}
                            value={value}
                            filterSelectedOptions
                            onChange={(_, data) => Change(data)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  color="secondary"
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Plan Condition"
                              />
                            )}
                          />
                          <p
                            className={ContainersClasses.section__error__helper}
                          >
                            {error?.message}
                          </p>
                        </>
                      )}
                    />
                  </Box>

                  {/* NUMBER OF DAYS */}
                  <Box width="100%">
                    <p className={ContainersClasses.section__subtitle}>
                      Number of days
                    </p>
                    <Controller
                      name="numberOfDays"
                      control={control}
                      render={({
                        field: { onChange: Change, value, ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            ref={ref}
                            multiple
                            id="numberDays"
                            options={numberOfDays}
                            value={value}
                            filterSelectedOptions
                            onChange={(_, data) => Change(data)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  color="secondary"
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Number of days"
                              />
                            )}
                          />
                          <p
                            className={ContainersClasses.section__error__helper}
                          >
                            {error?.message}
                          </p>
                        </>
                      )}
                    />
                  </Box>

                  {/* PAYMENT PERIOD */}
                  <Box width="100%">
                    <p className={ContainersClasses.section__subtitle}>
                      Payment Period
                    </p>
                    <Controller
                      name="paymentPeriod"
                      control={control}
                      render={({
                        field: { onChange: Change, value, ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            ref={ref}
                            multiple
                            id="numberDays"
                            options={durations}
                            value={value}
                            filterSelectedOptions
                            onChange={(_, data) => Change(data)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  color="secondary"
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Payment Period"
                              />
                            )}
                          />
                          <p
                            className={ContainersClasses.section__error__helper}
                          >
                            {error?.message}
                          </p>
                        </>
                      )}
                    />
                  </Box>
                </Box>
              )}

              {/* SendAt */}
              <LocalizationProvider dateAdapter={DateFnsAdapter}>
                {!dataWatch.isPushNotification && (
                  <Box width="100%">
                    <p className={ContainersClasses.section__subtitle}>
                      SendAt
                    </p>
                    <Controller
                      name="sendAt"
                      control={control}
                      render={({ field }) => (
                        <DateTimePicker
                          value={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: "100%" }} />
                          )}
                        />
                      )}
                    />
                    <p className={ContainersClasses.section__error__helper}>
                      {errors?.sendAt?.message}
                    </p>
                  </Box>
                )}
              </LocalizationProvider>

              {/* type */}
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Type</p>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Select
                      sx={{ width: "100%" }}
                      onChange={(event) =>
                        onChange(event.target.value as NotificationType)
                      }
                    >
                      {typeOptions.map((option) => (
                        <MenuItem
                          key={option.menuValue}
                          value={option.menuValue}
                          className={
                            dataWatch.type === option.menuValue
                              ? clsx(
                                  SelectClasses.option_item,
                                  SelectClasses.option_item_selected
                                )
                              : SelectClasses.option_item
                          }
                        >
                          {option.menuText}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <p className={ContainersClasses.section__error__helper}>
                  {errors?.type?.message}
                </p>
              </Box>

              <Box>
                {dataWatch.type && (
                  <>
                    <p className={ContainersClasses.section__subtitle}>
                      Templates Title
                    </p>
                    <Autocomplete
                      key={dataWatch.type}
                      options={TemplatesList}
                      getOptionLabel={(option) => option.title?.EN || ""}
                      value={TemplatesList.find(
                        (option) => option._id === selectedTemplate
                      )}
                      onChange={(_, data) => {
                        setSelectedTemplate(data?._id!);
                        setValue("bodyEn", data?.body?.EN || "");
                        setValue("bodyAr", data?.body?.AR || "");
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </>
                )}
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="start">
              <p className={ContainersClasses.section__subtitle}>
                Upload Image
              </p>

              <Controller
                name="picture"
                control={control}
                render={({ field: { onChange, ref } }) => {
                  return (
                    <DropZoneInput
                      onChange={(e) => {
                        onChange(e.target.files[0]);
                      }}
                      label="Drop File"
                      inputRef={ref}
                      name="picture"
                      errors={errors}
                      multiple={false}
                      disabled={false}
                      accept="image/*"
                      message="Recommended resolution for image 400px*400px"
                    />
                  );
                }}
              />

              <Controller
                name="isPushNotification"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    value="start"
                    label="Is Push Notification"
                    control={
                      <Checkbox
                        color="secondary"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    sx={{
                      marginTop: 3,
                    }}
                  />
                )}
              />
            </Box>
            <Box
              display="grid"
              width="100%"
              gridTemplateColumns="1fr 1fr"
              columnGap={2}
            >
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Template Body(EN)
                </p>
                {dataWatch.bodyEn ? (
                  <Box>
                    <IconButton
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                        marginRight: "20px",
                        marginLeft: "20px",
                      }}
                      onClick={handleRemoveFileEn}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setOpenDialogEn(true)}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <DropzoneArea
                    onChange={(files) => {
                      if (files && files.length) {
                        const reader = new FileReader();
                        reader.onload = function () {
                          const result = reader.result as string;
                          setValue("bodyEn", result);
                        };
                        reader.readAsText(files[0]);
                      }
                    }}
                    dropzoneText="Drag & Drop your HTML file here"
                    acceptedFiles={[".html"]}
                  />
                )}

                <Dialog open={openDialogEn}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenDialogEn(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <iframe
                    srcDoc={dataWatch.bodyEn}
                    style={{ width: "1000px", height: "700px" }}
                  />
                </Dialog>
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Template Body(AR)
                </p>
                {dataWatch.bodyAr ? (
                  <Box>
                    <IconButton
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                        marginRight: "20px",
                        marginLeft: "20px",
                      }}
                      onClick={handleRemoveFileAr}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setOpenDialogAr(true)}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <DropzoneArea
                    onChange={(files) => {
                      if (files && files.length) {
                        const reader = new FileReader();
                        reader.onload = function () {
                          const result = reader.result as string;
                          setValue("bodyAr", result);
                        };
                        reader.readAsText(files[0]);
                      }
                    }}
                    dropzoneText="Drag & Drop your HTML file here"
                    acceptedFiles={[".html"]}
                  />
                )}

                <Dialog open={openDialogAr}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenDialogAr(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <iframe
                    srcDoc={dataWatch.bodyAr}
                    style={{ width: "1000px", height: "700px" }}
                  />
                </Dialog>
              </Box>
            </Box>
          </Box>
        </ContainerStyled>
      </form>
    </FormProvider>
  );
};

export default AddNotification;
