import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  InputAdornment,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
//Types
import { EditCaloriesState } from "../Types";
import { ApolloError } from "@apollo/client";
import { CustomerActionsTypes } from "./Types";
//queries&mutations
import {
  useGetCustomerProfileByIdQuery,
  useInitiateChangeMacrosQuery,
  useOnChangeMacrosDashboardMutation,
} from "../../../graphql/types";
//utils
import { Goal, getMacros } from "../Utils";
import { getMessageError } from "../../Utils";

const EditCalories: React.FC<CustomerActionsTypes> = ({
  expanded,
  handleChange,
}) => {
  const { id } = useParams();
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //state
  const [carbs, setCarbs] = useState<string>("");
  const [proteines, setProteines] = useState<string>("");
  const [Fat, setFats] = useState<string>("");
  const [goal, setgoal] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  //styles
  const ButtonClasses = useStylesButtons();
  const ContainersClasses = useStylesContainers();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //react hook form
  const methodsEditCalories = useForm<EditCaloriesState>({
    defaultValues: {
      calories: "",
    },
  });
  const { control, handleSubmit, setValue, getValues } = methodsEditCalories;

  //queries
  const { data } = useGetCustomerProfileByIdQuery({
    variables: {
      customerId: params.id || "",
    },
    fetchPolicy: "network-only",
    skip: !params.id || expanded != "EDIT_TARGET_CALORIES",
  });

  const { data: caloriesData } = useInitiateChangeMacrosQuery({
    variables: {
      input: {
        meals: data?.getCustomerProfileById.plan?.meals || [],
        programId: data?.getCustomerProfileById.plan?.program?._id || "",
      },
    },
    fetchPolicy: "network-only",
    skip: !data || expanded != "EDIT_TARGET_CALORIES",
  });

  //queries
  const customerDetails = useMemo(
    () => data?.getCustomerProfileById || null,
    [data?.getCustomerProfileById]
  );

  const [updateCalories, { loading: loadingUpdateCalories }] =
    useOnChangeMacrosDashboardMutation();

  //function
  const handleCaloriesChange = (event: string) => {
    const calories = parseInt(event);
    const macros = getMacros(
      calories,
      goal as Goal,
      customerDetails?.plan?.program?.name?.EN?.toLowerCase().includes("keto")
    );
    setCarbs(macros?.carb);
    setProteines(macros?.prot);
    setFats(macros?.fat);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (customerDetails?.calories) {
      setValue("calories", customerDetails?.calories);
    }
  }, [customerDetails?.calories]);

  useEffect(() => {
    if (customerDetails?.goal) {
      setgoal(customerDetails?.goal);
    }
  }, [customerDetails?.goal]);

  const onSubmitEditCalories: SubmitHandler<EditCaloriesState> = async (
    data
  ) => {
    try {
      await updateCalories({
        variables: {
          input: {
            customer: id || "",
            calories: getValues("calories"),
            macros: {
              prot: proteines,
              fat: Fat,
              carb: carbs,
            },
          },
        },
        onCompleted: () => {
          enqueueSnackbar("Calories succuessfully updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <Accordion
      expanded={expanded === "EDIT_TARGET_CALORIES"}
      onChange={handleChange("EDIT_TARGET_CALORIES")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="#B27D3F" fontSize={18}>
          Edit Target Calories
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormProvider {...methodsEditCalories}>
          <form
            onSubmit={handleSubmit(onSubmitEditCalories)}
            id="EDIT_CALORIES_FORM"
          >
            <Box
              display="flex"
              flexWrap="wrap"
              gap="0px 20px"
              flexDirection="row"
            >
              <Box display="flex" flexGrow={1} flexDirection="row">
                <p
                  className={clsx(ContainersClasses.section__subtitle)}
                  style={{ marginRight: 10 }}
                >
                  Calories :
                </p>
                <Box>
                  <Controller
                    name="calories"
                    control={control}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          {...rest}
                          onChange={(ev) => {
                            handleCaloriesChange(ev?.target?.value);
                            onChange(ev?.target?.value);
                          }}
                          name="calories"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Kcal
                              </InputAdornment>
                            ),
                          }}
                        />
                      </>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {caloriesData?.initiateChangeMacros.min || "-"}-
                    {caloriesData?.initiateChangeMacros.max || "-"}
                  </FormHelperText>
                </Box>

                <Button
                  variant="contained"
                  className={ButtonClasses.button_open_list_page}
                  onClick={() => setOpen(true)}
                  sx={{ height: 40, marginTop: 0.5 }}
                  disabled={loadingUpdateCalories}
                >
                  {loadingUpdateCalories && (
                    <CircularProgress
                      size={20}
                      style={{ color: "white", marginRight: 6 }}
                    />
                  )}
                  Edit Calories
                </Button>

                <Box marginLeft={10}>
                  <Typography>
                    Protein: {customerDetails?.macros?.prot}g
                  </Typography>
                  <Typography>
                    Carbs: {customerDetails?.macros?.carb}g
                  </Typography>
                  <Typography>Fat: {customerDetails?.macros?.fat}g</Typography>
                </Box>
              </Box>
            </Box>

            {/* CONFIRMATION MODAL */}
            <Dialog
              open={open}
              onClose={handleCloseModal}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.alert_dialog_title}>
                <span className="alert_dialog_title_text">Confirm Action</span>
              </DialogTitle>
              <DialogContent className={DialogClasses.alert_dialog_content}>
                <DialogContentText>
                  Are you sure you want to edit this customer's target calories
                  ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={DialogClasses.alert_dialog_actions}>
                <Button
                  className={ButtonsClasses.GreyButton}
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  className={ButtonsClasses.GreenButton}
                  type="submit"
                  form="EDIT_CALORIES_FORM"
                  color="primary"
                  autoFocus
                  onClick={() => setOpen(false)}
                >
                  Edit
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default EditCalories;
