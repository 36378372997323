import React, { useMemo } from "react";
import QRCode from "react-qr-code";
import moment from "moment";
//types
import { BirthdayReportProps } from "../Types";

const DISH_DETAILS_PAGE_URL = "https://dish.dieture.com/";

const BirthdayReportPrint = React.forwardRef<
  HTMLDivElement,
  BirthdayReportProps
>(({ generateBirthday, date }, ref) => {
  const cakeData = useMemo(
    () => generateBirthday?.getBirthdayReport,
    [generateBirthday?.getBirthdayReport]
  );

  //render
  return (
    <div ref={ref}>
      {cakeData?.map((el) => (
        <div key={el._id}>
          <div
            style={{
              width: 302,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: 10,
                    lineBreak: "strict",
                    fontFamily: "Poppins",
                    width: 260,
                    maxHeight: 110,
                    minHeight: 110,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: 36,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      marginRight: 10,
                    }}
                  ></div>
                  <QRCode
                    level="L"
                    bgColor="white"
                    fgColor="black"
                    size={70}
                    value={
                      DISH_DETAILS_PAGE_URL + el?.size?.charAt(0) + "" + el?._id
                    }
                  />
                </div>
                <div
                  style={{
                    fontSize: 16,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    lineHeight: 1,
                    minHeight: 15,
                    maxHeight: 15,
                  }}
                >
                  {el?.name?.EN}
                </div>
              </div>
            </div>

            {/* TABLE FIRST ROW CAL PROT CARBS FAT */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 58,
                paddingRight: 5,
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              >
                {Math.round(
                  el?.nutritionDetails?.find((el) => {
                    return el?.name === "Energy";
                  })?.quantity || 0
                )}
                kcal
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  marginRight: 8,
                  fontWeight: "bold",
                }}
              >
                {Math.round(
                  el?.nutritionDetails?.find((el) => {
                    return el?.name === "Protein";
                  })?.quantity || 0
                )}
                g
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  marginRight: 8,
                  fontWeight: "bold",
                }}
              >
                {Math.round(
                  el?.nutritionDetails?.find((el) => {
                    return el?.name === "Carbohydrate";
                  })?.quantity || 0
                )}
                g
              </div>
              <div
                style={{
                  fontSize: 14,
                  marginRight: 8,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              >
                {Math.round(
                  el?.nutritionDetails?.find((el) => {
                    return el?.name === "Total Fat";
                  })?.quantity || 0
                )}
                g
              </div>
            </div>

            {/* TABLE SECOND ROW MICRO MEALS PDATE EDATE */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 30,
                paddingRight: 5,
              }}
            >
              <div
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              >
                {el?.reheatingInstruction} sec
              </div>
              <div
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  marginRight: 8,
                  fontWeight: "bold",
                }}
              >
                {moment(date).format("YYYY-MM-DD")}
              </div>
              <div
                style={{
                  fontSize: 10,
                  marginRight: 8,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              >
                {moment(date).add(1, "day").format("YYYY-MM-DD")}
              </div>
            </div>

            {/* INGREDIENTS LIST */}
            <div
              style={{
                fontSize: 10,
                marginTop: 30,
                lineBreak: "strict",
                fontFamily: "Poppins",
                fontWeight: "bold",
                width: 260,
                lineHeight: 1.3,
              }}
            >
              {el?.ingredients?.map((elem) => {
                return elem?.name?.EN + " ,";
              })}
            </div>
          </div>
          {/* LINE BREAK */}
          <div style={{ pageBreakAfter: "always" }}></div>
        </div>
      ))}
    </div>
  );
});

export default BirthdayReportPrint;
