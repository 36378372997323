import React, { useMemo, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller, useWatch, SubmitHandler } from "react-hook-form";
// Material-UI components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider, DesktopDatePicker } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
// lodash for debouncing
import _ from "lodash";
// Moment.js for date manipulation
import moment from "moment";
// Notistack for notifications
import { useSnackbar } from "notistack";
// GraphQL types and queries
import {
  ConvertLeadToCustomerWitoutPlanInput,
  CreateCustomerWithoutPlanFromDashboard,
  CustomerProfilePayload,
  LeadPayload,
  useGetActifCustomerPlansQuery,
  useGetAllCustomersProfileV2Query,
  useGetLeadsQuery,
  useTransferBoxesMutation,
} from "../../../../graphql/types";
// Custom components
import CreateNewCustomerModal from "./CreateNewCustomerModal";
import ConvertCustomerModel from "./ConvertCustomerModel";
// Types
import { CustomerActionsTypes } from "../Types";
import { ApolloError } from "@apollo/client";
import { getMessageError } from "../../../Utils";
// styles
import { useStylesContainers } from "../../../../styles/Containers__styles";

interface TransferBoxFormData {
  customerProgram: string | null;
  customerType: "CUSTOMER" | "LEAD" | "NEW_CUSTOMER" | null;
  customerId: string;
  startDate: Date | null;
}
const TransferBox: React.FC<CustomerActionsTypes> = ({
  expanded,
  handleChange,
}) => {
  // states
  const [customerSearchTerm, setCustomerSearchTerm] = useState<string | null>(
    null
  );
  const [leadSearchTerm, setLeadSearchTerm] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerProfilePayload | null>(null);
  const [selectedLead, setSelectedLead] = useState<LeadPayload | null>(null);
  const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);
  const [isNewCustomerModalOpen, setIsNewCustomerModalOpen] = useState(false);
  const [newCustomerDetails, setNewCustomerDetails] =
    useState<CreateCustomerWithoutPlanFromDashboard>();
  const [leadDetails, setLeadDetails] =
    useState<ConvertLeadToCustomerWitoutPlanInput>();
  const { id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //styles
  const ContainersClasses = useStylesContainers();

  const { data: leadData, loading: leadLoading } = useGetLeadsQuery({
    variables: {
      input: {
        page: 1,
        documentsPerPage: 100,
        nameCustomer: leadSearchTerm,
      },
    },
    fetchPolicy: "network-only",
    skip: expanded !== "TRANSFER_BOX",
  });

  const leads = useMemo(() => leadData?.getLeads.data || [], [leadData]);

  const { data: customerData, loading: customerLoading } =
    useGetAllCustomersProfileV2Query({
      variables: {
        input: {
          page: 1,
          documentsPerPage: 100,
          nameCustomer: customerSearchTerm,
        },
      },
      fetchPolicy: "network-only",
      skip: expanded !== "TRANSFER_BOX",
    });
  const customers = useMemo(
    () => customerData?.getAllCustomersProfileV2.data || [],
    [customerData]
  );
  const { data: activeCustomerPlansData, refetch } =
    useGetActifCustomerPlansQuery({
      variables: {
        customerId: id || "",
      },
      skip: expanded !== "TRANSFER_BOX",
    });

  const activeCustomerPlans = useMemo(
    () => activeCustomerPlansData?.getActifCustomerPlans || [],
    [activeCustomerPlansData]
  );

  const transferBoxForm = useForm<TransferBoxFormData>({
    defaultValues: {
      customerProgram: null,
      customerType: null,
      customerId: "",
      startDate: null,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = transferBoxForm;

  const watchFormValues = useWatch({ control });

  const [TrasferBoxToCustomer, { loading: transferBoxLoading }] =
    useTransferBoxesMutation();

  const handleFormSubmit: SubmitHandler<TransferBoxFormData> = async (
    formData
  ) => {
    if (formData.customerType === "CUSTOMER" && !formData.customerId) {
      enqueueSnackbar("Please select a customer", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
      return;
    }
    if (formData.customerType === "LEAD" && !selectedLead) {
      enqueueSnackbar("Please select a Lead", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
      return;
    }
    try {
      await TrasferBoxToCustomer({
        variables: {
          input: {
            toCustomerId: formData.customerId,
            planId: formData.customerProgram,
            fromDate: formData.startDate,
            customerType: formData.customerType,
            leadDetails: leadDetails,
            newCustomerDetails: newCustomerDetails,
          },
        },
        onCompleted: () => {
          reset();
          setNewCustomerDetails(undefined);
          setSelectedCustomer(null);
          setSelectedLead(null);
          setLeadDetails(undefined);
          refetch();
          enqueueSnackbar("Box successfully transferred", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleCustomerSelection = (
    event: unknown,
    value: CustomerProfilePayload | null
  ) => {
    setSelectedCustomer(value);
    setValue("customerId", value?._id || "");
  };

  const handleLeadSelection = (event: unknown, value: LeadPayload | null) => {
    setSelectedLead(value);
    setIsLeadModalOpen(true);
  };

  const handleCancel = () => {
    setIsLeadModalOpen(false);
    setIsNewCustomerModalOpen(false);
  };

  const newCustomerData = (data: CreateCustomerWithoutPlanFromDashboard) => {
    setNewCustomerDetails(data);
  };

  const debouncedSetCustomerSearchTerm = useCallback(
    _.debounce(setCustomerSearchTerm, 800),
    []
  );

  const debouncedSetLeadSearchTerm = useCallback(
    _.debounce(setLeadSearchTerm, 800),
    []
  );
  const capitalizeFirstLetter = (string: string | null | undefined) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const formatName = (
    fname: string | null | undefined,
    lname: string | null | undefined
  ) => {
    return `${capitalizeFirstLetter(fname)} ${capitalizeFirstLetter(lname)}`;
  };
  return (
    <Accordion
      expanded={expanded === "TRANSFER_BOX"}
      onChange={handleChange("TRANSFER_BOX")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="#B27D3F" fontSize={18}>
          Transfer Box
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form
          id="TRANSFER_BOX_FORM_CUSTOMER"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Box display="flex" flexWrap="wrap" gap="0px 20px">
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography className="section__subtitle">Date*</Typography>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <DesktopDatePicker
                      {...field}
                      inputFormat="DD/MM/YYYY"
                      disablePast
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ minWidth: "350px", flexGrow: 1 }}
                        />
                      )}
                    />
                  )}
                  rules={{
                    required: "Field required",
                    validate: (value) =>
                      value === null ||
                      moment(value).isValid() ||
                      "Enter a valid Start Date",
                  }}
                />
              </LocalizationProvider>
              <p className={ContainersClasses.section__error__helper}>
                {errors?.startDate?.message}
              </p>
            </Box>

            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography className="section__subtitle">
                Select Plan*
              </Typography>
              <Controller
                name="customerProgram"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    sx={{ minWidth: "400px" }}
                    value={field.value || ""}
                    onChange={(event) => field.onChange(event.target.value)}
                  >
                    {activeCustomerPlans?.map((plan) => {
                      const planId = plan?._id ?? "";
                      return (
                        <MenuItem key={planId} value={planId}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <span>{plan?.program?.name?.EN}</span>
                            <div className="left-side-container">
                              <span>
                                {`(${moment(plan?.startDate).format(
                                  "DD/MM/YYYY"
                                )} - ${moment(plan?.expiryDate).format(
                                  "DD/MM/YYYY"
                                )})`}{" "}
                              </span>
                            </div>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
                rules={{ required: { message: "Field Required", value: true } }}
              />
              <p className={ContainersClasses.section__error__helper}>
                {errors?.customerProgram?.message}
              </p>
            </Box>

            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography className="section__subtitle">
                Select Customer Type*
              </Typography>
              <Controller
                name="customerType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    sx={{ minWidth: "350px" }}
                    value={field.value || ""}
                    onChange={(event) => {
                      const selectedCustomerType = event.target.value;
                      field.onChange(selectedCustomerType);
                      setCustomerSearchTerm(null);
                      setLeadSearchTerm(null);
                      setSelectedCustomer(null);
                      setSelectedLead(null);
                      setValue("customerId", "");
                    }}
                  >
                    <MenuItem value="CUSTOMER">Customer</MenuItem>
                    <MenuItem value="LEAD">Lead</MenuItem>
                    <MenuItem
                      onClick={() => setIsNewCustomerModalOpen(true)}
                      value="NEW_CUSTOMER"
                    >
                      New Customer
                    </MenuItem>
                  </Select>
                )}
                rules={{ required: { value: true, message: "Field Required" } }}
              />
              <p className={ContainersClasses.section__error__helper}>
                {errors?.customerType?.message}
              </p>
            </Box>

            <Box width="100%" marginTop={4}>
              {watchFormValues.customerType === "CUSTOMER" && (
                <Autocomplete
                  id="selectCustomer"
                  options={customers || []}
                  onChange={handleCustomerSelection}
                  value={selectedCustomer}
                  getOptionLabel={(option) =>
                    `${capitalizeFirstLetter(
                      option.fname
                    )} ${capitalizeFirstLetter(option.lname)}`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  loading={customerLoading}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      key={option._id}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        color="secondary"
                        checked={selected}
                        style={{ marginRight: "8px" }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          gap: "16px",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {" "}
                          Name: {formatName(option?.fname, option?.lname)}
                        </span>

                        <span> Email: {option.email}</span>
                        <span> Ph: {option.phone?.number}</span>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search customer"
                      label="Customer"
                      onChange={(e) =>
                        debouncedSetCustomerSearchTerm(e.target.value)
                      }
                    />
                  )}
                  filterOptions={(options, { inputValue }) => {
                    const normalizedInputValue = inputValue
                      .trim()
                      .replace(/\s+/g, " ")
                      .toLowerCase();
                    return options.filter((option) => {
                      const fullName = `${option.fname} ${option.lname}`
                        .replace(/\s+/g, " ")
                        .toLowerCase();
                      const phone = option.phone?.number ?? "";
                      return (
                        fullName.includes(normalizedInputValue) ||
                        phone.includes(normalizedInputValue)
                      );
                    });
                  }}
                />
              )}

              {watchFormValues.customerType === "LEAD" && (
                <Autocomplete
                  id="selectLead"
                  options={leads || []}
                  onChange={handleLeadSelection}
                  value={selectedLead}
                  getOptionLabel={(option) =>
                    `${option.fname} ${option.lname}`.replace(/\s+/g, " ")
                  }
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  loading={leadLoading}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      key={option._id}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        color="secondary"
                        checked={selected}
                        style={{ marginRight: "8px" }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          gap: "16px",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {" "}
                          Name: {formatName(option.fname, option.lname)}
                        </span>

                        <span> Email: {option.email}</span>
                        <span> Ph: {option.phone?.number}</span>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search lead"
                      label="Lead"
                      onChange={(e) =>
                        debouncedSetLeadSearchTerm(e.target.value)
                      }
                    />
                  )}
                  filterOptions={(options, { inputValue }) => {
                    const normalizedInputValue = inputValue
                      .trim()
                      .replace(/\s+/g, " ")
                      .toLowerCase();
                    return options.filter((option) => {
                      const fullName = `${option.fname} ${option.lname}`
                        .replace(/\s+/g, " ")
                        .toLowerCase();
                      const phone = option.phone?.number ?? "";
                      return (
                        fullName.includes(normalizedInputValue) ||
                        phone.includes(normalizedInputValue)
                      );
                    });
                  }}
                />
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            flexGrow={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={1}
          >
            <Button
              variant="contained"
              type="submit"
              form="TRANSFER_BOX_FORM_CUSTOMER"
              disabled={transferBoxLoading}
            >
              {transferBoxLoading && (
                <CircularProgress
                  size={20}
                  style={{ color: "white", marginRight: 6 }}
                />
              )}
              TRANSFER{" "}
            </Button>
          </Box>
        </form>

        {/* Lead to Customer Modal */}
        <Dialog
          open={isLeadModalOpen}
          onClose={handleCancel}
          fullWidth
          maxWidth="xl"
          PaperProps={{
            style: {
              minHeight: "70vh",
              maxWidth: "100%",
            },
          }}
        >
          <DialogContent>
            <ConvertCustomerModel
              lead={selectedLead}
              setLeadDetails={setLeadDetails}
              onCancelLeadModal={handleCancel}
            />
          </DialogContent>
        </Dialog>

        {/* New Customer Modal */}
        <Dialog
          open={isNewCustomerModalOpen}
          onClose={handleCancel}
          fullWidth
          maxWidth="xl"
          PaperProps={{
            style: {
              minHeight: "70vh",
              maxWidth: "100%",
            },
          }}
        >
          <DialogContent>
            <CreateNewCustomerModal
              newCustomerData={newCustomerData}
              onCancelNewCustomerModal={handleCancel}
              newCustomerDetails={newCustomerDetails}
            />
          </DialogContent>
        </Dialog>
      </AccordionDetails>
    </Accordion>
  );
};

export default TransferBox;
