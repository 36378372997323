import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useForm,
  useWatch,
  SubmitHandler,
  FormProvider,
  Controller,
} from "react-hook-form";
import clsx from "clsx";
import { useSnackbar } from "notistack";
//material
import {
  Box,
  TextField,
  Autocomplete,
  Chip,
  Button,
  CircularProgress,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Checkbox,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//styles
import {
  useStylesIngredient,
  Container,
} from "../../../styles/Ingredient__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
//components
import NutritionInput from "../../layout/commun/NutritionInput";
//queries and mutations
import {
  useEditIngredientMutation,
  useGetAllergenQuery,
  useGetIngredientByIdQuery,
  GetIngredientsDocument,
  GetAllergenWithPaginationDocument,
  AllergenGroupPayload,
  useGetAllBrandsQuery,
  useCreateBrandMutation,
  useRemoveBrandMutation,
  useUpdateBrandMutation,
} from "../../../graphql/types";
//types
import { IngredientProps } from "../Types";
import { ApolloError } from "@apollo/client";
// utils
import { filterListAllergens } from "../Utils";
import { getMessageError } from "../../Utils";
//icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Delete from "../../../public/icons/delete";
import AddCircle from "../../../public/icons/AddCircle";
//context
import { useAuth } from "../../../contextAPI";

const UpdateIngredients = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const idIngredient = params.id || null;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const auth = useAuth();

  //styles
  const IngredientClasses = useStylesIngredient();
  const ButtonClasses = useStylesButtons();
  const DialogClasses = useStylesDialog();
  const ContainersClasses = useStylesContainers();

  // States
  const [open, setOpen] = useState<boolean>(false);
  const [newBrandName, setNewBrandName] = useState("");
  const [idDeleteBrand, setIdDeleteBrand] = useState<string | null>(null);
  const [openDeleteBrand, setOpenDeleteBrand] = useState(false);
  const [editedBrandName, setEditedBrandName] = useState("");
  const [openEditBrandDialog, setOpenEditBrandDialog] = useState(false);
  const [updateselectedBrandId, setUpdateSelectedBrandId] = useState<
    string | null
  >(null);

  //queries and mutations
  const { data: dataIngredient } = useGetIngredientByIdQuery({
    variables: { ingredientId: idIngredient || "" },
    skip: !idIngredient,
    fetchPolicy: "no-cache",
  });

  const [CreateBrand, { loading: createBrandLoading }] =
    useCreateBrandMutation();
  const [updateBrand, { loading: updateBrandLoading }] =
    useUpdateBrandMutation();
  const [removeBrand, { loading: removeBrandLoading }] =
    useRemoveBrandMutation();
  const { data: allergensList } = useGetAllergenQuery();
  const [editIngredient, dataEditIngredient] = useEditIngredientMutation();

  const dataGetBrands = useGetAllBrandsQuery({});
  const brandlist = useMemo(
    () => dataGetBrands.data?.getAllBrands || [],
    [dataGetBrands]
  );

  //react-hook-form
  const methods = useForm<IngredientProps>({
    defaultValues: {
      name__english: "",
      name__arabic: "",
      nutritions: [],
      allergens: [],
      cost: "",
      Brand: null,
      countries: [],
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  const allergensListFiltered = filterListAllergens(
    (dataWatch.allergens || []) as AllergenGroupPayload[],
    allergensList
  );

  useEffect(() => {
    if (dataIngredient) {
      setValue(
        "name__english",
        dataIngredient?.getIngredientById?.name?.EN || ""
      );
      setValue(
        "name__arabic",
        dataIngredient?.getIngredientById?.name?.AR
          ? dataIngredient?.getIngredientById?.name?.AR
          : ""
      );
      setValue(
        "nutritions",
        dataIngredient?.getIngredientById?.nutritions
          ? dataIngredient?.getIngredientById?.nutritions.map((nutrition) => {
              return {
                quantity: nutrition.quantity.toString(),
                nutrition: nutrition.nutrition,
                name: nutrition.nutrition.name,
              };
            })
          : []
      );
      setValue(
        "allergens",
        dataIngredient?.getIngredientById
          ?.allergenGroups as AllergenGroupPayload[]
      );
      setValue("cost", dataIngredient?.getIngredientById?.cost || "");
      setValue("Brand", dataIngredient?.getIngredientById?.brand || null);
      setValue("countries", dataIngredient.getIngredientById.country || []);
    }
  }, [dataIngredient]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpenDeleteDialog = (brandId: string) => {
    setIdDeleteBrand(brandId);
    setOpenDeleteBrand(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteBrand(false);
  };

  const handleOpenEditDialog = (brandId: string, brandName: string) => {
    setEditedBrandName(brandName);
    setUpdateSelectedBrandId(brandId);
    setOpenEditBrandDialog(true);
  };

  const handleCloseEditDialog = () => {
    setEditedBrandName("");
    setUpdateSelectedBrandId(null);
    setOpenEditBrandDialog(false);
  };

  const handleCreateBrand = async () => {
    if (newBrandName) {
      try {
        await CreateBrand({
          variables: {
            Input: {
              name: {
                EN: newBrandName,
              },
            },
          },
          onCompleted: () => {
            dataGetBrands.refetch();
            enqueueSnackbar("Brand successfully added", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);

            setNewBrandName("");
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
    setOpen(false);
  };

  const handleUpdateBrand = async () => {
    if (updateselectedBrandId) {
      try {
        await updateBrand({
          variables: {
            Input: {
              id: updateselectedBrandId,
              name: {
                EN: editedBrandName,
              },
            },
          },
          onCompleted: () => {
            dataGetBrands.refetch();
            enqueueSnackbar("Brand successfully updated", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      } finally {
        handleCloseEditDialog();
      }
    }
  };

  const handleRemoveBrand = async () => {
    setOpenDeleteBrand(false);
    if (idDeleteBrand) {
      try {
        await removeBrand({
          variables: {
            id: idDeleteBrand,
          },
          onCompleted: () => {
            dataGetBrands.refetch();
            enqueueSnackbar("Brand successfully deleted", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setIdDeleteBrand(null);
            setTimeout(() => closeSnackbar(), 5000);
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
    setValue("Brand", null);
  };

  const onSubmit: SubmitHandler<IngredientProps> = async (data) => {
    if (idIngredient) {
      try {
        await editIngredient({
          variables: {
            input: {
              id: idIngredient,
              name: {
                AR: data.name__arabic,
                EN: data.name__english,
              },
              cost: data.cost,
              nutritions: data.nutritions.map((item) => {
                return {
                  nutritionId: item.nutrition?._id || "",
                  quantity: parseFloat(item.quantity),
                };
              }),
              allergenGroups: data.allergens.map((item) => {
                return item._id || "";
              }),
              brand: data.Brand?._id,
              country: data?.countries
                ?.map((el) => el?._id)
                .filter(Boolean) as string[],
            },
          },
          refetchQueries: [
            {
              query: GetIngredientsDocument,
              variables: { input: { page: 1, documentsPerPage: 10 } },
            },
            {
              query: GetAllergenWithPaginationDocument,
              variables: { input: { page: 1, documentsPerPage: 10 } },
            },
          ],
          onCompleted: () => {
            navigate("/food/ingredients/list_ingredients");
            enqueueSnackbar("Ingredient succuessfully updated", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
            setValue("Brand", {
              ...dataWatch.Brand,
              name: {
                EN: editedBrandName,
              },
            });
          },
        });
      } catch (error) {}
    }
  };

  //render
  return (
    <FormProvider {...methods}>
      <Container>
        <p className={IngredientClasses.ingredient__title}>Update Ingredient</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            className={IngredientClasses.ingredient__container}
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "50%",
              }}
            >
              <p className={IngredientClasses.ingredient__subtitle}>
                Ingredient Name*<span>(English)</span>
              </p>
              <TextField
                id="demo-helper-text-misaligned"
                placeholder="Ingredient Name English"
                sx={{
                  width: "100%",
                }}
                {...register("name__english", {
                  required: "Enter the ingredient english name",
                  pattern: {
                    value: /^[A-Za-z\s]+$/,
                    message: "Enter a valid ingredient english name",
                  },
                })}
              />
              <p className={IngredientClasses.ingredient__error__helper}>
                {errors?.name__english?.message}
              </p>
            </Box>
            <Box
              sx={{
                width: "50%",
              }}
            >
              <p className={IngredientClasses.ingredient__subtitle}>
                Ingredient Name <span>(Arabic)</span>
              </p>
              <TextField
                id="demo-helper-text-misaligned"
                placeholder="Ingredient Name Arabic"
                sx={{
                  width: "100%",
                }}
                {...register("name__arabic")}
              />
              <p className={IngredientClasses.ingredient__error__helper}>
                {errors?.name__arabic?.message}
              </p>
            </Box>

            {/* COUNTRIES */}
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>Countries</p>
              <Controller
                name="countries"
                rules={{
                  required: "Select Country",
                }}
                control={control}
                render={({
                  field: { onChange: Change, value, ref, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      multiple
                      ref={ref}
                      id="countries"
                      options={auth.countriesList ?? []}
                      value={dataWatch?.countries}
                      getOptionLabel={(option) => option.name?.EN || ""}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      filterSelectedOptions
                      onChange={(_, data) => Change(data)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            color="secondary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name?.EN}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Country" />
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name?.EN}
                            {...getTagProps({ index })}
                            disabled={
                              !!!auth.countriesList.find(
                                (el) => el.code === option.code
                              )
                            }
                          />
                        ))
                      }
                    />
                    <p className={ContainersClasses.section__error__helper}>
                      {error?.message}
                    </p>
                  </>
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "50%",
            }}
          >
            <p className={clsx(IngredientClasses.ingredient__subtitle)}>
              Cost*<span>(For 100g)</span>
            </p>
            <TextField
              id="demo-helper-text-misaligned"
              placeholder="Cost"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {auth.countryCurrency}
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
              }}
              {...register("cost", {
                required: "Cost is Mandatory",
              })}
            />
            <p className={clsx(IngredientClasses.ingredient__error__helper)}>
              {errors?.cost?.message}
            </p>
          </Box>
          <NutritionInput updateMode={true} />
          <Box
            sx={{
              width: "50%",
            }}
          >
            <p className={clsx(IngredientClasses.ingredient__subtitle)}>
              Allergen Categories
            </p>
            <Controller
              name="allergens"
              control={control}
              render={({
                field: { onChange: Change, value, ref, ...rest },
              }) => (
                <>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    sx={{
                      width: "200%",
                    }}
                    value={dataWatch.allergens}
                    options={allergensListFiltered}
                    getOptionLabel={(option) => option?.name?.EN || ""}
                    disableCloseOnSelect
                    renderTags={() => null}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(_, data) => Change(data)}
                    {...rest}
                  />
                  <Box
                    mt={3}
                    sx={{
                      "& > :not(:last-child)": {
                        marginRight: 1,
                        marginTop: 1,
                      },
                      "& > *": { marginBottom: 1, marginTop: 1 },
                    }}
                  >
                    <Box
                      className={clsx(
                        IngredientClasses.ingredient__container__tags
                      )}
                    >
                      {dataWatch?.allergens?.map((v, i) => (
                        <Chip
                          key={v._id}
                          label={v.name?.EN}
                          onDelete={() =>
                            setValue(
                              "allergens",
                              dataWatch?.allergens?.filter(
                                (day) => day._id !== v._id
                              ) as AllergenGroupPayload[]
                            )
                          }
                          deleteIcon={
                            <CloseSharpIcon
                              className={
                                IngredientClasses.ingredient__tags__icon
                              }
                            />
                          }
                          className={IngredientClasses.ingredient__tags}
                        />
                      ))}
                    </Box>
                  </Box>
                </>
              )}
            />
          </Box>
          <Box
            sx={{
              width: "102%",
            }}
          >
            <p className={clsx(IngredientClasses.ingredient__subtitle)}>
              Brand
            </p>
            <Controller
              name="Brand"
              control={control}
              render={({ field: { onChange: Change } }) => (
                <>
                  <div style={{ display: "flex" }}>
                    <Autocomplete
                      id="brand"
                      sx={{
                        width: "200%",
                      }}
                      value={dataWatch?.Brand}
                      options={brandlist}
                      getOptionLabel={(option) => option?.name?.EN || ""}
                      filterSelectedOptions
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(_, data) => Change(data)}
                      renderOption={(props, option, state) => (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingLeft: "40px",
                            paddingRight: "40px",
                          }}
                        >
                          <span style={{ marginTop: "12px" }}>
                            {option?.name?.EN}
                          </span>
                          <div>
                            <IconButton
                              onClick={() =>
                                handleOpenEditDialog(
                                  option?._id || "",
                                  option?.name?.EN || ""
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>

                            <IconButton
                              onClick={() =>
                                option._id && handleOpenDeleteDialog(option._id)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </li>
                      )}
                    />

                    <Button
                      startIcon={<AddCircle />}
                      className={ButtonClasses.button_plus_add_new}
                      onClick={handleClickOpen}
                    ></Button>
                  </div>
                </>
              )}
            />
            <Dialog
              open={open}
              onClose={handleCreateBrand}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.dialog_title}>
                Add new brand
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  id="name"
                  label="Enter new brand"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={newBrandName}
                  onChange={(e) => setNewBrandName(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCreateBrand}>Cancel</Button>
                <Button
                  onClick={handleCreateBrand}
                  variant="contained"
                  className={ButtonClasses.button_submit_form}
                  disabled={createBrandLoading}
                >
                  {createBrandLoading && (
                    <CircularProgress
                      size={20}
                      style={{ marginRight: 10, color: "white" }}
                    />
                  )}
                  Add
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDeleteBrand}
              onClose={handleRemoveBrand}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.alert_dialog_title}>
                <Delete />
                <span className="alert_dialog_title_text">Delete Brand ?</span>
              </DialogTitle>
              <DialogContent className={DialogClasses.alert_dialog_content}>
                <DialogContentText>
                  Are you sure you want to delete this Brand ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={DialogClasses.alert_dialog_actions}>
                <Button
                  className={ButtonClasses.GreyButton}
                  onClick={handleCloseDelete}
                >
                  Cancel
                </Button>
                <Button
                  className={ButtonClasses.RedButton}
                  onClick={handleRemoveBrand}
                  color="primary"
                  autoFocus
                  disabled={removeBrandLoading}
                >
                  {removeBrandLoading && (
                    <CircularProgress
                      size={20}
                      style={{ marginRight: 10, color: "white" }}
                    />
                  )}
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openEditBrandDialog}
              onClose={handleCloseEditDialog}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.dialog_title}>
                Edit Brand
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  id="editedBrandName"
                  label="Enter new brand name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={editedBrandName}
                  onChange={(e) => setEditedBrandName(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEditDialog}>Cancel</Button>
                <Button
                  onClick={handleUpdateBrand}
                  variant="contained"
                  className={ButtonClasses.button_submit_form}
                  disabled={updateBrandLoading}
                >
                  {updateBrandLoading && (
                    <CircularProgress
                      size={20}
                      style={{ marginRight: 10, color: "white" }}
                    />
                  )}
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <Box
            sx={{
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
            className={clsx(IngredientClasses.ingredient__container)}
          >
            <Button
              variant="outlined"
              size="large"
              onClick={() => navigate("/food/ingredients/list_ingredients")}
              className={clsx(ButtonClasses.button_cancel_form)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={ButtonClasses.button_submit_form}
              type="submit"
              disabled={dataEditIngredient.loading}
            >
              {dataEditIngredient.loading && (
                <CircularProgress
                  size={20}
                  style={{ marginRight: 10, color: "white" }}
                />
              )}
              Update Ingredient
            </Button>
          </Box>
        </form>
      </Container>
    </FormProvider>
  );
};

export default UpdateIngredients;
