import { Controller, useFormContext } from "react-hook-form";
//material ui
import {
  Box,
  TextField,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Select,
} from "@mui/material";
//staticData
import { countryList, getCountryFromCallingCode } from "./CountryList";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
//Types
import { PhoneInputProps } from "./Types";
//utils
import { useCountryPrefix } from "../../../utils/Hooks/useCountryPrefix";

const PhoneInput: React.FC<PhoneInputProps> = (props) => {
  const {
    labelFormNumber,
    labelFormPrefix,
    placeholder,
    renderMessageError,
    size,
    stylePhoneNumber,
    loading,
    showErrorMessage = true,
  } = props;

  //styles
  const ContainersClasses = useStylesContainers();

  //react-hooks
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  //functions
  const onChange = (event: SelectChangeEvent) => {
    const callingCode = event.target.value as string;
    const country = getCountryFromCallingCode(callingCode);
    if (country) {
      setValue(labelFormPrefix, country.callingCode);
      setValue(labelFormNumber, "");
    }
  };
  const countryItem = useCountryPrefix();

  //render
  const renderOptions = () => {
    return countryList.map((item, index) => {
      return (
        <MenuItem key={index} value={item.callingCode}>
          <Box display="flex" alignItems="center">
            <img
              alt="country"
              src={item.flag}
              style={{ width: 22, height: 18, marginRight: 8 }}
            />
            +{item.callingCode}
          </Box>
        </MenuItem>
      );
    });
  };
  const renderError = (errors: any) => {
    if (renderMessageError) {
      return renderMessageError(errors);
    }
    return errors?.[labelFormNumber]?.message;
  };

  return (
    <>
      <Box display="flex" width="100%">
        <Controller
          name={labelFormPrefix}
          control={control}
          defaultValue={countryItem?.callingCode}
          render={({ field: { ref, value, ...rest } }) => (
            <FormControl
              style={{ minWidth: 120, flex: 1 }}
              size={size || "medium"}
              disabled={loading}
            >
              <Select {...rest} id="prefix" value={value} onChange={onChange}>
                {renderOptions()}
              </Select>
            </FormControl>
          )}
        />
        <TextField
          size={size || "medium"}
          placeholder={placeholder}
          id={labelFormNumber}
          style={{
            marginLeft: 8,
            minWidth: 160,
            flex: 5,
            ...stylePhoneNumber,
          }}
          {...register(labelFormNumber, {
            required: "Phone Number is Mandatory",
            pattern: {
              value: /^\d*$/,
              message: "Enter a valid phone number",
            },
          })}
        />
      </Box>
      {showErrorMessage && (
        <p className={ContainersClasses.section__error__helper}>
          {renderError(errors)}
        </p>
      )}
    </>
  );
};

export default PhoneInput;
