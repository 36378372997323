import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "User",
    accessor: "name",
  },
  {
    header: "Email",
    accessor: "email",
  },
  {
    header: "Phone",
    accessor: "phone",
  },
  {
    header: "Creation Date",
    accessor: "date",
  },
  {
    header: "Role",
    accessor: "role",
  },
  {
    header: "Countries",
    accessor: "countries",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

export const roleTableColumns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Role",
    accessor: "role",
  },
  {
    header: "Creation Date",
    accessor: "date",
  },
  {
    header: "No Users",
    accessor: "NumberUsers",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

export const Roles = [
  "ADMIN",
  "SUPPORT",
  "SALES",
  "SUPER_ADMIN",
  "MARKETING_TEAM",
  "ACCOUNTS",
  "CHEF",
  "TEAM_LEAD",
  "PACKER_SUPERVISOR",
];
export const PermissionsList = [
  {
    categoryName: "Dashboard",
    categoryPermissions: [
      {
        id: "DASHBOARD_HOME",
        name: "Home",
        isChecked: false,
      },
      {
        id: "DASHBOARD_EXPIRING_CUSTOMER",
        name: "Expiring Customers",
        isChecked: false,
      },
      {
        id: "DASHBOARD_SWITCHED_CUSTOMER",
        name: "Switching Customers",
        isChecked: false,
      },
      {
        id: "DASHBOARD_FIRSTBOX_DELIVERED",
        name: "First Box Delivered",
        isChecked: false,
      },
      {
        id: "DASHBOARD_UNFROZEN_CUSTOMER",
        name: "Unfrozen Customers",
        isChecked: false,
      },
      {
        id: "DASHBOARD_RENEWED_CUSTOMER",
        name: "Renewed Customers",
        isChecked: false,
      },
      {
        id: "DASHBOARD_SCHEDULED_RENEWED",
        name: "Renewing customer -cash on delivery",
        isChecked: false,
      },
      {
        id: "DASHBOARD_TOTAL_CUSTOMER",
        name: "Total Customers",
        isChecked: false,
      },
      {
        id: "DASHBOARD_TOTAL_CUSTOMER_REPORT",
        name: "Stats Report",
        isChecked: false,
      },
      {
        id: "DASHBOARD_SCHEDULED_PAYMENT",
        name: "New customer -cash on delivery",
        isChecked: false,
      },
      {
        id: "DASHBOARD_PENDING_PAYMENT",
        name: "Pending Payment",
        isChecked: false,
      },
      {
        id: "DASHBOARD_PROCESSING_PAYMENT",
        name: "Activated Payments",
        isChecked: false,
      },
      {
        id: "DASHBOARD_BIRTHDAYS",
        name: "Birthdays",
        isChecked: false,
      },
      {
        id: "DASHBOARD_NEW_SIGNED_UP_CUSTOMERS",
        name: "New Signed up Customers",
        isChecked: false,
      },
      {
        id: "DASHBOARD_DEACTIVATED_CUSTOMERS",
        name: "Deactivated Customers",
        isChecked: false,
      },
      {
        id: "DASHBOARD_BLACKLISTED_CUSTOMERS",
        name: "BlackListed Customers",
        isChecked: false,
      },
      {
        id: "DASHBOARD_DRIVER_BLACKLISTED_CUSTOMERS",
        name: "BlackListed Drivers",
        isChecked: false,
      },
      {
        id: "ADMIN_CONVERT_LIST",
        name: "Converted Leads",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_ROLES_LIST_SALESANDSUPPORTS",
        name: "Sales-Support filter in Converted Leads",
        isChecked: false,
      },
      {
        id: "ADMIN_CONVERTED_AMMOUNT",
        name: "Admin-converted-Amount",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_REMINDER_LIST",
        name: "Reminders",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_REMINDER_CREATE",
        name: "Reminder Creation",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_REMINDER_DELETE",
        name: "Reminder Delete",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_REMINDER_UPDATE",
        name: "Reminder Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_REMINDER_CLOSE",
        name: "Reminder close",
        isChecked: false,
      },
    ],
  },

  {
    categoryName: "Food",
    categoryPermissions: [
      {
        id: "FOOD_INGREDIENTS_LIST",
        name: "Ingredients List",
        isChecked: false,
      },
      {
        id: "FOOD_INGREDIENTS_DETAILS",
        name: "Ingredient Details",
        isChecked: false,
      },
      {
        id: "FOOD_INGREDIENTS_CREATE",
        name: "Ingredient Creation",
        isChecked: false,
      },
      {
        id: "FOOD_INGREDIENTS_UPDATE",
        name: "Ingredients Update",
        isChecked: false,
      },
      {
        id: "FOOD_INGREDIENTS_DELETE",
        name: "Ingredients Delete",
        isChecked: false,
      },
      {
        id: "FOOD_BRAND_LIST",
        name: "Brand List",
        isChecked: false,
      },
      {
        id: "FOOD_BRAND_DETAILS",
        name: "Brand Details",
        isChecked: false,
      },
      {
        id: "FOOD_BRAND_CREATE",
        name: "Brand Creation",
        isChecked: false,
      },
      {
        id: "FOOD_BRAND_UPDATE",
        name: "Brand Update",
        isChecked: false,
      },
      {
        id: "FOOD_BRAND_DELETE",
        name: "Brand Delete",
        isChecked: false,
      },
      {
        id: "FOOD_ALLERGENS_LIST",
        name: "Allergens List",
        isChecked: false,
      },
      {
        id: "FOOD_ALLERGENS_DETAILS",
        name: "Allergens Details",
        isChecked: false,
      },
      {
        id: "FOOD_ALLERGENS_CREATE",
        name: "Allergens Creation",
        isChecked: false,
      },
      {
        id: "FOOD_ALLERGENS_UPDATE",
        name: "Allergens Update",
        isChecked: false,
      },
      {
        id: "FOOD_ALLERGENS_DELETE",
        name: "Allergens Delete",
        isChecked: false,
      },
      {
        id: "FOOD_DISHS_LIST",
        name: "Dishes List",
        isChecked: false,
      },
      {
        id: "FOOD_DISHS_DETAILS",
        name: "Dishes Details",
        isChecked: false,
      },
      {
        id: "FOOD_DISHS_CREATE",
        name: "Dishes Creation",
        isChecked: false,
      },
      {
        id: "FOOD_DISHS_UPDATE",
        name: "Dishes Update",
        isChecked: false,
      },
      {
        id: "FOOD_DISHS_DELETE",
        name: "Dishes Delete",
        isChecked: false,
      },
      {
        id: "FOOD_PROGRAMS_LIST",
        name: "Programs List",
        isChecked: false,
      },
      {
        id: "FOOD_PROGRAMS_DETAILS",
        name: "Programs Details",
        isChecked: false,
      },
      {
        id: "FOOD_PROGRAMS_CREATE",
        name: "Programs Creation",
        isChecked: false,
      },
      {
        id: "FOOD_PROGRAMS_UPDATE",
        name: "Programs Update",
        isChecked: false,
      },
      {
        id: "FOOD_PROGRAMS_DELETE",
        name: "Programs Delete",
        isChecked: false,
      },
      {
        id: "FOOD_PROGRAMSMENU_MONTHS_LIST",
        name: "Program Menu List",
        isChecked: false,
      },
      {
        id: "FOOD_PROGRAMSMENU_DETAILS",
        name: "Program Menu Details",
        isChecked: false,
      },
      {
        id: "FOOD_PROGRAMSMENU_CREATE",
        name: "Program Menu Creation",
        isChecked: false,
      },
      {
        id: "FOOD_PROGRAMSMENU_PUBLISH",
        name: "Program Menu Publish",
        isChecked: false,
      },
    ],
  },

  {
    categoryName: "Admin",
    categoryPermissions: [
      {
        id: "ADMIN_LEADS_LIST",
        name: "Leads List",
        isChecked: false,
      },
      {
        id: "ADMIN_LEADS_DETAILS",
        name: "Leads Details",
        isChecked: false,
      },
      {
        id: "ADMIN_LEADS_BY_STATUS",
        name: "Leads Stats",
        isChecked: false,
      },
      {
        id: "ADMIN_LEADS_CREATE",
        name: "Leads Creation",
        isChecked: false,
      },
      {
        id: "ADMIN_LEADS_CONVERT",
        name: "Leads Conversion",
        isChecked: false,
      },
      {
        id: "ADMIN_LEADS_UPDATE",
        name: "Leads Update",
        isChecked: false,
      },
      {
        id: "ADMIN_LEADS_DELETE",
        name: "Leads Delete",
        isChecked: false,
      },
      {
        id: "ADMIN_CUSTOMERS_LIST",
        name: "Customers List",
        isChecked: false,
      },
      {
        id: "ADMIN_CUSTOMERS_CREATE",
        name: "Customer Creation",
        isChecked: false,
      },
      {
        id: "ADMIN_DRIVERS_LIST",
        name: "Drivers List",
        isChecked: false,
      },
      {
        id: "ADMIN_DRIVERS_CREATE",
        name: "Drivers Creation",
        isChecked: false,
      },
      {
        id: "ADMIN_DRIVERS_UPDATE",
        name: "Drivers Update",
        isChecked: false,
      },
      {
        id: "ADMIN_DRIVERS_DELETE",
        name: "Drivers Delete",
        isChecked: false,
      },
      {
        id: "ADMIN_DELIVERYAREA_LIST",
        name: "Delivery Area List",
        isChecked: false,
      },
      {
        id: "ADMIN_DELIVERYAREA_CREATE",
        name: "Delivery Area Creation",
        isChecked: false,
      },
      {
        id: "ADMIN_DELIVERYAREA_UPDATE",
        name: "Delivery Area Update",
        isChecked: false,
      },
      {
        id: "ADMIN_DELIVERYAREA_DELETE",
        name: "Delivery Area Delete",
        isChecked: false,
      },
      {
        id: "ADMIN_AREA_LIST",
        name: "Area List",
        isChecked: false,
      },
      {
        id: "ADMIN_AREA_CREATE",
        name: "Area Creation",
        isChecked: false,
      },
      {
        id: "ADMIN_AREA_UPDATE",
        name: "Area Update",
        isChecked: false,
      },
      {
        id: "ADMIN_AREA_DELETE",
        name: "Area Delete",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_BIRTHDAY_LABEL",
        name: "Birthday Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_LABEL",
        name: "Label Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_PACKAGING",
        name: "Packaging Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_BOXES",
        name: "Boxes Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_DRIVERS",
        name: "Drivers Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_GROCERY",
        name: "Grocery Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_DISHS",
        name: "Dishs Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_BULK_COOKING",
        name: "Bulk Cooking Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_DISH_SELECTIONS",
        name: "Dish Selections Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_ACTIVE_CUSTOMERS",
        name: "Active Customers Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_OUT_OF_RANGE",
        name: "Out of Range Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_TRAINER",
        name: "Trainers Report",
        isChecked: false,
      },
      {
        id: "ADMIN_REPORTS_SALES",
        name: "Sales Report",
        isChecked: false,
      },
      {
        id: "ADMIN_TRANSACRION_LIST",
        name: "Transactions List",
        isChecked: false,
      },
      {
        id: "UPDATE_CUSTOMER",
        name: "Customer Update",
        isChecked: false,
      },
      {
        id: "GET_CUSTOMER_INFO",
        name: "Customer Info",
        isChecked: false,
      },
      {
        id: "ADMIN_TRANSACRION_CHANGE_METHOD",
        name: "Change Payment Method",
        isChecked: false,
      },
      {
        id: "ADD_ASSIGNED_TO_TOCUSTOMERS",
        name: "Add Assigned To Customers",
        isChecked: false,
      },
      {
        id: "ADMIN_PACKER_LIST",
        name: "Packing Agents list",
        isChecked: false,
      },
      {
        id: "ADMIN_PACKER_CREATE",
        name: "Packing Agents Creation",
        isChecked: false,
      },
      {
        id: "ADMIN_PACKER_UPDATE",
        name: "Packing Agents Update",
        isChecked: false,
      },
      {
        id: "ADMIN_PACKER_DELETE",
        name: "Packing Agents Delete",
        isChecked: false,
      },
      {
        id: "ADMIN_PACKER_GROUP_LIST",
        name: "Packing Groups list",
        isChecked: false,
      },
      {
        id: "ADMIN_PACKER_GROUP_CREATE",
        name: "Packing Groups Creation",
        isChecked: false,
      },
      {
        id: "ADMIN_PACKER_GROUP_UPDATE",
        name: "Packing Groups Update",
        isChecked: false,
      },
      {
        id: "ADMIN_PACKER_GROUP_DELETE",
        name: "Packing Groups Delete",
        isChecked: false,
      },
      {
        id: "ADMIN_CHEF_LIST",
        name: "Chef List",
        isChecked: false,
      },
      {
        id: "ADMIN_CHEF_DETAILS",
        name: "Chef Details",
        isChecked: false,
      },
      {
        id: "ADMIN_CHEF_CREATE",
        name: "Chef Creation ",
        isChecked: false,
      },
      {
        id: "ADMIN_CHEF_UPDATE",
        name: "Chef Update",
        isChecked: false,
      },
      {
        id: "ADMIN_CHEF_DELETE",
        name: "Chef Delete",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_COMPLAIN_LIST",
        name: "Complaints List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_COMPLAIN_CREATE",
        name: "Complaints creation",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_COMPLAIN_UPDATE",
        name: "Complaints update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_COMPLAIN_DELETE",
        name: "Complaints Delete",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_COMPLAIN_UPDATE_STATUS",
        name: "Complaints status update",
        isChecked: false,
      },
    ],
  },

  {
    categoryName: "Data Management",
    categoryPermissions: [
      {
        id: "MANAGEMENT_PROMOCODE_LIST",
        name: "Promocodes List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_PROMOCODE_CREATE",
        name: "Promocodes Creation",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_PROMOCODE_UPDATE",
        name: "Promocodes Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_PROMOCODE_DELETE",
        name: "Promocodes Delete",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_CUSTOMER_PROMOCODE_LIST",
        name: "Customer Promocode List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_CUSTOMER_PROMOCODE_UPDATE",
        name: "Customer Promocode Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_TRAINER_LIST",
        name: "Trainers List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_TRAINER_DETAILS",
        name: "Trainer Details",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_TRAINER_CREATE",
        name: "Trainer Creation",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_TRAINER_UPDATE",
        name: "Trainer Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_TRAINER_DELETE",
        name: "Trainer Delete",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_GENERAL_FREEZ_LIST",
        name: "General Freeze List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_GENERAL_FREEZ_PUBLISH",
        name: "General Freeze Publish",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_GENERAL_FREEZ_CREATE",
        name: "General Freeze Creation",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_GENERAL_FREEZ_UPDATE",
        name: "General Freeze Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_GENERAL_FREEZ_DELETE",
        name: "General Freeze Delete",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_ROLES_LIST",
        name: "Roles List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_ROLES_UPDATE",
        name: "Roles Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_ROLES_LIST_ADMIN",
        name: "Users List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_ROLES_UPDATE_ADMIN",
        name: "Users Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_ROLES_CREATE_ADMIN",
        name: "Users Creation",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_NOTIFICATION_LIST",
        name: "Notifications List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_NOTIFICATION_CREATE",
        name: "Notifications Creation",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_NOTIFICATION_DELETE",
        name: "Notifications Delete",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_NOTIFICATION_UPDATE",
        name: "Notifications Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_TEMPLATE_LIST",
        name: "Notification Templates List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_TEMPLATE_CREATE",
        name: "Notification Templates Creation",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_TEMPLATE_DELETE",
        name: "Notification Template Delete",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_TEMPLATE_EDIT",
        name: "Notification Template Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_GET_COUNTRY_LIST",
        name: "Countries List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_CREATE_COUNTRY",
        name: "Countries Creation",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_UPDATE_COUNTRY",
        name: "Countries Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_DELETE_COUNTRY",
        name: "Countries Delete",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_GET_DELIVERY_LIST",
        name: "Driver App Dash",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_GET_PACKED_LIST",
        name: "Packing App Dash",
        isChecked: false,
      },
      {
        id: "ADMIN_GET_ALL_TASKS",
        name: "Kitchen App Dash",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_REWARD_LIST",
        name: "Reward List",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_REWARD_CREATE",
        name: "Reward Create",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_REWARD_EDIT",
        name: "Reward Update",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_REWARD_DELETE",
        name: "Reward Delete",
        isChecked: false,
      },
      {
        id: "MANAGEMENT_REWARDREDEMPTION_CREATE",
        name: "Make Rewards Redemption",
        isChecked: false,
      },
    ],
  },
];
