import { useCallback, useState } from "react";
import _ from "lodash";
import moment from "moment";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//styles
import {
  HeaderSearch,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../styles/Navigation__styles";
//icons
import { FiSearch } from "react-icons/fi";
//queries&mutations
import { useGetIngredientsTrackingQuery } from "../../../graphql/types";

type Props = {
  date?: Date;
};
const IngredientTracking: React.FC<Props> = ({ date }) => {
  //state
  const [search, setSearch] = useState<string>("");

  const debounced = useCallback(
    _.debounce(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearch(event.target.value);
      },
      800
    ),
    []
  );

  const ingredientTrackingDetails = useGetIngredientsTrackingQuery({
    variables: { date: date?.toDateString() || "", query: search },
    fetchPolicy: "no-cache",
  });

  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom={2}
        borderColor="lightgrey"
        paddingBottom={3}
      >
        <Typography fontSize={20}>Ingredient Tracking</Typography>
        <Typography>{moment(date).format("Do MMMM YYYY, dddd")}</Typography>
      </Box>
      <Box width="30%" marginTop={5} marginBottom={5}>
        <HeaderSearch sx={{ borderRadius: 50 }}>
          <SearchIconWrapper>
            <FiSearch />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search by Name"
            inputProps={{ "aria-label": "search" }}
            onChange={(event) => debounced(event)}
          />
        </HeaderSearch>
      </Box>
      {ingredientTrackingDetails.data?.getIngredientsTracking.map((el) => {
        return (
          <Box marginTop={2}>
            <Typography fontSize={20} marginLeft={2}>
              {el.name}
            </Typography>
            {el.details.map((element) => {
              return (
                <Accordion style={{ marginTop: 10 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ color: "black", background: "lightgrey" }}
                  >
                    {`${element?.dishName?.EN} (${element.dishCode})`}
                  </AccordionSummary>
                  <AccordionDetails>
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tr>
                        <th
                          style={{
                            border: "1px solid black",
                            padding: "8px",
                            color: "black",
                          }}
                        >
                          Ingredients
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            padding: "8px",
                            color: "black",
                          }}
                        >
                          Quantity Needed
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            padding: "8px",
                            color: "black",
                          }}
                        >
                          Recieved
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            padding: "8px",
                            color: "black",
                          }}
                        >
                          Excess
                        </th>
                      </tr>
                      {element.ingredients.map((elem) => {
                        return (
                          <tr>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                                color: "black",
                              }}
                            >
                              {elem.ingredient.EN}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                                color: "black",
                              }}
                            >
                              {elem.quantity}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                                color: "black",
                              }}
                            >
                              {elem.received}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                                color: "black",
                              }}
                            >
                              {elem.excess}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        );
      })}
    </div>
  );
};

export default IngredientTracking;
