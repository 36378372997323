import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  useForm,
  useWatch,
  SubmitHandler,
  FormProvider,
  Controller,
} from "react-hook-form";
import clsx from "clsx";
import { useSnackbar } from "notistack";
//material
import {
  Box,
  TextField,
  Autocomplete,
  Chip,
  Button,
  CircularProgress,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Checkbox,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Delete from "../../../public/icons/delete";
import AddCircle from "../../../public/icons/AddCircle";
//styles
import {
  useStylesIngredient,
  Container,
  ContainerForm,
  ContainerLeft,
  ContainerRight,
} from "../../../styles/Ingredient__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
// mutations and queries
import {
  useCreateIngredientMutation,
  useGetAllergenQuery,
  GetIngredientsDocument,
  GetAllergenWithPaginationDocument,
  useGetNutritionsQuery,
  NutritionInfoInput,
  AllergenGroupPayload,
  useGetAllBrandsQuery,
  useCreateBrandMutation,
  useRemoveBrandMutation,
  useUpdateBrandMutation,
} from "../../../graphql/types";
//types
import { IngredientProps } from "../Types";
import { ApolloError } from "@apollo/client";
// utils
import { getMessageError } from "../../Utils";
//context
import { useAuth } from "../../../contextAPI";

const AddIngredients = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const auth = useAuth();

  //styles
  const IngredientClasses = useStylesIngredient();
  const ButtonClasses = useStylesButtons();
  const DialogClasses = useStylesDialog();
  const ContainersClasses = useStylesContainers();

  // States
  const [open, setOpen] = useState<boolean>(false);
  const [newBrandName, setNewBrandName] = useState("");
  const [idDeleteBrand, setIdDeleteBrand] = useState<string | null>(null);
  const [openDeleteBrand, setOpenDeleteBrand] = useState(false);
  const [editedBrandName, setEditedBrandName] = useState("");
  const [openEditBrandDialog, setOpenEditBrandDialog] = useState(false);
  const [updateselectedBrandId, setUpdateSelectedBrandId] = useState<
    string | null
  >(null);

  // queries and mutations
  const [createIngredient, { loading }] = useCreateIngredientMutation();

  const { data: allergensList } = useGetAllergenQuery();

  const dataGetNutritions = useGetNutritionsQuery();

  const nutrutionsList = useMemo(
    () => dataGetNutritions.data?.getNutritions || [],
    [dataGetNutritions]
  );

  const [CreateBrand, { loading: createBrandLoading }] =
    useCreateBrandMutation();
  const [updateBrand, { loading: updateBrandLoading }] =
    useUpdateBrandMutation();
  const [removeBrand, { loading: removeBrandLoading }] =
    useRemoveBrandMutation();
  const dataGetBrands = useGetAllBrandsQuery({});
  const brandlist = useMemo(
    () => dataGetBrands.data?.getAllBrands || [],
    [dataGetBrands]
  );

  //react-hook-form
  const methods = useForm<IngredientProps>({
    defaultValues: {
      name__english: "",
      name__arabic: "",
      nutritions: [
        {
          nutrition: null,
          quantity: "",
        },
      ],
      Energy: "",
      Protein: "",
      Carbohydrate: "",
      fiber: "",
      fat: "",
      satFat: "",
      Sodium: "",
      Potassium: "",
      Calcium: "",
      Cholesterol: "",
      allergens: [],
      Brand: null,
      cost: "",
      countries: [],
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  //functions
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpenDeleteDialog = (brandId: string) => {
    setIdDeleteBrand(brandId);
    setOpenDeleteBrand(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteBrand(false);
  };

  const handleOpenEditDialog = (brandId: string, brandName: string) => {
    setEditedBrandName(brandName);
    setUpdateSelectedBrandId(brandId);
    setOpenEditBrandDialog(true);
  };

  const handleCloseEditDialog = () => {
    setEditedBrandName("");
    setUpdateSelectedBrandId(null);
    setOpenEditBrandDialog(false);
  };

  const handleCreateBrand = async () => {
    if (newBrandName) {
      try {
        await CreateBrand({
          variables: {
            Input: {
              name: {
                EN: newBrandName,
              },
            },
          },
          onCompleted: () => {
            dataGetBrands.refetch();
            enqueueSnackbar("Brand successfully added", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);

            setNewBrandName("");
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
    setOpen(false);
  };

  const handleUpdateBrand = async () => {
    if (updateselectedBrandId) {
      try {
        await updateBrand({
          variables: {
            Input: {
              id: updateselectedBrandId,
              name: {
                EN: editedBrandName,
              },
            },
          },
          onCompleted: () => {
            dataGetBrands.refetch();
            enqueueSnackbar("Brand successfully updated", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
            setValue("Brand", {
              ...dataWatch.Brand,
              name: {
                EN: editedBrandName,
              },
            });
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      } finally {
        handleCloseEditDialog();
      }
    }
  };

  const handleRemoveBrand = async () => {
    setOpenDeleteBrand(false);
    if (idDeleteBrand) {
      try {
        await removeBrand({
          variables: {
            id: idDeleteBrand,
          },
          onCompleted: () => {
            dataGetBrands.refetch();
            enqueueSnackbar("Brand successfully deleted", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setIdDeleteBrand(null);
            setTimeout(() => closeSnackbar(), 5000);
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
    setValue("Brand", null);
  };

  const onSubmit: SubmitHandler<IngredientProps> = async (data) => {
    try {
      const nutritionsList: NutritionInfoInput[] = [];
      nutrutionsList.map((el) => {
        if (el.name === "Energy") {
          nutritionsList.push({ nutritionId: el._id, quantity: +data.Energy });
        }
        if (el.name === "Protein") {
          nutritionsList.push({ nutritionId: el._id, quantity: +data.Protein });
        }
        if (el.name === "Carbohydrate") {
          nutritionsList.push({
            nutritionId: el._id,
            quantity: +data.Carbohydrate,
          });
        }
        if (el.name === "Sodium") {
          nutritionsList.push({ nutritionId: el._id, quantity: +data.Sodium });
        }
        if (el.name === "Potassium") {
          nutritionsList.push({
            nutritionId: el._id,
            quantity: +data.Potassium,
          });
        }
        if (el.name === "Calcium") {
          nutritionsList.push({ nutritionId: el._id, quantity: +data.Calcium });
        }
        if (el.name === "Cholesterol") {
          nutritionsList.push({
            nutritionId: el._id,
            quantity: +data.Cholesterol,
          });
        }
        if (el.name === "Fiber, total dietary") {
          nutritionsList.push({ nutritionId: el._id, quantity: +data.fiber });
        }
        if (el.name === "Total Fat") {
          nutritionsList.push({ nutritionId: el._id, quantity: +data.fat });
        }
        if (el.name === "Fatty acids, total saturated") {
          nutritionsList.push({ nutritionId: el._id, quantity: +data.satFat });
        }
      });
      await createIngredient({
        variables: {
          input: {
            name: {
              AR: data.name__arabic,
              EN: data.name__english,
            },
            nutritions: nutritionsList,
            allergenGroups: data.allergens.map((n) => {
              return n._id || "";
            }),
            cost: data.cost,
            brand: data.Brand?._id,
            country: data?.countries
              ?.map((el) => el?._id)
              .filter(Boolean) as string[],
          },
        },
        refetchQueries: [
          {
            query: GetIngredientsDocument,
            variables: { input: { page: 1, documentsPerPage: 10 } },
          },
          {
            query: GetAllergenWithPaginationDocument,
            variables: { input: { page: 1, documentsPerPage: 10 } },
          },
        ],
        onCompleted: () => {
          navigate("/food/ingredients/list_ingredients");
          enqueueSnackbar("Ingredient succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
        onError(error) {
          console.log("creating ingredient failed", error);
          enqueueSnackbar("failed to add ingredient", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (error) {
      console.log("#error", error);
    }
  };

  return (
    <FormProvider {...methods}>
      <Container>
        <p className={IngredientClasses.ingredient__title}>Add Ingredient</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContainerForm>
            <ContainerLeft>
              <Box
                className={IngredientClasses.ingredient__container}
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <p className={IngredientClasses.ingredient__subtitle}>
                    Ingredient Name*<span>(English)</span>
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Ingredient Name English"
                    sx={{
                      width: "100%",
                    }}
                    {...register("name__english", {
                      required: "Name is Mandatory",
                    })}
                  />
                  <p className={IngredientClasses.ingredient__error__helper}>
                    {errors?.name__english?.message}
                  </p>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <p className={IngredientClasses.ingredient__subtitle}>
                    Ingredient Name<span>(Arabic)</span>
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Ingredient Name Arabic"
                    sx={{
                      width: "100%",
                    }}
                    {...register("name__arabic")}
                  />
                  <p className={IngredientClasses.ingredient__error__helper}>
                    {errors?.name__arabic?.message}
                  </p>
                </Box>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                <Box>
                  <TextField
                    id="demo-helper-text-misaligned"
                    value={"Total calories"}
                    contentEditable={false}
                    focused={false}
                    spellCheck={false}
                    inputProps={{ style: { caretColor: "transparent" } }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Controller
                    name="Energy"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          id="calories"
                          sx={{
                            width: "100%",
                          }}
                          type="text"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Kcal
                              </InputAdornment>
                            ),
                          }}
                        />
                        {error && error.type === "validNumber" && (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Please enter a valid number
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <TextField
                    id="demo-helper-text-misaligned"
                    value={"Protein(g)"}
                    contentEditable={false}
                    focused={false}
                    spellCheck={false}
                    inputProps={{ style: { caretColor: "transparent" } }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Controller
                    name="Protein"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          id="protein"
                          sx={{
                            width: "100%",
                          }}
                          type="text"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">g</InputAdornment>
                            ),
                          }}
                        />
                        {error && error.type === "validNumber" && (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Please enter a valid number
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <TextField
                    id="demo-helper-text-misaligned"
                    value={"Total Carbs(g)"}
                    contentEditable={false}
                    focused={false}
                    spellCheck={false}
                    inputProps={{ style: { caretColor: "transparent" } }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Controller
                    name="Carbohydrate"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          id="carbs"
                          sx={{
                            width: "100%",
                          }}
                          type="text"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">g</InputAdornment>
                            ),
                          }}
                        />
                        {error && error.type === "validNumber" && (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Please enter a valid number
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <TextField
                    id="demo-helper-text-misaligned"
                    value={"Dietary Fiber(g)"}
                    contentEditable={false}
                    focused={false}
                    spellCheck={false}
                    inputProps={{ style: { caretColor: "transparent" } }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Controller
                    name="fiber"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          id="fiber"
                          sx={{
                            width: "100%",
                          }}
                          type="text"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">g</InputAdornment>
                            ),
                          }}
                        />
                        {error && error.type === "validNumber" && (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Please enter a valid number
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <TextField
                    id="demo-helper-text-misaligned"
                    value={"Total Fat(g)"}
                    contentEditable={false}
                    focused={false}
                    spellCheck={false}
                    inputProps={{ style: { caretColor: "transparent" } }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Controller
                    name="fat"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          id="fat"
                          sx={{
                            width: "100%",
                          }}
                          type="text"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">g</InputAdornment>
                            ),
                          }}
                        />
                        {error && error.type === "validNumber" && (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Please enter a valid number
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <TextField
                    id="demo-helper-text-misaligned"
                    value={"Sat Fat(g)"}
                    contentEditable={false}
                    focused={false}
                    spellCheck={false}
                    inputProps={{ style: { caretColor: "transparent" } }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Controller
                    name="satFat"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          id="satFat"
                          sx={{
                            width: "100%",
                          }}
                          type="text"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">g</InputAdornment>
                            ),
                          }}
                        />
                        {error && error.type === "validNumber" && (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Please enter a valid number
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <TextField
                    id="demo-helper-text-misaligned"
                    value={"Sodium(mg)"}
                    contentEditable={false}
                    focused={false}
                    spellCheck={false}
                    inputProps={{ style: { caretColor: "transparent" } }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Controller
                    name="Sodium"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          id="sodium"
                          sx={{
                            width: "100%",
                          }}
                          type="text"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mg</InputAdornment>
                            ),
                          }}
                        />
                        {error && error.type === "validNumber" && (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Please enter a valid number
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <TextField
                    id="demo-helper-text-misaligned"
                    value={"Potassium(mg)"}
                    contentEditable={false}
                    focused={false}
                    spellCheck={false}
                    inputProps={{ style: { caretColor: "transparent" } }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Controller
                    name="Potassium"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          id="potassium"
                          type="text"
                          sx={{
                            width: "100%",
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mg</InputAdornment>
                            ),
                          }}
                        />
                        {error && error.type === "validNumber" && (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Please enter a valid number
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <TextField
                    id="demo-helper-text-misaligned"
                    value={"Calcium(mg)"}
                    contentEditable={false}
                    focused={false}
                    spellCheck={false}
                    inputProps={{ style: { caretColor: "transparent" } }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Controller
                    name="Calcium"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          id="calcium"
                          type="text"
                          sx={{
                            width: "100%",
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mg</InputAdornment>
                            ),
                          }}
                        />
                        {error && error.type === "validNumber" && (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Please enter a valid number
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <TextField
                    id="demo-helper-text-misaligned"
                    value={"Cholesterol(mg)"}
                    contentEditable={false}
                    focused={false}
                    spellCheck={false}
                    inputProps={{
                      style: { caretColor: "transparent" },
                    }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box>
                  <Controller
                    name="Cholesterol"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          onChange={onChange}
                          id="cholesterol"
                          sx={{
                            width: "100%",
                          }}
                          type="text"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mg</InputAdornment>
                            ),
                          }}
                        />
                        {error && error.type === "validNumber" && (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Please enter a valid number
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
              </Box>
            </ContainerLeft>

            <ContainerRight>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <p className={IngredientClasses.ingredient__subtitle}>
                  Cost*<span>(For 100g)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Cost"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {auth.countryCurrency}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                  }}
                  {...register("cost", {
                    required: "Cost is Mandatory",
                  })}
                />
                <p className={IngredientClasses.ingredient__error__helper}>
                  {errors?.cost?.message}
                </p>
              </Box>
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <p className={clsx(IngredientClasses.ingredient__subtitle)}>
                  Allergen Categories
                </p>
                <Controller
                  name="allergens"
                  control={control}
                  render={({
                    field: { onChange: Change, value, ref, ...rest },
                  }) => (
                    <>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        sx={{
                          width: "200%",
                        }}
                        options={allergensList?.getAllergen || []}
                        getOptionLabel={(option) => option?.name?.EN || ""}
                        filterSelectedOptions
                        disableCloseOnSelect
                        renderTags={() => null}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(_, data) => Change(data)}
                      />

                      <Box
                        mt={3}
                        sx={{
                          "& > :not(:last-child)": {
                            marginRight: 1,
                            marginTop: 1,
                          },
                          "& > *": { marginBottom: 1, marginTop: 1 },
                        }}
                      >
                        <Box
                          className={clsx(
                            IngredientClasses.ingredient__container__tags
                          )}
                        >
                          {dataWatch?.allergens?.map((v, i) => (
                            <Chip
                              key={v._id}
                              label={v?.name?.EN}
                              onDelete={() =>
                                setValue(
                                  "allergens",
                                  dataWatch?.allergens?.filter(
                                    (aller) => aller?._id !== v?._id
                                  ) as AllergenGroupPayload[]
                                )
                              }
                              deleteIcon={
                                <CloseSharpIcon
                                  className={
                                    IngredientClasses.ingredient__tags__icon
                                  }
                                />
                              }
                              className={IngredientClasses.ingredient__tags}
                            />
                          ))}
                        </Box>
                      </Box>
                    </>
                  )}
                />
              </Box>

              {/* COUNTRIES */}
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Countries</p>
                <Controller
                  name="countries"
                  rules={{
                    required: "Select Country",
                  }}
                  control={control}
                  render={({
                    field: { onChange: Change, value, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        multiple
                        ref={ref}
                        id="countries"
                        options={auth.countriesList ?? []}
                        value={dataWatch?.countries}
                        getOptionLabel={(option) => option.name?.EN || ""}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        filterSelectedOptions
                        onChange={(_, data) => Change(data)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              color="secondary"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name?.EN}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Country" />
                        )}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
              <Box
                sx={{
                  width: "105%",
                }}
              >
                <p className={clsx(IngredientClasses.ingredient__subtitle)}>
                  Brand
                </p>
                <Controller
                  name="Brand"
                  control={control}
                  render={({ field: { onChange: Change } }) => (
                    <>
                      <div style={{ display: "flex" }}>
                        <Autocomplete
                          id="brand"
                          sx={{
                            width: "200%",
                          }}
                          value={dataWatch?.Brand}
                          options={brandlist}
                          getOptionLabel={(option) => option?.name?.EN || ""}
                          filterSelectedOptions
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(_, data) => Change(data)}
                          renderOption={(props, option, state) => (
                            <li
                              {...props}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingLeft: "40px",
                                paddingRight: "40px",
                              }}
                            >
                              <span style={{ marginTop: "12px" }}>
                                {option?.name?.EN}
                              </span>
                              <div>
                                <IconButton
                                  onClick={() =>
                                    handleOpenEditDialog(
                                      option?._id || "",
                                      option?.name?.EN || ""
                                    )
                                  }
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  onClick={() =>
                                    option._id &&
                                    handleOpenDeleteDialog(option._id)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </li>
                          )}
                        />

                        <Button
                          startIcon={<AddCircle />}
                          className={ButtonClasses.button_plus_add_new}
                          onClick={handleClickOpen}
                        ></Button>
                      </div>
                    </>
                  )}
                />
                <Dialog
                  open={open}
                  onClose={handleCreateBrand}
                  className={DialogClasses.dialog_container_brand}
                >
                  <DialogTitle className={DialogClasses.dialog_title}>
                    Add new brand
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      id="name"
                      label="Enter new brand"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={newBrandName}
                      onChange={(e) => setNewBrandName(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCreateBrand}>Cancel</Button>
                    <Button
                      onClick={handleCreateBrand}
                      variant="contained"
                      className={ButtonClasses.button_submit_form}
                      disabled={createBrandLoading}
                    >
                      {createBrandLoading && (
                        <CircularProgress
                          size={20}
                          style={{ marginRight: 10, color: "white" }}
                        />
                      )}
                      Add
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openDeleteBrand}
                  onClose={handleRemoveBrand}
                  className={DialogClasses.dialog_container_brand}
                >
                  <DialogTitle className={DialogClasses.alert_dialog_title}>
                    <Delete />
                    <span className="alert_dialog_title_text">
                      Delete Brand ?
                    </span>
                  </DialogTitle>
                  <DialogContent className={DialogClasses.alert_dialog_content}>
                    <DialogContentText>
                      Are you sure you want to delete this Brand ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions className={DialogClasses.alert_dialog_actions}>
                    <Button
                      className={ButtonClasses.GreyButton}
                      onClick={handleCloseDelete}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={ButtonClasses.RedButton}
                      onClick={handleRemoveBrand}
                      color="primary"
                      autoFocus
                      disabled={removeBrandLoading}
                    >
                      {removeBrandLoading && (
                        <CircularProgress
                          size={20}
                          style={{ marginRight: 10, color: "white" }}
                        />
                      )}
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openEditBrandDialog}
                  onClose={handleCloseEditDialog}
                  className={DialogClasses.dialog_container_brand}
                >
                  <DialogTitle className={DialogClasses.dialog_title}>
                    Edit Brand
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      id="editedBrandName"
                      label="Enter new brand name"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={editedBrandName}
                      onChange={(e) => setEditedBrandName(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseEditDialog}>Cancel</Button>
                    <Button
                      onClick={handleUpdateBrand}
                      variant="contained"
                      className={ButtonClasses.button_submit_form}
                      disabled={updateBrandLoading}
                    >
                      {updateBrandLoading && (
                        <CircularProgress
                          size={20}
                          style={{ marginRight: 10, color: "white" }}
                        />
                      )}
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </ContainerRight>
          </ContainerForm>
          <Box
            sx={{
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
            className={IngredientClasses.ingredient__container}
          >
            <Button
              variant="contained"
              size="large"
              className={ButtonClasses.button_cancel_form}
              onClick={() => navigate("/food/ingredients/list_ingredients")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={ButtonClasses.button_submit_form}
              type="submit"
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ marginRight: 10, color: "white" }}
                />
              )}
              Create Ingredient
            </Button>
          </Box>
        </form>
      </Container>
    </FormProvider>
  );
};

export default AddIngredients;
