import { LeadStatus, SocialMedia } from "../../graphql/types";
import { FilterBar } from "../Utils";
import { ColumnsProps } from "../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Lead Name",
    accessor: "name",
  },
  {
    header: "Mobile",
    accessor: "mobile",
  },
  {
    header: "OTP Number",
    accessor: "otp",
  },
  {
    header: "Target Calories",
    accessor: "calories",
  },
  {
    header: "date",
    accessor: "date",
  },
  {
    header: "Lead Status",
    accessor: "lead__status",
  },
  {
    header: "Contacted by ",
    accessor: "contacted_by",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];

export const sourceOptions: Array<{
  menuText: string;
  menuValue: SocialMedia;
}> = [
  {
    menuText: "Instagram",
    menuValue: "INSTAGRAM",
  },
  {
    menuText: "Facebook",
    menuValue: "FACEBOOK",
  },
  {
    menuText: "Snapchat",
    menuValue: "SNAPCHAT",
  },
  {
    menuText: "Tiktok",
    menuValue: "TIKTOK",
  },
  {
    menuText: "Referral",
    menuValue: "REFERRAL",
  },
  {
    menuText: "Corporate",
    menuValue: "CORPORATE",
  },
];
export const leadSituationOptions: Array<{
  menuText: string;
  menuValue: LeadStatus;
}> = [
  {
    menuText: "Contacted",
    menuValue: "CONTACTED",
  },
  {
    menuText: "Information required",
    menuValue: "INFORMATION_REQUIRED",
  },
  {
    menuText: "Closed",
    menuValue: "CLOSED",
  },
  {
    menuText: "Converted",
    menuValue: "CONVERTED",
  },
  {
    menuText: "Pending",
    menuValue: "PENDING",
  },
];

export const SIDE_FILTER_LEADS: FilterBar[] = [
  {
    id: "06",
    title: "Status",
    data: [
      { id: "PENDING", name: "Pending", isSelected: false },
      { id: "CLOSED", name: "Closed", isSelected: false },
      { id: "CONTACTED", name: "Contacted", isSelected: false },
      { id: "CONVERTED", name: "Converted", isSelected: false },
      {
        id: "INFORMATION_REQUIRED",
        name: "Information required",
        isSelected: false,
      },
    ],
  },
  {
    id: "11",
    title: "Has Note",
    data: [{ id: "HAS_NOTE", name: "Has Note", isSelected: false }],
  },
];
