import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CountUp from "react-countup";
//material-ui
import {
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
//components
import PageHeader from "../pageHeader/PageHeader";
import Table from "../layout/commun/Table";
import MultiSelect from "../reusable/inputs/Autocomplete/MultiSelect";
//styles
import { useStylesMenu } from "../../styles/Menu__styles";
import { useStylesDialog } from "../../styles/Dialog__styles";
import { useStylesButtons } from "../../styles/Buttons__styles";
import { TableContainer } from "../../styles/Table__styles";
//utils
import { PackingGroupsListColumns } from "./Utils";
import { useIsAuthorized } from "../../utils/Hooks/useIsAuthorized";
import { getMessageError } from "../Utils";
//queries&mutations
import {
  DriverPayload,
  useDeletePackerGroupMutation,
  useGetDeliveriesCountByDriverQuery,
  useGetDriversQuery,
  useGetPackerGroupsQuery,
  useUpdatePackerGroupMutation,
} from "../../graphql/types";
//types
import { ApolloError } from "@apollo/client";

const PackingGroupsList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [groupId, setGroupId] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Date>(new Date());

  //queries&mutations
  const [editGroup, { loading }] = useUpdatePackerGroupMutation();
  const dataDriver = useGetDriversQuery();
  const listGroups = useGetPackerGroupsQuery({
    variables: { date: date },
    fetchPolicy: "network-only",
  });
  const driverDeliveries = useGetDeliveriesCountByDriverQuery({
    variables: { date: date },
  });
  const [deleteGroup] = useDeletePackerGroupMutation();

  //functions
  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setGroupId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (groupId) {
      navigate(`/admin/packingGroups/update_packing_group/${groupId}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteGroup = () => {
    setOpen(false);
    groupId &&
      deleteGroup({
        variables: {
          packerGroupId: groupId,
        },
        onCompleted: () => {
          listGroups.refetch();
          enqueueSnackbar("Group succuessfully deleted", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
        onError: (err) => {
          const error = getMessageError(err as ApolloError);
          enqueueSnackbar(error, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
  };

  const handleDriverSelectionChange = async (
    groupId: string,
    drivers: DriverPayload[]
  ) => {
    try {
      await editGroup({
        variables: {
          updatePackerGroupInput: {
            _id: groupId || "",
            drivers: drivers.map((el) => el._id) as any,
          },
        },

        onCompleted: () => {
          listGroups.refetch();
          enqueueSnackbar("Group drivers updated succuessfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const renderTableRows = () => {
    return (
      listGroups.data?.getPackerGroups?.map((el, index) => {
        return {
          index: index + 1,
          name: el.name,
          firstPacker: el.firstPacker?.name,
          secondPacker: el.secondPacker?.name,
          otherPackers: el.otherPackers?.map((el) => {
            return <div>{el.name}</div>;
          }),
          number: el.totalCount,
          drivers: (
            <MultiSelect
              id={el._id || ""}
              optionsList={dataDriver.data?.getDrivers as DriverPayload[]}
              onChange={handleDriverSelectionChange}
              defaultList={el.drivers as DriverPayload[]}
              stats={
                driverDeliveries.data?.getDeliveriesCountByDriver.data || []
              }
            />
          ),
          actions: (
            <IconButton onClick={(e) => handleClickMenu(e, el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <div>
      {useIsAuthorized("ADMIN_PACKER_GROUP_CREATE") && (
        <PageHeader
          buttonLink={`/admin/packingGroups/add_packing_group`}
          buttonText={"Add Packing Group"}
        />
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginBottom={5}
      >
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DesktopDatePicker
            value={date}
            inputFormat="DD/MM/YYYY"
            disablePast={false}
            disableFuture={false}
            onChange={(newValue) => {
              setDate(newValue || new Date());
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Date"
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          width={700}
          marginBottom={5}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize={16}
            height="80px"
            width="120px"
            marginRight={2}
            borderRadius={2.5}
            boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
            textAlign="center"
          >
            Total
            <Typography variant="h2" fontSize={22} fontWeight="600">
              <CountUp
                end={
                  driverDeliveries.data?.getDeliveriesCountByDriver
                    .totalCount || 0
                }
                duration={0.7}
              />
            </Typography>
          </Box>
          {driverDeliveries.data?.getDeliveriesCountByDriver.data?.map(
            (el, index) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  fontFamily="Poppins"
                  fontWeight="400"
                  fontSize={16}
                  height="80px"
                  width="120px"
                  marginRight={2}
                  borderRadius={2.5}
                  boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
                  textAlign="center"
                >
                  {el.driver?.name}
                  <Typography variant="h2" fontSize={22} fontWeight="600">
                    <CountUp end={el.count || 0} duration={0.7} />
                  </Typography>
                </Box>
              );
            }
          )}
        </Box>
      </Box>
      <TableContainer>
        <Table
          columns={PackingGroupsListColumns}
          data={renderTableRows()}
          emptyMessage="No Packing Groups found !"
          loading={listGroups.loading}
          numberOfFakeRow={30}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("ADMIN_PACKER_GROUP_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <div>
                <span className="menu_title">Update</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("ADMIN_PACKER_GROUP_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>
      </div>

      {/* DELETE PACKING GROUP */}
      <Dialog
        open={open}
        onClose={handleCloseModal}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <Delete />
          <span className="alert_dialog_title_text">
            Delete Packing Group ?
          </span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to delete this Packing Group ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            className={ButtonsClasses.RedButton}
            onClick={handleDeleteGroup}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || listGroups.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default PackingGroupsList;
