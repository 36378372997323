import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useForm,
  useWatch,
  SubmitHandler,
  FormProvider,
  Controller,
} from "react-hook-form";
import { useSnackbar } from "notistack";
//material
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Autocomplete,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//styles
import { useStylesContainers } from "../../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../../styles/Recipe__styles";
//queries&mutations
import {
  LunchMeal,
  ProgramPayload,
  useGetActiveProgramsQuery,
  useGetPromoCodeByIdQuery,
  useUpdatePromoCodeMutation,
} from "../../../../graphql/types";
//components
import PageAddHeader from "../../../pageAddHeader/pageAddHeader";
//types
import { addPromoCodeProps } from "../Types";
import { ApolloError } from "@apollo/client";
//utils
import { CustomPackageMeals, getMessageError } from "../../../Utils";
import {
  duration,
  getSubtitleText,
  numberOfDays,
  promoCodeTypes,
} from "../Utils";
//context
import { useAuth } from "../../../../contextAPI";

const EditPromoCode = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const ContainersClasses = useStylesContainers();
  const RecipeClasses = useStylesRecipe();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  let auth = useAuth();

  // queries and mutations
  const [updatePromoCode, { loading }] = useUpdatePromoCodeMutation();

  const { data: activeProgramsData } = useGetActiveProgramsQuery({
    fetchPolicy: "network-only",
  });

  const { data: promoCodeData } = useGetPromoCodeByIdQuery({
    variables: { input: id || "" },
    fetchPolicy: "no-cache",
  });

  const activePrograms = useMemo(
    () => activeProgramsData?.getActivePrograms || [],
    [activeProgramsData]
  );

  //react-hook-form
  const methods = useForm<addPromoCodeProps>({
    defaultValues: {
      promotionName: "",
      noEnd: false,
      activate: false,
      autoActivate: false,
      startDate: null,
      endDate: null,
      program: [],
      duration: [],
      numberDays: [],
      promoCode: "",
      value: "",
      type: null,
      reductionType: "(QR)",
      extendType: "Days",
      event: {
        breakfast: 0,
        lunchDinner: 0,
        snack: 0,
      },
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  //functions
  const onSubmit: SubmitHandler<addPromoCodeProps> = async (data) => {
    let mealsArray: LunchMeal[] = [];
    if (data.event?.breakfast > 0) {
      mealsArray.push(
        ...[...Array(dataWatch.event?.breakfast)].fill("BREAKFAST")
      );
    }
    if (data.event?.lunchDinner > 0) {
      for (let i = 1; i < data.event?.lunchDinner + 1; i++) {
        if (i % 2 === 0) {
          mealsArray.push("DINNER");
        } else {
          mealsArray.push("LUNCH");
        }
      }
    }
    if (data.event?.snack > 0) {
      for (let i = 1; i < data.event?.snack + 1; i++) {
        if (i % 2 === 0) {
          mealsArray.push("EVENING_SNACK");
        } else {
          mealsArray.push("MORNING_SNACK");
        }
      }
    }
    try {
      await updatePromoCode({
        variables: {
          value: {
            id: id || "",
            code: data.promoCode,
            name: data.promotionName,
            startDate: data.startDate,
            expireDate: data.noEnd ? null : data.endDate,
            program: data.program.map((el) => el._id || ""),
            period: data.duration,
            nbrOfDays: data.numberDays,
            isActive: data.activate,
            autoActivate: data.autoActivate,
            extendDuration:
              data.type === "EXTEND" && data.extendType === "Days"
                ? parseInt(data.value, 10)
                : null,
            extendWeeks:
              data.type === "EXTEND" && data.extendType === "Weeks"
                ? parseInt(data.value, 10)
                : null,
            discountValue:
              data.type === "REDUCTION" && data.reductionType === "%"
                ? parseInt(data.value, 10)
                : null,
            reduction:
              data.type === "REDUCTION" && data.reductionType === "(QR)"
                ? parseInt(data.value, 10)
                : null,
            event:
              data.type === "EVENT"
                ? { daysFree: parseInt(data.value, 10), meals: mealsArray }
                : null,
          },
        },
        onCompleted: () => {
          enqueueSnackbar("Promo code updated succuessfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/data/promoCode/list_promoCode");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const updateMealsQuantityHandler = (
    config: "INCREMENT" | "DECREMENT",
    mealType: string,
    currentQuantity: number
  ) => {
    if (config === "INCREMENT") {
      currentQuantity++;
    } else {
      currentQuantity--;
    }
    if (mealType === "Breakfast") {
      setValue("event.breakfast", currentQuantity);
    } else if (mealType === "Lunch/Dinner") {
      setValue("event.lunchDinner", currentQuantity);
    } else if (mealType === "Snack") {
      setValue("event.snack", currentQuantity);
    }
  };

  useEffect(() => {
    if (
      promoCodeData?.getPromoCodeById &&
      activeProgramsData?.getActivePrograms
    ) {
      const {
        name,
        code,
        isActive,
        autoActivate,
        program,
        period,
        nbrOfDays,
        startDate,
        expireDate,
        type,
        event,
        reduction,
        discountValue,
        extendDuration,
        extendWeeks,
      } = promoCodeData?.getPromoCodeById ?? null;
      setValue("promotionName", name || "");
      setValue("promoCode", code || "");
      setValue("activate", isActive || false);
      setValue("autoActivate", autoActivate || false);
      setValue("program", program || []);
      setValue("duration", period || []);
      setValue("numberDays", nbrOfDays || []);
      setValue("startDate", startDate || null);
      setValue("endDate", expireDate || null);
      setValue("noEnd", expireDate === null ? true : false);

      if (type === "EVENT") {
        let numberBreakfast = 0;
        let numberLunchDinner = 0;
        let numberSnack = 0;
        if (event?.meals) {
          for (let i = 0; i < event?.meals?.length; i++) {
            if (event.meals[i] === "BREAKFAST") {
              numberBreakfast++;
            }
            if (event.meals[i] === "LUNCH" || event.meals[i] === "DINNER") {
              numberLunchDinner++;
            }
            if (
              event.meals[i] === "MORNING_SNACK" ||
              event.meals[i] === "EVENING_SNACK"
            ) {
              numberSnack++;
            }
          }
        }
        setValue("type", type || null);
        setValue("value", event?.daysFree?.toString() || "");
        setValue("event.breakfast", numberBreakfast);
        setValue("event.lunchDinner", numberLunchDinner);
        setValue("event.snack", numberSnack);
      }
      if (type === "REDUCTION") {
        setValue("type", type || null);
        if (discountValue) {
          setValue("reductionType", "%");
          setValue("value", Math.round(discountValue * 100)?.toString());
        } else if (reduction) {
          setValue("value", reduction?.toString() || "");
        }
      }
      if (type === "EXTEND") {
        setValue("type", type || null);
        if (extendWeeks) {
          setValue("extendType", "Weeks");
          setValue("value", extendWeeks?.toString() || "");
        } else if (extendDuration) {
          setValue("value", extendDuration?.toString() || "");
        }
      }
    }
  }, [promoCodeData, activeProgramsData]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <PageAddHeader
            title={"Edit Promo Code"}
            buttonText={"Edit Promo Code"}
            cancelButtonLink="/data/promoCode/list_promoCode"
            loading={loading}
          />
          <Box border={1} borderRadius={2} padding={5}>
            <Box
              display="grid"
              width="100%"
              gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
              gap={1}
            >
              <Box>
                <p className={ContainersClasses.section__subtitle}>
                  Promotion Name
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Promotion Name"
                  fullWidth
                  {...register("promotionName", {
                    required: "Name is Mandatory",
                  })}
                />
                {errors?.promotionName?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.promotionName?.message}
                  </p>
                )}
              </Box>
              <Box>
                <p className={ContainersClasses.section__subtitle}>From</p>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    value={dataWatch.startDate}
                    inputFormat="DD/MM/YYYY"
                    disablePast={false}
                    disableFuture={false}
                    onChange={(newValue) => {
                      setValue("startDate", newValue || new Date());
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} required />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              {!dataWatch.noEnd ? (
                <Box>
                  <p className={ContainersClasses.section__subtitle}>To</p>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DesktopDatePicker
                      value={dataWatch.endDate}
                      inputFormat="DD/MM/YYYY"
                      disablePast={false}
                      disableFuture={false}
                      onChange={(newValue) => {
                        setValue("endDate", newValue || new Date());
                      }}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              ) : (
                <Box></Box>
              )}
              <Box display="flex" alignItems="center" justifyContent="center">
                <Controller
                  name={`noEnd`}
                  control={control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <FormControlLabel
                      {...rest}
                      value={value}
                      control={<Switch checked={value} />}
                      label={"No end"}
                      labelPlacement="top"
                    />
                  )}
                />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Controller
                  name={`activate`}
                  control={control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <FormControlLabel
                      {...rest}
                      value={value}
                      control={<Switch checked={value} />}
                      label={value ? "Active" : "Inactive"}
                      labelPlacement="top"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display="grid"
              width="100%"
              gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
              gap={1}
            >
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Dish Program
                </p>
                <Controller
                  name="program"
                  rules={{
                    required: "Select Program",
                  }}
                  control={control}
                  render={({
                    field: { onChange: Change, value, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        ref={ref}
                        multiple
                        id="program"
                        options={activePrograms || []}
                        value={dataWatch?.program as ProgramPayload[]}
                        getOptionLabel={(option) => option.name?.EN || ""}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        filterSelectedOptions
                        onChange={(_, data) => Change(data)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              color="secondary"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name?.EN}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Dish Program" />
                        )}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Duration</p>
                <Controller
                  name="duration"
                  rules={{
                    required: "Select Duration",
                  }}
                  control={control}
                  render={({
                    field: { onChange: Change, value, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        ref={ref}
                        multiple
                        id="duration"
                        options={duration}
                        value={value}
                        filterSelectedOptions
                        onChange={(_, data) => Change(data)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              color="secondary"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Duration" />
                        )}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Number of days
                </p>
                <Controller
                  name="numberDays"
                  rules={{
                    required: "Select number of days",
                  }}
                  control={control}
                  render={({
                    field: { onChange: Change, value, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        ref={ref}
                        multiple
                        id="numberDays"
                        options={numberOfDays}
                        value={value}
                        filterSelectedOptions
                        onChange={(_, data) => Change(data)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              color="secondary"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Number of days" />
                        )}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
              <Box>
                <p className={ContainersClasses.section__subtitle}>
                  Promo Code
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Promotion Name"
                  fullWidth
                  {...register("promoCode", {
                    required: "Code is Mandatory",
                  })}
                />
                {errors?.promoCode?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.promoCode?.message}
                  </p>
                )}
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Controller
                  name={`autoActivate`}
                  control={control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <FormControlLabel
                      {...rest}
                      value={value}
                      control={<Switch checked={value} />}
                      label={value ? "AutoActivate On" : "AutoActivate Off"}
                      labelPlacement="top"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box paddingTop={5}>
              <Typography
                style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 16 }}
              >
                Promotion details:
              </Typography>
              <Box display="flex" flexDirection="row">
                <Box width="20%">
                  <p className={ContainersClasses.section__subtitle}>Create</p>
                  <Controller
                    name="type"
                    rules={{
                      required: "Select Promo code type",
                    }}
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          ref={ref}
                          disabled
                          id="promoCodeType"
                          clearIcon={false}
                          options={promoCodeTypes}
                          value={value}
                          filterSelectedOptions
                          onChange={(_, data) => Change(data)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Promo Code Type"
                            />
                          )}
                        />
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
                <Box marginLeft={1}>
                  <p className={ContainersClasses.section__subtitle}>
                    {getSubtitleText(
                      dataWatch.type || "",
                      dataWatch.extendType || "",
                      dataWatch.reductionType || "",
                      auth.countryCurrency
                    )}
                  </p>
                  <Controller
                    name="value"
                    rules={{
                      required: "value is required",
                    }}
                    control={control}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        {dataWatch.type === "EXTEND" && (
                          <>
                            <TextField
                              {...rest}
                              disabled={!dataWatch.type}
                              onChange={onChange}
                              type="number"
                              inputProps={{
                                min: 1,
                                max: 365,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {dataWatch.extendType}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Select
                              value={dataWatch.extendType}
                              sx={{ marginLeft: 1 }}
                              onChange={(ev) =>
                                setValue(
                                  "extendType",
                                  ev.target.value as string
                                )
                              }
                              disableUnderline={true}
                            >
                              <MenuItem value="Days">Days</MenuItem>
                              <MenuItem value="Weeks">Weeks</MenuItem>
                            </Select>
                          </>
                        )}
                        {dataWatch.type === "REDUCTION" && (
                          <>
                            <TextField
                              {...rest}
                              disabled={!dataWatch.type}
                              onChange={onChange}
                              type="number"
                              inputProps={{
                                min: 1,
                                max:
                                  dataWatch.reductionType === "%" ? 100 : 9000,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {auth.countryCurrency}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Select
                              value={dataWatch.reductionType}
                              sx={{ marginLeft: 1 }}
                              onChange={(ev) =>
                                setValue(
                                  "reductionType",
                                  ev.target.value as string
                                )
                              }
                              disableUnderline={true}
                            >
                              <MenuItem value="%">%</MenuItem>
                              <MenuItem value="(QR)">
                                ({auth.countryCurrency})
                              </MenuItem>
                            </Select>
                          </>
                        )}
                        {dataWatch.type === "EVENT" && (
                          <Box display="flex" flexDirection="row">
                            <TextField
                              {...rest}
                              disabled={!dataWatch.type}
                              onChange={onChange}
                              type="number"
                              inputProps={{
                                min: 1,
                                max: 365,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Days
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Box width="70%" marginLeft={5}>
                              {CustomPackageMeals?.map((elem) => {
                                let quantity = 1;
                                if (elem === "Breakfast") {
                                  quantity = dataWatch?.event?.breakfast || 0;
                                } else if (elem === "Lunch/Dinner") {
                                  quantity = dataWatch?.event?.lunchDinner || 0;
                                } else if (elem === "Snack") {
                                  quantity = dataWatch?.event?.snack || 0;
                                }
                                return (
                                  <Box
                                    key={elem}
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    marginTop={1}
                                  >
                                    <Box
                                      fontFamily="Poppins"
                                      fontSize={14}
                                      fontWeight="500"
                                      width={200}
                                    >
                                      {elem}
                                    </Box>
                                    <Box
                                      height={25}
                                      width={25}
                                      borderRadius={12}
                                      bgcolor={"#000"}
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      color="#fff"
                                      sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                          backgroundColor: "#C4C4C4",
                                          transition: "0.3s",
                                        },
                                      }}
                                      onClick={() =>
                                        quantity === 0
                                          ? () => null
                                          : updateMealsQuantityHandler(
                                              "DECREMENT",
                                              elem,
                                              quantity
                                            )
                                      }
                                    >
                                      -
                                    </Box>
                                    <Box
                                      height={25}
                                      width={40}
                                      borderRadius={1}
                                      bgcolor="#C4C4C4"
                                      marginLeft={1}
                                      marginRight={1}
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      {quantity}
                                    </Box>
                                    <Box
                                      height={25}
                                      width={25}
                                      borderRadius={12}
                                      bgcolor={"#000"}
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      color="#fff"
                                      sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                          backgroundColor: "#C4C4C4",
                                          transition: "0.3s",
                                        },
                                      }}
                                      onClick={() =>
                                        updateMealsQuantityHandler(
                                          "INCREMENT",
                                          elem,
                                          quantity
                                        )
                                      }
                                    >
                                      +
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        )}
                        {!dataWatch.type && (
                          <TextField
                            {...rest}
                            disabled={!dataWatch.type}
                            onChange={onChange}
                          />
                        )}
                        {error?.message && (
                          <p className={RecipeClasses.recipe__error__helper}>
                            {error?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default EditPromoCode;
