import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { useForm, SubmitHandler, Controller, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
//material
import {
  Box,
  TextField,
  Autocomplete,
  Chip,
  Button,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//styles
import {
  useStylesIngredient,
  Container,
} from "../../../styles/Ingredient__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
//querys and mutations
import {
  useGetAllergenByIdQuery,
  useGetIngredientsQuery,
  useEditAllergenMutation,
  IngredientPayload,
  GetIngredientsDocument,
  GetAllergenWithPaginationDocument,
} from "../../../graphql/types";
//types
import { allergenProps } from "../Types";
//context api
import { useAuth } from "../../../contextAPI";

const UpdateAllergenCategory = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const idIngredient = params.id || null;
  const auth = useAuth();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //styles
  const IngredientClasses = useStylesIngredient();
  const ButtonClasses = useStylesButtons();
  const ContainersClasses = useStylesContainers();

  //react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm<allergenProps>({
    defaultValues: {
      name__english: "",
      name__arabic: "",
      ingredients: [],
      countries: [],
    },
  });

  const dataWatch = useWatch({
    control,
  });

  const dataWatchIngredients = useWatch({ control, name: "ingredients" });

  //querys & mutations
  const dataIngredients = useGetIngredientsQuery({
    variables: { input: { page: 1, documentsPerPage: 2000 } },
  });

  const dataAllergen = useGetAllergenByIdQuery({
    variables: { allergenId: idIngredient || "" },
    skip: !idIngredient,
  });

  const [editAllergen, dataEditAllergen] = useEditAllergenMutation();

  //useEffect
  useEffect(() => {
    if (dataAllergen.data?.getAllergenById) {
      const { name, ingredients, country } = dataAllergen.data.getAllergenById;
      setValue("name__arabic", name?.AR ? name?.AR : "");
      setValue("name__english", name?.EN || "");
      setValue("ingredients", ingredients || []);
      setValue("countries", country || []);
    }
  }, [dataAllergen.data, setValue]);

  //functions
  const onSubmit: SubmitHandler<allergenProps> = async (data) => {
    if (idIngredient) {
      try {
        await editAllergen({
          variables: {
            AllergenInput: {
              id: idIngredient,
              name: { EN: data.name__english, AR: data.name__arabic },
              ingredients: data.ingredients?.map((item) => item._id || ""),
              country: data?.countries
                ?.map((el) => el?._id)
                .filter(Boolean) as string[],
            },
          },
          refetchQueries: [
            {
              query: GetAllergenWithPaginationDocument,
              variables: { input: { page: 1, documentsPerPage: 10 } },
            },
            {
              query: GetIngredientsDocument,
              variables: { input: { page: 1, documentsPerPage: 10 } },
            },
          ],
        });
        enqueueSnackbar("Allergen succuessfully updated ", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);

        navigate("/food/allergens/list_allergens");
      } catch (error) {
        console.log("#error", error);
      }
    }
  };

  const onDelete = (item: IngredientPayload) => () => {
    const ingredients = getValues("ingredients").filter(
      (value) => value._id !== item._id
    );
    setValue("ingredients", ingredients);
  };

  //render
  return (
    <Container>
      <p className={IngredientClasses.ingredient__title}>
        Update Allergen Category
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          className={IngredientClasses.ingredient__container}
          sx={{ width: "100%" }}
        >
          <Box sx={{ width: "50%" }}>
            <p className={clsx(IngredientClasses.ingredient__subtitle)}>
              Allergen Category Name*<span>(English)</span>
            </p>
            <TextField
              id="demo-helper-text-misaligned"
              placeholder="Allergen Category Name English"
              sx={{ width: "100%" }}
              {...register("name__english", {
                required: "Enter allergen category english name",
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: "Enter a valid allergen category english name",
                },
              })}
            />
            <p className={clsx(IngredientClasses.ingredient__error__helper)}>
              {errors?.name__english?.message}
            </p>
          </Box>
          <Box sx={{ width: "50%" }}>
            <p className={clsx(IngredientClasses.ingredient__subtitle)}>
              Allergen Category Name<span>(Arabic)</span>
            </p>
            <TextField
              id="demo-helper-text-misaligned"
              placeholder="Allergen Category Name Arabic"
              sx={{ width: "100%" }}
              {...register("name__arabic")}
            />
            <p className={clsx(IngredientClasses.ingredient__error__helper)}>
              {errors?.name__arabic?.message}
            </p>
          </Box>

          {/* COUNTRIES */}
          <Box width="50%" sx={{ marginTop: 0.7 }}>
            <p className={ContainersClasses.section__subtitle}>Countries</p>
            <Controller
              name="countries"
              rules={{
                required: "Select Country",
              }}
              control={control}
              render={({
                field: { onChange: Change, value, ref, ...rest },
                fieldState: { error },
              }) => (
                <>
                  <Autocomplete
                    multiple
                    ref={ref}
                    id="countries"
                    options={auth.countriesList ?? []}
                    value={dataWatch?.countries}
                    getOptionLabel={(option) => option.name?.EN || ""}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    filterSelectedOptions
                    onChange={(_, data) => Change(data)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          color="secondary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name?.EN}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Country" />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.name?.EN}
                          {...getTagProps({ index })}
                          disabled={
                            !!!auth.countriesList.find(
                              (el) => el.code === option.code
                            )
                          }
                        />
                      ))
                    }
                  />
                  <p className={ContainersClasses.section__error__helper}>
                    {error?.message}
                  </p>
                </>
              )}
            />
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <p className={IngredientClasses.ingredient__subtitle}>Ingredients</p>
          <Controller
            name="ingredients"
            control={control}
            render={({ field: { onChange: Change, value, ref, ...rest } }) => (
              <Autocomplete
                multiple
                id="tags-outlined"
                sx={{
                  width: "200%",
                }}
                value={dataWatchIngredients}
                options={dataIngredients.data?.getIngredients.data || []}
                filterSelectedOptions
                getOptionLabel={(option) => option?.name?.EN || ""}
                renderTags={() => null}
                renderInput={(params) => <TextField {...params} />}
                onChange={(_, data) => Change(data)}
                {...rest}
              />
            )}
          />
          <Box
            mt={3}
            sx={{
              "& > :not(:last-child)": { marginRight: 1, marginTop: 1 },
              "& > *": { marginBottom: 1, marginTop: 1 },
            }}
          >
            <Box className={IngredientClasses.ingredient__container__tags}>
              {dataWatchIngredients.map((item) => (
                <Chip
                  key={item._id}
                  label={item?.name?.EN}
                  onDelete={onDelete(item)}
                  deleteIcon={
                    <CloseSharpIcon
                      className={IngredientClasses.ingredient__tags__icon}
                    />
                  }
                  variant="outlined"
                  className={IngredientClasses.ingredient__tags}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
          className={IngredientClasses.ingredient__container}
        >
          <Button
            variant="outlined"
            size="large"
            className={ButtonClasses.button_cancel_form}
            onClick={() => navigate("/food/allergens/list_allergens")}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            type="submit"
            disabled={dataEditAllergen.loading}
          >
            {dataEditAllergen.loading && (
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "white" }}
              />
            )}
            Update Allergen Category
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default UpdateAllergenCategory;
