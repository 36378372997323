import { NotificationType, PlanCondition } from "../../../graphql/types";
import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Photo",
    accessor: "photo",
  },
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Short Description",
    accessor: "shortDescription",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Destination Count",
    accessor: "DestinationCount",
  },
  {
    header: "Send At",
    accessor: "sendAt",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

export const PushNotificationsColumns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Photo",
    accessor: "photo",
  },
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Short Description",
    accessor: "shortDescription",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Destination Count",
    accessor: "DestinationCount",
  },
  {
    header: "Send At",
    accessor: "sendAt",
  },
  {
    header: "Publish/Reset",
    accessor: "publishReset",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

export const AutomatedNotificationsColumns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Photo",
    accessor: "photo",
  },
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Short Description",
    accessor: "shortDescription",
  },
  {
    header: "Destination Count",
    accessor: "DestinationCount",
  },
  {
    header: "Send At",
    accessor: "sendAt",
  },
];

export const typeOptions: Array<{
  menuText: string;
  menuValue: NotificationType;
}> = [
  {
    menuText: "Important",
    menuValue: "IMPORTANT",
  },
  {
    menuText: "New content",
    menuValue: "NEW_CONTENT",
  },
  {
    menuText: "Promotion",
    menuValue: "PROMOTION",
  },
  {
    menuText: "Purchase update",
    menuValue: "PURCHASE_UPDATE",
  },
  {
    menuText: "User engagement",
    menuValue: "USER_ENGAGEMENT",
  },
  {
    menuText: "Customer support",
    menuValue: "CUSTOMER_SUPPORT",
  },
  {
    menuText: "App update",
    menuValue: "APP_UPDATE",
  },
  {
    menuText: "Location based",
    menuValue: "LOCATION_BASED",
  },
  {
    menuText: "Health tracking",
    menuValue: "HEALTH_TRACKING",
  },
  {
    menuText: "Other",
    menuValue: "OTHER",
  },
];

export const options = [
  { value: "Program", label: "Program" },
  { value: "planCondition:", label: "Status" },
  { value: "numberOfDays:", label: "Number Of Days" },
  { value: "paymentPeriod", label: "Payment Period" },
];

export const planOptions: Array<{
  menuText: string;
  menuValue: PlanCondition;
}> = [
  {
    menuText: "Frozen",
    menuValue: "FROZEN",
  },
  {
    menuText: "Upcoming",
    menuValue: "UPCOMING",
  },
  {
    menuText: "Active",
    menuValue: "ACTIVE",
  },
  {
    menuText: "Cancled",
    menuValue: "CANCLED",
  },
  {
    menuText: "Will be canceled",
    menuValue: "WILL_BE_CANCELED",
  },
  {
    menuText: "Deleted",
    menuValue: "DELETED",
  },
  {
    menuText: "Uncompleted",
    menuValue: "UNCOMPLETED",
  },
  {
    menuText: "Expiring soon",
    menuValue: "EXPIRING_SOON",
  },
  {
    menuText: "Expired",
    menuValue: "EXPIRED",
  },
  {
    menuText: "Will be frozen",
    menuValue: "WILL_BE_FROZEN",
  },
  {
    menuText: "Will be canceled",
    menuValue: "WILL_BE_CANCELED",
  },
  {
    menuText: "Will be desactivated",
    menuValue: "WILL_BE_DESACTIVATED",
  },
  {
    menuText: "Desactivated",
    menuValue: "DESACTIVATED",
  },
  {
    menuText: "Switched",
    menuValue: "SWITCHED",
  },
  {
    menuText: "Will be switched",
    menuValue: "WILL_BE_SWITCHED",
  },
];

export const durations = [
  "WEEKLY",
  "MONTHLY",
  "ONE_DAY",
  "TWO_WEEKS",
  "THREE_WEEKS",
  "TWO_MONTHS",
  "THREE_MONTHS",
  "FOUR_MONTHS",
];

export const planConditions: PlanCondition[] = [
  "FROZEN",
  "UPCOMING",
  "ACTIVE",
  "CANCLED",
  "WILL_BE_CANCELED",
  "DELETED",
  "UNCOMPLETED",
  "EXPIRING_SOON",
  "EXPIRED",
  "WILL_BE_FROZEN",
  "WILL_BE_CANCELED",
  "WILL_BE_DESACTIVATED",
  "DESACTIVATED",
  "SWITCHED",
  "WILL_BE_SWITCHED",
];
