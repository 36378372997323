import { useCallback, useEffect, useRef, useState } from "react";
import { FiFilter, FiSearch } from "react-icons/fi";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
//types
import {
  NumberOfDays,
  useGetAllProgramsQuery,
  useGetSalesAndSupportsListQuery,
} from "../../graphql/types";
import { FilterHeaderProps } from "./Types";
//material-ui
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  debounce,
  Grow,
  Menu,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import HelpIcon from "@mui/icons-material/Help";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
//styles
import { useStylesButtons } from "../../styles/Buttons__styles";
import { useStylesHeader } from "../../styles/Header__styles";
import {
  HeaderSearch,
  SearchIconWrapper,
  StyledInputBase,
} from "../../styles/Navigation__styles";
//components
import FilterItem from "./FilterItem";
import DateRangePicker from "../layout/commun/DateRangePicker";
//utils
import { FilterBar, FilterData, handleNbrDays, options } from "../Utils";
//icons
import ExcelIcon from "../../public/icons/ExcelIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

const PROGRAM_FILTER_ID = "02";
const DASHBOARD_FILTER_ID = "01";
const DURATION_FILTER_ID = "03";
const NUMBER_OF_MEALS_FILTER_ID = "04";
const NUMBER_OF_DAYS_FILTER_ID = "05";
const LEAD_STATUS_FILTER_ID = "06";
const PLAN_STATUS_FILTER_ID = "07";
const INFLUENCER_FILTER_ID = "08";
const TRANSACTION_STATUS_FILTER_ID = "09";
const DISH_SUITABILITY_FILTER_ID = "10";
const HAS_NOTE_FILTER_ID = "11";
const PAYMENT_METHOD_ID = "12";
const SALES_SUPPORT_FILTER_ID = "13";
const REMINDER_STATUS_ID = "14";

const mealsMap = new Map([
  [2, "Business Lunch"],
  [3, "3 meals"],
  [4, "4 meals"],
  [5, "5 meals"],
]);

const daysMap = new Map([
  ["5 Days", "FIVE_DAYS"],
  ["6 Days", "SIX_DAYS"],
  ["7 Days", "SEVEN_DAYS"],
]);

const dishSuitabilityMap = new Map([
  ["BREAKFAST", "Breakfast"],
  ["LUNCH", "Lunch/Dinner"],
  ["SNACKS", "Snack"],
  ["ADDON", "Add On"],
]);

const dishSuitabilityNamesMap = new Map([
  ["Breakfast", "BREAKFAST"],
  ["Lunch/Dinner", "LUNCH"],
  ["Snack", "SNACKS"],
  ["Add On", "ADDON"],
]);

const FilterHeader: React.FC<FilterHeaderProps> = ({
  buttonLink,
  buttonText,
  setSearchValue,
  setFilter,
  filterData,
  setDuration,
  durationData,
  setNumberOfDays,
  daysData,
  setNumberOfMeals,
  mealsData,
  setIdsProgram,
  programIdData,
  setIdsSalesSupport,
  salesSupportId,
  setPlanStatus,
  planStatus,
  setFrom,
  setTo,
  setStatus,
  statusdata,
  from,
  to,
  influencer,
  setInfluencer,
  hasNote,
  setHasNote,
  showSearch,
  hideFilter,
  showSalesSupport,
  pageTitle,
  pageInfo,
  count,
  filterConfig,
  hideProgram,
  hideToDate,
  hideExcel,
  loadExcel,
  generateExcel,
  hideDateAndTitle,
  transactionStatusData,
  setTransactionStatus,
  paymentMethodData,
  setPaymentMethod,
  dishSuitability,
  setDishSuitability,
  onFilteredProgramsList,
  setReminderStatus,
  reminderStatus,
}) => {
  let navigate = useNavigate();
  const anchorRef = useRef<HTMLDivElement>(null);
  //styles
  const ButtonClasses = useStylesButtons();
  const classesHeader = useStylesHeader();
  //state
  const [filterDetails, setFilterDetails] = useState<FilterBar[]>(filterConfig);
  const [programsList, setPrograms] = useState<any[]>();
  const [salesSupportsIdsList, setSalesSupportsIdsList] = useState<any[]>();
  const [combined, setCombined] = useState<any[]>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(6);
  const [open, setOpen] = useState(false);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuValue?: string) => {
    setAnchorEl(null);
  };

  //clear date range search
  const handleclear = () => {
    if (setFrom || setTo) {
      setFrom?.(null);
      setTo?.(null);
    }
  };

  //queries
  const dataPrograms = useGetAllProgramsQuery({
    variables: { input: { page: 1, documentsPerPage: 1000 } },
  });
  const salesSupportListDate = useGetSalesAndSupportsListQuery();

  //THIS USEEFFECT ADDS PROGRAMS TO THE FILTER OPTIONS SINCE PROGRAMS ARE DYNAMIC
  useEffect(() => {
    if (hideProgram) return;
    if (!dataPrograms.data?.getAllPrograms.data) return;
    const programs = dataPrograms?.data?.getAllPrograms.data?.map((element) => {
      return {
        id: element._id,
        name: element.name?.EN,
        isSelected: false,
      };
    });
    setPrograms(programs);
    if (filterDetails.find((el) => el.id === PROGRAM_FILTER_ID)) return;
    const programFilter = {
      id: PROGRAM_FILTER_ID,
      title: "Program",
      data: programs,
    };
    setFilterDetails((old) => [...old, programFilter]);
  }, [dataPrograms.data?.getAllPrograms.data, hideProgram, filterDetails]);

  //THIS USEEFFECT ADDS SALES SUPPORT LIST TO THE FILTER OPTIONS SINCE THEY ARE DYNAMIC
  useEffect(() => {
    if (!showSalesSupport) return;
    if (!salesSupportListDate.data?.getSalesAndSupportsList) return;
    const salesSupportFormattedList =
      salesSupportListDate?.data?.getSalesAndSupportsList?.map((element) => {
        return {
          id: element._id,
          name: `${element.fname} ${element.lname}`,
          isSelected: false,
        };
      });
    setSalesSupportsIdsList(salesSupportFormattedList);
    if (filterDetails.find((el) => el.id === SALES_SUPPORT_FILTER_ID)) return;
    const salesSupportFilter = {
      id: SALES_SUPPORT_FILTER_ID,
      title: "Sales and Support Agents List",
      data: salesSupportFormattedList,
    };
    setFilterDetails((old) => [...old, salesSupportFilter]);
  }, [salesSupportListDate.data?.getSalesAndSupportsList, showSalesSupport]);

  const onSelectDeselectHandler = (
    isSelected: boolean,
    id: string,
    filterArr: any
  ) => {
    if (!isSelected) {
      filterArr = [...filterArr, id];
    } else {
      const newArr = filterArr.filter((elem: any) => elem != id);
      filterArr = [...newArr];
    }
    return filterArr;
  };

  const selectFilter = (id: string) => {
    setFilterDetails((oldState) => {
      let filterArr = filterData || [];
      let durationArr = durationData || [];
      let numberOfDaysArr = daysData || [];
      let numberOfMealsArr = mealsData || [];
      let programIdsArr = programIdData || [];
      let salesSupportIdsArr = salesSupportId || [];
      let statusArr = statusdata || [];
      let planStatusArr = planStatus || [];
      let transactionStatusArr = transactionStatusData || [];
      let paymentMethodArr = paymentMethodData || [];
      let dishSuitabilityArr = dishSuitability || [];
      let reminderStatusArr = reminderStatus || [];

      const newFilter = oldState.map((element) => {
        const newData = element.data.map((el) => {
          if (el.id === id) {
            //DASH FILTERS
            if (element.id === DASHBOARD_FILTER_ID) {
              filterArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                filterArr
              );
            } else if (element.id === PROGRAM_FILTER_ID) {
              //PROGRAM FILTERS
              programIdsArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                programIdsArr
              );
            } else if (element.id === SALES_SUPPORT_FILTER_ID) {
              //SALES SUPPORT FILTERS
              salesSupportIdsArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                salesSupportIdsArr
              );
            } else if (element.id === DURATION_FILTER_ID) {
              //DURATION FILTER
              durationArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                durationArr
              );
            } else if (element.id === NUMBER_OF_MEALS_FILTER_ID) {
              //NUMBER OF MEALS FILTERS
              if (!el.isSelected) {
                numberOfMealsArr = [...numberOfMealsArr, parseInt(el.id, 10)];
              } else {
                const newArr = numberOfMealsArr.filter(
                  (elem) => elem != parseInt(el.id || "", 10)
                );
                numberOfMealsArr = [...newArr];
              }
            } else if (element.id === NUMBER_OF_DAYS_FILTER_ID) {
              //NUMBER OF DAYS FILTER
              numberOfDaysArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                numberOfDaysArr
              );
            } else if (element.id === LEAD_STATUS_FILTER_ID) {
              //LEAD STATUS FILTER
              statusArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                statusArr
              );
            } else if (element.id === PLAN_STATUS_FILTER_ID) {
              //PLAN STATUS FILTER
              planStatusArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                planStatusArr
              );
            } else if (element.id === TRANSACTION_STATUS_FILTER_ID) {
              //TRANSACTION STATUS FILTER
              transactionStatusArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                transactionStatusArr
              );
            } else if (element.id === PAYMENT_METHOD_ID) {
              //PAYMENT METHOD
              paymentMethodArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                paymentMethodArr
              );
            } else if (element.id === DISH_SUITABILITY_FILTER_ID) {
              //DISH SUITABILITY FILTER
              dishSuitabilityArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                dishSuitabilityArr
              );
            } else if (element.id === INFLUENCER_FILTER_ID) {
              //INFLUENCER FILTER
              if (!el.isSelected && setInfluencer) {
                setInfluencer(true);
              }
              if (el.isSelected && setInfluencer) {
                setInfluencer(false);
              }
            } else if (element.id === HAS_NOTE_FILTER_ID) {
              //HAS NOTE FILTER
              if (!el.isSelected && setHasNote) {
                setHasNote(true);
              }
              if (el.isSelected && setHasNote) {
                setHasNote(false);
              }
            } else if (element.id === REMINDER_STATUS_ID) {
              //REMINDER STATUS FILTER
              reminderStatusArr = onSelectDeselectHandler(
                el.isSelected!,
                el.id,
                reminderStatusArr
              );
            }
            return { ...el, isSelected: !el.isSelected };
          } else {
            return { ...el };
          }
        });
        return { ...element, data: newData };
      });

      if (setFilter) {
        setFilter(filterArr);
      }
      if (setDuration) {
        setDuration(durationArr);
      }
      if (setNumberOfDays) {
        setNumberOfDays(numberOfDaysArr);
      }
      if (setNumberOfMeals) {
        setNumberOfMeals(numberOfMealsArr);
      }
      setIdsProgram(programIdsArr);
      if (setIdsSalesSupport) {
        setIdsSalesSupport(salesSupportIdsArr);
      }
      if (setStatus) {
        setStatus(statusArr);
      }
      if (setPlanStatus) {
        setPlanStatus(planStatusArr);
      }
      if (setTransactionStatus) {
        setTransactionStatus(transactionStatusArr);
      }
      if (setPaymentMethod) {
        setPaymentMethod(paymentMethodArr);
      }
      if (setDishSuitability) {
        setDishSuitability(dishSuitabilityArr);
      }
      if (setReminderStatus) {
        setReminderStatus(reminderStatusArr);
      }
      return newFilter;
    });
  };

  const debounced = useCallback(
    debounce((event) => {
      if (setSearchValue) {
        setSearchValue(event.target.value);
      }
    }, 800),
    []
  );

  //THIS USE EFFECT WAS CREATED TO FILL THE COMBINED STATE WHICH CONTAINS FILTER TAGS
  useEffect(() => {
    let filteredDays = daysData?.map((el) => {
      return handleNbrDays(el, "number");
    });

    let filteredMeals = mealsData?.map((el) => {
      return mealsMap.get(el);
    });

    let filteredProgramsList: any = [];
    for (let i = 0; i < (programIdData?.length || 0); i++) {
      for (let j = 0; j < (programsList?.length || 0); j++) {
        if (
          programIdData &&
          programsList &&
          programIdData[i] === programsList[j].id
        ) {
          filteredProgramsList.push(programsList[j].name);
        }
      }
    }

    if (onFilteredProgramsList) onFilteredProgramsList(filteredProgramsList);

    //adding sales supports names to combined tags
    let filteredSalesSupportList: any = [];
    for (let i = 0; i < (salesSupportId?.length || 0); i++) {
      for (let j = 0; j < (salesSupportsIdsList?.length || 0); j++) {
        if (
          salesSupportId &&
          salesSupportsIdsList &&
          salesSupportId[i] === salesSupportsIdsList[j].id
        ) {
          filteredSalesSupportList.push(salesSupportsIdsList[j].name);
        }
      }
    }

    let influencerArray = [];
    if (influencer) {
      influencerArray.push("Influencer");
    }

    let hasNoteArray = [];
    if (hasNote) {
      hasNoteArray.push("Has Note");
    }

    let filteredDishSuitability = dishSuitability?.map((el) => {
      return dishSuitabilityMap.get(el);
    });

    setCombined([
      ...(filteredMeals || []),
      ...(durationData || []),
      ...(filterData || []),
      ...(statusdata || []),
      ...(filteredDays || []),
      ...(planStatus || []),
      ...(filteredProgramsList || []),
      ...(filteredSalesSupportList || []),
      ...(influencerArray || []),
      ...(hasNoteArray || []),
      ...(transactionStatusData || []),
      ...(paymentMethodData || []),
      ...(filteredDishSuitability || []),
      ...(reminderStatus || []),
    ]);
  }, [
    daysData,
    mealsData,
    programIdData,
    programsList,
    salesSupportId,
    salesSupportsIdsList,
    durationData,
    filterData,
    statusdata,
    planStatus,
    influencer,
    hasNote,
    dishSuitability,
    reminderStatus,
  ]);

  const clearAllhandler = () => {
    if (setFilter) {
      setFilter([]);
    }
    if (setDuration) {
      setDuration([]);
    }
    if (setNumberOfDays) {
      setNumberOfDays([]);
    }
    if (setNumberOfMeals) {
      setNumberOfMeals([]);
    }
    setIdsProgram([]);
    if (setIdsSalesSupport) {
      setIdsSalesSupport([]);
    }
    if (setStatus) {
      setStatus([]);
    }
    if (setPlanStatus) {
      setPlanStatus([]);
    }
    if (setTransactionStatus) {
      setTransactionStatus([]);
    }
    if (setPaymentMethod) {
      setPaymentMethod([]);
    }
    if (setDishSuitability) {
      setDishSuitability([]);
    }
    if (setInfluencer) {
      setInfluencer(false);
    }
    if (setHasNote) {
      setHasNote(false);
    }
    if (setReminderStatus) {
      setReminderStatus([]);
    }
    if (setIdsSalesSupport) {
      //in case of sales support filter get to the default filter status
      const salesSupportFormattedList =
        salesSupportListDate?.data?.getSalesAndSupportsList?.map((element) => {
          return {
            id: element._id,
            name: element.username,
            isSelected: false,
          };
        });
      const salesSupportFilter = {
        id: SALES_SUPPORT_FILTER_ID,
        title: "Sales and Support Agents List",
        data: salesSupportFormattedList,
      };
      setFilterDetails([salesSupportFilter as any]);
    } else {
      setFilterDetails(filterConfig);
    }
  };

  const onDeleteTagUpdateFilterList = (FILTER_ID: string, filter: string) => {
    setFilterDetails((oldState) => {
      const newFilter = oldState.map((element: FilterBar) => {
        if (element.id === FILTER_ID) {
          const newData = element.data.map((el: FilterData) => {
            if (el.id === filter) {
              return { ...el, isSelected: false };
            } else {
              return el;
            }
          });
          return { ...element, data: newData };
        } else {
          return element;
        }
      });
      return newFilter;
    });
  };

  const deleteFilterHandler = (filter: string) => {
    //UPDATE FILTER TAGS
    setCombined((oldState) => {
      return oldState?.filter((el) => el != filter);
    });

    //UPDATE DURATION
    if (setDuration) {
      setDuration((oldState) => {
        return oldState.filter((el) => el != filter);
      });
    }
    onDeleteTagUpdateFilterList(DURATION_FILTER_ID, filter);

    //UPDATE NUMNBER OF MEALS
    if (
      filter === "Business Lunch" ||
      filter === "3 meals" ||
      filter === "4 meals" ||
      filter === "5 meals"
    ) {
      let mealNumber: number = 0;
      mealsMap.forEach((value, key) => {
        if (value === filter) {
          mealNumber = key;
        }
      });
      if (setNumberOfMeals) {
        setNumberOfMeals((oldState) => {
          return oldState.filter((el) => el != mealNumber);
        });
      }
      setFilterDetails((oldState) => {
        const newFilter = oldState.map((element: FilterBar) => {
          if (element.id === NUMBER_OF_MEALS_FILTER_ID) {
            const newNoMeals = element.data.map((el: FilterData) => {
              if (parseInt(el.id || "0", 10) === mealNumber) {
                return { ...el, isSelected: false };
              } else {
                return el;
              }
            });
            return { ...element, data: newNoMeals };
          } else {
            return element;
          }
        });
        return newFilter;
      });
    }

    //UPDATE NUMBER OF DAYS
    if (filter === "7 Days" || filter === "6 Days" || filter === "5 Days") {
      let daysFilter: NumberOfDays;
      daysFilter = daysMap.get(filter) as NumberOfDays;
      if (setNumberOfDays) {
        setNumberOfDays((oldState) => {
          return oldState.filter((el) => el != daysFilter);
        });
      }
      onDeleteTagUpdateFilterList(NUMBER_OF_DAYS_FILTER_ID, daysFilter);
    }

    //UPDATE STATUS
    if (
      filter === "FROZEN" ||
      filter === "UPCOMING" ||
      filter === "ACTIVE" ||
      filter === "CANCLED" ||
      filter === "WILL_BE_CANCELED" ||
      filter === "DELETED" ||
      filter === "UNCOMPLETED" ||
      filter === "EXPIRING_SOON" ||
      filter === "EXPIRED" ||
      filter === "WILL_BE_FROZEN" ||
      filter === "WILL_BE_DESACTIVATED" ||
      filter === "DESACTIVATED" ||
      filter === "SWITCHED" ||
      filter === "WILL_BE_SWITCHED"
    ) {
      if (setPlanStatus) {
        setPlanStatus((oldState) => {
          return oldState.filter((el) => el != filter);
        });
      }
      onDeleteTagUpdateFilterList(PLAN_STATUS_FILTER_ID, filter);
    }

    //UPDATING DASH FILTER IN CUSTOMERS PAGE
    if (
      filter === "FIRST_BOX_DELIVERED" ||
      filter === "PAYMENT_COMPLETED" ||
      filter === "EXPIRING_CUSTOMERS" ||
      filter === "UNFROZEN_CUSTOMER" ||
      filter === "RENEWED_CUSTOMERS" ||
      filter === "BIRTHDAYS" ||
      filter === "CASH_ON_DELIVERY" ||
      filter === "PAYMENT_PENDING"
    ) {
      if (setFilter) {
        setFilter((oldState) => {
          return oldState.filter((el) => el != filter);
        });
      }
      onDeleteTagUpdateFilterList(DASHBOARD_FILTER_ID, filter);
    }

    //UPDATING LEAD STATUS
    if (
      filter === "PENDING" ||
      filter === "CLOSED" ||
      filter === "CONTACTED" ||
      filter === "CONVERTED" ||
      filter === "INFORMATION_REQUIRED"
    ) {
      if (setStatus) {
        setStatus((oldState) => {
          return oldState.filter((el) => el != filter);
        });
      }
      onDeleteTagUpdateFilterList(LEAD_STATUS_FILTER_ID, filter);
    }

    //UPDATING TRANSACTION STATUS
    if (
      filter === "CREATED" ||
      filter === "ACTIVATED" ||
      filter === "SUCCESS" ||
      filter === "FAILED" ||
      filter === "CANCELED" ||
      filter === "SCHEDULED_PAYMENT" ||
      filter === "PENDING"
    ) {
      if (setTransactionStatus) {
        setTransactionStatus((oldState) => {
          return oldState.filter((el) => el != filter);
        });
      }
      onDeleteTagUpdateFilterList(TRANSACTION_STATUS_FILTER_ID, filter);
    }

    //UPDATING PAYMENT METHOD
    if (
      filter === "DEBIT_CARD" ||
      filter === "CREDIT_CARD" ||
      filter === "CASH" ||
      filter === "WALLET" ||
      filter === "NAPS" ||
      filter === "APPLE_PAY" ||
      filter === "GOOGLE_PAY"
    ) {
      if (setPaymentMethod) {
        setPaymentMethod((oldState) => {
          return oldState.filter((el) => el != filter);
        });
      }
      onDeleteTagUpdateFilterList(PAYMENT_METHOD_ID, filter);
    }

    //UPDATING DISH SUITABILITY
    if (
      filter === "Breakfast" ||
      filter === "Lunch/Dinner" ||
      filter === "Snack" ||
      filter === "Add On"
    ) {
      let dishSuitabilityFilter = dishSuitabilityNamesMap.get(filter);
      if (setDishSuitability) {
        setDishSuitability((oldState) => {
          return oldState.filter((el) => el != dishSuitabilityFilter);
        });
      }
      onDeleteTagUpdateFilterList(
        DISH_SUITABILITY_FILTER_ID,
        dishSuitabilityFilter ?? ""
      );
    }

    //UPDATING PROGRAM
    if (
      filter === "Keto" ||
      filter === "Ramadan" ||
      filter === "Classic" ||
      filter === "Vegan"
    ) {
      let programFilter: string = "";
      for (let i = 0; i < (programsList?.length || 0); i++) {
        if (programsList && filter === programsList[i].name) {
          programFilter = programsList[i].id;
        }
      }
      setIdsProgram((oldState) => {
        return oldState.filter((el) => el != programFilter);
      });
      onDeleteTagUpdateFilterList(PROGRAM_FILTER_ID, programFilter);
    }

    //UPDATING SALES SUPPORT

    if (setIdsSalesSupport) {
      let salesSupportFilter: string = "";
      for (let i = 0; i < (salesSupportsIdsList?.length || 0); i++) {
        if (salesSupportsIdsList && filter === salesSupportsIdsList[i].name) {
          salesSupportFilter = salesSupportsIdsList[i].id;
        }
      }
      setIdsSalesSupport((oldState) => {
        return oldState.filter((el) => el != salesSupportFilter);
      });
      onDeleteTagUpdateFilterList(SALES_SUPPORT_FILTER_ID, salesSupportFilter);
    }

    //UPDATING INFLUENCER
    if (filter === "Influencer" && setInfluencer) {
      setInfluencer(false);
      onDeleteTagUpdateFilterList(INFLUENCER_FILTER_ID, "INFLUENCER");
    }

    //UPDATING HAS NOTE
    if (filter === "Has Note" && setHasNote) {
      setHasNote(false);
      onDeleteTagUpdateFilterList(HAS_NOTE_FILTER_ID, "HAS_NOTE");
    }

    //UPDATING REMINDER STATUS
    if (filter === "CLOSED" || filter === "PENDING" || filter === "EXPIRED") {
      if (setReminderStatus) {
        setReminderStatus((oldState) => {
          return oldState.filter((el) => el != filter);
        });
      }
      onDeleteTagUpdateFilterList(REMINDER_STATUS_ID, filter);
    }
  };

  const handleExportExcel = async () => {
    generateExcel();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {!hideFilter ? (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Badge badgeContent={combined?.length} color="secondary">
              <Button
                variant="outlined"
                startIcon={<FiFilter />}
                onClick={handleClickMenu}
                className={ButtonClasses.filter_button}
                style={{ height: "40px" }}
              >
                Filter
              </Button>
            </Badge>
            <Box>
              <Tooltip title={pageInfo}>
                <IconButton>
                  <HelpIcon color="primary" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ) : (
          <Box></Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="50%"
        >
          {showSearch && (
            <HeaderSearch
              style={{
                marginBottom: hideDateAndTitle ? 0 : 10,
                width: "100%",
                marginTop: "10px",
              }}
            >
              <SearchIconWrapper>
                <FiSearch />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search..."
                inputProps={{ "aria-label": "search" }}
                onChange={(event) => debounced(event)}
              />
            </HeaderSearch>
          )}

          {!hideDateAndTitle ? (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              marginTop="15px"
            >
              <Typography
                style={{ fontSize: 20, color: "#888888", marginRight: 10 }}
                color="black"
              ></Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography
                  style={{ fontSize: 14, marginRight: 10 }}
                  color="black"
                >
                  {hideToDate ? "Date" : "From"}
                </Typography>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    value={from}
                    inputFormat="DD/MM/YYYY"
                    disablePast={false}
                    disableFuture={false}
                    disabled={options[selectedIndex] !== "Custom range"}
                    onChange={(newValue) => {
                      if (setFrom) {
                        setFrom(newValue || new Date());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: "70%",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                          border: 0,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              {!hideToDate && (
                <>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      style={{ fontSize: 14, marginRight: 10 }}
                      color="black"
                    >
                      To
                    </Typography>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DesktopDatePicker
                        value={to}
                        inputFormat="DD/MM/YYYY"
                        disablePast={false}
                        disableFuture={false}
                        disabled={options[selectedIndex] !== "Custom range"}
                        minDate={moment(from)}
                        onChange={(newValue) => {
                          if (setTo) {
                            setTo(newValue?.toDate() || new Date());
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              width: "70%",
                              boxShadow:
                                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                              border: 0,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  {to && options[selectedIndex] == "Custom range" ? (
                    <IconButton
                      onClick={handleclear}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                        marginRight: 50,
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                  <ButtonGroup
                    variant="outlined"
                    ref={anchorRef}
                    aria-label="split button"
                    color="secondary"
                    disableElevation
                    sx={{ ml: "auto" }}
                  >
                    <Button disableElevation sx={{ width: 145, height: 55 }}>
                      {options[selectedIndex]}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      aria-controls={open ? "split-button-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                      disableElevation
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    sx={{
                      zIndex: 1,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <DateRangePicker
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            setOpen={setOpen}
                            setFrom={setFrom || (() => {})}
                            setTo={setTo || (() => {})}
                          />
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </Box>
          ) : null}
        </Box>
        {buttonLink && buttonText && (
          <Button
            variant="contained"
            onClick={() => {
              navigate(buttonLink);
            }}
            style={{ height: "40px" }}
            className={clsx(ButtonClasses.button_open_list_page)}
          >
            {buttonText}
          </Button>
        )}

        {!buttonLink && !buttonText && count == null && count == undefined && (
          <div style={{ width: "190px" }}></div>
        )}

        {count !== null && count !== undefined ? (
          <Box
            height={71}
            minWidth={90}
            bgcolor="#232222"
            borderRadius={1}
            fontFamily="Poppins"
            fontWeight="400"
            color="#fff"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize={38}
          >
            <CountUp end={count} duration={0.7} />
          </Box>
        ) : null}

        {/* filterMenu */}

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleClose()}
          className={classesHeader.advanced_filter_menu}
        >
          {filterDetails?.map((menu, index) => (
            <FilterItem
              title={menu.title}
              data={menu.data}
              selectFilter={selectFilter}
              key={menu.id}
            />
          ))}
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
              paddingLeft="20px"
              paddingRight="15px"
            >
              <Typography
                fontFamily="Poppins"
                fontWeight="600"
                fontSize="12px"
                color="#000"
              >
                Selected Filters
              </Typography>

              <Typography
                fontFamily="Poppins"
                fontWeight="400"
                fontSize="10px"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={clearAllhandler}
              >
                Clear all
              </Typography>
            </Box>

            <Stack
              direction="row"
              spacing={1}
              marginTop={2}
              flexWrap="wrap"
              width="250px"
            >
              {/* THE CHIP OF MATERIAL UI CHANGES THE LABEL VALUE TO LOWERCASE */}
              {combined?.map((el) => {
                return (
                  <Box marginLeft="8px" key={el}>
                    <Chip
                      label={el}
                      onDelete={() => deleteFilterHandler(el)}
                      size="small"
                    />
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Menu>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography
          fontFamily="Poppins"
          fontWeight="600"
          fontSize="12px"
          color="#000"
          paddingRight="15px"
        >
          Selected Filters
        </Typography>

        {combined && combined.length > 0 && (
          <Typography
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="10px"
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={clearAllhandler}
          >
            Clear all
          </Typography>
        )}
      </Box>

      <Stack direction="row" spacing={1} margin={1}>
        {combined?.map((el) => {
          return (
            <Box marginLeft="8px" key={el}>
              <Chip
                label={el}
                onDelete={() => deleteFilterHandler(el)}
                size="small"
              />
            </Box>
          );
        })}
      </Stack>
      {!hideExcel && (
        <Button
          onClick={handleExportExcel}
          className={clsx(ButtonClasses.button_export_excel)}
          startIcon={<ExcelIcon />}
          disabled={loadExcel}
        >
          Export Excel
          {loadExcel ? <CircularProgress size={14} color="inherit" /> : ""}
        </Button>
      )}
    </Box>
  );
};
export default FilterHeader;
