import moment from "moment";
import { ColumnsProps } from "../../layout/commun/Table";
import {
  AddonsPackedPayload,
  SaucesPackedPayload,
} from "../../../graphql/types";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Customer",
    accessor: "customer",
  },
  {
    header: "meals",
    accessor: "meals",
  },
  {
    header: "1st check",
    accessor: "firstCheck",
  },
  {
    header: "2nd check",
    accessor: "secondCheck",
  },
  {
    header: "Packed",
    accessor: "packed",
  },
  {
    header: "Group",
    accessor: "group",
  },
  {
    header: "Driver",
    accessor: "driver",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "Photo",
    accessor: "photo",
  },
  {
    header: "Status",
    accessor: "status",
  },
];

export const DELIVERY_TIME = ["MORNING", "EVENING", "ALL"];

export const packingResults = (firstCheckDate: Date, secondCheckDate: Date) => {
  return moment(secondCheckDate).diff(moment(firstCheckDate), "minutes");
};

export const formatSaucesToDishes = (sauces: SaucesPackedPayload[]) => {
  const formattedSaucesArray = sauces.map((el: SaucesPackedPayload) => {
    return {
      code: el.code,
      dish: el.sauceName,
      firstCheckedAt: el.firstCheckedAt,
      firstCheckedBy: el.firstCheckedBy,
      secondCheckedBy: el.secondCheckedBy,
      secondCheckedAt: el.secondCheckedAt,
    };
  });
  return formattedSaucesArray;
};

export const formatAddOnsToDishes = (addOns: AddonsPackedPayload[]) => {
  const formattedAddOnsArray = addOns.map((el: AddonsPackedPayload) => {
    return {
      code: "addon",
      dish: el.addon,
      firstCheckedAt: el.firstCheckedAt,
      firstCheckedBy: el.firstCheckedBy,
      secondCheckedBy: el.secondCheckedBy,
      secondCheckedAt: el.secondCheckedAt,
    };
  });
  return formattedAddOnsArray;
};
