import React, { useEffect } from "react";
//material ui
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
//components
import AppMenuItem from "../AppMenuItem/AppMenuItem";
//icons
import DashIcon from "../../../public/icons/DashIcon";
import FoodIcon from "../../../public/icons/FoodIcon";
import AdminIcon from "../../../public/icons/AdminIcon";
import DataManagementIcon from "../../../public/icons/DataManagementIcon";
//context api
import { useAuth } from "../../../contextAPI";
//types
import { Permissions_Enum } from "../../../graphql/types";

type Item = {
  name: string;
  link: string;
};
type Menu = {
  name: string;
  rootLink: string;
  items: Item[];
  Icon: JSX.Element;
};

const appMenuItems = [
  {
    name: "Dashboard",
    //link: "/dashboard/home",
    Icon: <DashIcon />,
    rootLink: "/dashboard",
    items: [
      {
        name: "Home",
        link: "/dashboard/home/list",
      },
      {
        name: "Expiring Customers",
        link: "/dashboard/expiring_customers/list_expiring_customers",
      },
      {
        name: "Switching Customers",
        link: "/dashboard/switched_customers/list_switched_customers",
      },
      {
        name: "First Box Delivered",
        link: "/dashboard/first_box_delivered/list_first_box_delivered_customers",
      },
      {
        name: "Unfrozen Customers",
        link: "/dashboard/unfrozen_customers/list_unfrozen_customers",
      },
      {
        name: "Renewed Customers",
        link: "/dashboard/renewed_customers/list_renewed_customers",
      },
      {
        name: "Renewing customer -cash on delivery",
        link: "/dashboard/scheduled_reneweal_customers/list_scheduled_reneweal_customers",
      },
      {
        name: "Total Customers",
        link: "/dashboard/total_customers/list_total_customers",
      },
      {
        name: "New customer  -cash on delivery",
        link: "/dashboard/scheduled_customers/list_scheduled_customers",
      },
      {
        name: "Pending Payment",
        link: "/dashboard/pending_customers/list_pending_customers",
      },
      {
        name: "Activated Payments",
        link: "/dashboard/activated_customers/list_activated_customers",
      },
      {
        name: "Birthdays",
        link: "/dashboard/birthdays/list_birthdays",
      },
      {
        name: "New Signed up Customers",
        link: "/dashboard/new_signed_up_customers/list_new_signed_up_customers",
      },

      {
        name: "Deactivated Customers",
        link: "/dashboard/deactivated_customer/list_customer",
      },
      {
        name: "BlackListed Customers",
        link: "/dashboard/blackListed_customers/list_blackListed_customers",
      },
      {
        name: "BlackListed Drivers",
        link: "/dashboard/blackListed_drivers/list_blackListed_drivers",
      },
      {
        name: "Converted Leads",
        link: "/dashboard/converted_leads/list_converted_leads",
      },
      {
        name: "Box Trasfers",
        link: "/dashboard/list_box_transfer/list_box_transfer",
      },
    ],
  },
  {
    name: "Food",
    Icon: <FoodIcon />,
    rootLink: "/food",
    items: [
      {
        name: "Ingredients",
        link: "/food/ingredients/list_ingredients",
      },
      {
        name: "Allergens",
        link: "/food/allergens/list_allergens",
      },
      {
        name: "Dishes",
        link: "/food/dishes/list_dishes",
      },
      {
        name: "Programs",
        link: "/food/programs/list_programs",
      },
      {
        name: "Programs Menus",
        link: "/food/program_menu/view_menu",
      },
    ],
  },
  {
    name: "Admin",
    rootLink: "/admin",
    Icon: <AdminIcon />,
    items: [
      {
        name: "Lead",
        link: "/admin/leads/list_leads",
      },
      {
        name: "Customer",
        link: "/admin/customers/list_customers",
      },
      {
        name: "Delivery - Driver",
        link: "/admin/delivery_driver/list_drivers",
      },
      {
        name: "Delivery - Area",
        link: "/admin/delivery_area/list_areas",
      },
      {
        name: "Area",
        link: "/admin/area/list_areas",
      },
      {
        name: "Packing - Agents",
        link: "/admin/packingTeam/list_packing_team",
      },
      {
        name: "Packing - Groups",
        link: "/admin/packingGroups/list_packing_groups",
      },
      {
        name: "Reports",
        link: "/admin/reports/view_reports",
      },
      {
        name: "Transactions",
        link: "/admin/transactions/view_transactions",
      },
      {
        name: "Complains",
        link: "/admin/complains/list_complains",
      },
      {
        name: "Reminders",
        link: "/admin/reminders/list_reminders",
      },
      {
        name: "Chef",
        link: "/admin/chef/list_chefs",
      },
    ],
  },
  {
    name: "Data Management",
    rootLink: "/data",
    Icon: <DataManagementIcon />,
    items: [
      {
        name: "Promo code",
        link: "/data/promoCode/list_promoCode",
      },
      {
        name: "Customer Promo code",
        link: "/data/customer_promoCode/list_customer_promoCode",
      },
      {
        name: "Trainers",
        link: "/data/trainers/list_trainers",
      },
      /* {
        name: "General Freezes",
        link: "/data/general_freezes/list_general_freezes",
      }, */
      {
        name: "Users and Roles",
        link: "/data/users_and_roles/list_users_roles",
      },
      {
        name: "Notifications",
        link: "/data/notifications/list_notifications",
      },
      {
        name: "Notifications Templates",
        link: "/data/notification_templates/list_notification_templates",
      },
      {
        name: "Countries",
        link: "/data/countries/list_countries",
      },
      {
        name: "Rewards",
        link: "/data/rewards/list_rewards",
      },
      {
        name: "Drivers APP Dash",
        link: "/data/drivers-app/driver-dash-report",
      },
      {
        name: "Packing APP Dash",
        link: "/data/packing-app/packing-dash-report",
      },
      {
        name: "Kitchen APP Dash",
        link: "/data/kitchen-app/kitchen-dash-report",
      },
    ],
  },
];

const dashboardAutorizationsMap = new Map([
  ["DASHBOARD_HOME", { name: "Home", link: "/dashboard/home/list" }],
  [
    "DASHBOARD_EXPIRING_CUSTOMER",
    {
      name: "Expiring Customers",
      link: "/dashboard/expiring_customers/list_expiring_customers",
    },
  ],
  [
    "DASHBOARD_SWITCHED_CUSTOMER",
    {
      name: "Switching Customers",
      link: "/dashboard/switched_customers/list_switched_customers",
    },
  ],
  [
    "DASHBOARD_FIRSTBOX_DELIVERED",
    {
      name: "First Box Delivered",
      link: "/dashboard/first_box_delivered/list_first_box_delivered_customers",
    },
  ],
  [
    "DASHBOARD_UNFROZEN_CUSTOMER",
    {
      name: "Unfrozen Customers",
      link: "/dashboard/unfrozen_customers/list_unfrozen_customers",
    },
  ],
  [
    "DASHBOARD_RENEWED_CUSTOMER",
    {
      name: "Renewed Customers",
      link: "/dashboard/renewed_customers/list_renewed_customers",
    },
  ],
  [
    "DASHBOARD_SCHEDULED_RENEWED",
    {
      name: "Renewing customer -cash on delivery",
      link: "/dashboard/scheduled_reneweal_customers/list_scheduled_reneweal_customers",
    },
  ],
  [
    "DASHBOARD_TOTAL_CUSTOMER",
    {
      name: "Total Customers",
      link: "/dashboard/total_customers/list_total_customers",
    },
  ],
  [
    "DASHBOARD_SCHEDULED_PAYMENT",
    {
      name: "New customer -cash on delivery",
      link: "/dashboard/scheduled_customers/list_scheduled_customers",
    },
  ],
  [
    "DASHBOARD_PENDING_PAYMENT",
    {
      name: "Pending Payment",
      link: "/dashboard/pending_customers/list_pending_customers",
    },
  ],
  [
    "DASHBOARD_PROCESSING_PAYMENT",
    {
      name: "Activated Payments",
      link: "/dashboard/activated_customers/list_activated_customers",
    },
  ],
  [
    "DASHBOARD_BIRTHDAYS",
    {
      name: "Birthdays",
      link: "/dashboard/birthdays/list_birthdays",
    },
  ],
  [
    "DASHBOARD_NEW_SIGNED_UP_CUSTOMERS",
    {
      name: "New Signed up Customers",
      link: "/dashboard/new_signed_up_customers/list_new_signed_up_customers",
    },
  ],

  [
    "DASHBOARD_DEACTIVATED_CUSTOMERS",
    {
      name: "Deactivated Customers",
      link: "/dashboard/deactivated_customer/list_customer",
    },
  ],
  [
    "DASHBOARD_BLACKLISTED_CUSTOMERS",
    {
      name: "BlackListed Customers",
      link: "/dashboard/blackListed_customers/list_blackListed_customers",
    },
  ],
  [
    "DASHBOARD_DRIVER_BLACKLISTED_CUSTOMERS",
    {
      name: "BlackListed Drivers",
      link: "/dashboard/blackListed_drivers/list_blackListed_drivers",
    },
  ],
  [
    "ADMIN_CONVERT_LIST",
    {
      name: "Converted Leads",
      link: "/dashboard/converted_leads/list_converted_leads",
    },
  ],
  [
    "MANAGEMENT_TRANSFERED_BOX_LIST",
    {
      name: "Box Trasfers",
      link: "/dashboard/list_box_transfer/list_box_transfer",
    },
  ],
]);

const foodAutorizationsMap = new Map([
  [
    "FOOD_INGREDIENTS_LIST",
    {
      name: "Ingredients",
      link: "/food/ingredients/list_ingredients",
    },
  ],
  [
    "FOOD_ALLERGENS_LIST",
    {
      name: "Allergens",
      link: "/food/allergens/list_allergens",
    },
  ],
  [
    "FOOD_DISHS_LIST",
    {
      name: "Dishes",
      link: "/food/dishes/list_dishes",
    },
  ],
  [
    "FOOD_PROGRAMS_LIST",
    {
      name: "Programs",
      link: "/food/programs/list_programs",
    },
  ],
  [
    "FOOD_PROGRAMSMENU_MONTHS_LIST",
    {
      name: "Programs Menus",
      link: "/food/program_menu/view_menu",
    },
  ],
]);

const adminAutorizationsMap = new Map([
  [
    "ADMIN_LEADS_LIST",
    {
      name: "Lead",
      link: "/admin/leads/list_leads",
    },
  ],
  [
    "ADMIN_CUSTOMERS_LIST",
    {
      name: "Customer",
      link: "/admin/customers/list_customers",
    },
  ],
  [
    "ADMIN_DRIVERS_LIST",
    {
      name: "Delivery - Driver",
      link: "/admin/delivery_driver/list_drivers",
    },
  ],
  [
    "ADMIN_DELIVERYAREA_LIST",
    {
      name: "Delivery - Area",
      link: "/admin/delivery_area/list_areas",
    },
  ],
  [
    "ADMIN_AREA_LIST",
    {
      name: "Area",
      link: "/admin/area/list_areas",
    },
  ],
  [
    "ADMIN_PACKER_LIST",
    {
      name: "Packing - Agents",
      link: "/admin/packingTeam/list_packing_team",
    },
  ],
  [
    "ADMIN_PACKER_GROUP_LIST",
    {
      name: "Packing - Groups",
      link: "/admin/packingGroups/list_packing_groups",
    },
  ],
  [
    "ADMIN_TRANSACRION_LIST",
    {
      name: "Transactions",
      link: "/admin/transactions/view_transactions",
    },
  ],
  [
    "MANAGEMENT_COMPLAIN_LIST",
    {
      name: "Complaints",
      link: "/admin/complains/list_complains",
    },
  ],
  [
    "MANAGEMENT_REMINDER_LIST",
    {
      name: "Reminders",
      link: "/admin/reminders/list_reminders",
    },
  ],
  [
    "ADMIN_CHEF_LIST",
    {
      name: "Chef",
      link: "/admin/chef/list_chefs",
    },
  ],
]);

const dataManagementAutorizationsMap = new Map([
  [
    "MANAGEMENT_PROMOCODE_LIST",
    {
      name: "Promo code",
      link: "/data/promoCode/list_promoCode",
    },
  ],
  [
    "MANAGEMENT_CUSTOMER_PROMOCODE_LIST",
    {
      name: "Customer Promo code",
      link: "/data/customer_promoCode/list_customer_promoCode",
    },
  ],
  [
    "MANAGEMENT_TRAINER_LIST",
    {
      name: "Trainers",
      link: "/data/trainers/list_trainers",
    },
  ],
  /* [
    "MANAGEMENT_GENERAL_FREEZ_LIST",
    {
      name: "General Freezes",
      link: "/data/general_freezes/list_general_freezes",
    },
  ], */
  [
    "MANAGEMENT_NOTIFICATION_LIST",
    {
      name: "Notifications",
      link: "/data/notifications/list_notifications",
    },
  ],
  [
    "MANAGEMENT_TEMPLATE_LIST",
    {
      name: "Notifications Templates",
      link: "/data/notification_templates/list_notification_templates",
    },
  ],
  [
    "MANAGEMENT_GET_COUNTRY_LIST",
    {
      name: "Countries",
      link: "/data/countries/list_countries",
    },
  ],
  [
    "MANAGEMENT_REWARD_LIST",
    {
      name: "Rewards",
      link: "/data/rewards/list_rewards",
    },
  ],
  [
    "MANAGEMENT_GET_DELIVERY_LIST",
    {
      name: "Drivers APP Dash",
      link: "/data/drivers-app/driver-dash-report",
    },
  ],
  [
    "MANAGEMENT_GET_PACKED_LIST",
    {
      name: "Packing APP Dash",
      link: "/data/packing-app/packing-dash-report",
    },
  ],
  [
    "ADMIN_GET_ALL_TASKS",
    {
      name: "Kitchen APP Dash",
      link: "/data/kitchen-app/kitchen-dash-report",
    },
  ],
]);

const AppMenu: React.FC = () => {
  let auth = useAuth();

  //styles
  const classes = useStyles();

  //state
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);
  const [userPermissions, setUserPermissions] =
    React.useState<Permissions_Enum[]>();
  const [sideMenu, setSideMenu] = React.useState<Menu[]>(appMenuItems);

  useEffect(() => {
    const sideMenuCopy = [...sideMenu];
    const dash: Menu = sideMenuCopy[0];
    const food: Menu = sideMenuCopy[1];
    const admin: Menu = sideMenuCopy[2];
    const dataManagement: Menu = sideMenuCopy[3];
    //dash
    const updatedDashItems = userPermissions
      ?.map((el) => {
        if (dashboardAutorizationsMap.has(el))
          return dashboardAutorizationsMap.get(el);
      })
      .filter((el) => el);
    //food
    const updatedFoodItems = userPermissions
      ?.map((el) => {
        if (foodAutorizationsMap.has(el)) return foodAutorizationsMap.get(el);
      })
      .filter((el) => el);
    //admin
    const updatedAdminItems = userPermissions
      ?.map((el) => {
        if (adminAutorizationsMap.has(el)) return adminAutorizationsMap.get(el);
      })
      .filter((el) => el);
    //add report menu in case user has at least one report permissions
    if (
      userPermissions?.includes("ADMIN_REPORTS_LABEL") ||
      userPermissions?.includes("ADMIN_REPORTS_PACKAGING") ||
      userPermissions?.includes("ADMIN_REPORTS_BOXES") ||
      userPermissions?.includes("ADMIN_REPORTS_DRIVERS") ||
      userPermissions?.includes("ADMIN_REPORTS_GROCERY") ||
      userPermissions?.includes("ADMIN_REPORTS_DISHS") ||
      userPermissions?.includes("ADMIN_REPORTS_BULK_COOKING") ||
      userPermissions?.includes("ADMIN_REPORTS_DISH_SELECTIONS") ||
      userPermissions?.includes("ADMIN_REPORTS_ACTIVE_CUSTOMERS") ||
      userPermissions?.includes("ADMIN_REPORTS_OUT_OF_RANGE") ||
      userPermissions?.includes("ADMIN_REPORTS_TRAINER") ||
      userPermissions?.includes("ADMIN_TRANSACRION_LIST")
    ) {
      updatedAdminItems?.push({
        name: "Reports",
        link: "/admin/reports/view_reports",
      });
    }
    //data management
    const updatedDataManagementItems = userPermissions
      ?.map((el) => {
        if (dataManagementAutorizationsMap.has(el))
          return dataManagementAutorizationsMap.get(el);
      })
      .filter((el) => el);
    //add roles menu in case user has roles/users permissions
    if (
      userPermissions?.includes("MANAGEMENT_ROLES_LIST") ||
      userPermissions?.includes("MANAGEMENT_ROLES_LIST_ADMIN")
    ) {
      updatedDataManagementItems?.push({
        name: "Users and Roles",
        link: "/data/users_and_roles/list_users_roles",
      });
    }
    dash.items = updatedDashItems as Item[];
    food.items = updatedFoodItems as Item[];
    admin.items = updatedAdminItems as Item[];
    dataManagement.items = updatedDataManagementItems as Item[];
    sideMenuCopy[0] = dash;
    sideMenuCopy[1] = food;
    sideMenuCopy[2] = admin;
    sideMenuCopy[3] = dataManagement;
    setSideMenu(sideMenuCopy);
  }, [userPermissions]);

  const handleItemClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const openItemDefault = (index: number) => {
    setOpenIndex(index);
  };

  useEffect(() => {
    if (auth.permissions) {
      setUserPermissions(auth.permissions);
    }
  }, [auth.permissions]);

  return (
    <List component="nav" className={classes.appMenuContainer} disablePadding>
      {sideMenu.map((item, index) => (
        <AppMenuItem
          {...item}
          key={item.name}
          isOpen={openIndex === index}
          handleItemClick={() => handleItemClick(index)}
          openItemDefault={() => openItemDefault(index)}
        />
      ))}
    </List>
  );
};

const useStyles = makeStyles(() => {
  return {
    appMenuContainer: {
      width: "95%",
      paddingLeft: "0px",
      marginTop: 10,
    },
  };
});

export default AppMenu;
