import React from "react";
import moment from "moment";
//materials ui
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//utils
import { ReminderCardProps, getStatusBackgroundColor } from "./Utils";

const ReminderCard: React.FC<ReminderCardProps> = ({
  item,
  handleClickMenu,
}) => {
  const id = item?._id || "";
  const statusStyles = getStatusBackgroundColor(item?.status);
  return (
    <Grid item xs={3} key={id}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "450px",
          ...statusStyles,
        }}
      >
        <Typography sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="subtitle1"
            sx={{
              border: "1px solid white",
              padding: "8px",
              width: "fit-content",
            }}
          >
            {item?.status}
          </Typography>
          <IconButton
            sx={statusStyles}
            onClick={handleClickMenu(id ?? "", item?.status ?? "")}
          >
            <MoreVertIcon />
          </IconButton>
        </Typography>
        <CardHeader
          title={
            <Typography
              variant="subtitle1"
              sx={{
                ...statusStyles,
              }}
            >
              Title : {item.title}
            </Typography>
          }
          subheader={
            <Typography
              variant="body2"
              sx={{
                textDecoration: "underline",
                ...statusStyles,
              }}
            >
              Date :{" "}
              {item?.date
                ? moment(item.date).format("DD-MMM-YYYY hh:mm a")
                : "---"}
              <Typography variant="body2">
                {" "}
                Priority : {item?.priority ? item.priority : "---"}
              </Typography>
            </Typography>
          }
        />
        <CardContent sx={{ flex: 1 }}>
          <Typography sx={statusStyles}>
            Description : {item?.description}
          </Typography>
        </CardContent>

        <CardContent>
          <Typography>Details</Typography>
          <Typography variant="body2">
            Created by : {item?.createdBy?.fname} {item?.createdBy?.lname}
          </Typography>
          <Typography variant="body2">
            Closed by :{" "}
            {item?.closedBy?.fname
              ? `${item?.closedBy?.fname} ${item?.closedBy?.lname}`
              : "---"}
          </Typography>
          <Typography variant="body2">
            Closed at :{" "}
            {item?.closedAt
              ? moment(item.closedAt).format("DD-MMM-YYYY")
              : "---"}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ReminderCard;
