import { ColumnsProps } from "../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Title",
    accessor: "title",
    cellProps: { align: "left", width: "30%" },
  },
  {
    header: "Customer",
    accessor: "customer",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Ticket",
    accessor: "ticket",
    cellProps: { align: "center" },
  },
  {
    header: "Image",
    accessor: "image",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

export const ComplainTypes = [
  "CUSTOMER_SUPPORT",
  "DELIVERY",
  "FOOD",
  "OTHERS",
  "TECH",
];

export const ComplainStatus = ["PENDING", "RESOLVED", "REJECTED", "CHECKING"];
