import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  useWatch,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import moment from "moment";
//material_ui
import {
  Box,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
} from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
//styles
import { useStylesLeads } from "../../../styles/Leads__styles";
import {
  ContainerFullWidth,
  ContainerLeft,
  LeftElement,
  ContainersContent,
  useStylesContainers,
} from "../../../styles/Containers__styles";
//utils
import { countries } from "../../../staticData/staticData";
import { weightOptions, heightOptions } from "../Utils";
import { getMessageError } from "../../Utils";
//types
import { customersProps } from "../Types";
import { ApolloError } from "@apollo/client";
//components
import PageAddHeader from "../../pageAddHeader/pageAddHeader";
import PhoneInput from "../../layout/PhoneInput";
//mutations & queries
import {
  useGetCustomerProfileByIdQuery,
  useUpdateProfileInfoMutation,
  GetCustomerProfileByIdDocument,
  Weight,
  Height,
} from "../../../graphql/types";

const UpdateCustomer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const idCustomer = params.id || null;

  //styles
  const ContainersClasses = useStylesContainers();
  const LeadsClasses = useStylesLeads();

  // queries and mutations
  const { data: dataCustomer } = useGetCustomerProfileByIdQuery({
    variables: {
      customerId: idCustomer || "",
    },
    skip: !idCustomer,
  });
  const [updateCustomer, { loading }] = useUpdateProfileInfoMutation();

  //react-hook-form
  const methods = useForm<customersProps>({
    defaultValues: {
      first__name: "",
      last__name: "",
      nationality: "",
      gender: "MALE",
      birth__date: null,
      weight: 0,
      weight__unit: "KG",
      height: 0,
      height__unit: "CM",
      influencer: false,
      phone__number: null,
      phone__prefix: null,
      withCutlery: true,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  //useEffect
  useEffect(() => {
    if (dataCustomer) {
      const {
        dob,
        fname,
        lname,
        gender,
        height,
        weight,
        nationality,
        influencer,
        phone,
        withCutlery,
      } = dataCustomer?.getCustomerProfileById ?? null;
      setValue("first__name", fname || "");
      setValue("last__name", lname || "");
      setValue("gender", gender || "MALE");
      setValue(
        "nationality",
        countries?.find((el) => el === nationality) ?? ""
      );
      setValue("phone__number", phone?.number);
      setValue("phone__prefix", phone?.prefix?.replace("+", "") || "");
      setValue("birth__date", dob);
      setValue("influencer", influencer || false);
      setValue("weight", weight?.value || 0);
      setValue("height", height?.value || 0);
      setValue("height__unit", height?.unit || "CM");
      setValue("weight__unit", weight?.unit || "KG");
      setValue("withCutlery", withCutlery || true);
    }
  }, [dataCustomer, setValue]);

  //functions
  const onSubmit: SubmitHandler<customersProps> = async (
    data: customersProps
  ) => {
    try {
      await updateCustomer({
        variables: {
          updateProfileInfoInput: {
            id: idCustomer || "",
            fname: data?.first__name,
            lname: data?.last__name,
            height: {
              unit: data.height__unit,
              value: Number(data?.height),
            },
            weight: {
              unit: data.weight__unit,
              value: Number(data?.weight),
            },
            nationality: data?.nationality,
            gender: data?.gender ? data?.gender : null,
            withCutlery: data?.withCutlery ? data?.withCutlery : null,
            dob: data?.birth__date,
            phone:
              data?.phone__number && data.phone__prefix
                ? {
                    number: data?.phone__number,
                    prefix: `+${data?.phone__prefix}`,
                  }
                : null,
            influencer: data?.influencer,
          },
        },
        refetchQueries: [
          {
            query: GetCustomerProfileByIdDocument,
            variables: { input: { page: 1, documentsPerPage: 10 } },
          },
        ],
        onCompleted: () => {
          navigate("/admin/customers/list_customers");
          enqueueSnackbar("Customer Informations succuessfully updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title="Update Customer"
          buttonText="Update Customer"
          cancelButtonLink="/admin/customers/list_customers"
          loading={loading}
        />
        <ContainersContent>
          <ContainerFullWidth>
            <FormProvider {...methods}>
              <ContainerLeft>
                <LeftElement>
                  <p className={ContainersClasses.section__title}>
                    Personal Informations
                  </p>

                  <Box className={ContainersClasses.section__container}>
                    {/* First Name */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        First Name*
                      </p>
                      <TextField
                        placeholder="First Name"
                        sx={{ width: "100%" }}
                        {...register("first__name", {
                          required: "Enter Customer First Name",
                        })}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.first__name?.message}
                      </p>
                    </Box>

                    {/* Last Name */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Last Name*
                      </p>
                      <TextField
                        placeholder="Last Name"
                        sx={{ width: "100%" }}
                        {...register("last__name", {
                          required: "Enter Customer Last Name",
                        })}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.last__name?.message}
                      </p>
                    </Box>
                  </Box>
                  <Box className={ContainersClasses.section__container}>
                    {/* Nationality */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Nationality
                      </p>
                      <Controller
                        name="nationality"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                        }) => (
                          <Autocomplete
                            options={countries}
                            disableCloseOnSelect
                            value={dataWatch?.nationality}
                            onChange={(_, data) => Change(data)}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputRef={ref}
                                variant="outlined"
                                sx={{ width: "100%" }}
                              />
                            )}
                          />
                        )}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.nationality?.message}
                      </p>
                    </Box>

                    {/* Date of Birth */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Date of Birth
                      </p>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <Controller
                          name="birth__date"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <DesktopDatePicker
                              {...rest}
                              inputFormat="DD/MM/YYYY"
                              disablePast={false}
                              disableFuture={false}
                              renderInput={(params) => (
                                <TextField {...params} sx={{ width: "100%" }} />
                              )}
                            />
                          )}
                          rules={{
                            validate: (value) =>
                              value === null ||
                              moment(value).isValid() ||
                              "Please enter a valid Date",
                          }}
                        />
                      </LocalizationProvider>
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.birth__date?.message}
                      </p>
                    </Box>
                  </Box>
                  <Box className={ContainersClasses.section__container}>
                    {/* Weight */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Weight
                      </p>
                      <TextField
                        placeholder="Weigth"
                        sx={{ width: "70%" }}
                        type="number"
                        {...register("weight", {})}
                      />
                      <Controller
                        name="weight__unit"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "28%", marginLeft: "3px" }}
                            className={LeadsClasses.select_container}
                          >
                            {weightOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue(
                                    "weight__unit",
                                    option.menuValue as Weight
                                  )
                                }
                                value={option.menuValue}
                                className={LeadsClasses.option_item}
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>

                    {/* Height */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Height
                      </p>
                      <TextField
                        placeholder="Height"
                        sx={{ width: "70%" }}
                        type="number"
                        {...register("height", {})}
                      />
                      <Controller
                        name="height__unit"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "28%", marginLeft: "3px" }}
                            className={LeadsClasses.select_container}
                          >
                            {heightOptions.map((option) => (
                              <MenuItem
                                key={option.menuValue}
                                onClick={() =>
                                  setValue(
                                    "height__unit",
                                    option.menuValue as Height
                                  )
                                }
                                value={option.menuValue}
                                className={LeadsClasses.option_item}
                              >
                                {option.menuText}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>
                  </Box>
                  <Box className={ContainersClasses.section__container}>
                    {/* Gender */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Gender
                      </p>
                      <Controller
                        name="gender"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref },
                        }) => (
                          <RadioGroup value={dataWatch?.gender} row>
                            <FormControlLabel
                              value="MALE"
                              control={<Radio />}
                              label="Male"
                              onClick={() => setValue("gender", "MALE")}
                            />
                            <FormControlLabel
                              value="FEMALE"
                              control={<Radio />}
                              label="Female"
                              onClick={() => setValue("gender", "FEMALE")}
                            />
                          </RadioGroup>
                        )}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.gender?.message}
                      </p>
                    </Box>

                    {/* Influencer */}
                    <FormControlLabel
                      sx={{ width: "100%", paddingTop: "35px" }}
                      control={
                        <Checkbox
                          checked={dataWatch.influencer}
                          color="secondary"
                        />
                      }
                      label="Influencer"
                      {...register("influencer")}
                    />
                  </Box>
                  {/* Phone Number */}
                  <Box width="53%">
                    <p className={ContainersClasses.section__subtitle}>
                      Phone Number
                    </p>
                    <PhoneInput
                      labelFormPrefix="phone__prefix"
                      labelFormNumber="phone__number"
                      placeholder="Phone Number"
                    />
                  </Box>
                </LeftElement>
              </ContainerLeft>
            </FormProvider>
          </ContainerFullWidth>
        </ContainersContent>
      </form>
    </FormProvider>
  );
};

export default UpdateCustomer;
