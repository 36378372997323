import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//material ui
import { Box, Button, Typography } from "@mui/material";
//style
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import Table from "../../layout/commun/Table";
import FilterHeader from "../../pageHeader/FilterHeader";
//mutations&querries
import {
  NumberOfDays,
  PlanCondition,
  useGetNewSignedUpCustomersQuery,
} from "../../../graphql/types";
//utils
import {
  columns,
  generateNewSignedUpCustomersReport,
  SIDE_FILTER_OPTIONS_NEW_SIGNED_UP_CUSTOMERS,
} from "./Utils";
import { convertNumberOfDaysEnum } from "../../customer/Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//context
import { useAuth } from "../../../contextAPI";

function NewSignedUpCustomers() {
  const navigate = useNavigate();
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");
  let auth = useAuth();

  //styles
  const ButtonClasses = useStylesButtons();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [idsProgram, setIdsProgram] = useState<string[]>([]);
  const [duration, setDuration] = useState<string[]>([]);
  const [numberOfDays, setNumberOfDays] = useState<NumberOfDays[]>([]);
  const [numberOfMeals, setNumberOfMeals] = useState<number[]>([]);
  const [from, setFrom] = useState<Date | null>(yesterday);
  const [to, setTo] = useState<Date | null>(new Date());
  const [planStatus, setPlanStatus] = useState<PlanCondition[]>([]);
  const [exportExcelLoading, setExportExcelLoading] = useState<boolean>(false);

  //queries&mutations
  const dataGetCustomers = useGetNewSignedUpCustomersQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        duration: duration,
        nbrOfDays: numberOfDays,
        meals: numberOfMeals,
        program: idsProgram,
        condition: planStatus,
        from: from,
        to: to,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
    fetchPolicy: "network-only",
  });

  const newSignedUpCustomers = useMemo(
    () => dataGetCustomers.data?.getNewSignedUpCustomers.data || [],
    [dataGetCustomers.data]
  );

  const count = useMemo(
    () => dataGetCustomers.data?.getNewSignedUpCustomers.count || 0,
    [dataGetCustomers.data]
  );

  const handleExportExcel = async () => {
    setExportExcelLoading(true);
    await generateNewSignedUpCustomersReport(
      auth.token || "",
      from || new Date(),
      to || new Date(),
      "CREATE_ASC",
      undefined,
      idsProgram,
      duration,
      [],
      numberOfDays,
      numberOfMeals,
      planStatus,
      [],
      undefined
    ).finally(() => {
      setExportExcelLoading(false);
    });
  };

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderTableRows = () => {
    return newSignedUpCustomers.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        id: item?.ref,
        name: isAuthorizedToSeeProfile ? (
          <a
            style={{ textDecoration: "none" }}
            href={`/admin/customers/view_customer/${item?._id}`}
          >
            <Button>{`${item?.fname} ${item?.lname}`}</Button>
          </a>
        ) : (
          item.fname + " " + item.lname
        ),
        mobile: item?.phone?.prefix + " " + item?.phone?.number,
        email: item?.email,
        date: moment(item.signedUpDate).format("DD-MMM-YYYY"),
        startDate: moment(item?.plan?.startDate).format("DD-MMM-YYYY"),
        endDate: item?.plan?.expiryDate
          ? moment(item?.plan?.expiryDate).format("DD-MMM-YYYY")
          : "----",
        plan: (
          <>
            <Typography style={{ fontSize: 12 }}>
              {item.plan?.program?.name?.EN +
                ", " +
                convertNumberOfDaysEnum(item?.plan?.nbrOfDays || "FIVE_DAYS")}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {item?.plan?.meals?.length +
                " meals, " +
                item?.plan?.paymentPeriod}
            </Typography>
          </>
        ),
        price: auth.countryCurrency + " " + item?.plan?.price?.toFixed(0),
        promoCode: item?.plan?.promoCode || "---",
        actions: isAuthorizedToSeeProfile && (
          <Button
            variant="contained"
            size="medium"
            className={ButtonClasses.button_cancel_form}
            onClick={() =>
              navigate(`/admin/customers/view_customer/${item?._id}`)
            }
          >
            Profile
          </Button>
        ),
      };
    });
  };

  return (
    <>
      <Box marginBottom={5}>
        <FilterHeader
          count={count}
          showSearch={false}
          pageTitle="New Signed up Customers"
          pageInfo="This is the list of customers who signed up for the first time yesterday or today"
          setDuration={setDuration}
          durationData={duration}
          setNumberOfDays={setNumberOfDays}
          daysData={numberOfDays}
          setNumberOfMeals={setNumberOfMeals}
          mealsData={numberOfMeals}
          setIdsProgram={setIdsProgram}
          programIdData={idsProgram}
          setPlanStatus={setPlanStatus}
          planStatus={planStatus}
          setFrom={setFrom}
          from={from}
          setTo={setTo}
          to={to}
          filterConfig={SIDE_FILTER_OPTIONS_NEW_SIGNED_UP_CUSTOMERS}
          loadExcel={exportExcelLoading}
          generateExcel={handleExportExcel}
        />
      </Box>
      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetCustomers.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No new signed up customers found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
    </>
  );
}

export default NewSignedUpCustomers;
