import React from "react";
import moment from "moment";
import CountUp from "react-countup";
//icons
import StatsDown from "../../../public/icons/StatsDown";
import StatsUp from "../../../public/icons/StatsUp";
//types
import { StatsCardProps } from "./Types";
//material-ui
import { Card, CardContent, Typography } from "@mui/material";

const StatsCard: React.FC<StatsCardProps> = ({
  title,
  endDate,
  number,
  description,
  up,
  total,
  styles = {},
  filteredProgramsList,
}) => {
  return (
    <div>
      <Card style={styles} variant="outlined">
        <CardContent>
          <Typography
            style={{ fontSize: 18, textAlign: "center" }}
            color="black"
          >
            {!total && "New"} {title} {!total && "Plans"}
          </Typography>
          {!total && (
            <Typography
              style={{ fontSize: 14, textAlign: "center" }}
              color="black"
            >
              {moment().startOf("month").format("DD/MM/YYYY")} -{" "}
              {moment(endDate).format("DD/MM/YYYY")}
            </Typography>
          )}

          <Typography
            style={{ fontSize: 30, textAlign: "center", marginBottom: 5 }}
            color="black"
          >
            <CountUp end={number || 0} duration={0.7} />
          </Typography>
          {!total && (
            <Typography
              style={{ fontSize: 10, textAlign: "center" }}
              color={up ? "#1BA9A5" : "#E55724"}
            >
              {up ? <StatsUp /> : <StatsDown />}{" "}
              {up ? description : Math.abs(description || 0)}
              {" % "}
              {up ? "more than last month" : "less than last month"}
            </Typography>
          )}
          {filteredProgramsList && (
            <Typography
              style={{ fontSize: 12, textAlign: "center", marginTop: 10 }}
              color="black"
            >
              {filteredProgramsList.join(", ")}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
export default StatsCard;
