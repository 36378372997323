import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Delete from "../../../public/icons/delete";
import { DialogContentText } from "@mui/material";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";

type DialogDeleteProps = {
  open: boolean;
  handleClose: () => void;
  handleSaveDelete: () => void;
};
const DialogDelete: React.FC<DialogDeleteProps> = (props) => {
  const { open, handleClose, handleSaveDelete } = props;
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={DialogClasses.dialog_container}
    >
      <DialogTitle className={DialogClasses.alert_dialog_title}>
        <Delete />
        <span className="alert_dialog_title_text">Delete Note ?</span>
      </DialogTitle>
      <DialogContent className={DialogClasses.alert_dialog_content}>
        <DialogContentText>
          Are you sure you want to delete this note ?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={DialogClasses.alert_dialog_actions}>
        <Button
          className={ButtonsClasses.GreyButton}
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          className={ButtonsClasses.RedButton}
          onClick={handleSaveDelete}
          color="primary"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogDelete;
