import { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesLeads } from "../../../styles/Leads__styles";
import { useStylesSelect } from "../../../styles/Select__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { StyledMenuItem } from "../CustomerMenuProgram/CustomerMenuProgram.styles";
//types
import { ExtendMealPlanState } from "../Types";
import { ApolloError } from "@apollo/client";
import { CustomerActionsTypes } from "./Types";
//queries&mutations
import {
  useExtendCustomerPlanMutation,
  useGetActifCustomerPlansQuery,
} from "../../../graphql/types";
//utils
import { getMessageError } from "../../Utils";
//context
import { CustomerContext } from "../viewCustomer/ViewCustomer";

const ExtendMealPlant: React.FC<CustomerActionsTypes> = ({
  expanded,
  handleChange,
}) => {
  const { id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const SelectClasses = useStylesSelect();
  const LeadsClasses = useStylesLeads();
  const ButtonClasses = useStylesButtons();
  const ContainersClasses = useStylesContainers();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [open, setOpen] = useState<boolean>(false);

  //react hook form
  const methodsExtendMealPlan = useForm<ExtendMealPlanState>({
    defaultValues: {
      reasonOfExtention: "",
      numberOfDays: "",
      program: null,
    },
  });
  const {
    formState: { errors },
    register,
    setValue,
    reset,
    control,
  } = methodsExtendMealPlan;

  const dataWatch = useWatch({
    control,
  }); 

  //context
  const context = useContext(CustomerContext);

  //mutations
  const [extendMealPlan, { loading: loadingExtendMealPlan }] =
    useExtendCustomerPlanMutation();

  const { data: CustomerActifPlansData } = useGetActifCustomerPlansQuery({
    variables: {
      customerId: id || "",
    },
    skip: !id || expanded != "EXTEND_MEAL_PLAN",
  });

  const CustomerActifPlans = CustomerActifPlansData?.getActifCustomerPlans;

  //functions

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSubmitExtendMealPlan: SubmitHandler<ExtendMealPlanState> = async (
    data
  ) => {
    try {
      await extendMealPlan({
        variables: {
          extendCustomerPlanInput: {
            extend: Number(data.numberOfDays),
            planId: data.program || "",
            reasonExtention: data.reasonOfExtention,
          },
        },
        onCompleted: () => {
          reset();
          enqueueSnackbar("Extension succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const renderItems = () => {
    return CustomerActifPlans?.map((item) => {
      return (
        <StyledMenuItem
          key={item._id}
          value={item?._id || ""}
          className={
            dataWatch?.program?._id === item._id
              ? clsx(
                  SelectClasses.option_item,
                  SelectClasses.option_item_selected
                )
              : SelectClasses.option_item
          }
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <span>{item?.program?.name?.EN}</span>
            <div className="left-side-container">
              <span>
                {`(${moment(item.startDate).format("DD/MM/YYYY")} - ${moment(
                  item.expiryDate
                ).format("DD/MM/YYYY")})`}{" "}
              </span>
            </div>
          </Box>
        </StyledMenuItem>
      );
    });
  };

  useEffect(() => {
    setValue("program", context?.getCustomerProfileById?.plan?._id || "");
  }, []);

  return (
    <Accordion
      expanded={expanded === "EXTEND_MEAL_PLAN"}
      onChange={handleChange("EXTEND_MEAL_PLAN")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="#B27D3F" fontSize={18}>
          Extend Meal Plan
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormProvider {...methodsExtendMealPlan}>
          <form
            onSubmit={methodsExtendMealPlan.handleSubmit(
              onSubmitExtendMealPlan
            )}
            id="EXTEND_MEAL_PLAN_FORM"
          >
            <Box display="flex" flexDirection="column" width="32%">
              <p className={clsx(ContainersClasses.section__subtitle)}>
                Plan to extend
              </p>
              <Controller
                name="program"
                control={control}
                render={({
                  field: { ref, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      {...rest}
                      sx={{ minWidth: "400px" }}
                      className={LeadsClasses.select_container}
                      onChange={(event) => {
                        let plan = event.target.value;
                        onChange(plan);
                      }}
                      value={dataWatch.program}
                    >
                      {renderItems()}
                    </Select>
                    <p className={ContainersClasses.section__error__helper}>
                      {error?.message}
                    </p>
                  </>
                )}
                rules={{
                  required: {
                    message: "Field Required",
                    value: true,
                  },
                }}
              />
            </Box>

            <Box display="flex" flexDirection="column" flexGrow={1} width="50%">
              <p className={ContainersClasses.section__subtitle}>
                Reason for extension
              </p>
              <TextField
                placeholder="Reason of extension"
                sx={{ width: "100%", minWidth: "500px" }}
                multiline={true}
                maxRows={4}
                minRows={4}
                {...register("reasonOfExtention", {
                  required: "Enter customer reason of extension",
                  minLength: {
                    value: 10,
                    message: "Please enter a least 10 characters",
                  },
                })}
              />
              <p className={ContainersClasses.section__error__helper}>
                {errors?.reasonOfExtention?.message}
              </p>
            </Box>
            <Box>
              <Box display="flex" flexDirection="column" width="50%">
                <p className={ContainersClasses.section__subtitle}>
                  Number of days
                </p>
                <TextField
                  autoComplete="off"
                  size="medium"
                  type="number"
                  sx={{ minWidth: "300px" }}
                  {...register("numberOfDays", {
                    required: "Field required",
                  })}
                />

                <p className={ContainersClasses.section__error__helper}>
                  {errors?.numberOfDays?.message}
                </p>
              </Box>
            </Box>

            <Box
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="flex-end"
              mt={1}
            >
              <Button
                variant="contained"
                className={ButtonClasses.button_open_list_page}
                onClick={() => setOpen(true)}
                disabled={loadingExtendMealPlan}
              >
                {loadingExtendMealPlan && (
                  <CircularProgress
                    size={20}
                    style={{ color: "white", marginRight: 6 }}
                  />
                )}
                Extend
              </Button>
            </Box>

            {/* CONFIRMATION MODAL */}
            <Dialog
              open={open}
              onClose={handleCloseModal}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.alert_dialog_title}>
                <span className="alert_dialog_title_text">Confirm Action</span>
              </DialogTitle>
              <DialogContent className={DialogClasses.alert_dialog_content}>
                <DialogContentText>
                  Are you sure you want to extend this meal plan ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={DialogClasses.alert_dialog_actions}>
                <Button
                  className={ButtonsClasses.GreyButton}
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  className={ButtonsClasses.GreenButton}
                  type="submit"
                  form="EXTEND_MEAL_PLAN_FORM"
                  color="primary"
                  autoFocus
                  onClick={() => setOpen(false)}
                >
                  Extend
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExtendMealPlant;
