import React, { memo, useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";
//material-ui
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { Box, Button, ButtonGroup, CircularProgress, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, TextField } from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import GroceryReportPrint from "./GroceryReportPrint";
//utils
import { useAuth } from "../../../contextAPI";
import { generateGroceryReport } from "../Utils";

const options = [
  "Today",
  "Yesterday",
  "Last 7 days",
  "Last 30 days",
  "This month",
  "Last month",
  "Custom range",
];

const GroceryReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  let auth = useAuth();

  //state

  const [showGenerate, setShowGenerate] = useState<boolean>(true);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = React.useState(6);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<{
    from: string | null ;
    to: string | null;
  }>({ from: null, to: null });
  const [dataGroceryReport, setDataGroceryReport] = useState();
  //styles
  const ButtonClasses = useStylesButtons();
  const printRef = useRef<HTMLDivElement>(null);
  //functions
  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    documentTitle:
      "Grocery Report " +
      moment(new Date()).format("DD/MM/YYYY dddd") +
      " generated on " +
      moment().format("DD/MM/YYYY dddd"),
  });
  const onGenerateHandler = async () => {
    setShowGenerate(false);
    setLoading(true);
    try {

      let reportData;
      if (options[selectedIndex] === "Custom range") {
        reportData = await generateGroceryReport(auth.token || "", moment(startDate).toDate(),moment(endDate).toDate());
      } else {
        reportData = await generateGroceryReport(auth.token || "", moment(value.from).add(1, 'days').toDate(), moment(value.to).add(1, 'days').toDate() );
      }
      setDataGroceryReport(reportData);
      handlePrint();
    } catch (error) {
      
     console.log("error",error)
    } finally {
      setShowGenerate(true);
      setLoading(false);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    option: string
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    switch (option) {
      case "Today":
        setValue({
          from: dayjs().format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        });
        setStartDate(null);
        setEndDate(null);
        break;
      case "Yesterday":
        setValue({
          from: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
          to: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
        });
        setStartDate(null);
        setEndDate(null);
        break;
      case "Last 7 days":
        setValue({
          from: dayjs().subtract(6, "day").startOf("day").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        });
        setStartDate(null);
        setEndDate(null);
        break;
      case "Last 30 days":
        setValue({
          from: dayjs().subtract(29, "day").startOf("day").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        });
        setStartDate(null);
        setEndDate(null);
        break;
      case "This month":
        setValue({
          from: dayjs().startOf("month").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        });
        setStartDate(null);
        setEndDate(null);
        break;
      case "Last month":
        setValue({
          from: dayjs()
            .subtract(1, "months")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: dayjs().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
        });
        setStartDate(null);
        setEndDate(null);
        break;
      case "Custom range":
        setValue({
          from: null,
          to: null,
        });
        break;
      default:
        setValue({
          from: null,
          to: null,
        });
    }
  };


  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={600}
    >
      <Box
        width="40%"
        height="70%"
        bgcolor="#ffffff73"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection="column"
        borderRadius={5}
        border={1}
        borderColor="#e7e7e773"
      >
        <Box
          fontFamily="Poppins"
          fontSize={30}
          fontWeight="600"
          alignSelf="flex-start"
          marginLeft="25%"
          marginBottom='10px'
        >
          Grocery Report
        </Box>


{/* customer Range Button */}

<Box marginBottom='10px' marginLeft='30%'>
        <ButtonGroup
  variant="outlined"
  ref={anchorRef}
  aria-label="split button"
  color="primary"
  disableElevation

  sx={{ ml: "auto" }}  // This pushes the button group to the right
>
  <Button disableElevation sx={{ width: 145 }}>
    {options[selectedIndex]}
  </Button>
  <Button
    variant="contained"
    size="small"
    aria-controls={open ? "split-button-menu" : undefined}
    aria-expanded={open ? "true" : undefined}
    aria-label="select merge strategy"
    aria-haspopup="menu"
    onClick={handleToggle}
    disableElevation
    disabled={!showGenerate  }
  >
    <ArrowDropDownIcon />
  </Button>
</ButtonGroup>

<Popper
  sx={{
    zIndex: 1,
  }}
  open={open}
  anchorEl={anchorRef.current}
  role={undefined}
  transition
  disablePortal
>
  {({ TransitionProps, placement }) => (
    <Grow
      {...TransitionProps}
      style={{
        transformOrigin:
          placement === "bottom" ? "center top" : "center bottom",
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList id="split-button-menu" autoFocusItem>
            {options.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === selectedIndex}
                onClick={(event) =>
                  handleMenuItemClick(event, index, option)
                }
                sx={{ width: 175 }}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Grow>
  )}
</Popper>

        </Box>


<Box width="70%"  marginBottom='10px'>
          <Box width="100%">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                value={startDate}
                inputFormat="DD/MM/YYYY"
                disablePast={false}
                disableFuture={false}
                disabled={options[selectedIndex] !== "Custom range"}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Start Date"
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Box width="70%" marginBottom='10px'>
          <Box width="100%">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                value={endDate}
                inputFormat="DD/MM/YYYY"
                disablePast={false}
                disableFuture={false}
                disabled={options[selectedIndex] !== "Custom range" || !startDate}
                minDate={startDate || null}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="End Date"
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
    
 
        <Box
          width="70%"
          height={100}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            type="submit"
            disabled={!showGenerate ||  options[selectedIndex] === "Custom range" &&  !endDate}
            onClick={onGenerateHandler}
            sx={{ width: "100%", height: "50%" }}
            
          >
            {loading ?   <CircularProgress
              size={20}
              color="secondary"
              style={{ marginLeft: "6px" }}
            /> :  "Generate Reports"}
           
          </Button>
          <Box
            fontFamily="Poppins"
            fontSize={10}
            fontWeight="500"
            alignSelf="center"
          >
            Use this table to generate grocery report .
          </Box>
        </Box>
        <div style={{ display: "none" }}>
          <GroceryReportPrint
            generateGroceryReport={dataGroceryReport }
            ref={printRef}
            deliveryDate={options[selectedIndex] === "Custom range" ? endDate : value.from}
            startDate={options[selectedIndex] === "Custom range" ? startDate : value.from}
            endDate={options[selectedIndex] === "Custom range" ? endDate : value.to}
          />
        </div>
      </Box>
    </Box>
  );
};

export default memo(GroceryReport);
