import { useState, useMemo, useCallback } from "react";
import moment from "moment";
import { FiSearch } from "react-icons/fi";
import _ from "lodash";
//material ui
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DownloadIcon from "@mui/icons-material/Download";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { TableContainer } from "../../../styles/Table__styles";
import {
  HeaderSearch,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../styles/Navigation__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
//components
import Table from "../../layout/commun/Table";
import Status from "../../Status/Status";
//mutations&querries
import {
  AddonsPackedPayload,
  DriverPayload,
  PackerGroupPayload,
  SaucesPackedPayload,
  useGetDriversQuery,
  useGetPackedListByDriverQuery,
  useGetPackerGroupsQuery,
} from "../../../graphql/types";
//utils
import {
  DELIVERY_TIME,
  columns,
  formatAddOnsToDishes,
  formatSaucesToDishes,
  packingResults,
} from "./Utils";
import { handleStatus } from "../../customer/Utils";

function PackingAppDashboard() {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //styles
  const ButtonClasses = useStylesButtons();
  const DialogClasses = useStylesDialog();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [date, setDate] = useState<Date | null>(new Date());
  const [driver, setDriver] = useState<DriverPayload[] | []>([]);
  const [deliveryTime, setDeliveryTime] = useState<string | null>();
  const [group, setGroup] = useState<PackerGroupPayload | null>();
  const [search, setSearch] = useState<string>("");
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [image, setImage] = useState<string>("");

  //queries&mutations
  const dataDriver = useGetDriversQuery();
  const dataGetPackedList = useGetPackedListByDriverQuery({
    variables: {
      packedListInput: {
        page: page,
        documentPerPage: rowsPerPage,
        customerName: search,
        deliverDate: date,
        deliveryTime: deliveryTime === "ALL" ? null : deliveryTime,
        driver:
          driver.length > 0
            ? driver.map((el) => el._id).filter((id): id is string => !!id)
            : null,
        packerGroup: group?._id ? [group?._id || ""] : null,
      },
    },
  });
  const listGroups = useGetPackerGroupsQuery({
    variables: { date: date || new Date() },
    fetchPolicy: "network-only",
  });

  const packedList = useMemo(
    () => dataGetPackedList.data?.getPackedListByDriver.data || [],
    [dataGetPackedList.data]
  );

  const count = useMemo(
    () => dataGetPackedList.data?.getPackedListByDriver.count || 0,
    [dataGetPackedList.data]
  );

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const RenderCheckIcon = ({ firstCheckedAt, secondCheckedAt }: any) => {
    if (!firstCheckedAt || !secondCheckedAt) {
      return <CheckCircleRoundedIcon sx={{ height: 15, width: 15 }} />;
    }

    const minutesDiff = packingResults(firstCheckedAt, secondCheckedAt);

    if (minutesDiff <= 5) {
      return (
        <CheckCircleRoundedIcon
          sx={{ height: 15, width: 15 }}
          color="success"
        />
      );
    }

    if (minutesDiff < 1440) {
      return (
        <CheckCircleRoundedIcon
          sx={{ height: 15, width: 15 }}
          color="secondary"
        />
      );
    }

    return (
      <CheckCircleRoundedIcon sx={{ height: 15, width: 15 }} color="error" />
    );
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const openImageModalHandler = (image: string) => {
    setOpenImageModal(true);
    setImage(image);
  };

  const renderTableRows = () => {
    return packedList.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        customer: item.customerName,
        group: item.packerGroup,
        driver: item.driver,
        date: moment(item.date).format("DD-MMM-YYYY"),
        status: (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Status
              status={item?.status as any}
              statusText={handleStatus(item?.status)}
            />
          </Box>
        ),

        meals: [
          ...(item.dishs as any),
          ...formatSaucesToDishes(item.sauces as SaucesPackedPayload[]),
          ...formatAddOnsToDishes(item.addons as AddonsPackedPayload[]),
        ]?.map((el, index) => {
          return (
            <Box display="flex" flexDirection="row" key={index}>
              <Box marginLeft={2} marginRight={1} width={7}>
                {index + 1}
              </Box>
              <Box minWidth={50} textAlign="left">
                {el.code}
              </Box>
              <Box textAlign="left">{el.dish}</Box>
            </Box>
          );
        }),

        firstCheck: [
          ...(item.dishs as any),
          ...formatSaucesToDishes(item.sauces as SaucesPackedPayload[]),
          ...formatAddOnsToDishes(item.addons as AddonsPackedPayload[]),
        ]?.map((el, index) => {
          return (
            <Box display="flex" flexDirection="row" width={120} key={index}>
              <div>
                {el.firstCheckedAt
                  ? moment(el.firstCheckedAt || new Date()).format("HH:mm a")
                  : "----"}
              </div>
              <div>{el.firstCheckedBy ? `-${el.firstCheckedBy}` : ""}</div>
            </Box>
          );
        }),

        secondCheck: [
          ...(item.dishs as any),
          ...formatSaucesToDishes(item.sauces as SaucesPackedPayload[]),
          ...formatAddOnsToDishes(item.addons as AddonsPackedPayload[]),
        ]?.map((el, index) => {
          return (
            <Box display="flex" flexDirection="row" width={150} key={index}>
              <div>
                {el.secondCheckedAt
                  ? moment(el.secondCheckedAt || new Date()).format("HH:mm a")
                  : "----"}
              </div>
              <div>{el.secondCheckedBy ? `-${el.secondCheckedBy}` : ""}</div>
            </Box>
          );
        }),

        packed: [
          ...(item.dishs as any),
          ...formatSaucesToDishes(item.sauces as SaucesPackedPayload[]),
          ...formatAddOnsToDishes(item.addons as AddonsPackedPayload[]),
        ]?.map((el, index) => {
          return (
            <Box display="flex" flexDirection="row" key={index}>
              <RenderCheckIcon
                firstCheckedAt={el?.firstCheckedAt}
                secondCheckedAt={el?.secondCheckedAt}
              />
            </Box>
          );
        }),
        photo: item.photo ? (
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              size="medium"
              className={ButtonClasses.button_cancel_form}
              sx={{ marginRight: 1 }}
              onClick={() => openImageModalHandler(item.photo || "")}
            >
              Image
            </Button>
            <a href={item.photo} target="_blank" download>
              <DownloadIcon color={"action"} />
            </a>
          </Box>
        ) : (
          "---"
        ),
      };
    });
  };

  const debounced = useCallback(
    _.debounce((event) => {
      setSearch(event.target.value);
    }, 800),
    []
  );

  return (
    <>
      <Box marginBottom={5}>
        <Box marginLeft={40} marginRight={40} marginTop={3} marginBottom={3}>
          <HeaderSearch>
            <SearchIconWrapper>
              <FiSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search..."
              inputProps={{ "aria-label": "search" }}
              onChange={(event) => debounced(event)}
            />
          </HeaderSearch>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              value={date}
              inputFormat="DD/MM/YYYY"
              disablePast={false}
              disableFuture={false}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Date"
                  sx={{
                    backgroundColor: "#fff",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          alignSelf="center"
          paddingLeft={42}
          paddingRight={42}
        >
          <Box marginTop={3} display="flex" alignItems="center" minWidth="30%">
            <Autocomplete
              id="driver"
              multiple
              sx={{ width: "100%" }}
              options={dataDriver?.data?.getDrivers || []}
              onChange={(_, value) => {
                setDriver(value);
              }}
              value={driver}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="secondary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a driver"
                  label="Driver"
                />
              )}
            />
          </Box>
          <Box marginTop={3} display="flex" alignItems="center" minWidth="30%">
            <Autocomplete
              id="chosenDeliveryTime"
              sx={{ width: "100%" }}
              options={DELIVERY_TIME}
              onChange={(_, value) => {
                setDeliveryTime(value);
              }}
              value={deliveryTime}
              getOptionLabel={(option) => option || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="secondary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a Delivery Time"
                  label="Delivery Time"
                />
              )}
            />
          </Box>

          <Box marginTop={3} display="flex" alignItems="center" minWidth="30%">
            <Autocomplete
              id="group"
              sx={{ width: "100%" }}
              options={listGroups.data?.getPackerGroups || []}
              onChange={(_, value) => {
                setGroup(value);
              }}
              value={group}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="secondary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a Group"
                  label="Group"
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetPackedList.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No packs found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>

      {/* IMAGE MODAL */}
      <Dialog
        open={openImageModal}
        onClose={handleCloseImageModal}
        className={DialogClasses.dialog_image_container}
      >
        <Avatar
          alt={image || ""}
          src={image || ""}
          variant="rounded"
          style={{ width: 500, height: 500 }}
        />
      </Dialog>
    </>
  );
}

export default PackingAppDashboard;
