import { useMemo, useContext, useEffect, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Select,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesLeads } from "../../../styles/Leads__styles";
import { useStylesSelect } from "../../../styles/Select__styles";
import { useStylesInput } from "../../../styles/Input__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { StyledMenuItem } from "../CustomerMenuProgram/CustomerMenuProgram.styles";
//mutations&queries
import {
  GetCustomerProfileByIdDocument,
  NumberOfDays,
  useGetActifCustomerPlansQuery,
  useUpdateDaysOffMutation,
} from "../../../graphql/types";
//utils
import { convertNumberOfDaysEnum, handleDays, weekDays } from "../Utils";
import { getMessageError } from "../../Utils";
//context
import { CustomerContext } from "../viewCustomer/ViewCustomer";
//types
import { ApolloError } from "@apollo/client";
import { ChangeDaysOff } from "../Types";
import { CustomerActionsTypes } from "./Types";

const EditDaysOff: React.FC<CustomerActionsTypes> = ({
  expanded,
  handleChange,
}) => {
  const { id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //state
  const [daysPerWeek, setDaysPerWeek] = useState("");
  const [open, setOpen] = useState<boolean>(false);

  //styles
  const LeadsClasses = useStylesLeads();
  const ContainersClasses = useStylesContainers();
  const SelectClasses = useStylesSelect();
  const InputClasses = useStylesInput();
  const ButtonClasses = useStylesButtons();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //context
  const context = useContext(CustomerContext);

  //react hook form
  const methodsEditCalories = useForm<ChangeDaysOff>({
    defaultValues: {
      customerProgram: "",
      daysOff: [],
    },
  });

  const { control, handleSubmit, setValue, reset } = methodsEditCalories;

  const dataWatch = useWatch({
    control,
  });

  //queries&mutations
  const [updateDaysOff, { loading: loadingEditCustomerPlanDaysOff }] =
    useUpdateDaysOffMutation();

  const { data: CustomerActifPlansData } = useGetActifCustomerPlansQuery({
    variables: {
      customerId: id || "",
    },
    skip: !id || expanded != "EDIT_DAYS_OFF",
  });

  const CustomerActifPlans = useMemo(
    () => CustomerActifPlansData?.getActifCustomerPlans,
    [CustomerActifPlansData?.getActifCustomerPlans]
  );

  useEffect(() => {
    setValue(
      "customerProgram",
      context?.getCustomerProfileById?.plan?._id || ""
    );
    setValue("daysOff", context?.getCustomerProfileById?.plan?.daysOff || []);
    setDaysPerWeek(context?.getCustomerProfileById?.plan?.nbrOfDays || "");
  }, []);

  //functions
  const renderItems = () => {
    return CustomerActifPlans?.map((item) => {
      return (
        <StyledMenuItem
          key={item._id}
          value={item?._id || ""}
          className={
            dataWatch?.customerProgram === item._id
              ? clsx(
                  SelectClasses.option_item,
                  SelectClasses.option_item_selected
                )
              : SelectClasses.option_item
          }
          onClick={() => {
            setValue("daysOff", item?.daysOff || []);
            setDaysPerWeek(item?.nbrOfDays || "");
          }}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <span>{item?.program?.name?.EN}</span>
            <div className="left-side-container">
              <span>
                {`(${moment(item.startDate).format("DD/MM/YYYY")} - ${moment(
                  item.expiryDate
                ).format("DD/MM/YYYY")})`}{" "}
              </span>
            </div>
          </Box>
        </StyledMenuItem>
      );
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSubmitEditDaysOff: SubmitHandler<ChangeDaysOff> = async (data) => {
    //test whether the combination between days per week and days off is correct
    if (
      (daysPerWeek === "FIVE_DAYS" && dataWatch?.daysOff?.length != 2) ||
      (daysPerWeek === "SIX_DAYS" && dataWatch?.daysOff?.length != 1) ||
      (daysPerWeek === "SEVEN_DAYS" && dataWatch?.daysOff?.length != 0)
    ) {
      enqueueSnackbar(
        "Please Verify the combination between Days per week and Days off",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
    } else {
      try {
        await updateDaysOff({
          variables: {
            input: {
              planId: dataWatch.customerProgram || "",
              daysOff: dataWatch.daysOff || [],
            },
          },
          refetchQueries: [
            {
              query: GetCustomerProfileByIdDocument,
              variables: {
                customerId: context?.getCustomerProfileById._id || "",
              },
            },
          ],
          onCompleted: () => {
            reset();
            enqueueSnackbar("Days off changed successfully", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
  };

  return (
    <Accordion
      expanded={expanded === "EDIT_DAYS_OFF"}
      onChange={handleChange("EDIT_DAYS_OFF")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="#B27D3F" fontSize={18}>
          Edit Days Off
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormProvider {...methodsEditCalories}>
          <form
            onSubmit={handleSubmit(onSubmitEditDaysOff)}
            id="EDIT_DAYS_OFF_FORM"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box display="flex" flexDirection="column" marginRight={5}>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Plan to edit its days off: (
                  {convertNumberOfDaysEnum(daysPerWeek as NumberOfDays)} per
                  week )
                </p>
                <Controller
                  name="customerProgram"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        {...rest}
                        sx={{ minWidth: "400px" }}
                        className={LeadsClasses.select_container}
                        onChange={(event) => {
                          let plan = event.target.value;
                          onChange(plan);
                        }}
                        value={dataWatch.customerProgram}
                      >
                        {renderItems()}
                      </Select>
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {error?.message}
                      </p>
                    </>
                  )}
                  rules={{
                    required: {
                      message: "Field Required",
                      value: true,
                    },
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Days off
                </p>
                <Controller
                  name="daysOff"
                  control={control}
                  render={({
                    field: { onChange: Change, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        {...rest}
                        multiple
                        id="daysOff"
                        value={dataWatch.daysOff || []}
                        options={weekDays}
                        getOptionLabel={(option) => handleDays(option) ?? ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputRef={ref}
                            variant="outlined"
                            sx={{ minWidth: "400px" }}
                          />
                        )}
                        onChange={(_, data) => {
                          Change(data);
                        }}
                      />
                      <p className={InputClasses.input__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                marginBottom={4}
              >
                <Button
                  sx={{ marginTop: 6 }}
                  variant="contained"
                  className={clsx(ButtonClasses.button_open_list_page)}
                  onClick={() => setOpen(true)}
                  disabled={loadingEditCustomerPlanDaysOff}
                >
                  {loadingEditCustomerPlanDaysOff && (
                    <CircularProgress
                      size={20}
                      style={{ color: "white", marginRight: 6 }}
                    />
                  )}
                  Edit
                </Button>
              </Box>
            </Box>

            {/* CONFIRMATION MODAL */}
            <Dialog
              open={open}
              onClose={handleCloseModal}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.alert_dialog_title}>
                <span className="alert_dialog_title_text">Confirm Action</span>
              </DialogTitle>
              <DialogContent className={DialogClasses.alert_dialog_content}>
                <DialogContentText>
                  Are you sure you want to edit the days off of this meal plan ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={DialogClasses.alert_dialog_actions}>
                <Button
                  className={ButtonsClasses.GreyButton}
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  className={ButtonsClasses.GreenButton}
                  type="submit"
                  form="EDIT_DAYS_OFF_FORM"
                  color="primary"
                  autoFocus
                  onClick={() => setOpen(false)}
                >
                  Edit
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default EditDaysOff;
