import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
import { useSnackbar } from "notistack";
//material ui
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//style
import { TableContainer } from "../../styles/Table__styles";
import { useStylesButtons } from "../../styles/Buttons__styles";
//components
import Table from "../layout/commun/Table";
import FilterHeader from "../pageHeader/FilterHeader";
import Status from "../Status/Status";
//mutations&querries
import {
  GetRemindersListDocument,
  ReminderStatus,
  useCloseReminderMutation,
  useDeleteReminderMutation,
  useGetRemindersListLazyQuery,
  useGetRemindersListQuery,
} from "../../graphql/types";
//utils
import { useIsAuthorized } from "../../utils/Hooks/useIsAuthorized";
import { getMessageError } from "../Utils";
import { SIDE_FILTER_OPTIONS_REMINDERS, columns } from "./Utils";
import { handleStatus } from "../customer/Utils";
//Style
import { useStylesMenu } from "../../styles/Menu__styles";
import { useStylesDialog } from "../../styles/Dialog__styles";
//icons
import Delete from "../../public/icons/delete";
import { Info } from "@mui/icons-material";

function RemindersList() {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [mine, setMine] = useState<boolean | null>(null);
  const [search, setSearch] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [idReminder, setIdReminder] = useState<string | null>(null);
  const [reminderStatus, setReminderStatus] = useState<ReminderStatus[]>([]);
  const [selectedReminderStatus, setSelectedReminderStatus] = useState("");
  const [expandedDescriptions, setExpandedDescriptions] = useState<{
    [key: string]: boolean;
  }>({});
  //queries&mutations
  const dataGetReminders = useGetRemindersListQuery({
    variables: {
      input: {
        search: search,
        page: page,
        documentPerPage: rowsPerPage,
        from: from,
        to: to,
        mine: mine ?? false,
        status: reminderStatus,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
    fetchPolicy: "network-only",
  });
  const Reminders = useMemo(
    () => dataGetReminders.data?.getRemindersList.data || [],
    [dataGetReminders.data]
  );

  const count = useMemo(
    () => dataGetReminders.data?.getRemindersList.count || 0,
    [dataGetReminders.data]
  );

  const [deleteReminder] = useDeleteReminderMutation();
  const [closeReminder] = useCloseReminderMutation();

  const [getRemindersData, { loading: RemindersListLoading }] =
    useGetRemindersListLazyQuery();

  const handleExportExcel = async () => {
    await getRemindersData({
      variables: {
        input: {
          page: 1,
          documentPerPage: count,
          from: from,
          to: to,
          mine: mine ?? false,
          status: reminderStatus,
        },
      },
    })
      .then((data) => {
        if (count != 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const fileExtension = ".xlsx";
          const filteredCustomers = data?.data?.getRemindersList?.data?.map(
            (el, index) => {
              return {
                index: index + 1,
                title: el.title,
                customer: el?.customer?.fname + " " + el?.customer?.lname,
                createdBy: el?.createdBy?.fname + " " + el?.createdBy?.lname,
                closed_By: el?.closedBy?.fname
                  ? el?.closedBy?.fname + " " + el?.closedBy?.lname
                  : "---",
                closedAt: el?.closedAt
                  ? moment(el?.closedAt).format("DD-MMM-YYYY")
                  : "---",
                Description: el?.description,
                priority: el?.priority,
                status: el?.status,
                date: el?.date ? moment(el?.date).format("DD-MMM-YYYY") : "---",
              };
            }
          );
          const ws = XLSX.utils.json_to_sheet(filteredCustomers || []);
          ws["!cols"] = [
            { wpx: 40 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 170 },
            { wpx: 80 },
            { wpx: 70 },
            { wpx: 300 },
            { wpx: 80 },
            { wpx: 80 },
          ];
          const wrapText = { alignment: { wrapText: true } };
          if (filteredCustomers) {
            for (let i = 1; i <= filteredCustomers.length; i++) {
              const cellRef = `G${i + 1}`;
              if (ws[cellRef]) {
                if (filteredCustomers[i - 1]?.Description) {
                  ws[cellRef].s = wrapText;
                }
              }
            }
          }
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const res = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            res,
            "reminders report" +
              " generated on " +
              moment().format("DD/MM/YYYY dddd LT") +
              fileExtension
          );
          return "Done";
        }
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };

  //functions
  const handleClickMenu =
    (item: string, status: string) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setIdReminder(item || null);
      setSelectedReminderStatus(status);
    };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (idReminder) {
      navigate(`/admin/reminders/update_reminder/${idReminder}`);
    }
  };
  const handleCloseDelete = () => {
    setAnchorEl(null);
    setOpenDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDelete(false);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const DeleteReminderHandler = async () => {
    setOpenDelete(false);
    await deleteReminder({
      variables: { input: idReminder || "" },
      refetchQueries: [GetRemindersListDocument],
      onCompleted() {
        enqueueSnackbar("Reminder deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };
  const CloseReminderHandler = async () => {
    setOpen(false);
    await closeReminder({
      variables: { closeReminderInput: { _id: idReminder || "" } },
      refetchQueries: [GetRemindersListDocument],
      onCompleted() {
        enqueueSnackbar("Reminder closed succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };
  const renderDescription = (id: string, description: string) => {
    const maxLength = 100;
    const isExpanded = expandedDescriptions[id];

    const toggleRowDescription = () => {
      setExpandedDescriptions((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    };

    const renderShortDescription = () => {
      if (description.length > maxLength) {
        return (
          <>
            {description.substring(0, maxLength)}
            <span
              onClick={toggleRowDescription}
              style={{ cursor: "pointer", color: "black" }}
            >
              ... See more
            </span>
          </>
        );
      } else {
        return description;
      }
    };

    const renderExpandedDescription = () => {
      return (
        <>
          {description}
          <span
            onClick={toggleRowDescription}
            style={{ cursor: "pointer", color: "black" }}
          >
            See less
          </span>
        </>
      );
    };

    return isExpanded ? renderExpandedDescription() : renderShortDescription();
  };

  const renderTableRows = () => {
    return Reminders.map((item, index) => {
      const id = item?._id || "";
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        title: item.title,
        customer:
          isAuthorizedToSeeProfile && item?.customer ? (
            <a
              style={{ textDecoration: "none" }}
              href={`/admin/customers/view_customer/${item?.customer?._id}`}
            >
              <Button>
                {item?.customer?.fname} {item?.customer?.lname}
              </Button>
            </a>
          ) : (
            "----"
          ),
        created_By: item?.createdBy?.fname + " " + item?.createdBy?.lname,
        closed_By: item?.closedBy?.fname
          ? item?.closedBy?.fname + " " + item?.closedBy?.lname
          : "---",
        closed_At: item?.closedAt
          ? moment(item?.closedAt).format("DD-MMM-YYYY")
          : "---",
        description: (
          <div
            style={{
              maxWidth: "300px",
              overflowWrap: "break-word",
              marginLeft: "80px",
            }}
          >
            {renderDescription(id, item?.description ?? "")}
          </div>
        ),
        priority: item?.priority,
        status: (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Status
              status={item?.status}
              statusText={handleStatus(item?.status)}
            />
          </Box>
        ),
        date: item?.date ? moment(item?.date).format("DD-MMM-YYYY") : "---",

        actions: (
          <IconButton
            onClick={handleClickMenu(item._id ?? "", item?.status ?? "")}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      };
    });
  };

  return (
    <>
      <Box marginBottom={5}>
        <FilterHeader
          buttonLink={
            useIsAuthorized("MANAGEMENT_REMINDER_CREATE")
              ? "/admin/reminders/add_reminder"
              : undefined
          }
          buttonText={
            useIsAuthorized("MANAGEMENT_REMINDER_CREATE") ? "Create" : undefined
          }
          setSearchValue={setSearch}
          showSearch={true}
          pageTitle="Reminders"
          pageInfo="This is the list of reminders"
          setFrom={setFrom}
          from={from}
          setTo={setTo}
          to={to}
          setIdsProgram={() => null}
          hideProgram={true}
          reminderStatus={reminderStatus}
          setReminderStatus={setReminderStatus}
          filterConfig={SIDE_FILTER_OPTIONS_REMINDERS}
          loadExcel={RemindersListLoading}
          generateExcel={handleExportExcel}
        />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom="30px"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={mine || false}
              onChange={(e) => setMine(e.target.checked)}
            />
          }
          label="Mine"
        />
      </Box>

      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetReminders.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Reminders found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("MANAGEMENT_REMINDER_UPDATE") &&
            selectedReminderStatus == "PENDING" && (
              <MenuItem
                onClick={handleCloseUpdate}
                className={classes.menu_container}
                disableRipple
              >
                <Box>
                  <span className="menu_title">Update</span>
                </Box>
              </MenuItem>
            )}

          {useIsAuthorized("MANAGEMENT_REMINDER_CLOSE") &&
            selectedReminderStatus == "PENDING" && (
              <MenuItem
                onClick={handleClose}
                className={classes.menu_container}
                disableRipple
              >
                <Box>
                  <span className="menu_title">Close</span>
                </Box>
              </MenuItem>
            )}
          {useIsAuthorized("MANAGEMENT_REMINDER_DELETE") && (
            <MenuItem
              onClick={handleCloseDelete}
              className={classes.menu_delete_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>
        <Dialog
          open={openDelete}
          onClose={handleCloseModalDelete}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Reminder ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this reminder?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModalDelete}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={DeleteReminderHandler}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Info />
            <span className="confirm_dialog_title_text">
              close this reminder ?
            </span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to close this reminder ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.BlueButton}
              onClick={CloseReminderHandler}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default RemindersList;
