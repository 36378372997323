import { useAuth } from "../../contextAPI";

function AdminIcon() {
  const auth = useAuth();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      fill="none"
      viewBox="0 0 19 20"
    >
      <path
        fill={auth.countryCode === "SA" ? "#008000" : "#fff"}
        fillRule="evenodd"
        d="M8.549.014C7.308.17 6.309.64 5.449 1.47c-1.9 1.833-2.104 4.803-.472 6.9 1.57 2.019 4.436 2.588 6.638 1.319a5.19 5.19 0 002.534-5.494C13.767 2.17 12.133.52 10.1.104c-.386-.08-1.244-.13-1.551-.09zM4.877 10.81c-1.73.304-3.315 1.377-4.304 2.913-.21.325-.462.875-.462 1.005 0 .257.743 1.302 1.446 2.033 1.716 1.787 3.831 2.851 6.27 3.155.507.064 1.922.063 2.421-.001 1.976-.253 3.733-.99 5.254-2.206.47-.376 1.4-1.335 1.764-1.821.412-.55.736-1.077.736-1.196 0-.116-.22-.597-.41-.894-.668-1.045-1.597-1.895-2.64-2.413-.762-.378-1.711-.641-2.19-.607a.823.823 0 00-.431.148c-.565.356-.742.455-1.057.592a5.395 5.395 0 01-2.413.452 5.409 5.409 0 01-2.885-.925c-.196-.129-.405-.245-.464-.258l-.166-.036a3.092 3.092 0 00-.47.06z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default AdminIcon;
