import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ReactPlayer from "react-player";
//material ui
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
//styles
import { useStylesButtons } from "../../../../styles/Buttons__styles";
import { useStylesRecipe } from "../../../../styles/Recipe__styles";
import { useStylesForms } from "../../../../styles/Forms__styles";
//queries&mutations
import { useGetTrainerByIdQuery } from "../../../../graphql/types";
//utils
import { handleNbrDays } from "../../../Utils";
import { useIsAuthorized } from "../../../../utils/Hooks/useIsAuthorized";
//context
import { useAuth } from "../../../../contextAPI";

const TrainerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useAuth();

  //styles
  const ButtonClasses = useStylesButtons();
  const RecipeClasses = useStylesRecipe();
  const FormsClasses = useStylesForms();

  //state
  const [videoUrl, setVideoUrl] = useState("");

  //querries&mutations
  const { data } = useGetTrainerByIdQuery({
    variables: {
      input: id || "",
    },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  const trainer = useMemo(
    () => data?.getTrainerById || null,
    [data?.getTrainerById]
  );

  //functions
  const renderMeals = () => {
    return trainer?.plan?.meals?.map((item, index) => (
      <Chip
        key={item}
        label={item}
        variant="outlined"
        className={FormsClasses.chips__tags}
      />
    ));
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(videoUrl || "");
  };

  const navigateToCustomerProfile = () => {
    if (trainer?.customer?._id) {
      navigate(`/admin/customers/view_customer/${trainer?.customer?._id}`);
    }
  };

  const navigateToUpdate = () => {
    if (trainer?._id) {
      navigate(`/data/trainers/update_trainer/${trainer?._id}`);
    }
  };

  useEffect(() => {
    if (trainer) {
      setVideoUrl(trainer?.video || "");
    }
  }, [trainer]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          size="large"
          sx={{
            paddingLeft: 6,
            paddingRight: 6,
            paddingTop: 1.5,
            paddingBottom: 1.5,
          }}
          onClick={navigateToCustomerProfile}
          className={ButtonClasses.button_submit_form}
          disabled={!trainer?.customer?._id}
        >
          View Trainer Customer Profile
        </Button>

        {useIsAuthorized("MANAGEMENT_TRAINER_UPDATE") && (
          <Button
            variant="contained"
            size="large"
            sx={{
              marginLeft: 2,
              paddingLeft: 6,
              paddingRight: 6,
              paddingTop: 1.5,
              paddingBottom: 1.5,
            }}
            onClick={navigateToUpdate}
            className={ButtonClasses.button_submit_form}
          >
            Edit
          </Button>
        )}
      </Box>

      {!trainer?.customer?._id && (
        <Typography
          textAlign="right"
          color="red"
          fontFamily="Poppins"
          fontSize={11}
          marginRight="170px"
        >
          There is no registered customer for this trainer
        </Typography>
      )}

      <Box
        display="grid"
        gridTemplateColumns="2fr 1fr"
        columnGap={6}
        marginTop={6}
      >
        <Box width="100%">
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="row">
              <Avatar
                alt={trainer?.fname?.EN || ""}
                src={trainer?.picture || ""}
                variant="rounded"
                style={{ width: 200, height: 200 }}
              />
              <Box marginLeft={4}>
                <Box>{trainer?.fname?.EN! + " " + trainer?.lname?.EN!}</Box>
                <Box height="50px" width="200px">
                  <Typography
                    style={{
                      color: "grey",

                      fontSize: 12,
                      marginTop: 10,
                      whiteSpace: "pre-wrap",
                      lineBreak: "strict",
                    }}
                  >
                    {trainer?.descriptions?.EN}
                  </Typography>
                </Box>
                <Box height="50px" width="200px">
                  <Typography
                    style={{
                      color: "grey",

                      fontSize: 12,
                      marginTop: 10,
                      whiteSpace: "pre-wrap",
                      lineBreak: "strict",
                    }}
                  >
                    {trainer?.descriptions?.AR}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>{trainer?.fname?.AR + " " + trainer?.lname?.AR}</Box>
          </Box>
          <Box marginTop={4}>
            <Box>Personal code</Box>
            <Box display="grid" gridTemplateColumns="2fr 1fr" width="50%">
              <Box>Promo Code : </Box>
              <Box>{trainer?.promoCode?.code}</Box>
              <Box>Used By : </Box>
              <Box>{trainer?.promoCode?.usedBy}</Box>
              <Box>Total Value : </Box>
              <Box>
                {trainer?.promoCode?.reduction} {auth.countryCurrency}
              </Box>
              <Box>Free Session Sent Requests :</Box>
              <Box>{trainer?.requests}</Box>
            </Box>
          </Box>
          <Box marginTop={4}>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
              width="100%"
              columnGap={1}
            >
              <p className={RecipeClasses.recipe__title}>Status</p>
              <p className={RecipeClasses.recipe__title}>Program</p>
              <p className={RecipeClasses.recipe__title}>Meal Plan</p>
              <p className={RecipeClasses.recipe__title}>No of Days</p>
              <p className={RecipeClasses.recipe__title}>Duration</p>
              <p className={RecipeClasses.recipe__title}>Box Left</p>
              <p className={RecipeClasses.recipe__title}>Start Date</p>
              <p className={RecipeClasses.recipe__title}>End Date</p>
              <Chip
                label={trainer?.plan?.planCondition}
                variant="outlined"
                className={FormsClasses.chips__tags}
              />
              <Chip
                label={trainer?.plan?.program?.name?.EN}
                variant="outlined"
                className={FormsClasses.chips__tags}
              />
              <Chip
                label={
                  trainer?.plan?.meals
                    ? trainer?.plan?.meals?.length + " meals"
                    : ""
                }
                variant="outlined"
                className={FormsClasses.chips__tags}
              />
              <Chip
                label={
                  trainer?.plan?.nbrOfDays
                    ? handleNbrDays(trainer?.plan?.nbrOfDays, "number")
                    : ""
                }
                variant="outlined"
                className={FormsClasses.chips__tags}
              />
              <Chip
                label={trainer?.plan?.paymentPeriod}
                variant="outlined"
                className={FormsClasses.chips__tags}
              />
              <Chip
                label={
                  trainer?.boxes
                    ? (trainer.boxes.nbrOfBoxes || 0) -
                      (trainer.boxes.nbrOfBoxesConsumed || 0)
                    : ""
                }
                variant="outlined"
                className={FormsClasses.chips__tags}
              />
              <Chip
                label={
                  trainer?.plan?.startDate
                    ? moment(trainer?.plan?.startDate).format("DD/MM/YYYY")
                    : ""
                }
                variant="outlined"
                className={FormsClasses.chips__tags}
              />
              <Chip
                label={
                  trainer?.plan?.expiryDate
                    ? moment(trainer?.plan?.expiryDate).format("DD/MM/YYYY")
                    : ""
                }
                variant="outlined"
                className={FormsClasses.chips__tags}
              />
            </Box>
            <Box marginTop={2}>
              <p className={RecipeClasses.recipe__title}>Meals</p>
              <Box className={RecipeClasses.recipe__tags__container}>
                {renderMeals()}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box width="100%">
          <Box marginTop={4}>
            <ReactPlayer
              url={trainer?.video || ""}
              controls
              light={trainer?.videoPicture || ""}
            />
          </Box>
          <Box>
            <TextField
              variant="outlined"
              sx={{ width: "100%" }}
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton onClick={handleCopyClick} aria-label="Copy">
                    <FileCopyIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TrainerDetails;
