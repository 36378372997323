import { Box, Typography } from "@mui/material";
import React from "react";
import ProgressCircle from "./ProgressCircle";
import CountUp from "react-countup";

type Props = {
  title: string;
  number: number;
  percentage: number;
  centralColor: string;
  progressColor: string;
};

const ProgressCircleStatsCard: React.FC<Props> = ({
  title,
  number,
  percentage,
  centralColor,
  progressColor,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h2" fontSize={18} fontWeight="400" color="#000">
        {title}
      </Typography>
      <ProgressCircle
        centralColor={centralColor}
        progressColor={progressColor}
        percentage={percentage}
      />
      <Typography variant="h4" fontSize={22} fontWeight="600" color="#000">
        <CountUp end={number} duration={0.7} />
      </Typography>
    </Box>
  );
};

export default ProgressCircleStatsCard;
