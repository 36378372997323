function SignedUpCustomersIcon() {
  return (
    <svg
      width="28"
      height="38"
      viewBox="0 0 54 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_394_60759)">
        <path
          d="M4.46185 75.4896C3.78321 75.4896 3.25 74.9532 3.25 74.2705V45.1571C3.25 44.8157 3.39542 44.5231 3.58932 44.2793L14.6899 33.1118C15.1746 32.6242 15.9502 32.6242 16.3864 33.1118C16.8227 33.5995 16.8712 34.3798 16.3864 34.8187L5.62522 45.6447V74.2705C5.62522 74.9532 5.09201 75.4896 4.41338 75.4896H4.46185Z"
          fill="#fff"
        />
        <path
          d="M20.3602 66.6638C20.0693 66.6638 19.73 66.5663 19.4876 66.3225C19.0029 65.8348 19.0029 65.0546 19.4876 64.6157C20.9903 63.1039 21.7174 61.5434 21.669 60.0316C21.572 57.7396 19.6331 56.1303 19.5846 56.0816C19.3422 55.8865 19.1483 55.5452 19.1483 55.2038C19.1483 54.8624 19.2453 54.5211 19.4876 54.2772L26.0801 47.645C29.5218 44.1826 27.9221 40.4764 26.8072 38.7696L13.0891 52.5704C12.6043 53.0581 11.8288 53.0581 11.3925 52.5704C10.9562 52.0828 10.9078 51.3025 11.3925 50.8636L26.0801 36.0875C26.0801 36.0875 26.6133 35.7461 26.9526 35.7461C27.292 35.7461 27.5828 35.8924 27.8252 36.1362C30.0065 38.5258 32.818 44.3777 27.8252 49.4006L22.0568 55.2038C22.8808 56.1791 23.9472 57.8372 24.0927 59.9341C24.1896 62.1774 23.2686 64.3231 21.2327 66.3712C20.9903 66.6151 20.6995 66.7126 20.3602 66.7126V66.6638Z"
          fill="#fff"
        />
        <path
          d="M29.4228 75.4905C28.7442 75.4905 28.2109 74.9541 28.2109 74.2714V69.0534C28.2109 68.4194 28.6957 67.883 29.3258 67.8342C29.8591 67.8342 34.4156 67.3466 34.4156 62.8113V56.9594C34.4156 56.2767 34.9488 55.7402 35.6275 55.7402C36.3061 55.7402 36.8393 56.2767 36.8393 56.9594V62.8113C36.8393 67.5904 33.2522 69.5898 30.6346 70.1262V74.3201C30.6346 75.0029 30.1014 75.5393 29.4228 75.5393V75.4905Z"
          fill="#fff"
        />
        <path
          d="M32.526 82.9991C31.8474 82.9991 31.3142 82.4627 31.3142 81.7799V75.4891H2.4237V81.7799C2.4237 82.4627 1.89048 82.9991 1.21185 82.9991C0.533214 82.9991 0 82.4627 0 81.7799V74.2699C0 73.5872 0.533214 73.0508 1.21185 73.0508H32.526C33.2047 73.0508 33.7379 73.5872 33.7379 74.2699V81.7799C33.7379 82.4627 33.2047 82.9991 32.526 82.9991Z"
          fill="#fff"
        />
        <path
          d="M43.331 57.885H26.2197C25.541 57.885 25.0078 57.3486 25.0078 56.6659C25.0078 55.9831 25.541 55.4467 26.2197 55.4467H43.331C44.3489 55.4467 45.173 54.6177 45.173 53.5936V43.9379C45.173 43.2552 45.7062 42.7188 46.3848 42.7188C47.0635 42.7188 47.5967 43.2552 47.5967 43.9379V53.5936C47.5967 55.9831 45.6577 57.885 43.331 57.885Z"
          fill="#fff"
        />
        <path
          d="M15.5556 44.7186C14.877 44.7186 14.3438 44.1821 14.3438 43.4994V4.29142C14.3438 1.90188 16.2827 0 18.6095 0H43.2827C45.6579 0 47.5484 1.95065 47.5484 4.29142V13.5082C47.5484 14.191 47.0152 14.7274 46.3366 14.7274C45.6579 14.7274 45.1247 14.191 45.1247 13.5082V4.29142C45.1247 3.26733 44.3007 2.43831 43.2827 2.43831H18.6095C17.5915 2.43831 16.7674 3.26733 16.7674 4.29142V43.4994C16.7674 44.1821 16.2342 44.7186 15.5556 44.7186Z"
          fill="#fff"
        />
        <path
          d="M46.3856 49.6435H33.54C32.8613 49.6435 32.3281 49.1071 32.3281 48.4243C32.3281 47.7416 32.8613 47.2052 33.54 47.2052H45.1737V43.8891C45.1737 43.2063 45.7069 42.6699 46.3856 42.6699C47.0642 42.6699 47.5974 43.2063 47.5974 43.8891V48.4243C47.5974 49.1071 47.0642 49.6435 46.3856 49.6435Z"
          fill="#fff"
        />
        <path
          d="M15.5556 44.9132C14.877 44.9132 14.3438 44.3768 14.3438 43.694V7.02189C14.3438 6.33916 14.877 5.80273 15.5556 5.80273H46.385C47.0637 5.80273 47.5969 6.33916 47.5969 7.02189V13.5078C47.5969 14.1905 47.0637 14.7269 46.385 14.7269C45.7064 14.7269 45.1732 14.1905 45.1732 13.5078V8.24104H16.7674V43.694C16.7674 44.3768 16.2342 44.9132 15.5556 44.9132V44.9132Z"
          fill="#fff"
        />
        <path
          d="M31.8475 5.21761H30.1025C29.4238 5.21761 28.8906 4.68118 28.8906 3.99845C28.8906 3.31572 29.4238 2.7793 30.1025 2.7793H31.8475C32.5262 2.7793 33.0594 3.31572 33.0594 3.99845C33.0594 4.68118 32.5262 5.21761 31.8475 5.21761Z"
          fill="#fff"
        />
        <path
          d="M33.7317 53.7391H28.1572C27.4785 53.7391 26.9453 53.2027 26.9453 52.5199C26.9453 51.8372 27.4785 51.3008 28.1572 51.3008H33.7317C34.4103 51.3008 34.9435 51.8372 34.9435 52.5199C34.9435 53.2027 34.4103 53.7391 33.7317 53.7391V53.7391Z"
          fill="#fff"
        />
        <path
          d="M42.4637 34.5277H39.7492C38.0526 34.5277 36.6953 33.1623 36.6953 31.4555C36.6953 30.7727 37.2285 30.2363 37.9072 30.2363C38.5858 30.2363 39.119 30.7727 39.119 31.4555C39.119 31.7968 39.4099 32.0894 39.7492 32.0894H42.4637C42.803 32.0894 43.0939 31.7968 43.0939 31.4555V30.3826C43.0939 30.0412 42.803 29.7486 42.4637 29.7486H39.7492C38.0526 29.7486 36.6953 28.3832 36.6953 26.6764V25.6035C36.6953 23.8967 38.0526 22.5312 39.7492 22.5312H42.4637C44.1603 22.5312 45.5176 23.8967 45.5176 25.6035C45.5176 26.2862 44.9844 26.8227 44.3057 26.8227C43.6271 26.8227 43.0939 26.2862 43.0939 25.6035C43.0939 25.2622 42.803 24.9696 42.4637 24.9696H39.7492C39.4099 24.9696 39.119 25.2622 39.119 25.6035V26.6764C39.119 27.0177 39.4099 27.3103 39.7492 27.3103H42.4637C44.1603 27.3103 45.5176 28.6758 45.5176 30.3826V31.4555C45.5176 33.1623 44.1603 34.5277 42.4637 34.5277Z"
          fill="#fff"
        />
        <path
          d="M41.1103 24.8714C40.4317 24.8714 39.8984 24.335 39.8984 23.6523V21.7504C39.8984 21.0677 40.4317 20.5312 41.1103 20.5312C41.7889 20.5312 42.3221 21.0677 42.3221 21.7504V23.6523C42.3221 24.335 41.7889 24.8714 41.1103 24.8714Z"
          fill="#fff"
        />
        <path
          d="M41.1103 36.3812C40.4317 36.3812 39.8984 35.8448 39.8984 35.162V33.2602C39.8984 32.5774 40.4317 32.041 41.1103 32.041C41.7889 32.041 42.3221 32.5774 42.3221 33.2602V35.162C42.3221 35.8448 41.7889 36.3812 41.1103 36.3812Z"
          fill="#fff"
        />
        <path
          d="M41.105 41.4514C33.9793 41.4514 28.2109 35.6482 28.2109 28.4796C28.2109 21.311 33.9793 15.5078 41.105 15.5078C48.2307 15.5078 53.9991 21.311 53.9991 28.4796C53.9991 35.6482 48.2307 41.4514 41.105 41.4514ZM41.105 17.9461C35.3366 17.9461 30.6346 22.6764 30.6346 28.4796C30.6346 34.2828 35.3366 39.0131 41.105 39.0131C46.8734 39.0131 51.5754 34.2828 51.5754 28.4796C51.5754 22.6764 46.8734 17.9461 41.105 17.9461Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SignedUpCustomersIcon;
