import React from "react";
import { useParams, useNavigate } from "react-router";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import clsx from "clsx";
//material
import {
  Box,
  CircularProgress,
  Avatar,
  Button,
  FormControlLabel,
  Switch,
  InputAdornment,
  TextField,
  Chip,
} from "@mui/material";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesPricingInput } from "../../../styles/PricingInput__styles";
//queries
import { useFindProgramByIdV2Query } from "../../../graphql/types";
//types
import { ProgramProps } from "../Types";
//components
import Pack from "../Pack";
//utils
import { formatPacksData } from "../Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//context
import { useAuth } from "../../../contextAPI";

const ViewProgram: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const isAuthorizedToUpdate = useIsAuthorized("FOOD_PROGRAMS_UPDATE");
  let auth = useAuth();

  //react-hook-form
  const methods = useForm<ProgramProps>({
    defaultValues: {
      hasFour: false,
      canBeMixed: false,
      isLimited: false,
      startDate: null,
      endDate: null,
      program__name__english: "",
      program__desription__arabic: "",
      program__desription__english: "",
      program__menu__prefix: "",
      program__name__arabic: "",
      photoUrl: "",
      breakfast: "",
      breakfastAR: "",
      lunch: "",
      lunchAR: "",
      dinner: "",
      dinnerAR: "",
      snack: "",
      snackAR: "",
      smallBreakfast: 0,
      mediumBreakfast: 0,
      largeBreakfast: 0,
      smallLunch: 0,
      mediumLunch: 0,
      largeLunch: 0,
      smallSnack: 0,
      mediumSnack: 0,
      largeSnack: 0,
      weeklyBreakfast: 0,
      weeklyLunch: 0,
      weeklySnack: 0,
      monthlyBreakfast: 0,
      monthlyLunch: 0,
      monthlySnack: 0,
      threeMonthsBreakfast: 0,
      threeMonthsLunch: 0,
      threeMonthsSnack: 0,
      packs: [],
      numberOfMeals: [],
    },
  });

  const { register, control, reset } = methods;

  const { fields } = useFieldArray({
    control,
    name: "packs",
  });

  //styles
  const ContainersClasses = useStylesContainers();
  const ButtonsClasses = useStylesButtons();
  const PriceClasses = useStylesPricingInput();

  //queries
  const { data: dataProgram, loading } = useFindProgramByIdV2Query({
    variables: { input: params.id || "" },
    skip: !params.id,
    fetchPolicy: "no-cache",
  });

  //functions
  React.useEffect(() => {
    if (dataProgram?.findProgramByIdV2) {
      const { calories, isActif, defaultPricing, packs } =
        dataProgram?.findProgramByIdV2 ?? null;
      reset({
        smallBreakfast: calories?.small.breakfast,
        smallLunch: calories?.small.lunchDinner,
        smallSnack: calories?.small.snack,

        mediumBreakfast: calories?.medium.breakfast,
        mediumLunch: calories?.medium.lunchDinner,
        mediumSnack: calories?.medium.snack,

        largeBreakfast: calories?.large.breakfast,
        largeLunch: calories?.large.lunchDinner,
        largeSnack: calories?.large.snack,

        monthlyBreakfast: defaultPricing?.monthly?.breakfast || 0,
        monthlyLunch: defaultPricing?.monthly?.lunchDinner || 0,
        monthlySnack: defaultPricing?.monthly?.snack || 0,

        weeklyBreakfast: defaultPricing?.weekly?.breakfast || 0,
        weeklyLunch: defaultPricing?.weekly?.lunchDinner || 0,
        weeklySnack: defaultPricing?.weekly?.snack || 0,

        threeMonthsBreakfast: defaultPricing?.threeMonths?.breakfast || 0,
        threeMonthsLunch: defaultPricing?.threeMonths?.lunchDinner || 0,
        threeMonthsSnack: defaultPricing?.threeMonths?.snack || 0,

        packs: formatPacksData(packs || []),
        active: isActif || false,
        program__menu__prefix:
          dataProgram?.findProgramByIdV2?.programMenuPrefix || "",
      });
    }
  }, [dataProgram?.findProgramByIdV2]);

  //render
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "150px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <FormProvider {...methods}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              fontFamily="Poppins"
              fontSize={18}
              fontWeight="500"
              color="#C7893E"
            >
              Program Details
            </Box>
            {isAuthorizedToUpdate && (
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/food/programs/update_program/${params.id}`)
                }
                className={ButtonsClasses.button_submit_form}
              >
                Update Program
              </Button>
            )}
          </Box>
          <Box border={1} borderRadius={2} padding={5} marginTop={5}>
            <Box display="flex">
              <Box>
                <Avatar
                  alt={dataProgram?.findProgramByIdV2?.photoUrl || ""}
                  src={dataProgram?.findProgramByIdV2?.photoUrl || ""}
                  variant="rounded"
                  style={{ width: 150, height: 150 }}
                />
              </Box>
              <Box marginLeft={5}>Program status</Box>
              <Box marginLeft={5}>
                <Controller
                  name={`active`}
                  control={control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <FormControlLabel
                      {...rest}
                      value={value}
                      control={<Switch checked={value} disabled />}
                      label={value ? "Active" : "Inactive"}
                    />
                  )}
                />
              </Box>
              {dataProgram?.findProgramByIdV2.canBeMixed && (
                <Box marginLeft={5}>
                  <Chip
                    label="Can Be Mixed"
                    sx={{ backgroundColor: "green", color: "white" }}
                  />
                </Box>
              )}
            </Box>
            <Box
              fontFamily="Poppins"
              fontSize={13}
              fontWeight="400"
              color="#1BA9A5"
              marginTop={2.5}
            >
              Deiture ID Number
            </Box>
            <Box
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="400"
              color="#888888"
              display="flex"
              gap={2}
            >
              Program Menu Prefix
              <Box>
                {dataProgram?.findProgramByIdV2?.programMenuPrefix || ""}
              </Box>
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              marginTop={2.5}
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="400"
              color="#888888"
              gap={5}
            >
              <Box display="grid" gridTemplateColumns="1fr 2fr" rowGap={2.5}>
                <Box>Program Name (en)</Box>
                <Box>{dataProgram?.findProgramByIdV2?.name?.EN}</Box>
                <Box>Descriptions </Box>
                <Box>{dataProgram?.findProgramByIdV2?.description?.EN}</Box>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr 2fr">
                <Box>Program Name (ar)</Box>
                <Box>{dataProgram?.findProgramByIdV2?.name?.AR}</Box>
                <Box>Descriptions </Box>
                <Box>{dataProgram?.findProgramByIdV2?.description?.AR}</Box>
                <Box></Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              width="60%"
              marginTop={5}
              gap={2}
            >
              <Box></Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Small
                </p>
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Medium
                </p>
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Large
                </p>
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Breakfast
                </p>
              </Box>
              <Box>
                <Controller
                  name="smallBreakfast"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        disabled
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="mediumBreakfast"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        disabled
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="largeBreakfast"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        disabled
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Lunch / Dinner
                </p>
              </Box>
              <Box>
                <Controller
                  name="smallLunch"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        disabled
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="mediumLunch"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        disabled
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="largeLunch"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        disabled
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Snack
                </p>
              </Box>
              <Box>
                <Controller
                  name="smallSnack"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        disabled
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="mediumSnack"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        disabled
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="largeSnack"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        disabled
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              marginTop={10}
              width="80%"
              border={1}
              borderRadius={2}
              padding={5}
            >
              <p className={ContainersClasses.section__title}>
                Default Meals Pricing
              </p>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr 1fr"
                gap={2}
                style={{
                  marginBottom: "24px",
                  padding: "16px",
                }}
              >
                <Box></Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Breakfast
                  </p>
                </Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Lunch/Dinner
                  </p>
                </Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Snacks
                  </p>
                </Box>

                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Weekly
                  </p>
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`weeklyBreakfast`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`weeklyLunch`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`weeklySnack`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>

                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Monthly
                  </p>
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`monthlyBreakfast`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`monthlyLunch`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`monthlySnack`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Three Months
                  </p>
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`threeMonthsBreakfast`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`threeMonthsLunch`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`threeMonthsSnack`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {fields.map((field, index) => (
              <Box
                sx={{
                  marginTop: 5,
                  display: "flex",
                  alignItems: "flex-start",
                }}
                key={field.id}
              >
                <Pack
                  disabledPack={true}
                  mode="VIEW"
                  control={control}
                  index={index}
                  image={field.photoUrl}
                />
              </Box>
            ))}
          </Box>
        </FormProvider>
      )}
    </>
  );
};

export default ViewProgram;
