import _ from "lodash";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  useWatch,
} from "react-hook-form";
//material ui
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
} from "@mui/material";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { LocalizationProvider } from "@mui/lab";
import DateFnsAdapter from "@mui/lab/AdapterDateFns";
//components
import PageAddHeader from "../pageAddHeader/pageAddHeader";
import DropZoneInput from "../layout/commun/DropZoneInput";
//styles
import { useStylesContainers } from "../../styles/Containers__styles";
//utils
import { getMessageError } from "../Utils";
import { Priorities } from "./Utils";
//querys and mutations
import {
  useGetAdminsListQuery,
  useGetReminderByIdQuery,
  useUpdateReminderMutation,
} from "../../graphql/types";
//types
import { ReminderProps } from "./types";
import { ApolloError } from "@apollo/client";

const UpdateReminders = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //stytles
  const ContainersClasses = useStylesContainers();
  //states
  const [searchUser, setSearchUser] = useState("");
  //queries and mutations
  const [updateReminder, dataUpdateRemind] = useUpdateReminderMutation();

  const { data } = useGetReminderByIdQuery({
    variables: { input: id || "" },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  const Reminders = useMemo(
    () => data?.getReminderById || null,
    [data?.getReminderById]
  );

  const UsersData = useGetAdminsListQuery({
    variables: {
      input: {
        page: 1,
        documentsPerPage: 50,
        name: searchUser,
      },
    },
    fetchPolicy: "no-cache",
  });

  const UsersList = useMemo(
    () => UsersData.data?.getAdminsList.data || [],
    [UsersData.data]
  );

  const debounced = useCallback(
    _.debounce((event) => setSearchUser(event.target.value), 800),
    []
  );

  const methods = useForm<ReminderProps>({
    defaultValues: {
      title: "",
      description: "",
      date: null,
      photo: "",
      isPrivate: false,
      priority: undefined,
      receivers: [],
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = methods;

  const dataWatch = useWatch({ control });

  useEffect(() => {
    if (Reminders) {
      setValue("title", Reminders.title || "");
      setValue("date", Reminders?.date || null);
      setValue("description", Reminders?.description || "");
      setValue("photo", Reminders?.photo || "");
      setValue("isPrivate", Reminders?.isPrivate || false);
      setValue("priority", Reminders?.priority || "LOW");
      setValue("receivers", Reminders?.receivers || []);
    }
  }, [Reminders]);

  const onSubmit: SubmitHandler<ReminderProps> = async (data) => {
    try {
      await updateReminder({
        variables: {
          updateReminderInput: {
            _id: id || "",
            title: data?.title,
            description: data?.description,
            date: data?.date || new Date(),
            photo: data?.photo,
            isPrivate: data?.isPrivate,
            priority: data?.priority,
            receivers: data?.receivers.map((r) => r._id || ""),
          },
        },
      });
      enqueueSnackbar("Reminder successfully updated", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
      navigate(-1);
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <PageAddHeader
            title={"Update Reminder"}
            buttonText={"Update Reminder"}
            cancelButtonLink="/admin/reminders/list_reminders"
            loading={dataUpdateRemind.loading}
          />
          <Box
            display="grid"
            width="100%"
            gridTemplateColumns="2fr 1fr"
            gap={4}
          >
            <Box
              display="grid"
              width="100%"
              gridTemplateColumns="1fr 1fr"
              columnGap={2}
            >
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Title</p>
                <TextField
                  placeholder="Title"
                  sx={{ width: "100%" }}
                  {...register("title", {
                    required: "Enter Reminder Title",
                    minLength: {
                      value: 2,
                      message: "Please enter at least 2 characters",
                    },
                  })}
                />
                <p className={ContainersClasses.section__error__helper}>
                  {errors?.title?.message}
                </p>
              </Box>
              <LocalizationProvider dateAdapter={DateFnsAdapter}>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>Date</p>
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: { value: true, message: "Enter Date" } }}
                    render={({ field }) => (
                      <DateTimePicker
                        inputFormat="dd/MM/yyyy HH:mm"
                        minDateTime={new Date()}
                        value={field.value}
                        onChange={(date) => field.onChange(date)}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    )}
                  />
                  <p className={ContainersClasses.section__error__helper}>
                    {errors?.date?.message}
                  </p>
                </Box>
              </LocalizationProvider>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Select other recipients
                </p>
                <Controller
                  name="receivers"
                  control={control}
                  render={({ field: { onChange: Change } }) => (
                    <Autocomplete
                      multiple
                      id="receivers"
                      options={UsersList}
                      value={dataWatch?.receivers}
                      getOptionLabel={(option) =>
                        option.fname + " " + option.lname
                      }
                      filterSelectedOptions
                      disableCloseOnSelect
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option.fname + " " + option.lname}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(_, data) => Change(data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(event) => debounced(event)}
                        />
                      )}
                    />
                  )}
                />
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Priority
                  </p>
                  <Controller
                    name="priority"
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          ref={ref}
                          id="priority"
                          options={Priorities}
                          getOptionLabel={(option) => option.menuText}
                          isOptionEqualToValue={(option, value) =>
                            option.menuValue === value.menuValue
                          }
                          value={
                            dataWatch?.priority
                              ? {
                                  menuText: dataWatch.priority,
                                  menuValue: dataWatch.priority,
                                }
                              : null
                          }
                          filterSelectedOptions
                          onChange={(_, data) =>
                            Change(data ? data.menuValue : "")
                          }
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Priority" />
                          )}
                        />
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                  <Box>
                    <Controller
                      name="isPrivate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          checked={value ?? false}
                          onChange={(event) =>
                            onChange((event.target as HTMLInputElement).checked)
                          }
                          control={<Checkbox color="secondary" />}
                          label="Private"
                          labelPlacement="end"
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Description
                </p>
                <TextField
                  placeholder="Description"
                  multiline
                  rows={10}
                  sx={{ width: "100%" }}
                  {...register("description", {
                    required: "Enter Description",
                  })}
                />
                <p className={ContainersClasses.section__error__helper}>
                  {errors?.description?.message}
                </p>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="start">
              <p className={ContainersClasses.section__subtitle}>
                Upload Image
              </p>
              <Controller
                name="photo"
                control={control}
                render={({ field: { onChange, ref } }) => (
                  <DropZoneInput
                    onChange={(e) => onChange(e.target.files[0])}
                    label="Drop File"
                    inputRef={ref}
                    name="photo"
                    errors={errors}
                    multiple={false}
                    disabled={false}
                    accept="image/*"
                    message="Recommended resolution for image 400px*400px"
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default UpdateReminders;
