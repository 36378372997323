function Info({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      className={className ?? ""}
    >
      <path
        fill="#C4C4C4"
        d="M9 0C4.03 0 0 4.031 0 9a9 9 0 009 9 9 9 0 009-9c0-4.969-4.03-9-9-9zm0 3.992A1.524 1.524 0 119 7.04a1.524 1.524 0 010-3.048zm2.032 9.218c0 .24-.195.435-.435.435H7.403a.436.436 0 01-.435-.435v-.871c0-.24.195-.436.435-.436h.436V9.581h-.436a.436.436 0 01-.435-.436v-.87c0-.241.195-.436.435-.436h2.323c.24 0 .435.195.435.435v3.63h.436c.24 0 .435.194.435.435v.87z"
      ></path>
    </svg>
  );
}

export default Info;
