import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Customer",
    accessor: "customer",
  },
  {
    header: "Delivery time",
    accessor: "delivery",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Delivered Time",
    accessor: "deliveredTime",
  },
  {
    header: "Location",
    accessor: "location",
  },
  {
    header: "Driver",
    accessor: "driver",
  },
  {
    header: "Received By",
    accessor: "receivedBy",
  },
  {
    header: "Photo",
    accessor: "photo",
  },
  {
    header: "Reason",
    accessor: "reason",
  },
];

export const DELIVERY_TIME = ["MORNING", "EVENING", "ALL"];

export type FilterElementsProps = {
  menuText: string;
  menuValue: string;
  selected: boolean;
};

export const filterElements: Array<FilterElementsProps> = [
  {
    menuText: "Location",
    menuValue: "AREA_NAME",
    selected: false,
  },
  {
    menuText: "Customer Name",
    menuValue: "CUSTOMER_NAME",
    selected: false,
  },
];
