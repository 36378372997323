import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useForm, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
//material ui
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/Save";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
//components
import Table from "../../layout/commun/Table";
//Utils
import { columns } from "./Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { PromocodeUsersTableColumns } from "../promoCode/Utils";
import { DialogTitleProps, getMessageError } from "../../Utils";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
//queries&mutations
import {
  ReferedListPayload,
  useGetPromoCodeUsersLazyQuery,
  useUpdateRefferedValueMutation,
  useGetPromocodeByCustomerQuery,
  GetPromocodeByCustomerDocument,
} from "../../../graphql/types";

const CustomerPromoCodeById = () => {
  const { id: customerId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isAuthorizedToUpdate = useIsAuthorized(
    "MANAGEMENT_CUSTOMER_PROMOCODE_UPDATE"
  );
  let textInput = useRef<HTMLTextAreaElement>();

  //state
  const [selected, setSelected] = useState<ReferedListPayload | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [openPromoCodeUsersModal, setOpenPromoCodeUsersModal] =
    useState<boolean>(false);

  //styles
  const DialogClasses = useStylesDialog();

  //react hook form
  const methods = useForm<{ totalValue: string }>({
    defaultValues: {
      totalValue: "",
    },
  });
  const dataWatch = useWatch({ control: methods.control });

  //queries&mutations
  const [updateValue] = useUpdateRefferedValueMutation();

  const {
    data,
    refetch,
    loading: loadingPromocodes,
  } = useGetPromocodeByCustomerQuery({
    variables: { customerId: customerId || "" },
    skip: !customerId,
  });

  const customersPromoCode = useMemo(
    () => data?.getPromocodeByCustomer || [],
    [data]
  );

  const [getPromoCodeUsers, { data: promoCodeUsers, loading }] =
    useGetPromoCodeUsersLazyQuery({
      fetchPolicy: "no-cache",
    });

  //functions
  const cancelEditValue = () => {
    setEditMode(false);
    setSelected(null);
  };

  const handleEditMode = (item: ReferedListPayload) => {
    setEditMode(true);
    setSelected(item);
    methods.setValue("totalValue", String(item?.totalValue));
  };

  const handleSubmitEditTotalValue = async () => {
    setEditMode(false);
    await updateValue({
      variables: {
        value: {
          id: selected?.id || "",
          value: parseInt(dataWatch.totalValue || "", 10),
        },
      },
      refetchQueries: [
        {
          query: GetPromocodeByCustomerDocument,
        },
      ],
      onCompleted() {
        refetch();
        enqueueSnackbar("Total value updated succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const onLineClickHandler = async (id: string) => {
    setOpenPromoCodeUsersModal(true);
    await getPromoCodeUsers({
      variables: {
        id: id,
      },
    });
  };

  const renderTableRows = () => {
    return (
      customersPromoCode.map((el, index) => {
        return {
          ref: index + 1,
          userName: el.customer?.fname + " " + el.customer?.lname,
          promoCode: el?.code,
          phone:
            el?.customer?.phone?.prefix + " " + el?.customer?.phone?.number,
          usedBy: (
            <Button onClick={() => onLineClickHandler(el.id || "")}>
              {el?.usedBy}
            </Button>
          ),
          joinedAt: moment(el?.joinedAt).format("DD/MM/YYYY"),
          lastDateUsed: el?.lastDateUsed
            ? moment(el?.lastDateUsed).format("DD/MM/YYYY")
            : "----",
          value: (
            <>
              <form onSubmit={methods.handleSubmit(handleSubmitEditTotalValue)}>
                {editMode && selected?.id === el.id ? (
                  <TextField
                    inputRef={textInput}
                    placeholder="Transaction price"
                    sx={{ width: "80px" }}
                    defaultValue={dataWatch.totalValue}
                    size="small"
                    {...methods.register("totalValue", {
                      required: "Enter total value",
                    })}
                    autoFocus
                  />
                ) : (
                  `${el.totalValue}`
                )}
                {editMode && selected?.id === el.id ? (
                  <>
                    <IconButton
                      type="submit"
                      style={{
                        marginLeft: "5px",
                        width: "26px",
                        height: "26px",
                      }}
                    >
                      <SaveAltIcon style={{ width: "16px", height: "16px" }} />
                    </IconButton>
                    <IconButton
                      onClick={cancelEditValue}
                      style={{
                        marginLeft: "5px",
                        width: "26px",
                        height: "26px",
                      }}
                    >
                      <HighlightOffIcon
                        style={{ width: "18px", height: "18px" }}
                      />
                    </IconButton>
                  </>
                ) : (
                  isAuthorizedToUpdate && (
                    <IconButton
                      onClick={() => handleEditMode(el)}
                      style={{
                        marginLeft: "5px",
                        width: "26px",
                        height: "26px",
                      }}
                    >
                      <EditIcon style={{ width: "16px", height: "16px" }} />
                    </IconButton>
                  )
                )}
              </form>
            </>
          ),
        };
      }) || []
    );
  };

  const renderPromoCodeUsersTableRows = () => {
    return (
      promoCodeUsers?.getPromoCodeUsers?.map((el, index) => {
        return {
          ref: index + 1,
          name: el.customer?.fname + " " + el.customer?.lname,
          date: moment(el?.usedAt).format("DD/MM/YYYY"),
        };
      }) || []
    );
  };

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              height: "fit-content",
            }}
          >
            <CloseIcon
              style={{
                minHeight: "22px",
                height: "22px",
                width: "22px",
                marginBottom: "0px",
              }}
            />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <>
      <br />
      <TableContainer>
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No Customer Promo codes found !"
          loading={loadingPromocodes}
        />
      </TableContainer>

      {/* LIST OF PROMOCODE USERS */}
      <Dialog
        open={openPromoCodeUsersModal}
        onClose={() => setOpenPromoCodeUsersModal(false)}
        className={DialogClasses.dialog_renew_container}
      >
        <BootstrapDialogTitle
          className={DialogClasses.alert_dialog_title}
          onClose={() => setOpenPromoCodeUsersModal(false)}
        >
          <span
            className="confirm_dialog_title_text"
            style={{ alignSelf: "flex-start" }}
          >
            Promocode Users List
          </span>
        </BootstrapDialogTitle>
        <DialogContent>
          <TableContainer>
            <Table
              columns={PromocodeUsersTableColumns}
              data={renderPromoCodeUsersTableRows()}
              emptyMessage="No users for this promocode !"
              loading={loading}
              numberOfFakeRow={10}
            />
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomerPromoCodeById;
