import React, { useState, useEffect } from "react";
import { useFieldArray, Controller, useWatch } from "react-hook-form";
//material ui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
//Types
import { IngredientDetailsFormProps } from "../../Types";
//icons
import AddCircle from "../../../../public/icons/AddCircle";
//styles
import {
  ContainerStyled,
  useStylesContainers,
} from "../../../../styles/Containers__styles";
import { useStylesButtons } from "../../../../styles/Buttons__styles";
//context
import { useAuth } from "../../../../contextAPI";

const IngredientDetailsForm = ({
  control,
  getValues,
  reset,
  ingredientsList,
  mode,
}: IngredientDetailsFormProps) => {
  let auth = useAuth();
  //styles
  const ContainersClasses = useStylesContainers();
  const ButtonClasses = useStylesButtons();

  //state
  const [costSmallList, setcostSmallList] = useState<number[]>([]);
  const [costMediumList, setcostMediumList] = useState<number[]>([]);
  const [costLargeList, setcostLargeList] = useState<number[]>([]);

  //react-hook-form
  const { fields, append, remove } = useFieldArray({
    control,
    name: "ingredients",
  });
  const handleAdd = () => {
    append({});
  };
  const dataWatch = useWatch({ control });

  const handleDelete = (index: number) => {
    remove(index);
    reset({
      ...getValues(),
      ingredients: [
        ...getValues("ingredients").slice(0, index),
        ...getValues("ingredients").slice(index),
      ],
    });
  };

  useEffect(() => {
    if (fields) {
      const newArray = fields.map((elem: any) => {
        const CostOf100G = +elem?.ingredient?.cost;
        return (CostOf100G / 100) * elem.quantitySmall;
      });
      setcostSmallList(newArray);
      const newArrayMedium = fields.map((elem: any) => {
        const CostOf100G = +elem?.ingredient?.cost;
        return (CostOf100G / 100) * elem.quantityMedium;
      });
      setcostMediumList(newArrayMedium);
      const newArrayLarge = fields.map((elem: any) => {
        const CostOf100G = +elem?.ingredient?.cost;
        return (CostOf100G / 100) * elem.quantityLarge;
      });
      setcostLargeList(newArrayLarge);
    }
  }, [fields]);

  const ChangeCostByValue = (
    newValue: number,
    ValueIndex: number,
    size: "SMALL" | "MEDIUM" | "LARGE",
    cost: number
  ) => {
    if (size === "SMALL") {
      setcostSmallList((oldState) => {
        const newArray = oldState.map((elem: number, index: number) => {
          if (index === ValueIndex) {
            return (cost / 100) * newValue;
          } else {
            return elem;
          }
        });
        return newArray;
      });
    } else if (size === "MEDIUM") {
      setcostMediumList((oldState) => {
        const newArray = oldState.map((elem: number, index: number) => {
          if (index === ValueIndex) {
            return (cost / 100) * newValue;
          } else {
            return elem;
          }
        });
        return newArray;
      });
    } else {
      setcostLargeList((oldState) => {
        const newArray = oldState.map((elem: number, index: number) => {
          if (index === ValueIndex) {
            return (cost / 100) * newValue;
          } else {
            return elem;
          }
        });
        return newArray;
      });
    }
  };

  return (
    <ContainerStyled>
      <Box
        display="grid"
        width="100%"
        gridTemplateColumns="3fr 2fr 2fr 2fr 50px"
        rowGap={1}
        columnGap={2}
      >
        <p className={ContainersClasses.section__subtitle}>Ingredient Name</p>
        <p className={ContainersClasses.section__subtitle}>
          Small Ingredient quantity
          <span>( Gr )</span>
        </p>
        <p className={ContainersClasses.section__subtitle}>
          Medium Ingredient quantity
          <span>( Gr )</span>
        </p>
        <p className={ContainersClasses.section__subtitle}>
          Large Ingredient quantity
          <span>( Gr )</span>
        </p>
        <p />

        {fields?.map((item, index) => (
          <React.Fragment key={item.id}>
            <Controller
              name={`ingredients.${index}.ingredient`}
              control={control}
              render={({
                field: { ref, onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  loading={true}
                  ref={ref}
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  disabled={mode === "VIEW" ? true : false}
                  options={ingredientsList}
                  getOptionLabel={(option) => option?.name?.EN || ""}
                  inputValue={value?.name?.EN}
                  value={ingredientsList?.find((el) => el?._id === value?._id)}
                  isOptionEqualToValue={(option, value) =>
                    option?._id === value?._id
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Ingredient Name" />
                  )}
                />
              )}
            />
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={1}>
              <Controller
                name={`ingredients.${index}.quantitySmall`}
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      disabled={mode === "VIEW" ? true : false}
                      ref={ref}
                      onChange={(el) => {
                        ChangeCostByValue(
                          parseInt(el.target.value) || 1,
                          index,
                          "SMALL",
                          +dataWatch.ingredients[index].ingredient.cost || 1
                        );
                        onChange(el);
                      }}
                      placeholder="quantity "
                      fullWidth
                      value={value}
                    />
                  </>
                )}
              />
              <Controller
                name={`ingredients.${index}.cookedWeightSmall`}
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      disabled={mode === "VIEW" ? true : false}
                      ref={ref}
                      onChange={(el) => {
                        onChange(el);
                      }}
                      id={`ingredients.${index}.cookedWeightSmall`}
                      placeholder="cooked weight"
                      fullWidth
                      value={value}
                    />
                  </>
                )}
              />
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={1}>
              <Controller
                name={`ingredients.${index}.quantityMedium`}
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      disabled={mode === "VIEW" ? true : false}
                      ref={ref}
                      onChange={(el) => {
                        ChangeCostByValue(
                          parseInt(el.target.value) || 1,
                          index,
                          "MEDIUM",
                          +dataWatch.ingredients[index].ingredient.cost || 1
                        );
                        onChange(el);
                      }}
                      id={`ingredients.${index}.quantityMedium`}
                      placeholder="quantity "
                      fullWidth
                      value={value}
                    />
                  </>
                )}
              />
              <Controller
                name={`ingredients.${index}.cookedWeightMedium`}
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      disabled={mode === "VIEW" ? true : false}
                      ref={ref}
                      onChange={(el) => {
                        onChange(el);
                      }}
                      id={`ingredients.${index}.cookedWeightMedium`}
                      placeholder="cooked weight"
                      fullWidth
                      value={value}
                    />
                  </>
                )}
              />
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={1}>
              <Controller
                name={`ingredients.${index}.quantityLarge`}
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      disabled={mode === "VIEW" ? true : false}
                      ref={ref}
                      onChange={(el) => {
                        ChangeCostByValue(
                          parseInt(el.target.value) || 1,
                          index,
                          "LARGE",
                          +dataWatch.ingredients[index].ingredient.cost || 1
                        );
                        onChange(el);
                      }}
                      id={`ingredients.${index}.quantityLarge`}
                      placeholder="quantity "
                      fullWidth
                      value={value}
                    />
                  </>
                )}
              />
              <Controller
                name={`ingredients.${index}.cookedWeightLarge`}
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      disabled={mode === "VIEW" ? true : false}
                      ref={ref}
                      onChange={(el) => {
                        onChange(el);
                      }}
                      id={`ingredients.${index}.cookedWeightLarge`}
                      placeholder="cooked weight "
                      fullWidth
                      value={value}
                    />
                  </>
                )}
              />
            </Box>

            <IconButton
              onClick={() => handleDelete(index)}
              disabled={mode === "VIEW" ? true : false}
            >
              {mode != "VIEW" && <DeleteIcon color="error" />}
            </IconButton>
          </React.Fragment>
        ))}
        <Box sx={{ gridColumn: "span 5" }}>
          {mode != "VIEW" && (
            <Button
              startIcon={<AddCircle />}
              className={ButtonClasses.button_plus_add_new}
              onClick={handleAdd}
            >
              Add New Ingredient
            </Button>
          )}
        </Box>
        <Typography variant="h3" align="right" fontWeight={700}>
          Cost :
        </Typography>
        <Typography variant="h4" align="center">
          {auth.countryCurrency}{" "}
          {costSmallList
            .filter((el: number) => el)
            .reduce((prev, next) => {
              return prev + next;
            }, 0)
            .toFixed(2)}
        </Typography>
        <Typography variant="h4" align="center">
          {auth.countryCurrency}{" "}
          {costMediumList
            .filter((el: number) => el)
            .reduce((prev, next) => {
              return prev + next;
            }, 0)
            .toFixed(2)}
        </Typography>
        <Typography variant="h4" align="center">
          {auth.countryCurrency}{" "}
          {costLargeList
            .filter((el: number) => el)
            .reduce((prev, next) => {
              return prev + next;
            }, 0)
            .toFixed(2)}
        </Typography>
      </Box>
    </ContainerStyled>
  );
};

export default IngredientDetailsForm;
