function RenewingCustomerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={1000}
      height={68}
      viewBox="50 50 200 200"
      version="1.1"
    >
      <path
        d="M 145.250 94.609 C 136.313 99.290, 129.009 103.431, 129.019 103.810 C 129.039 104.525, 157.731 120.099, 159.500 120.355 C 160.050 120.435, 160.637 118.362, 160.805 115.750 C 161.141 110.511, 161.463 110.331, 166.640 112.494 C 184.561 119.982, 195.001 142.163, 189.432 160.917 C 188.570 163.821, 188.024 166.357, 188.219 166.552 C 189.178 167.512, 197.496 170.812, 198.066 170.459 C 199.669 169.469, 202.254 156.825, 202.258 149.959 C 202.272 128.001, 188.588 108.426, 167.969 100.908 L 162.086 98.763 161.793 92.430 L 161.500 86.096 145.250 94.609 M 101.055 131.355 C 95.881 144.964, 96.789 160.757, 103.508 174.016 C 109.285 185.416, 119.761 194.618, 132.074 199.107 L 138 201.268 138 207.709 L 138 214.150 154.222 205.658 C 163.145 200.987, 170.560 196.791, 170.702 196.333 C 170.940 195.561, 142.244 179.707, 140.350 179.564 C 139.882 179.529, 139.363 181.637, 139.195 184.250 C 138.859 189.489, 138.537 189.669, 133.360 187.506 C 115.588 180.080, 104.942 157.693, 110.489 139.409 C 112.303 133.430, 112.306 133.341, 110.750 132.698 C 110.063 132.414, 107.805 131.457, 105.733 130.570 C 102.018 128.981, 101.954 128.992, 101.055 131.355"
        fill="#fcf6f4"
      />
    </svg>
  );
}

export default RenewingCustomerIcon;
