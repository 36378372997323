import React, { useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
//material
import {
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//Utils
import {
  columns,
  SIDE_FILTER_OPTIONS_TRANSACTIONS,
  handleStatus,
  handleKind,
} from "./Utils";
import { handlePayment } from "../Utils";
import { useIsAuthorized } from "../../utils/Hooks/useIsAuthorized";
import { PaymentList } from "../customer/CustomerTransactions/Utils";
//components
import Table from "../layout/commun/Table";
import FilterHeader from "../pageHeader/FilterHeader";
import Status from "../Status/Status";
import CustomSplitButton from "../reusable/SplitButton/CustomSpiltButton";
//icons
import Info from "../../public/icons/Info";
//styles
import { TableContainer } from "../../styles/Table__styles";
import { useStylesMenu } from "../../styles/Menu__styles";
import { useStylesDialog } from "../../styles/Dialog__styles";
import { useStylesButtons } from "../../styles/Buttons__styles";
//queries&mutations
import {
  useGetTransactionsQuery,
  useApproveTransactionMutation,
  TransactionPayload,
  GetTransactionsDocument,
  useActivateTransactionMutation,
  TransactionStatus,
  useGetTransactionsLazyQuery,
  PaymentMethod,
  useChangeTransactionsPaymentMethodMutation,
} from "../../graphql/types";

const ViewTransaction = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //states
  const [show, setShow] = useState<"approve" | "cancel" | "activate" | null>(
    null
  );
  const [search, setSearch] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<TransactionPayload | null>(null);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [status, setStatus] = useState<TransactionStatus[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod[]>([]);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);

  //queries and muations
  const [approveTransaction, dataTransaction] = useApproveTransactionMutation();

  const [activateTransaction] = useActivateTransactionMutation();

  const dataGetTransactions = useGetTransactionsQuery({
    variables: {
      transactionInput: {
        page: page,
        documentsPerPage: rowsPerPage,
        nameCustomer: search,
        transactionStatus: status,
        paymentMethod: paymentMethod,
        from: from,
        to: to,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
  });

  const isAuthorizedToChangeMethod = useIsAuthorized(
    "ADMIN_TRANSACRION_CHANGE_METHOD"
  );

  const count = useMemo(
    () => dataGetTransactions.data?.getTransactions.count || 0,
    [dataGetTransactions.data]
  );

  const transactions = useMemo(
    () => dataGetTransactions.data?.getTransactions.data || [],
    [dataGetTransactions.data]
  );

  const [getTransactionsData, { loading: transactionDataListLoading }] =
    useGetTransactionsLazyQuery();

  const handleExportExcel = async () => {
    await getTransactionsData({
      variables: {
        transactionInput: {
          page: 1,
          documentsPerPage: count,
          nameCustomer: search,
          transactionStatus: status,
          paymentMethod: paymentMethod,
          from: from,
          to: to,
        },
      },
    })
      .then((data) => {
        if (count != 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const fileExtension = ".xlsx";
          const filteredTransactions = data?.data?.getTransactions?.data?.map(
            (el, index) => {
              return {
                id: (index + 1).toString(),
                name: el.customer?.fname + " " + el.customer?.lname,
                program: el.plan?.EN,
                price: `${el?.amount?.toFixed(2)} ${el?.currency}`,
                status: handleStatus(el.transactionStatus || "FAILED"),
                method: handlePayment(el?.paymentMethod || "UNSPECIFIED"),
                kind: handleKind(el?.kind),
                date: moment(el?.date).format("YYYY-MM-DD"),
                ref: el?.merchantReference,
              };
            }
          );
          const ws = XLSX.utils.json_to_sheet(filteredTransactions || []);
          ws["!cols"] = [
            { wpx: 40 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 150 },
          ];
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const res = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            res,
            "transactions report" +
              " generated on " +
              moment().format("DD/MM/YYYY dddd LT") +
              fileExtension
          );
          return "Done";
        }
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };
  //functions
  const handleClickMenu =
    (item: TransactionPayload) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseApprove = (
    type: "approve" | "cancel" | "activate" | null
  ) => {
    setAnchorEl(null);
    setOpen(true);
    setShow(type);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const [changeTransactionPayment] =
    useChangeTransactionsPaymentMethodMutation();

  const handleSubmitChangePaymentMethod = async (
    status: string,
    id: string
  ) => {
    if (paymentMethod) {
      try {
        await changeTransactionPayment({
          variables: {
            input: {
              transactionId: id,
              paymentMethod: status as PaymentMethod,
            },
          },
          refetchQueries: [GetTransactionsDocument],
          onCompleted: () => {
            setAnchorEl(null);
            enqueueSnackbar("Payment Method succuessfully Updated", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 10000);
          },
        });
      } catch (err) {
        setAnchorEl(null);
        const error = "";
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 10000);
      }
    }
  };

  const onApproveTransaction = async (approve: boolean) => {
    setOpen(false);
    if (selected) {
      try {
        await approveTransaction({
          variables: {
            changeTransactionStatusInput: {
              approve,
              transactionId: selected?._id || "",
            },
          },
          refetchQueries: [
            {
              query: GetTransactionsDocument,
              variables: {
                transactionInput: {
                  page: page,
                  documentsPerPage: rowsPerPage,
                  nameCustomer: search,
                },
              },
            },
          ],
        });
        enqueueSnackbar("Transaction status succuessfully changed", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setSelected(null);
      } catch (error) {
        setSelected(null);
        console.log("#error", error);
      }
    }
  };

  const onActivateTransaction = async () => {
    setOpen(false);
    if (selected) {
      try {
        await activateTransaction({
          variables: {
            input: {
              approve: true,
              transactionId: selected?._id || "",
            },
          },
          refetchQueries: [
            {
              query: GetTransactionsDocument,
              variables: {
                transactionInput: {
                  page: page,
                  documentsPerPage: rowsPerPage,
                  nameCustomer: search,
                },
              },
            },
          ],
        });
        enqueueSnackbar("Transaction status succuessfully changed to active", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setSelected(null);
      } catch (error) {
        setSelected(null);
        console.log("#error", error);
      }
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  //render
  const renderTableRows = () => {
    return transactions.map((item) => {
      const isLoading = selected?._id === item._id && dataTransaction?.loading;
      return {
        name: isAuthorizedToSeeProfile ? (
          <a
            style={{ textDecoration: "none" }}
            href={`/admin/customers/view_customer/${item?.customer?._id}`}
          >
            <Button>
              {`${item?.customer?.fname} ${item?.customer?.lname}`}
            </Button>
          </a>
        ) : (
          item.customer?.fname + " " + item.customer?.lname
        ),
        plan: item?.plan?.EN || "----",
        price: `${item?.amount?.toFixed(2)} ${item?.currency}`,
        status: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {item?.transactionStatus ? (
              <Status
                status={item?.transactionStatus}
                statusText={handleStatus(item?.transactionStatus)}
              />
            ) : (
              "----"
            )}
          </div>
        ),
        method: isAuthorizedToChangeMethod ? (
          <>
            <CustomSplitButton
              defaultValue={item.paymentMethod || "NAPS"}
              onStatuschange={handleSubmitChangePaymentMethod}
              id={item._id || ""}
              optionsList={PaymentList}
            />
          </>
        ) : (
          handlePayment(item.paymentMethod || "UNSPECIFIED")
        ),
        kind: item?.kind ? (
          handleKind(item?.kind)
        ) : (
          <span style={{ textAlign: "center", width: "100%" }}>----</span>
        ),
        merchant_reference: item?.merchantReference || <span>----</span>,
        dibsyId: item.dibsyId || (
          <span style={{ textAlign: "center" }}>----</span>
        ),
        date: moment(item?.date).format("YYYY-MM-DD"),
        approve:
          item?.transactionStatus !== "CANCELED" ? (
            isLoading ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              <IconButton onClick={handleClickMenu(item)}>
                <MoreVertIcon />
              </IconButton>
            )
          ) : (
            <p></p>
          ),
      };
    });
  };
  return (
    <div>
      <FilterHeader
        count={count}
        setSearchValue={setSearch}
        showSearch={true}
        pageInfo="This is the list of all the transactions in our system"
        pageTitle="Transactions"
        setIdsProgram={() => null}
        programIdData={[]}
        filterConfig={SIDE_FILTER_OPTIONS_TRANSACTIONS}
        hideProgram={true}
        setFrom={setFrom}
        from={from}
        setTo={setTo}
        to={to}
        setTransactionStatus={setStatus}
        transactionStatusData={status}
        setPaymentMethod={setPaymentMethod}
        paymentMethodData={paymentMethod}
        loadExcel={transactionDataListLoading}
        generateExcel={handleExportExcel}
      />
      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetTransactions.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Transactions found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          <MenuItem
            onClick={() => handleCloseApprove("approve")}
            className={classes.menu_container}
            disableRipple
          >
            <div>
              <span className="menu_title">Approve Transaction</span>
            </div>
          </MenuItem>

          <MenuItem
            onClick={() => handleCloseApprove("activate")}
            className={classes.menu_container}
            disableRipple
          >
            <div>
              <span className="menu_title">Activate</span>
            </div>
          </MenuItem>

          <MenuItem
            onClick={() => handleCloseApprove("cancel")}
            className={classes.menu_container}
            disableRipple
          >
            <div>
              <span className="menu_title">Cancel Transaction</span>
            </div>
          </MenuItem>
        </Menu>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Info />
            <span className="confirm_dialog_title_text">
              {show === "approve"
                ? "Approve Transaction ?"
                : show === "cancel"
                ? "Cancel Transaction ?"
                : "Activation"}
            </span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              {show === "approve"
                ? "Are you sure you want to approve this transaction ?"
                : show === "cancel"
                ? "Are you sure you want to cancel this transaction ?"
                : "Are you sure you want to activate this transaction ?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
              color="primary"
            >
              Cancel
            </Button>
            {show === "approve" || show === "cancel" ? (
              <Button
                className={ButtonsClasses.BlueButton}
                onClick={() => {
                  onApproveTransaction(show === "approve");
                }}
                color="primary"
                autoFocus
              >
                confirm
              </Button>
            ) : null}
            {show === "activate" && (
              <Button
                className={ButtonsClasses.BlueButton}
                onClick={() => {
                  onActivateTransaction();
                }}
                color="primary"
                autoFocus
              >
                confirm
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ViewTransaction;
