function AddCircle() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="10"
        transform="rotate(-180 10 10)"
        fill="#232222"
      />
      <path
        d="M9.77257 14.2229V9.77843M9.77257 9.77843V5.33398M9.77257 9.77843H14.217M9.77257 9.77843H5.32812"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default AddCircle;
