import React from "react";
import moment from "moment";
import styled from "styled-components";
//logo
import dieture_logo_black from "../../../public/images/dieture_logo_black.png";
//styles
import { useStylesReport } from "../../../styles/Report__styles";
//types
import { driverProps } from "../Types";
//material ui
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const DriverReportPrint = React.forwardRef<HTMLDivElement, driverProps>(
  ({ morning, evening, deliveryData }, ref) => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const curTime =
      current.getHours() +
      ":" +
      current.getMinutes() +
      ":" +
      current.getSeconds();
    const ReportClasses = useStylesReport();
    const Table = styled.table`
      border: 1px solid black;
      border-radius: 5px;
    `;

    const Th = styled.th`
      border: 1px solid black;
      border-radius: 5px;
    `;

    const Td = styled.td`
      border: 1px solid black;
      border-radius: 5px;
    `;
    return (
      <div ref={ref} className={ReportClasses.report__container}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize={14} fontWeight={600}>
            Drivers Report
          </Typography>
          <img
            src={dieture_logo_black}
            height="50px"
            alt="dieture_logo_black"
          />
          <Box>
            <Typography fontSize={10} fontWeight="600">
              Delivery on:{" "}
              {moment(deliveryData?.date).format("DD/MM/YYYY-dddd")}
            </Typography>
            <Typography fontSize={10} fontWeight="600">
              Driver :{deliveryData?.driver?.name}{" "}
            </Typography>
            <Typography fontSize={8.5} style={{ marginBlockEnd: 20 }}>
              Generated on: {date}-{curTime}
            </Typography>
            <Typography fontSize={14} fontWeight="600">
              Total Delivery= {deliveryData?.totalDeliveries}
            </Typography>
          </Box>
        </Box>
        <div>
          <Typography
            fontWeight={500}
            fontSize={12}
            style={{ marginBlockEnd: 5 }}
          >
            Morning delivery
          </Typography>
          <Table
            style={{
              borderWidth: 1,
              borderColor: "#232222",
              borderCollapse: "collapse",
              marginRight: 8,
              width: "100%",
              marginBlockEnd: 5,
            }}
          >
            <tr
              style={{
                color: "#000000",
                borderColor: "black",
              }}
            >
              <Th
                style={{
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                  fontSize: 10,
                  fontWeight: 600,
                }}
              >
                Si No
              </Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Customer name
              </Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Address
              </Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Box No
              </Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Delivery instruction
              </Th>
            </tr>
            {morning?.map((el, index) => (
              <>
                <tr style={{ pageBreakInside: "avoid" }}>
                  <Td style={{ textAlign: "center", fontSize: 10 }}>
                    {index + 1}
                  </Td>

                  <Td
                    style={{
                      fontWeight: 500,
                      textAlign: "center",
                      fontSize: 8,
                    }}
                  >
                    {el?.customer?.fname} {el?.customer?.lname}
                    {el?.customer?.isNew && (
                      <sup
                        style={{
                          fontSize: 5,
                          fontWeight: 700,
                          paddingLeft: 5,
                        }}
                      >
                        New
                      </sup>
                    )}{" "}
                  </Td>
                  <Td
                    style={{
                      fontWeight: 500,
                      textAlign: "left",
                      paddingLeft: 10,
                    }}
                  >
                    <p
                      style={{ fontSize: 7 }}
                    >{`Address Nickname: ${el?.deliveryAddress?.nick}`}</p>
                    <p style={{ fontSize: 7, marginInlineStart: 2 }}>
                      {el?.deliveryAddress?.nbrApt},{" "}
                      {el?.deliveryAddress?.floor}
                      {`Zone no: ${el?.deliveryAddress?.zone}`},
                      {`Building: ${el?.deliveryAddress?.build}`},
                      {`Street no: ${el?.deliveryAddress?.street}`},
                    </p>
                    <p
                      style={{ fontSize: 7 }}
                    >{`Area: ${el?.deliveryAddress?.area?.name}`}</p>

                    {el?.deliveryAddress?.deliveryPhone?.number &&
                    el?.deliveryAddress?.deliveryPhone?.prefix ? (
                      <p style={{ fontSize: 7 }}>
                        {`phone number: ${el?.deliveryAddress?.deliveryPhone?.prefix} ${el?.deliveryAddress?.deliveryPhone?.number}`}
                      </p>
                    ) : (
                      <p style={{ fontSize: 7 }}>
                        {" "}
                        Customer Phone Number {el?.customer?.phone?.prefix}
                        {el?.customer?.phone?.number}
                      </p>
                    )}
                  </Td>
                  <Td style={{ fontWeight: 500, textAlign: "center" }}>
                    <p style={{ fontSize: 8 }}>
                      {el?.customer?.consumedBoxes} / {el?.customer?.nbrOfBoxes}
                    </p>
                  </Td>
                  <Td style={{ fontWeight: 500, textAlign: "center" }}>
                    <p style={{ fontSize: 7 }}>
                      {el?.deliveryAddress?.deliveryInstructions || "--"}
                    </p>{" "}
                  </Td>
                </tr>
                {(index + 1) % 15 === 0 ? (
                  <div style={{ pageBreakAfter: "always" }}></div>
                ) : null}
              </>
            ))}
          </Table>
        </div>
        <div style={{ marginBlockStart: 20 }}>
          <Typography
            variant="h3"
            fontWeight={500}
            fontSize={12}
            style={{
              marginBlockEnd: 5,
              pageBreakInside: "avoid",
            }}
          >
            Evening delivery
          </Typography>

          <Table
            style={{
              borderWidth: 1,
              borderColor: "#232222",
              borderCollapse: "collapse",
              marginRight: 8,
              width: "100%",
              marginBlockEnd: 10,
            }}
          >
            <tr
              style={{
                color: "#000000",
                borderColor: "black",
              }}
            >
              <Th
                style={{
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                  fontSize: 10,
                  fontWeight: 600,
                }}
              >
                Si No
              </Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Customer name
              </Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Address
              </Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Box No
              </Th>
              <Th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  textAlignLast: "center",
                  borderColor: "black",
                  paddingLeft: 8,
                }}
              >
                Delivery instruction
              </Th>
            </tr>
            {evening?.map((el, index) => (
              <tr key={el.customer?.ref} style={{ pageBreakInside: "avoid" }}>
                <Td style={{ textAlign: "center", fontSize: 10 }}>
                  {index + 1}
                </Td>
                <Td
                  style={{ fontWeight: 500, textAlign: "center", fontSize: 8 }}
                >
                  {el?.customer?.fname} {el?.customer?.lname}
                  {el?.customer?.isNew && (
                    <sup
                      style={{
                        fontSize: 5,
                        fontWeight: 700,
                        paddingLeft: 5,
                      }}
                    >
                      New
                    </sup>
                  )}{" "}
                </Td>
                <Td
                  style={{
                    fontWeight: 500,
                    textAlign: "left",
                    paddingLeft: 10,
                  }}
                >
                  <p
                    style={{ fontSize: 7 }}
                  >{`Address Nickname: ${el?.deliveryAddress?.nick}`}</p>
                  <p style={{ fontSize: 7, marginInlineStart: 2 }}>
                    {el?.deliveryAddress?.nbrApt}, {el?.deliveryAddress?.floor}
                    {`Zone no: ${el?.deliveryAddress?.zone}`},
                    {`Building: ${el?.deliveryAddress?.build}`},
                    {`Street no: ${el?.deliveryAddress?.street}`},
                  </p>
                  <p
                    style={{ fontSize: 7 }}
                  >{`Area: ${el?.deliveryAddress?.area?.name}`}</p>

                  {el?.deliveryAddress?.deliveryPhone?.number &&
                  el?.deliveryAddress?.deliveryPhone?.prefix ? (
                    <p style={{ fontSize: 7 }}>
                      {`phone number: ${el?.deliveryAddress?.deliveryPhone?.prefix} ${el?.deliveryAddress?.deliveryPhone?.number}`}
                    </p>
                  ) : (
                    <p style={{ fontSize: 7 }}>
                      {" "}
                      Customer Phone Number {el?.customer?.phone?.prefix}
                      {el?.customer?.phone?.number}
                    </p>
                  )}
                </Td>
                <Td style={{ fontWeight: 500, textAlign: "center" }}>
                  <p style={{ fontSize: 8 }}>
                    {el?.customer?.consumedBoxes} / {el?.customer?.nbrOfBoxes}
                  </p>
                </Td>
                <Td style={{ fontWeight: 500, textAlign: "center" }}>
                  <p style={{ fontSize: 7 }}>
                    {el?.deliveryAddress?.deliveryInstructions || "--"}
                  </p>{" "}
                </Td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    );
  }
);

export default DriverReportPrint;
