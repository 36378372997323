import React from "react";
import moment from "moment";
import styled from "styled-components";
//material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//logo
import dieture_logo_black from "../../../public/images/dieture_logo_black.png";
//types
import { printReportProps } from "../Types";

const DishReportPrint = React.forwardRef<HTMLDivElement, printReportProps>(
  ({ generateReport, DateDelivery }, ref) => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const curTime =
      current.getHours() +
      ":" +
      current.getMinutes() +
      ":" +
      current.getSeconds();

    const Table = styled.table`
      border: 1px solid black;
      border-collapse: collapse;
    `;

    const Th = styled.th`
      border: 1px solid black;
    `;

    const Td = styled.td`
      border: 1px solid black;
    `;

    //render
    return (
      <div ref={ref}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Typography style={{ fontSize: 14 }} fontWeight={600}>
            Dish Report
          </Typography>
          <img
            src={dieture_logo_black}
            height="50px"
            alt="dieture_logo_black"
          />
          <Box>
            <Typography variant="body2" fontWeight={600}>
              Delivery on: {moment(DateDelivery).format("DD/MM/YYYY-dddd")}
            </Typography>
            <Typography fontSize={8.5}>
              Generated on : {date}-{curTime}
            </Typography>
          </Box>
        </Box>
        {generateReport?.getPaxReport?.paxes?.map((item) => (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            p={2}
            style={{ pageBreakInside: "avoid" }}
          >
            <div
              style={{
                fontSize: 15,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "600",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ marginRight: 100 }}>
                {item?.meal} : {item?.dish?.name?.EN}
              </div>
              <div style={{ justifyContent: "right", display: "flex" }}></div>
              Program : {item?.program?.name?.EN}
            </div>
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
              {/* Table for small */}
              <Table
                style={{
                  borderWidth: 1,
                  borderColor: "#232222",
                  borderCollapse: "collapse",
                }}
              >
                <tr
                  style={{
                    backgroundColor: "#232222",
                    color: "#FFFF",
                    borderColor: "black",
                  }}
                >
                  <Th
                    style={{
                      fontSize: 16,
                      textAlignLast: "justify",
                      borderColor: "black",
                      paddingLeft: 16,
                    }}
                  >
                    SMALL
                  </Th>
                  <Th style={{ fontSize: 15 }}>Raw</Th>
                  <Th style={{ fontSize: 15 }}>Cooked</Th>
                </tr>
                <tr style={{ fontSize: 12, fontWeight: 600 }}>
                  <Td>Total Calorie</Td>
                  <Td>{item?.small?.totalCalories?.raw?.toFixed(2)}</Td>
                  <Td>{item?.small?.totalCalories?.cooked?.toFixed(2)}</Td>
                </tr>
                {item?.small?.ingredients?.map((el) => (
                  <tr style={{ fontSize: 12 }}>
                    <Td> {el?.ingredient?.name?.EN} </Td>
                    <Td>{el?.weight?.raw}</Td>
                    <Td>{el?.weight?.cooked || 0}</Td>
                  </tr>
                ))}
                <tr style={{ fontSize: 12, fontWeight: 600 }}>
                  <Td>Net weight</Td>
                  <Td>{item?.small?.netWeight?.raw}</Td>
                  <Td>{item?.small?.netWeight?.cooked}</Td>
                </tr>

                <Th colSpan={3}>{item?.small?.nbPax} Pax</Th>
              </Table>

              {/* Table for medium */}
              <Table>
                <tr
                  style={{
                    backgroundColor: "#232222",
                    color: "#FFFF",
                    borderColor: "black",
                  }}
                >
                  <Th
                    style={{
                      fontSize: 16,
                      textAlignLast: "justify",
                      borderColor: "black",
                      paddingLeft: 16,
                    }}
                  >
                    MEDIUM
                  </Th>
                  <Th style={{ fontSize: 15 }}>Raw</Th>
                  <Th style={{ fontSize: 15 }}>Cooked</Th>
                </tr>
                <tr style={{ fontSize: 12, fontWeight: 600 }}>
                  <Td>Total Calorie</Td>
                  <Td>{item?.medium?.totalCalories?.raw?.toFixed(2)}</Td>
                  <Td>{item?.medium?.totalCalories?.cooked?.toFixed(2)}</Td>
                </tr>
                {item?.medium?.ingredients?.map((el) => (
                  <tr style={{ fontSize: 12 }}>
                    <Td> {el?.ingredient?.name?.EN} </Td>
                    <Td>{el?.weight?.raw}</Td>
                    <Td>{el?.weight?.cooked || 0}</Td>
                  </tr>
                ))}
                <tr style={{ fontSize: 12, fontWeight: 600 }}>
                  <Td>Net weight</Td>
                  <Td>{item?.medium?.netWeight?.raw}</Td>
                  <Td>{item?.medium?.netWeight?.cooked}</Td>
                </tr>

                <Th colSpan={3}>{item?.medium?.nbPax} Pax</Th>
              </Table>

              {/* Table for large */}
              <Table>
                <tr
                  style={{
                    backgroundColor: "#232222",
                    color: "#FFFF",
                    borderColor: "black",
                  }}
                >
                  <Th
                    style={{
                      fontSize: 16,
                      textAlignLast: "justify",
                      borderColor: "black",
                      paddingLeft: 16,
                    }}
                  >
                    LARGE
                  </Th>
                  <Th style={{ fontSize: 15 }}>Raw</Th>
                  <Th style={{ fontSize: 15 }}>Cooked</Th>
                </tr>
                <tr style={{ fontSize: 12, fontWeight: 600 }}>
                  <Td>Total Calorie</Td>
                  <Td>{item?.large?.totalCalories?.raw?.toFixed(2)}</Td>
                  <Td>{item?.large?.totalCalories?.cooked?.toFixed(2)}</Td>
                </tr>
                {item?.large?.ingredients?.map((el) => (
                  <tr style={{ fontSize: 12 }}>
                    <Td> {el?.ingredient?.name?.EN} </Td>
                    <Td>{el?.weight?.raw}</Td>
                    <Td>{el?.weight?.cooked || 0}</Td>
                  </tr>
                ))}
                <tr style={{ fontSize: 12, fontWeight: 600 }}>
                  <Td>Net weight</Td>
                  <Td>{item?.large?.netWeight?.raw}</Td>
                  <Td>{item?.large?.netWeight?.cooked}</Td>
                </tr>

                <Th colSpan={3}>{item?.large?.nbPax} Pax</Th>
              </Table>
            </Box>
          </Box>
        ))}
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          columnGap={2.5}
          p={2}
        >
          {generateReport?.getPaxReport?.addons?.content?.map((item) => (
            <Box
              display="flex"
              flexDirection="column"
              style={{ pageBreakInside: "avoid" }}
            >
              <div
                style={{
                  fontSize: 15,
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                ADDONS : {item?.dish?.name?.EN}
                <div style={{ justifyContent: "right", display: "flex" }}></div>
              </div>

              <Table
                style={{
                  borderWidth: 1,
                  borderColor: "#232222",
                  borderCollapse: "collapse",
                }}
              >
                <tr
                  style={{
                    backgroundColor: "#232222",
                    color: "#FFFF",
                    borderColor: "black",
                  }}
                >
                  <Th
                    style={{
                      fontSize: 16,
                      textAlignLast: "justify",
                      borderColor: "black",
                      paddingLeft: 16,
                    }}
                  >
                    ADDON
                  </Th>
                  <Th style={{ fontSize: 15 }}>Raw</Th>
                  <Th style={{ fontSize: 15 }}>Cooked</Th>
                </tr>
                <tr style={{ fontSize: 12, fontWeight: 600 }}>
                  <Td>Total Calorie</Td>
                  <Td>{item?.info?.totalCalories?.raw?.toFixed(2)}</Td>
                  <Td>{item?.info?.totalCalories?.cooked?.toFixed(2)}</Td>
                </tr>
                {item?.info?.ingredients?.map((el) => (
                  <tr style={{ fontSize: 12 }}>
                    <Td> {el?.ingredient?.name?.EN} </Td>
                    <Td>{el?.weight?.raw}</Td>
                    <Td>{el?.weight?.cooked || 0}</Td>
                  </tr>
                ))}
                <tr style={{ fontSize: 12, fontWeight: 600 }}>
                  <Td>Net weight</Td>
                  <Td>{item?.info?.netWeight?.raw}</Td>
                  <Td>{item?.info?.netWeight?.cooked}</Td>
                </tr>

                <Th colSpan={3}>{item?.info?.nbPax} Pax</Th>
              </Table>
            </Box>
          ))}
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          columnGap={2.5}
          p={2}
        >
          {generateReport?.getPaxReport?.sauces?.content?.map((item) => (
            <Box
              display="flex"
              flexDirection="column"
              style={{ pageBreakInside: "avoid" }}
            >
              <div
                style={{
                  fontSize: 15,
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                SAUCES : {item?.dish?.name?.EN}
                <div style={{ justifyContent: "right", display: "flex" }}></div>
              </div>

              <Table
                style={{
                  borderWidth: 1,
                  borderColor: "#232222",
                  borderCollapse: "collapse",
                }}
              >
                <tr
                  style={{
                    backgroundColor: "#232222",
                    color: "#FFFF",
                    borderColor: "black",
                  }}
                >
                  <Th
                    style={{
                      fontSize: 16,
                      textAlignLast: "justify",
                      borderColor: "black",
                      paddingLeft: 16,
                    }}
                  >
                    SAUCES
                  </Th>
                  <Th style={{ fontSize: 15 }}>Raw</Th>
                  <Th style={{ fontSize: 15 }}>Cooked</Th>
                </tr>
                <tr style={{ fontSize: 12, fontWeight: 600 }}>
                  <Td>Total Calorie</Td>
                  <Td>{item?.info?.totalCalories?.raw?.toFixed(2)}</Td>
                  <Td>{item?.info?.totalCalories?.cooked?.toFixed(2)}</Td>
                </tr>
                {item?.info?.ingredients?.map((el) => (
                  <tr style={{ fontSize: 12 }}>
                    <Td> {el?.ingredient?.name?.EN} </Td>
                    <Td>{el?.weight?.raw}</Td>
                    <Td>{el?.weight?.cooked || 0}</Td>
                  </tr>
                ))}
                <tr style={{ fontSize: 12, fontWeight: 600 }}>
                  <Td>Net weight</Td>
                  <Td>{item?.info?.netWeight?.raw}</Td>
                  <Td>{item?.info?.netWeight?.cooked}</Td>
                </tr>

                <Th colSpan={3}>{item?.info?.nbPax} Pax</Th>
              </Table>
            </Box>
          ))}
        </Box>
      </div>
    );
  }
);

export default DishReportPrint;
