import { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { ApolloError } from "@apollo/client";
import { useSnackbar } from "notistack";
import moment from "moment";
//material ui
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
//queries&mutations
import {
  DishBoxPayload,
  GetCustomerPausesDocument,
  GetCustomerPlanDishesDocument,
  LunchMeal,
  MealDisplayV2Payload,
  PlanCondition,
  useAddFreezToCustomerMutation,
  useChangeBoxDishesMutation,
  useFindProgramMenuByDateLazyQuery,
  useGetActifCustomerPlansQuery,
  useGetCustomerPausesQuery,
  useGetCustomerPlanDishesLazyQuery,
  useMoveAddonToAnotherDayMutation,
  useUnskipFreezMutation,
} from "../../../graphql/types";
//styles
import {
  CustomerMenuProgramHeader,
  FullContainer,
  StyledLoaderContainer,
  StyledProgramInfosContainer,
} from "../../../styles/Customers__styles";
import {
  StyledContainer,
  StyledDay,
  StyledDaysWrapper,
  StyledDayText,
  StyledLineContainer,
  StyledMeal,
  StyledMealsWrapper,
  StyledMealText,
  StyledMenuItem,
  StyledPlanStatus,
  StyledProgramDishesWrapper,
} from "./CustomerMenuProgram.styles";
//components
import { AutoComplete } from "../../reusable/inputs/Autocomplete/AutoComplete";
//utils
import { chunk, getMessageError, handlePlanCondition } from "../../Utils";
import { isDayAFreeze } from "../Utils";
//types
import { formType } from "./CustomerMenuProgram.types";

const Portal = ({ children }: { children: React.ReactNode }) => {
  const portalNodeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = document.createElement("div");
    node.id = "dnd-portal";
    portalNodeRef.current = node;

    document.body.appendChild(node);

    return () => {
      if (portalNodeRef.current) {
        document.body.removeChild(portalNodeRef.current);
      }
    };
  }, []);

  if (!portalNodeRef.current) return null;

  return ReactDOM.createPortal(children, portalNodeRef.current);
};

const renderMeals = (
  meals: (LunchMeal | null | undefined)[],
  isLimited: boolean,
  displayMeals: MealDisplayV2Payload
) => {
  return (
    <>
      {meals.map((meal, index) => {
        if (meal === "BREAKFAST") {
          return (
            <StyledMeal key={index}>
              <StyledMealText>
                {isLimited ? displayMeals?.breakfast?.EN : "Breakfast"}
              </StyledMealText>
            </StyledMeal>
          );
        } else if (meal === "MORNING_SNACK") {
          return (
            <StyledMeal key={index}>
              <StyledMealText>
                {isLimited ? displayMeals?.snack?.EN : "AM Snack"}
              </StyledMealText>
            </StyledMeal>
          );
        } else if (meal === "LUNCH") {
          return (
            <StyledMeal key={index}>
              <StyledMealText>
                {isLimited ? displayMeals?.lunch?.EN : "Lunch"}
              </StyledMealText>
            </StyledMeal>
          );
        } else if (meal === "EVENING_SNACK") {
          return (
            <StyledMeal key={index}>
              <StyledMealText>
                {isLimited ? displayMeals?.snack?.EN : "PM Snack"}
              </StyledMealText>
            </StyledMeal>
          );
        } else if (meal === "DINNER") {
          return (
            <StyledMeal key={index}>
              <StyledMealText>
                {isLimited ? displayMeals?.dinner?.EN : "Dinner"}
              </StyledMealText>
            </StyledMeal>
          );
        }
      })}
      <StyledMeal>
        <StyledMealText>{"Addons"}</StyledMealText>
      </StyledMeal>
    </>
  );
};

export const planStatus = (status: PlanCondition | null | undefined) => {
  return (
    <StyledPlanStatus backgroundColor={handlePlanCondition(status)?.color}>
      {handlePlanCondition(status)?.text}
    </StyledPlanStatus>
  );
};

//the menu shows only 28 days at a time which is one month
const NUMBER_OF_DAYS = 28;
function CustomerMenuProgram() {
  //router
  const { id } = useParams();
  //react hook form
  const methods = useForm<formType>({
    defaultValues: {},
  });
  //consts
  const dataWatch = methods.watch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //state
  const [showSpinner, setShowSpinner] = useState(false);
  const [menu, setMenu] = useState<DishBoxPayload[]>([]);
  const [paginationFrom, setPaginationFrom] = useState<number>(0);
  const [paginationTo, setPaginationTo] = useState<number>(NUMBER_OF_DAYS);

  //queries&mutations
  const { data: dataPauses } = useGetCustomerPausesQuery({
    variables: { customerId: id || "" },
    fetchPolicy: "no-cache",
    skip: !dataWatch.program,
  });

  const { data: CustomerActifPlansData } = useGetActifCustomerPlansQuery({
    variables: {
      customerId: id || "",
    },
    fetchPolicy: "network-only",
    skip: !id,
  });

  const [
    getCustomerPlanDishes,
    { data: CustomerPlanDishesData, loading: LoadingCustomerPlanDishesData },
  ] = useGetCustomerPlanDishesLazyQuery({
    variables: {
      planId: dataWatch?.program?._id || "",
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      setMenu(data.getCustomerPlanDishes);
    },
  });

  const [unskipFunction] = useUnskipFreezMutation({
    onCompleted() {
      setShowSpinner(false);
    },
    onError() {
      setShowSpinner(false);
    },
  });

  const [addFreezeFunction] = useAddFreezToCustomerMutation({
    onCompleted() {
      setShowSpinner(false);
    },
    onError() {
      setShowSpinner(false);
    },
  });

  const [updateDish] = useChangeBoxDishesMutation();

  //get dish function
  const [
    getProgramMenuByDateLazy,
    { data: programMenuData, loading: loadingProgramMenuData },
  ] = useFindProgramMenuByDateLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const CustomerActifPlans = CustomerActifPlansData?.getActifCustomerPlans;
  const ProgramMenuPerDateAndMeal =
    programMenuData?.FindProgramMenuByDate || [];

  const meals = useMemo(() => {
    return CustomerPlanDishesData?.getCustomerPlanDishes[0]?.dishs?.map(
      (el) => el.meal
    );
  }, [CustomerPlanDishesData]);

  const renderItem = () => {
    return CustomerActifPlans?.map((item, index: number) => {
      return (
        <StyledMenuItem key={index} value={item as any}>
          <span>{item?.program?.name?.EN}</span>
          <div className="left-side-container">
            <span>
              {`(${moment(item.startDate).format("DD/MM/YYYY")} - ${moment(
                item.expiryDate
              ).format("DD/MM/YYYY")})`}{" "}
            </span>
            {planStatus(item.planCondition)}
          </div>
        </StyledMenuItem>
      );
    });
  };

  useEffect(() => {
    if (dataWatch.program) {
      setPaginationFrom(0);
      setPaginationTo(NUMBER_OF_DAYS);
      getCustomerPlanDishes();
    }
  }, [dataWatch.program]);

  const UnskipDayHandler = (date: Date, freezId: string) => {
    setShowSpinner(true);
    unskipFunction({
      variables: {
        date: date,
        planId: dataWatch?.program?._id || "",
        freezId: freezId || "",
      },
      refetchQueries: [
        GetCustomerPausesDocument,
        GetCustomerPlanDishesDocument,
      ],
    });
  };

  const SkipDayHandler = (date: Date) => {
    setShowSpinner(true);
    addFreezeFunction({
      variables: {
        freezPlanInput: { from: date, to: date, customerId: id || "" },
      },
      refetchQueries: [
        GetCustomerPausesDocument,
        GetCustomerPlanDishesDocument,
      ],
      awaitRefetchQueries: true,
    });
  };

  const LoadNextMonth = () => {
    setPaginationFrom((oldState) => oldState + NUMBER_OF_DAYS);
    setPaginationTo((oldState) => oldState + NUMBER_OF_DAYS);
  };

  const LoadLastMonth = () => {
    setPaginationFrom((oldState) => oldState - NUMBER_OF_DAYS);
    setPaginationTo((oldState) => oldState - NUMBER_OF_DAYS);
  };

  const [moveAddonToAnotherDay] = useMoveAddonToAnotherDayMutation();

  const onDragEnd = async (result: DropResult) => {
    try {
      const { source, destination, draggableId } = result;

      if (!destination) return;

      if (source.droppableId === destination.droppableId) return;

      const sourceDate = source.droppableId;
      const destinationDate = destination.droppableId;
      const addonId = draggableId.split("-")[0];

      await moveAddonToAnotherDay({
        variables: {
          from: sourceDate,
          to: destinationDate,
          addonId: addonId,
        },
        onCompleted: () => {
          const updatedMenu = [...menu];
          const sourceDay = updatedMenu.find((day) => day._id === sourceDate);
          const destinationDay = updatedMenu.find(
            (day) => day._id === destinationDate
          );

          if (sourceDay && destinationDay) {
            const addon = sourceDay.addons?.find(
              (addon) => addon._id === addonId
            );
            if (addon) {
              sourceDay.addons = sourceDay.addons?.filter(
                (addon) => addon._id !== addonId
              );
              destinationDay.addons = [...(destinationDay.addons || []), addon];
            }
          }

          setMenu(updatedMenu);
          enqueueSnackbar("Addon Moved successfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 3000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 3000);
      console.error(error);
    }
  };

  return (
    <FullContainer>
      <CustomerMenuProgramHeader>
        <Controller
          control={methods.control}
          name="program"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const isError = error && error.message !== null;
            return (
              <FormControl variant="outlined" error={isError}>
                <InputLabel id="Program">Program</InputLabel>
                <Select
                  labelId={`select-program`}
                  id="program"
                  value={value || ""}
                  error={isError}
                  label="Program"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  sx={{ minWidth: "500px" }}
                  renderValue={(val) => {
                    return <p style={{ margin: 0 }}>{val.program.name.EN}</p>;
                  }}
                >
                  {renderItem()}
                </Select>
              </FormControl>
            );
          }}
        />
        {dataWatch.program && (
          <StyledProgramInfosContainer>
            <div className="program_data">
              <span className="program_date_label">From:</span>
              <span className="program_date_value">
                {moment(dataWatch.program.startDate).format("DD/MM/YYYY")}
              </span>
            </div>
            <div className="program_data">
              <span className="program_date_label">To:</span>
              <span className="program_date_value">
                {moment(dataWatch.program.expiryDate).format("DD/MM/YYYY")}
              </span>
            </div>
            {planStatus(dataWatch.program.planCondition)}
          </StyledProgramInfosContainer>
        )}
      </CustomerMenuProgramHeader>
      {(LoadingCustomerPlanDishesData || showSpinner) && (
        <StyledLoaderContainer>
          <CircularProgress size={40} color="secondary" />
        </StyledLoaderContainer>
      )}
      {dataWatch.program && !LoadingCustomerPlanDishesData && !showSpinner && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          marginBottom={5}
        >
          <Button
            variant="contained"
            onClick={LoadLastMonth}
            disabled={!menu || paginationFrom <= 0}
          >
            <UploadIcon />
          </Button>
        </Box>
      )}

      {dataWatch.program && !LoadingCustomerPlanDishesData && !showSpinner && (
        <FormProvider {...methods}>
          <DragDropContext onDragEnd={onDragEnd}>
            <StyledContainer>
              {chunk(7, menu.slice(paginationFrom, paginationTo) || []).map(
                (block, ind) => {
                  return (
                    <StyledLineContainer key={ind}>
                      <StyledMealsWrapper>
                        {renderMeals(
                          meals || [],
                          dataWatch.program?.program?.isLimited || false,
                          dataWatch.program?.program?.displayMeals
                        )}
                      </StyledMealsWrapper>

                      <StyledDaysWrapper>
                        {block.map((day, index) => (
                          <StyledProgramDishesWrapper key={index}>
                            <StyledDay>
                              {isDayAFreeze(
                                dataPauses?.getCustomerPauses,
                                new Date(day?.date || new Date())
                              ).freezeFound &&
                              moment().isSameOrBefore(day?.date, "day") ? (
                                <StyledDayText>
                                  {moment(day?.date).format("MMM Do YYYY")}
                                  <Button
                                    variant="text"
                                    onClick={() =>
                                      UnskipDayHandler(
                                        day?.date ?? new Date(),
                                        isDayAFreeze(
                                          dataPauses?.getCustomerPauses,
                                          new Date(day?.date ?? new Date())
                                        ).freezeId
                                      )
                                    }
                                    size="small"
                                  >
                                    Unskip
                                  </Button>
                                </StyledDayText>
                              ) : (
                                <StyledDayText>
                                  {moment(day?.date).format("MMM Do YYYY")}
                                  {moment().isSameOrBefore(day?.date, "day") ? (
                                    <Button
                                      variant="text"
                                      onClick={() =>
                                        SkipDayHandler(day?.date ?? new Date())
                                      }
                                      size="small"
                                    >
                                      Skip
                                    </Button>
                                  ) : null}
                                </StyledDayText>
                              )}
                            </StyledDay>
                            {day?.dishs?.map((el, j) => (
                              <div key={`${el._id}-${j}`}>
                                <AutoComplete
                                  key={`${el._id}-${j}`}
                                  disabled={
                                    isDayAFreeze(
                                      dataPauses?.getCustomerPauses,
                                      new Date(day?.date ?? new Date())
                                    ).freezeFound
                                  }
                                  name="concernedCompanies"
                                  loading={loadingProgramMenuData}
                                  data={ProgramMenuPerDateAndMeal}
                                  getOptionLabel={(option) => {
                                    return option?.name?.EN || "";
                                  }}
                                  getOptionValue={(option, value) =>
                                    option?._id === value?._id
                                  }
                                  rules={{
                                    required: "This field is required",
                                  }}
                                  sx={{
                                    backgroundColor: isDayAFreeze(
                                      dataPauses?.getCustomerPauses,
                                      new Date(day?.date || new Date())
                                    ).freezeFound
                                      ? "#d3d3d3"
                                      : "transparent",
                                    width: "190px",
                                  }}
                                  size="medium"
                                  placeholder="Choose a meal"
                                  defaultValue={{
                                    name: el?.dish?.name,
                                    _id: el?._id,
                                  }}
                                  disableClearable={true}
                                  onFocus={() => {
                                    getProgramMenuByDateLazy({
                                      variables: {
                                        findProgramMenuByDateInput: {
                                          customerId: id || "",
                                          date: day?.date ?? new Date(),
                                          mealType:
                                            (el?.meal?.includes("SNACK")
                                              ? "SNACKS"
                                              : el?.meal) ?? "BREAKFAST",
                                          program:
                                            dataWatch?.program?.program?._id ||
                                            "",
                                        },
                                      },
                                    });
                                  }}
                                  onChangeExtra={(value) => {
                                    updateDish({
                                      variables: {
                                        changeBoxDishesInput: {
                                          date: day?.date ?? new Date(),
                                          dishes: [
                                            {
                                              oldDish: el?.dish?._id ?? "",
                                              dish: value?._id,
                                              mealType: el?.meal ?? "BREAKFAST",
                                            },
                                          ],
                                          planId: dataWatch?.program?._id || "",
                                        },
                                      },
                                    });
                                  }}
                                />
                              </div>
                            ))}
                            <Droppable
                              key={day._id}
                              droppableId={day._id || ""}
                              isDropDisabled={
                                isDayAFreeze(
                                  dataPauses?.getCustomerPauses,
                                  new Date(day?.date || new Date())
                                ).freezeFound
                              }
                            >
                              {(provided) => (
                                <Box
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  border={1}
                                  borderRadius={1}
                                  borderColor={"rgb(196 196 196)"}
                                  p={1}
                                  sx={{
                                    "&:hover": { borderColor: "#888888" },
                                    justifyContent: "start",
                                    gap: "4px",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    fontSize: "10px",
                                    backgroundColor: isDayAFreeze(
                                      dataPauses?.getCustomerPauses,
                                      new Date(day?.date || new Date())
                                    ).freezeFound
                                      ? "#d3d3d3"
                                      : "transparent",
                                    overflowY: "auto",
                                    height: "56px",
                                    maxWidth: "170px",
                                  }}
                                >
                                  {day?.addons?.map((el, j) => (
                                    <Draggable
                                      key={`${el._id}-${index}-${j}`}
                                      draggableId={`${el._id}-${index}-${j}`}
                                      index={j}
                                    >
                                      {(provided, snapshot) => {
                                        const content = (
                                          <Box
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              ...provided.draggableProps.style,
                                              fontSize: "10px",
                                            }}
                                            color={"#888888"}
                                            sx={{
                                              backgroundColor: "transparent",
                                              borderRadius: "4px",
                                              padding: "4px",
                                              border: "1px solid",
                                              borderColor: "#ccc",
                                              height: "fit-content",
                                            }}
                                          >
                                            {el && (
                                              <Box>
                                                {el?.name?.EN} - {el.quantity}
                                              </Box>
                                            )}
                                          </Box>
                                        );

                                        return snapshot.isDragging ? (
                                          <Portal>{content}</Portal>
                                        ) : (
                                          content
                                        );
                                      }}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </Box>
                              )}
                            </Droppable>
                          </StyledProgramDishesWrapper>
                        ))}
                      </StyledDaysWrapper>
                    </StyledLineContainer>
                  );
                }
              )}
            </StyledContainer>
          </DragDropContext>
        </FormProvider>
      )}
      {dataWatch.program && !LoadingCustomerPlanDishesData && !showSpinner && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          marginTop={5}
        >
          <Button
            variant="contained"
            onClick={LoadNextMonth}
            disabled={!menu || paginationTo > menu.length}
          >
            <FileDownloadIcon />
          </Button>
        </Box>
      )}
    </FullContainer>
  );
}

export default CustomerMenuProgram;
