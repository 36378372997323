function AppointmentRequested() {
  return (
    <svg
      width="32"
      height="36"
      viewBox="0 0 59 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_394_60825)">
        <path
          d="M41.1983 63.0006H1.05963C0.466236 63.0006 0 62.5349 0 61.9422V12.9139C0 12.3212 0.466236 11.8555 1.05963 11.8555H6.48491C7.0783 11.8555 7.54454 12.3212 7.54454 12.9139C7.54454 13.5067 7.0783 13.9724 6.48491 13.9724H2.11925V60.8837H40.1387V48.2244C40.1387 47.6317 40.6049 47.166 41.1983 47.166C41.7917 47.166 42.2579 47.6317 42.2579 48.2244V61.9422C42.2579 62.5349 41.7917 63.0006 41.1983 63.0006Z"
          fill="#fff"
        />
        <path
          d="M41.2002 28.4946C40.6068 28.4946 40.1405 28.0289 40.1405 27.4361V13.9724H35.5206C34.9272 13.9724 34.4609 13.5067 34.4609 12.9139C34.4609 12.3212 34.9272 11.8555 35.5206 11.8555H41.2002C41.7936 11.8555 42.2598 12.3212 42.2598 12.9139V27.3938C42.2598 27.9865 41.7936 28.4522 41.2002 28.4522V28.4946Z"
          fill="#fff"
        />
        <path
          d="M30.6883 20.3649H11.5726C9.62293 20.3649 8.05469 18.7984 8.05469 16.8508V13.5907C8.05469 11.6431 9.62293 10.0766 11.5726 10.0766H11.9965C11.9965 9.78024 11.9541 9.48387 11.9541 9.1875C11.9117 4.14919 16.0655 0 21.1517 0C26.2379 0 30.3492 4.14919 30.3492 9.1875C30.3492 9.48387 30.3492 9.78024 30.3068 10.0766H30.7307C32.6804 10.0766 34.2487 11.6431 34.2487 13.5907V16.8508C34.2487 18.7984 32.6804 20.3649 30.7307 20.3649H30.6883ZM11.5726 12.2359C10.8097 12.2359 10.1739 12.871 10.1739 13.6331V16.8931C10.1739 17.6552 10.8097 18.2903 11.5726 18.2903H30.6883C31.4512 18.2903 32.087 17.6552 32.087 16.8931V13.6331C32.087 12.871 31.4512 12.2359 30.6883 12.2359H29.0353C28.6962 12.2359 28.3995 12.0665 28.1876 11.8125C27.9757 11.5585 27.9333 11.2198 27.9757 10.9234C28.1028 10.3306 28.1876 9.78024 28.1876 9.22984C28.1876 5.33468 25.0087 2.15927 21.1093 2.15927C17.2099 2.15927 13.9886 5.33468 13.9886 9.22984C13.9886 9.78024 14.0734 10.3306 14.2005 10.9234C14.2853 11.2198 14.2005 11.5585 13.9886 11.8125C13.7767 12.0665 13.48 12.2359 13.1409 12.2359H11.4879H11.5726Z"
          fill="#fff"
        />
        <path
          d="M25.1325 54.5327C24.8358 54.5327 24.5815 54.4057 24.3696 54.2363C24.1153 53.9823 24.0305 53.6436 24.0729 53.3049L25.2597 47.2504C25.2597 47.2504 25.3868 46.8694 25.5564 46.7L46.9608 25.319C47.3423 24.9379 48.0629 24.9379 48.4443 25.319L53.3186 30.1879C53.7425 30.6113 53.7425 31.2887 53.3186 31.6698L31.9141 53.0508C31.9141 53.0508 31.5751 53.3049 31.3631 53.3472L25.3021 54.5327C25.3021 54.5327 25.1749 54.5327 25.0902 54.5327H25.1325ZM27.2942 47.9279L26.4889 52.1194L30.685 51.315L51.0722 30.95L47.6814 27.5629L27.2942 47.9702V47.9279Z"
          fill="#fff"
        />
        <path
          d="M55.0589 29.5521C54.8046 29.5521 54.5079 29.4674 54.296 29.2557L49.4217 24.3868C49.4217 24.3868 49.125 23.9211 49.125 23.6247C49.125 23.3283 49.2522 23.0743 49.4217 22.8626L52.3039 19.9836C52.7277 19.5602 53.4059 19.5602 53.7874 19.9836L58.6616 24.8525C58.6616 24.8525 58.9583 25.3182 58.9583 25.6146C58.9583 25.911 58.8312 26.165 58.6616 26.3767L55.7795 29.2557C55.7795 29.2557 55.3132 29.5521 55.0165 29.5521H55.0589ZM51.6681 23.6247L55.0589 27.0118L56.4576 25.6146L53.0668 22.2275L51.6681 23.6247Z"
          fill="#fff"
        />
        <path
          d="M41.323 38.3593C41.0687 38.3593 40.772 38.2746 40.5601 38.0629C40.1362 37.6395 40.1362 36.9621 40.5601 36.581L49.3762 27.7746C49.8 27.3512 50.4782 27.3512 50.8596 27.7746C51.2411 28.198 51.2835 28.8754 50.8596 29.2564L42.0436 38.0629C42.0436 38.0629 41.5773 38.3593 41.2806 38.3593H41.323Z"
          fill="#fff"
        />
        <path
          d="M31.2364 53.3475C30.982 53.3475 30.6853 53.2629 30.4734 5e3.0512L25.5991 48.1822C25.1753 47.7588 25.1753 47.0814 25.5991 46.7004C26.023 46.277 26.7011 46.277 27.0826 46.7004L31.9569 51.5693C32.3807 51.9927 32.3807 52.6701 31.9569 53.0512C31.745 53.2629 31.4907 53.3475 31.194 53.3475H31.2364Z"
          fill="#fff"
        />
        <path
          d="M8.26724 35.7327C7.97055 35.7327 7.71624 35.6057 7.50431 35.4364L5.72414 33.6581C5.30029 33.2348 5.30029 32.5573 5.72414 32.1763C6.14799 31.7952 6.82615 31.7529 7.20762 32.1763L8.26724 33.2348L13.3111 28.1964C13.7349 27.7731 14.4131 27.7731 14.7945 28.1964C15.176 28.6198 15.2184 29.2973 14.7945 29.6783L9.03017 35.4364C9.03017 35.4364 8.56394 35.7327 8.26724 35.7327Z"
          fill="#fff"
        />
        <path
          d="M8.26724 45.2601C7.97055 45.2601 7.71624 45.1331 7.50431 44.9637L5.72414 43.1855C5.30029 42.7621 5.30029 42.0847 5.72414 41.7036C6.14799 41.3226 6.82615 41.2802 7.20762 41.7036L8.26724 42.7621L13.3111 37.7238C13.7349 37.3004 14.4131 37.3004 14.7945 37.7238C15.176 38.1472 15.2184 38.8246 14.7945 39.2056L9.03017 44.9637C9.03017 44.9637 8.56394 45.2601 8.26724 45.2601Z"
          fill="#fff"
        />
        <path
          d="M8.26724 54.7874C7.97055 54.7874 7.71624 54.6604 7.50431 54.4911L5.72414 52.7128C5.30029 52.2894 5.30029 51.612 5.72414 51.231C6.14799 50.8499 6.82615 50.8076 7.20762 51.231L8.26724 52.2894L13.3111 47.2511C13.7349 46.8277 14.4131 46.8277 14.7945 47.2511C15.176 47.6745 15.2184 48.3519 14.7945 48.733L9.03017 54.4911C9.03017 54.4911 8.56394 54.7874 8.26724 54.7874Z"
          fill="#fff"
        />
      </g>
      {/* <defs>
        <clipPath id="clip0_394_60825">
          <rect width="59" height="63" fill="white" />
        </clipPath>
      </defs> */}
    </svg>
  );
}

export default AppointmentRequested;
