import { FilterBar } from "../../Utils";
import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "CUSTOMER NAME",
    accessor: "name",
  },
  {
    header: "MOBILE",
    accessor: "mobile",
  },

  {
    header: "Converted At",
    accessor: "convertedAt",
  },
  {
    header: "Called At",
    accessor: "calledAt",
  },
  {
    header: "Amount",
    accessor: "amount",
  },
  {
    header: "Converted By",
    accessor: "convertedBy",
  },

  {
    header: "",
    accessor: "actions",
  },
];

export const SIDE_FILTER_OPTIONS_CONVERTED_LEADS: FilterBar[] = [];
