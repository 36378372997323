import { Theme, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { lighten } from "polished";

export const useStylesTables = makeStyles((theme: Theme) => {
  return {
    cell_image_container: {
      maxHeight: "48px",
    },
  };
});

export const TableContainer = styled("div")(({ theme }) => ({
  width: "auto",
  // padding: "10px",
  backgroundColor: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
}));

export const TableCellStyled = styled(TableCell)`
  background: ${({ theme }) => lighten(0.3, theme.palette.secondary.light)};
  color: ${({ theme }) => theme.palette.common.black};
  text-align: center;
  padding: "12px 16px";
  border: none;
  &:first-of-type {
    border-top-left-radius: 6px;
    /* border-bottom-left-radius: 8px; */
  }
  &:last-child {
    border-top-right-radius: 6px;
    /* border-bottom-right-radius: 8px; */
  }
`;
// eslint-disable-next-line
type TableCellStyledDataProps = { hideborder?: boolean | string };

export const TableCellStyledData = styled(TableCell)<TableCellStyledDataProps>`
  /* background: ${({ theme }) => theme.palette.secondary.contrastText}; */
  color: ${({ theme }) => theme.palette.common.black};
  text-align: center;
  border: ${({ hideborder }) => (hideborder ? "none" : undefined)};
  padding: 6px 0;
  /* padding: 6px 0 6px 16px !important; */
  font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
  font-weight: 500;
  min-width: 60px;
  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const TableRowStyled = styled(TableRow)`
  ${({ hover }) => hover && { cursor: "pointer" }}
`;
