import { useContext, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useParams } from "react-router-dom";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesLeads } from "../../../styles/Leads__styles";
import { useStylesSelect } from "../../../styles/Select__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { StyledMenuItem } from "../CustomerMenuProgram/CustomerMenuProgram.styles";
//types
import { RenewMealPlanState } from "../Types";
import { ApolloError } from "@apollo/client";
import { CustomerActionsTypes } from "./Types";
//context
import { CustomerContext } from "../viewCustomer/ViewCustomer";
//queries&mutations
import {
  useGetActifCustomerPlansQuery,
  useRenewPlanMutation,
} from "../../../graphql/types";
//utils
import { getMessageError } from "../../Utils";

const RenewMealPlant: React.FC<CustomerActionsTypes> = ({
  expanded,
  handleChange,
}) => {
  const { id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const SelectClasses = useStylesSelect();
  const LeadsClasses = useStylesLeads();
  const ButtonClasses = useStylesButtons();
  const ContainersClasses = useStylesContainers();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [open, setOpen] = useState<boolean>(false);

  //react book form
  const methodsRenewMealPlan = useForm<RenewMealPlanState>({
    defaultValues: {
      customerProgram: null,
      startDate: null,
      paid: false,
      paymentMethod: null,
      promoCode:"",
    },
  });

  const {
    formState: { errors },
    control,
    setValue,
  } = methodsRenewMealPlan;

  const dataWatch = useWatch({
    control: methodsRenewMealPlan.control,
  });

  //context
  const context = useContext(CustomerContext);

  //queries
  const { data: CustomerActifPlansData } = useGetActifCustomerPlansQuery({
    variables: {
      customerId: id || "",
    },
    skip: !id || expanded != "RENEW_MEAL_PLAN",
  });

  //mutations
  const [renewPlan, { loading: loadingRenewPlan }] = useRenewPlanMutation();

  //consts
  const CustomerActifPlans = CustomerActifPlansData?.getActifCustomerPlans;

  //useEffects
  useEffect(() => {
    setValue(
      "customerProgram",
      context?.getCustomerProfileById?.plan?._id || ""
    );
  }, []);

  //functions
  const onSubmitRenewMealPlan: SubmitHandler<RenewMealPlanState> = async (
    data: RenewMealPlanState
  ) => {
    try {
      await renewPlan({
        variables: {
          renewPlanInput: {
            isPaid: data.paid,
            paymentMethod: data.paymentMethod ?? "CASH",
            planId: data.customerProgram ?? "",
            renwalDate: data.startDate ?? new Date(),
            promoCode: data.promoCode,
          },
        },
        onCompleted: () => {
          methodsRenewMealPlan.reset();
          enqueueSnackbar("Renewal succuessfully done", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  //render
  const renderItems = () => {
    return CustomerActifPlans?.map((item) => {
      return (
        <StyledMenuItem
          key={item._id}
          value={item?._id || ""}
          className={
            dataWatch?.customerProgram === item._id
              ? clsx(
                  SelectClasses.option_item,
                  SelectClasses.option_item_selected
                )
              : SelectClasses.option_item
          }
          onClick={() => setValue("customerProgram", item._id || "")}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <span>{item?.program?.name?.EN}</span>
            <div className="left-side-container">
              <span>
                {`(${moment(item.startDate).format("DD/MM/YYYY")} - ${moment(
                  item.expiryDate
                ).format("DD/MM/YYYY")})`}{" "}
              </span>
            </div>
          </Box>
        </StyledMenuItem>
      );
    });
  };

  return (
    <Accordion
      expanded={expanded === "RENEW_MEAL_PLAN"}
      onChange={handleChange("RENEW_MEAL_PLAN")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="#B27D3F" fontSize={18}>
          Renew Meal Plan
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormProvider {...methodsRenewMealPlan}>
          <form
            onSubmit={methodsRenewMealPlan.handleSubmit(onSubmitRenewMealPlan)}
            id="RENEW_MEAL_PLAN_FORM"
          >
          <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                rowGap={0}
                columnGap={4}
          >
              <Box display="flex" flexDirection="column" flexGrow={1} >
                <p className={ContainersClasses.section__subtitle}>
                  Plan to renew
                </p>
                <Controller
                  name="customerProgram"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        {...rest}
                        sx={{ minWidth: "400px" }}
                        className={LeadsClasses.select_container}
                        onChange={(event) => {
                          let plan = event.target.value;
                          onChange(plan);
                        }}
                        value={dataWatch.customerProgram}
                      >
                        {renderItems()}
                      </Select>
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {error?.message}
                      </p>
                    </>
                  )}
                  rules={{
                    required: {
                      message: "Field Required",
                      value: true,
                    },
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <p className={ContainersClasses.section__subtitle}>
                  Start date
                </p>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <Controller
                    name="startDate"
                    control={methodsRenewMealPlan.control}
                    render={({ field: { ref, ...rest } }) => (
                      <DesktopDatePicker
                        {...rest}
                        inputFormat="DD/MM/YYYY"
                        disablePast={false}
                        disableFuture={false}
                        value={dataWatch?.startDate}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ minWidth: "260px" }} />
                        )}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: "Field Required",
                      },
                      validate: (value) =>
                        value === null ||
                        moment(value).isValid() ||
                        "Enter a valid Birth Date",
                    }}
                  />
                </LocalizationProvider>
                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.startDate?.message}
                </p>
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1} sx={{ margin: '60px' }}  justifyContent="center">
                <FormControlLabel
                  control={
                    <Switch
                      {...methodsRenewMealPlan.register("paid")}
                      checked={dataWatch.paid}
                      color="secondary"
                      
                    />
                  }
                  
                  label="Is Paid"
                />
              </Box>
              {!dataWatch.paid && (
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={ContainersClasses.section__subtitle}>
                    Payment method
                  </p>
                  <Controller
                    name="paymentMethod"
                    control={methodsRenewMealPlan.control}
                    render={({ field: { ref, onChange, ...rest } }) => (
                      <Select
                        {...rest}
                        sx={{ minWidth: "260px" }}
                        className={LeadsClasses.select_container}
                        onChange={(event) => onChange(event.target.value)}
                      >
                        <MenuItem
                          className={
                            dataWatch?.paymentMethod === "DEBIT_CARD"
                              ? clsx(
                                  LeadsClasses.option_item,
                                  LeadsClasses.option_item_selected
                                )
                              : LeadsClasses.option_item
                          }
                          value="DEBIT_CARD"
                        >
                          Debit Card
                        </MenuItem>
                        <MenuItem
                          className={
                            dataWatch?.paymentMethod === "CREDIT_CARD"
                              ? clsx(
                                  LeadsClasses.option_item,
                                  LeadsClasses.option_item_selected
                                )
                              : LeadsClasses.option_item
                          }
                          value="CREDIT_CARD"
                        >
                          Credit Card
                        </MenuItem>
                        <MenuItem
                          className={
                            dataWatch?.paymentMethod === "CASH"
                              ? clsx(
                                  LeadsClasses.option_item,
                                  LeadsClasses.option_item_selected
                                )
                              : LeadsClasses.option_item
                          }
                          value="CASH"
                        >
                          Cash
                        </MenuItem>
                      </Select>
                    )}
                    rules={{
                      required: {
                        message: "Field Required",
                        value: true,
                      },
                    }}
                  />
                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {errors?.paymentMethod?.message}
                  </p>
                </Box>
              )}
                { <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={ContainersClasses.section__subtitle}>
                    Promo code
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Promo code"
                    fullWidth
                    {...methodsRenewMealPlan.register("promoCode")}
                  />
                </Box> }
            </Box>
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="flex-end"
              mt={1}
            >
              <Button
                variant="contained"
                className={clsx(ButtonClasses.button_open_list_page)}
                onClick={() => setOpen(true)}
                disabled={loadingRenewPlan}
              >
                {loadingRenewPlan && (
                  <CircularProgress
                    size={20}
                    style={{ color: "white", marginRight: 6 }}
                  />
                )}
                Renew
              </Button>
            </Box>

            {/* CONFIRMATION MODAL */}
            <Dialog
              open={open}
              onClose={handleCloseModal}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.alert_dialog_title}>
                <span className="alert_dialog_title_text">Confirm Action</span>
              </DialogTitle>
              <DialogContent className={DialogClasses.alert_dialog_content}>
                <DialogContentText>
                  Are you sure you want to renew this meal plan ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={DialogClasses.alert_dialog_actions}>
                <Button
                  className={ButtonsClasses.GreyButton}
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  className={ButtonsClasses.GreenButton}
                  type="submit"
                  form="RENEW_MEAL_PLAN_FORM"
                  color="primary"
                  autoFocus
                  onClick={() => setOpen(false)}
                >
                  Renew
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default RenewMealPlant;
