import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
//material ui
import Switch from "@mui/material/Switch";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Menu,
  Button,
  MenuItem,
  IconButton,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
//components
import Table from "../../layout/commun/Table";
import PageHeader from "../../pageHeader/PageHeader";
//icons
import Delete from "../../../public/icons/delete";
//Utils
import { PromocodeUsersTableColumns, columns, filterElements } from "./Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { DialogTitleProps, getMessageError } from "../../Utils";
//styles
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//queries&mutations
import {
  GetAllPromoCodesDocument,
  PromoCodeType,
  useActivateDesactivatePromoCodeMutation,
  useDeletePromoCodeMutation,
  useGetAllPromoCodesQuery,
  useGetPromoCodeUsersLazyQuery,
} from "../../../graphql/types";

const PromoCode = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //state
  const [open, setOpen] = useState<boolean>(false);
  const [openPromoCodeUsersModal, setOpenPromoCodeUsersModal] =
    useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [sortBy, setSortBy] = useState<PromoCodeType[] | null>(null);
  const [promoCodeId, setpromoCodeId] = useState<string | null>(null);

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //queries&mutations
  const [deletePromoCode] = useDeletePromoCodeMutation();
  const [updatePromoCodeStatus] = useActivateDesactivatePromoCodeMutation();
  const PromoCode = useGetAllPromoCodesQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        search: search,
        type: sortBy,
      },
    },
    fetchPolicy: "no-cache",
  });

  const PromoCodeList = useMemo(
    () => PromoCode.data?.getAllPromoCodes.data || [],
    [PromoCode.data]
  );

  const count = useMemo(
    () => PromoCode.data?.getAllPromoCodes.count || 0,
    [PromoCode.data]
  );

  const [getPromoCodeUsers, { data: promoCodeUsers, loading }] =
    useGetPromoCodeUsersLazyQuery({
      fetchPolicy: "no-cache",
    });

  //functions
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (promoCodeId) {
      navigate(`/data/promoCode/update_promoCode/${promoCodeId}`);
    }
  };

  const handleClickMenu =
    (item: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setpromoCodeId(item);
    };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const updateStatusHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    await updatePromoCodeStatus({
      variables: {
        value: { id: id, isActive: event.target.checked || false },
      },
      refetchQueries: [GetAllPromoCodesDocument],
      onCompleted() {
        enqueueSnackbar("Promo code status changed succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const onLineClickHandler = async (id: string) => {
    setOpenPromoCodeUsersModal(true);
    await getPromoCodeUsers({
      variables: {
        id: id,
      },
    });
  };

  const renderTableRows = () => {
    return (
      PromoCodeList?.map((el, index) => {
        return {
          ref: index + 1 + (page - 1) * rowsPerPage,
          promoName: el?.name,
          promoCode: el?.code,
          usedBy: (
            <Button onClick={() => onLineClickHandler(el._id || "")}>
              {el?.usedBy}
            </Button>
          ),
          users: el.numberofUsers,
          startingDate: el?.startDate
            ? moment(el?.startDate).format("DD/MM/YYYY")
            : "--",
          endDate: el?.expireDate
            ? moment(el?.expireDate).format("DD/MM/YYYY")
            : "--",
          type: el?.type,
          status: (
            <Switch
              defaultChecked={el?.isActive || false}
              onChange={(event) => updateStatusHandler(event, el._id || "")}
            />
          ),
          actions: (
            <IconButton onClick={handleClickMenu(el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  const renderPromoCodeUsersTableRows = () => {
    return (
      promoCodeUsers?.getPromoCodeUsers?.map((el, index) => {
        return {
          ref: index + 1,
          name: el.customer?.fname + " " + el.customer?.lname,
          date: moment(el?.usedAt).format("DD/MM/YYYY"),
        };
      }) || []
    );
  };

  const DeletePromocodeHandler = async () => {
    setOpen(false);
    await deletePromoCode({
      variables: { value: promoCodeId || "" },
      refetchQueries: [GetAllPromoCodesDocument],
      onCompleted() {
        enqueueSnackbar("Promo code deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              height: "fit-content",
            }}
          >
            <CloseIcon
              style={{
                minHeight: "22px",
                height: "22px",
                width: "22px",
                marginBottom: "0px",
              }}
            />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <>
        {useIsAuthorized("MANAGEMENT_PROMOCODE_CREATE") ? (
          <PageHeader
            buttonLink="/data/promoCode/add_promoCode"
            buttonText="Create"
            filterElements={filterElements}
            setSelectedFilter={setSortBy}
            selectedFilter={sortBy}
            setSearchValue={setSearch}
          />
        ) : (
          <PageHeader
            filterElements={filterElements}
            setSelectedFilter={setSortBy}
            selectedFilter={sortBy}
            setSearchValue={setSearch}
          />
        )}

        <br />
        <TableContainer>
          <Table
            columns={columns}
            data={renderTableRows()}
            emptyMessage="No promo codes found !"
            loading={PromoCode.loading}
            numberOfFakeRow={30}
            tablePaginationProps={{
              rowsPerPageOptions: [30, 60, 90],
              count: count,
              page: page - 1,
              onPageChange,
              rowsPerPage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            paginationProps={{
              page: page,
              count: Math.ceil(count / rowsPerPage),
              showFirstButton: true,
              showLastButton: true,
              boundaryCount: 1,
              siblingCount: 2,
              onChange: onPageChange,
            }}
          />
        </TableContainer>

        <Box className={classes.menu_container}>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorPosition={{
              left: anchorEl?.getBoundingClientRect().left || 0,
              top: anchorEl?.getBoundingClientRect().top || 0,
            }}
            anchorOrigin={{ horizontal: "left", vertical: "center" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorReference="anchorPosition"
          >
            {useIsAuthorized("MANAGEMENT_PROMOCODE_UPDATE") && (
              <MenuItem
                onClick={handleCloseUpdate}
                className={classes.menu_container}
                disableRipple={true}
              >
                <Box>
                  <span className="menu_title">Update</span>
                </Box>
              </MenuItem>
            )}

            {useIsAuthorized("MANAGEMENT_PROMOCODE_DELETE") && (
              <MenuItem
                onClick={handleClose}
                className={classes.menu_delete_container}
                disableRipple={true}
              >
                <Box onClick={() => setOpen(true)}>
                  <span className="menu_title">Delete</span>
                </Box>
              </MenuItem>
            )}
          </Menu>
        </Box>

        {/* DELETE PROMOCODE */}
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete PromoCode ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this PromoCode ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={DeletePromocodeHandler}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* LIST OF PROMOCODE USERS */}
        <Dialog
          open={openPromoCodeUsersModal}
          onClose={() => setOpenPromoCodeUsersModal(false)}
          className={DialogClasses.dialog_renew_container}
        >
          <BootstrapDialogTitle
            className={DialogClasses.alert_dialog_title}
            onClose={() => setOpenPromoCodeUsersModal(false)}
          >
            <span
              className="confirm_dialog_title_text"
              style={{ alignSelf: "flex-start" }}
            >
              Promocode Users List
            </span>
          </BootstrapDialogTitle>
          <DialogContent>
            <TableContainer>
              <Table
                columns={PromocodeUsersTableColumns}
                data={renderPromoCodeUsersTableRows()}
                emptyMessage="No users for this promocode !"
                loading={loading}
                numberOfFakeRow={30}
              />
            </TableContainer>
          </DialogContent>
        </Dialog>
      </>
    </ClickAwayListener>
  );
};

export default PromoCode;
