function TotalRenewedCustomer() {
  return (
    <svg
      width="43"
      height="27"
      viewBox="0 0 85 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_394_60735)">
        <path
          d="M8.13304 32.3798C8.13304 32.3798 7.68091 32.3297 7.5302 32.2296L0.597579 28.276C0.296161 28.1258 0.095215 27.8256 -0.0052578 27.5253C-0.105731 27.225 -0.0052578 26.8747 0.095215 26.5744L15.0657 0.800533C15.4173 0.199977 16.1709 -0.000208169 16.7737 0.350116L23.6561 4.30378C23.9575 4.45392 24.1585 4.75419 24.2589 5.05447C24.3594 5.35475 24.2589 5.70507 24.1585 6.00535L9.18801 31.7292C8.93682 32.1295 8.53493 32.3297 8.0828 32.3297L8.13304 32.3798ZM2.95869 26.7245L7.68091 29.427L21.3452 5.85521L16.623 3.15271L2.95869 26.7245Z"
          fill="#fff"
        />
        <path
          d="M76.9125 32.1786C76.4604 32.1786 76.0585 31.9784 75.8073 31.578L60.8871 5.80418C60.7364 5.5039 60.6862 5.15358 60.7866 4.8533C60.8871 4.55302 61.0881 4.25274 61.3895 4.1026L68.2216 0.148944C68.8245 -0.20138 69.578 -0.00119469 69.9297 0.599361L84.8499 26.3232C85.0006 26.6235 85.0508 26.9738 84.9504 27.2741C84.8499 27.5743 84.6489 27.8746 84.3475 28.0248L77.4651 31.9784C77.4651 31.9784 77.0632 32.1286 76.8623 32.1286L76.9125 32.1786ZM63.7004 5.65404L77.3647 29.2259L82.0869 26.5234L68.3723 2.95154L63.6501 5.65404H63.7004Z"
          fill="#fff"
        />
        <path
          d="M18.6322 40.6865C17.5773 40.6865 16.5223 40.3361 15.7185 39.6355C13.7091 38.034 13.4076 35.0813 15.0152 33.0794L16.1706 31.6781C16.9744 30.7272 18.0796 30.1267 19.3355 29.9766C20.5412 29.8264 21.7971 30.2268 22.7516 30.9775C24.7611 32.579 25.0625 35.5317 23.4549 37.5335L22.2995 38.9348C21.4957 39.8857 20.3905 40.4863 19.1346 40.6364C18.9839 40.6364 18.7829 40.6364 18.6322 40.6364V40.6865ZM17.2758 37.6837C17.728 38.034 18.2806 38.2342 18.8834 38.1341C19.436 38.0841 19.9886 37.7838 20.3403 37.3334L21.4957 35.9321C22.2492 35.0312 22.0985 33.6299 21.1943 32.8792C20.7421 32.5289 20.1896 32.3287 19.5867 32.4288C19.0341 32.4789 18.4815 32.7791 18.1299 33.2296L16.9744 34.6309C16.2209 35.5317 16.3716 36.933 17.2758 37.6837Z"
          fill="#fff"
        />
        <path
          d="M23.8094 45.1927C22.7544 45.1927 21.7497 44.8424 20.8956 44.1418C19.9412 43.341 19.3383 42.24 19.1876 40.9888C19.0369 39.7377 19.4388 38.5366 20.1923 37.5857L25.1657 31.48C25.9695 30.5292 27.0747 29.9286 28.3306 29.7785C29.5865 29.6784 30.7922 30.0287 31.7467 30.7794C33.7562 32.3809 34.0576 35.3336 32.45 37.3355L27.4766 43.4411C26.5724 44.5922 25.216 45.1427 23.8596 45.1427L23.8094 45.1927ZM28.7828 32.2808C28.7828 32.2808 28.6321 32.2808 28.5316 32.2808C27.979 32.3308 27.4264 32.6311 27.0747 33.0815L22.1013 39.1872C21.7497 39.6376 21.5487 40.1881 21.6492 40.7887C21.6994 41.3392 22.0008 41.8897 22.453 42.24C23.3572 42.9907 24.7638 42.8406 25.5174 41.9397L30.4908 35.8341C30.8425 35.3837 31.0434 34.8332 30.9429 34.2326C30.8927 33.6821 30.5913 33.1316 30.1391 32.7813C29.7373 32.481 29.2851 32.2808 28.7828 32.2808Z"
          fill="#fff"
        />
        <path
          d="M28.8406 49.8471C27.7856 49.8471 26.7307 49.4967 25.9269 48.7961C24.9724 47.9953 24.3696 46.8943 24.2189 45.6432C24.0681 44.392 24.47 43.1909 25.2236 42.24L29.2927 37.2354C30.9003 35.2335 33.8642 34.9333 35.8737 36.5347C37.8832 38.1362 38.1846 41.089 36.577 43.0908L32.5079 48.0954C31.7041 49.0463 30.5989 49.6469 29.343 49.797C29.1923 49.797 28.9913 49.797 28.8406 49.797V49.8471ZM32.9098 38.0361C32.2567 38.0361 31.6538 38.2864 31.2017 38.8369L27.1326 43.8415C26.7809 44.2919 26.58 44.8424 26.6804 45.443C26.7307 45.9935 27.0321 46.544 27.4842 46.8943C28.4387 47.645 29.7951 47.4949 30.5486 46.5941L34.6178 41.5894C35.3713 40.6886 35.2206 39.2873 34.3164 38.5366C33.9145 38.2363 33.4121 38.0361 32.96 38.0361H32.9098Z"
          fill="#fff"
        />
        <path
          d="M34.2625 54.0001C33.2075 54.0001 32.1525 53.6498 31.3488 52.9491C30.3943 52.1484 29.7914 51.0474 29.6407 49.7962C29.49 48.545 29.8919 47.3439 30.6455 46.393L33.4587 42.9398C34.2625 41.989 35.3677 41.3884 36.6236 41.2383C37.8795 41.0881 39.0852 41.4885 40.0397 42.2392C42.0491 43.8407 42.3505 46.7934 40.743 48.7953L37.9297 52.2485C37.126 53.1993 36.0208 53.7999 34.7648 53.95C34.6141 53.95 34.4132 53.95 34.2625 53.95V54.0001ZM37.0757 43.7406C37.0757 43.7406 36.925 43.7406 36.8245 43.7406C36.2719 43.7906 35.7193 44.0909 35.3677 44.5413L32.5544 47.9945C32.2028 48.4449 32.0018 48.9955 32.1023 49.596C32.1526 50.1465 32.454 50.697 32.9061 51.0474C33.3582 51.3977 33.9108 51.5979 34.5137 51.4978C35.0663 51.4477 35.6189 51.1474 35.9705 50.697L38.7838 47.2438C39.5373 46.343 39.3866 44.9417 38.4823 44.191C38.0804 43.8907 37.6283 43.6905 37.126 43.6905L37.0757 43.7406Z"
          fill="#fff"
        />
        <path
          d="M46.4154 53.1492C43.2002 53.1492 39.9349 51.8479 37.8752 50.6468C37.2723 50.2965 37.0714 49.5458 37.423 48.9453C37.7747 48.3447 38.5282 48.1445 39.1311 48.4948C39.4827 48.695 48.1234 53.6496 51.5897 47.8943C51.9414 47.2937 52.6949 47.0935 53.2977 47.4439C53.9006 47.7942 54.1015 48.5449 53.7499 49.1454C51.9916 52.0982 49.2286 53.0991 46.4154 53.0991V53.1492Z"
          fill="#fff"
        />
        <path
          d="M54.4601 49.997C50.9436 49.997 45.2166 46.5939 42.6043 44.9424C42.0015 44.592 41.8508 43.7913 42.2025 43.2408C42.5541 42.6402 43.3579 42.4901 43.9105 42.8404C48.683 45.8932 53.8071 48.0452 54.8118 47.4447C56.771 46.2936 56.771 45.7932 56.771 44.1917C56.771 43.491 57.3236 42.9405 58.0269 42.9405C58.7302 42.9405 59.2828 43.491 59.2828 44.1917C59.2828 46.3437 59.0819 47.8451 56.0677 49.5967C55.6156 49.8469 55.063 49.997 54.4099 49.997H54.4601Z"
          fill="#fff"
        />
        <path
          d="M16.83 34.1328C16.4783 34.1328 16.0765 33.9827 15.8253 33.6824L10.1988 26.6759C9.74667 26.1254 9.84714 25.3747 10.3997 24.9243C10.9523 24.4739 11.7059 24.574 12.158 25.1245L17.7845 32.131C18.2366 32.6815 18.1362 33.4322 17.5836 33.8826C17.3324 34.0828 17.0812 34.1328 16.7798 34.1328H16.83Z"
          fill="#fff"
        />
        <path
          d="M59.0287 45.1421C54.0051 45.1421 47.0222 39.1866 46.1682 38.4359C45.6658 37.9854 45.6156 37.1847 46.0677 36.6842C46.5199 36.1838 47.3237 36.1337 47.826 36.5841C51.3928 39.687 57.5719 43.5906 60.0335 42.4896C61.1889 41.9891 61.4401 40.1374 61.4401 38.636C61.4401 37.9354 61.9927 37.3849 62.696 37.3849C63.3993 37.3849 63.9519 37.9354 63.9519 38.636C63.9519 41.839 62.9974 43.8909 61.0884 44.7417C60.4856 45.042 59.7823 45.1421 59.079 45.1421H59.0287Z"
          fill="#fff"
        />
        <path
          d="M65.3572 40.4373C60.5345 40.4373 52.3459 34.2816 49.5829 32.0796C49.0303 31.6292 48.9801 30.8785 49.382 30.328C49.8341 29.7775 50.5877 29.6774 51.1403 30.1278C57.0682 34.8822 64.7041 39.1862 66.1107 37.7849C66.1107 37.7849 66.161 37.7348 66.2112 37.6848C66.2112 37.6848 67.5173 36.5838 67.4169 35.1324C67.3164 33.7812 65.96 32.2798 63.5487 30.8785C56.3649 26.7747 45.1119 19.3178 42.7006 17.7163L40.038 18.4169C39.1338 19.6681 36.0694 23.2714 30.9955 24.1222C29.7898 24.3224 28.5841 23.872 27.7803 22.9211C26.9766 21.9702 26.7756 20.7191 27.1775 19.568C28.0818 17.0156 30.041 13.062 34.4618 8.65791C34.7632 8.40768 40.0883 3.90351 53.8028 7.10647C55.0085 7.35671 56.1137 7.65698 57.0682 7.90722C59.8814 8.65791 61.4387 9.10833 63.5487 7.90722C64.1515 7.55689 64.905 7.75708 65.2567 8.35763C65.6084 8.95819 65.4074 9.70888 64.8046 10.0592C61.7904 11.8108 59.379 11.1602 56.4151 10.3595C55.5108 10.1093 54.4559 9.80898 53.3004 9.60879C40.9925 6.75615 36.2201 10.5096 36.2201 10.5597C32.2514 14.5133 30.4429 18.1167 29.6391 20.4688C29.4884 20.9193 29.6893 21.2696 29.7898 21.4197C29.8903 21.5198 30.1917 21.8201 30.6438 21.72C35.567 20.9193 38.2295 16.7654 38.2798 16.7154C38.4305 16.4651 38.7319 16.265 39.0333 16.1649L42.7006 15.214C43.0522 15.1139 43.4039 15.214 43.7053 15.3641C43.856 15.4642 56.9175 24.1723 64.905 28.7265C68.1202 30.5782 69.8785 32.6802 70.0292 34.9322C70.2301 37.4846 68.3211 39.2362 67.9695 39.5365C67.3164 40.1371 66.4624 40.3873 65.4576 40.3873L65.3572 40.4373Z"
          fill="#fff"
        />
        <path
          d="M67.6689 33.8814C67.2167 33.8814 66.8148 33.6813 66.5637 33.2809C66.212 32.6803 66.413 31.9296 67.0158 31.5793C67.4177 31.3291 71.4868 28.1762 73.9484 26.2744C74.501 25.874 75.3048 25.9241 75.7067 26.4746C76.1086 27.0251 76.0583 27.8258 75.5057 28.2262C72.4413 30.6284 68.6736 33.4811 68.2717 33.7313C68.0708 33.8314 67.8698 33.8814 67.6689 33.8814Z"
          fill="#fff"
        />
        <path
          d="M32.1475 13.4627C26.8224 13.4627 20.995 9.30889 20.7438 9.1087C20.1912 8.70833 20.0405 7.90759 20.4926 7.35708C20.8945 6.80657 21.6983 6.65643 22.2509 7.10685C22.3011 7.10685 28.2793 11.4108 32.901 10.9604C33.6043 10.9104 34.2072 11.4108 34.2574 12.0614C34.3076 12.7621 33.8053 13.3626 33.1522 13.4127C32.8508 13.4127 32.4991 13.4127 32.1977 13.4127L32.1475 13.4627Z"
          fill="#fff"
        />
      </g>
      {/* <defs>
          <clipPath id="clip0_394_60735">
            <rect width="85" height="54" fill="white" />
          </clipPath>
        </defs> */}
    </svg>
  );
}

export default TotalRenewedCustomer;
