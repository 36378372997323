import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

export const useStylesAuth = makeStyles((theme: Theme) =>
  createStyles({
    auth__title: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      marginBottom: 4,
      fontWeight: theme.typography.subtitle1.fontWeight,
    },
    auth__subtitle: {
      color: `${theme.palette.success.contrastText} !important`,
      fontSize: theme.typography.h4.fontSize,
      fontWeight: theme.typography.h4.fontWeight,
      textDecoration: "underline",
    },
    auth__error__helper: {
      color: `${theme.palette.error.main}`,
      fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    },
    auth__text__link: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.subtitle2.fontSize,
    },
    auth__button__submit: {
      backgroundColor: theme.palette.primary.main,
      padding: "10px 34px !important",
      marginTop: "8px !important",
    },
    auth__logo: {
      animation: ` $showing 450ms linear, $myEffect 5000ms infinite `,
    },

    "@keyframes showing": {
      "0%": {
        opacity: 0,
        transform: "translate3d(0 , 200px , 0)",
      },

      "100%": {
        opacity: 1,
        transform: "translate3d(0 , 0 , 0)",
      },
    },

    "@keyframes myEffect": {
      "0%": {
        filter: "drop-shadow( 0 0 48px rgba(199, 137, 62, 1))",
      },
      "25%": {
        filter: "drop-shadow( 0 0 52px rgba(199, 137, 62, 1))",
      },
      "50%": {
        filter: "drop-shadow( 0 0 55px rgba(199, 137, 62, 1))",
      },
      "75%": {
        filter: "drop-shadow( 0 0 50px rgba(199, 137, 62, 1))",
      },
      "100%": {
        filter: "drop-shadow( 0 0 45px rgba(199, 137, 62, 1))",
      },
    },
  })
);

export const useStylesForgetPassword = makeStyles((theme: Theme) =>
  createStyles({
    foget__box: {
      display: "flex",
      height: "100vh",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      gap: 24,
    },
    forget__form: {
      display: "flex",
      width: 310,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 30,
    },
    forget__btn_reset_password: {
      padding: "10px 34px !important",
      marginTop: "30px !important",
    },
    forget__go__back__login: {
      color: theme.palette.primary.main,
      fontSize: "12px",
      marginTop: "15px",
    },
  })
);

export const Container = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,
  height: "100vh",
}));

export const RightContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  width: "50%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px 0",
  boxSizing: "border-box",
  gap: "50px",
}));

export const ForgetPassword = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "25px",
}));
