import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
//material-ui
import {
  IconButton,
  Menu,
  MenuItem,
  Button,
  Typography,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//components
import Table from "../../layout/commun/Table";
import Status from "../../Status/Status";
import StatsCard from "../../reusable/Cards/StatsCard";
import FilterHeader from "../../pageHeader/FilterHeader";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
//utils
import { columns, convertNumberOfDaysEnum, handleStatus } from "../Utils";
import { SIDE_FILTER_OPTIONS } from "../../pageHeader/Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//mutations&queries
import {
  CustomerProfilePayload,
  useGetAllCustomersProfileV2Query,
  FilterCustomer,
  NumberOfDays,
  useGetCustomerStatsQuery,
  PlanCondition,
  useGetAllCustomersProfileV2LazyQuery,
} from "../../../graphql/types";

const ListCustomers = () => {
  const navigate = useNavigate();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");
  //styles
  const classes = useStylesMenu();

  //states
  const [filter, setFilter] = useState<FilterCustomer[]>([]);
  const [idsProgram, setIdsProgram] = useState<string[]>([]);
  const [duration, setDuration] = useState<string[]>([]);
  const [numberOfDays, setNumberOfDays] = useState<NumberOfDays[]>([]);
  const [numberOfMeals, setNumberOfMeals] = useState<number[]>([]);
  const [planStatus, setPlanStatus] = useState<PlanCondition[]>([]);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<CustomerProfilePayload | null>(null);
  const [search, setSearch] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(30);
  const [influencer, setInfluencer] = useState<boolean | null>(null);
  const [filteredProgramsList, setFilteredProgramsList] = useState<any>([]);

  // Function to receive the filtered programs list from FilterHeader
  const handleFilteredProgramsList = (programsList: any[]) => {
    setFilteredProgramsList(programsList);
  };

  //muations
  const dataGetCustomers = useGetAllCustomersProfileV2Query({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        nameCustomer: search,
        sort: null,
        filters: filter,
        duration: duration,
        nbrOfDays: numberOfDays,
        meals: numberOfMeals,
        program: idsProgram,
        from: from,
        to: to,
        planCondition: planStatus,
        influencer: influencer,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
    fetchPolicy: "network-only",
  });

  const customers = useMemo(
    () => dataGetCustomers?.data?.getAllCustomersProfileV2.data || [],
    [dataGetCustomers?.data]
  );

  const count = useMemo(
    () => dataGetCustomers.data?.getAllCustomersProfileV2.count || 0,
    [dataGetCustomers.data]
  );

  const [getCustomersData, { loading: CustomersListLoading }] =
    useGetAllCustomersProfileV2LazyQuery();

  const handleExportExcel = async () => {
    await getCustomersData({
      variables: {
        input: {
          page: 1,
          documentsPerPage: count,
          filters: filter,
          duration: duration,
          nbrOfDays: numberOfDays,
          meals: numberOfMeals,
          program: idsProgram,
          from: from,
          to: to,
          planCondition: planStatus,
        },
      },
    })
      .then((data) => {
        if (count != 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const fileExtension = ".xlsx";
          const filteredCustomers =
            data?.data?.getAllCustomersProfileV2?.data?.map((el, index) => {
              return {
                id: (index + 1).toString(),
                name: el.fname + " " + el.lname,
                phone: el.phone?.prefix + " " + el.phone?.number,
                details:
                  el?.plan?.program?.name?.EN +
                  "/" +
                  el?.plan?.meals?.length +
                  " meals",
                assignedTo:
                  (el?.userAssignedTo?.fname ?? "----") +
                  " " +
                  (el?.userAssignedTo?.lname ?? "----"),
                duration:
                  el?.plan?.paymentPeriod?.toLowerCase().replace(/_/g, " ") +
                  "/" +
                  convertNumberOfDaysEnum(el?.plan?.nbrOfDays as NumberOfDays),
                NumberOfBoxes: el?.nbrOfBoxesConsumed + "/" + el?.nbrOfBoxes,
                startDateEndDate:
                  "From : " +
                  moment(el?.plan?.startDate).format("DD/MM/YYYY") +
                  " , " +
                  "To : " +
                  moment(el?.plan?.expiryDate).format("DD/MM/YYYY"),
              };
            });
          const ws = XLSX.utils.json_to_sheet(filteredCustomers || []);
          ws["!cols"] = [
            { wpx: 40 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 70 },
            { wpx: 250 },
          ];
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const res = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            res,
            "customers report" +
              " generated on " +
              moment().format("DD/MM/YYYY dddd LT") +
              fileExtension
          );
          return "Done";
        }
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };
  const customerStatsData = useGetCustomerStatsQuery({
    fetchPolicy: "network-only",
  });

  const customerStats = useMemo(
    () => customerStatsData.data?.getCustomerStats.activePrograms || [],
    [customerStatsData.data]
  );

  //functions
  const handleClickMenu =
    (item: CustomerProfilePayload) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDetails = () => {
    setAnchorEl(null);
    if (selected) {
      navigate(`/admin/customers/view_customer/${selected._id}`);
    }
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (selected) {
      navigate(`/admin/customers/update_customer/${selected._id}`);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  //rows
  const renderTableRows = () => {
    return (
      customers?.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          program__name: el?.plan?.program?.name?.EN || "--",
          customer__status: el?.plan?.planCondition ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Status
                status={el?.plan?.planCondition}
                statusText={handleStatus(el?.plan?.planCondition)}
              />
            </Box>
          ) : (
            "--"
          ),
          start__date: moment(el?.plan?.startDate).isValid() ? (
            <>
              <Typography style={{ fontSize: 12 }}>
                {"From : " + moment(el?.plan?.startDate).format("DD/MM/YYYY")}
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                {"To : " + moment(el?.plan?.expiryDate).format("DD/MM/YYYY")}
              </Typography>
            </>
          ) : (
            "--"
          ),
          otp__number: (el?.otp || 0) > 0 ? el?.otp : "--",
          id: el?.ref,
          name: isAuthorizedToSeeProfile ? (
            <a
              style={{ textDecoration: "none" }}
              href={`/admin/customers/view_customer/${el?._id}`}
            >
              <Button>{`${el?.fname} ${el?.lname}`}</Button>
            </a>
          ) : (
            el.fname + " " + el.lname
          ),
          actions: (
            <IconButton onClick={handleClickMenu(el)}>
              <MoreVertIcon />
            </IconButton>
          ),

          details:
            el?.plan?.program?.name?.EN +
            "/" +
            el?.plan?.meals?.length +
            " meals",
          assignedTo:
            (el?.userAssignedTo?.fname ?? "----") +
            " " +
            (el?.userAssignedTo?.lname ?? "----"),

          duration:
            el?.plan?.paymentPeriod?.toLowerCase().replace(/_/g, " ") +
            "/" +
            convertNumberOfDaysEnum(el?.plan?.nbrOfDays as NumberOfDays),
          NumberOfBoxes: el?.nbrOfBoxesConsumed + "/" + el?.nbrOfBoxes,
          mobile: el?.phone?.prefix + " " + el?.phone?.number,
        };
      }) || []
    );
  };

  return (
    <>
      <FilterHeader
        buttonLink={
          useIsAuthorized("ADMIN_CUSTOMERS_CREATE")
            ? "/admin/customers/add_customer"
            : undefined
        }
        buttonText={
          useIsAuthorized("ADMIN_CUSTOMERS_CREATE") ? "Create" : undefined
        }
        setSearchValue={setSearch}
        showSearch={true}
        pageInfo="This is a page displaying the list of customers"
        setFilter={setFilter}
        filterData={filter}
        setDuration={setDuration}
        durationData={duration}
        setNumberOfDays={setNumberOfDays}
        daysData={numberOfDays}
        setNumberOfMeals={setNumberOfMeals}
        mealsData={numberOfMeals}
        setIdsProgram={setIdsProgram}
        programIdData={idsProgram}
        setPlanStatus={setPlanStatus}
        planStatus={planStatus}
        setFrom={setFrom}
        from={from}
        setTo={setTo}
        to={to}
        influencer={influencer || false}
        setInfluencer={setInfluencer}
        filterConfig={SIDE_FILTER_OPTIONS}
        loadExcel={CustomersListLoading}
        generateExcel={handleExportExcel}
        onFilteredProgramsList={handleFilteredProgramsList}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        marginTop="70px"
        marginBottom="50px"
        width="100%"
        flexWrap="wrap"
      >
        <StatsCard
          title={"Total No of  Customers"}
          startDate={new Date()}
          endDate={new Date()}
          number={count || 0}
          total={true}
          filteredProgramsList={filteredProgramsList}
          styles={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            width: 275,
            borderRadius: 5,
            height: 160,
            marginBottom: "15px",
          }}
        />
        {customerStats.map((elem, index) => {
          return (
            <StatsCard
              key={elem.program}
              title={elem.program || ""}
              startDate={new Date()}
              endDate={new Date()}
              number={elem.count || 0}
              description={elem.percentage || 0}
              up={(elem?.percentage || 0) > 0}
              styles={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                width: 275,
                borderRadius: 5,
                height: 160,
                marginBottom: "15px",
              }}
            />
          );
        })}
      </Box>
      <TableContainer>
        <Table
          columns={columns}
          data={renderTableRows()}
          loading={dataGetCustomers.loading}
          numberOfFakeRow={30}
          emptyMessage="No Customers found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {isAuthorizedToSeeProfile && (
            <MenuItem
              onClick={handleCloseDetails}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Details</span>
              </Box>
            </MenuItem>
          )}

          {useIsAuthorized("UPDATE_CUSTOMER") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>
    </>
  );
};

export default ListCustomers;
