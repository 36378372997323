import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CountUp from "react-countup";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
//material ui
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
//icons
import ExcelIcon from "../../../public/icons/ExcelIcon";
//style
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
//components
import Table from "../../layout/commun/Table";
import FilterHeader from "../../pageHeader/FilterHeader";
import Status from "../../Status/Status";
import ProgressCircleStatsCard from "../../reusable/Cards/ProgressCircleStatsCard";
//mutations&querries
import {
  NumberOfDays,
  PlanCondition,
  useGetCustomerStatsQuery,
  useGetCustomerStatsReportLazyQuery,
  useGetTotalCustomerLazyQuery,
  useGetTotalCustomerQuery,
} from "../../../graphql/types";
//utils
import {
  columns,
  percentageCalculator,
  SIDE_FILTER_OPTIONS_TOTAL_CUSTOMERS,
} from "./Utils";
import { convertNumberOfDaysEnum, handleStatus } from "../../customer/Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//context
import { useAuth } from "../../../contextAPI";

function TotalCustomers() {
  const navigate = useNavigate();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");
  const isAuthorizedToGenerateReport = useIsAuthorized(
    "DASHBOARD_TOTAL_CUSTOMER_REPORT"
  );
  let auth = useAuth();

  //styles
  const ButtonClasses = useStylesButtons();
  const DialogClasses = useStylesDialog();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [idsProgram, setIdsProgram] = useState<string[]>([]);
  const [duration, setDuration] = useState<string[]>([]);
  const [numberOfDays, setNumberOfDays] = useState<NumberOfDays[]>([]);
  const [numberOfMeals, setNumberOfMeals] = useState<number[]>([]);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [planStatus, setPlanStatus] = useState<PlanCondition[]>([]);
  const [influencer, setInfluencer] = useState<boolean | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [statsFrom, setStatsFrom] = useState<Date | null>(null);
  const [statsTo, setStatsTo] = useState<Date | null>(null);

  //queries&mutations
  const dataGetCustomers = useGetTotalCustomerQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        filters: [],
        duration: duration,
        nbrOfDays: numberOfDays,
        meals: numberOfMeals,
        program: idsProgram,
        planCondition: planStatus,
        from: from,
        to: to,
        influencer: influencer,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
    fetchPolicy: "network-only",
  });

  const totalCustomers = useMemo(
    () => dataGetCustomers.data?.getTotalCustomer.data || [],
    [dataGetCustomers.data]
  );

  const count = useMemo(
    () => dataGetCustomers.data?.getTotalCustomer.count || 0,
    [dataGetCustomers.data]
  );

  const customerStatsData = useGetCustomerStatsQuery({
    fetchPolicy: "network-only",
  });

  const customerStats = useMemo(
    () => customerStatsData.data?.getCustomerStats.allPrograms || [],
    [customerStatsData.data]
  );

  const [getCustomerStatsReportData, { loading: customerStatsReportLoading }] =
    useGetCustomerStatsReportLazyQuery();

  const [getAllCustomersData, { loading: allCustomersListLoading }] =
    useGetTotalCustomerLazyQuery();

  const handleExportExcel = async () => {
    await getAllCustomersData({
      variables: {
        input: {
          page: 1,
          documentsPerPage: count,
          duration: duration,
          nbrOfDays: numberOfDays,
          meals: numberOfMeals,
          program: idsProgram,
          planCondition: planStatus,
          from: from,
          to: to,
        },
      },
    })
      .then((data) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const fileExtension = ".xlsx";
        const filteredCustomers = data?.data?.getTotalCustomer?.data?.map(
          (el, index) => {
            return {
              id: (index + 1).toString(),
              name: el.fname + " " + el.lname,
              gender: el.gender,
              age: moment().diff(el.dob, "years").toString(),
              phone: el.phone?.prefix + " " + el.phone?.number,
              email: el.email ? el.email : "null@null.com",
              StartdateEndDate:
                "From : " +
                moment(el?.plan?.startDate).format("DD/MM/YYYY") +
                " , " +
                "To : " +
                moment(el?.plan?.expiryDate).format("DD/MM/YYYY"),
              price: auth.countryCurrency + " " + el?.plan?.price?.toFixed(0),
              status: el?.plan?.planCondition,
              plan:
                el.plan?.program?.name?.EN +
                " , " +
                convertNumberOfDaysEnum(el?.plan?.nbrOfDays || "FIVE_DAYS") +
                " , " +
                el?.plan?.meals?.length +
                " meals " +
                " , " +
                el?.plan?.paymentPeriod,
            };
          }
        );
        const ws = XLSX.utils.json_to_sheet(filteredCustomers || []);
        ws["!cols"] = [
          { wpx: 40 },
          { wpx: 150 },
          { wpx: 70 },
          { wpx: 50 },
          { wpx: 100 },
          { wpx: 200 },
          { wpx: 200 },
          { wpx: 70 },
          { wpx: 130 },
          { wpx: 250 },
        ];
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        const res = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(
          res,
          "total customers report" +
            " generated on " +
            moment().format("DD/MM/YYYY dddd LT") +
            fileExtension
        );
        return "Done";
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };

  const exportStatsExcelReport = async () => {
    await getCustomerStatsReportData({
      variables: {
        input: {
          from: statsFrom,
          to: statsTo,
        },
      },
    })
      .then((data) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const fileExtension = ".xlsx";
        const filteredStats = data?.data?.getCustomerStatsReport?.map(
          (el, index) => {
            return {
              Date: moment(el.date).format("YYYY-MM-DD"),
              Total_Customers: el.totalCustomer,
              Total_Active_Customers: el.totalActiveCustomer,
              Total_Inactive_Customers:
                (el.totalCustomer || 0) - (el.totalActiveCustomer || 0),
              Total_Freezed_Today: el.totalFreezedToday,
              Total_Today_Deliveries: el.totalTodaydeliveries,
              Total_Active_Influencers: el.totalActiveInfluencers,
              Total_Without_Influencers:
                (el.totalActiveCustomer || 0) -
                (el.totalActiveInfluencers || 0),
              New_signed_Up: el.newSignedUp,
            };
          }
        );
        const ws = XLSX.utils.json_to_sheet(filteredStats || []);
        ws["!cols"] = [
          { wpx: 60 },
          { wpx: 90 },
          { wpx: 120 },
          { wpx: 130 },
          { wpx: 120 },
          { wpx: 120 },
          { wpx: 120 },
          { wpx: 140 },
          { wpx: 80 },
        ];
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        const res = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(
          res,
          "Stats report" +
            " generated on " +
            moment().format("DD/MM/YYYY dddd LT") +
            fileExtension
        );
        return "Done";
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setStatsFrom(null);
    setStatsTo(null);
  };

  const renderTableRows = () => {
    return totalCustomers.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        id: item?.ref,
        name: isAuthorizedToSeeProfile ? (
          <a
            style={{ textDecoration: "none" }}
            href={`/admin/customers/view_customer/${item?._id}`}
          >
            <Button>{`${item?.fname} ${item?.lname}`}</Button>
          </a>
        ) : (
          item.fname + " " + item.lname
        ),
        mobile: item?.phone?.prefix + " " + item?.phone?.number,
        email: item?.email,
        plan: (
          <>
            <Typography style={{ fontSize: 12 }}>
              {item.plan?.program?.name?.EN +
                ", " +
                convertNumberOfDaysEnum(item?.plan?.nbrOfDays || "FIVE_DAYS")}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {item?.plan?.meals?.length +
                " meals, " +
                item?.plan?.paymentPeriod}
            </Typography>
          </>
        ),
        price: auth.countryCurrency + " " + item?.plan?.price?.toFixed(0),
        promoCode: item?.plan?.promoCode || "---",
        actions: isAuthorizedToSeeProfile && (
          <Button
            variant="contained"
            size="medium"
            className={ButtonClasses.button_cancel_form}
            onClick={() =>
              navigate(`/admin/customers/view_customer/${item?._id}`)
            }
          >
            Profile
          </Button>
        ),
        date: moment(item?.plan?.startDate).isValid() ? (
          <>
            <Typography style={{ fontSize: 12 }}>
              {"From : " + moment(item?.plan?.startDate).format("DD/MM/YYYY")}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {"To : " + moment(item?.plan?.expiryDate).format("DD/MM/YYYY")}
            </Typography>
          </>
        ) : (
          "--"
        ),
        status: item?.plan?.planCondition ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Status
              status={item?.plan?.planCondition}
              statusText={handleStatus(item?.plan?.planCondition)}
            />
          </Box>
        ) : (
          "--"
        ),
      };
    });
  };

  return (
    <>
      <Box marginBottom={5}>
        <FilterHeader
          count={count}
          showSearch={false}
          pageTitle="Total Customers"
          pageInfo="This is the list of all customers"
          setDuration={setDuration}
          durationData={duration}
          setNumberOfDays={setNumberOfDays}
          daysData={numberOfDays}
          setNumberOfMeals={setNumberOfMeals}
          mealsData={numberOfMeals}
          setIdsProgram={setIdsProgram}
          programIdData={idsProgram}
          setPlanStatus={setPlanStatus}
          planStatus={planStatus}
          setFrom={setFrom}
          from={from}
          setTo={setTo}
          to={to}
          influencer={influencer || false}
          setInfluencer={setInfluencer}
          filterConfig={SIDE_FILTER_OPTIONS_TOTAL_CUSTOMERS}
          loadExcel={allCustomersListLoading}
          generateExcel={handleExportExcel}
        />
      </Box>

      {/* STATS CARDS */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginBottom={5}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          fontFamily="Poppins"
          fontWeight="400"
          fontSize={18}
          height="80px"
          width="120px"
          marginRight={2}
          borderRadius={2.5}
          boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
          textAlign="center"
          onClick={() => setOpen(true)}
          sx={{
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#C4C4C4",
              transition: "0.3s",
            },
          }}
        >
          Total
          <Typography variant="h2" fontSize={24} fontWeight="600">
            <CountUp
              end={
                customerStatsData.data?.getCustomerStats.totalActiveCustomer ||
                0
              }
              duration={0.7}
            />
          </Typography>
        </Box>
        {customerStats
          .filter((element) => element.count != 0)
          .map((el) => {
            return (
              <Box
                key={el.program}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                fontFamily="Poppins"
                fontWeight="400"
                fontSize={18}
                height="80px"
                width="120px"
                marginRight={2}
                borderRadius={2.5}
                boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
                textAlign="center"
              >
                {el.program}
                <Typography variant="h2" fontSize={24} fontWeight="600">
                  <CountUp end={el.count || 0} duration={0.7} />
                </Typography>
              </Box>
            );
          })}
      </Box>

      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetCustomers.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Customers found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>

      {/* STATS MODAL */}
      <Dialog
        open={open}
        onClose={handleCloseModal}
        className={DialogClasses.dialog_stats_container}
      >
        <DialogContent>
          {/* FIRST ROW STATS */}
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr">
            <ProgressCircleStatsCard
              title="Total Customers"
              centralColor="#D9D7FE"
              progressColor="#4339F2"
              number={
                customerStatsData.data?.getCustomerStats.totalCustomer || 0
              }
              percentage={100}
            />
            <ProgressCircleStatsCard
              title="Total Active"
              centralColor="#E1FBD6"
              progressColor="#54B435"
              number={
                customerStatsData.data?.getCustomerStats.totalActiveCustomer ||
                0
              }
              percentage={Math.round(
                percentageCalculator(
                  customerStatsData.data?.getCustomerStats
                    .totalActiveCustomer || 0,
                  customerStatsData.data?.getCustomerStats.totalCustomer || 1
                )
              )}
            />
            <ProgressCircleStatsCard
              title="Total Inactive"
              centralColor="#dadada"
              progressColor="#a7a7a7"
              number={
                (customerStatsData.data?.getCustomerStats.totalCustomer || 0) -
                  (customerStatsData.data?.getCustomerStats
                    .totalActiveCustomer || 0) || 0
              }
              percentage={Math.round(
                percentageCalculator(
                  (customerStatsData.data?.getCustomerStats.totalCustomer ||
                    0) -
                    (customerStatsData.data?.getCustomerStats
                      .totalActiveCustomer || 0),
                  customerStatsData.data?.getCustomerStats.totalCustomer || 1
                )
              )}
            />
            <ProgressCircleStatsCard
              title="Total Freezed Today"
              centralColor="#ace1d0"
              progressColor="#43bbc3"
              number={
                customerStatsData.data?.getCustomerStats.totalFreezedToday || 0
              }
              percentage={Math.round(
                percentageCalculator(
                  customerStatsData.data?.getCustomerStats.totalFreezedToday ||
                    0,
                  customerStatsData.data?.getCustomerStats
                    .totalActiveCustomer || 1
                )
              )}
            />
          </Box>

          {/* SECOND ROW STATS */}
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            marginTop={5}
          >
            <ProgressCircleStatsCard
              title="Total Influencers"
              centralColor="#f9cb9c"
              progressColor="#f6977a"
              number={
                customerStatsData.data?.getCustomerStats
                  .totalActiveInfluencers || 0
              }
              percentage={Math.round(
                percentageCalculator(
                  customerStatsData.data?.getCustomerStats
                    .totalActiveInfluencers || 0,
                  customerStatsData.data?.getCustomerStats
                    .totalActiveCustomer || 1
                )
              )}
            />
            <ProgressCircleStatsCard
              title="Total Without Influencers"
              centralColor="#ffe9a8"
              progressColor="#ffb806"
              number={
                (customerStatsData.data?.getCustomerStats.totalActiveCustomer ||
                  0) -
                (customerStatsData.data?.getCustomerStats
                  .totalActiveInfluencers || 0)
              }
              percentage={Math.round(
                percentageCalculator(
                  (customerStatsData.data?.getCustomerStats
                    .totalActiveCustomer || 0) -
                    (customerStatsData.data?.getCustomerStats
                      .totalActiveInfluencers || 0),
                  customerStatsData.data?.getCustomerStats
                    .totalActiveCustomer || 1
                )
              )}
            />
            <ProgressCircleStatsCard
              title="Total Deliveries Today"
              centralColor="#d8be93"
              progressColor="#c7a164"
              number={
                customerStatsData.data?.getCustomerStats.totalTodaydeliveries ||
                0
              }
              percentage={Math.round(
                percentageCalculator(
                  customerStatsData.data?.getCustomerStats
                    .totalTodaydeliveries || 0,
                  customerStatsData.data?.getCustomerStats
                    .totalActiveCustomer || 1
                )
              )}
            />
            <ProgressCircleStatsCard
              title="New Customers Today"
              centralColor="#d199e8"
              progressColor="#4e2c5b"
              number={customerStatsData.data?.getCustomerStats.newSignedUp || 0}
              percentage={Math.round(
                percentageCalculator(
                  customerStatsData.data?.getCustomerStats.newSignedUp || 0,
                  customerStatsData.data?.getCustomerStats
                    .totalActiveCustomer || 1
                )
              )}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            marginTop={5}
          >
            {/* EXPORT EXCEL BUTTON */}
            {statsFrom && statsTo && isAuthorizedToGenerateReport && (
              <Button
                onClick={exportStatsExcelReport}
                className={ButtonClasses.button_export_excel}
                startIcon={<ExcelIcon />}
                disabled={customerStatsReportLoading}
              >
                Export Excel
                {customerStatsReportLoading ? (
                  <CircularProgress size={14} color="inherit" />
                ) : (
                  ""
                )}
              </Button>
            )}

            {/* FROM DATE PICKER */}
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography
                style={{ fontSize: 14, marginRight: 10 }}
                color="black"
              >
                From
              </Typography>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  value={statsFrom}
                  inputFormat="DD/MM/YYYY"
                  disablePast={false}
                  disableFuture={false}
                  onChange={(newValue) => {
                    if (setStatsFrom) {
                      setStatsFrom(newValue || new Date());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "70%" }} />
                  )}
                />
              </LocalizationProvider>
            </Box>

            {/* TO DATE PICKER */}
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography
                style={{ fontSize: 14, marginRight: 10 }}
                color="black"
              >
                To
              </Typography>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  value={statsTo}
                  inputFormat="DD/MM/YYYY"
                  disablePast={false}
                  disableFuture={false}
                  onChange={(newValue) => {
                    if (setStatsTo) {
                      setStatsTo(newValue || new Date());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "70%" }} />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TotalCustomers;
