// react
import { useState } from "react";
// components
import PageHeader from "../../../pageHeader/PageHeader";
import Table from "../../../layout/commun/Table";
// apolo graphql
import {
  GetAllAreasDocument,
  SortBy,
  useDeleteAreaMutation,
  useGetAllAreasQuery,
  useGetAreaByUserLocationLazyQuery,
  useUpdateAreaMutation,
} from "../../../../graphql/types";
// react router
import { useNavigate } from "react-router-dom";
// MUI
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
// icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
// style hooks
import { useSnackbar } from "notistack";
import { useStylesMenu } from "../../../../styles/Menu__styles";
import { useStylesDialog } from "../../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../../styles/Buttons__styles";
import { useStylesForms } from "../../../../styles/Forms__styles";
import { useStylesContainers } from "../../../../styles/Containers__styles";
import { TableContainer } from "../../../../styles/Table__styles";
//utils
import { useIsAuthorized } from "../../../../utils/Hooks/useIsAuthorized";
import { areaCols } from "../../Utils";
import { filterElements } from "../../../Utils";

const AreaList = () => {
  //            **** React Hooks ****
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [idArea, setIdArea] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortBy>("NAME_A_Z");
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [openGetArea, setOpenGetArea] = useState(false);
  const [latitude, setLatitude] = useState<number>(0);
  const [Longitude, setLongitude] = useState<number>(0);
  const [areaNameEN, setAreaNameEN] = useState<string | null>(null);
  const [areaNameAR, setAreaNameAR] = useState<string | null>(null);
  //           *** Custom style hooks ****
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();
  const FormsClasses = useStylesForms();
  const ContainersClasses = useStylesContainers();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //Query all data hook
  const { data: areasData, loading } = useGetAllAreasQuery({
    variables: {
      input: {
        page: page,
        documentPerPage: rowsPerPage,
        name: search,
        sortBy,
      },
    },
  });
  const count = areasData?.getAllAreas?.count[0]?.total || 0;

  const [getArea, { loading: loadingArea }] = useGetAreaByUserLocationLazyQuery(
    {
      variables: { customerInput: [latitude, Longitude] },
    }
  );

  const findArea = async () => {
    const result = await getArea();
    if (result.data) {
      setAreaNameEN(result.data.getAreaByUserLocation?.areaName?.EN || "");
      setAreaNameAR(result.data.getAreaByUserLocation?.areaName?.AR || "");
    }
  };

  // Update hook
  const [updateArea] = useUpdateAreaMutation({
    refetchQueries: [GetAllAreasDocument],
    onCompleted: () => {
      enqueueSnackbar("Status succuessfully Update", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    },
  });

  // delete hook
  const [deleteArea, { loading: isLoading }] = useDeleteAreaMutation({
    refetchQueries: [GetAllAreasDocument],
    onCompleted: () => {
      enqueueSnackbar("Area succuessfully deleted", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    },
  });

  //        **** Handlers ****

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setIdArea(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (idArea) {
      navigate(`/admin/area/addEditArea/${idArea}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenGetArea = () => {
    setOpenGetArea(true);
    setAreaNameEN("");
    setAreaNameAR("");
  };

  const handleCloseGetArea = () => {
    setOpenGetArea(false);
  };

  // Delete Area
  const handleDeleteArea = () => {
    setOpen(false);
    idArea &&
      deleteArea({
        variables: {
          input: idArea,
        },
      });
  };

  // Update area Status
  function handleIsActive(
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ): void {
    updateArea({
      variables: {
        input: {
          id: id,
          isActive: e.target.checked,
        },
      },
    });
  }

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderTableRows = () => {
    return (
      areasData?.getAllAreas.data?.map((el, index) => {
        const loadingg = idArea === el?._id && isLoading;
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          name: el.name,
          nameAr: el.areaName?.AR,
          hasCord: el.cordinates?.coordinates?.length ? (
            <Chip
              key={el._id}
              label={"YES"}
              variant="outlined"
              className={FormsClasses.chips__tags}
            />
          ) : (
            <Chip
              key={el._id}
              label={"NO"}
              variant="outlined"
              className={FormsClasses.chips__tags}
            />
          ),
          actions: loadingg ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <IconButton onClick={(e) => handleClickMenu(e, el?._id)}>
              <MoreVertIcon />
            </IconButton>
          ),
          status: (
            <Switch
              defaultChecked={el?.isActive || false}
              onChange={(e) => handleIsActive(e, el._id)}
            />
          ),
        };
      }) || []
    );
  };

  return (
    <div>
      {useIsAuthorized("ADMIN_AREA_CREATE") ? (
        <PageHeader
          buttonLink="/admin/area/AddEditArea"
          buttonText="Add Area"
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      ) : (
        <PageHeader
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      )}
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <Button variant="contained" onClick={handleOpenGetArea}>
          Get Area
        </Button>
        <Dialog
          open={openGetArea}
          onClose={handleCloseGetArea}
          className={DialogClasses.dialog_container}
        >
          <Box display="flex" justifyContent="space-between">
            <DialogTitle className={DialogClasses.alert_dialog_title}>
              Get Area
            </DialogTitle>
            <Box marginLeft={20}>
              <IconButton
                color="inherit"
                onClick={handleCloseGetArea}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <DialogContent>
            <Box>
              <p className={ContainersClasses.section__subtitle}>Latitude</p>
              <TextField
                fullWidth
                required
                onChange={(e) => setLatitude(parseFloat(e.target.value))}
              />
            </Box>
            <Box>
              <p className={ContainersClasses.section__subtitle}>Longitude</p>
              <TextField
                fullWidth
                required
                onChange={(e) => setLongitude(parseFloat(e.target.value))}
              />
            </Box>
            <Box display="flex" justifyContent="space-around">
              <Typography marginTop={3} display="flex">
                Area Name (EN): {areaNameEN}
              </Typography>
              <Typography marginTop={3} display="flex">
                Area Name (AR): {areaNameAR}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={findArea}
              disabled={loadingArea}
            >
              {loadingArea && (
                <CircularProgress
                  size={20}
                  style={{ marginRight: 10, color: "white" }}
                />
              )}
              Find Area
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <TableContainer>
        <Table
          columns={areaCols}
          loading={loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Areas found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("ADMIN_AREA_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <div>
                <span className="menu_title">Update</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("ADMIN_AREA_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Area ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Area ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={handleDeleteArea}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default AreaList;
