import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export const useStylesMenu = makeStyles((theme: Theme) =>
  createStyles({
    poper_menu_container: {
      // transform: "translateX(26px)",
      "& > div > ul": {
        // padding: "60px !important",
      },
    },
    menu_delete_container: {
      "&:hover": {
        backgroundColor: "#fff !important",
      },
      padding: " 0 !important",
      "& > div": {
        padding: "8px 20px 8px 10px",
        width: "100%",
        // backgroundColor: "#ffe2e2",
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: `#B27D3F14 !important`,
        },
        "& .menu_title": {
          fontWeight: 500,
          marginRight: "20px",
          color: "rgb(236, 116, 116);",
        },
        "& svg": {
          width: "22px",
          marginRight: "10px",
          transform: "translate(-3px, 1px)",
        },
      },
    },
    menu_container: {
      "&:hover": {
        backgroundColor: "#fff !important",
      },
      padding: "0 !important",
      "& > div": {
        padding: "8px 20px 8px 10px",
        width: "100%",
        // backgroundColor: " #ffb1b1",
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: `#B27D3F14 !important`,
        },
        "& .menu_title": {
          fontWeight: 500,
          marginRight: "20px",
        },
        "& svg": {
          width: "17px",
          marginRight: "10px",
          transform: "translateY(1px)",
        },
      },
    },
  })
);
