import React from "react";
// materiel ui
import {
  Table as TableMUI,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCellProps,
  PaginationProps,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { TablePaginationProps } from "@mui/material/TablePagination";
//styles
import {
  TableCellStyled,
  TableCellStyledData,
  TableRowStyled,
} from "../../../styles/Table__styles";
// utils
import { generateKey } from "../../../utils/generate-key";
// components
import CustomTablePagination from "./TablePagination";

export type ColumnsProps = {
  header: React.ReactNode;
  accessor: string;
  headerCellProps?: TableCellProps;
  cellProps?: TableCellProps;
}[];

type TableProps<T> = {
  data: T[];
  columns: ColumnsProps;
  tablePaginationProps?: TablePaginationProps;
  emptyMessage?: string;
  onClickRow?: (row: T) => void;
  wrapper?: any;
  loading?: boolean;
  numberOfFakeRow?: number;
  paginationProps?: PaginationProps;
};

function Table<T extends { [key: string]: React.ReactNode }>(
  tableProps: TableProps<T>
): JSX.Element {
  const {
    data,
    columns,
    tablePaginationProps,
    paginationProps,
    emptyMessage,
    onClickRow,
    loading,
    numberOfFakeRow,
  } = tableProps;

  const rows = data.map((item) => ({ render: item, key: generateKey() }));
  const number = React.useMemo(() => numberOfFakeRow ?? 10, [numberOfFakeRow]);
  //render
  const renderContent = () => {
    if (loading) {
      const fakeRows = new Array(number).fill(10);
      return fakeRows.map((row, iterator) => {
        return (
          <TableRowStyled key={iterator}>
            {columns?.map((column, index) => (
              <TableCellStyledData
                // eslint-disable-next-line
                key={index}
                hideborder={iterator + 1 === rows.length ? "true" : undefined}
                {...column.cellProps}
              >
                <Skeleton
                  variant="text"
                  height={16}
                  sx={{ margin: "12px 8px" }}
                />
              </TableCellStyledData>
            ))}
          </TableRowStyled>
        );
      });
    } else if (data.length === 0 && !loading) {
      return (
        <TableRowStyled>
          <TableCellStyledData
            size="medium"
            hideborder
            colSpan={columns.length}
            align="center"
          >
            {emptyMessage}
          </TableCellStyledData>
        </TableRowStyled>
      );
    }
    return rows.map((row, iterator) => {
      return (
        <TableRowStyled
          key={row.key}
          hover={!!onClickRow}
          onClick={() => onClickRow?.(row.render)}
        >
          {columns?.map((column, index) => (
            <TableCellStyledData
              // eslint-disable-next-line
              key={index}
              hideborder={iterator + 1 === rows.length ? "true" : undefined}
              {...column.cellProps}
            >
              {row.render[column?.accessor]}
            </TableCellStyledData>
          ))}
        </TableRowStyled>
      );
    });
  };

  return (
    <TableContainer>
      <TableMUI aria-label="customized table" size="small">
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCellStyled
                // eslint-disable-next-line
                key={index}
                {...column.headerCellProps}
              >
                {column.header}
              </TableCellStyled>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{renderContent()}</TableBody>
        <TableFooter>
          <TableRow>
            {tablePaginationProps && (
              <CustomTablePagination
                {...tablePaginationProps}
                paginationProps={paginationProps}
              />
            )}
          </TableRow>
        </TableFooter>
      </TableMUI>
    </TableContainer>
  );
}

export default Table;
