import { FilterBar } from "../../Utils";
import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  /* {
    header: "ID",
    accessor: "id",
  }, */
  {
    header: "CUSTOMER NAME",
    accessor: "name",
  },
  {
    header: "MOBILE",
    accessor: "mobile",
  },
  {
    header: "EMAIL",
    accessor: "email",
  },
  {
    header: "PLAN",
    accessor: "plan",
  },
  {
    header: "own Promocode",
    accessor: "ownPromoCode",
  },
  {
    header: "Start Date",
    accessor: "date",
  },
  {
    header: "Expirey Date",
    accessor: "exDate",
  },
  {
    header: "Price",
    accessor: "price",
  },
  {
    header: "Type",
    accessor: "Type",
  },
  {
    header: "used Promocode",
    accessor: "promoCode",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Driver",
    accessor: "driver",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];
export const SIDE_FILTER_OPTIONS_FIRST_BOX_DELIVERED: FilterBar[] = [
  {
    id: "03",
    title: "Duration",
    data: [
      { id: "TWO_MONTHS", name: "Two Months", isSelected: false },
      { id: "THREE_MONTHS", name: "Three Months", isSelected: false },
      { id: "MONTHLY", name: "Monthly", isSelected: false },
      { id: "WEEKLY", name: "Weekly", isSelected: false },
      { id: "ONE_DAY", name: "One Day", isSelected: false },
      /*  { id: "ALL_DURATIONS", name: "All", isSelected: false }, */
    ],
  },
  {
    id: "04",
    title: "Meal plan",
    data: [
      { id: "5", name: "5 meals", isSelected: false },
      { id: "4", name: "4 meals", isSelected: false },
      { id: "3", name: "3 meals", isSelected: false },
      { id: "2", name: "Business Lunch", isSelected: false },
      /* { id: "CUSTOM", name: "Custom", isSelected: false }, */
      /* { id: "ALL_PLANS", name: "All", isSelected: false }, */
    ],
  },
  {
    id: "05",
    title: "No of Days",
    data: [
      { id: "FIVE_DAYS", name: "5 days", isSelected: false },
      { id: "SIX_DAYS", name: "6 days", isSelected: false },
      { id: "SEVEN_DAYS", name: "7 days", isSelected: false },
      /* { id: "ALL_DAYS", name: "All", isSelected: false }, */
    ],
  },
  {
    id: "07",
    title: "Status",
    data: [
      { id: "FROZEN", name: "Frozen", isSelected: false },
      { id: "UPCOMING", name: "Upcoming", isSelected: false },
      { id: "ACTIVE", name: "Active", isSelected: false },
      { id: "CANCLED", name: "Canceled", isSelected: false },
      { id: "WILL_BE_CANCELED", name: "Will be canceled", isSelected: false },
      { id: "DELETED", name: "Deleted", isSelected: false },
      { id: "UNCOMPLETED", name: "Uncompleted", isSelected: false },
      { id: "EXPIRING_SOON", name: "Expiring soon", isSelected: false },
      { id: "EXPIRED", name: "Expired", isSelected: false },
      { id: "WILL_BE_FROZEN", name: "Will be frozen", isSelected: false },
      {
        id: "WILL_BE_DESACTIVATED",
        name: "Will be desactivated",
        isSelected: false,
      },
      { id: "DESACTIVATED", name: "Desactivated", isSelected: false },
      { id: "SWITCHED", name: "Switched", isSelected: false },
      { id: "WILL_BE_SWITCHED", name: "Will be Switched", isSelected: false },
    ],
  },
];
