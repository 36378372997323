import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//material-ui
import {
  IconButton,
  Menu,
  MenuItem,
  Button,
  Typography,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//components
import Table from "../../../layout/commun/Table";
import Status from "../../../Status/Status";
//styles
import { TableContainer } from "../../../../styles/Table__styles";
import { useStylesMenu } from "../../../../styles/Menu__styles";
//utils
import { useIsAuthorized } from "../../../../utils/Hooks/useIsAuthorized";
import {
  columns,
  convertNumberOfDaysEnum,
  handleStatus,
} from "../../../customer/Utils";
//mutations&queries
import {
  CustomerProfilePayload,
  useGetAllCustomersProfileV2Query,
  NumberOfDays,
} from "../../../../graphql/types";

const ListCustomers = () => {
  const navigate = useNavigate();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");

  //styles
  const classes = useStylesMenu();

  //states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<CustomerProfilePayload | null>(null);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);

  //muations
  const dataGetCustomers = useGetAllCustomersProfileV2Query({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        sort: null,
        influencer: true,
      },
    },
    fetchPolicy: "network-only",
  });

  const customers = useMemo(
    () => dataGetCustomers?.data?.getAllCustomersProfileV2.data || [],
    [dataGetCustomers?.data]
  );

  const count = useMemo(
    () => dataGetCustomers.data?.getAllCustomersProfileV2.count || 0,
    [dataGetCustomers.data]
  );

  //functions
  const handleClickMenu =
    (item: CustomerProfilePayload) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDetails = () => {
    setAnchorEl(null);
    if (selected) {
      navigate(`/admin/customers/view_customer/${selected._id}`);
    }
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (selected) {
      navigate(`/admin/customers/update_customer/${selected._id}`);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  //rows
  const renderTableRows = () => {
    return (
      customers?.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          program__name: el?.plan?.program?.name?.EN || "--",
          customer__status: el?.plan?.planCondition ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Status
                status={el?.plan?.planCondition}
                statusText={handleStatus(el?.plan?.planCondition)}
              />
            </Box>
          ) : (
            "--"
          ),
          start__date: moment(el?.plan?.startDate).isValid() ? (
            <>
              <Typography style={{ fontSize: 12 }}>
                {"From : " + moment(el?.plan?.startDate).format("DD/MM/YYYY")}
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                {"To : " + moment(el?.plan?.expiryDate).format("DD/MM/YYYY")}
              </Typography>
            </>
          ) : (
            "--"
          ),
          otp__number: (el?.otp || 0) > 0 ? el?.otp : "--",
          id: el?.ref,
          name: isAuthorizedToSeeProfile ? (
            <a
              style={{ textDecoration: "none" }}
              href={`/admin/customers/view_customer/${el?._id}`}
            >
              <Button>{`${el?.fname} ${el?.lname}`}</Button>
            </a>
          ) : (
            el.fname + " " + el.lname
          ),
          actions: (
            <IconButton onClick={handleClickMenu(el)}>
              <MoreVertIcon />
            </IconButton>
          ),

          details:
            el?.plan?.program?.name?.EN +
            "/" +
            el?.plan?.meals?.length +
            " meals",
          assignedTo:
            (el?.userAssignedTo?.fname ?? "----") +
            " " +
            (el?.userAssignedTo?.lname ?? "----"),

          duration:
            el?.plan?.paymentPeriod?.toLowerCase() +
            "/" +
            convertNumberOfDaysEnum(el?.plan?.nbrOfDays as NumberOfDays),
          NumberOfBoxes: el?.nbrOfBoxesConsumed + "/" + el?.nbrOfBoxes,
          mobile: el?.phone?.prefix + " " + el?.phone?.number,
        };
      }) || []
    );
  };

  return (
    <>
      <TableContainer>
        <Table
          columns={columns}
          data={renderTableRows()}
          loading={dataGetCustomers.loading}
          numberOfFakeRow={30}
          emptyMessage="No influencers found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {isAuthorizedToSeeProfile && (
            <MenuItem
              onClick={handleCloseDetails}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Details</span>
              </Box>
            </MenuItem>
          )}

          {useIsAuthorized("UPDATE_CUSTOMER") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>
    </>
  );
};

export default ListCustomers;
