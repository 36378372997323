import { useEffect, useState } from "react";
//material ui
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//types
import {
  DeliveriesByDriverPayload,
  DriverPayload,
} from "../../../../graphql/types";

type Props = {
  id: string;
  optionsList: DriverPayload[];
  defaultList: DriverPayload[];
  onChange: any;
  stats: DeliveriesByDriverPayload[];
};

const MultiSelect: React.FC<Props> = ({
  optionsList,
  onChange,
  defaultList,
  id,
  stats,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //state
  const [drivers, setDrivers] = useState<DriverPayload[]>([]);

  useEffect(() => {
    if (defaultList.length > 0) {
      setDrivers(defaultList);
    }
  }, []);

  const getDeliveriesNumberForDriver = (id: string) => {
    const result = stats.find((el) => el.driver?._id === id);
    return result?.count || 0;
  };

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      id={Math.random().toString()}
      sx={{ width: "100%" }}
      options={optionsList || []}
      onChange={(event, value) => {
        setDrivers(value);
        onChange(id, value);
      }}
      getOptionLabel={(option) =>
        `${option.name} (${getDeliveriesNumberForDriver(option._id || "")}) ` ||
        ""
      }
      value={drivers}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            color="secondary"
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.name} (${getDeliveriesNumberForDriver(option._id || "")})`}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} placeholder="Drivers" label="Drivers" />
      )}
    />
  );
};

export default MultiSelect;
