import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export const useStylesDialog = makeStyles((theme: Theme) =>
  createStyles({
    dialog_container: {
      "& .MuiDialog-paperWidthSm": {
        minHeight: "320px",
        minWidth: "550px",
        padding: "20px",
      },
    },

    dialog_container_brand: {
      "& .MuiDialog-paperWidthSm": {
        minWidth: "550px",
        padding: "20px",
      },
    },
    dialog_container_generate_link: {
      "& .MuiDialog-paperWidthSm": {
        minHeight: "30%",
        minWidth: "42%",
        padding: "20px",
      },
    },
    dialog_renew_container: {
      "& .MuiDialog-paperWidthSm": {
        minHeight: "70%",
        minWidth: "60%",
        padding: "20px",
      },
    },
    dialog_image_container: {
      "& .MuiDialog-paperWidthSm": {
        minHeight: "50%",
        minWidth: "25%",
        padding: "20px",
      },
    },
    dialog_CustomTransaction_container: {
      "& .MuiDialog-paperWidthSm": {
        minHeight: "35%",
        minWidth: "50%",
        padding: "20px",
      },
    },
    dialog_stats_container: {
      "& .MuiDialog-paperWidthSm": {
        minHeight: "55%",
        minWidth: "60%",
        padding: "20px",
      },
    },
    dialog_title: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    alert_dialog_title: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& svg": {
        minWidth: "fit-content",
        minHeight: "60px",
        marginBottom: "16px",
      },
      "& span": {
        minWidth: "fit-content",
        marginBottom: "10px",
      },
      "& .alert_dialog_title_text": {
        color: theme.palette.error.main,
        fontWeight: 500,
      },
      "& .confirm_dialog_title_text": {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
    },
    alert_dialog_content: {
      display: "flex",
      justifyContent: "center",
      "& p": {
        textAlign: "center",
        fontWeight: 500,
        fontSize: "22px",
        width: "80%",
        color: "black",
      },
    },
    alert_dialog_contentv2: {
      display: "flex",
      justifyContent: "center",
      "& p": {
        textAlign: "center",
        width: "80%",
      },
    },
    dialog_content_transaction_link: {
      display: "flex",
      justifyContent: "center",
      fontSize: "16px",
      "& p": {
        textAlign: "center",
        fontWeight: 500,
        fontSize: "16px",
        width: "90%",
        "& span": {
          color: theme.palette.secondary.main,
        },
      },
    },
    alert_dialog_actions: {
      display: "flex !important",
      justifyContent: "center !important",
      "& button": {
        textTransform: "capitalize !important",
      },
    },
    alert_dialog_renew: {
      display: "flex !important",
      justifyContent: "space-between !important",
      marginLeft: "100px",
      marginRight: "50px",
      "& button": {
        textTransform: "capitalize !important",
      },
    },
  })
);
