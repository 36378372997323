import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStylesStatus = makeStyles((theme: Theme) => {
  return {
    active_status: { backgroundColor: theme.palette.primary.main },
    success_status: { backgroundColor: "#C7893E" },
    frozen_status: { backgroundColor: "#18A2A0" },
    finished_status: { backgroundColor: "#C4C4C4" },
    inactive_status: { backgroundColor: "#888888" },
    failed_status: { backgroundColor: "#E55724" },
    green_status: { backgroundColor: "#47BC45" },
  };
});

export const StatusContainer = styled("div")(({ theme }) => ({
  padding: "5px 10px",
  borderRadius: "5px",
  color: theme.palette.primary.contrastText,
  width: "max-content",
  "& span": {
    textTransform: "capitalize",
  },
}));
