import * as React from "react";
//material ui
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
//utils
import { TabPanelProps, a11yProps } from "../../Utils";
//components
import NotificationsList from "./NotificationsList";
import PushNotificationsList from "./PushNotificationsList";
import AutomatedNotificationsList from "./AutomatedNotificationsList";

const NotificationsView = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Notifications" {...a11yProps(0)} />
            <Tab label="Push notifications" {...a11yProps(1)} />
            <Tab label="Automated notifications" {...a11yProps(2)} />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <NotificationsList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PushNotificationsList />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AutomatedNotificationsList />
      </TabPanel>
    </Box>
  );
};
export default NotificationsView;

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
