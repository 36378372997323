import { useCallback, useMemo, useState } from "react";

import _ from "lodash";
import moment from "moment";
//material ui
import { Box } from "@mui/material";

//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
//compoments
import Table from "../../layout/commun/Table";
//utils
import { columns, filterElements, SortElements } from "./Utils";
//queries&mutations
import { useGetPlanTransferDetailsQuery } from "../../../graphql/types";
import PageHeader from "../../pageHeader/PageHeader";

const BoxTransferDashboard = () => {
  const classes = useStylesMenu();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [search, setSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState<SortElements>("CREATE_DESC");
  //queries&mutations
  const { data: boxTransferDetails, loading } = useGetPlanTransferDetailsQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        name: search,
        sort: sortBy,
      },
    },
    fetchPolicy: "network-only",
  });

  const boxTransferData = useMemo(
    () => boxTransferDetails?.getPlanTransferDetails.data || [],
    [boxTransferDetails]
  );

  const count = useMemo(
    () => boxTransferDetails?.getPlanTransferDetails.count || 0,
    [boxTransferDetails]
  );

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderTableRows = () => {
    return boxTransferData.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        date: item.transferDate
          ? moment(item.transferDate).format("DD-MMM-YYYY")
          : "",
        fromCustomerName:
          item.transferredFrom?.fname + " " + item.transferredFrom?.lname,
        toCustomerName:
          item.transferredTo?.fname + " " + item.transferredTo?.lname,
      };
    });
  };

  const debounced = useCallback(
    _.debounce((event) => {
      setSearch(event.target.value);
    }, 800),
    []
  );

  return (
    <>
      <Box marginLeft={40} marginRight={40} marginTop={3} marginBottom={3}>
        <PageHeader
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      </Box>

      <TableContainer>
        <Table
          columns={columns}
          loading={boxTransferData.length === 0 && loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Box Transfer to display !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
    </>
  );
};

export default BoxTransferDashboard;
