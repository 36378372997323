import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
//material ui
import { Autocomplete, Box, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//styles
import {
  ContainerFullWidth,
  ContainerLeft,
  ContainersContent,
  LeftElement,
  useStylesContainers,
} from "../../../../styles/Containers__styles";
//utils
import { Roles } from "../Utils";
import { getMessageError } from "../../../Utils";
import { useCountryPrefix } from "../../../../utils/Hooks/useCountryPrefix";
//types
import { ApolloError } from "@apollo/client";
import { userProps } from "../Types";
//queries&mutations
import {
  useCreateUserMutation,
  useGetCountriesListQuery,
} from "../../../../graphql/types";
//components
import PageAddHeader from "../../../pageAddHeader/pageAddHeader";
import PhoneInput from "../../../layout/PhoneInput";

const AddUser = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const ContainersClasses = useStylesContainers();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //mutation
  const [createUser, { loading }] = useCreateUserMutation();

  //HOOKS
  const countryPrefix = useCountryPrefix();

  //react-hook-form
  const methods = useForm<userProps>({
    defaultValues: {
      first__name: "",
      last__name: "",
      user__name: "",
      email: "",
      phone__number: "",
      prefix__phone__number: countryPrefix?.callingCode,
      password: "",
      confirm__password: "",
      role: null,
      countries: [],
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  const { data: countriesListData } = useGetCountriesListQuery({
    fetchPolicy: "network-only",
  });

  const countriesList = useMemo(
    () => countriesListData?.getCountriesList || [],
    [countriesListData]
  );

  //functions
  const onSubmit: SubmitHandler<userProps> = async (data: userProps) => {
    if (data.password != data.confirm__password) {
      enqueueSnackbar("Password and confirm password should be identical", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      return;
    }
    try {
      const phone = data.phone__number.replace(/\s/g, "");
      await createUser({
        variables: {
          input: {
            email: data.email,
            phone: {
              prefix: `+${data?.prefix__phone__number}`,
              number: phone,
            },
            fname: data.first__name,
            lname: data.last__name,
            role: data.role,
            username: data.user__name,
            password: data.password,
            countriesList: data?.countries
              ?.map((el) => el?._id)
              .filter(Boolean) as string[],
          },
        },
        onCompleted: () => {
          enqueueSnackbar("User succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/data/users_and_roles/list_users_roles");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title="Create User"
          buttonText="Create User"
          cancelButtonLink="/data/users_and_roles/list_users_roles"
          loading={loading}
        />
        <ContainersContent>
          <ContainerFullWidth>
            <FormProvider {...methods}>
              <ContainerLeft>
                <LeftElement>
                  <p className={ContainersClasses.section__title}>
                    Personal Informations
                  </p>
                  <Box className={ContainersClasses.section__container}>
                    {/* First Name */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        First Name*
                      </p>
                      <TextField
                        placeholder="First Name"
                        sx={{ width: "100%" }}
                        {...register("first__name", {
                          required: "Enter User First Name",
                        })}
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.first__name?.message}
                      </p>
                    </Box>

                    {/* Last Name */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Last Name*
                      </p>
                      <TextField
                        placeholder="Last Name"
                        sx={{ width: "100%" }}
                        {...register("last__name", {
                          required: "Enter User Last Name",
                        })}
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.last__name?.message}
                      </p>
                    </Box>
                    {/* Username */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Username*
                      </p>
                      <TextField
                        placeholder="Username"
                        sx={{ width: "100%" }}
                        {...register("user__name", {
                          required: "Enter Username",
                        })}
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.user__name?.message}
                      </p>
                    </Box>
                  </Box>
                  <Box className={ContainersClasses.section__container}>
                    {/* Email */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Email
                      </p>
                      <TextField
                        placeholder="Email"
                        sx={{ width: "100%" }}
                        {...register("email", {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Enter a valid Email",
                          },
                          required: "Email is Mandatory",
                        })}
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.email?.message}
                      </p>
                    </Box>
                    {/* Phone */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Phone Number*
                      </p>
                      <PhoneInput
                        labelFormPrefix="prefix__phone__number"
                        labelFormNumber="phone__number"
                        placeholder="Phone Number"
                      />
                    </Box>
                  </Box>
                  <Box className={ContainersClasses.section__container}>
                    {/* Password */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Password*
                      </p>
                      <TextField
                        placeholder="Password"
                        autoComplete="off"
                        type="password"
                        sx={{ width: "100%" }}
                        {...register("password", {
                          required: "Enter User Password",
                        })}
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.password?.message}
                      </p>
                    </Box>
                    {/* Confirm Password */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Confirm Password*
                      </p>
                      <TextField
                        placeholder="Confirm Password"
                        autoComplete="off"
                        type="password"
                        sx={{ width: "100%" }}
                        {...register("confirm__password", {
                          required: "Enter Password again",
                        })}
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.confirm__password?.message}
                      </p>
                    </Box>
                  </Box>
                  <Box className={ContainersClasses.section__container}>
                    {/* Role */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Role
                      </p>
                      <Controller
                        name="role"
                        rules={{
                          required: "Select role",
                        }}
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <Autocomplete
                              ref={ref}
                              id="role"
                              options={Roles || []}
                              value={dataWatch?.role}
                              getOptionLabel={(option) => option || ""}
                              filterSelectedOptions
                              onChange={(_, data) => Change(data)}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    color="secondary"
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Role" />
                              )}
                            />
                            <p
                              className={
                                ContainersClasses.section__error__helper
                              }
                            >
                              {error?.message}
                            </p>
                          </>
                        )}
                      />
                    </Box>
                    {/* COUNTRIES */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Countries
                      </p>
                      <Controller
                        name="countries"
                        rules={{
                          required: "Select Country",
                        }}
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <Autocomplete
                              multiple
                              ref={ref}
                              id="countries"
                              options={countriesList ?? []}
                              value={dataWatch?.countries}
                              getOptionLabel={(option) => option.name?.EN || ""}
                              isOptionEqualToValue={(option, value) =>
                                option._id === value._id
                              }
                              filterSelectedOptions
                              onChange={(_, data) => Change(data)}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    color="secondary"
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name?.EN}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Country" />
                              )}
                            />
                            <p
                              className={
                                ContainersClasses.section__error__helper
                              }
                            >
                              {error?.message}
                            </p>
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                </LeftElement>
              </ContainerLeft>
            </FormProvider>
          </ContainerFullWidth>
        </ContainersContent>
      </form>
    </FormProvider>
  );
};

export default AddUser;
