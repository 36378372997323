import { Theme, lighten } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStylesLeads = makeStyles((theme: Theme) => {
  return {
    select_container: {},
    option_item: {
      padding: "8px 10px",
      width: "100%",
      borderRadius: "6px !important",
      "&:hover": {
        backgroundColor: `${lighten(
          theme.palette.secondary.main,
          0.7
        )} !important`,
      },
      "& .menu_title": {
        fontWeight: `${500} !important`,
      },
    },
    option_item_selected: {
      color: `${theme.palette.secondary.main} !important`,
      backgroundColor: `${lighten(
        theme.palette.secondary.main,
        0.85
      )} !important`,
    },
    convert_to_customer_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    // switch_track: {
    //   backgroundColor: "grey",
    // },
    // switch_base: {
    //   color: "#f50057",
    //   "&.Mui-disabled": {
    //     color: "#e886a9",
    //   },
    //   "&.Mui-checked": {
    //     color: "#95cc97",
    //   },
    //   "&.Mui-checked + .MuiSwitch-track": {
    //     backgroundColor: "#4CAF50",
    //   },
    // },
    // switch_primary: {
    //   "&.Mui-checked": {
    //     color: "#4CAF50",
    //   },
    //   "&.Mui-checked + .MuiSwitch-track": {
    //     backgroundColor: "#4CAF50",
    //   },
    // },
    info_text: {
      color: "grey",
      fontSize: theme.typography.subtitle1.fontSize,
    },
    infos_container: {
      display: "flex",
      alignItems: "center",
      marginTop: "6px",
      "& svg": {
        marginRight: "14px",
        minHeight: "22px",
        minWidth: "22px",
      },
      "& p ": {
        margin: "2px 0 0 0",
      },
    },
  };
});
