import React from "react";
import moment from "moment";
//material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//logo
import dieture_logo_black from "../../../public/images/dieture_logo_black.png";
//types
import { printDishSummeryReportProps } from "../Types";
import { SummaryDishesPayload } from "../../../graphql/types";

const DishSummeryReportPrint = React.forwardRef<
  HTMLDivElement,
  printDishSummeryReportProps
>(({ generateReport, DateDelivery }, ref) => {
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const curTime =
    current.getHours() +
    ":" +
    current.getMinutes() +
    ":" +
    current.getSeconds();

  const allDishes = generateReport?.getDishSummaryReport.dishes || [];
  const dishes: { [key: string]: SummaryDishesPayload[] } = {};
  allDishes.forEach((dish) => {
    const programName = dish.program?.EN || "";
    if (!dishes[programName]) {
      dishes[programName] = [];
    }
    dishes[programName].push(dish);
  });
  //render
  return (
    <div ref={ref}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography style={{ fontSize: 18 }} fontWeight={600}>
          Dish Report - Summary
        </Typography>
        <img src={dieture_logo_black} height="50px" alt="dieture_logo_black" />
        <Box>
          <Typography variant="body2" fontWeight={600}>
            Delivery on: {moment(DateDelivery).format("DD/MM/YYYY-dddd")}
          </Typography>
          <Typography fontSize={8.5}>
            Generated on : {date}-{curTime}
          </Typography>
        </Box>
      </Box>

      <Box display="grid" gridTemplateColumns="repeat(2,1fr)" gap={3}>
        {Object.keys(dishes).map((program) => (
          <Box display="flex" flexDirection="column" height={"93vh"}>
            {dishes[program].map((el) => (
              <Box key={el.meal} flexGrow={1}>
                <Typography style={{ fontSize: 14 }} fontWeight={600}>
                  {el.program?.EN} : {el.meal}
                </Typography>
                <table
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: 0,
                    width: "100%",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#dedcdd",
                        borderTopLeftRadius: 5,
                        overflow: "hidden",
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.15,
                      }}
                    >
                      <div></div>
                    </th>
                    <th
                      style={{
                        backgroundColor: "#dedcdd",
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.15,
                        borderLeftWidth: 0,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          paddingLeft: 6,
                        }}
                      >
                        code
                      </div>
                    </th>
                    <th
                      style={{
                        backgroundColor: "#dedcdd",
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.15,
                        borderLeftWidth: 0,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          paddingLeft: 6,
                        }}
                      >
                        Dish Name
                      </div>
                    </th>
                    <th
                      style={{
                        backgroundColor: "#93b4cf",
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.15,
                        borderLeftWidth: 0,
                        borderBottomWidth: 1,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                        }}
                      >
                        Total
                      </div>
                    </th>
                    <th
                      style={{
                        backgroundColor: "#dec1d6",
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.15,
                        borderLeftWidth: 0,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                        }}
                      >
                        S
                      </div>
                    </th>
                    <th
                      style={{
                        backgroundColor: "#dec1d6",
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.15,
                        borderLeftWidth: 0,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                        }}
                      >
                        M
                      </div>
                    </th>
                    <th
                      style={{
                        backgroundColor: "#dec1d6",
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.15,
                        borderTopRightRadius: 5,
                        borderLeftWidth: 0,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                        }}
                      >
                        L
                      </div>
                    </th>
                  </tr>
                  {el.content?.map((element, index) => {
                    return (
                      <tr key={element.code}>
                        <td
                          style={{
                            borderColor: "black",
                            borderStyle: "solid",
                            borderWidth: 0.15,
                            borderTopWidth: 0,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 12,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontFamily: "Poppins",
                            }}
                          >
                            {index + 1}
                          </div>
                        </td>
                        <td
                          style={{
                            borderColor: "black",
                            borderStyle: "solid",
                            borderWidth: 0.15,
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 12,
                              fontFamily: "Poppins",
                            }}
                          >
                            {element.code}
                          </div>
                        </td>
                        <td
                          style={{
                            borderColor: "black",
                            borderStyle: "solid",
                            borderWidth: 0.15,
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 12,
                              paddingLeft: 6,
                              fontFamily: "Poppins",
                            }}
                          >
                            {element?.name?.EN}
                          </div>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#93b4cf",
                            borderColor: "black",
                            borderStyle: "solid",
                            borderWidth: 0.15,
                            borderTopWidth: 0.1,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 12,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontFamily: "Poppins",
                            }}
                          >
                            {element.total}
                          </div>
                        </td>
                        <td
                          style={{
                            borderColor: "black",
                            borderStyle: "solid",
                            borderWidth: 0.15,
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 12,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontFamily: "Poppins",
                            }}
                          >
                            {element.small}
                          </div>
                        </td>
                        <td
                          style={{
                            borderColor: "black",
                            borderStyle: "solid",
                            borderWidth: 0.15,
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 12,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontFamily: "Poppins",
                            }}
                          >
                            {element.medium}
                          </div>
                        </td>
                        <td
                          style={{
                            borderColor: "black",
                            borderStyle: "solid",
                            borderWidth: 0.15,
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 12,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontFamily: "Poppins",
                            }}
                          >
                            {element.large}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      {(generateReport?.getDishSummaryReport?.addons || []).length > 0 && (
        <Box>
          <Box marginTop={5}>
            <Typography style={{ fontSize: 14 }} fontWeight={600}>
              ADDON
            </Typography>
          </Box>
          <table
            style={{
              borderCollapse: "separate",
              borderSpacing: 0,
              width: "100%",
            }}
          >
            <tr>
              <th
                style={{
                  backgroundColor: "#dedcdd",
                  borderTopLeftRadius: 5,
                  overflow: "hidden",
                  borderColor: "black",
                  borderStyle: "solid",
                  borderWidth: 0.15,
                }}
              >
                <div></div>
              </th>
              <th
                style={{
                  backgroundColor: "#dedcdd",
                  borderColor: "black",
                  borderStyle: "solid",
                  borderWidth: 0.15,
                  borderLeftWidth: 0,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    paddingLeft: 6,
                  }}
                >
                  Addon Name
                </div>
              </th>
              <th
                style={{
                  backgroundColor: "#93b4cf",
                  borderColor: "black",
                  borderStyle: "solid",
                  borderWidth: 0.15,
                  borderTopRightRadius: 5,
                  borderLeftWidth: 0,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  Total
                </div>
              </th>
            </tr>
            {generateReport?.getDishSummaryReport.addons?.map((el, index) => {
              return (
                <tr key={el?._id}>
                  <td
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.15,
                      borderTopWidth: 0,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Poppins",
                      }}
                    >
                      {index + 1}
                    </div>
                  </td>
                  <td
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.15,
                      borderTopWidth: 0,
                      borderLeftWidth: 0,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        paddingLeft: 6,
                        fontFamily: "Poppins",
                      }}
                    >
                      {el?.name?.EN}
                    </div>
                  </td>
                  <td
                    style={{
                      backgroundColor: "#93b4cf",
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.15,
                      borderTopWidth: 0.1,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Poppins",
                      }}
                    >
                      {el?.total}
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </Box>
      )}
      {(generateReport?.getDishSummaryReport?.sauces || []).length > 0 && (
        <Box>
          <Box marginTop={2.5}>
            <Typography style={{ fontSize: 14 }} fontWeight={600}>
              Sauce
            </Typography>
          </Box>
          <table
            style={{
              borderCollapse: "separate",
              borderSpacing: 0,
              width: "100%",
            }}
          >
            <tr>
              <th
                style={{
                  backgroundColor: "#dedcdd",
                  borderTopLeftRadius: 5,
                  overflow: "hidden",
                  borderColor: "black",
                  borderStyle: "solid",
                  borderWidth: 0.15,
                }}
              >
                <div></div>
              </th>
              <th
                style={{
                  backgroundColor: "#dedcdd",
                  borderColor: "black",
                  borderStyle: "solid",
                  borderWidth: 0.15,
                  borderLeftWidth: 0,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    paddingLeft: 6,
                  }}
                >
                  Sauce Name
                </div>
              </th>
              <th
                style={{
                  backgroundColor: "#93b4cf",
                  borderColor: "black",
                  borderStyle: "solid",
                  borderWidth: 0.15,
                  borderTopRightRadius: 5,
                  borderLeftWidth: 0,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  Total
                </div>
              </th>
            </tr>
            {generateReport?.getDishSummaryReport.sauces?.map((el, index) => {
              return (
                <tr key={el?._id}>
                  <td
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.15,
                      borderTopWidth: 0,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Poppins",
                      }}
                    >
                      {index + 1}
                    </div>
                  </td>
                  <td
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.15,
                      borderTopWidth: 0,
                      borderLeftWidth: 0,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        paddingLeft: 6,
                        fontFamily: "Poppins",
                      }}
                    >
                      {el?.name?.EN}
                    </div>
                  </td>
                  <td
                    style={{
                      backgroundColor: "#93b4cf",
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.15,
                      borderTopWidth: 0.1,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Poppins",
                      }}
                    >
                      {el?.total}
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </Box>
      )}
    </div>
  );
});

export default DishSummeryReportPrint;
