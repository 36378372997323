import React, { createContext, ReactNode, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useParams } from "react-router-dom";
//material
import { AppBar, Tab, Tabs, Box, Typography, Tooltip } from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
//styles
import {
  ContainerCustomerView,
  useStylesCustomers,
} from "../../../styles/Customers__styles";
import { StyledSpan } from "./ViewCustomer.styles";
//components
import ProfileDetails from "../ProfileDetails/ProfileDetails";
import CustomerlistAllergies from "../CustomerlistAllergies/CustomerlistAllergies";
import CustomerPayment from "../CustomerPayment/CustomerPayment";
import CustomerDeliveryAddress from "../CustomerDeliveryAddress/CustomerDeliveryAddress";
import CustomerMenuProgram from "../CustomerMenuProgram/CustomerMenuProgram";
import CustomerPauses from "../customerPauses/CustomerPauses";
import CustomerTransactions from "../CustomerTransactions/CustomerTransactions";
import CostumerActions from "../CustomerActions/CostumerActions";
import CustomerNotes from "../CustomerNotes/CustomerNotes";
import CustomerRewards from "../CustomerRewards/CustomerRewards";
import CustomerPromoCodeById from "../../dataManagement/CustomerPromoCode/CustomerPromoCodeById";
//import CustomerAppointment from "../CustomerAppointment/CustomerAppointment";
import CustomerComplains from "../CustomerComplains/CustomerComplains";
import CustomerReviews from "../CustomerReviews/CustomerReviews";
import CustomerReminders from "../CustomerReminders/CustomerReminders";
import CustomerFavourites from "../CustomerFavourites/CustomerFavourites";
//mutations&queries
import {
  GetCustomerProfileByIdQuery,
  PaymentPeriod,
  useGetCustomerProfileByIdQuery,
} from "../../../graphql/types";
//utils
import {
  a11yProps,
  handleNbrDays,
  handlePaymentPeriod,
  handlePlanCondition,
  TabPanelProps,
} from "../../Utils";
import { getPluralizedBoxText } from "../Utils";
// import ProgressView from "../CustomerProgress/ProgressView";

export const CustomerContext = createContext<
  GetCustomerProfileByIdQuery | undefined
>(undefined);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

interface CustomerTabsTypes {
  labelTab: string;
  Comp: ReactNode;
}

const ViewCustomer = () => {
  //styles
  const CustomerClasses = useStylesCustomers();
  //states
  const [value, setValue] = useState(0);
  const [linkCopied, setLinkCopied] = useState(false);
  //router
  const { id } = useParams();
  //functions
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const handleCopyClick = (name: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      setLinkCopied(true);
      window.focus();
      navigator.clipboard.writeText(name);
      setTimeout(() => {
        setLinkCopied(false);
      }, 1000);
    }
  };

  //queries
  const { data } = useGetCustomerProfileByIdQuery({
    variables: {
      customerId: id || "",
    },
    fetchPolicy: "cache-only",
    skip: !id,
  });

  //consts
  const firstName = data?.getCustomerProfileById?.fname;
  const lastName = data?.getCustomerProfileById?.lname;
  const phoneNumber =
    data?.getCustomerProfileById?.phone?.prefix +
    " " +
    data?.getCustomerProfileById?.phone?.number;
  const planCondition = data?.getCustomerProfileById?.plan?.planCondition;
  const upcomingPlanCondition =
    data?.getCustomerProfileById?.upcomingPlan?.planCondition;

  const planName = data?.getCustomerProfileById?.plan?.program?.name?.EN;
  const upcominPlanName =
    data?.getCustomerProfileById?.upcomingPlan?.program?.name?.EN;
  const planNumberMeals =
    data?.getCustomerProfileById?.plan?.meals &&
    data?.getCustomerProfileById?.plan?.meals.length;
  const upcomingPlanNumberMeals =
    data?.getCustomerProfileById?.upcomingPlan?.meals &&
    data?.getCustomerProfileById?.upcomingPlan?.meals.length;
  const planNbrOfDays = handleNbrDays(
    data?.getCustomerProfileById?.plan?.nbrOfDays,
    "number"
  );
  const UpcomingPlanNbrOfDays = handleNbrDays(
    data?.getCustomerProfileById?.upcomingPlan?.nbrOfDays,
    "number"
  );

  const planPeriod = data?.getCustomerProfileById?.plan?.paymentPeriod;
  const upcomingPlanPeriod =
    data?.getCustomerProfileById?.upcomingPlan?.paymentPeriod;
  const nbrOfBoxes = data?.getCustomerProfileById?.nbrOfBoxes ?? 0;
  const nbrOfBoxesConsumed =
    data?.getCustomerProfileById?.nbrOfBoxesConsumed ?? 0;

  const CustomerTabs: CustomerTabsTypes[] = [
    { labelTab: "Profile details", Comp: <ProfileDetails /> },
    { labelTab: "Allergies", Comp: <CustomerlistAllergies /> },
    { labelTab: "History", Comp: <CustomerPayment /> },
    { labelTab: "Delivery Addresses", Comp: <CustomerDeliveryAddress /> },
    { labelTab: "My Menu", Comp: <CustomerMenuProgram /> },
    { labelTab: "Pauses", Comp: <CustomerPauses /> },
    { labelTab: "Actions", Comp: <CostumerActions /> },
    { labelTab: "Transactions", Comp: <CustomerTransactions /> },
    { labelTab: "Notes", Comp: <CustomerNotes /> },
    { labelTab: "PromoCode", Comp: <CustomerPromoCodeById /> },
    { labelTab: "Rewards", Comp: <CustomerRewards /> },
    /* { labelTab: "Appointment", Comp: <CustomerAppointment /> }, */
    { labelTab: "Complaints", Comp: <CustomerComplains /> },
    { labelTab: "Reminders", Comp: <CustomerReminders /> },
    { labelTab: "Reviews", Comp: <CustomerReviews /> },
    { labelTab: "Favourites", Comp: <CustomerFavourites /> },
    // { labelTab: "Progress", Comp: <ProgressView /> },
  ];

  return (
    <CustomerContext.Provider value={data}>
      <Box className={CustomerClasses.customer_view_container}>
        <Box flex={2} sx={{ width: "100vh" }}>
          <Box className={CustomerClasses.customer_tabs_container}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.4 },
                  },
                }}
              >
                {CustomerTabs.map((el, index) => (
                  <Tab
                    label={el.labelTab}
                    {...a11yProps(index)}
                    key={el.labelTab}
                  />
                ))}
              </Tabs>
            </AppBar>
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 7fr" marginTop="10px">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Tooltip
                title={linkCopied ? "copied" : "Click here to copy the name"}
              >
                <Typography
                  fontWeight="600"
                  onClick={() => handleCopyClick(firstName + " " + lastName)}
                >
                  {firstName}
                </Typography>
              </Tooltip>
              <Tooltip
                title={linkCopied ? "copied" : "Click here to copy the name"}
              >
                <Typography
                  fontWeight="600"
                  onClick={() => handleCopyClick(firstName + " " + lastName)}
                >
                  {lastName}
                </Typography>
              </Tooltip>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="8px"
                  marginRight="8px"
                >
                  <Typography fontWeight="600">Mobile</Typography>
                  <Typography fontWeight="600">
                    {phoneNumber ? phoneNumber : "No Phone Number"}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="8px"
                  marginRight="8px"
                >
                  <Typography fontWeight="600">Status</Typography>
                  <StyledSpan color={handlePlanCondition(planCondition).color}>
                    {planCondition
                      ? handlePlanCondition(planCondition).text
                      : "No plan"}
                  </StyledSpan>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="8px"
                  marginRight="8px"
                >
                  <Typography fontWeight="600">Program</Typography>
                  <Typography fontWeight="600">
                    {planName ? planName : "No Plan"}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="8px"
                  marginRight="8px"
                >
                  <Typography fontWeight="600">Meal Plan</Typography>
                  <Typography fontWeight="600">
                    {planNumberMeals
                      ? planNumberMeals + " Meals"
                      : "No Number of Meals"}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="8px"
                  marginRight="8px"
                >
                  <Typography fontWeight="600">No of days</Typography>
                  <Typography fontWeight="600">{planNbrOfDays}/Week</Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="8px"
                  marginRight="8px"
                >
                  <Typography fontWeight="600">Duration</Typography>
                  <Typography fontWeight="600">
                    {planPeriod
                      ? handlePaymentPeriod(planPeriod as PaymentPeriod)
                      : "No plan period"}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="8px"
                  marginRight="8px"
                >
                  <Typography fontWeight="600">Box left</Typography>
                  <Typography fontWeight="600">
                    {getPluralizedBoxText(nbrOfBoxes - nbrOfBoxesConsumed)}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                marginTop="5px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="4px"
                  marginRight="4px"
                >
                  <Typography>Next Program </Typography>
                  <Typography></Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="4px"
                  marginRight="4px"
                >
                  <Typography>Status</Typography>
                  <StyledSpan
                    color={handlePlanCondition(upcomingPlanCondition).color}
                  >
                    {upcomingPlanCondition
                      ? handlePlanCondition(upcomingPlanCondition).text
                      : "----"}
                  </StyledSpan>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="4px"
                  marginRight="4px"
                >
                  <Typography>Program</Typography>
                  <Typography>
                    {upcominPlanName ? upcominPlanName : "----"}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="4px"
                  marginRight="4px"
                >
                  <Typography>Meal Plan</Typography>
                  <Typography>
                    {upcomingPlanNumberMeals
                      ? upcomingPlanNumberMeals + " Meals"
                      : "----"}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="8px"
                  marginRight="8px"
                >
                  <Typography>No of days</Typography>
                  <Typography>
                    {data?.getCustomerProfileById?.upcomingPlan?.nbrOfDays
                      ? UpcomingPlanNbrOfDays + "/Week"
                      : "----"}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  marginLeft="8px"
                  marginRight="8px"
                >
                  <Typography>Duration</Typography>
                  <Typography>
                    {upcomingPlanPeriod
                      ? handlePaymentPeriod(upcomingPlanPeriod as PaymentPeriod)
                      : "----"}
                  </Typography>
                </Box>
                <Box></Box>
              </Box>
            </Box>
          </Box>

          <SwipeableViews
            index={value}
            onChangeIndex={handleChangeIndex}
            className={CustomerClasses.swipeable_views_container}
          >
            {CustomerTabs.map((el, index) => (
              <TabPanel value={value} index={index} key={el.labelTab}>
                <ContainerCustomerView>{el.Comp}</ContainerCustomerView>
              </TabPanel>
            ))}
          </SwipeableViews>
        </Box>
      </Box>
    </CustomerContext.Provider>
  );
};

export default ViewCustomer;
