import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
//material ui
import { Autocomplete, Box, TextField } from "@mui/material";
//types
import { RewardsProps } from "./types";
import { ApolloError } from "@apollo/client";
//utils
import { getMessageError } from "../../Utils";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../styles/Recipe__styles";
//context api
import { useAuth } from "../../../contextAPI";
//componenets
import PageAddHeader from "../../pageAddHeader/pageAddHeader";
//mutations&queries
import {
  GetAllRewardsListDocument,
  useCreateRewardMutation,
} from "../../../graphql/types";

const AddReward = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const auth = useAuth();
  const RecipeClasses = useStylesRecipe();

  //styles
  const ContainersClasses = useStylesContainers();

  // react hook form
  const methods = useForm<RewardsProps>({
    defaultValues: {
      name__english: "",
      name__arabic: "",
      points: "",
      country: [],
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  //mutations&queries
  const [addReward, { loading: loadingAddReward }] = useCreateRewardMutation();

  const onSubmit = async (data: RewardsProps) => {
    try {
      await addReward({
        variables: {
          createRewardInput: {
            name: { EN: data.name__english, AR: data.name__arabic },
            pointsRequired: parseFloat(data.points),
            country: data?.country
              ?.map((el) => el?._id)
              .filter(Boolean) as string[],
          },
        },
        refetchQueries: [
          {
            query: GetAllRewardsListDocument,
          },
        ],
        onCompleted: () => {
          enqueueSnackbar("Reward succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/data/rewards/list_rewards");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  //render
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title={"Create Reward"}
          buttonText={"Create Reward"}
          cancelButtonLink="/data/rewards/list_rewards"
          loading={loadingAddReward}
        />
        <Box display="flex" flexDirection="column">
          <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={4}>
            <Box width="100%">
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={ContainersClasses.section__subtitle}>Name(EN)</p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder=" Name English"
                  sx={{ width: "100%" }}
                  {...register("name__english", {
                    required: "name is Mandatory",
                  })}
                />
                {errors?.name__english?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.name__english?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Countries</p>
                <Controller
                  name="country"
                  rules={{
                    required: "Select Country",
                  }}
                  control={control}
                  render={({
                    field: { onChange: Change, value, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        ref={ref}
                        id="country"
                        options={auth.countriesList ?? []}
                        multiple
                        value={dataWatch?.country}
                        getOptionLabel={(option) => option.name?.EN || ""}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value?._id
                        }
                        filterSelectedOptions
                        onChange={(_, data) => Change(data)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>{option.name?.EN}</li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Country" />
                        )}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
            </Box>
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>Name(AR)</p>
              <TextField
                id="demo-helper-text-misaligned"
                placeholder="Name Arabic"
                sx={{ width: "100%" }}
                {...register("name__arabic")}
              />
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Points</p>
                <TextField
                  sx={{ width: "100%" }}
                  {...register("points", {
                    required: " points is Mandatory",
                  })}
                />
                {errors?.points?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.points?.message}
                  </p>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};
export default AddReward;
