import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Delete } from "@mui/icons-material";
//components
import PageAddHeader from "../../pageAddHeader/pageAddHeader";
import DropZoneInput from "../../layout/commun/DropZoneInput";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../styles/Recipe__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
//utils
import { ComplainStatus } from "../Utils";
import { getMessageError } from "../../Utils";
//types
import { complain } from "../Types";
import { ApolloError } from "@apollo/client";
//mutations&queries
import {
  useCreateComplainMutation,
  useCreateComplainTagMutation,
  useDeleteComplainTagMutation,
  useGetDishByIdv2Query,
  useGetDishsWithPaginationQuery,
  useGetDriversQuery,
  useGetSpecificCustomerByIdQuery,
  useGetSpecificCustomerListQuery,
  useGetcomplainsTagsListQuery,
  useUpdateComplainTagMutation,
} from "../../../graphql/types";

const AddComplain = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state as {
    CustomerId: string | null;
    dishId: string | null;
  };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //state
  const [search, setSearch] = useState("");
  const [searchDish, setSearchdish] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newType, setNewtype] = useState<string>("");
  const [idTypeToBeDeleted, setIdTypeToBeDeleted] = useState<string | null>("");
  const [idTypeToBeUpdated, setIdTypeToBeUpdated] = useState<string | null>("");
  const [editedType, setEditedType] = useState("");
  const [openEditTypeDialog, setOpenEditTypeDialog] = useState(false);

  //style
  const ContainersClasses = useStylesContainers();
  const RecipeClasses = useStylesRecipe();
  const DialogClasses = useStylesDialog();
  const ButtonClasses = useStylesButtons();

  //react-hook-form
  const methods = useForm<complain>({
    defaultValues: {
      title: "",
      date: new Date(),
      description: "",
      type: null,
      status: null,
      customerIssue: false,
      dishIssue: false,
      driverIssue: false,
      photo: "",
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  //queries&mutations
  const dataDriver = useGetDriversQuery({ skip: !dataWatch.driverIssue });
  const Tags = useGetcomplainsTagsListQuery();
  const [createComplain, { loading }] = useCreateComplainMutation();
  const [CreateComplainType, { loading: createComplainTypeLoading }] =
    useCreateComplainTagMutation();
  const [removeComplainType, { loading: removeComplainTypeLoading }] =
    useDeleteComplainTagMutation();
  const [updateComplainType, { loading: updateComplainTypeLoading }] =
    useUpdateComplainTagMutation();
  const customersList = useGetSpecificCustomerListQuery({
    variables: {
      input: {
        page: 1,
        documentsPerPage: 10,
        nameCustomer: search,
      },
    },
    skip: !dataWatch.customerIssue,
  });
  const { data } = useGetDishByIdv2Query({
    variables: {
      dishId: params.dishId || "",
    },
    skip: !params.dishId,
    fetchPolicy: "no-cache",
  });

  const dataGetDish = useGetDishsWithPaginationQuery({
    variables: {
      input: {
        page: 1,
        documentsPerPage: 10,
        name: searchDish,
      },
    },
    skip: !dataWatch.dishIssue,
  });

  const dataCustomer = useGetSpecificCustomerByIdQuery({
    variables: {
      customerId: params?.CustomerId || "",
    },
    skip: !params?.CustomerId,
    fetchPolicy: "no-cache",
  });

  const customers = useMemo(
    () => customersList?.data?.getSpecificCustomerList.data || [],
    [customersList?.data]
  );

  const dish = useMemo(
    () => dataGetDish?.data?.getDishsWithPagination.data || [],
    [dataGetDish?.data]
  );

  const driver = useMemo(
    () => dataDriver?.data?.getDrivers || [],
    [dataDriver?.data]
  );

  const debounced = useCallback(
    _.debounce((event: string) => {
      setSearch(event);
    }, 800),
    []
  );

  const debouncedDish = useCallback(
    _.debounce((event: string) => {
      setSearchdish(event);
    }, 800),
    []
  );

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseEditTypeModal = () => {
    setOpenEditTypeDialog(false);
  };

  const handleDelete = (id: string) => {
    setOpenDeleteDialog(true);
    setIdTypeToBeDeleted(id);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenEditModal = (typeId: string, typeName: string) => {
    setEditedType(typeName);
    setIdTypeToBeUpdated(typeId);
    setOpenEditTypeDialog(true);
  };

  //filling the dish and customer autocomplete based on whether the user came from a customer profile or dish page
  useEffect(() => {
    if (params?.CustomerId) {
      setValue("customerIssue", true);
      if (dataCustomer.data?.getSpecificCustomerById) {
        setValue("customer", dataCustomer.data?.getSpecificCustomerById);
      }
    }
    if (params?.dishId) {
      setValue("dishIssue", true);
      setValue("dish", data?.getDishByIdv2);
    }
  }, [
    params?.CustomerId,
    params?.dishId,
    dataCustomer?.data?.getSpecificCustomerById,
    data?.getDishByIdv2,
  ]);

  const onSubmit: SubmitHandler<complain> = async (data) => {
    try {
      await createComplain({
        variables: {
          createComplainInput: {
            title: data.title,
            description: data.description,
            date: data.date,
            photo: data.photo,
            status: data.status,
            type: data.type?._id,
            customer: data.customer?._id,
            dish: data.dish?._id,
            driver: data.driver?._id,
            ticketId: data.ticket,
          },
        },
        onCompleted: () => {
          enqueueSnackbar("New Complain succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/admin/complains/list_complains");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleCreateType = async () => {
    if (newType) {
      try {
        await CreateComplainType({
          variables: {
            name: newType,
          },
          onCompleted: () => {
            Tags.refetch();
            enqueueSnackbar("New Complain Type successfully added", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
            setNewtype("");
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
    setOpen(false);
  };

  const handleRemoveType = async () => {
    setOpenDeleteDialog(false);
    if (openDeleteDialog) {
      try {
        await removeComplainType({
          variables: {
            id: idTypeToBeDeleted || "",
          },
          onCompleted: () => {
            Tags.refetch();
            enqueueSnackbar("Type successfully deleted", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setIdTypeToBeDeleted(null);
            setTimeout(() => closeSnackbar(), 5000);
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
    setValue("type", null);
  };

  const handleUpdateType = async () => {
    if (idTypeToBeUpdated) {
      try {
        await updateComplainType({
          variables: {
            id: idTypeToBeUpdated,
            name: editedType,
          },
          onCompleted: () => {
            Tags.refetch();
            enqueueSnackbar("Type successfully updated", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
            setValue("type", null);
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      } finally {
        handleCloseEditTypeModal();
      }
    }
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <PageAddHeader
              title={"Create Complaint"}
              buttonText={"Create Complaint"}
              cancelButtonLink="/admin/complains/list_complains"
              loading={loading}
            />
            <Box
              display="grid"
              width="100%"
              gridTemplateColumns="2fr 1fr"
              gap={4}
            >
              <Box
                display="grid"
                width="100%"
                gridTemplateColumns="1fr 1fr"
                gap={1}
              >
                <Box>
                  <p className={ContainersClasses.section__subtitle}>Title</p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Title"
                    fullWidth
                    {...register("title", {
                      required: "Title is Mandatory",
                    })}
                  />
                  {errors?.title?.message && (
                    <p className={RecipeClasses.recipe__error__helper}>
                      {errors?.title?.message}
                    </p>
                  )}
                </Box>
                <Box>
                  <p className={ContainersClasses.section__subtitle}>Date</p>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DesktopDatePicker
                      value={dataWatch.date}
                      inputFormat="DD/MM/YYYY"
                      disablePast={false}
                      disableFuture={false}
                      onChange={(newValue) => {
                        setValue("date", newValue || new Date());
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%" }}
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box>
                  <p className={ContainersClasses.section__subtitle}>
                    Description
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    multiline
                    placeholder="Description"
                    fullWidth
                    {...register("description", {
                      required: "Title is Mandatory",
                    })}
                  />
                  {errors?.description?.message && (
                    <p className={RecipeClasses.recipe__error__helper}>
                      {errors?.description?.message}
                    </p>
                  )}
                </Box>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>Type</p>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Controller
                      name="type"
                      control={control}
                      render={({
                        field: { onChange: Change, value, ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            ref={ref}
                            id="Type"
                            options={Tags.data?.getcomplainsTagsList || []}
                            value={dataWatch?.type}
                            getOptionLabel={(option) => option.name || ""}
                            filterSelectedOptions
                            onChange={(_, data) => Change(data)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <Box>
                                    <Checkbox
                                      color="secondary"
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.name}
                                  </Box>
                                  <Box>
                                    <IconButton
                                      onClick={() =>
                                        handleOpenEditModal(
                                          option._id || "",
                                          option.name || ""
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>

                                    <IconButton
                                      onClick={() =>
                                        handleDelete(option._id || "")
                                      }
                                    >
                                      <DeleteIcon color="error" />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Type"
                                sx={{ minWidth: 500 }}
                              />
                            )}
                          />
                          <p
                            className={ContainersClasses.section__error__helper}
                          >
                            {error?.message}
                          </p>
                        </>
                      )}
                    />
                    <Box
                      height={25}
                      width={25}
                      borderRadius={12}
                      bgcolor={"#000"}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      color="#fff"
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#C4C4C4",
                          transition: "0.3s",
                        },
                      }}
                      onClick={() => setOpen(true)}
                    >
                      +
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <p className={ContainersClasses.section__subtitle}>
                    Ticket Number
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Ticket Number"
                    fullWidth
                    {...register("ticket")}
                  />
                  {errors?.ticket?.message && (
                    <p className={RecipeClasses.recipe__error__helper}>
                      {errors?.ticket?.message}
                    </p>
                  )}
                </Box>
                <Box marginTop={6}>
                  <Controller
                    name="customerIssue"
                    control={control}
                    render={({ field: { onChange, ref } }) => {
                      return (
                        <FormControlLabel
                          checked={dataWatch?.customerIssue}
                          onChange={(_, value) => {
                            onChange(value);
                          }}
                          value="start"
                          control={<Checkbox color="secondary" />}
                          label="Customer Related"
                          labelPlacement="end"
                        />
                      );
                    }}
                  />
                  <Controller
                    name="dishIssue"
                    control={control}
                    render={({ field: { onChange, ref } }) => {
                      return (
                        <FormControlLabel
                          checked={dataWatch?.dishIssue}
                          onChange={(_, value) => {
                            onChange(value);
                          }}
                          value="start"
                          control={<Checkbox color="secondary" />}
                          label="Dish Related"
                          labelPlacement="end"
                        />
                      );
                    }}
                  />
                  <Controller
                    name="driverIssue"
                    control={control}
                    render={({ field: { onChange, ref } }) => {
                      return (
                        <FormControlLabel
                          checked={dataWatch?.driverIssue}
                          onChange={(_, value) => {
                            onChange(value);
                          }}
                          value="start"
                          control={<Checkbox color="secondary" />}
                          label="Driver Related"
                          labelPlacement="end"
                        />
                      );
                    }}
                  />
                </Box>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>Status</p>
                  <Controller
                    name="status"
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          ref={ref}
                          id="status"
                          options={ComplainStatus || []}
                          value={dataWatch?.status}
                          getOptionLabel={(option) => option || ""}
                          filterSelectedOptions
                          onChange={(_, data) => Change(data)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Status" />
                          )}
                        />
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
                {dataWatch.customerIssue && (
                  <Box width="100%">
                    <p className={ContainersClasses.section__subtitle}>
                      Customer
                    </p>
                    <Controller
                      name="customer"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            id="customer"
                            options={customers || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.fname} ${option.lname}`
                            }
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            filterSelectedOptions
                            onChange={(_, data) => onChange(data)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  color="secondary"
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {`${option.fname} ${option.lname}`}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Customer"
                                onChange={(event) =>
                                  debounced(event.target.value)
                                }
                              />
                            )}
                            filterOptions={(options, { inputValue }) => {
                              const normalizedInputValue = inputValue
                                .trim()
                                .replace(/\s+/g, " ")
                                .toLowerCase();
                              return options.filter((option) => {
                                const fullName =
                                  `${option.fname} ${option.lname}`
                                    .replace(/\s+/g, " ")
                                    .toLowerCase();
                                const phone = option.phone?.number ?? "";
                                return (
                                  fullName.includes(normalizedInputValue) ||
                                  phone.includes(normalizedInputValue)
                                );
                              });
                            }}
                          />
                          {error && (
                            <p
                              className={
                                ContainersClasses.section__error__helper
                              }
                            >
                              {error.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </Box>
                )}
                {dataWatch.dishIssue && (
                  <Box width="100%">
                    <p className={ContainersClasses.section__subtitle}>Dish</p>
                    <Controller
                      name="dish"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            id="dish"
                            options={dish || []}
                            value={value || null}
                            getOptionLabel={(option) => option.name?.EN || ""}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            filterSelectedOptions
                            onChange={(_, data) => onChange(data)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  color="secondary"
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name?.EN}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Dish"
                                onChange={(event) =>
                                  debouncedDish(event.target.value)
                                }
                              />
                            )}
                          />
                          {error && (
                            <p
                              className={
                                ContainersClasses.section__error__helper
                              }
                            >
                              {error.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </Box>
                )}
                {dataWatch.driverIssue && (
                  <Box width="100%">
                    <p className={ContainersClasses.section__subtitle}>
                      Driver
                    </p>
                    <Controller
                      name="driver"
                      control={control}
                      render={({
                        field: { onChange: Change, value, ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            ref={ref}
                            id="driver"
                            options={driver || []}
                            value={dataWatch?.driver}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            filterSelectedOptions
                            onChange={(_, data) => Change(data)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  color="secondary"
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Driver" />
                            )}
                          />
                          <p
                            className={ContainersClasses.section__error__helper}
                          >
                            {error?.message}
                          </p>
                        </>
                      )}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <p className={ContainersClasses.section__subtitle}>
                  Upload image
                </p>
                <Controller
                  name="photo"
                  control={control}
                  render={({ field: { onChange, ref } }) => {
                    return (
                      <DropZoneInput
                        onChange={(e) => {
                          onChange(e.target.files[0]);
                        }}
                        label="Drop File"
                        inputRef={ref}
                        name="photo"
                        errors={errors}
                        multiple={false}
                        disabled={false}
                        accept="image/*"
                        message="Recommended resolution for image 400px*400px"
                      />
                    );
                  }}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>

      {/* ADD COMPLAIN TYPE MODAL */}
      <Dialog
        open={open}
        onClose={handleCloseModal}
        className={DialogClasses.dialog_container_brand}
      >
        <DialogTitle className={DialogClasses.dialog_title}>
          Add new type
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="name"
            label="Enter new type"
            type="text"
            fullWidth
            variant="standard"
            value={newType}
            onChange={(e) => setNewtype(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            onClick={handleCreateType}
            variant="contained"
            className={ButtonClasses.button_submit_form}
            disabled={createComplainTypeLoading}
          >
            {createComplainTypeLoading && (
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "white" }}
              />
            )}
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* DELETE COMPLAIN TYPE MODAL */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteModal}
        className={DialogClasses.dialog_container_brand}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <Delete />
          <span className="alert_dialog_title_text">
            Delete Complain Type ?
          </span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to delete this Complain Type ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonClasses.GreyButton}
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </Button>
          <Button
            className={ButtonClasses.RedButton}
            onClick={handleRemoveType}
            color="primary"
            autoFocus
            disabled={removeComplainTypeLoading}
          >
            {removeComplainTypeLoading && (
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "white" }}
              />
            )}
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* UPDATE COMPLAIN TYPE MODAL */}
      <Dialog
        open={openEditTypeDialog}
        onClose={handleCloseEditTypeModal}
        className={DialogClasses.dialog_container_brand}
      >
        <DialogTitle className={DialogClasses.dialog_title}>
          Edit Brand
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="editedBrandName"
            label="Enter new brand name"
            type="text"
            fullWidth
            variant="standard"
            value={editedType}
            onChange={(e) => setEditedType(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditTypeModal}>Cancel</Button>
          <Button
            onClick={handleUpdateType}
            variant="contained"
            className={ButtonClasses.button_submit_form}
            disabled={updateComplainTypeLoading}
          >
            {updateComplainTypeLoading && (
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "white" }}
              />
            )}
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddComplain;
