import { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
//material ui
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FiSearch } from "react-icons/fi";
//style
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import {
  HeaderSearch,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../styles/Navigation__styles";
//components
import Table from "../../layout/commun/Table";
import {
  CustomerProfilePayload,
  useGetDesactivatedCustomerQuery,
} from "../../../graphql/types";
//utils
import { columns } from "./Utils";
import { convertNumberOfDaysEnum } from "../../customer/Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";

function DeactivatedCustomer() {
  const navigate = useNavigate();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");

  //styles
  const classes = useStylesMenu();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<CustomerProfilePayload | null>(null);
  const [search, setSearch] = useState<string>("");

  //queries&mutations
  const deactivatedCustomer = useGetDesactivatedCustomerQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        nameCustomer: search,
        sort: "CREATE_DESC",
      },
    },
    fetchPolicy: "network-only",
  });

  const desCustomers = useMemo(
    () => deactivatedCustomer.data?.getDesactivatedCustomer.data || [],
    [deactivatedCustomer.data]
  );

  const count = useMemo(
    () => deactivatedCustomer.data?.getDesactivatedCustomer.count || 0,
    [deactivatedCustomer.data]
  );

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderTableRows = () => {
    return desCustomers.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        id: item.ref,
        name: isAuthorizedToSeeProfile ? (
          <a
            style={{ textDecoration: "none" }}
            href={`/admin/customers/view_customer/${item?._id}`}
          >
            <Button>{`${item?.fname} ${item?.lname}`}</Button>
          </a>
        ) : (
          item.fname + " " + item.lname
        ),
        mobile: item?.phone?.prefix + " " + item?.phone?.number,
        email: item?.email,
        deactivationDate: moment(item.desactivatedAt).format("DD-MMM-YYYY"),
        deactivationReason: item?.reasonDesactivation,
        plan: (
          <>
            <Typography style={{ fontSize: 12 }}>
              {item.plan?.program?.name?.EN +
                ", " +
                convertNumberOfDaysEnum(item?.plan?.nbrOfDays || "FIVE_DAYS")}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {item?.plan?.meals?.length +
                " meals, " +
                item?.plan?.paymentPeriod}
            </Typography>
          </>
        ),
        actions: (
          <IconButton onClick={handleClickMenu(item)}>
            <MoreVertIcon />
          </IconButton>
        ),
      };
    });
  };

  const handleClickMenu =
    (item: CustomerProfilePayload) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToCustomerProfileHandler = () => {
    navigate(`/admin/customers/view_customer/${selected?._id}`);
  };

  const debounced = useCallback(
    _.debounce((event) => {
      setSearch(event.target.value);
    }, 800),
    []
  );

  return (
    <>
      <Box marginLeft={40} marginRight={40} marginTop={3} marginBottom={3}>
        <HeaderSearch>
          <SearchIconWrapper>
            <FiSearch />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search..."
            inputProps={{ "aria-label": "search" }}
            onChange={(event) => debounced(event)}
          />
        </HeaderSearch>
      </Box>

      <TableContainer>
        <Table
          columns={columns}
          loading={deactivatedCustomer.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Deactivated customers to display !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {isAuthorizedToSeeProfile && (
            <MenuItem
              onClick={goToCustomerProfileHandler}
              className={classes.menu_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Go to profile</span>
              </div>
            </MenuItem>
          )}
        </Menu>
      </div>
    </>
  );
}

export default DeactivatedCustomer;
