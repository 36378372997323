import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useSnackbar } from "notistack";

//types
import { NotificationProps } from "../types";
import { ApolloError } from "@apollo/client";
// material
import {
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  MenuItem,
  Select,
  IconButton,
  Dialog,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import DateFnsAdapter from "@mui/lab/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import { DropzoneArea } from "material-ui-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
//Styles
import {
  ContainerStyled,
  useStylesContainers,
} from "../../../../styles/Containers__styles";
import { useStylesSelect } from "../../../../styles/Select__styles";
import { useStylesRecipe } from "../../../../styles/Recipe__styles";
//components
import PageAddHeader from "../../../pageAddHeader/pageAddHeader";
import DropZoneInput from "../../../layout/commun/DropZoneInput";
//utils
import { typeOptions } from "../Utils";
import { getMessageError } from "../../../Utils";
//mutations&queries
import {
  NotificationType,
  useEditNotificationFromDashboardMutation,
  useGetNotificationByIdQuery,
  useGetTemplatesListLazyQuery,
} from "../../../../graphql/types";

const UpdateNotification = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const ContainersClasses = useStylesContainers();
  const SelectClasses = useStylesSelect();
  const RecipeClasses = useStylesRecipe();

  //state
  const [openDialogEn, setOpenDialogEn] = useState(false);
  const [openDialogAr, setOpenDialogAr] = useState(false);

  //mutations&queries
  const [UpdateNotification, { loading }] =
    useEditNotificationFromDashboardMutation();

  const { data } = useGetNotificationByIdQuery({
    variables: {
      input: id || "",
    },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  const Notification = useMemo(
    () => data?.getNotificationById || null,
    [data?.getNotificationById]
  );
  //react-hook-form
  const methods = useForm<NotificationProps>({
    defaultValues: {
      title__english: "",
      title__arabic: "",
      shortdescription__arabic: "",
      shortdescription__english: "",
      type: null,
      sendAt: null,
      picture: "",
      isPushNotification: false,
      bodyEn: "",
      bodyAr: "",
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  //useEffects
  useEffect(() => {
    if (Notification) {
      setValue("title__english", Notification.title?.EN || "");
      setValue("title__arabic", Notification.title?.AR || "");
      setValue("type", Notification.type || null);
      setValue(
        "shortdescription__english",
        Notification.shortDescription?.EN || ""
      );
      setValue(
        "shortdescription__arabic",
        Notification.shortDescription?.AR || ""
      );
      setValue("sendAt", Notification.sendAt || null);
      setValue("picture", Notification.photo || null);
      setValue("isPushNotification", Notification.isPushNotification || false);
      setValue("bodyEn", Notification.htmlBody?.EN || "");
      setValue("bodyAr", Notification.htmlBody?.AR || "");
    }
  }, [Notification]);

  const [getTemplates, { data: templatesData }] =
    useGetTemplatesListLazyQuery();

  useEffect(() => {
    if (dataWatch.type) {
      getTemplates({
        variables: {
          input: {
            page: 1,
            documentsPerPage: 100,
            type: dataWatch.type,
          },
        },
      });
    }
  }, [dataWatch.type]);

  const TemplatesList = useMemo(
    () => templatesData?.getTemplatesList.data || [],
    [templatesData?.getTemplatesList]
  );

  const handleRemoveFileEn = () => {
    setValue("bodyEn", "");
  };

  const handleRemoveFileAr = () => {
    setValue("bodyAr", "");
  };
  //functions
  const onSubmit: SubmitHandler<NotificationProps> = async (
    data: NotificationProps
  ) => {
    try {
      await UpdateNotification({
        variables: {
          input: {
            _id: id || "",
            title: {
              EN: data.title__english,
              AR: data.title__arabic,
            },
            shortDescription: {
              AR: data.shortdescription__arabic,
              EN: data.shortdescription__english,
            },
            type: data.type!,
            htmlBody: {
              EN: data.bodyEn,
              AR: data.bodyAr,
            },
            sendAt: !data.isPushNotification
              ? moment(data.sendAt).add(1, "hour").toDate()
              : null,
            isPushNotification: data.isPushNotification,
            photo: data.picture,
          },
        },
        onCompleted: () => {
          const snackbarMessage = dataWatch.isPushNotification
            ? "Push Notification successfully updated"
            : "Notification successfully updated";
          enqueueSnackbar(snackbarMessage, {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/data/notifications/list_notifications");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title={
            dataWatch.isPushNotification
              ? "Update Push Notification"
              : "Update Notification"
          }
          buttonText={
            dataWatch.isPushNotification
              ? "Update Push Notification"
              : "Update Notification"
          }
          cancelButtonLink="/data/notifications/list_notifications"
          loading={loading}
        />

        <ContainerStyled>
          <Box
            display="grid"
            width="100%"
            gridTemplateColumns="2fr 1fr"
            gap={4}
          >
            <Box
              display="grid"
              width="100%"
              gridTemplateColumns="1fr 1fr"
              columnGap={2}
            >
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Title* <span>(English)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Title English"
                  fullWidth
                  {...register("title__english", {
                    required: "Name is Mandatory",
                  })}
                />
                {errors?.title__english?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.title__english?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Title<span>(Arabic)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Title Arabic"
                  fullWidth
                  {...register("title__arabic")}
                />
                {errors?.title__arabic?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.title__arabic?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Short Description <span>(English)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Short Description English"
                  fullWidth
                  {...register("shortdescription__english")}
                />
                {errors?.shortdescription__english?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.shortdescription__english?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Short Description <span>(Arabic)</span>
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Short Description Arabic"
                  fullWidth
                  {...register("shortdescription__arabic")}
                />
                {errors?.shortdescription__arabic?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.shortdescription__arabic?.message}
                  </p>
                )}
              </Box>
              {/* SendAt */}
              {!dataWatch.isPushNotification && (
                <LocalizationProvider dateAdapter={DateFnsAdapter}>
                  <Box width="100%">
                    <p className={ContainersClasses.section__subtitle}>
                      SendAt
                    </p>
                    <Controller
                      name="sendAt"
                      control={control}
                      render={({ field }) => (
                        <DateTimePicker
                          value={field.value || new Date()}
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: "100%" }} />
                          )}
                        />
                      )}
                    />
                    <p className={ContainersClasses.section__error__helper}>
                      {errors?.sendAt?.message}
                    </p>
                  </Box>
                </LocalizationProvider>
              )}
              {/* type */}
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Type</p>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Select
                      {...rest}
                      sx={{ width: "100%" }}
                      value={dataWatch.type}
                      onChange={(event) =>
                        onChange(event.target.value as NotificationType)
                      }
                    >
                      {typeOptions.map((option) => (
                        <MenuItem
                          key={option.menuValue}
                          value={option.menuValue}
                          className={
                            dataWatch.type === option.menuValue
                              ? clsx(
                                  SelectClasses.option_item,
                                  SelectClasses.option_item_selected
                                )
                              : SelectClasses.option_item
                          }
                        >
                          {option.menuText}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <p className={ContainersClasses.section__error__helper}>
                  {errors?.type?.message}
                </p>
              </Box>
              <Box>
                <p className={ContainersClasses.section__subtitle}>
                  Templates Title
                </p>
                <Autocomplete
                  key={dataWatch.type}
                  options={TemplatesList}
                  getOptionLabel={(option) => option.title?.EN || ""}
                  onChange={(_, data) => {
                    setValue("bodyEn", data?.body?.EN || "");
                    setValue("bodyAr", data?.body?.AR || "");
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="start">
              <p className={ContainersClasses.section__subtitle}>
                Upload Image
              </p>

              <Controller
                name="picture"
                control={control}
                render={({ field: { onChange, ref } }) => {
                  return (
                    <DropZoneInput
                      onChange={(e) => {
                        onChange(e.target.files[0]);
                      }}
                      label="Drop File"
                      inputRef={ref}
                      name="picture"
                      errors={errors}
                      multiple={false}
                      disabled={false}
                      accept="image/*"
                      message="Recommended resolution for image 400px*400px"
                    />
                  );
                }}
              />

              <Controller
                name="isPushNotification"
                control={control}
                render={() => (
                  <FormControlLabel
                    value="start"
                    label="Is Push Notification"
                    control={
                      <Checkbox
                        color="secondary"
                        checked={dataWatch.isPushNotification}
                        onChange={() => {}}
                      />
                    }
                    sx={{
                      marginTop: 3,
                    }}
                  />
                )}
              />
            </Box>
            <Box
              display="grid"
              width="100%"
              gridTemplateColumns="1fr 1fr"
              columnGap={2}
            >
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Template Body(EN)
                </p>
                {dataWatch.bodyEn ? (
                  <Box>
                    <IconButton
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                        marginRight: "20px",
                        marginLeft: "20px",
                      }}
                      onClick={handleRemoveFileEn}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setOpenDialogEn(true)}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <DropzoneArea
                    onChange={(files) => {
                      if (files && files.length) {
                        const reader = new FileReader();
                        reader.onload = function () {
                          const result = reader.result as string;
                          setValue("bodyEn", result);
                        };
                        reader.readAsText(files[0]);
                      }
                    }}
                    dropzoneText="Drag & Drop your HTML file here"
                    acceptedFiles={[".html"]}
                  />
                )}

                <Dialog open={openDialogEn}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenDialogEn(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <iframe
                    srcDoc={dataWatch.bodyEn}
                    style={{ width: "1000px", height: "700px" }}
                  />
                </Dialog>
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Template Body(AR)
                </p>
                {dataWatch.bodyAr ? (
                  <Box>
                    <IconButton
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                        marginRight: "20px",
                        marginLeft: "20px",
                      }}
                      onClick={handleRemoveFileAr}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setOpenDialogAr(true)}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <DropzoneArea
                    onChange={(files) => {
                      if (files && files.length) {
                        const reader = new FileReader();
                        reader.onload = function () {
                          const result = reader.result as string;
                          setValue("bodyAr", result);
                        };
                        reader.readAsText(files[0]);
                      }
                    }}
                    dropzoneText="Drag & Drop your HTML file here"
                    acceptedFiles={[".html"]}
                  />
                )}

                <Dialog open={openDialogAr}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenDialogAr(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <iframe
                    srcDoc={dataWatch.bodyAr}
                    style={{ width: "1000px", height: "700px" }}
                  />
                </Dialog>
              </Box>
            </Box>
          </Box>
        </ContainerStyled>
      </form>
    </FormProvider>
  );
};
export default UpdateNotification;
