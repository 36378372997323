import { PackPricesPayload } from "../../../graphql/types";

export const calculatePackCost = (
  daysPerWeek: string,
  duration: "WEEKLY" | "MONTHLY" | "THREE_MONTHS",
  prices?: PackPricesPayload
) => {
  switch (daysPerWeek) {
    case "FIVE_DAYS":
      {
        if (duration === "WEEKLY") {
          return prices?.weekly?.five;
        }
        if (duration === "MONTHLY") {
          return prices?.monthly?.five;
        }
        if (duration === "THREE_MONTHS") {
          return prices?.threeMonths?.five;
        }
      }
      break;
    case "SEVEN_DAYS":
      {
        if (duration === "WEEKLY") {
          return prices?.weekly?.seven;
        }
        if (duration === "MONTHLY") {
          return prices?.monthly?.seven;
        }
        if (duration === "THREE_MONTHS") {
          return prices?.threeMonths?.seven;
        }
      }
      break;
    case "SIX_DAYS":
      {
        if (duration === "WEEKLY") {
          return prices?.weekly?.six;
        }
        if (duration === "MONTHLY") {
          return prices?.monthly?.six;
        }
        if (duration === "THREE_MONTHS") {
          return prices?.threeMonths?.six;
        }
      }
      break;

    default:
      break;
  }
};
