import { useAuth } from "../../contextAPI";

function DashIcon() {
  const auth = useAuth();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={auth.countryCode === "SA" ? "#008000" : "#fff"}
        d="M2.857 0A2.857 2.857 0 000 2.857v2.857a2.857 2.857 0 002.857 2.857h2.857a2.857 2.857 0 002.857-2.857V2.857A2.857 2.857 0 005.714 0H2.857zm0 11.429A2.857 2.857 0 000 14.286v2.857A2.857 2.857 0 002.857 20h2.857a2.857 2.857 0 002.857-2.857v-2.857a2.857 2.857 0 00-2.857-2.857H2.857zm8.572-8.572A2.857 2.857 0 0114.286 0h2.857A2.857 2.857 0 0120 2.857v2.857a2.857 2.857 0 01-2.857 2.857h-2.857a2.857 2.857 0 01-2.857-2.857V2.857z"
      ></path>
      <path
        fill="#232222"
        d="M12.265 12.265a2.857 2.857 0 00-.836 2.02v2.858A2.857 2.857 0 0014.286 20h2.857A2.857 2.857 0 0020 17.143v-2.857a2.857 2.857 0 00-2.857-2.857h-2.857c-.758 0-1.485.3-2.02.836z"
      ></path>
    </svg>
  );
}

export default DashIcon;
