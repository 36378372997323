import moment from "moment";
import { ColumnsProps } from "../layout/commun/Table";
export const CreateDaysForMonth = (date: string) => {
  const month = moment(date).startOf("month");
  const daysInMonth = month.daysInMonth();
  const days = [];
  for (let i = 0; i < daysInMonth; i++) {
    days.push(moment(month).add(i, "days").toDate());
  }
  return days;
};

export const CreateDaysForMonthBasedOnMonth = (month: number, year: number) => {
  const daysInMonth = moment(`${year}-${month}`, "YYYY-M").daysInMonth();
  const days = [];
  for (let i = 0; i < daysInMonth; i++) {
    days.push(moment(`${year}-${month}-${i + 1}`, "YYYY-M-D").toDate());
  }
  return days;
};

export function getDatesByYear(year: string, dates: string[]) {
  const filteredDates = dates.filter((date) => date.startsWith(year + "-"));
  return filteredDates;
}

export const columns: ColumnsProps = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Dishs Photo",
    accessor: "photo",
    cellProps: { align: "center", width: "30px" },
  },
  {
    header: "Dishs Name",
    accessor: "name",
  },
  {
    header: "Allergies",
    accessor: "allergies",
  },
  {
    header: "Rated",
    accessor: "rated",
  },
  {
    header: "Selected",
    accessor: "selected",
  },
  {
    header: "Complains",
    accessor: "complains",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

export const CheckIfDecemberIsLastMonth = (monthList: string[]) => {
  return monthList[monthList.length - 1]?.slice(-2) == "12";
};

export const ShouldAddYearBeDisabled = (
  monthList: string[],
  chosenProgram: any
) => {
  if (chosenProgram?.isLimited || CheckIfDecemberIsLastMonth(monthList)) {
    return false;
  } else {
    return true;
  }
};

export const getDisplayType = (
  type: "BREAKFAST" | "LUNCH" | "DINNER" | "SNACKS",
  display: any
) => {
  switch (type) {
    case "BREAKFAST":
      return display?.breakfast?.EN ? display.breakfast.EN : "BREAKFAST";
    case "LUNCH":
      return display?.lunch?.EN ? display.lunch.EN : "LUNCH";
    case "DINNER":
      return display?.dinner?.EN ? display.dinner.EN : "DINNER";
    case "SNACKS":
      return display?.snack?.EN ? display.snack.EN : "SNACKS";
    default:
      return null;
  }
};

export const ShouldPublishBeDisabled = (
  programId: string,
  loadingCreateProgramMenu: boolean,
  loadingPublishProgramMenu: boolean,
  isPublished: boolean,
  isLimited: boolean,
  warnings: string
) => {
  if (
    !programId ||
    loadingCreateProgramMenu ||
    loadingPublishProgramMenu ||
    isPublished
  ) {
    return true;
  }
  if (isLimited) {
    return false;
  }
  if (warnings) {
    return true;
  }

  return false;
};
