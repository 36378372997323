import { useCallback, useState } from "react";
import Typography from "@mui/material/Typography";
import { useFormContext, useWatch } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import { fileUploadXHR } from "../../../graphql/FileUpload";
//material-ui
import { Button, IconButton, Avatar } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import LinearProgress from "@mui/material/LinearProgress";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesForms } from "../../../styles/Forms__styles";
//icons
import UploadIcon from "../../../public/icons/UploadIcon";
import FoldersIllustration from "../../../public/icons/FoldersIllustration";
import Info from "../../../public/icons/Info";
import defaultImage from "../../../public/images/default.jpg";

const DropZoneInput = ({
  onChange,
  multiple,
  name,
  inputRef,
  disabled,
  accept,
  message,
}: {
  onChange: (...event: any[]) => void;
  label?: string;
  name: string;
  inputRef: any;
  errors: any;
  multiple?: boolean;
  disabled?: boolean;
  accept?: string;
  message?: string;
}) => {
  //state
  const [percentages, setPercentages] = useState<number | null>(null);
  const { control, setValue } = useFormContext();
  //styles
  const ButtonClasses = useStylesButtons();
  const FormClasses = useStylesForms();
  //react-hook-form
  const watchFile = useWatch({
    control,
    name: name,
  });
  //functions
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const reader = new FileReader();
      const file = acceptedFiles[0];
      reader.readAsDataURL(file);
      reader.onload = async () => {
        fileUploadXHR(file, (response: any, progress: number) => {
          setPercentages(progress);
          if (response?.url) {
            setValue(name, response.url);
          }
        });
      };
    },
    [name, setValue]
  );
  const { getRootProps, getInputProps } = useDropzone({
    multiple: multiple,
    disabled: disabled,
    accept: accept,
    onDrop,
  });

  //render
  return (
    <>
      {message && (
        <div className={FormClasses.message__dropzone}>
          <Info className={FormClasses.icon} />
          {message}
        </div>
      )}
      <div
        ref={inputRef}
        style={{
          border: "2px dashed rgb(161 161 161)",
          backgroundColor: "rgb(245 244 255)",
          padding: "20px",
          borderRadius: "5px",
          display: "flex",
        }}
        {...getRootProps()}
      >
        <FoldersIllustration />
        <div style={{ marginLeft: "40px" }}>
          <input {...getInputProps({ onChange })} />

          <div style={{ textAlign: "center" }}>
            <Typography
              width={180}
              align="center"
              sx={{ mt: "5px", mb: 2, color: "text.primary" }}
            >
              Drag & drop your images or
            </Typography>
            <Button
              variant="contained"
              onClick={() => {}}
              style={{ minWidth: "200px !important" }}
              className={clsx(ButtonClasses.button_dropzone)}
              startIcon={<UploadIcon />}
            >
              Select
            </Button>
          </div>
        </div>
      </div>

      {watchFile && (
        <div
          style={{
            position: "relative",
            width: "fit-content",
            marginTop: "25px",
            minHeight: "120px",
            maxHeight: "120px",
          }}
        >
          <IconButton
            onClick={() => setValue(name, "")}
            style={{
              position: "absolute",
              right: "-16px",
              top: "-16px",
              padding: "5px",
              backgroundColor: "#cbcbcb",
              zIndex: 1000,
            }}
          >
            <ClearIcon />
          </IconButton>
          <Avatar
            src={watchFile.length > 0 ? watchFile : defaultImage}
            variant="rounded"
            style={{ width: 120, height: 120, marginBottom: 4 }}
          />
          {percentages && (
            <LinearProgress variant="determinate" value={percentages} />
          )}
        </div>
      )}
    </>
  );
};

export default DropZoneInput;
