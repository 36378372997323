import React, { useState, useMemo } from "react";
import { useParams } from "react-router";
import moment from "moment";
import clsx from "clsx";
//material
import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import {
  FullContainer,
  useStylesCustomers,
} from "../../../styles/Customers__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import TabContentHeader from "../../TabContentHeader/TabContentHeader";
import Table from "../../layout/commun/Table";
import Status from "../../Status/Status";
//utils
import { payColumns, handleStatus, getCustomerLoggerToExport } from "../Utils";
import { handleNbrDays, handlePaymentPeriod } from "../../Utils";
//queries and mutations
import {
  useGetCustomerHistoryPlanQuery,
  CustomerPlanPayload,
  useGetCustomerHistoryPlanByIdQuery,
  PaymentPeriod,
} from "../../../graphql/types";
//context
import { useAuth } from "../../../contextAPI";
import ExcelIcon from "../../../public/icons/ExcelIcon";

const CustomerPayment = () => {
  let auth = useAuth();
  //state
  const params = useParams();
  const [rightContainerMode, setRightContainerMode] = useState<null | string>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<CustomerPlanPayload | null>(null);
  const [spinning, setSpinning] = useState<boolean>(false);

  //styles
  const ButtonClasses = useStylesButtons();
  const CustomerClasses = useStylesCustomers();
  const classes = useStylesMenu();

  //queries
  const { data, loading } = useGetCustomerHistoryPlanQuery({
    variables: {
      customerId: params.id || "",
    },
    fetchPolicy: "network-only",
    skip: !params.id,
  });

  const customerPlans = useMemo(
    () => data?.getCustomerHistoryPlan || null,
    [data?.getCustomerHistoryPlan]
  );

  const { data: onePlan, loading: oneLoad } =
    useGetCustomerHistoryPlanByIdQuery({
      variables: {
        planId: selected?._id || "",
      },
      skip: !rightContainerMode,
    });

  const customerOnePlan = useMemo(
    () => onePlan?.getCustomerHistoryPlanById || null,
    [onePlan?.getCustomerHistoryPlanById]
  );

  //functions
  const handleClickMenu =
    (item: CustomerPlanPayload) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDetails = () => {
    setAnchorEl(null);
    setRightContainerMode("view");
  };

  //render list
  const renderTableRows = () => {
    return (
      customerPlans?.map((el) => {
        const isLoading = selected?._id === el._id && loading;
        return {
          startDate: el?.startDate
            ? moment(el?.startDate).format("MMMM Do YYYY")
            : "--",
          endDate: el?.expiryDate
            ? moment(el?.expiryDate).format("MMMM Do YYYY")
            : "--",
          plan: el?.program?.name?.EN || "--",
          nbrOfBoxes: el?.extend
            ? (el?.nbrOfBoxes || "--") + " + " + (el?.extend || "")
            : el?.nbrOfBoxes || "--",
          duration: el?.paymentPeriod
            ? handlePaymentPeriod(el?.paymentPeriod as PaymentPeriod)
            : "--",
          status: el?.planCondition ? (
            <Status
              status={el?.planCondition}
              statusText={handleStatus(el?.planCondition)}
            />
          ) : (
            "--"
          ),
          days: el?.nbrOfDays ? handleNbrDays(el?.nbrOfDays) : "--",
          price:
            el?.price || el?.price === 0
              ? `${el?.price} ${auth.countryCurrency}`
              : "--",
          actions: isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <IconButton onClick={handleClickMenu(el)}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };
  const handleExport = async () => {
    setSpinning(true);
    await getCustomerLoggerToExport(params.id || "", auth.token || "");
    setSpinning(false);
  };

  //render
  return (
    <>
      <FullContainer>
        <TabContentHeader title={"History List"} />
        <TableContainer>
          <Table
            columns={payColumns}
            data={renderTableRows()}
            loading={loading}
            numberOfFakeRow={30}
            emptyMessage="No History found !"
          />
        </TableContainer>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          <MenuItem
            onClick={handleCloseDetails}
            className={classes.menu_container}
            disableRipple={true}
          >
            <div>
              <span className="menu_title">Details</span>
            </div>
          </MenuItem>
        </Menu>
        {rightContainerMode && (
          <div>
            <hr />
            <TabContentHeader
              title={"Plan Details"}
              buttonText={"Close"}
              buttonFnc={() => {
                setRightContainerMode(null);
              }}
            />
            {oneLoad ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              <Box>
                <div className={CustomerClasses.customer__subunit}>
                  <p className={CustomerClasses.customer__subtitle}>
                    Program Name
                  </p>
                  <p className={CustomerClasses.customer__subtext}>
                    {customerOnePlan?.program?.name?.EN}
                  </p>
                </div>
                <div className={CustomerClasses.customer__subunit}>
                  <p className={CustomerClasses.customer__subtitle}>
                    Start Date
                  </p>
                  <p className={CustomerClasses.customer__subtext}>
                    {moment(customerOnePlan?.startDate).format(
                      "dddd, MMMM Do YYYY"
                    )}
                  </p>
                </div>
                <div className={CustomerClasses.customer__subunit}>
                  <p className={CustomerClasses.customer__subtitle}>End Date</p>
                  <p className={CustomerClasses.customer__subtext}>
                    {moment(customerOnePlan?.expiryDate).format(
                      "dddd, MMMM Do YYYY"
                    )}
                  </p>
                </div>
                <div className={CustomerClasses.customer__subunit}>
                  <p className={CustomerClasses.customer__subtitle}>
                    Plan Price
                  </p>
                  <p
                    className={CustomerClasses.customer__subtext}
                  >{`${customerOnePlan?.price} ${auth.countryCurrency}`}</p>
                </div>
                <div className={CustomerClasses.customer__subunit}>
                  <p className={CustomerClasses.customer__subtitle}>
                    Payment Status
                  </p>
                  <Status
                    status={customerOnePlan?.planCondition}
                    statusText={customerOnePlan?.planCondition}
                  />
                </div>
              </Box>
            )}
          </div>
        )}

        <Button
          onClick={handleExport}
          className={ButtonClasses.button_export_excel}
          startIcon={<ExcelIcon />}
        >
          Export Log&nbsp;&nbsp;
          {spinning ? <CircularProgress size={14} color="inherit" /> : ""}
        </Button>
      </FullContainer>
    </>
  );
};

export default CustomerPayment;
