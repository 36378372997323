import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
//material ui
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
//styles
import { useStylesButtons } from "../../styles/Buttons__styles";
import { useStylesHeader } from "../../styles/Header__styles";

type PageAddHeaderProps = {
  // main button text
  title?: string;
  // main button text
  buttonText?: string | string[];
  // main button link
  buttonLink?: any | any[];
  // cancel button Link
  cancelButtonLink?: any;
  loading?: boolean;
};

const PageAddHeader: React.FC<PageAddHeaderProps> = ({
  title,
  buttonText,
  buttonLink,
  children,
  cancelButtonLink,
  loading,
}) => {
  const classesHeader = useStylesHeader();
  const ButtonClasses = useStylesButtons();
  const navigate = useNavigate();

  if (typeof buttonText === "string") {
    return (
      <div className={classesHeader.root}>
        <span className={clsx(classesHeader.title, classesHeader.title_gold)}>
          {title}
        </span>

        <Box>
          {cancelButtonLink && (
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
              className={clsx(ButtonClasses.button_upload_csv)}
              startIcon={children}
            >
              Cancel
            </Button>
          )}
          {buttonText && !buttonLink && (
            <Button
              variant="contained"
              className={clsx(ButtonClasses.button_open_list_page)}
              startIcon={children}
              type="submit"
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ color: "white", marginRight: 6 }}
                />
              )}
              {buttonText}
            </Button>
          )}
          {buttonText && buttonLink && (
            <Button
              className={clsx(ButtonClasses.button_open_list_page)}
              onClick={() => navigate(buttonLink || null)}
            >
              {buttonText}
            </Button>
          )}
        </Box>
      </div>
    );
  } else {
    return (
      <div className={classesHeader.root}>
        <span className={clsx(classesHeader.title, classesHeader.title_gold)}>
          {title}
        </span>
        <Box>
          {buttonText!.map((btext, index) => (
            <Button
              key={btext}
              className={clsx(ButtonClasses.button_open_list_page)}
              onClick={() => navigate(buttonLink[index] || null)}
            >
              {btext}
            </Button>
          ))}
        </Box>
      </div>
    );
  }
};

export default PageAddHeader;
