import { useState, useMemo } from "react";
import moment from "moment";
//material ui
import { Avatar } from "@mui/material";
//styles
import { TableContainer } from "../../../styles/Table__styles";
//components
import Table from "../../layout/commun/Table";
//queries&mutations
import { useGetAutomatedNotificationsQuery } from "../../../graphql/types";
//Utils
import { AutomatedNotificationsColumns } from "./Utils";

const AutomatedNotificationsList = () => {
  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);

  //quries
  const AutomatedNotificationData = useGetAutomatedNotificationsQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
      },
    },
    fetchPolicy: "no-cache",
  });

  const notificationList = useMemo(
    () => AutomatedNotificationData.data?.getAutomatedNotifications.data || [],
    [AutomatedNotificationData.data]
  );

  const count = useMemo(
    () => AutomatedNotificationData.data?.getAutomatedNotifications.count || 0,
    [AutomatedNotificationData.data]
  );

  //functions
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const renderTableRows = () => {
    return (
      notificationList.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          photo: (
            <Avatar
              alt={el?.title?.EN!}
              src={el.photo!}
              variant="rounded"
              style={{ width: 55, height: 55 }}
            />
          ),
          title: el.title?.EN,
          shortDescription: el.shortDescription?.EN,
          htmlBody: el?.htmlBody?.EN,
          DestinationCount: el.destinationCount,
          sendAt: moment(el?.sendAt).format("DD/MM/YYYY"),
        };
      }) || []
    );
  };

  return (
    <>
      <TableContainer>
        <Table
          columns={AutomatedNotificationsColumns}
          data={renderTableRows()}
          emptyMessage="No notifications found !"
          loading={AutomatedNotificationData.loading}
          numberOfFakeRow={30}
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
    </>
  );
};

export default AutomatedNotificationsList;
