import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "Si No",
    accessor: "ref",
  },
  {
    header: "UserName",
    accessor: "userName",
  },
  {
    header: "Promo Code",
    accessor: "promoCode",
  },
  {
    header: "Phone Number",
    accessor: "phone",
  },
  {
    header: "Used By",
    accessor: "usedBy",
  },
  {
    header: "Joined At",
    accessor: "joinedAt",
  },
  {
    header: "Last Date Used",
    accessor: "lastDateUsed",
  },
  {
    header: "Total Value",
    accessor: "value",
  },
];
