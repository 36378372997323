import { URLFILE } from "../../utils/config";
import { saveAs } from "file-saver";
import moment from "moment";

export const generateRapport = async (
  token: string,
  mode:
    | "BOXES_REPORT"
    | "DISH_SELECTION_REPORT"
    | "ACTIVE_FROZEN_REPORT"
    | "OUT_OF_RANGE_REPORT"
    | "TRAINER_REPORT"
    | "AREA_REPORT"
    | "SALES_REPORT"
    | "DRIVERS_APP_REPORT"
    | "GROCERY_REPORT"
    | "COMPLAINTS_REPORT",
  startDate: Date | null,
  endDate?: Date | null,
  deliveryTime?: "MORNING" | "EVENING" | "ALL" | null,
  driverId?: string | null
) => {
  let href = "";
  let reportName = "";
  let format = "";

  if (mode === "BOXES_REPORT") {
    href = `${URLFILE}/report/boxes/${startDate?.toISOString()}`;
    reportName = "Boxes Report";
    format = "DD/MM/YYYY dddd";
  } else if (mode === "DISH_SELECTION_REPORT") {
    href = `${URLFILE}/report/selection/${
      moment(startDate).month() + 1
    }/${moment(startDate).year()}`;
    reportName = "Dish Selections Report";
    format = "MM/YYYY";
  } else if (mode === "ACTIVE_FROZEN_REPORT") {
    href = `${URLFILE}/report/customers`;
    reportName = "Active Frozen Report";
  } else if (mode === "OUT_OF_RANGE_REPORT") {
    href = `${URLFILE}/report/outOfRange`;
    reportName = "Out of Range Report";
  } else if (mode === "TRAINER_REPORT") {
    href = `${URLFILE}/promoCode/codes/${startDate}/${endDate}`;
    reportName = "Trainer Report";
    format = "DD/MM/YYYY dddd";
  } else if (mode === "SALES_REPORT") {
    href = `${URLFILE}/report/sales/${startDate}/${endDate}`;
    reportName = "Sales Report";
    format = "DD/MM/YYYY dddd";
  } else if (mode === "COMPLAINTS_REPORT") {
    href = `${URLFILE}/complains/list/${startDate ? startDate : undefined}/${
      endDate ? endDate : undefined
    }`;
    reportName = "Complaints Report";
    format = "DD/MM/YYYY dddd";
  } else if (mode === "DRIVERS_APP_REPORT") {
    href = `${URLFILE}/boxes/${startDate?.toString()}/${
      deliveryTime ? deliveryTime : "ALL"
    }/${driverId ? driverId : "ALL"}`;
    reportName = "Drivers APP Report";
    format = "DD/MM/YYYY dddd";
  } else if (mode === "GROCERY_REPORT") {
    href = `${URLFILE}/report/grocery/${startDate}/${endDate}`;
    reportName = "Grocery Report";
    format = "DD/MM/YYYY dddd";
  } else {
    href = `${URLFILE}/report/area`;
    reportName = "Area Report";
    format = "DD/MM/YYYY dddd";
  }
  const authToken = token;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  await fetch(href, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((blobData) => {
      saveAs(
        blobData,
        `${reportName} ` +
          (mode != "ACTIVE_FROZEN_REPORT" &&
          mode != "OUT_OF_RANGE_REPORT" &&
          mode != "TRAINER_REPORT" &&
          mode != "SALES_REPORT" &&
          mode != "COMPLAINTS_REPORT" &&
          mode != "AREA_REPORT" &&
          mode != "DRIVERS_APP_REPORT"
            ? moment(startDate).toISOString()
            : "") +
          " generated on " +
          moment().format("DD/MM/YYYY dddd") +
          ".xlsx"
      );
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

// Generate Grocery report

export async function generateGroceryReport(
  token: string,
  startDate: Date,
  endDate: Date
) {
  const authToken = token;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  try {
    const response = await fetch(
      `${URLFILE}/report/grocery/${startDate}/${endDate}`,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error generating grocery report:", error);
  }
}
