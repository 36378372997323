import clsx from "clsx";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  useWatch,
} from "react-hook-form";
import {
  Box,
  TextField,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
} from "@mui/material";
//components
import PageAddHeader from "../../pageAddHeader/pageAddHeader";
import Info from "../../../public/icons/Info";
import PhoneInput from "../../layout/PhoneInput";
//styles
import { useStylesSelect } from "../../../styles/Select__styles";
import { useStylesLeads } from "../../../styles/Leads__styles";
import {
  ContainerFullWidth,
  ContainerLeft,
  ContainerRight,
  LeftElement,
  RightElement,
  useStylesContainers,
} from "../../../styles/Containers__styles";
//utils
import { sourceOptions, leadSituationOptions } from "../Utils";
import { countries } from "../../../staticData/staticData";
import { useCountryPrefix } from "../../../utils/Hooks/useCountryPrefix";
import { getMessageError } from "../../Utils";
//querys and mutations
import {
  GetLeadsDocument,
  LeadStatus,
  SocialMedia,
  useCreateLeadMutation,
} from "../../../graphql/types";
//types
import { LeadProps } from "../Types";
import { ApolloError } from "@apollo/client";

const AddLead = () => {
  //state
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const ContainersClasses = useStylesContainers();
  const SelectClasses = useStylesSelect();
  const LeadsClasses = useStylesLeads();
  //queries and mutations
  const [createLead, dataCreateLead] = useCreateLeadMutation();
  //HOOKS
  const countryPrefix = useCountryPrefix();
  //react hook form
  const methods = useForm<LeadProps>({
    defaultValues: {
      first__name: "",
      last__name: "",
      phone__number: "",
      prefix__phone__number: countryPrefix?.callingCode,
      date__appointment: "",
      time__appointment: "",
      lead__status: null,
      friend__name: "",
      friend__number: "",
      friend__prefix: countryPrefix?.callingCode,
      email: "",
      source: null,
      gender: "MALE",
      nationality: "",
      note: "",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  const NationalityWatch = useWatch({
    control,
    name: "nationality",
  });
  const leadSituationWatch = useWatch({
    control,
    name: "lead__status",
  });
  const sourceWatch = useWatch({
    control,
    name: "source",
  });

  //functions
  const onSubmit: SubmitHandler<LeadProps> = async (data) => {
    try {
      const phone = data.phone__number.replace(/\s/g, "");
      await createLead({
        variables: {
          createLeadInput: {
            fname: data?.first__name,
            lname: data?.last__name,
            phone: {
              prefix: `+${data?.prefix__phone__number}`,
              number: phone,
            },
            leadStatus: data?.lead__status ? data?.lead__status : null,
            source: {
              socialMedia: data?.source,
              friend: {
                name: data?.friend__name,
                phone: {
                  prefix: countryPrefix?.callingCode || "",
                  number: data?.friend__number,
                },
              },
            },
            email: data?.email,
            gender: data?.gender,
            nationality: data?.nationality,
            note: data?.note,
          },
        },
        refetchQueries: [
          {
            query: GetLeadsDocument,
            variables: { input: { page: 1, documentsPerPage: 10 } },
          },
        ],
      });
      enqueueSnackbar("Lead succuessfully added", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      navigate("/admin/leads/list_leads");
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title="Create Lead"
          buttonText="Create Lead"
          // buttonLink="/"
          cancelButtonLink="/admin/leads/list_leads"
          loading={dataCreateLead.loading}
        />
        <ContainerFullWidth>
          <ContainerLeft>
            <LeftElement>
              <p className={clsx(ContainersClasses.section__title)}>
                Personal Informations
              </p>
              <Box className={clsx(ContainersClasses.section__container)}>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    First Name*
                  </p>
                  <TextField
                    placeholder="First Name"
                    sx={{ width: "100%" }}
                    {...register("first__name", {
                      required: "Enter Lead First Name",
                      minLength: {
                        value: 2,
                        message: "Please enter a least 2 characters",
                      },
                    })}
                  />
                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {errors?.first__name?.message}
                  </p>
                </Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Last Name*
                  </p>
                  <TextField
                    placeholder="Last Name"
                    sx={{ width: "100%" }}
                    {...register("last__name", {
                      required: "Enter Lead Last Name",
                      minLength: {
                        value: 2,
                        message: "Please enter a least 2 characters",
                      },
                    })}
                  />
                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {errors?.last__name?.message}
                  </p>
                </Box>
              </Box>

              <Box className={clsx(ContainersClasses.section__container)}>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Email
                  </p>
                  <TextField
                    placeholder="Email"
                    sx={{ width: "100%" }}
                    {...register("email", {
                      required: "Enter Lead Email",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: "Enter a valid Email",
                      },
                    })}
                  />

                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {errors?.email?.message}
                  </p>
                </Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Phone Number*
                  </p>
                  <PhoneInput
                    labelFormPrefix="prefix__phone__number"
                    labelFormNumber="phone__number"
                    placeholder="Phone Number"
                  />
                </Box>
              </Box>
              <Box className={clsx(ContainersClasses.section__container)}>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Nationality
                  </p>
                  <Controller
                    name="nationality"
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        {...rest}
                        freeSolo
                        options={countries}
                        value={NationalityWatch}
                        disableCloseOnSelect
                        onChange={(_, data) => Change(data)}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputRef={ref}
                            variant="outlined"
                            sx={{ width: "100%" }}
                            placeholder="Nationality"
                          />
                        )}
                      />
                    )}
                  />
                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {errors?.nationality?.message}
                  </p>
                </Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Gender
                  </p>
                  <Controller
                    name="gender"
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                    }) => (
                      <RadioGroup row>
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                          onClick={() => setValue("gender", "MALE")}
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"
                          onClick={() => setValue("gender", "FEMALE")}
                        />
                      </RadioGroup>
                    )}
                  />
                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {errors?.gender?.message}
                  </p>
                </Box>
              </Box>
            </LeftElement>
            <LeftElement>
              <p className={clsx(ContainersClasses.section__title)}>Note</p>
              <Box>
                <TextField
                  multiline
                  rows={10}
                  sx={{ width: "100%" }}
                  {...register("note")}
                />
              </Box>
              <Box className={LeadsClasses.infos_container}>
                <Info />
                <p className={clsx(LeadsClasses.info_text)}>
                  Add notes if the lead wants to discuss something in particular
                  with the dietitian or to list the reason of not being
                  converted.
                </p>
              </Box>
            </LeftElement>
          </ContainerLeft>
          <ContainerRight>
            <RightElement>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Lead Status
                </p>
                <Controller
                  name="lead__status"
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <Select
                      {...rest}
                      sx={{ width: "100%" }}
                      className={LeadsClasses.select_container}
                      onChange={(event) =>
                        onChange(event.target.value as LeadStatus)
                      }
                    >
                      {leadSituationOptions.map((option) => (
                        <MenuItem
                          key={option.menuValue}
                          value={option.menuValue}
                          className={
                            leadSituationWatch === option.menuValue
                              ? clsx(
                                  SelectClasses.option_item,
                                  SelectClasses.option_item_selected
                                )
                              : SelectClasses.option_item
                          }
                        >
                          {option.menuText}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />

                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.lead__status?.message}
                </p>
              </Box>
            </RightElement>
            <RightElement>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Lead Source
                </p>
                <Controller
                  name="source"
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <Select
                      sx={{ width: "100%" }}
                      className={LeadsClasses.select_container}
                      onChange={(event) =>
                        onChange(event.target.value as SocialMedia)
                      }
                    >
                      {sourceOptions.map((option) => (
                        <MenuItem
                          key={option.menuValue}
                          value={option.menuValue}
                          className={
                            sourceWatch === option.menuValue
                              ? clsx(
                                  SelectClasses.option_item,
                                  SelectClasses.option_item_selected
                                )
                              : SelectClasses.option_item
                          }
                        >
                          {option.menuText}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />

                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.source?.message}
                </p>
              </Box>
              {sourceWatch === "REFERRAL" && (
                <>
                  <Box>
                    <p className={clsx(ContainersClasses.section__subtitle)}>
                      Friend Recommendation
                    </p>
                    <TextField
                      placeholder="Friend Name"
                      sx={{ width: "100%" }}
                      {...register("friend__name", {
                        minLength: {
                          value: 2,
                          message: "Please enter a least 2 characters",
                        },
                      })}
                    />
                    <p
                      className={clsx(ContainersClasses.section__error__helper)}
                    >
                      {errors?.friend__name?.message}
                    </p>
                  </Box>
                  <Box>
                    <TextField
                      placeholder="Friend Phone Number"
                      sx={{ width: "100%" }}
                      {...register("friend__number", {
                        pattern: {
                          value: /^\d*$/,
                          message: "Enter a valid phone number",
                        },
                      })}
                    />
                  </Box>
                </>
              )}
            </RightElement>
          </ContainerRight>
        </ContainerFullWidth>
      </form>
    </FormProvider>
  );
};

export default AddLead;
