import { useState } from "react";
//material ui
import { Box, Button } from "@mui/material";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
//utils
import { generateRapport } from "../Utils";
//context
import { useAuth } from "../../../contextAPI";

const OutOfRangeReport = () => {
  let auth = useAuth();
  //style
  const ButtonClasses = useStylesButtons();

  //state
  const [loading, setLoading] = useState<boolean>(false);

  //functions
  const onGenerateHandler = async () => {
    setLoading(true);
    try {
      await generateRapport(
        auth.token || "",
        "OUT_OF_RANGE_REPORT",
        new Date()
      );
    } catch (error) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={350}
    >
      <Box
        width="40%"
        height="70%"
        bgcolor="#ffffff73"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection="column"
        borderRadius={5}
        border={1}
        borderColor="#e7e7e773"
      >
        <Box
          fontFamily="Poppins"
          fontSize={20}
          fontWeight="600"
          alignSelf="flex-start"
          marginLeft="15%"
        >
          Out of Range Report
        </Box>

        <Box
          width="70%"
          height={100}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            type="submit"
            disabled={loading}
            onClick={onGenerateHandler}
            sx={{ width: "100%", height: "50%" }}
          >
            Generate Report
          </Button>
          <Box
            fontFamily="Poppins"
            fontSize={10}
            fontWeight="500"
            alignSelf="flex-start"
          >
            Use this table to generate the list of customers which have calories
            out of range that their plan provides .
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OutOfRangeReport;
