import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
import moment from "moment";
//material-ui
import {
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  Chip,
  CircularProgress,
  Avatar,
  Box,
  Rating,
  Switch,
  Typography,
} from "@mui/material";
import Delete from "../../../public/icons/delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
//Utils
import { SIDE_FILTER_OPTIONS_DISHES, columns } from "./Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { getMessageError } from "../../Utils";
import { ComplainsColumn, DialogTitleProps } from "../Utils";
import { ComplainStatus } from "../../complains/Utils";
//components
import Table from "../../layout/commun/Table";
import FilterHeader from "../../pageHeader/FilterHeader";
import CustomSplitButton from "../../reusable/SplitButton/CustomSpiltButton";
//mutation & querys
import {
  ComplainsStatus,
  DishPayload,
  GetComplainsListDocument,
  GetDishsWithPaginationDocument,
  LunchMeal,
  useActiveDeactivateDishMutation,
  useDeleteDishMutation,
  useGetComplainsListLazyQuery,
  useGetDishsWithPaginationLazyQuery,
  useGetDishsWithPaginationQuery,
  useUpdateComplainStatusMutation,
} from "../../../graphql/types";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesForms } from "../../../styles/Forms__styles";
//types
import { ApolloError } from "@apollo/client";

const ListDish = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { searchKey } = useParams();
  const isAuthorizedToSeeDetails = useIsAuthorized("FOOD_DISHS_DETAILS");
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");

  //state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<DishPayload | null>(null);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(() => {
    const storedPage = localStorage.getItem("page");
    return storedPage ? +storedPage : 1;
  });
  const [rowsPerPage, setRowsPerPage] = useState<number>(() => {
    const storedRowsPerPage = localStorage.getItem("rowsPerPage");
    return storedRowsPerPage ? +storedRowsPerPage : 30;
  });
  const [idsProgram, setIdsProgram] = useState<string[]>([]);
  const [dishSuitability, setDishSuitability] = useState<LunchMeal[]>([]);
  const [rating, setRating] = useState<number | null>(null);
  const [openComplainsModal, setOpenComplainsModal] = useState<boolean>(false);

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();
  const FormsClasses = useStylesForms();
  const ButtonClasses = useStylesButtons();

  //queries and muations
  const [updateStatus] = useUpdateComplainStatusMutation();
  const dataGetDish = useGetDishsWithPaginationQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        name: search,
        programs: idsProgram,
        dishSuitability: dishSuitability,
        minRating: rating ? 0 : null,
        maxRating: rating ? +rating : null,
        withStats: true,
      },
    },
    fetchPolicy: "network-only",
  });
  const [getComplains, { data: complains, loading }] =
    useGetComplainsListLazyQuery({
      fetchPolicy: "no-cache",
    });

  const dishs = useMemo(
    () => dataGetDish.data?.getDishsWithPagination.data || [],
    [dataGetDish.data]
  );

  const count = useMemo(
    () => dataGetDish.data?.getDishsWithPagination.count || 0,
    [dataGetDish.data]
  );

  const [getDishsData, { loading: getDishsLoading }] =
    useGetDishsWithPaginationLazyQuery();

  const handleExportExcel = async () => {
    await getDishsData({
      variables: {
        input: {
          page: 1,
          documentsPerPage: count,
          name: search,
          programs: idsProgram,
          dishSuitability: dishSuitability,
          minRating: rating ? 0 : null,
          maxRating: rating ? +rating : null,
        },
      },
    })
      .then((data) => {
        if (count != 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const fileExtension = ".xlsx";
          const filteredDishes = data?.data?.getDishsWithPagination?.data?.map(
            (el, index) => {
              return {
                id: (index + 1).toString(),
                name: el?.name?.EN,
              };
            }
          );
          const ws = XLSX.utils.json_to_sheet(filteredDishes || []);
          ws["!cols"] = [
            { wpx: 40 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 200 },
            { wpx: 120 },
            { wpx: 70 },
            { wpx: 80 },
            { wpx: 250 },
          ];
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const res = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            res,
            "list of dishes" +
              " generated on " +
              moment().format("DD/MM/YYYY dddd LT") +
              fileExtension
          );
          return "Done";
        }
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };

  const [deleteDish, dataDeleteDish] = useDeleteDishMutation();
  const [activateDesactivateDish] = useActiveDeactivateDishMutation();

  useEffect(() => {
    if (searchKey) {
      setSearch(searchKey);
    }
  }, [searchKey]);

  //functions
  const handleClickMenu =
    (item: DishPayload) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (selected) {
      navigate(`/food/dishes/update_dish/${selected._id}/${search}`);
    }
  };
  const handleCloseGoToComplaint = () => {
    setAnchorEl(null);
    navigate(`/admin/complains/add_complain`, {
      state: { CustomerId: null, dishId: selected?._id },
    });
  };

  const handleCloseDelete = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onDeleteRecipe = async () => {
    setOpen(false);
    if (selected) {
      try {
        await deleteDish({
          variables: { dishId: selected._id || "" },
          onCompleted: () => {
            dataGetDish.refetch();
            enqueueSnackbar("Dish succuessfully deleted", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setSelected(null);
          },
        });
      } catch (error) {
        setSelected(null);
        console.log("#error", error);
      }
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(1);
    localStorage.setItem("rowsPerPage", newRowsPerPage.toString());
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
    localStorage.setItem("page", newPage.toString());
  };

  const activateDesactivateDishHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    await activateDesactivateDish({
      variables: {
        input: {
          _id: id,
          isActive: event.target.checked,
        },
      },
      refetchQueries: [GetDishsWithPaginationDocument],
      onCompleted() {
        enqueueSnackbar("Dish status updated succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const setChosenRating = (
    e: React.SyntheticEvent<Element, Event>,
    value: number | null
  ) => {
    setRating(value);
  };

  const onComplaintsClickHandler = async (id: string) => {
    setOpenComplainsModal(true);
    await getComplains({
      variables: {
        getComplainsInput: {
          page: 1,
          documentPerPage: 10,
          dish: id,
        },
      },
    });
  };

  const handleSubmitChangeStatus = async (status: string, id: string) => {
    try {
      await updateStatus({
        variables: {
          updateComplainStatusInput: {
            _id: id,
            status: status as ComplainsStatus,
          },
        },
        refetchQueries: [GetComplainsListDocument],
        onCompleted: () => {
          setAnchorEl(null);
          enqueueSnackbar("Complain Status succuessfully Updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 10000);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  //render
  const renderTableRows = () => {
    return dishs.map((item, index) => {
      const isLoading = selected?._id === item._id && dataDeleteDish.loading;
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        id: item.ref || "--",
        photoUrl: (
          <Avatar
            alt={item?.name?.EN || ""}
            src={item?.pictureUrl || ""}
            variant="rounded"
            style={{ width: 50, height: 50, margin: "0 auto" }}
          />
        ),
        name: isAuthorizedToSeeDetails ? (
          <a
            style={{ textDecoration: "none" }}
            href={`/food/dishes/view_dish/${item._id}`}
          >
            <Button>{item?.name?.EN || ""}</Button>
          </a>
        ) : (
          item.name?.EN
        ),

        tags:
          item?.tags && item?.tags.length
            ? item?.tags
                .filter((_, index: number) => index < 3)
                .map((elem, i) => (
                  <React.Fragment key={elem}>
                    <Chip
                      key={elem}
                      label={elem.toUpperCase()}
                      variant="outlined"
                      className={FormsClasses.chips__tags}
                    />
                    {item?.tags && item?.tags?.length - 1 > 2 && i === 2 && (
                      <Chip
                        key={elem}
                        label={`+ ${item?.tags?.length}`}
                        variant="outlined"
                        className={FormsClasses.chips__tags}
                      />
                    )}
                  </React.Fragment>
                ))
            : "--",
        rate: (
          <Box>
            <Rating
              name="half-rating"
              defaultValue={
                item?.rating?.average ? Math.round(item.rating.average) : 0
              }
              precision={0.1}
              size="small"
              readOnly
            />
            <Box>({item?.rating?.count ? item.rating.count : 0})</Box>
          </Box>
        ),
        selected: item.selected,
        complaints:
          (item?.complains || 0) > 0 ? (
            <Button onClick={() => onComplaintsClickHandler(item._id || "")}>
              {item?.complains}
            </Button>
          ) : (
            item.complains
          ),
        status: (
          <Switch
            defaultChecked={item?.isActive || false}
            onChange={(event) =>
              activateDesactivateDishHandler(event, item._id || "")
            }
          />
        ),
        actions: isLoading ? (
          <CircularProgress size={20} color="secondary" />
        ) : (
          <IconButton onClick={handleClickMenu(item as DishPayload)}>
            <MoreVertIcon />
          </IconButton>
        ),
      };
    });
  };

  const renderComplainsTableRows = () => {
    return (
      complains?.getComplainsList.data?.map((el, index) => {
        return {
          index: index + 1,
          title: el.title,
          customer:
            isAuthorizedToSeeProfile && el.customer?.fname ? (
              <a
                style={{ textDecoration: "none" }}
                href={`/admin/customers/view_customer/${el?.customer?._id}`}
              >
                <Button>{`${el.customer?.fname} ${el.customer?.lname}`}</Button>
              </a>
            ) : el.customer?.fname ? (
              el.customer?.fname + " " + el.customer?.lname
            ) : (
              "----"
            ),
          date: moment(el?.date).format("DD/MM/YYYY"),
          type: el.type?.name,
          image: el.photo ? (
            <a href={el.photo} target="_blank" download>
              <Button
                variant="contained"
                size="medium"
                className={ButtonClasses.button_cancel_form}
              >
                Download
              </Button>
            </a>
          ) : (
            "---"
          ),
          status: (
            <CustomSplitButton
              defaultValue={el.status || "PENDING"}
              onStatuschange={handleSubmitChangeStatus}
              id={el._id || ""}
              optionsList={ComplainStatus}
            />
          ),
        };
      }) || []
    );
  };

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              height: "fit-content",
            }}
          >
            <CloseIcon
              style={{
                minHeight: "22px",
                height: "22px",
                width: "22px",
                marginBottom: "0px",
              }}
            />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <>
      <Box marginBottom={2}>
        <FilterHeader
          buttonLink={
            useIsAuthorized("FOOD_DISHS_CREATE")
              ? "/food/dishes/add_dish"
              : undefined
          }
          buttonText={
            useIsAuthorized("FOOD_DISHS_CREATE") ? "Create Dish" : undefined
          }
          setSearchValue={setSearch}
          showSearch={true}
          pageInfo="This is the list of all the dishes in our system"
          setIdsProgram={setIdsProgram}
          programIdData={idsProgram}
          filterConfig={SIDE_FILTER_OPTIONS_DISHES}
          hideProgram={false}
          hideDateAndTitle={true}
          loadExcel={getDishsLoading}
          generateExcel={handleExportExcel}
          dishSuitability={dishSuitability}
          setDishSuitability={setDishSuitability}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginBottom={2}
      >
        <Typography>Rating filter :</Typography>
        <Rating
          name="half-rating"
          defaultValue={0}
          precision={0.5}
          value={rating}
          onChange={setChosenRating}
          size="small"
        />
      </Box>
      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetDish.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Dishes found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("FOOD_DISHS_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Update</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("MANAGEMENT_COMPLAIN_CREATE") && (
            <MenuItem
              onClick={handleCloseGoToComplaint}
              className={classes.menu_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Create a complaint</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("FOOD_DISHS_DELETE") && (
            <MenuItem
              onClick={handleCloseDelete}
              className={classes.menu_delete_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>

        {/* DELETE DISH MODAL */}
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Dish ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Dish ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={onDeleteRecipe}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* LIST OF COMPLAINS*/}
      <Dialog
        open={openComplainsModal}
        onClose={() => setOpenComplainsModal(false)}
        className={DialogClasses.dialog_renew_container}
      >
        <BootstrapDialogTitle
          className={DialogClasses.alert_dialog_title}
          onClose={() => setOpenComplainsModal(false)}
        >
          <span
            className="confirm_dialog_title_text"
            style={{ alignSelf: "flex-start" }}
          >
            Complains for this dish
          </span>
        </BootstrapDialogTitle>
        <DialogContent>
          <TableContainer>
            <Table
              columns={ComplainsColumn}
              data={renderComplainsTableRows()}
              emptyMessage="No complains for this Dish !"
              loading={loading}
              numberOfFakeRow={10}
            />
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ListDish;
