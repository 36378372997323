import { Theme, lighten } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStylesReport = makeStyles((theme: Theme) => {
  return {
    section__subtitle: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      marginBottom: "5px",
    },
    option_item: {
      padding: "8px 10px",
      width: "100%",
      borderRadius: "6px !important",
      "&:hover": {
        backgroundColor: `${lighten(
          theme.palette.secondary.main,
          0.7
        )} !important`,
      },
      "& .menu_title": {
        fontWeight: `${500} !important`,
      },
    },
    option_item_selected: {
      color: `${theme.palette.secondary.main} !important`,
      backgroundColor: `${lighten(
        theme.palette.secondary.main,
        0.85
      )} !important`,
    },
    report__container: {
      padding: "25px",
      backgroundColor: "white",
      // height: "100vh",
    },
    report__top__header: {},
    report__container__unit: {},
    report__title__unit: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    report__box: {
      display: "flex",
      gap: "15px",
    },
    report__title__program: { fontWeight: "bold", margin: 0 },

    dish__details: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 5,
      fontFamily: "BAAAAA + Metropolis-Regularal",
      color: "#000000",
    },
  };
});

export const LeftContainer = styled("div")(({ theme }) => ({
  maxWidth: "74%",
  height: "fit-content",
  width: "74%",
  backgroundColor: "white",
  alignSelf:"center",
  padding: "15px 20px ",
  
  //boxSizing: "border-box",
  // display: "flex",
  // justifyContent: "center",
}));
export const Table = styled("table")(() => ({
  borderCollapse: "collapse",
  width: "100%",
}));
export const TableHeaders = styled("th")(() => ({
  border: "1px solid black",
  padding: "8px",
  textAlign: "left",
}));
export const TableData = styled("td")(() => ({
  border: "1px solid black",
  padding: "8px",
  "& p": {
    margin: "15px 0",
  },
}));
export const TableHead = styled("tr")(() => ({}));
export const TableRow = styled("tr")(() => ({
  height: "18vh",
}));
export const FakeTableRow = styled("tr")(() => ({
  height: "2.6vh",
}));
