import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { AuthProvider, RequireAuth } from "./contextAPI";
//pages
import Dashboard from "./pages/Dashboard";
import Ingredients from "./pages/Ingredients";
import AllergenCategories from "./pages/AllergenCategories";
import Dishes from "./pages/Dishes";
import Leads from "./pages/Leads";
import Customers from "./pages/Customers";
import Calendar from "./pages/Calendar";
import DeliveryDriver from "./pages/DeliveryDriver";
import DeliveryArea from "./pages/DeliveryArea";
import Report from "./pages/Report";
import Transaction from "./pages/Transaction";
//components
import MainContainer from "./components/layout/mainContainer/MainContainer";
import Login from "./components/Auth/Login/Login";
import ForgetPassword from "./components/Auth/ForgetPassword/ForgetPassword";
import DashboardHome from "./components/dashboard/dashboardHome/DashboardHome";
import AddIngredients from "./components/ingredients/addIngredient/AddIngredient";
import ListIngredients from "./components/ingredients/listIngredients/ListIngredients";
import UpdateIngredients from "./components/ingredients/UpdateIngredient/UpdateIngredient";
import ViewIngredient from "./components/ingredients/viewIngredient/ViewIngredient";
import ListAllergens from "./components/allergenCategory/listAllergenCategory/ListAllergens";
import AddAllergenCategory from "./components/allergenCategory/addAllergenCategory/AddAllergenCategory";
import UpdateAllergenCategory from "./components/allergenCategory/updateAllergenCategory/UpdateAllergenCategory";
import ViewAllergenCategory from "./components/allergenCategory/viewAllergenCategory/ViewAllergenCategory";
import ListDish from "./components/dish/listDish/ListDish";
import FormDish from "./components/dish/FormDish/FormDish";
import ViewDish from "./components/dish/viewDish/ViewDish";
import AddProgram from "./components/programs/addProgram/AddProgram";
//import ListPrograms from "./components/programs/listPrograms/ListPrograms";
import UpdateProgram from "./components/programs/updateProgram/UpdateProgram";
import ViewProgram from "./components/programs/viewProgram/ViewProgram";
import AddCustomer from "./components/customer/addCustomer/AddCustomer";
import ListCustomers from "./components/customer/listCustomers/ListCustomers";
import ViewCustomer from "./components/customer/viewCustomer/ViewCustomer";
import UpdateCustomer from "./components/customer/UpdateCustomer/UpdateCustomer";
import ConvertedCustomer from "./components/customer/ConvertedCustomer/ConvertedCustomer";
import AddLead from "./components/lead/addLead/AddLead";
import ListLeads from "./components/lead/listLeads/ListLeads";
import ViewLead from "./components/lead/viewLead/ViewLead";
import UpdateLead from "./components/lead/updateLead/UpdateLead";
import AddDriver from "./components/delivery/driver/addDriver/AddDriver";
import ListDrivers from "./components/delivery/driver/listDriver/ListDrivers";
import UpdateDriver from "./components/delivery/driver/updateDriver/UpdateDriver";
import AddArea from "./components/delivery/area/addArea/AddArea";
import ListAreas from "./components/delivery/area/listArea/ListAreas";
import UpdateArea from "./components/delivery/area/updateArea/UpdateArea";
import ViewReport from "./components/reports/ViewReport";
import ViewTransaction from "./components/transaction/ViewTransaction";
import ExpiringCustomers from "./components/dashboard/ExpiringCustomers/ExpiringCustomers";
import FirstBoxDelivered from "./components/dashboard/FirstBoxDelivered/FirstBoxDelivered";
import UnfrozenCustomers from "./components/dashboard/UnfrozenCustomers/UnfrozenCustomers";
import RenewedCustomers from "./components/dashboard/RenewedCustomers/RenewedCustomers";
import TotalCustomers from "./components/dashboard/TotalCustomers/TotalCustomers";
import ScheduledPayment from "./components/dashboard/ScheduledPayment/ScheduledPayment";
import Birthdays from "./components/dashboard/Birthdays/Birthdays";
import NewSignedUpCustomers from "./components/dashboard/NewSignedUpCustomers/NewSignedUpCustomers";
import UnattendedLeads from "./components/dashboard/UnattendedLeads/UnattendedLeads";
import DataManagement from "./pages/DataManagement";
import PromoCode from "./components/dataManagement/promoCode/PromoCode";
import CustomerPromoCode from "./components/dataManagement/CustomerPromoCode/CustomerPromoCode";
import AddPromoCode from "./components/dataManagement/promoCode/AddPromoCode/AddPromoCode";
import ProgramMenuView from "./components/calendar/ProgramMenuView";
import EditPromoCode from "./components/dataManagement/promoCode/EditPromoCode/EditPromoCode";
import PendingCustomers from "./components/dashboard/PendingCustomers/PendingCustomers";
import AddTrainer from "./components/dataManagement/Trainers/AddTrainer/AddTrainer";
import UpdateTrainer from "./components/dataManagement/Trainers/UpdateTrainer/UpdateTrainer";
import TrainerDetails from "./components/dataManagement/Trainers/TrainerDetails/TrainerDetails";
import ScheduledRenewal from "./components/dashboard/ScheduledRenewal/ScheduledRenewal";
import GeneralFreezesList from "./components/dataManagement/GeneralFreezes/GeneralFreezesList";
import AddGeneralFreeze from "./components/dataManagement/GeneralFreezes/AddGeneralFreeze/AddGeneralFreeze";
import UpdateGeneralFreeze from "./components/dataManagement/GeneralFreezes/UpdateGeneralFreeze/UpdateGeneralFreeze";
import UsersAndRolesList from "./components/dataManagement/UsersAndRoles/UsersAndRolesList";
import AddUser from "./components/dataManagement/UsersAndRoles/AddUser/AddUser";
import UpdateUser from "./components/dataManagement/UsersAndRoles/UpdateUser/UpdateUser";
import AddEditArea from "./components/delivery/area/addArea/AddEditArea";
import AreaList from "./components/delivery/area/listArea/AreaList";
import UpdateRole from "./components/dataManagement/UsersAndRoles/UpdateRole/UpdateRole";
import DeactivatedCustomer from "./components/dashboard/deactivatedCustomer/DeactivatedCustomer";
import BlackListedCustomers from "./components/dashboard/BlackListedCustomers/BlackListedCustomers";
import ConvertedLeads from "./components/dashboard/ConvertedLeads/ConvertedLeads";
import SwitchedCustomers from "./components/dashboard/switchingCustomers/switchingCustomers";
import AddNotification from "./components/dataManagement/Notifications/AddNotification/AddNotification";
import NotificationTemplatesList from "./components/dataManagement/NotificationTemplates/NotificationTemplatesList";
import AddTemplate from "./components/dataManagement/NotificationTemplates/AddTemplate/AddTemplate";
import UpdateTemplate from "./components/dataManagement/NotificationTemplates/UpdateTemplate/UpdateTemplate";
import UpdateNotification from "./components/dataManagement/Notifications/UpdateNotification/UpdateNotification";
import NotificationsView from "./components/dataManagement/Notifications/NotificationsView";
import TrainerView from "./components/dataManagement/Trainers/TrainerView";
import InfluencersList from "./components/dataManagement/Trainers/Influencers/InfluencersList";
import CountriesList from "./components/dataManagement/Countries/CountriesList";
import AddCountry from "./components/dataManagement/Countries/AddCountry/AddCountry";
import UpdateCountry from "./components/dataManagement/Countries/UpdateCountry/UpdateCountry";
import DriversAppDashboard from "./components/dataManagement/DriversAppDashboard/DriversAppDashboard";
import BlackListedDrivers from "./components/dashboard/BlackListedDrivers/BlackListedDrivers";
import PackingAppDashboard from "./components/dataManagement/PackingAppDashboard/PackingAppDashboard";
import Packing from "./pages/Packing";
import PackingTeamList from "./components/PackingTeam/PackingTeamList";
import PackingGroupsList from "./components/PackingGroups/PackingGroupsList";
import AddPackingAgent from "./components/PackingTeam/AddPackingAgent/AddPackingAgent";
import AddPackingGroup from "./components/PackingGroups/AddPackingGroup/AddPackingGroup";
import UpdatePackingAgent from "./components/PackingTeam/UpdatePackingAgent/UpdatePackingAgent";
import UpdatePackingGroup from "./components/PackingGroups/UpdatePackingGroup/UpdatePackingGroup";
import { useCustomTheme } from "./theme/useCustomTheme";
import RewardsList from "./components/dataManagement/Rewards/RewardsList";
import AddReward from "./components/dataManagement/Rewards/AddReward";
import EditReward from "./components/dataManagement/Rewards/EditReward";
import ActivatedCustomers from "./components/dashboard/ActivatedCustomers/ActivatedCustomers";
import RemindersList from "./components/Reminders/RemindersList";
import AddReminder from "./components/Reminders/AddReminders";
import UpdateReminders from "./components/Reminders/UpdateReminders";
import Complains from "./pages/Complains";
import ComplainsList from "./components/complains/ComplainsList";
import AddComplain from "./components/complains/AddComplain/AddComplain";
import Reminders from "./pages/Reminders";
import UpdateComplain from "./components/complains/UpdateComplain/UpdateComplain";
import BoxTransferDashboard from "./components/dashboard/boxTransfer/boxTransferDashboard";
import KitchenAppDashboard from "./components/dataManagement/KitchenAppDashboard/KitchenAppDashboard";
import Chefs from "./pages/Chefs";
import AddChef from "./components/Chefs/AddChef";
import ChefsList from "./components/Chefs/ChefsList";
import UpdateChef from "./components/Chefs/UpdateChef";
import ProgramsList from "./components/programs/listPrograms/ProgramsList";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
});
function App() {
  const { theme } = useCustomTheme();

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <StylesProvider generateClassName={generateClassName}>
            <Router>
              <Routes>
                {/*Authentification*/}
                {/*Login*/}
                <Route path="/login" element={<Login />} />
                <Route path="/forget_password" element={<ForgetPassword />} />
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <MainContainer />
                    </RequireAuth>
                  }
                >
                  {/* Dashboard */}
                  <Route path="/dashboard" element={<Dashboard />}>
                    <Route path="home/list" element={<DashboardHome />} />
                    <Route
                      path="expiring_customers/list_expiring_customers"
                      element={<ExpiringCustomers />}
                    />
                    <Route
                      path="switched_customers/list_switched_customers"
                      element={<SwitchedCustomers />}
                    />

                    <Route
                      path="first_box_delivered/list_first_box_delivered_customers"
                      element={<FirstBoxDelivered />}
                    />
                    <Route
                      path="unfrozen_customers/list_unfrozen_customers"
                      element={<UnfrozenCustomers />}
                    />
                    <Route
                      path="renewed_customers/list_renewed_customers"
                      element={<RenewedCustomers />}
                    />
                    <Route
                      path="scheduled_reneweal_customers/list_scheduled_reneweal_customers"
                      element={<ScheduledRenewal />}
                    />
                    <Route
                      path="total_customers/list_total_customers"
                      element={<TotalCustomers />}
                    />
                    <Route
                      path="scheduled_customers/list_scheduled_customers"
                      element={<ScheduledPayment />}
                    />
                    <Route
                      path="pending_customers/list_pending_customers"
                      element={<PendingCustomers />}
                    />
                    <Route
                      path="activated_customers/list_activated_customers"
                      element={<ActivatedCustomers />}
                    />
                    <Route
                      path="birthdays/list_birthdays"
                      element={<Birthdays />}
                    />
                    <Route
                      path="new_signed_up_customers/list_new_signed_up_customers"
                      element={<NewSignedUpCustomers />}
                    />
                    <Route
                      path="unattended_leads/list_unattended_leads"
                      element={<UnattendedLeads />}
                    />
                    <Route
                      path="deactivated_customer/list_customer"
                      element={<DeactivatedCustomer />}
                    />
                    <Route
                      path="blackListed_customers/list_blackListed_customers"
                      element={<BlackListedCustomers />}
                    />
                    <Route
                      path="blackListed_drivers/list_blackListed_drivers"
                      element={<BlackListedDrivers />}
                    />
                    <Route
                      path="converted_leads/list_converted_leads"
                      element={<ConvertedLeads />}
                    />
                    <Route
                      path="list_box_transfer/list_box_transfer"
                      element={<BoxTransferDashboard />}
                    />
                  </Route>

                  {/* Food */}
                  {/*Ingredient */}

                  <Route path="/food" element={<Ingredients />}>
                    <Route
                      path="ingredients/add_ingredient"
                      element={<AddIngredients />}
                    />
                    <Route
                      path="ingredients/update_ingredient/:id"
                      element={<UpdateIngredients />}
                    />
                    <Route
                      path="ingredients/list_ingredients"
                      element={<ListIngredients />}
                    />
                    <Route
                      path="ingredients/view_ingredient/:id"
                      element={<ViewIngredient />}
                    />
                  </Route>

                  {/* Allergen */}
                  <Route path="/food" element={<AllergenCategories />}>
                    <Route
                      path="allergens/add_allergen"
                      element={<AddAllergenCategory />}
                    />
                    <Route
                      path="allergens/update_allergen/:id"
                      element={<UpdateAllergenCategory />}
                    />
                    <Route
                      path="allergens/list_allergens"
                      element={<ListAllergens />}
                    />
                    <Route
                      path="allergens/view_allergen/:id"
                      element={<ViewAllergenCategory />}
                    />
                  </Route>

                  {/*  Dish */}
                  <Route path="/food" element={<Dishes />}>
                    <Route path="dishes/add_dish" element={<FormDish />} />
                    <Route
                      path="dishes/list_dishes/:searchKey"
                      element={<ListDish />}
                    />
                    <Route path="dishes/list_dishes" element={<ListDish />} />
                    <Route path="dishes/view_dish/:id" element={<ViewDish />} />
                    <Route
                      path="dishes/update_dish/:id/:search"
                      element={<FormDish />}
                    />
                    <Route
                      path="dishes/update_dish/:id"
                      element={<FormDish />}
                    />
                  </Route>

                  {/* Program */}
                  <Route path="/food" element={<Customers />}>
                    <Route
                      path="programs/add_program"
                      element={<AddProgram />}
                    />
                    <Route
                      path="programs/list_programs"
                      element={<ProgramsList />}
                    />
                    <Route
                      path="programs/view_program/:id"
                      element={<ViewProgram />}
                    />
                    <Route
                      path="programs/update_program/:id"
                      element={<UpdateProgram />}
                    />
                  </Route>

                  {/* Calendar */}
                  <Route path="/food" element={<Calendar />}>
                    <Route
                      path="program_menu/view_menu"
                      element={<ProgramMenuView />}
                    />
                  </Route>

                  {/* admin */}
                  {/* Lead  */}

                  <Route path="/admin" element={<Leads />}>
                    <Route path="leads/add_lead" element={<AddLead />} />
                    <Route path="leads/list_leads" element={<ListLeads />} />
                    <Route path="leads/view_lead/:id" element={<ViewLead />} />
                    <Route
                      path="leads/update_lead/:id"
                      element={<UpdateLead />}
                    />
                  </Route>

                  {/* Customer */}
                  <Route path="/admin" element={<Customers />}>
                    <Route
                      path="customers/add_customer"
                      element={<AddCustomer />}
                    />
                    <Route
                      path="customers/list_customers"
                      element={<ListCustomers />}
                    />
                    <Route
                      path="customers/view_customer/:id"
                      element={<ViewCustomer />}
                    />
                    <Route
                      path="customers/update_customer/:id"
                      element={<UpdateCustomer />}
                    />
                    <Route
                      path="customers/converted_customer/:id"
                      element={<ConvertedCustomer />}
                    />
                  </Route>

                  {/* Driver */}
                  <Route path="/admin" element={<DeliveryDriver />}>
                    <Route
                      path="delivery_driver/add_driver"
                      element={<AddDriver />}
                    />
                    <Route
                      path="delivery_driver/list_drivers"
                      element={<ListDrivers />}
                    />
                    <Route
                      path="delivery_driver/update_driver/:id"
                      element={<UpdateDriver />}
                    />
                  </Route>

                  {/* Delivery Area */}
                  <Route path="/admin" element={<DeliveryArea />}>
                    <Route
                      path="delivery_area/list_areas"
                      element={<ListAreas />}
                    />
                    <Route
                      path="delivery_area/update_area/:id"
                      element={<UpdateArea />}
                    />
                    <Route
                      path="delivery_area/add_area"
                      element={<AddArea />}
                    />
                  </Route>

                  {/* Area */}
                  <Route path="/admin" element={<DeliveryArea />}>
                    <Route path="area/list_areas" element={<AreaList />} />
                    <Route path="area/addEditArea" element={<AddEditArea />} />
                    <Route
                      path="area/addEditArea/:id"
                      element={<AddEditArea />}
                    />
                  </Route>
                  {/* Chef */}
                  <Route path="/admin" element={<Chefs />}>
                    <Route path="chef/add_chef" element={<AddChef />} />
                    <Route path="chef/list_chefs" element={<ChefsList />} />
                    <Route
                      path="chef/update_chefs/:id"
                      element={<UpdateChef />}
                    />
                  </Route>
                  {/* Report*/}
                  <Route path="/admin" element={<Report />}>
                    <Route
                      path="reports/view_reports"
                      element={<ViewReport />}
                    />
                  </Route>

                  {/* PACKING */}
                  <Route path="/admin" element={<Packing />}>
                    <Route
                      path="packingTeam/list_packing_team"
                      element={<PackingTeamList />}
                    />
                    <Route
                      path="packingGroups/list_packing_groups"
                      element={<PackingGroupsList />}
                    />
                    <Route
                      path="packingTeam/add_packing_agent"
                      element={<AddPackingAgent />}
                    />
                    <Route
                      path="packingGroups/add_packing_group"
                      element={<AddPackingGroup />}
                    />
                    <Route
                      path="packingTeam/update_packing_agent/:id"
                      element={<UpdatePackingAgent />}
                    />
                    <Route
                      path="packingGroups/update_packing_group/:id"
                      element={<UpdatePackingGroup />}
                    />
                  </Route>

                  {/* Transaction*/}
                  <Route path="/admin" element={<Transaction />}>
                    <Route
                      path="transactions/view_transactions"
                      element={<ViewTransaction />}
                    />
                  </Route>

                  {/* COMPLAINS */}
                  <Route path="/admin" element={<Complains />}>
                    <Route
                      path="complains/list_complains"
                      element={<ComplainsList />}
                    />
                    <Route
                      path="complains/add_complain"
                      element={<AddComplain />}
                    />
                    <Route
                      path="complains/update_complain/:id"
                      element={<UpdateComplain />}
                    />
                  </Route>
                  {/* Reminders */}
                  <Route path="/admin" element={<Reminders />}>
                    <Route
                      path="reminders/list_reminders"
                      element={<RemindersList />}
                    />
                    <Route
                      path="reminders/add_reminder"
                      element={<AddReminder />}
                    />
                    <Route
                      path="reminders/update_reminder/:id"
                      element={<UpdateReminders />}
                    />
                  </Route>

                  {/* Data Management*/}
                  <Route path="/data" element={<DataManagement />}>
                    {/* Promocode */}
                    <Route
                      path="promoCode/list_promoCode"
                      element={<PromoCode />}
                    />
                    <Route
                      path="promoCode/add_promoCode"
                      element={<AddPromoCode />}
                    />
                    <Route
                      path="promoCode/update_promoCode/:id"
                      element={<EditPromoCode />}
                    />

                    {/* Customer Promocode */}
                    <Route
                      path="customer_promoCode/list_customer_promoCode"
                      element={<CustomerPromoCode />}
                    />

                    {/* Trainer */}
                    <Route
                      path="trainers/list_trainers"
                      element={<TrainerView />}
                    />
                    <Route
                      path="trainers/add_trainer"
                      element={<AddTrainer />}
                    />
                    <Route
                      path="trainers/update_trainer/:id"
                      element={<UpdateTrainer />}
                    />
                    <Route
                      path="trainers/view_trainer/:id"
                      element={<TrainerDetails />}
                    />
                    <Route
                      path="influencers/list_influencers"
                      element={<InfluencersList />}
                    />
                    {/* General Freezes */}
                    {/* <Route
                      path="general_freezes/list_general_freezes"
                      element={<GeneralFreezesList />}
                    />
                    <Route
                      path="general_freezes/add_general_freezes"
                      element={<AddGeneralFreeze />}
                    />
                    <Route
                      path="general_freezes/update_general_freeze/:id"
                      element={<UpdateGeneralFreeze />}
                    /> */}

                    {/* Users & Roles */}
                    <Route
                      path="users_and_roles/list_users_roles"
                      element={<UsersAndRolesList />}
                    />
                    <Route
                      path="users_and_roles/add_user"
                      element={<AddUser />}
                    />
                    <Route
                      path="users_and_roles/update_user/:id"
                      element={<UpdateUser />}
                    />
                    <Route
                      path="users_and_roles/update_role/:role"
                      element={<UpdateRole />}
                    />

                    {/* Notifications */}
                    <Route
                      path="notifications/list_notifications"
                      element={<NotificationsView />}
                    />
                    <Route
                      path="notifications/add_notification"
                      element={<AddNotification />}
                    />
                    <Route
                      path="notifications/update_notification/:id"
                      element={<UpdateNotification />}
                    />

                    {/* NOTIFICATION TEMPLATES */}
                    <Route
                      path="notification_templates/list_notification_templates"
                      element={<NotificationTemplatesList />}
                    />
                    <Route
                      path="notification_templates/add_notification_templates"
                      element={<AddTemplate />}
                    />
                    <Route
                      path="notification_templates/update_notification_templates/:id"
                      element={<UpdateTemplate />}
                    />

                    {/* COUNTRIES */}
                    <Route
                      path="countries/list_countries"
                      element={<CountriesList />}
                    />
                    <Route
                      path="countries/add_country"
                      element={<AddCountry />}
                    />
                    <Route
                      path="countries/update_country/:id"
                      element={<UpdateCountry />}
                    />

                    {/* DRIVERS APP DASHBOARD */}
                    <Route
                      path="drivers-app/driver-dash-report"
                      element={<DriversAppDashboard />}
                    />

                    {/* Rewards*/}
                    <Route
                      path="rewards/List_rewards"
                      element={<RewardsList />}
                    />
                    <Route path="rewards/add_reward" element={<AddReward />} />
                    <Route
                      path="rewards/edit_reward/:id"
                      element={<EditReward />}
                    />

                    {/* PACKING APP DASHBOARD */}
                    <Route
                      path="packing-app/packing-dash-report"
                      element={<PackingAppDashboard />}
                    />
                    {/* KITCHEN APP DASHBOARD */}
                    <Route
                      path="kitchen-app/kitchen-dash-report"
                      element={<KitchenAppDashboard />}
                    />
                  </Route>
                </Route>
              </Routes>
            </Router>
          </StylesProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
