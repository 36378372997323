import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material-ui
import {
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//icons
import Delete from "../../public/icons/delete";
//components
import PageHeader from "../pageHeader/PageHeader";
import Table from "../layout/commun/Table";
//Utils
import { chefsColumns } from "./Utils";
import { useIsAuthorized } from "../../utils/Hooks/useIsAuthorized";
//styles
import { useStylesButtons } from "../../styles/Buttons__styles";
import { useStylesMenu } from "../../styles/Menu__styles";
import { useStylesDialog } from "../../styles/Dialog__styles";
import { TableContainer } from "../../styles/Table__styles";

//queries&mutations
import {
  GetALlChefsDocument,
  useDeleteChefMutation,
  useGetALlChefsQuery,
} from "../../graphql/types";

const ChefsList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [idChef, setIdChef] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);

  //queries&mutations
  const listChefs = useGetALlChefsQuery({ fetchPolicy: "network-only" });
  const [deleteChef, { loading }] = useDeleteChefMutation();

  //functions
  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setIdChef(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (idChef) {
      navigate(`/admin/chef/update_chefs/${idChef}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteChef = () => {
    setOpen(false);
    idChef &&
      deleteChef({
        variables: {
          input: idChef,
        },
        refetchQueries: [{ query: GetALlChefsDocument }],
        onCompleted: () => {
          listChefs.refetch();
          enqueueSnackbar("Chef succuessfully deleted", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
  };

  const renderTableRows = () => {
    return (
      listChefs?.data?.getALlChefs?.map((el, index) => {
        const isLoading = idChef === el._id && loading;
        return {
          index: index + 1,
          name: el.name,
          email: el.email,
          password: el.password,
          phone: `${el?.phone?.prefix}${el?.phone?.number}`,
          actions: isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <IconButton onClick={(e) => handleClickMenu(e, el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <div>
      {useIsAuthorized("ADMIN_CHEF_CREATE") && (
        <PageHeader
          buttonLink={`/admin/chef/add_chef`}
          buttonText={"Add Chef"}
        />
      )}
      <TableContainer>
        <Table
          columns={chefsColumns}
          data={renderTableRows()}
          emptyMessage="No Chefs found !"
          loading={listChefs?.loading}
          numberOfFakeRow={30}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("ADMIN_CHEF_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <div>
                <span className="menu_title">Update</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("ADMIN_CHEF_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Chef ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Chef ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={handleDeleteChef}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ChefsList;
