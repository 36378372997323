import { RewardsActionEnum } from "../../../graphql/types";
import { ColumnsProps } from "../../layout/commun/Table";
import { RewardsAction } from "../Utils";

export const columnsRewards: ColumnsProps = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "Reward Type",
    accessor: "RewardsActionType",
  },
  {
    header: "Points",
    accessor: "points",
  },
  {
    header: "Remaining Balance",
    accessor: "remaining_balance",
  },
  {
    header: "Status",
    accessor: "status",
  },

  {
    header: "Wallet",
    accessor: "wallet",
  },
  {
    header: "Expired Date",
    accessor: "expired_date",
  },
];
export const columnsCustomerRewards: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "Points Required",
    accessor: "pointsRequired",
  },
  {
    header: "Value",
    accessor: "value",
  },
  {
    header: "Claimable",
    accessor: "claimable",
  },
];

export const WalletTableColumns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Point Used",
    accessor: "pointsUsed",
  },
  {
    header: "Remaining Amount",
    accessor: "remainingAmount",
  },
  {
    header: "wallet Balance",
    accessor: "walletBalance",
  },
  {
    header: "Wallet Status",
    accessor: "walletStatus",
  },
];

export const RewardsType = (actionType: RewardsActionEnum): string => {
  const action = RewardsAction.find((a) => a.menuValue === actionType);
  return action ? action.menuText : "";
};
