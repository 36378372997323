import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
//material ui
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete } from "@mui/icons-material";
//styles
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import Table from "../../layout/commun/Table";
//queries&mutations
import {
  GetPushNotificationsListDocument,
  useDeleteNotificationFromDashboardMutation,
  useGetPushNotificationsListQuery,
  usePublishNotifToMultipleMutation,
  useResetNotificationFromDashboardMutation,
} from "../../../graphql/types";
//Utils
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { getMessageError } from "../../Utils";
import { PushNotificationsColumns } from "./Utils";

const PushNotificationsList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pushNotificationId, setPushNotificationId] = useState<string | null>(
    null
  );
  const [open, setOpen] = useState<boolean>(false);

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //queries&mutations
  const [deletePushNotification] = useDeleteNotificationFromDashboardMutation();
  const [resetPushNotification, { loading: loadingReset }] =
    useResetNotificationFromDashboardMutation();
  const [publishPushNotification, { loading: loadingPublish }] =
    usePublishNotifToMultipleMutation();
  const PushNotificationData = useGetPushNotificationsListQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
      },
    },
    fetchPolicy: "no-cache",
  });

  const notificationList = useMemo(
    () => PushNotificationData.data?.getPushNotificationsList.data || [],
    [PushNotificationData.data]
  );

  const count = useMemo(
    () => PushNotificationData.data?.getPushNotificationsList.count || 0,
    [PushNotificationData.data]
  );

  //functions
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (pushNotificationId) {
      navigate(`/data/notifications/update_notification/${pushNotificationId}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClickMenu =
    (item: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setPushNotificationId(item);
    };

  const DeleteNotificationHandler = async () => {
    setOpen(false);
    await deletePushNotification({
      variables: { input: pushNotificationId || "" },
      refetchQueries: [GetPushNotificationsListDocument],
      onCompleted() {
        enqueueSnackbar("Push Notification deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const PublishNotificationHandler = async (id: string) => {
    await publishPushNotification({
      variables: { input: id || "" },
      refetchQueries: [GetPushNotificationsListDocument],
      onCompleted() {
        enqueueSnackbar("Push Notification Published succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const ResetNotificationHandler = async (id: string) => {
    await resetPushNotification({
      variables: { id: id || "" },
      refetchQueries: [GetPushNotificationsListDocument],
      onCompleted() {
        enqueueSnackbar("Push Notification Reset succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const renderTableRows = () => {
    return (
      notificationList.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          photo: (
            <Avatar
              alt={el?.title?.EN!}
              src={el.photo!}
              variant="rounded"
              style={{ width: 55, height: 55 }}
            />
          ),
          title: el.title?.EN,
          shortDescription: el.shortDescription?.EN,
          htmlBody: el?.htmlBody?.EN,
          type: el.type,
          DestinationCount: el.destinationCount,
          sendAt: el?.sendAt
            ? moment(el?.sendAt).format("DD/MM/YYYY")
            : "NOT PUBLISHED",
          actions: (
            <IconButton onClick={handleClickMenu(el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
          publishReset: (
            <ButtonGroup variant="contained">
              <Button
                disabled={el.isPublished ? true : false || loadingPublish}
                onClick={() => PublishNotificationHandler(el._id || "")}
              >
                Publish
              </Button>
              <Button
                disabled={!el.isPublished ? true : false || loadingReset}
                onClick={() => ResetNotificationHandler(el._id || "")}
              >
                Reset
              </Button>
            </ButtonGroup>
          ),
        };
      }) || []
    );
  };

  return (
    <>
      <TableContainer>
        <Table
          columns={PushNotificationsColumns}
          data={renderTableRows()}
          emptyMessage="No notifications found !"
          loading={PushNotificationData.loading}
          numberOfFakeRow={30}
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>

      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("MANAGEMENT_NOTIFICATION_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}

          {useIsAuthorized("MANAGEMENT_NOTIFICATION_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <Box onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>

      {/* DELETE PUSH NOTIFICATION */}
      <Dialog
        open={open}
        onClose={handleCloseModal}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <Delete />
          <span className="alert_dialog_title_text">
            Delete Push Notification ?
          </span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to delete this Push Notification ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            className={ButtonsClasses.RedButton}
            onClick={DeleteNotificationHandler}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PushNotificationsList;
