import * as React from "react";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
//material ui
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type CardStatisticalProps = {
  title: string;
  icon: JSX.Element;
  count: number;
  bgcolorIcon: string;
  path: string;
};

const CardStatistical = ({
  title,
  icon,
  count,
  bgcolorIcon,
  path,
}: CardStatisticalProps): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Box
      p={2}
      borderRadius={2}
      boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#F4EEE0",
          transition: "0.3s",
          transform: "scale(1.03)",
        },
      }}
      onClick={() => navigate(path)}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Box
          position="absolute"
          bottom="10px"
          left="0"
          display="flex"
          width="55px"
          height="55px"
          alignItems="center"
          justifyContent="center"
          bgcolor={bgcolorIcon}
          borderRadius="50%"
        >
          {icon}
        </Box>
        <Typography variant="h1">
          <CountUp end={count} duration={0.7} />
        </Typography>
      </Box>
      <Typography variant="subtitle1">{title}</Typography>
    </Box>
  );
};

export default React.memo(CardStatistical);
