import { ColumnsProps } from "../../layout/commun/Table";
export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Si No",
    accessor: "ref",
  },
  {
    header: "Trainer Name",
    accessor: "name",
  },
  {
    header: "Phone Number",
    accessor: "phone",
  },
  {
    header: "Promo code",
    accessor: "promocode",
  },
  {
    header: "Used By",
    accessor: "usedBy",
  },
  {
    header: "Joined",
    accessor: "joined",
  },
  {
    header: "Total Value",
    accessor: "totalValue",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];
