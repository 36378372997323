import { ColumnsProps } from "../layout/commun/Table";
import { TransactionKind, TransactionStatus } from "../../graphql/types";
import { FilterBar } from "../Utils";
export const columns: ColumnsProps = [
  {
    header: "Costumer Name",
    accessor: "name",
  },
  {
    header: "Costumer Program",
    accessor: "plan",
  },
  {
    header: "Program Price",
    accessor: "price",
  },

  {
    header: "Transaction Status",
    accessor: "status",
  },
  {
    header: "Payment Method",
    accessor: "method",
  },
  {
    header: "Kind",
    accessor: "kind",
  },
  {
    header: "Merchant Reference",
    accessor: "merchant_reference",
    cellProps: { width: "300px" },
  },
  {
    header: "Dibsy Id",
    accessor: "dibsyId",
  },
  {
    header: "Transaction Date",
    accessor: "date",
  },
  {
    header: "Approval",
    accessor: "approve",
  },
];

export const handleStatus = (status: TransactionStatus) => {
  switch (status) {
    case "CREATED":
      return "Created";
    case "ACTIVATED":
      return "Activated";
    case "SUCCESS":
      return "Success";
    case "FAILED":
      return "Failed";
    case "CANCELED":
      return "Canceled";
    case "PENDING":
      return "Pending";
    default:
      return "Scheduled Payment";
  }
};
export const handleKind = (kind: TransactionKind | null | undefined) => {
  switch (kind) {
    case "PLAN":
      return "Plan";
    case "ON_DEMAND":
      return "On Demand";
    case "REFUND":
      return "Refund";
    case "EXTEND_PLAN":
      return "Extend Plan";
    case "ADDON":
      return "Add on";
    case "SWITCH":
      return "Switch";
    case "CUSTOM":
      return "Custom";
    default:
      return "";
  }
};
export const SIDE_FILTER_OPTIONS_TRANSACTIONS: FilterBar[] = [
  {
    id: "09",
    title: "Transaction Status",
    data: [
      { id: "CREATED", name: "Created", isSelected: false },
      { id: "ACTIVATED", name: "Activated", isSelected: false },
      { id: "SUCCESS", name: "Success", isSelected: false },
      { id: "FAILED", name: "Failed", isSelected: false },
      { id: "CANCELED", name: "Canceled", isSelected: false },
      { id: "SCHEDULED_PAYMENT", name: "Scheduled payment", isSelected: false },
      { id: "PENDING", name: "Pending", isSelected: false },
    ],
  },
  {
    id: "12",
    title: "Payment Method",
    data: [
      { id: "DEBIT_CARD", name: "Debit Card", isSelected: false },
      { id: "CREDIT_CARD", name: "Credit Card", isSelected: false },
      { id: "CASH", name: "Cash", isSelected: false },
      { id: "WALLET", name: "Wallet", isSelected: false },
      { id: "NAPS", name: "Naps", isSelected: false },
      { id: "APPLE_PAY", name: "Apple pay", isSelected: false },
      { id: "GOOGLE_PAY", name: "Google pay", isSelected: false },
    ],
  },
];
