import { Button, CircularProgress } from "@mui/material";
import clsx from "clsx";
import React from "react";

import { useStylesButtons } from "../../styles/Buttons__styles";
import { useStylesHeader } from "../../styles/Header__styles";
import { Box } from "@mui/system";

type TabContentHeaderProps = {
  // main button text
  title?: string;
  // main button text
  buttonText?: string;
  // cancel button text
  leftButtonText?: string;
  // main button function
  buttonFnc?: any;
  // cancel button function
  cancelButtonFnc?: any;
  loading?: boolean;
};

const TabContentHeader: React.FC<TabContentHeaderProps> = ({
  title,
  buttonText,
  buttonFnc,
  loading,
  children,
  leftButtonText,
  cancelButtonFnc,
}) => {
  const classesHeader = useStylesHeader();
  const ButtonClasses = useStylesButtons();

  return (
    <div className={classesHeader.root}>
      <span className={clsx(classesHeader.title)}>{title}</span>

      <Box>
        {leftButtonText && (
          <Button
            className={clsx(ButtonClasses.button_save)}
            startIcon={children}
            onClick={cancelButtonFnc}
          >
            {leftButtonText}
          </Button>
        )}
        {buttonText && (
          <Button
            className={clsx(ButtonClasses.button_save)}
            startIcon={children}
            type="submit"
            onClick={buttonFnc}
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                size={20}
                style={{ color: "white", marginRight: 6 }}
              />
            )}
            {buttonText}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default TabContentHeader;
