import React from "react";
import clsx from "clsx";
//react hook form
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
//styles
import { useStylesInput } from "../../../../styles/Input__styles";
import { useStylesLeads } from "../../../../styles/Leads__styles";
import { useStylesCustomers } from "../../../../styles/Customers__styles";
import { useStylesPhoneInput } from "../../../../styles/PhoneInput__styles";
import { useStylesContainers } from "../../../../styles/Containers__styles";
import { useStylesButtons } from "../../../../styles/Buttons__styles";
//material
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
//types
import { customersProps } from "../../Types";
import { useGetActiveAreasQuery } from "../../../../graphql/types";
//utils
import { days, typeOptions } from "../../Utils";
import { useCountryPrefix } from "../../../../utils/Hooks/useCountryPrefix";

function AddressInputForTranferBox() {
  //styles
  const ContainersClasses = useStylesContainers();
  const LeadsClasses = useStylesLeads();
  const CustomersClasses = useStylesCustomers();
  const PhoneInputClasses = useStylesPhoneInput();
  const InputClasses = useStylesInput();
  const ButtonClasses = useStylesButtons();
  //queries
  const dataArea = useGetActiveAreasQuery();
  //react-hook-form
  const {
    control,
    setValue,
    formState: { errors },
    register,
  } = useFormContext<customersProps>();


  const { fields, append, remove } = useFieldArray({
    control, 
    name: "addresses", 
  });

  const countryPrefix = useCountryPrefix();
  //consts
  const customerAreas = React.useMemo(
    () => dataArea?.data?.getAreas,
    [dataArea?.data]
  );

  //functions
  const handleDays = (i: number, data: any) => {
    let currentValues = dataWatch.addresses;
    const newValues = currentValues?.map((item, index) => {
      if (index !== i) {
        const delivery__days = item.delivery__days?.filter(
          (o) => !data.find((el: any) => el.value === o.value)
        );
        return { ...item, delivery__days };
      }
      return { ...item };
    });
    setValue("addresses", newValues as any);
  };

  const onClickAdd = () => {
    append({
      address__name: "",
      area__name: "",
      building__name: "",
      street__name: "",
      aprt__number: "",
      floor__number: "",
      address__type: "",
      address__kind: [],
      delivery__number: "",
      delivery__prefix: countryPrefix?.callingCode,
      delivery__days: [],
      zone: "",
      delivery__time: null,
    });
  };

  const dataWatch = useWatch({
    control,
  });

  const onClickRemove = (index: number) => () => {
    let deletedAddressDaysData = dataWatch?.addresses
      ? dataWatch?.addresses[index].delivery__days
      : [];

    let transfered = false;
    setValue(
      "addresses",
      dataWatch?.addresses
        ?.filter((el, i) => i !== index)
        .map((el, i) => {
          if (!transfered) {
            transfered = true;
            return {
              ...el,
              delivery__days: [
                ...(el.delivery__days as any),
                ...(deletedAddressDaysData as any),
              ],
            };
          } else {
            return el;
          }
        }) as any
    );
    remove(index);
  };

  //render
  return (
    <>
      {fields.map((el, index) => {
        return (
          <div
            key={el.id}
            style={{
              position: "relative",
              border: "2px solid #F3F4FA",
              borderRadius: "8px",
              padding: "12px",
              marginBottom: "18px",
            }}
          >
            {fields.length > 1 && (
              <IconButton
                onClick={onClickRemove(index)}
                style={{
                  position: "absolute",
                }}
                className={clsx(InputClasses.input__clear__address)}
              >
                <ClearIcon />
              </IconButton>
            )}
            <Box className={ContainersClasses.section__container}>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Address Name
                </p>
                <TextField
                  placeholder=" Address Name"
                  sx={{ width: "100%" }}
                  {...register(`addresses.${index}.address__name`, {
                    required: {
                      message: "This field is required",
                      value: true,
                    },
                  })}
                />
                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.addresses?.[index].address__name?.message}
                </p>
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Area Name*
                </p>
                <Controller
                  name={`addresses.${index}.area__name`}
                  rules={{
                    validate: (value) => value !== "" || "Select Area Name",
                  }}
                  control={control}
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        {...rest}
                        sx={{ width: "100%" }}
                        className={LeadsClasses.select_container}
                      >
                        {customerAreas?.map((option, index) => (
                          <MenuItem
                            key={option._id}
                            value={option?._id}
                            className={
                              dataWatch?.addresses &&
                              dataWatch?.addresses?.[index] &&
                              dataWatch?.addresses?.[index]?.area__name ===
                                option?._id
                                ? clsx(
                                    CustomersClasses.option_item,
                                    CustomersClasses.option_item_selected
                                  )
                                : CustomersClasses.option_item
                            }
                          >
                            {option?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
            </Box>
            <Box className={clsx(ContainersClasses.section__container)}>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Building / House Name
                </p>
                <TextField
                  placeholder="Building / House Name"
                  sx={{ width: "100%" }}
                  {...register(`addresses.${index}.building__name`, {})}
                />
                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.addresses?.[index].building__name?.message}
                </p>
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Street Name
                </p>
                <TextField
                  placeholder="Street Name"
                  sx={{ width: "100%" }}
                  {...register(`addresses.${index}.street__name`, {})}
                />
                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.addresses?.[index].street__name?.message}
                </p>
              </Box>
            </Box>
            <Box className={clsx(ContainersClasses.section__container)}>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Zone
                </p>
                <TextField
                  placeholder="Zone"
                  sx={{ width: "100%" }}
                  {...register(`addresses.${index}.zone`, {
                    required: {
                      message: "This field is required",
                      value: true,
                    },
                  })}
                />
                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.addresses?.[index].zone?.message}
                </p>
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Delivery Contact Number
                </p>
                <Box className={PhoneInputClasses.container}>
                  <TextField
                    placeholder="Delivery Phone Number"
                    sx={{
                      width: fields.length === 1 ? "49%" : "100%",
                    }}
                    {...register(`addresses.${index}.delivery__number`, {
                      pattern: {
                        value: /^\d*$/,
                        message: "Enter a valid phone number",
                      },
                    })}
                  />
                </Box>
                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.addresses?.[index].delivery__number?.message}
                </p>
              </Box>
            </Box>

            <Box className={clsx(ContainersClasses.section__container)}>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Address Type*
                </p>
                <Controller
                  name={`addresses.${index}.address__type`}
                  control={control}
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <Select
                      {...rest}
                      sx={{ width: "100%" }}
                      className={LeadsClasses.select_container}
                    >
                      {typeOptions.map((option) => (
                        <MenuItem
                          key={option.menuValue}
                          onClick={() =>
                            setValue(
                              `addresses.${index}.address__type`,
                              option.menuValue
                            )
                          }
                          value={option.menuValue}
                          className={
                            dataWatch?.addresses &&
                            dataWatch?.addresses?.[index] &&
                            dataWatch?.addresses?.[index].address__type ===
                              option.menuValue
                              ? clsx(
                                  LeadsClasses.option_item,
                                  LeadsClasses.option_item_selected
                                )
                              : LeadsClasses.option_item
                          }
                        >
                          {option.menuText}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{
                    required: "Select Address Type",
                  }}
                />
                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.addresses?.[index].address__type?.message}
                </p>
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Delievry Time
                </p>
                <Controller
                  control={control}
                  name={`addresses.${index}.delivery__time`}
                  rules={{
                    required: {
                      message: "This field is required",
                      value: true,
                    },
                  }}
                  render={({
                    field: { onChange, value, ...rest },
                    fieldState: { error },
                  }) => (
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="delivery_time"
                        name="delivery_time_radio"
                        style={{
                          flexDirection: "row",
                          gap: "10px",
                          height: "54px",
                          alignItems: "center",
                        }}
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel
                          value="MORNING"
                          control={<Radio />}
                          label="Morning"
                        />
                        <FormControlLabel
                          value="EVENING"
                          control={<Radio />}
                          label="Evening"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.addresses?.[index].zone?.message}
                </p>
              </Box>
            </Box>
            <Box className={clsx(ContainersClasses.section__container)}>
              {dataWatch?.addresses?.[index]?.address__type === "APARTMENT" ? (
                <div>
                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Apartment Number
                      </p>
                      <TextField
                        placeholder="Apartment Number"
                        sx={{ width: "100%" }}
                        {...register(`addresses.${index}.aprt__number`)}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.addresses?.[index].aprt__number?.message}
                      </p>
                    </Box>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Floor Number
                      </p>
                      <TextField
                        placeholder="Floor Number"
                        sx={{ width: "100%" }}
                        {...register(`addresses.${index}.floor__number`)}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.addresses?.[index].floor__number?.message}
                      </p>
                    </Box>
                  </Box>
                </div>
              ) : dataWatch?.addresses?.[index]?.address__type === "HOUSE" ? (
                <div>
                  <Box className={clsx(ContainersClasses.section__container)}>
                    <Box>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        House Number
                      </p>
                      <TextField
                        placeholder="House Number"
                        sx={{ width: "50%" }}
                        {...register(`addresses.${index}.aprt__number`)}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.addresses?.[index].aprt__number?.message}
                      </p>
                    </Box>
                  </Box>
                </div>
              ) : (
                <div></div>
              )}
              {fields.length >= 1 && (
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Days
                  </p>
                  <Controller
                    name={`addresses.${index}.delivery__days`}
                    control={control}
                    rules={{ required: "Choose Tags" }}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          {...rest}
                          multiple
                          id="recipe-outlined"
                          value={
                            (dataWatch.addresses?.[index] &&
                              dataWatch.addresses?.[index].delivery__days) ||
                            []
                          }
                          options={days}
                          getOptionLabel={(option) => option.label || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputRef={ref}
                              variant="outlined"
                              sx={{ maxWidth: "100%" }}
                            />
                          )}
                          onChange={(_, data) => {
                            handleDays(index, data);
                            Change(data);
                          }}
                        />
                        <p className={InputClasses.input__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
              )}
            </Box>
          </div>
        );
      })}
   
    </>
  );
}

export default AddressInputForTranferBox;
