import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
//material ui
import { Box, Button, Typography } from "@mui/material";
//style
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import Table from "../../layout/commun/Table";
import FilterHeader from "../../pageHeader/FilterHeader";
import Status from "../../Status/Status";
//mutations&querries
import {
  NumberOfDays,
  PlanCondition,
  useGetPendingCustomersLazyQuery,
  useGetPendingCustomersQuery,
} from "../../../graphql/types";
//utils
import { columns } from "./Utils";
import { convertNumberOfDaysEnum, handleStatus } from "../../customer/Utils";
import { handlePayment } from "../../Utils";
import { SIDE_FILTER_OPTIONS_RENEWED_CUSTOMERS } from "../RenewedCustomers/Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//context
import { useAuth } from "../../../contextAPI";

function PendingCustomers() {
  const navigate = useNavigate();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");
  let auth = useAuth();

  //styles
  const ButtonClasses = useStylesButtons();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [idsProgram, setIdsProgram] = useState<string[]>([]);
  const [duration, setDuration] = useState<string[]>([]);
  const [numberOfDays, setNumberOfDays] = useState<NumberOfDays[]>([]);
  const [numberOfMeals, setNumberOfMeals] = useState<number[]>([]);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [search, setSearch] = useState<string>("");
  const [planStatus, setPlanStatus] = useState<PlanCondition[]>([]);

  //queries&mutations
  const dataGetCustomers = useGetPendingCustomersQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        nameCustomer: search,
        duration: duration,
        nbrOfDays: numberOfDays,
        meals: numberOfMeals,
        program: idsProgram,
        condition: planStatus,
        from: from,
        to: to,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
    fetchPolicy: "network-only",
  });

  const cashOnDeliveryCustomers = useMemo(
    () => dataGetCustomers.data?.getPendingCustomers.data || [],
    [dataGetCustomers.data]
  );

  const count = useMemo(
    () => dataGetCustomers.data?.getPendingCustomers.count || 0,
    [dataGetCustomers.data]
  );

  const [getPendingCustomersData, { loading: pendingCustomersListLoading }] =
    useGetPendingCustomersLazyQuery();

  const handleExportExcel = async () => {
    await getPendingCustomersData({
      variables: {
        input: {
          page: 1,
          documentsPerPage: count,
          duration: duration,
          nbrOfDays: numberOfDays,
          meals: numberOfMeals,
          program: idsProgram,
          condition: planStatus,
          from: from,
          to: to,
        },
      },
    })
      .then((data) => {
        if (count != 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const fileExtension = ".xlsx";
          const filteredCustomers = data?.data?.getPendingCustomers?.data?.map(
            (el, index) => {
              return {
                id: (index + 1).toString(),
                name: el.fname + " " + el.lname,
                phone: el.phone?.prefix + " " + el.phone?.number,
                email: el.email ? el.email : "null@null.com",
                date: moment(el.transactionDate).format("DD-MMM-YYYY"),
                price: auth.countryCurrency + " " + el?.plan?.price?.toFixed(0),
                status: el?.plan?.planCondition,
                payment: handlePayment(el?.paymentMethod || "UNSPECIFIED"),
                promoCode: el?.plan?.promoCode || "---",
                plan:
                  el.plan?.program?.name?.EN +
                  " , " +
                  convertNumberOfDaysEnum(el?.plan?.nbrOfDays || "FIVE_DAYS") +
                  " , " +
                  el?.plan?.meals?.length +
                  " meals " +
                  " , " +
                  el?.plan?.paymentPeriod,
              };
            }
          );
          const ws = XLSX.utils.json_to_sheet(filteredCustomers || []);
          ws["!cols"] = [
            { wpx: 40 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 200 },
            { wpx: 100 },
            { wpx: 70 },
            { wpx: 130 },
            { wpx: 70 },
            { wpx: 70 },
            { wpx: 250 },
          ];
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const res = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            res,
            "pending customers report" +
              " generated on " +
              moment().format("DD/MM/YYYY dddd LT") +
              fileExtension
          );
          return "Done";
        }
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };
  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderTableRows = () => {
    return cashOnDeliveryCustomers.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        id: item?.ref,
        name: isAuthorizedToSeeProfile ? (
          <a
            style={{ textDecoration: "none" }}
            href={`/admin/customers/view_customer/${item?._id}`}
          >
            <Button>{`${item?.fname} ${item?.lname}`}</Button>
          </a>
        ) : (
          item.fname + " " + item.lname
        ),
        mobile: item?.phone?.prefix + " " + item?.phone?.number,
        email: item?.email,
        plan: (
          <>
            <Typography style={{ fontSize: 12 }}>
              {item.plan?.program?.name?.EN +
                ", " +
                convertNumberOfDaysEnum(item?.plan?.nbrOfDays || "FIVE_DAYS")}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {item?.plan?.meals?.length +
                " meals, " +
                item?.plan?.paymentPeriod}
            </Typography>
          </>
        ),
        price: auth.countryCurrency + " " + item?.plan?.price?.toFixed(0),
        payment: handlePayment(item?.paymentMethod || "UNSPECIFIED"),
        promoCode: item?.plan?.promoCode || "---",
        date: moment(item?.transactionDate).format("DD-MMM-YYYY"),
        actions: isAuthorizedToSeeProfile && (
          <Button
            variant="contained"
            size="medium"
            className={ButtonClasses.button_cancel_form}
            onClick={() =>
              navigate(`/admin/customers/view_customer/${item?._id}`)
            }
          >
            Profile
          </Button>
        ),
        status: item?.plan?.planCondition ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Status
              status={item?.plan?.planCondition}
              statusText={handleStatus(item?.plan?.planCondition)}
            />
          </Box>
        ) : (
          "--"
        ),
      };
    });
  };

  return (
    <>
      <Box marginBottom={5}>
        <FilterHeader
          count={count}
          setSearchValue={setSearch}
          showSearch={true}
          pageTitle="Pending Customers"
          pageInfo="This is the list of customers who's payment is not completed yet with activated plans"
          setDuration={setDuration}
          durationData={duration}
          setNumberOfDays={setNumberOfDays}
          daysData={numberOfDays}
          setNumberOfMeals={setNumberOfMeals}
          mealsData={numberOfMeals}
          setIdsProgram={setIdsProgram}
          programIdData={idsProgram}
          setPlanStatus={setPlanStatus}
          planStatus={planStatus}
          setFrom={setFrom}
          from={from}
          setTo={setTo}
          to={to}
          filterConfig={SIDE_FILTER_OPTIONS_RENEWED_CUSTOMERS}
          loadExcel={pendingCustomersListLoading}
          generateExcel={handleExportExcel}
        />
      </Box>

      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetCustomers.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Pending Customers found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
    </>
  );
}

export default PendingCustomers;
