import { ColumnsProps } from "../layout/commun/Table";

export const chefsColumns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Chef Name",
    accessor: "name",
  },
  {
    header: "Chef Phone",
    accessor: "phone",
  },
  {
    header: "Chef Email",
    accessor: "email",
  },
  {
    header: "Chef Password",
    accessor: "password",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
