import { useMemo, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import { Box, IconButton, Menu, MenuItem, Switch } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
//components
import Table from "../../layout/commun/Table";
//utils
import { roleTableColumns } from "./Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { getMessageError } from "../../Utils";
//queries&mutations
import {
  GetRolesPermissionsListDocument,
  Roles,
  useGetRolesPermissionsListQuery,
  useUpdatePermissionMutation,
} from "../../../graphql/types";
//types
import { ApolloError } from "@apollo/client";

const RolesList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //styles
  const classes = useStylesMenu();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [role, setRole] = useState<string>("");

  //queries&mutations
  const [updateRolePermissions] = useUpdatePermissionMutation();
  const rolesData = useGetRolesPermissionsListQuery({
    fetchPolicy: "no-cache",
  });

  const rolesList = useMemo(
    () => rolesData.data?.getRolesPermissionsList || [],
    [rolesData.data]
  );

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClickMenu =
    (item: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setRole(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (role) {
      navigate(`/data/users_and_roles/update_role/${role}`);
    }
  };

  const updateStatusHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
    role: Roles
  ) => {
    await updateRolePermissions({
      variables: {
        input: { role: role, isActive: event.target.checked || false },
      },
      refetchQueries: [GetRolesPermissionsListDocument],
      onCompleted() {
        enqueueSnackbar("Role status changed succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const renderTableRows = () => {
    return (
      rolesList.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          NumberUsers: el.count,
          date: moment(el.createdAt).format("DD/MM/YYYY"),
          role: el.role,
          status: (
            <Switch
              defaultChecked={el?.isActive || false}
              onChange={(event) =>
                updateStatusHandler(event, el.role || "SALES")
              }
            />
          ),
          actions: (
            <IconButton onClick={handleClickMenu(el.role || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <div>
      <br />
      <TableContainer>
        <Table
          columns={roleTableColumns}
          data={renderTableRows()}
          emptyMessage="No roles found !"
          loading={rolesData.loading}
          numberOfFakeRow={30}
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: rolesList.length,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(rolesList.length / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("MANAGEMENT_ROLES_UPDATE_ADMIN") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>
    </div>
  );
};

export default RolesList;
