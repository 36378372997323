import React from "react";

function FoldersIllustration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="78"
      height="95"
      fill="none"
      viewBox="0 0 78 95"
    >
      <path
        fill="#1C2843"
        fillOpacity="0.15"
        stroke="#1C2843"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M61.292 88.987a5 5 0 01-5 5h-40a5 5 0 01-5-5v-35a5 5 0 015-5h12.5l5 7.5h22.5a5 5 0 015 5v27.5z"
      ></path>
      <g opacity="0.5">
        <path
          fill="#1C2843"
          fillOpacity="0.15"
          stroke="#1C2843"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18.046 6.114l-9.369 4.74a3 3 0 00-1.322 4.032L18.19 36.301a3 3 0 004.032 1.322l16.06-8.128a3 3 0 001.323-4.031l-7.45-14.722-14.11-4.628z"
        ></path>
        <path
          fill="#1C2843"
          fillOpacity="0.15"
          d="M17.857 6.69l4.741 9.369 9.369-4.741"
        ></path>
        <path
          stroke="#1C2843"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.857 6.69l4.741 9.369 9.369-4.741"
        ></path>
        <path
          fill="#1C2843"
          fillOpacity="0.15"
          stroke="#1C2843"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M61.278 27.712l-6.433-4.785a2.29 2.29 0 00-3.206.47L40.702 38.103a2.29 2.29 0 00.471 3.205l11.028 8.203a2.29 2.29 0 003.205-.471l7.52-10.11-1.648-11.217z"
        ></path>
        <path
          fill="#1C2843"
          fillOpacity="0.15"
          d="M60.82 27.779l-4.785 6.433 6.433 4.785"
        ></path>
        <path
          stroke="#1C2843"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M60.82 27.779l-4.785 6.433 6.433 4.785"
        ></path>
      </g>
      <path
        stroke="#1BA9A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M70.334 49.493L71 46.987l-2.494.667-2.506.679 1.827 1.827 1.84 1.827.667-2.494zM3.768 25.3l2.578.285-1.032-2.366-1.046-2.376-1.534 2.08-1.531 2.091 2.565.287z"
        opacity="0.2"
      ></path>
      <g
        stroke="#1BA9A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        opacity="0.2"
      >
        <path d="M2.6 37.336c-1.237-1.02.796-3.048-.782-4.349M7.817 41.637c-1.237-1.02.796-3.048-.782-4.348M2.6 37.336c1.237 1.02 2.84-1.363 4.417-.062M7.817 41.637c1.237 1.02 2.84-1.363 4.418-.062"></path>
      </g>
      <g
        stroke="#1BA9A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        opacity="0.2"
      >
        <path d="M73.182 23.513c.662-1.126 2.553.25 3.397-1.185M70.391 28.26c.662-1.126 2.553.25 3.397-1.186M73.182 23.513c-.662 1.125 1.46 2.11.615 3.544M70.391 28.26c-.662 1.125 1.46 2.109.616 3.544"></path>
      </g>
      <path
        stroke="#1BA9A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M33.266 39.621a2.801 2.801 0 01.62 3.92 2.801 2.801 0 01-3.92.619M57.438 4.162A2.801 2.801 0 0159.422.726a2.801 2.801 0 013.436 1.984"
        opacity="0.2"
      ></path>
      <g
        stroke="#1BA9A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        opacity="0.2"
      >
        <path d="M62.254 18.135L59 15.094M59.107 18.22l3.04-3.233"></path>
      </g>
      <path
        stroke="#1BA9A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M50 12.487c0 1.937-1.563 3.5-3.5 3.5a3.495 3.495 0 01-3.5-3.5c0-1.937 1.563-3.5 3.5-3.5s3.5 1.563 3.5 3.5zM43.296 53.759c-.697.432-1.634.24-2.067-.48-.433-.696-.24-1.631.48-2.063.698-.432 1.635-.24 2.068.48.433.695.217 1.607-.48 2.063zM36.296 3.759c-.697.432-1.634.24-2.067-.48-.433-.696-.24-1.631.48-2.063.698-.432 1.635-.24 2.068.48.433.695.217 1.607-.48 2.063z"
        opacity="0.2"
      ></path>
    </svg>
  );
}

export default FoldersIllustration;
