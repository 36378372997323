import { FilterElementsProps } from "../../Utils";
import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "From Customer",
    accessor: "fromCustomerName",
  },
  {
    header: "To Customer",
    accessor: "toCustomerName",
  },
];

export type SortElements = "CREATE_ASC" | "CREATE_DESC";

export const filterElements: Array<FilterElementsProps> = [
  {
    menuText: "New - Old",
    menuValue: "CREATE_DESC",
    selected: false,
  },
  {
    menuText: "Old - New",
    menuValue: "CREATE_ASC",
    selected: false,
  },
];
