import { ColumnsProps } from "../../layout/commun/Table";

export const reviewColumns: ColumnsProps = [
  {
    header: "Dish",
    accessor: "dish",
  },
  {
    header: "Feedback",
    accessor: "comment",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "Rating",
    accessor: "Rating",
  },
  {
    header: "Time",
    accessor: "time",
  },
];
