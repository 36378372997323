import React from "react";
import { DaysOfTheWeek } from "../../graphql/types";
import { StyledActifDayIcon, StyledInactifDayIcon } from "./DaysIcons.styles";

interface DayIconProps {
  days: DaysOfTheWeek[];
  actif?: boolean;
}

function DaysIcon({ days, actif }: DayIconProps) {
  return (
    <>
      {days.includes("MONDAY") ? (
        <StyledActifDayIcon>
          <span>M</span>
        </StyledActifDayIcon>
      ) : (
        <StyledInactifDayIcon>
          <span>M</span>
        </StyledInactifDayIcon>
      )}
      {days.includes("TUESDAY") ? (
        <StyledActifDayIcon>
          <span>T</span>
        </StyledActifDayIcon>
      ) : (
        <StyledInactifDayIcon>
          <span>T</span>
        </StyledInactifDayIcon>
      )}
      {days.includes("WEDNESDAY") ? (
        <StyledActifDayIcon>
          <span>W</span>
        </StyledActifDayIcon>
      ) : (
        <StyledInactifDayIcon>
          <span>W</span>
        </StyledInactifDayIcon>
      )}
      {days.includes("THURSDAY") ? (
        <StyledActifDayIcon>
          <span>T</span>
        </StyledActifDayIcon>
      ) : (
        <StyledInactifDayIcon>
          <span>T</span>
        </StyledInactifDayIcon>
      )}
      {days.includes("FRIDAY") ? (
        <StyledActifDayIcon>
          <span>F</span>
        </StyledActifDayIcon>
      ) : (
        <StyledInactifDayIcon>
          <span>F</span>
        </StyledInactifDayIcon>
      )}
      {days.includes("SATURDAY") ? (
        <StyledActifDayIcon>
          <span>S</span>
        </StyledActifDayIcon>
      ) : (
        <StyledInactifDayIcon>
          <span>S</span>
        </StyledInactifDayIcon>
      )}
      {days.includes("SUNDAY") ? (
        <StyledActifDayIcon>
          <span>S</span>
        </StyledActifDayIcon>
      ) : (
        <StyledInactifDayIcon>
          <span>S</span>
        </StyledInactifDayIcon>
      )}
    </>
  );
}

export default DaysIcon;
