import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { MenuItem, MenuList, ClickAwayListener } from "@mui/material";
import { options } from "../../Utils";

dayjs.extend(utc);

interface DateRangePickerProps {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  setOpen: (open: boolean) => void;
  setFrom: (from: Date | null) => void;
  setTo: (to: Date | null) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  selectedIndex,
  setSelectedIndex,
  setOpen,
  setFrom,
  setTo,
}) => {
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    option: string
  ) => {
    setSelectedIndex(index);
    setOpen(false);

    const getStartOfMonthUTC = (date: dayjs.Dayjs) =>
      date.utc().startOf("month").toDate();

    switch (option) {
      case "Today":
        setFrom(dayjs().toDate());
        setTo(dayjs().toDate());
        break;
      case "Yesterday":
        setFrom(dayjs().subtract(1, "d").toDate());
        setTo(dayjs().subtract(1, "d").toDate());
        break;
      case "Last 7 days":
        setFrom(dayjs().subtract(7, "d").toDate());
        setTo(dayjs().toDate());
        break;
      case "Last 30 days":
        setFrom(dayjs().subtract(30, "d").toDate());
        setTo(dayjs().toDate());
        break;
      case "This month":
        setFrom(getStartOfMonthUTC(dayjs()));
        setTo(dayjs().toDate());
        break;
      case "Last month":
        setFrom(getStartOfMonthUTC(dayjs().subtract(1, "month")));
        setTo(dayjs().subtract(1, "months").endOf("month").toDate());
        break;
      case "Custom range":
        setFrom(null);
        setTo(null);
        break;
      default:
        setFrom(null);
        setTo(null);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <MenuList id="split-button-menu" autoFocusItem>
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index, option)}
            sx={{ width: 175 }}
          >
            {option}
          </MenuItem>
        ))}
      </MenuList>
    </ClickAwayListener>
  );
};

export default DateRangePicker;
