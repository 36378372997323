import { useRef, useState, useMemo, useEffect, useCallback } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import moment from "moment";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useReactToPrint } from "react-to-print";
//material ui
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { Alert, Button, CircularProgress } from "@mui/material";
//queries & mutations
import {
  DishPayload,
  GetDishesPaginationDto,
  LunchMeal,
  MealDisplayV2Payload,
  ProgramMealType,
  useCreateProgramMenuMutation,
  useFindListMonthsProgramMenuQuery,
  useFindProgramMenuByMonthQuery,
  useGetAllProgramsQuery,
  useGetDishsWithPaginationLazyQuery,
  usePublishProgramMenuMutation,
} from "../../graphql/types";
//styles
import { useStylesContainers } from "../../styles/Containers__styles";
import { useStylesButtons } from "../../styles/Buttons__styles";
//utils
import { getMessageError } from "../Utils";
import {
  CheckIfDecemberIsLastMonth,
  CreateDaysForMonth,
  ShouldAddYearBeDisabled,
  ShouldPublishBeDisabled,
  getDatesByYear,
} from "./Utils";
import { useIsAuthorized } from "../../utils/Hooks/useIsAuthorized";
//components
import CalendarMenu from "./DailyMenu/CalendarMenu";
import ProgramMenuReportPrint from "./ProgramMenuReportPrint";
//types
import { mealPlanType, programMenu } from "./Types";
import { ApolloError } from "@apollo/client";
import debounce from "lodash/debounce";

const ProgramMenuCalendar = () => {
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const printProgramMenuRef = useRef<HTMLDivElement>(null);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //styles
  const ContainersClasses = useStylesContainers();
  const ButtonClasses = useStylesButtons();

  //state
  const [yearsList, setYearsList] = useState<string[]>([]);
  const [monthList, setMonthList] = useState([
    moment(new Date()).format("YYYY-MM-DD"),
  ]);
  const [monthDisplayList, setMonthDisplayList] = useState([
    moment(new Date()).format("YYYY-MM-DD"),
  ]);
  const [daysList, setdaysList] = useState<Date[]>([]);
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [currentDate, setCurrentDate] = useState<Date>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [mealPlans, setMealPlans] = useState<mealPlanType[]>([]);
  const [display, setDisplay] = useState<MealDisplayV2Payload>();
  const [menuId, setMenuId] = useState("");
  const [latestMonth, setLatestMonth] = useState(
    moment(new Date()).format("YYYY-MM")
  );
  const [warningDate, setWarningDate] = useState("");
  const [isHalfMenuFilled, setIsHalfMenuFilled] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [copiedData, setCopiedData] = useState<mealPlanType>();

  //react hook form
  const methods = useForm<programMenu>({
    defaultValues: {
      chosenProgram: null,
      importMonth: "",
    },
  });
  const { control, setValue } = methods;
  const dataWatch = useWatch({ control });

  //queries&mutations
  const [createProgramMenu, { loading: loadingCreateProgramMenu }] =
    useCreateProgramMenuMutation();

  const [publishProgramMenu, { loading: loadingPublishProgramMenu }] =
    usePublishProgramMenuMutation();

  const dataPrograms = useGetAllProgramsQuery({
    variables: { input: { page: 1, documentsPerPage: 2000 } },
  });

  const programs = useMemo(
    () => dataPrograms.data?.getAllPrograms.data || [],
    [dataPrograms.data]
  );

  const { data: monthListData, loading: monthListLoading } =
    useFindListMonthsProgramMenuQuery({
      variables: {
        findListMonthsProgramMenuInput: {
          programId: dataWatch?.chosenProgram?._id || "",
        },
      },
      fetchPolicy: "network-only",
      skip: !dataWatch?.chosenProgram?._id,
    });

  const [getDishsData, { data, loading: dishLoading }] =
    useGetDishsWithPaginationLazyQuery({});

  const {
    data: allMenuData,
    loading: menuLoading,
    refetch: refetchProgramMenu,
  } = useFindProgramMenuByMonthQuery({
    skip: dataWatch?.chosenProgram?._id ? false : true,
    variables: {
      findProgramMenuByMonthInput: {
        program: dataWatch?.chosenProgram?._id || "",
        month: Number(moment(currentMonth, "YYYY-MM").format("MM")),
        year: Number(moment(currentMonth, "YYYY-MM").format("YYYY")),
      },
    },
    fetchPolicy: "no-cache",
  });

  const { data: importMenuData } = useFindProgramMenuByMonthQuery({
    variables: {
      findProgramMenuByMonthInput: {
        program: dataWatch?.chosenProgram?._id || "",
        month: Number(moment(dataWatch?.importMonth, "YYYY-MM").format("MM")),
        year: Number(moment(dataWatch?.importMonth, "YYYY-MM").format("YYYY")),
      },
    },
    skip:
      dataWatch?.chosenProgram?._id && dataWatch?.importMonth ? false : true,
    fetchPolicy: "no-cache",
  });

  //this useEffect is used to set up the list of months and days
  useEffect(() => {
    if (
      monthListData?.findListMonthsProgramMenu &&
      monthListData?.findListMonthsProgramMenu?.list?.length > 0
    ) {
      let tempMonthList = monthListData?.findListMonthsProgramMenu?.list;
      //to sort the months list in ascending order
      tempMonthList.sort(function (a: string, b: string) {
        let c: Date = new Date(a);
        let d: Date = new Date(b);
        return c.getTime() - d.getTime();
      });
      //to get the years list
      const yearsSet = new Set(tempMonthList.map((date) => date.split("-")[0]));
      const years = Array.from(yearsSet);
      setYearsList(years);
      setMonthList([...tempMonthList]);
      setdaysList(
        CreateDaysForMonth(monthListData?.findListMonthsProgramMenu?.list[0])
      );
      const currentYearIndex = years.findIndex(
        (el) => el === moment().year().toString()
      );
      //if current year doesn't exist inside the list, display first year
      setCurrentYear(
        currentYearIndex === -1 ? years[0] : years[currentYearIndex]
      );
      const currentMonthIndex =
        monthListData?.findListMonthsProgramMenu?.list.findIndex(
          (el) => el === moment().format("YYYY-M")
        );
      //if current month doesn't exist inside the list , display first month
      setCurrentMonth(
        currentMonthIndex === -1
          ? monthListData?.findListMonthsProgramMenu?.list[0]
          : monthListData?.findListMonthsProgramMenu?.list[currentMonthIndex]
      );
      setMonthDisplayList(
        getDatesByYear(
          currentYearIndex === -1 ? years[0] : years[currentYearIndex],
          tempMonthList
        )
      );
      setCurrentDate(new Date());
      setLatestMonth(
        monthListData?.findListMonthsProgramMenu?.list[
          monthListData?.findListMonthsProgramMenu?.list.length - 1
        ]
      );
    } else {
      //values to show before selecting a program
      setMonthList([moment(new Date()).format("YYYY-MM")]);
      setYearsList([moment(new Date()).format("YYYY")]);
      setCurrentMonth(moment(new Date()).format("YYYY-MM"));
      setLatestMonth(moment(new Date()).format("YYYY-MM"));
      setdaysList(CreateDaysForMonth(moment().toLocaleString()));
      setCurrentDate(new Date());
    }
  }, [monthListData]);

  const changeMonth = (item: string) => {
    setCurrentMonth(item);
    setdaysList(CreateDaysForMonth(item));
    setCurrentDate(CreateDaysForMonth(item)[0]);
  };

  const changeYear = (item: string) => {
    setCurrentYear(item);
    let yearsDisplay = getDatesByYear(item, monthList);
    setMonthDisplayList(yearsDisplay);
    setCurrentMonth(yearsDisplay[0]);
  };

  const deleteDish = (dishId: string, date: Date, category: LunchMeal) => {
    let tempMealPlanArray = [...mealPlans];
    let objIndex = tempMealPlanArray.findIndex((obj) => obj.date === date);
    let mealIndex = tempMealPlanArray[objIndex].meals.findIndex(
      (obj) => obj.typeOfMeal === category
    );
    let tempDishes = tempMealPlanArray[objIndex].meals[mealIndex].dishes.filter(
      (obj) => obj.id !== dishId
    );
    tempMealPlanArray[objIndex].meals[mealIndex].dishes = tempDishes;
    setMealPlans([...tempMealPlanArray]);
  };

  const getListOfDishes = (param: GetDishesPaginationDto) => {
    getDishsData({
      variables: {
        input: param,
      },
    });
  };

  const debouncedSendRequest = useCallback(debounce(getListOfDishes, 500), []);

  const setDishData = (category: LunchMeal, date: Date, dishName: string) => {
    setSelectedDate(date);
    debouncedSendRequest({
      page: 1,
      documentsPerPage: 30,
      name: dishName,
      dishSuitability: [category],
      programs: dataWatch?.chosenProgram?._id
        ? [dataWatch?.chosenProgram?._id]
        : [],
      isActive: true,
      isSauce: false,
      withoutSauce: true,
    });
  };

  const pushDish = (category: LunchMeal, date: Date, dish: DishPayload) => {
    let tempMealPlanArray = [...mealPlans];
    let elementByDateIndex = mealPlans?.findIndex(
      (item) => item?.date === date
    );
    let mealsIndex = tempMealPlanArray[elementByDateIndex]?.meals?.findIndex(
      (item) => item.typeOfMeal === category
    );
    if (mealsIndex === -1) {
      tempMealPlanArray[elementByDateIndex]?.meals?.push({
        typeOfMeal: category as ProgramMealType,
        dishes: [
          {
            id: dish?._id ?? "",
            name: dish?.name?.EN ?? "",
            code: dish?.code ?? "",
          },
        ],
      });

      let objIndex = tempMealPlanArray.findIndex((obj) => obj.date === date);
      tempMealPlanArray[objIndex] = tempMealPlanArray[elementByDateIndex];
    } else if (
      tempMealPlanArray[elementByDateIndex].meals[mealsIndex].dishes.length < 20
    ) {
      tempMealPlanArray[elementByDateIndex].meals[mealsIndex]?.dishes.push({
        id: dish?._id ?? "",
        name: dish?.name?.EN ?? "",
        code: dish?.code ?? "",
      });
      let objIndex = tempMealPlanArray[elementByDateIndex]?.meals?.findIndex(
        (obj) => obj.typeOfMeal === category
      );
      tempMealPlanArray[elementByDateIndex].meals[objIndex] =
        tempMealPlanArray[elementByDateIndex].meals[mealsIndex];

      let planIndex = tempMealPlanArray.findIndex((obj) => obj.date === date);
      tempMealPlanArray[planIndex] = tempMealPlanArray[elementByDateIndex];
    }
    setMealPlans([...tempMealPlanArray]);
  };

  let dishList = data?.getDishsWithPagination.data as DishPayload[];

  //this function is used to set the custom meal names if they exist (ex:iftar insted of lunch ...)
  const onSelectProgram = (id: string) => {
    if (programs?.length) {
      setValue("importMonth", ""); //to empty import month when selecting another program
      programs.map((el) => {
        if (el._id === id) {
          if (el.displayMeals?.breakfast?.EN !== "") {
            setDisplay(el?.displayMeals || {});
          } else {
            setDisplay(undefined);
          }
        }
      });
    }
  };

  const addMonth = () => {
    const nextMonth = moment(latestMonth, "YYYY-MM")
      .add(1, "months")
      .format("YYYY-MM");
    let tempMonthList = monthList;
    tempMonthList.push(nextMonth);
    setMonthList(tempMonthList);
    setMonthDisplayList(getDatesByYear(currentYear, tempMonthList));
    setLatestMonth(nextMonth);
  };

  const addYear = () => {
    const nextYear = moment(yearsList[yearsList.length - 1], "YYYY")
      .add(1, "years")
      .format("YYYY");
    let tempYearsList = yearsList;
    tempYearsList.push(nextYear);
    setYearsList(tempYearsList);
    addMonth();
  };

  const onDaySelectedHandler = (item: Date, index: number) => {
    scrollToItem(index);
    setCurrentDate(item);
  };

  const setRefs = (ref: never, index: number) => {
    refs.current[index] = ref;
  };

  const scrollToItem = (index: number) => {
    (refs.current[index] as HTMLDivElement).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const handleProgramMenuPrint = useReactToPrint({
    content: () => printProgramMenuRef?.current,
    documentTitle:
      "Program Menu Report " +
      " generated on " +
      moment().format("DD/MM/YYYY dddd"),
    pageStyle: "@page { size: landscape; }",
  });

  function shuffleArray(x: number) {
    let arr = Array.from(Array(x).keys());
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

  const onShuffleHandler = () => {
    const shuffledMealPlans: mealPlanType[] = JSON.parse(
      JSON.stringify(mealPlans)
    );
    const randomIndexArray = shuffleArray(mealPlans.length);
    for (let i = 0; i < shuffledMealPlans.length; i++) {
      const randomIndex = randomIndexArray[i];
      const test = [...mealPlans[randomIndex].meals];
      shuffledMealPlans[i].meals = test;
    }
    setMealPlans(shuffledMealPlans);
  };

  const onFillSecondHalfHandler = () => {
    let mealPlansCopy = [...mealPlans];
    let j = 0;
    for (let i = 15; i < mealPlans.length; i++) {
      mealPlansCopy[i].meals = mealPlansCopy[j].meals;
      j++;
    }
    setMealPlans(mealPlansCopy);
  };

  const onSave = async () => {
    if (!dataWatch?.chosenProgram?._id) return;
    //this code is meant to format mealplans for the api

    const formattedMealsArray = mealPlans.map((el) => {
      let meals = el.meals.map((element) => {
        let formattedDishes = element.dishes.map((el) => {
          return { id: el.id, name: el.name };
        });
        return {
          typeOfMeal: element.typeOfMeal,
          dishes: formattedDishes /* element.dishes */,
        };
      });
      return { meals: meals, date: el.date };
    });
    try {
      await createProgramMenu({
        variables: {
          createProgramMenuInput: {
            id: menuId,
            program: dataWatch?.chosenProgram?._id || "",
            year: Number(moment(currentMonth, "YYYY-MM").format("YYYY")),
            month: Number(moment(currentMonth, "YYYY-MM").format("MM")),
            mealPlans: formattedMealsArray,
          },
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
          setMenuId(data.createProgramMenu._id);
          enqueueSnackbar("program menu updated succuessfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
          refetchProgramMenu();
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const onPublish = async () => {
    if (!dataWatch?.chosenProgram?._id || warningDate.length > 0) return;
    //test whether any empty slots exist to warn the user
    let emptySlot = false;
    outerLoop: for (let i = 0; i < mealPlans.length; i++) {
      for (let j = 0; j < mealPlans[i].meals.length; j++) {
        if (mealPlans[i].meals[j].dishes.length === 0) {
          emptySlot = true;
          break outerLoop;
        }
      }
    }

    if (emptySlot) {
      enqueueSnackbar(
        "You need to have all dishes selecetd to publish the menu",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
      return;
    }

    const formattedMealsArray = mealPlans.map((el) => {
      let meals = el.meals.map((element) => {
        let formattedDishes = element.dishes.map((el) => {
          return { id: el.id, name: el.name };
        });
        return {
          typeOfMeal: element.typeOfMeal,
          dishes: formattedDishes /* element.dishes */,
        };
      });
      return { meals: meals, date: el.date };
    });
    try {
      await createProgramMenu({
        variables: {
          createProgramMenuInput: {
            id: menuId,
            program: dataWatch?.chosenProgram?._id || "",
            year: Number(moment(currentMonth, "YYYY-MM").format("YYYY")),
            month: Number(moment(currentMonth, "YYYY-MM").format("MM")),
            mealPlans: formattedMealsArray,
          },
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
          enqueueSnackbar("program menu updated succuessfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
          try {
            publishProgramMenu({
              variables: {
                input: data.createProgramMenu._id,
              },
              fetchPolicy: "network-only",
              onCompleted: () => {
                enqueueSnackbar("program menu published succuessfully", {
                  variant: "success",
                  anchorOrigin: { vertical: "bottom", horizontal: "center" },
                });
              },
            });
          } catch (err) {
            console.log("publish", err);

            const error = getMessageError(err as ApolloError);
            enqueueSnackbar(error, {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
          }
        },
      });
    } catch (err) {
      console.log("create", err);

      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const swapDish = (
    indexSource: number,
    indexDestination: number,
    type: LunchMeal,
    date: Date
  ) => {
    const dayIndex = mealPlans.findIndex((el) => el.date === date);
    const mealTypeIndex = mealPlans[dayIndex].meals.findIndex(
      (el) => el.typeOfMeal === type
    );
    const updatedMeals = [...mealPlans];
    const DayDishes = [...mealPlans[dayIndex].meals[mealTypeIndex].dishes];
    const [reorderedItem] = DayDishes.splice(indexSource, 1);
    DayDishes.splice(indexDestination, 0, reorderedItem);
    updatedMeals[dayIndex].meals[mealTypeIndex].dishes = DayDishes;
    setMealPlans(updatedMeals);
  };

  //this useEffect is used to fill the menu
  useEffect(() => {
    let tempMenuData = [];
    if (allMenuData?.findProgramMenuByMonth) {
      setMenuId(allMenuData?.findProgramMenuByMonth?._id);

      //to fill the menu item
      let result = allMenuData?.findProgramMenuByMonth?.mealPlans.map((el) => {
        let meals = el.meals.map((element) => {
          let dishes = element.dishes.map((elem) => {
            return {
              id: elem._id,
              name: elem?.name?.EN || "",
              code: elem.code,
            };
          });
          return { typeOfMeal: element.typeOfMeal, dishes: dishes };
        });

        return { meals: meals, date: el.date };
      });
      tempMenuData = [...result];
    } else {
      //to create an empty menu list in case the API call didn't happen
      const daysCount = moment(currentMonth, "YYYY-MM").daysInMonth();
      setMenuId("");
      for (let i = 1; i < Number(daysCount) + 1; i++) {
        let rowDate = currentMonth + "-" + (i >= 10 ? i : "0" + i);
        tempMenuData[i - 1] = {
          date: rowDate,
          meals: [],
        };
      }
    }
    setMealPlans(tempMenuData as mealPlanType[]);
  }, [currentMonth, allMenuData]);

  //this useEffect is used to fill the menu when it's empty using import
  useEffect(() => {
    let tempMenuData = [];
    if (importMenuData?.findProgramMenuByMonth) {
      //to fill the menu item
      let result = importMenuData?.findProgramMenuByMonth?.mealPlans.map(
        (el, index) => {
          let meals = el.meals.map((element) => {
            let dishes = element.dishes.map((elem) => {
              return {
                id: elem._id,
                name: elem?.name?.EN || "",
              };
            });
            return { typeOfMeal: element.typeOfMeal, dishes: dishes };
          });

          return { meals: meals, date: mealPlans[index]?.date };
        }
      );
      //this code is used to make sure the current number of days displayed after import is correct even if the imported month have more or less days
      let List = mealPlans;
      if (
        importMenuData?.findProgramMenuByMonth?.mealPlans.length < List.length
      ) {
        for (
          let i = 0;
          i < importMenuData?.findProgramMenuByMonth?.mealPlans.length;
          i++
        ) {
          List[i] = result[i] as mealPlanType;
        }
      } else if (
        importMenuData?.findProgramMenuByMonth?.mealPlans.length > List.length
      ) {
        List = result.slice(0, List.length) as mealPlanType[];
      } else {
        List = result as mealPlanType[];
      }

      tempMenuData = [...List];
      setMealPlans(tempMenuData as mealPlanType[]);
    }
  }, [importMenuData]);

  //to populate the warning
  useEffect(() => {
    let nCount = mealPlans.length;
    for (let i = 0; i < nCount; i++) {
      if (mealPlans[i].meals.length > 0) {
        if (
          mealPlans[i].meals.findIndex(
            (obj) => obj.typeOfMeal === "BREAKFAST"
          ) < 0
        ) {
          setWarningDate(
            moment(mealPlans[i]?.date).format("dddd") + " " + "breakfast"
          );
          break;
        } else if (
          mealPlans[i].meals.findIndex((obj) => obj.typeOfMeal === "LUNCH") < 0
        ) {
          setWarningDate(
            moment(mealPlans[i]?.date).format("dddd") + " " + "lunch"
          );
          break;
        } else if (
          mealPlans[i].meals.findIndex((obj) => obj.typeOfMeal === "DINNER") <
            0 &&
          display
        ) {
          setWarningDate(
            moment(mealPlans[i]?.date).format("dddd") + " " + "dinner"
          );
          break;
        } else if (
          mealPlans[i].meals.findIndex((obj) => obj.typeOfMeal === "SNACKS") < 0
        ) {
          setWarningDate(
            moment(mealPlans[i]?.date).format("dddd") + " " + "snacks"
          );
          break;
        } else setWarningDate("");
      } else {
        setWarningDate(
          moment(mealPlans[i]?.date).format("dddd") + " " + "breakfast"
        );
        break;
      }
    }
  }, [mealPlans]);

  //to check whether the first half of the menu is filled
  const checkHalfMenuIsFilled = (mealPlans: mealPlanType[]) => {
    for (let i = 0; i < 15; i++) {
      if (mealPlans[i]?.meals?.length < 4) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setIsHalfMenuFilled(checkHalfMenuIsFilled(mealPlans));
  }, [mealPlans]);

  const handleCopyClick = (data: mealPlanType) => {
    setCopiedData(data);
    setLinkCopied(true);
    window.focus();
    setTimeout(() => {
      setLinkCopied(false);
    }, 1000);
  };

  const handlePasteMenuInADay = (date: Date) => {
    const menu = mealPlans.map((el) => {
      if (el.date === date) {
        return { ...el, meals: copiedData?.meals ?? [] };
      } else {
        return { ...el };
      }
    });
    setMealPlans(menu);
  };

  //render
  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        {warningDate && dataWatch.chosenProgram?._id && !menuLoading && (
          <Box sx={{ position: "absolute", top: 100, right: 10 }}>
            <Alert sx={{ width: "370px", textAlign: "end" }} severity="error">
              {warningDate} is missing , please assign a dish
            </Alert>
          </Box>
        )}
        <Box width="20%">
          <p className={ContainersClasses.section__subtitle}>
            Select a program
          </p>
          <Controller
            name="chosenProgram"
            control={control}
            render={({
              field: { onChange: Change, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <>
                <Autocomplete
                  id="chosenProgram"
                  options={programs}
                  onChange={(_, value) => {
                    Change(value);
                    onSelectProgram(value?._id || "");
                  }}
                  value={dataWatch?.chosenProgram}
                  getOptionLabel={(option) => option?.name?.EN || ""}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        color="secondary"
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.name?.EN}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select a program" />
                  )}
                />
              </>
            )}
          />
        </Box>
        <Box width="20%" marginLeft={2}>
          <p className={ContainersClasses.section__subtitle}>Import from</p>
          <Controller
            name="importMonth"
            control={control}
            render={({
              field: { onChange: Change, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <>
                <Autocomplete
                  id="importMonth"
                  options={monthList}
                  onChange={(_, value) => {
                    Change(value);
                  }}
                  value={dataWatch?.importMonth}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        color="secondary"
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select a program" />
                  )}
                />
              </>
            )}
          />
        </Box>
        {useIsAuthorized("FOOD_PROGRAMSMENU_CREATE") && (
          <Button
            sx={{ marginTop: 5 }}
            variant="contained"
            onClick={onSave}
            className={clsx(ButtonClasses.button_open_list_page)}
            disabled={!dataWatch.chosenProgram?._id || loadingCreateProgramMenu}
          >
            {loadingCreateProgramMenu && (
              <CircularProgress
                size={20}
                style={{ color: "white", marginRight: 6 }}
              />
            )}
            Save
          </Button>
        )}

        <Button
          sx={{ marginTop: 5 }}
          variant="contained"
          onClick={handleProgramMenuPrint}
          className={clsx(ButtonClasses.button_open_list_page)}
          disabled={!dataWatch.chosenProgram?._id || loadingCreateProgramMenu}
        >
          {loadingCreateProgramMenu && (
            <CircularProgress
              size={20}
              style={{ color: "white", marginRight: 6 }}
            />
          )}
          Export PDF
        </Button>

        <Button
          sx={{ marginTop: 5 }}
          variant="contained"
          onClick={onShuffleHandler}
          className={clsx(ButtonClasses.button_open_list_page)}
          disabled={!dataWatch.chosenProgram?._id || loadingCreateProgramMenu}
        >
          {loadingCreateProgramMenu && (
            <CircularProgress
              size={20}
              style={{ color: "white", marginRight: 6 }}
            />
          )}
          Shuffle
        </Button>

        <Button
          sx={{ marginTop: 5 }}
          variant="contained"
          onClick={onFillSecondHalfHandler}
          className={clsx(ButtonClasses.button_open_list_page)}
          disabled={
            !dataWatch.chosenProgram?._id ||
            loadingCreateProgramMenu ||
            !isHalfMenuFilled
          }
        >
          {loadingCreateProgramMenu && (
            <CircularProgress
              size={20}
              style={{ color: "white", marginRight: 6 }}
            />
          )}
          Fill Second half
        </Button>

        {useIsAuthorized("FOOD_PROGRAMSMENU_PUBLISH") && (
          <Button
            sx={{ marginTop: 5 }}
            variant="contained"
            onClick={onPublish}
            className={clsx(ButtonClasses.button_open_list_page)}
            disabled={ShouldPublishBeDisabled(
              dataWatch.chosenProgram?._id || "",
              loadingCreateProgramMenu,
              loadingPublishProgramMenu,
              allMenuData?.findProgramMenuByMonth?.isPublished || false,
              dataWatch?.chosenProgram?.isLimited || false,
              warningDate
            )}
          >
            {loadingCreateProgramMenu ||
              (loadingPublishProgramMenu && (
                <CircularProgress
                  size={20}
                  style={{ color: "white", marginRight: 6 }}
                />
              ))}
            Publish Menu
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        {monthListLoading || menuLoading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "150px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box sx={{ flex: 1, marginBottom: "10px" }}>
              {yearsList?.map((item) => {
                return (
                  <Button
                    variant="contained"
                    key={item}
                    sx={{
                      marginRight: "7px",
                      borderRadius: "2px",
                      height: "24px",
                      backgroundColor:
                        moment(currentYear, "YYYY").format("YYYY") ===
                        moment(item, "YYYY").format("YYYY")
                          ? "#232222"
                          : "#C4C4C4",
                    }}
                    onClick={() => changeYear(item)}
                  >
                    {moment(item, "YYYY").format("YYYY")}
                  </Button>
                );
              })}
              <Button
                onClick={addYear}
                variant="contained"
                sx={{
                  marginRight: "7px",
                  borderRadius: "2px",
                  height: "24px",
                  backgroundColor: "#47BC45",
                }}
                disabled={ShouldAddYearBeDisabled(
                  monthList,
                  dataWatch?.chosenProgram
                )}
              >
                Add year
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              {monthDisplayList?.map((item) => {
                return (
                  <Button
                    variant="contained"
                    key={item}
                    sx={{
                      marginRight: "7px",
                      borderRadius: "2px",
                      height: "24px",
                      backgroundColor:
                        moment(currentMonth, "YYYY-MM").format("YYYY-MM") ===
                        moment(item, "YYYY-MM").format("YYYY-MM")
                          ? "#232222"
                          : "#C4C4C4",
                    }}
                    onClick={() => changeMonth(item)}
                  >
                    {moment(item, "YYYY-MM").format("MMM YYYY")}
                  </Button>
                );
              })}
              <Button
                onClick={addMonth}
                variant="contained"
                sx={{
                  marginRight: "7px",
                  borderRadius: "2px",
                  height: "24px",
                  backgroundColor: "#47BC45",
                }}
                disabled={
                  (methods.watch("chosenProgram") ? false : true) ||
                  CheckIfDecemberIsLastMonth(monthDisplayList)
                }
              >
                Add month
              </Button>
            </Box>
            <Box
              fontFamily="Poppins"
              fontWeight="400"
              fontSize={16}
              marginTop={2.5}
            >
              {moment(currentMonth).format("MMMM YYYY")}
            </Box>
            <Box display="flex" flexDirection="row" marginTop={2.5}>
              {daysList?.map((item, index) => {
                return (
                  <Box
                    key={item.toDateString()}
                    sx={{
                      marginRight: "7px",
                      height: "40px",
                      width: "40px",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "4px 8px 6px rgba(208,202,205,0.8)",
                      cursor: "pointer",
                      backgroundColor:
                        moment(currentDate).format("DD-MM-YYYY") ===
                        moment(item).format("DD-MM-YYYY")
                          ? "#232222"
                          : "#fff",
                      color:
                        moment(currentDate).format("DD-MM-YYYY") ===
                        moment(item).format("DD-MM-YYYY")
                          ? "#fff"
                          : "#000",
                    }}
                    onClick={() => onDaySelectedHandler(item, index)}
                  >
                    {moment(item, "YYYY-MM").format("D")}
                  </Box>
                );
              })}
            </Box>
            <Box
              fontFamily="Poppins"
              fontWeight="400"
              fontSize={16}
              marginTop={2.5}
            >
              {moment(currentDate).format("D MMMM YYYY - dddd")}
            </Box>
            <Box sx={{ marginTop: "15px", height: "50px" }}>
              {mealPlans?.map((item, index) => {
                return (
                  <Box
                    ref={(ref) => setRefs(ref as never, index)}
                    key={new Date(item.date).toDateString()}
                  >
                    <CalendarMenu
                      getCategory={setDishData}
                      pushDish={pushDish}
                      deleteDish={deleteDish}
                      dishLoading={dishLoading}
                      swapDish={swapDish}
                      dishList={dishList}
                      data={item}
                      selectedDate={selectedDate || new Date()}
                      display={display}
                      menuId={menuId}
                      linkCopied={linkCopied}
                      handleCopyClick={handleCopyClick}
                      handlePasteMenuInADay={handlePasteMenuInADay}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
      <div style={{ display: "none" }}>
        <ProgramMenuReportPrint
          generateReport={allMenuData}
          ref={printProgramMenuRef}
        />
      </div>
    </Box>
  );
};

export default ProgramMenuCalendar;
