import React from "react";
import moment from "moment";
//types
import { ReceiptPrintProps } from "../Types";
//logos
import dieture_logo_black from "../../../public/icons/dietureLogoSvg.svg";
import mailLogo from "../../../public/icons/emailSvg.svg";
import websiteLogo from "../../../public/icons/websiteSvg.svg";
import whatsappLogo from "../../../public/icons/whatsappSvg.svg";
import addressLogo from "../../../public/icons/addressSvg.svg";
//utils
import { handleNbrDays, handlePayment } from "../../Utils";
import { mealsConverter } from "./Utils";

const DIETURE_EMAIL = "hello@dieture.com";
const DIETURE_WEBSITE = "www.dieture.com";
const DIETURE_WHATSAPP = "+974 333 44 679";
const DIETURE_ADDRESS = "Al Khafji Street, Duhail South - Qatar 4471 2343";

const ReceiptPrint = React.forwardRef<HTMLDivElement, ReceiptPrintProps>(
  ({ receiptData, clientName, clientMobile }, ref) => {
    const transactionTypeMap: { [key: string]: string } = {
      CUSTOM: "CUSTOM PLAN",
      EXTEND_PLAN: "PLAN EXTEND",
      ADDON: "PLAN ADDON ",
      PLAN: "NEW PLAN",
      REFUND: "REFUND",
      SWITCH: "PLAN SWITCH",
    };
    const transactionType: string = receiptData?.kind || "---";
    return (
      <div ref={ref}>
        <div
          style={{
            margin: "3vh 2vh 3vh 2vh",
            height: "94vh",
            border: "4px solid #000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div
              style={{
                width: "30%",
                height: "6%",
                backgroundColor: "#000",
                display: "flex",
                alignSelf: "flex-end",
                flexDirection: "column",
                color: "#fff",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>PAYMENT RECEIPT</div>
              <div>إيصال الدفع</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={dieture_logo_black}
                height="60px"
                width="150px"
                alt="dieture_logo_black"
                style={{ margin: "10px" }}
              />
              <div>Kitchen W.L.L</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                margin: "20px 5px 0px 20px",
                paddingRight: "6.5%",
              }}
            >
              <div>
                <div style={{ fontFamily: "Poppins", fontWeight: "400" }}>
                  Name : {clientName}
                </div>
                <div style={{ fontFamily: "Poppins", fontWeight: "400" }}>
                  Contact :{clientMobile}
                </div>
                <div style={{ fontFamily: "Poppins", fontWeight: "400" }}>
                  Date :{moment(receiptData?.date).format("DD/MM/YYYY")}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
              
                <div style={{ fontFamily: "Poppins", fontWeight: "400" }}>
                  Receipt No :{"RE" + receiptData?.receiptNo}
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    paddingTop: "25px",
                  }}
                >
                  Transaction ID : {receiptData?.merchantReference || ""}
                </div>
              </div>
            </div>
            <table
              style={{
                marginTop: "20px",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tr>
                <th
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                  }}
                >
                  <div style={{ fontFamily: "Poppins", fontWeight: "700" }}>
                    Plan Name
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "700",
                    }}
                  >
                    اسم الباقة{" "}
                  </div>
                </th>
                <th
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                  }}
                >
                  <div style={{ fontFamily: "Poppins", fontWeight: "700" }}>
                    Number of meals
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "700",
                    }}
                  >
                    عدد الوجبات
                  </div>
                </th>
                <th
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                  }}
                >
                  <div style={{ fontFamily: "Poppins", fontWeight: "700" }}>
                    Number of boxes
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "700",
                    }}
                  >
                    عدد العلب
                  </div>
                </th>
                <th
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                  }}
                >
                  <div style={{ fontFamily: "Poppins", fontWeight: "700" }}>
                    Start date
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "700",
                    }}
                  >
                    تاريخ البدء
                  </div>
                </th>
                <th
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    width: "150px",
                  }}
                >
                  <div style={{ fontFamily: "Poppins", fontWeight: "700" }}>
                    Plan price
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "700",
                    }}
                  >
                    سعر الباقة
                  </div>
                </th>
              </tr>
              <tr style={{ height: "110px" }}>
                <td
                  rowSpan={3}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  <div>
                    {receiptData?.kind === "SWITCH" && (
                      <s>
                        {receiptData?.customerPlan?.switchedFrom?.program?.name
                          ?.EN === receiptData?.customerPlan?.program?.name?.EN
                          ? " "
                          : receiptData?.customerPlan?.switchedFrom?.program
                              ?.name?.EN}
                      </s>
                    )}
                  </div>
                  <div>{receiptData?.customerPlan?.program?.name?.EN}</div>
                </td>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  <div>
                    {receiptData?.kind == "SWITCH" && (
                      <s>
                        {receiptData?.customerPlan?.meals?.length ===
                        receiptData?.customerPlan?.switchedFrom?.meals?.length
                          ? " "
                          : `${receiptData?.customerPlan?.switchedFrom?.meals?.length} meals`}
                      </s>
                    )}
                  </div>
                  {receiptData?.customerPlan?.meals.length} meals
                </td>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  <div>
                    {receiptData?.kind === "SWITCH" && (
                      <s>
                        {receiptData?.customerPlan?.switchedFrom?.nbrOfBoxes ===
                        receiptData?.customerPlan?.nbrOfBoxes
                          ? " "
                          : `${receiptData?.customerPlan?.switchedFrom?.nbrOfBoxes} Boxes`}
                      </s>
                    )}
                  </div>
                  {receiptData?.customerPlan?.nbrOfBoxes}
                  {receiptData?.customerPlan?.nbrOfBoxes === 1
                    ? " Box"
                    : " Boxes"}
                </td>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  {moment(receiptData?.customerPlan?.startDate).format(
                    "DD/MM/YYYY"
                  )}
                </td>
                <td
                  rowSpan={3}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  {`${receiptData?.customerPlan?.totalPrice} ${receiptData?.currency}`}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  <div>Number of Dishes</div>
                  <div>عدد الاطباق</div>
                </td>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  <div>Number of Days</div>
                  <div>عدد الأيام</div>
                </td>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  <div>Plan validity</div>
                  <div>صلاحية الباقة</div>
                </td>
              </tr>
              <tr style={{ height: "110px" }}>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  {mealsConverter(receiptData?.customerPlan?.meals || []).map(
                    (el) => {
                      return <div key={el}>{el}</div>;
                    }
                  )}
                </td>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  <div>
                    <div>
                      {receiptData?.kind === "SWITCH" &&
                        receiptData?.customerPlan?.switchedFrom?.nbrOfDays !==
                          receiptData?.customerPlan?.nbrOfDays && (
                          <s>
                            {handleNbrDays(
                              receiptData?.customerPlan?.switchedFrom
                                ?.nbrOfDays,
                              "number"
                            )}{" "}
                            a week
                          </s>
                        )}
                    </div>
                    {handleNbrDays(
                      receiptData?.customerPlan?.nbrOfDays,
                      "number"
                    )}{" "}
                    a week
                  </div>
                </td>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  <div>
                    {moment(receiptData?.customerPlan?.expiryDate).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                </td>
              </tr>
              {receiptData?.customerPlan?.totalDiscount ? (
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      paddingLeft: 15,
                      fontWeight: "700",
                      borderRightColor: "#fff",
                    }}
                  >
                    SUB TOTAL
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      textAlign: "right",
                      fontWeight: "700",
                      borderLeftColor: "#fff",
                      borderRightColor: "#fff",
                    }}
                  >
                    المجموع الاصلي
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    {`${receiptData?.currency} ${receiptData?.customerPlan?.totalPrice} `}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td
                  colSpan={3}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    paddingLeft: 15,
                    fontWeight: "500",
                    borderRightColor: "#fff",
                    height: "30px",
                  }}
                >
                  Mode of Payment
                </td>
                <td
                  colSpan={1}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "right",
                    fontWeight: "700",
                    borderLeftColor: "#fff",
                    borderRightColor: "#fff",
                  }}
                >
                  طريقة الدفع
                </td>
                <td
                  colSpan={1}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                >
                  {handlePayment(receiptData?.paymentMethod || "UNSPECIFIED")}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    paddingLeft: 15,
                    fontWeight: "500",
                    borderRightColor: "#fff",
                    height: "30px",
                  }}
                >
                  Transaction Type
                </td>
                <td
                  colSpan={1}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "right",
                    fontWeight: "700",
                    borderLeftColor: "#fff",
                    borderRightColor: "#fff",
                  }}
                >
                  نوع المعاملة
                </td>
                <td
                  colSpan={1}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                >
                  {transactionTypeMap[transactionType] || "---"}
                </td>
              </tr>
              {receiptData?.customerPlan?.promoCode && (
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      paddingLeft: 15,
                      borderRight: 0,
                      borderRightWidth: 0,
                      fontWeight: "500",
                    }}
                  >
                    Promo Code
                    {` - ${receiptData.customerPlan.promoCode}`}
                    {receiptData.customerPlan.extendDurationByPromoCode !== 0 &&
                      `(${receiptData.customerPlan.extendDurationByPromoCode} Extra Days)`}
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      textAlign: "right",
                      fontWeight: "700",
                      borderLeftColor: "#fff",
                      borderRightColor: "#fff",
                    }}
                  >
                    كوبون خصم
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      textAlign: "center",
                    }}
                  >
                    {` ${receiptData?.currency} ${receiptData?.customerPlan?.promocodeAmountApplied} `}
                  </td>
                </tr>
              )}
              {receiptData?.customerPlan?.secondPromoCode && (
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      paddingLeft: 15,
                      borderRight: 0,
                      borderRightWidth: 0,
                      fontWeight: "500",
                    }}
                  >
                    Wallet Promo Code
                    {` - ${receiptData?.customerPlan?.secondPromoCode} `}
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      textAlign: "right",
                      fontWeight: "700",
                      borderLeftColor: "#fff",
                      borderRightColor: "#fff",
                    }}
                  >
                    قسيمة المحفظة
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      textAlign: "center",
                    }}
                  >
                    {` ${receiptData?.currency} ${receiptData?.customerPlan?.secondPromocodeAmountApplied} `}
                  </td>
                </tr>
              )}
              {receiptData?.customerPlan?.alreadyPaidAmount ? (
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      paddingLeft: 15,
                      borderRight: 0,
                      borderRightWidth: 0,
                      fontWeight: "500",
                    }}
                  >
                    Previous Plan Credit
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      textAlign: "right",
                      fontWeight: "700",
                      borderLeftColor: "#fff",
                      borderRightColor: "#fff",
                    }}
                  >
                    رصيد الخطة السابقة
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      textAlign: "center",
                    }}
                  >
                    {` ${receiptData?.currency} ${receiptData?.customerPlan?.alreadyPaidAmount} `}
                  </td>
                </tr>
              ) : null}
              {receiptData?.customerPlan?.totalDiscount ? (
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      paddingLeft: 15,
                      fontWeight: "500",
                      borderRightColor: "#fff",
                    }}
                  >
                    Total Savings
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      textAlign: "right",
                      fontWeight: "700",
                      borderLeftColor: "#fff",
                      borderRightColor: "#fff",
                    }}
                  >
                    إجمالي الخصم
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      borderColor: "black",
                      borderStyle: "solid",
                      borderWidth: 0.08,
                      textAlign: "center",
                      fontStyle: "italic",
                    }}
                  >
                    {` - ${receiptData?.currency} ${receiptData?.customerPlan?.totalDiscount}` ||
                      "----"}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td
                  colSpan={3}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    paddingLeft: 15,
                    fontWeight: "700",
                    borderRightColor: "#fff",
                    height: "40px",
                  }}
                >
                  TOTAL AMOUNT
                </td>
                <td
                  colSpan={1}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "right",
                    fontWeight: "700",
                    borderLeftColor: "#fff",
                    borderRightColor: "#fff",
                  }}
                >
                  المجموع
                </td>
                <td
                  colSpan={1}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  {`${receiptData?.currency} ${receiptData?.amount} `}
                </td>
              </tr>
              {receiptData?.customerPlan?.returnToWallet ? (
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      paddingLeft: 15,
                      fontWeight: "500",
                      borderRightColor: "#fff",
                      height: "20px",
                      fontSize: "15px",
                    }}
                  >
                    Wallet Refund
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      textAlign: "right",
                      fontWeight: "700",
                      borderLeftColor: "#fff",
                      borderRightColor: "#fff",
                      fontSize: "15px",
                    }}
                  >
                    استرداد المحفظة
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    {`${receiptData?.currency} ${receiptData?.customerPlan?.returnToWallet} `}
                  </td>
                </tr>
              ) : null}
            </table>
          </div>
          <div
            style={{
              backgroundColor: "#000",
              height: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                color: "#fff",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={mailLogo}
                alt="mail"
                width={20}
                style={{ marginRight: "10px", marginLeft: "10px" }}
              />
              {DIETURE_EMAIL}
              <img
                src={websiteLogo}
                alt="website"
                width={20}
                style={{ marginRight: "10px", marginLeft: "10px" }}
              />
              {DIETURE_WEBSITE}
              <img
                src={whatsappLogo}
                alt="whatsapp"
                width={20}
                style={{ marginRight: "10px", marginLeft: "10px" }}
              />
              {DIETURE_WHATSAPP}
            </div>
            <div style={{ color: "#fff" }}>
              <img
                src={addressLogo}
                alt="address"
                width={15}
                style={{ marginRight: "10px" }}
              />
              {DIETURE_ADDRESS}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ReceiptPrint;
