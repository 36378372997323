import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Description",
    accessor: "description",
    cellProps: { width: "500px" },
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "Created By",
    accessor: "created_By",
  },
  {
    header: "Closed By",
    accessor: "closed_By",
  },
  {
    header: "Closed At",
    accessor: "closed_At",
  },
  {
    header: "Status",
    accessor: "status",
  },

  {
    header: "Actions",
    accessor: "actions",
  },
];
export interface ReminderCardProps {
  item: any;
  handleClickMenu: (
    id: string,
    status: string
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const getStatusBackgroundColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "pending":
      return { color: "white", backgroundColor: "#e3a459" };
    case "closed":
      return { color: "white", backgroundColor: "#b14a4a" };
    case "expired":
      return { color: "black", backgroundColor: "#C4C4C4" };
    default:
      return {};
  }
};
