import React from "react";
import moment from "moment";
//types
import { ReceiptPrintProps } from "../Types";
//logos
import dieture_logo_black from "../../../public/icons/dietureLogoSvg.svg";
import mailLogo from "../../../public/icons/emailSvg.svg";
import websiteLogo from "../../../public/icons/websiteSvg.svg";
import whatsappLogo from "../../../public/icons/whatsappSvg.svg";
import addressLogo from "../../../public/icons/addressSvg.svg";
//utils
import { handlePayment } from "../../Utils";
import { Box, Typography } from "@mui/material";
//context
import { useAuth } from "../../../contextAPI";

const DIETURE_EMAIL = "hello@dieture.com";
const DIETURE_WEBSITE = "www.dieture.com";
const DIETURE_WHATSAPP = "+974 333 44 679";
const DIETURE_ADDRESS = "Al Khafji Street, Duhail South - Qatar 4471 2343";

const AddOnsReceiptPrint = React.forwardRef<HTMLDivElement, ReceiptPrintProps>(
  ({ receiptData, clientName, clientMobile }, ref) => {
    const auth = useAuth();
    return (
      <div ref={ref}>
        <div
          style={{
            margin: "3vh 2vh 3vh 2vh",
            height: "94vh",
            border: "4px solid #000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div
              style={{
                width: "30%",
                height: "6%",
                backgroundColor: "#000",
                display: "flex",
                alignSelf: "flex-end",
                flexDirection: "column",
                color: "#fff",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>PAYMENT RECEIPT</div>
              <div>إيصال الدفع</div>
            </div>

            <img
              src={dieture_logo_black}
              height="60px"
              width="150px"
              alt="dieture_logo_black"
              style={{ marginLeft: "10px" }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "20px 10px 0px 20px",
                paddingRight: "6.5%",
              }}
            >
              <Box>
                <Typography fontFamily="Poppins" fontWeight="400">
                  Name : {clientName}
                </Typography>
                <Typography fontFamily="Poppins" fontWeight="400">
                  Contact :{clientMobile}
                </Typography>
                <Typography fontFamily="Poppins" fontWeight="400">
                  Date :{moment(receiptData?.date).format("DD/MM/YYYY")}
                </Typography>
              </Box>
              <Typography fontFamily="Poppins" fontWeight="400">
                Receipt No :{"RE" + receiptData?.receiptNo}
              </Typography>
            </div>

            <table
              style={{
                marginTop: "20px",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tr>
                <th
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "left",
                    width: "65%",
                    paddingLeft: "5%",
                  }}
                >
                  <Typography fontFamily="Poppins" fontWeight="700">
                    Add on’s
                  </Typography>
                  <Typography fontFamily="Poppins" fontWeight="700">
                    إضافات
                  </Typography>
                </th>
                <th
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                  }}
                >
                  <Typography fontFamily="Poppins" fontWeight="700">
                    unit price
                  </Typography>
                  <Typography fontFamily="Poppins" fontWeight="700">
                    سعر الوحدة
                  </Typography>
                </th>
                <th
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                  }}
                >
                  <Typography fontFamily="Poppins" fontWeight="700">
                    Count
                  </Typography>
                  <Typography fontFamily="Poppins" fontWeight="700">
                    العدد
                  </Typography>
                </th>
                <th
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                  }}
                >
                  <Typography fontFamily="Poppins" fontWeight="700">
                    Price{" "}
                  </Typography>
                  <Typography fontFamily="Poppins" fontWeight="700">
                    السعر{" "}
                  </Typography>
                </th>
              </tr>
              <tr style={{ height: "150px" }}>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "left",
                    paddingLeft: "5%",
                  }}
                >
                  {receiptData?.addons?.map((el) => (
                    <p key={el.addon?._id}> {el.addon?.name?.EN}</p>
                  ))}
                </td>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  {receiptData?.addons?.map((el) => (
                    <p key={el.addon?._id}>
                      {auth.countryCurrency} {el.addon?.retailsSellingPrice}
                    </p>
                  ))}
                </td>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  {receiptData?.addons?.map((el) => (
                    <p key={el.addon?._id}>{el.quantity}</p>
                  ))}
                </td>
                <td
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  {receiptData?.addons?.map((el) => (
                    <p key={el.addon?._id}>
                      {auth.countryCurrency}
                      {el.totalPrice}
                    </p>
                  ))}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    height: 30,
                  }}
                ></td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    paddingLeft: 15,
                    fontWeight: "500",
                    borderRightColor: "#fff",
                  }}
                >
                  Mode of payment
                </td>
                <td
                  colSpan={1}
                  style={{
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "right",
                    fontWeight: "500",
                    borderLeftColor: "#fff",
                    borderRightColor: "#fff",
                  }}
                >
                  طريقة الدفع{" "}
                </td>
                <td
                  colSpan={1}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                  }}
                >
                  {handlePayment(receiptData?.paymentMethod || "UNSPECIFIED")}
                </td>
              </tr>
              {receiptData?.order?.promoCode && (
                <>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.08,
                        paddingLeft: 15,
                        fontWeight: "500",
                        borderRightColor: "#fff",
                      }}
                    >
                      Promo Code
                    </td>
                    <td
                      colSpan={1}
                      style={{
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.08,
                        textAlign: "right",
                        fontWeight: "500",
                        borderLeftColor: "#fff",
                        borderRightColor: "#fff",
                      }}
                    >
                      كوبون خصم
                    </td>
                    <td
                      colSpan={1}
                      style={{
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.08,
                        textAlign: "center",
                      }}
                    >
                      {`${receiptData?.order?.promoCode}`}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.08,
                        paddingLeft: 15,
                        fontWeight: "500",
                        borderRightColor: "#fff",
                      }}
                    >
                      Discount
                    </td>
                    <td
                      colSpan={1}
                      style={{
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.08,
                        textAlign: "right",
                        fontWeight: "500",
                        borderLeftColor: "#fff",
                        borderRightColor: "#fff",
                      }}
                    >
                      تخفيض
                    </td>
                    <td
                      colSpan={1}
                      style={{
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: 0.08,
                        textAlign: "center",
                        fontStyle: "italic",
                      }}
                    >
                      {`-${receiptData?.order?.totalDiscount} ${receiptData?.currency}`}
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td
                  colSpan={2}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    paddingLeft: 15,
                    fontWeight: "500",
                    borderRightColor: "#fff",
                    height: "50px",
                  }}
                >
                  TOTAL
                </td>
                <td
                  colSpan={1}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "right",
                    fontWeight: "500",
                    borderLeftColor: "#fff",
                    borderRightColor: "#fff",
                  }}
                >
                  المجموع
                </td>
                <td
                  colSpan={1}
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: 0.08,
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  {`${receiptData?.order?.totalPrice} ${receiptData?.currency}`}
                </td>
              </tr>
            </table>
            <Typography fontFamily="Poppins" marginTop={2.5} paddingLeft={2.5}>
              Transaction ID : {receiptData?.merchantReference || ""}
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: "#000",
              height: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                color: "#fff",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={mailLogo}
                alt="mail"
                width={20}
                style={{ marginRight: "10px", marginLeft: "10px" }}
              />
              {DIETURE_EMAIL}
              <img
                src={websiteLogo}
                alt="website"
                width={20}
                style={{ marginRight: "10px", marginLeft: "10px" }}
              />
              {DIETURE_WEBSITE}
              <img
                src={whatsappLogo}
                alt="whatsapp"
                width={20}
                style={{ marginRight: "10px", marginLeft: "10px" }}
              />
              {DIETURE_WHATSAPP}
            </div>
            <div style={{ color: "#fff" }}>
              <img
                src={addressLogo}
                alt="address"
                width={15}
                style={{ marginRight: "10px" }}
              />
              {DIETURE_ADDRESS}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default AddOnsReceiptPrint;
