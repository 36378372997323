import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Rating,
  Typography,
} from "@mui/material";
import { TableContainer } from "../../../styles/Table__styles";
import {
  LeftContainer,
  RightContainer,
  StyledLoaderContainer,
} from "../../../styles/Customers__styles";
import PageHeader from "../../pageHeader/PageHeader";
import TabContentHeader from "../../TabContentHeader/TabContentHeader";
import Table from "../../layout/commun/Table";
import { reviewColumns } from "./Utils";
import { ReviewProps } from "./Types";
import { useGetAllRatingsOfOneCustomerQuery } from "../../../graphql/types";
import { useParams } from "react-router-dom";

function CustomerReviews() {
  const [selectedDish, setSelectedDish] = useState<ReviewProps>(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const { id } = useParams();
  const {
    data,
    loading,
    error,
    refetch: refetchCustomerReviews,
  } = useGetAllRatingsOfOneCustomerQuery({
    variables: {
      GetRatingInput: {
        customer_id: id,
      },
    },
    fetchPolicy: "no-cache",
  });

  const customerReviewData = data?.getAllRatingsOfOneCustomer;

  const count = customerReviewData?.length || 0;

  const handleDishClick = (item: any) => {
    setSelectedDish(item);
  };

  const handleCloseClick = () => {
    setSelectedDish(null);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const transformedData =
    customerReviewData?.map((item) => ({
      ...item,
      Rating: (
        <Rating
          name="read-only"
          value={Math.round(item?.rating ?? 0)}
          readOnly
          precision={0.5} // Use half-star increments
          sx={{ color: "#FFD700" }} // Gold color for stars
        />
      ),
      dish: (
        <ButtonBase
          onClick={() => handleDishClick(item)}
          sx={{ textDecoration: "none", color: "primary.main" }}
        >
          {item?.dish?.name?.EN}
        </ButtonBase>
      ),
      comment: (
        <Typography
          noWrap
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "150px",
            fontSize: "0.9rem",
          }}
        >
          {item?.comment || "No feedback available"}
        </Typography>
      ),
      date: item?.date ? new Date(item.date).toLocaleDateString("en-GB") : "",
      time: item?.date ? new Date(item.date).toLocaleTimeString() : "",
    })) || [];

  return (
    <>
      {!loading ? (
        <Box display="flex" fontSize="14px" fontFamily="sans-serif">
          <LeftContainer>
            <TableContainer>
              <Table
                columns={reviewColumns}
                data={transformedData.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )}
                emptyMessage="No Reviews found !"
                tablePaginationProps={{
                  rowsPerPageOptions: [30, 60, 90],
                  count: count,
                  page: page - 1,
                  onPageChange,
                  rowsPerPage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                paginationProps={{
                  page: page,
                  count: Math.ceil(count / rowsPerPage),
                  showFirstButton: true,
                  showLastButton: true,
                  boundaryCount: 1,
                  siblingCount: 2,
                  onChange: onPageChange,
                }}
              />
            </TableContainer>
          </LeftContainer>
          {selectedDish && (
            <RightContainer padding="20px 10px 20px 0px">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="start"
                borderRadius="8px"
                border="2px solid #F3F4FA"
                padding="15px"
              >
                <TabContentHeader
                  title="Details of dish"
                  buttonFnc={handleCloseClick}
                  buttonText="close"
                />

                <Rating
                  name="read-only"
                  value={selectedDish?.rating}
                  readOnly
                />
                <Box>
                  <Box m={2} display="flex" justifyContent="space-between">
                    <Box width="150px" textAlign="start">
                      Dish Name
                    </Box>
                    <Box flex="1" textAlign="start">
                      {selectedDish?.dish?.name?.EN}
                    </Box>
                  </Box>
                  <Box m={2} display="flex" justifyContent="space-between">
                    <Box width="150px" textAlign="start">
                      Feedback
                    </Box>
                    <Box flex="1" textAlign="start">
                      {selectedDish.comment || "No feedback available"}
                    </Box>
                  </Box>
                  <Box m={2} display="flex" justifyContent="space-between">
                    <Box width="150px" textAlign="start">
                      Date
                    </Box>
                    <Box flex="1" textAlign="start">
                      {new Date(selectedDish?.date).getDate()}/
                      {new Date(selectedDish?.date).getMonth() + 1}/
                      {new Date(selectedDish?.date).getFullYear()}
                    </Box>
                  </Box>
                  <Box m={2} display="flex" justifyContent="space-between">
                    <Box width="150px" textAlign="start">
                      Time
                    </Box>
                    <Box flex="1" textAlign="start">
                      {new Date(selectedDish?.date).toLocaleTimeString()}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </RightContainer>
          )}
        </Box>
      ) : (
        <StyledLoaderContainer>
          <CircularProgress size={40} color="secondary" />
        </StyledLoaderContainer>
      )}
    </>
  );
}

export default CustomerReviews;
