export const lowerOption = <T>(option: string) => {
  if (option === null) {
    return "unspecified";
  } else {
    return option?.toString().toLowerCase().replace(/_/g, " ");
  }
};

export const lowercasedOptionsList = <T>(optionsList: string[]) => {
  return optionsList.map((option) => lowerOption(option));
};
