function BirthdaysIcon() {
  return (
    <svg
      width="31"
      height="36"
      viewBox="0 0 51 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_394_60816)">
        <path
          d="M50.1816 24.3031H0.818357C0.360077 24.3031 0 23.9383 0 23.4742V13.0633C0 12.5991 0.360077 12.2344 0.818357 12.2344H50.1816C50.6399 12.2344 51 12.5991 51 13.0633V23.4742C51 23.9383 50.6399 24.3031 50.1816 24.3031ZM1.63671 22.6453H49.3633V13.8922H1.63671V22.6453Z"
          fill="#fff"
        />
        <path
          d="M46.5817 55.9992H4.41992C3.96164 55.9992 3.60156 55.6345 3.60156 55.1703V23.4734C3.60156 23.0092 3.96164 22.6445 4.41992 22.6445C4.8782 22.6445 5.23828 23.0092 5.23828 23.4734V54.3082H45.7633V23.4734C45.7633 23.0092 46.1234 22.6445 46.5817 22.6445C47.0399 22.6445 47.4 23.0092 47.4 23.4734V55.1371C47.4 55.6013 47.0399 55.966 46.5817 55.966V55.9992Z"
          fill="#fff"
        />
        <path
          d="M31.4587 24.3031H19.5762C19.1179 24.3031 18.7578 23.9383 18.7578 23.4742V13.0633C18.7578 12.5991 19.1179 12.2344 19.5762 12.2344H31.4587C31.917 12.2344 32.2771 12.5991 32.2771 13.0633V23.4742C32.2771 23.9383 31.917 24.3031 31.4587 24.3031ZM20.3945 22.6453H30.6404V13.8922H20.3945V22.6453Z"
          fill="#fff"
        />
        <path
          d="M30.0777 55.9992H20.9121C20.4538 55.9992 20.0938 55.6345 20.0938 55.1703V23.4734C20.0938 23.0092 20.4538 22.6445 20.9121 22.6445H30.0777C30.536 22.6445 30.8961 23.0092 30.8961 23.4734V55.1371C30.8961 55.6013 30.536 55.966 30.0777 55.966V55.9992ZM21.7305 54.3414H29.2593V24.3023H21.7305V54.3082V54.3414Z"
          fill="#fff"
        />
        <path
          d="M25.2373 13.8922H12.4054C8.60827 13.8922 5.53125 10.7756 5.53125 6.92954C5.53125 3.08348 8.57554 0.0663114 12.4054 0C12.4054 0 12.5036 0 12.5364 0C19.9016 0 25.7283 12.2013 25.9574 12.7318C26.0884 12.997 26.0556 13.2954 25.9247 13.5275C25.761 13.7596 25.4992 13.9254 25.2373 13.9254V13.8922ZM12.4382 1.65779C9.4921 1.7241 7.2007 4.045 7.2007 6.9627C7.2007 9.8804 9.55757 12.2676 12.4382 12.2676H23.8952C22.2912 9.2836 17.6757 1.65779 12.5364 1.65779C12.5036 1.65779 12.4709 1.65779 12.4382 1.65779Z"
          fill="#fff"
        />
        <path
          d="M38.3012 13.8922H25.5021C25.2075 13.8922 24.9456 13.7596 24.8147 13.4943C24.651 13.2622 24.651 12.9638 24.782 12.6986C25.0438 12.1681 30.8378 -0.0332031 38.203 -0.0332031C38.2357 -0.0332031 38.3012 -0.0332031 38.3339 -0.0332031C42.1639 0.0331083 45.1754 3.11659 45.1754 6.9295C45.1754 10.7424 42.0984 13.8922 38.3012 13.8922ZM26.8442 12.2344H38.3012C41.1818 12.2344 43.5387 9.8472 43.5387 6.9295C43.5387 4.01179 41.2473 1.69089 38.3012 1.62458C38.2685 1.62458 38.2357 1.62458 38.203 1.62458C33.0965 1.62458 28.4809 9.21724 26.8442 12.2344Z"
          fill="#fff"
        />
      </g>
      {/* <defs>
          <clipPath id="clip0_394_60816">
            <rect width="51" height="56" fill="white" />
          </clipPath>
        </defs> */}
    </svg>
  );
}

export default BirthdaysIcon;
