function ExpiringCustomersIcon() {
  return (
    <svg
      width="24"
      height="32"
      viewBox="0 0 48 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_394_60837)">
        <path
          d="M24.0346 32.7163C23.8785 32.7163 23.7192 32.6867 23.5731 32.6276C11.7673 27.8971 3.83594 15.2748 3.83594 1.21791C3.83594 0.544943 4.38373 0 5.06432 0C5.74492 0 6.29271 0.544943 6.29271 1.21791C6.29271 14.1061 13.4007 25.6747 24.0313 30.182C34.6219 25.6648 41.7001 14.0963 41.7001 1.21791C41.7001 0.544943 42.2479 0 42.9285 0C43.6091 0 44.1569 0.544943 44.1569 1.21791C44.1569 15.2617 36.2554 27.884 24.4927 32.6276C24.3467 32.6867 24.1873 32.7163 24.0279 32.7163H24.0346Z"
          fill="#fff"
        />
        <path
          d="M46.7716 2.43583H1.22839C0.547794 2.43583 0 1.89089 0 1.21791C0 0.544943 0.547794 0 1.22839 0H46.7716C47.4522 0 48 0.544943 48 1.21791C48 1.89089 47.4522 2.43583 46.7716 2.43583Z"
          fill="#fff"
        />
        <path
          d="M42.9363 63.0008C42.2557 63.0008 41.7079 62.4559 41.7079 61.7829C41.7079 48.9045 34.6298 37.336 24.0391 32.8188C13.4119 37.3261 6.30052 48.8947 6.30052 61.7829C6.30052 62.4559 5.75273 63.0008 5.07214 63.0008C4.39154 63.0008 3.84375 62.4559 3.84375 61.7829C3.84375 47.726 11.7751 35.1037 23.5809 30.3699C23.8764 30.2517 24.2117 30.2517 24.5072 30.3699C36.2698 35.1168 44.1713 47.7358 44.1713 61.7796C44.1713 62.4526 43.6235 62.9975 42.9429 62.9975L42.9363 63.0008Z"
          fill="#fff"
        />
        <path
          d="M46.7716 63.0003H1.22839C0.547794 63.0003 0 62.4553 0 61.7824C0 61.1094 0.547794 60.5645 1.22839 60.5645H46.7716C47.4522 60.5645 48 61.1094 48 61.7824C48 62.4553 47.4522 63.0003 46.7716 63.0003Z"
          fill="#fff"
        />
        <path
          d="M24.0352 25.9182C23.8792 25.9182 23.7198 25.8887 23.5738 25.8296C19.5865 24.2374 16.2964 21.0203 14.3011 16.7658C14.1251 16.3883 14.155 15.9484 14.3808 15.6004C14.6065 15.2492 14.9983 15.0391 15.4199 15.0391H32.5808C32.9991 15.0391 33.3909 15.2524 33.62 15.6004C33.8457 15.9484 33.8756 16.3883 33.6996 16.7658C31.7143 21.0039 28.4475 24.221 24.5067 25.8263C24.3573 25.8854 24.1979 25.9182 24.0385 25.9182H24.0352ZM17.4683 17.4716C19.1084 20.1438 21.3826 22.1988 24.0319 23.3773C26.6547 22.1923 28.9056 20.1372 30.5357 17.4716H17.4683Z"
          fill="#fff"
        />
        <path
          d="M35.1863 58.2036H12.8164C12.3981 58.2036 12.0096 57.9935 11.7839 57.6488C11.5581 57.3008 11.5216 56.8642 11.6943 56.4867C14.1444 51.116 18.4769 46.8681 23.5797 44.8328C23.8752 44.7146 24.2039 44.7146 24.496 44.8328C29.5457 46.8451 33.8517 51.0898 36.3084 56.4867C36.4811 56.8642 36.4446 57.3008 36.2188 57.6455C35.993 57.9935 35.6013 58.2003 35.1863 58.2003V58.2036ZM14.815 55.7677H33.191C31.0131 51.9203 27.7662 48.8969 24.0379 47.2785C20.2631 48.91 16.9929 51.9334 14.815 55.7677Z"
          fill="#fff"
        />
        <path
          d="M24.0409 47.1804C23.3603 47.1804 22.8125 46.6354 22.8125 45.9625V43.7433C22.8125 43.0703 23.3603 42.5254 24.0409 42.5254C24.7215 42.5254 25.2693 43.0703 25.2693 43.7433V45.9625C25.2693 46.6354 24.7215 47.1804 24.0409 47.1804Z"
          fill="#fff"
        />
        <path
          d="M24.0409 40.5905C23.3603 40.5905 22.8125 40.0456 22.8125 39.3726V37.0812C22.8125 36.4082 23.3603 35.8633 24.0409 35.8633C24.7215 35.8633 25.2693 36.4082 25.2693 37.0812V39.3726C25.2693 40.0456 24.7215 40.5905 24.0409 40.5905Z"
          fill="#fff"
        />
      </g>
      {/* <defs>
            <clipPath id="clip0_394_60837">
              <rect width="48" height="63" fill="white" />
            </clipPath>
          </defs> */}
    </svg>
  );
}

export default ExpiringCustomersIcon;
