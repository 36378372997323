function BlacklistedCustomersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path d="M445 4790 l-59 -59 2175 -2171 2174 -2170 60 60 60 60 -304 304 -304 304 71 94 c237 312 380 650 439 1038 24 158 23 463 0 620 -38 248 -100 447 -207 665 -325 663 -936 1108 -1680 1222 -157 23 -462 24 -620 0 -385 -59 -711 -195 -1019 -425 l-113 -85 -302 302 c-165 165 -303 301 -306 301 -3 0 -32 -27 -65 -60z m2290 -190 c492 -46 926 -249 1271 -594 625 -625 779 -1570 384 -2356 -23 -46 -64 -119 -90 -162 -54 -87 -167 -238 -178 -238 -4 0 -206 199 -449 443 l-442 442 21 87 c18 77 24 90 52 106 38 23 89 107 122 202 69 199 72 429 5 447 -18 4 -19 15 -19 126 1 144 -10 189 -67 282 -23 39 -63 108 -88 155 -64 119 -103 160 -195 208 -44 23 -85 49 -93 58 -35 42 -7 143 67 245 l34 46 -53 -4 c-442 -33 -757 -118 -910 -244 -21 -18 -84 -101 -139 -185 -55 -85 -103 -154 -107 -154 -3 0 -145 139 -314 308 l-308 308 91 68 c410 306 914 452 1405 406z m45 -733 c0 -110 68 -205 184 -261 83 -39 88 -45 176 -206 34 -63 71 -127 81 -142 16 -22 19 -44 19 -139 0 -62 -5 -164 -11 -227 -6 -62 -9 -115 -6 -118 2 -3 23 0 46 5 l42 9 -6 -41 c-12 -73 -35 -157 -60 -213 -24 -54 -24 -54 -69 -54 -51 0 -45 8 -66 -105 -6 -33 -14 -68 -17 -79 -4 -15 -121 97 -558 534 l-553 553 55 76 c30 42 77 113 104 159 69 112 127 153 300 207 75 23 291 73 327 74 7 1 12 -12 12 -32z" />
        <path d="M813 3923 c-242 -314 -390 -659 -450 -1053 -24 -159 -24 -464 0 -620 39 -249 100 -447 207 -665 224 -457 590 -817 1053 -1035 296 -139 593 -204 937 -204 216 0 346 15 541 64 291 72 609 229 837 413 l63 51 -108 109 c-59 60 -126 118 -148 129 -22 12 -131 61 -243 109 -231 100 -312 150 -404 248 -67 70 -98 133 -98 195 0 30 26 92 52 128 11 15 5 25 -46 77 -33 33 -63 58 -67 56 -4 -3 -23 -29 -42 -58 -174 -263 10 -579 445 -762 114 -48 420 -185 427 -191 9 -8 -212 -141 -330 -198 -646 -312 -1398 -261 -1999 134 l-94 62 64 30 c36 17 160 72 276 123 129 56 245 113 296 148 116 77 220 189 266 286 34 69 37 84 37 161 0 103 -24 160 -105 260 -85 104 -125 211 -169 443 -12 59 -21 110 -21 113 0 2 -20 4 -45 4 -42 0 -46 3 -64 38 -32 63 -85 262 -69 262 2 0 24 -7 51 -15 56 -17 53 -27 35 122 l-11 102 -94 93 -94 93 7 -97 c6 -83 4 -98 -8 -98 -8 0 -23 -9 -34 -21 -17 -19 -19 -37 -18 -153 1 -112 5 -144 28 -219 41 -138 104 -249 149 -266 16 -6 26 -32 52 -137 39 -158 93 -276 168 -367 61 -74 83 -129 72 -186 -26 -139 -182 -275 -449 -388 -83 -36 -222 -98 -309 -138 l-158 -73 -82 81 c-311 310 -517 714 -584 1150 -23 152 -23 442 0 593 55 351 200 690 411 958 25 33 46 62 46 66 0 3 -26 32 -58 63 l-59 58 -60 -78z" />
      </g>
    </svg>
  );
}

export default BlacklistedCustomersIcon;
