import {
  Autocomplete,
  DrawingManager,
  GoogleMap,
  Marker,
  Polygon,
} from "@react-google-maps/api";
import { useEffect, useMemo, useRef, useState } from "react";
import { AreaPayload } from "../../../../graphql/types";
import { centroid } from "../../Utils";
interface propTypes {
  containerClass: string;
  center: { lat: number; lng: number };
  zoom: number;
  areas: AreaPayload[];
  update?: AreaPayload;
  polygonCord: number[][][];
  onPolygonComplete: (polygon: google.maps.Polygon) => void;
  onLoad: (polygon: google.maps.Polygon) => void;
  onEdit: () => void;
  onUnmount: () => void;
}

interface MarkerData {
  id?: string;
  position: google.maps.LatLngLiteral;
  title: string | undefined;
}

const GeoMap = ({
  polygonCord,
  containerClass,
  center,
  areas,
  update,
  onEdit,
  onLoad,
  onUnmount,
  onPolygonComplete,
}: propTypes) => {
  const [searchBox, setSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);
  const [markers, setMarkers] = useState<MarkerData[]>([]);
  const [selected, setSelected] = useState<string>("");
  const [searchs, setSearchs] = useState<MarkerData[]>([]);
  const mapRef = useRef<any>(null);
  const bounds = new google.maps.LatLngBounds();

  useEffect(() => {
    const map = mapRef.current;

    if (map && markers.length > 0) {
      [...markers, ...searchs].forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.position));
      });
      map.fitBounds(bounds);
    }
  }, [markers, searchs]);

  const onMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;

    const newMarkers = areas.filter(
      (area) => !!area.cordinates?.coordinates?.length
    );

    if (newMarkers.length) {
      setMarkers(
        newMarkers.map((area) => ({
          id: area._id,
          title: area.name ?? "Uknown",
          position: {
            lat: centroid(area.cordinates!)[0],
            lng: centroid(area.cordinates!)[1],
          },
        }))
      );
    }

    setSearchBox(
      new window.google.maps.places.SearchBox(
        document.getElementById("search-box") as HTMLInputElement
      )
    );
  };

  const onPlacesChanged = () => {
    if (!searchBox) return;

    const places = searchBox.getPlaces();

    const newMarkers: MarkerData[] = places?.map((place: any) => ({
      position: place.geometry!.location!.toJSON(),
      title: place.name,
    }))!;

    setSearchs((prev) => [...prev, ...newMarkers]);
  };

  const polygonOptions = {
    fillOpacity: 0.3,
    fillColor: "#ff0000",
    strokeColor: "#ff0000",
    strokeWeight: 2,
    draggable: true,
    editable: true,
  };

  const drawingManagerOptions = {
    polygonOptions: {
      ...polygonOptions,
      fillColor: "green",
      strokeColor: "green",
    },
    drawingControl: true,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
    },
  };

  const geoCenter = useMemo(() => {
    return center;
  }, [center]);

  return (
    <>
      <Autocomplete onPlaceChanged={onPlacesChanged}>
        <input
          id="search-box"
          type="text"
          placeholder="Search for a location"
          style={{
            boxSizing: "border-box",
            border: "1px solid transparent",
            width: "100%",
            height: "32px",
            padding: "0 12px",
            borderRadius: "3px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            fontSize: "14px",
            outline: "none",
            textOverflow: "ellipses",
          }}
        />
      </Autocomplete>
      <GoogleMap
        options={{ maxZoom: 15 }}
        ref={mapRef}
        onLoad={onMapLoad}
        zoom={10}
        center={geoCenter}
        mapContainerClassName={containerClass}
      >
        <DrawingManager
          options={drawingManagerOptions}
          onPolygonComplete={onPolygonComplete}
        />

        {selected &&
          markers.map((marker, index) => {
            if (selected === marker.id) {
              return (
                <Marker
                  key={index}
                  position={marker.position}
                  title={marker.title}
                />
              );
            }
            return null;
          })}

        {searchs.length &&
          searchs.map((marker, index) => {
            return (
              <Marker
                key={index}
                position={marker.position}
                title={marker.title}
              />
            );
          })}

        {areas &&
          areas.map((area) => {
            if (update?._id === area._id) {
              return (
                <Polygon
                  onMouseOver={(e) => {
                    setSelected(area._id);
                  }}
                  onMouseOut={() => setSelected("")}
                  key={area._id}
                  onMouseUp={onEdit}
                  onDragEnd={onEdit}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  path={
                    polygonCord.length
                      ? polygonCord![0]?.map((latLng) => {
                          return { lat: latLng[0], lng: latLng[1] };
                        })
                      : []
                  }
                  options={{
                    ...polygonOptions,
                    draggable: true,
                    editable: true,
                    fillColor: "green",
                    strokeColor: "green",
                  }}
                />
              );
            }

            return (
              area.cordinates?.coordinates && (
                <Polygon
                  onMouseOver={(e) => {
                    setSelected(area._id);
                  }}
                  onMouseOut={() => setSelected("")}
                  key={area._id}
                  path={area.cordinates?.coordinates![0]?.map((latLng) => {
                    return { lat: latLng[0], lng: latLng[1] };
                  })}
                  options={{
                    ...polygonOptions,
                    draggable: false,
                    editable: false,
                    fillColor: "red",
                    strokeColor: "red",
                  }}
                />
              )
            );
          })}
      </GoogleMap>
    </>
  );
};

export default GeoMap;
