import _ from "lodash";
import { useNavigate, useLocation } from "react-router";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
} from "react-hook-form";
//material ui
import {
  Box,
  TextField,
  Autocomplete,
  Chip,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { LocalizationProvider } from "@mui/lab";
import DateFnsAdapter from "@mui/lab/AdapterDateFns";
//components
import PageAddHeader from "../pageAddHeader/pageAddHeader";
import DropZoneInput from "../layout/commun/DropZoneInput";
//styles
import { useStylesContainers } from "../../styles/Containers__styles";
//utils
import { getMessageError } from "../Utils";
import { Priorities } from "./Utils";
//querys and mutations
import {
  useCreateReminderMutation,
  useGetAdminsListQuery,
  useGetSpecificCustomerListQuery,
} from "../../graphql/types";
//types
import { ReminderProps } from "./types";
import { ApolloError } from "@apollo/client";

const AddReminder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerid");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //state
  const [search, setSearch] = useState("");
  const [searchUser, setSearchUser] = useState("");
  //styles
  const ContainersClasses = useStylesContainers();
  //queries and mutations
  const [createReminder, dataCreateRemind] = useCreateReminderMutation();
  const customersList = useGetSpecificCustomerListQuery({
    variables: {
      input: {
        page: 1,
        documentsPerPage: 10,
        nameCustomer: search,
      },
    },
  });

  const customers = useMemo(
    () => customersList?.data?.getSpecificCustomerList.data || [],
    [customersList?.data]
  );

  const UsersData = useGetAdminsListQuery({
    variables: {
      input: {
        page: 1,
        documentsPerPage: 50,
        name: searchUser,
      },
    },
    fetchPolicy: "no-cache",
  });

  const UsersList = useMemo(
    () => UsersData.data?.getAdminsList.data || [],
    [UsersData.data]
  );

  const handleDebouncedSearch = useCallback(
    _.debounce(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const target = event.target;
        if (target.name === "search") {
          setSearch(target.value);
        } else if (target.name === "searchUser") {
          setSearchUser(target.value);
        }
      },
      800
    ),
    []
  );
  //react hook form
  const methods = useForm<ReminderProps>({
    defaultValues: {
      title: "",
      customer: customerId || "",
      description: "",
      date: null,
      photo: "",
      isPrivate: false,
      priority: undefined,
      receivers: [],
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  //functions
  const onSubmit: SubmitHandler<ReminderProps> = async (data) => {
    try {
      await createReminder({
        variables: {
          createReminderInput: {
            title: data?.title,
            customer: data?.customer,
            description: data?.description,
            date: data?.date || new Date(),
            photo: data?.photo,
            isPrivate: data?.isPrivate,
            priority: data?.priority,
            receivers: data?.receivers.map((r) => {
              return r._id || "";
            }),
          },
        },
      });
      enqueueSnackbar("Reminder successfully added", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
      navigate(-1);
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <PageAddHeader
            title={"Create Reminder"}
            buttonText={"Create Reminder"}
            cancelButtonLink
            loading={dataCreateRemind.loading}
          />
          <Box
            display="grid"
            width="100%"
            gridTemplateColumns="2fr 1fr"
            gap={4}
          >
            <Box
              display="grid"
              width="100%"
              gridTemplateColumns="1fr 1fr"
              columnGap={2}
            >
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Title</p>
                <TextField
                  placeholder="Title"
                  sx={{ width: "100%" }}
                  {...register("title", {
                    required: "Enter Reminder Title",
                    minLength: {
                      value: 2,
                      message: "Please enter at least 2 characters",
                    },
                  })}
                />
                <p className={ContainersClasses.section__error__helper}>
                  {errors?.title?.message}
                </p>
              </Box>
              {!customerId && (
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Customer name
                  </p>
                  <Controller
                    name="customer"
                    control={control}
                    render={({ field: { onChange: Change } }) => (
                      <>
                        <Autocomplete
                          id="customer"
                          options={customers}
                          getOptionLabel={(option) =>
                            option.fname + " " + option.lname
                          }
                          filterSelectedOptions
                          onChange={(_, data) => {
                            if (data && data._id) {
                              Change(data._id);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(event) => handleDebouncedSearch(event)}
                              name="search"
                            />
                          )}
                        />
                      </>
                    )}
                  />
                </Box>
              )}
              <LocalizationProvider dateAdapter={DateFnsAdapter}>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>Date</p>
                  <Controller
                    name="date"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Enter Date",
                      },
                    }}
                    render={({ field }) => (
                      <DateTimePicker
                        inputFormat="dd/MM/yyyy HH:mm"
                        minDateTime={new Date()}
                        value={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    )}
                  />
                  <p className={ContainersClasses.section__error__helper}>
                    {errors?.date?.message}
                  </p>
                </Box>
              </LocalizationProvider>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Select other recipients
                </p>
                <Controller
                  name="receivers"
                  control={control}
                  render={({ field: { onChange: Change } }) => (
                    <>
                      <Autocomplete
                        multiple
                        id="receivers"
                        options={UsersList}
                        getOptionLabel={(option) =>
                          option.fname + " " + option.lname
                        }
                        filterSelectedOptions
                        disableCloseOnSelect
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option.fname + " " + option.lname}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        onChange={(_, data) => Change(data)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select other recipients"
                            onChange={(event) => handleDebouncedSearch(event)}
                            name="searchUser"
                          />
                        )}
                      />
                    </>
                  )}
                />
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Priority</p>
                <Controller
                  name="priority"
                  control={control}
                  render={({
                    field: { onChange: Change, value, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        ref={ref}
                        id="priority"
                        options={Priorities}
                        getOptionLabel={(option) => option.menuText}
                        isOptionEqualToValue={(option, value) =>
                          option.menuValue === value.menuValue
                        }
                        filterSelectedOptions
                        onChange={(_, data) =>
                          Change(data ? data.menuValue : "")
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Priority" />
                        )}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
                <Box>
                  <Controller
                    name="isPrivate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        checked={value ?? false}
                        onChange={(event) => {
                          onChange((event.target as HTMLInputElement).checked);
                        }}
                        control={<Checkbox color="secondary" />}
                        label="Private"
                        labelPlacement="end"
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Description
                </p>
                <TextField
                  placeholder="Description"
                  multiline
                  rows={10}
                  sx={{ width: "100%" }}
                  {...register("description", {
                    required: "Enter Description",
                  })}
                />
                <p className={ContainersClasses.section__error__helper}>
                  {errors?.description?.message}
                </p>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="start">
              <p className={ContainersClasses.section__subtitle}>
                Upload Image
              </p>
              <Controller
                name="photo"
                control={control}
                render={({ field: { onChange, ref } }) => {
                  return (
                    <DropZoneInput
                      onChange={(e) => {
                        onChange(e.target.files[0]);
                      }}
                      label="Drop File"
                      inputRef={ref}
                      name="photo"
                      errors={errors}
                      multiple={false}
                      disabled={false}
                      accept="image/*"
                      message="Recommended resolution for image 400px*400px"
                    />
                  );
                }}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddReminder;
