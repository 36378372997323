import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export const useStylesButtons = makeStyles((theme: Theme) =>
  createStyles({
    RedButton: {
      padding: "6px 10px !important",
      fontSize: "1em !important",
      width: "fit-content !important",
      minWidth: "6em !important",
      backgroundColor: `${theme.palette.error.main} !important`,
      color: "white !important",
    },
    BlueButton: {
      padding: "6px 10px !important",
      fontSize: "1em !important",
      width: "fit-content !important",
      minWidth: "6em !important",
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "white !important",
    },
    GreenButton: {
      padding: "6px 10px !important",
      fontSize: "1em !important",
      width: "fit-content !important",
      minWidth: "6em !important",
      backgroundColor: `#47BC45 !important`,
      color: "white !important",
    },
    GreyButton: {
      padding: "6px 10px !important",
      fontSize: "1em !important",
      width: "fit-content !important",
      minWidth: "6em !important",
      backgroundColor: `${theme.palette.grey[400]} !important`,
      color: "white !important",
      borderRadius: "5px !important",
    },
    button_test: {
      backgroundColor: "black !important",
      borderRadius: "35px !important",
      top: "0",
    },
    button_cancel_form: {
      background: "#232222",
      border: "1px solid #012044 !important",
      borderRadius: "5px !important",
    },
    button_submit_form: {
      background: "#232222",
      borderRadius: "5px",
      color: `${theme.palette.primary.contrastText} !important`,
      fontSize: "20px",
    },
    button_plus_add_new: {
      margin: "16px 0",
    },
    filter_button: {
      height: 53,
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      paddingBottom: "7px !important",
      paddingTop: "7px !important",
      minWidth: "160px !important",
      width: "fit-content !important",
    },
    button_open_list_page: {
      height: 44,
      minWidth: "180px !important",
      paddingBottom: "7px !important",
      paddingTop: "7px !important",
      paddingLeft: "10px !important",
      paddingRight: "10px !important",
      marginLeft: "10px !important",
      width: "fit-content !important",
    },
    button_export_excel: {
      height: 44,
      minWidth: "180px !important",
      paddingBottom: "7px !important",
      paddingTop: "7px !important",
      paddingLeft: "10px !important",
      paddingRight: "10px !important",
      color: "black !important",
      width: "fit-content !important",
    },
    button_save: {
      minWidth: "100px !important",
      backgroundColor: "black !important",
      color: `${theme.palette.primary.contrastText} !important`,
      padding: "7px 16px!important",
      borderRadius: "35px !important",
      marginLeft: "10px !important",
      width: "fit-content !important",
    },
    button_upload_csv: {
      minWidth: "160px !important",
      paddingBottom: "7px !important",
      paddingTop: "7px !important",
      paddingLeft: "10px !important",
      paddingRight: "10px !important",
      marginLeft: "10px !important",
      width: "fit-content !important",
    },
    button_dropzone: {
      minWidth: "150px !important",
      paddingBottom: "7px !important",
      paddingTop: "7px !important",
      paddingLeft: "10px !important",
      paddingRight: "10px !important",
      marginLeft: "10px !important",
      width: "fit-content !important",
    },
  })
);
