import { Box, lighten, MenuItem, styled } from "@mui/material";
import React from "react";

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledLineContainer = styled(Box)`
  display: flex;
  gap: 20px;
`;

export const StyledMealsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 40px;
`;

export const StyledMeal = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: #fff;
  height: 65px;
  width: 120px;
  position: relative;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
`;

export const StyledMealText = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 100%;
`;

export const StyledDaysWrapper = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 15px 10px;
  width: 72.5vw;
`;

export const StyledProgramDishesWrapper = styled(Box)`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const StyledDay = styled(Box)`
  /* padding: 10px; */
  background-color: ${({ theme }) =>
    lighten(theme.palette.secondary.main, 0.8)};
  color: ${({ theme }) => theme.palette.secondary.main};
  height: 26px;
  width: 190px;
  position: relative;
  text-align: center;
`;
export const StyledDayText = styled("p")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 100%;
`;

export const StyledMenuItem = styled(MenuItem)`
  min-height: 46px !important;
  border-radius: 8px;
  padding: 6px 4px 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .left-side-container {
    display: flex;
    gap: 14px;
    align-items: center;
  }
  & .program-status-indicator {
    width: 12px;
    height: 34px;
    background-color: blue;
    border-radius: 8px;
  }
`;

export const StyledPlanStatus = styled("span")<{ backgroundColor: string }>`
  border-radius: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 4px 10px;
  color: white;
  font-weight: 500;
`;
