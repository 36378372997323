import { useMemo, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesForms } from "../../../styles/Forms__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//icons
import Delete from "../../../public/icons/delete";
//components
import Table from "../../layout/commun/Table";
import PageHeader from "../../pageHeader/PageHeader";
//utils
import { columns } from "./Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { filterElements, getMessageError } from "../../Utils";
//types
import {
  CountryPayload,
  GetAdminsListDocument,
  SortBy,
  useDeleteUserMutation,
  useGetAdminsListQuery,
  useUpdateUserMutation,
} from "../../../graphql/types";

const UsersList = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  //styles
  const classes = useStylesMenu();
  const FormsClasses = useStylesForms();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [sortBy, setSortBy] = useState<SortBy | null>(null);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userId, setUserId] = useState<string>("");
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  //queries&mutations
  const [updateUser] = useUpdateUserMutation();
  const UsersData = useGetAdminsListQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        sort: sortBy,
        name: search,
      },
    },
    fetchPolicy: "no-cache",
  });

  const [deleteUser] = useDeleteUserMutation();

  const UsersList = useMemo(
    () => UsersData.data?.getAdminsList.data || [],
    [UsersData.data]
  );

  const count = useMemo(
    () => UsersData.data?.getAdminsList.count || 0,
    [UsersData.data]
  );

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClickMenu =
    (item: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setUserId(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (userId) {
      navigate(`/data/users_and_roles/update_user/${userId}`);
    }
  };

  const handleCloseDelete = () => {
    setAnchorEl(null);
    setOpenDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenDelete(false);
  };

  const updateStatusHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    await updateUser({
      variables: {
        input: { id: id, isActive: event.target.checked },
      },
      refetchQueries: [GetAdminsListDocument],
      onCompleted() {
        enqueueSnackbar("User status changed succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const DeleteUserHandler = async () => {
    setOpenDelete(false);
    await deleteUser({
      variables: { input: userId ?? "" },
      onCompleted() {
        UsersData.refetch();
        enqueueSnackbar("User deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const renderCountries = (countries: CountryPayload[]) => {
    return countries?.map((item, index) => {
      if (index < 4) {
        return (
          <Chip
            key={item._id}
            label={item?.name?.EN}
            variant="outlined"
            className={FormsClasses.chips__tags}
          />
        );
      }
      return null;
    });
  };

  const renderTableRows = () => {
    return (
      UsersList.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          name: el.fname + " " + el.lname,
          date: moment(el.created_at).format("DD/MM/YYYY"),
          role: el.role,
          countries: el?.countriesList ? (
            <Box>{renderCountries(el?.countriesList)}</Box>
          ) : (
            "--"
          ),
          status: (
            <Switch
              defaultChecked={el?.isActive || false}
              onChange={(event) => updateStatusHandler(event, el._id || "")}
            />
          ),
          email: el.email,
          phone: el.phone ? el.phone.number : "----",
          actions: (
            <IconButton onClick={handleClickMenu(el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <div>
      <Box marginTop={2}>
        {useIsAuthorized("MANAGEMENT_ROLES_CREATE_ADMIN") ? (
          <PageHeader
            buttonLink="/data/users_and_roles/add_user"
            buttonText="Create User"
            filterElements={filterElements}
            setSelectedFilter={setSortBy}
            selectedFilter={sortBy}
            setSearchValue={setSearch}
          />
        ) : (
          <PageHeader
            filterElements={filterElements}
            setSelectedFilter={setSortBy}
            selectedFilter={sortBy}
            setSearchValue={setSearch}
          />
        )}
      </Box>
      <br />
      <TableContainer>
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No users found !"
          loading={UsersData.loading}
          numberOfFakeRow={30}
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>

      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {/* SAME PERMISSION IS USED FOR UPDATE AND DELETE USER */}
          {useIsAuthorized("MANAGEMENT_ROLES_UPDATE_ADMIN") && (
            <>
              <MenuItem
                onClick={handleCloseUpdate}
                className={classes.menu_container}
                disableRipple={true}
              >
                <Box>
                  <span className="menu_title">Update</span>
                </Box>
              </MenuItem>
              <MenuItem
                onClick={handleCloseDelete}
                className={classes.menu_delete_container}
                disableRipple
              >
                <div>
                  <span className="menu_title">Delete</span>
                </div>
              </MenuItem>
            </>
          )}
        </Menu>
      </Box>

      {/* DELETE USER */}
      <Dialog
        open={openDelete}
        onClose={handleCloseModalDelete}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <Delete />
          <span className="alert_dialog_title_text">Delete User ?</span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseModalDelete}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={ButtonsClasses.RedButton}
            onClick={DeleteUserHandler}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersList;
