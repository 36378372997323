import { useState } from "react";
//types
import { FilterItemProps, FilterData } from "../Utils";
//material-ui
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const FilterItem: React.FC<FilterItemProps> = ({
  title,
  data,
  selectFilter,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  //state
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "white" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListSubheader
        onClick={handleClick}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListSubheader>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {data?.map((elem: FilterData) => {
            return (
              <ListItemButton
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                key={elem.id}
              >
                <Checkbox
                  color="secondary"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginLeft: -20 }}
                  checked={elem.isSelected}
                  onClick={() => selectFilter(elem.id ?? "")}
                />
                <ListItemText
                  primary={elem.name}
                  onClick={() => selectFilter(elem.id ?? "")}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};
export default FilterItem;
