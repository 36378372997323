import { styled } from "@mui/material";
import { useRef, useEffect } from "react";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */

const StyledCustomSwitch = styled("div")`
  .selected-item {
    text-align: center;
  }

  --highlight-width: auto;
  --highlight-x-pos: 0;

  display: flex;
  /* margin: 100px 0 25px; */

  .controls {
    display: inline-flex;
    justify-content: space-between;
    background: black;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    max-width: 500px;
    padding: 0 2px;
    /* margin: auto; */
    overflow: hidden;
    position: relative;
  }

  .controls input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }

  .segment {
    /* width: 100%; uncomment for each segment to have matching width */
    min-width: 140px;
    position: relative;
    text-align: center;
    z-index: 1;
  }

  .segment label {
    cursor: pointer;
    display: block;
    font-weight: 500;
    padding: 10px;
    transition: color 0.3s ease;
    color: #ffffff;
  }

  .segment.active label {
    color: black;
  }

  .controls::before {
    content: "";
    background: #ffffff;
    border-radius: 50px;
    width: var(--highlight-width);
    /* height: 36px; */
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 0;
    z-index: 0;
  }

  /* Only allow transitions once component is ready */
  .controls.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }
`;

interface CustomSwitchProps {
  name: string;
  segments: {
    label: string;
    value: string;
    ref: any;
  }[];
  callback: (value: string) => void;
  defaultIndex: number;
  controlRef: any;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

const CustomSwitch = ({
  name,
  segments,
  callback,
  defaultIndex,
  controlRef,
  activeIndex,
  setActiveIndex,
}: CustomSwitchProps) => {
  const componentReady = useRef();

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;

    style.setProperty("--highlight-width", `${offsetWidth}px`);
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
  }, [activeIndex, callback, controlRef, segments]);

  const onInputChange = (value: string, index: number) => {
    setActiveIndex(index);
    callback(value);
  };

  return (
    <StyledCustomSwitch ref={controlRef}>
      <div className={`controls ${componentReady.current ? "ready" : "idle"}`}>
        {segments?.map((item, i) => (
          <div
            key={item.value}
            className={`segment ${i === activeIndex ? "active" : "inactive"}`}
            ref={item.ref}
          >
            <input
              type="radio"
              value={item.value}
              id={item.label}
              name={name}
              onChange={() => onInputChange(item.value, i)}
              checked={i === activeIndex}
            />
            <label htmlFor={item.label}>{item.label}</label>
          </div>
        ))}
      </div>
    </StyledCustomSwitch>
  );
};

export default CustomSwitch;
