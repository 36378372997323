import { FilterBar } from "../Utils";
import { ColumnsProps } from "../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Description",
    accessor: "description",
  },
  {
    header: "Priority",
    accessor: "priority",
  },
  {
    header: "Customer Name",
    accessor: "customer",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "Created By",
    accessor: "created_By",
  },
  {
    header: "Closed By",
    accessor: "closed_By",
  },
  {
    header: "Closed At",
    accessor: "closed_At",
  },
  {
    header: "Status",
    accessor: "status",
  },

  {
    header: "Actions",
    accessor: "actions",
  },
];
export const Priorities = [
  {
    menuText: "Low",
    menuValue: "LOW",
  },
  {
    menuText: "Medium",
    menuValue: "MEDIUM",
  },
  {
    menuText: "High",
    menuValue: "HIGH",
  },
];

export const SIDE_FILTER_OPTIONS_REMINDERS: FilterBar[] = [
  {
    id: "14",
    title: "Reminders Status",
    data: [
      { id: "PENDING", name: "Pending", isSelected: false },
      { id: "CLOSED", name: "Closed", isSelected: false },
      { id: "EXPIRED", name: "Expired", isSelected: false },
    ],
  },
];
