import TablePaginationMUI, {
  TablePaginationProps,
} from "@mui/material/TablePagination";
import Pagination, { PaginationProps } from "@mui/material/Pagination";
import { withStyles, createStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const styles = createStyles({
  toolbar: {
    "&.MuiToolbar-root": {
      minHeight: "52px",
      paddingRight: "25px",
    },
  },

  displayedRows: {
    marginRight: "30px",
  },
});

type TablePaginationCombinedProps = TablePaginationProps & {
  paginationProps?: PaginationProps;
};

const TablePagination = withStyles(styles)(
  ({
    paginationProps,
    ...tablePaginationProps
  }: TablePaginationCombinedProps) => (
    <TablePaginationMUI
      {...tablePaginationProps}
      ActionsComponent={() => (
        <Box>
          <Pagination
            sx={{
              "&": {
                shrink: 0,
                display: "flex",
                justifyContent: "end",
                width: "max-content",
              },
              "& .MuiPaginationItem-root": {
                minWidth: "max-content",
                padding: "10px",
                "&.Mui-selected": {
                  backgroundColor: "black",
                  color: "white",
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                },
              },
            }}
            {...paginationProps}
          />
        </Box>
      )}
    />
  )
);

export default TablePagination;
