import { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import clsx from "clsx";
import moment from "moment";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  TextField,
  Typography,
} from "@mui/material";
//icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//context
import { CustomerContext } from "../viewCustomer/ViewCustomer";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesLeads } from "../../../styles/Leads__styles";
import { useStylesSelect } from "../../../styles/Select__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { StyledMenuItem } from "../CustomerMenuProgram/CustomerMenuProgram.styles";
//types
import {
  GetCustomerProfileByIdDocument,
  useCalculeRefundPlanQuery,
  useCancelPlanMutation,
  useGetActifCustomerPlansQuery,
} from "../../../graphql/types";
import { ApolloError } from "@apollo/client";
import { CancelMealPlanState } from "../Types";
import { CustomerActionsTypes } from "./Types";
//utils
import { getMessageError } from "../../Utils";

const CancelMealPlant: React.FC<CustomerActionsTypes> = ({
  expanded,
  handleChange,
}) => {
  const { id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const SelectClasses = useStylesSelect();
  const ButtonClasses = useStylesButtons();
  const ContainersClasses = useStylesContainers();
  const LeadsClasses = useStylesLeads();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [open, setOpen] = useState<boolean>(false);

  //react-hook-form
  const methodsCancelMealPlan = useForm<CancelMealPlanState>({
    defaultValues: {
      customerProgram: null,
      reasonOfCancelation: "",
      numberOfBoxesConsumed: "",
      convertedToPlans: "",
      balanceToRefund: "",
    },
  });
  const {
    formState: { errors },
    register,
    reset,
    control,
    setValue,
  } = methodsCancelMealPlan;

  const dataWatch = useWatch({
    control: methodsCancelMealPlan.control,
  });

  //context
  const context = useContext(CustomerContext);

  //query
  const { data: CalculeRefundPlanData } = useCalculeRefundPlanQuery({
    variables: {
      planIdInput: dataWatch?.customerProgram || "",
    },
    skip: !dataWatch?.customerProgram || expanded != "CANCEL_MEAL_PLAN",
    onCompleted: () => {},
  });

  const { data: CustomerActifPlansData } = useGetActifCustomerPlansQuery({
    variables: {
      customerId: id || "",
    },
    skip: !id || expanded != "CANCEL_MEAL_PLAN",
  });

  //mutations
  const [cancelPlan, { loading: loadingCancelPlan }] = useCancelPlanMutation();

  //consts
  const CustomerActifPlans = CustomerActifPlansData?.getActifCustomerPlans;
  const amountToRefund =
    CalculeRefundPlanData?.calculeRefundPlan?.amountToRefund;
  const numberTotalOfBoxes =
    CalculeRefundPlanData?.calculeRefundPlan?.numberTotalOfBoxes;
  const numberOfBoxesConsumed =
    CalculeRefundPlanData?.calculeRefundPlan?.numberOfBoxesConsumed;
  const balance =
    numberTotalOfBoxes !== null &&
    numberTotalOfBoxes !== undefined &&
    numberOfBoxesConsumed !== null &&
    numberOfBoxesConsumed !== undefined
      ? numberTotalOfBoxes - numberOfBoxesConsumed
      : "";

  //useEffects
  useEffect(() => {
    setValue(
      "customerProgram",
      context?.getCustomerProfileById?.plan?._id || ""
    );
  }, []);

  //functions
  const onSubmitCancelMealPlan: SubmitHandler<CancelMealPlanState> = async (
    data
  ) => {
    try {
      await cancelPlan({
        variables: {
          cancelPlanInput: {
            planId: data.customerProgram || "",
            reason: data.reasonOfCancelation,
          },
        },
        refetchQueries: [
          {
            query: GetCustomerProfileByIdDocument,
            variables: {
              customerId: context?.getCustomerProfileById._id || "",
            },
          },
        ],
        onCompleted: () => {
          reset();
          enqueueSnackbar("Cancellation succuessfully done", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  //render
  const renderItems = () => {
    return CustomerActifPlans?.map((item) => {
      return (
        <StyledMenuItem
          key={item._id}
          value={item?._id || ""}
          className={
            dataWatch?.customerProgram === item._id
              ? clsx(
                  SelectClasses.option_item,
                  SelectClasses.option_item_selected
                )
              : SelectClasses.option_item
          }
          onClick={() => setValue("customerProgram", item._id || "")}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <span>{item?.program?.name?.EN}</span>
            <div className="left-side-container">
              <span>
                {`(${moment(item.startDate).format("DD/MM/YYYY")} - ${moment(
                  item.expiryDate
                ).format("DD/MM/YYYY")})`}{" "}
              </span>
            </div>
          </Box>
        </StyledMenuItem>
      );
    });
  };

  return (
    <Accordion
      expanded={expanded === "CANCEL_MEAL_PLAN"}
      onChange={handleChange("CANCEL_MEAL_PLAN")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="#B27D3F" fontSize={18}>
          Cancel Meal Plan
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormProvider {...methodsCancelMealPlan}>
          <form
            onSubmit={methodsCancelMealPlan.handleSubmit(
              onSubmitCancelMealPlan
            )}
            id="CANCEL_MEAL_PLAN_FORM"
          >
            <Box display="flex" gap={3}>
              <Box display="flex" flexDirection="column" flex={2}>
                {/* Plan to cancel */}
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={ContainersClasses.section__subtitle}>
                    Plan to cancel
                  </p>
                  <Controller
                    name="customerProgram"
                    control={control}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Select
                          {...rest}
                          sx={{ minWidth: "400px" }}
                          className={LeadsClasses.select_container}
                          onChange={(event) => {
                            let plan = event.target.value;
                            onChange(plan);
                          }}
                          value={dataWatch.customerProgram}
                        >
                          {renderItems()}
                        </Select>
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                    rules={{
                      required: {
                        message: "Field Required",
                        value: true,
                      },
                    }}
                  />
                </Box>

                {/* Reason */}
                <Box
                  display="flex"
                  flexDirection="column"
                  flexGrow={1}
                  minWidth="50%"
                >
                  <p className={ContainersClasses.section__subtitle}>
                    Reason for cancelation
                  </p>
                  <TextField
                    placeholder="Reason of cancelation"
                    sx={{ width: "100%", minWidth: "500px" }}
                    multiline={true}
                    maxRows={4}
                    minRows={4}
                    {...register("reasonOfCancelation", {
                      required: "Enter customer reason of cancelation",
                      minLength: {
                        value: 10,
                        message: "Please enter a least 10 characters",
                      },
                    })}
                  />
                  <p className={ContainersClasses.section__error__helper}>
                    {errors?.reasonOfCancelation?.message}
                  </p>
                </Box>
              </Box>

              <Box display="flex" flexWrap="wrap" gap="2px 12px" flex={1}>
                {/* Number of boxes consumed */}
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={ContainersClasses.section__subtitle}>
                    Number of boxes consumed
                  </p>
                  <Controller
                    name="numberOfBoxesConsumed"
                    control={methodsCancelMealPlan.control}
                    render={({ field: { ref, onChange, ...rest } }) => (
                      <TextField
                        autoComplete="off"
                        size="medium"
                        sx={{ minWidth: "300px" }}
                        value={numberOfBoxesConsumed}
                      />
                    )}
                  />
                </Box>

                {/* Balance to refund */}
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={ContainersClasses.section__subtitle}>
                    Balance to refund
                  </p>
                  <Controller
                    name="balanceToRefund"
                    control={methodsCancelMealPlan.control}
                    render={({ field: { ref, onChange, ...rest } }) => (
                      <TextField
                        autoComplete="off"
                        size="medium"
                        sx={{ minWidth: "300px" }}
                        value={balance}
                      />
                    )}
                  />
                </Box>

                {/* Amount to refund */}
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={ContainersClasses.section__subtitle}>
                    Amount to refund
                  </p>
                  <Controller
                    name="balanceToRefund"
                    control={methodsCancelMealPlan.control}
                    render={({ field: { ref, onChange, ...rest } }) => (
                      <TextField
                        autoComplete="off"
                        size="medium"
                        sx={{ minWidth: "300px" }}
                        value={amountToRefund}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="flex-end"
              mt={1}
            >
              <Button
                sx={{ height: 40 }}
                variant="contained"
                className={ButtonClasses.button_open_list_page}
                onClick={() => setOpen(true)}
                disabled={loadingCancelPlan}
              >
                {loadingCancelPlan && (
                  <CircularProgress
                    size={20}
                    style={{ color: "white", marginRight: 6 }}
                  />
                )}
                Cancel
              </Button>
            </Box>

            {/* CONFIRMATION MODAL */}
            <Dialog
              open={open}
              onClose={handleCloseModal}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.alert_dialog_title}>
                <span className="alert_dialog_title_text">Confirm Action</span>
              </DialogTitle>
              <DialogContent className={DialogClasses.alert_dialog_content}>
                <DialogContentText>
                  Are you sure you want to cancel this meal plan ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={DialogClasses.alert_dialog_actions}>
                <Button
                  className={ButtonsClasses.GreyButton}
                  onClick={handleCloseModal}
                >
                  Exit
                </Button>
                <Button
                  className={ButtonsClasses.GreenButton}
                  type="submit"
                  form="CANCEL_MEAL_PLAN_FORM"
                  color="primary"
                  autoFocus
                  onClick={() => setOpen(false)}
                >
                  Cancel Meal Plan
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default CancelMealPlant;
