import { useState } from "react";
import CancelMealPlant from "./CancelMealPlant";
import EditCalories from "./EditCalories";
import EditDaysOff from "./EditDaysOff";
import EditMealPlant from "./EditMealPlan";
import EditPlan from "./EditPlan";
import ExtendMealPlant from "./ExtendMealPlant";
import NewMealPlant from "./NewMealPlant";
import RenewMealPlant from "./RenewMealPlant";
import EditMealPlanWithPayment from "./EditMealPlanWithPayement";
import TransferBox from "./TransferBox/TransaferBox";

const CostumerActions = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <NewMealPlant expanded={expanded} handleChange={handleChange} />
      <EditMealPlant expanded={expanded} handleChange={handleChange} />
      <EditMealPlanWithPayment
        expanded={expanded}
        handleChange={handleChange}
      />
      <EditDaysOff expanded={expanded} handleChange={handleChange} />
      <RenewMealPlant expanded={expanded} handleChange={handleChange} />
      <CancelMealPlant expanded={expanded} handleChange={handleChange} />
      <ExtendMealPlant expanded={expanded} handleChange={handleChange} />
      <EditCalories expanded={expanded} handleChange={handleChange} />
      <EditPlan expanded={expanded} handleChange={handleChange} />
      <TransferBox expanded={expanded} handleChange={handleChange} />
    </>
  );
};

export default CostumerActions;
