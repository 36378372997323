import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Allergy Category",
    accessor: "name",
  },
  {
    header: "Ingredients",
    accessor: "ingredients",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
