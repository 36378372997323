import React from "react";
import ReactDOM from "react-dom";
import * as dotenv from "dotenv";
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/apolloClient";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: "https://8efc01be62a0d79ff7ce4360978aab64@o4506479963865088.ingest.sentry.io/4506479967535104",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

dotenv.config();

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);
