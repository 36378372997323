import { Droppable, Draggable } from "react-beautiful-dnd";
import { List, ListItem, Paper, Typography } from "@material-ui/core";
import { Box, Button, Chip, Grid } from "@mui/material";
import { ChefPayload, GetAllTasksPayload } from "../../../graphql/types";

interface ChefsDropSectionProps {
  chefList: ChefPayload[];
  _id: string;
  items: {
    [category: string]: GetAllTasksPayload[];
  };
  loading: boolean;
}
const ChefsDropSection: React.FC<ChefsDropSectionProps> = ({
  chefList,
  _id,
  items,
  loading,
}) => {
  const chefName = chefList?.find((chef) => chef._id === _id)?.name;

  return (
    <Paper>
      <Grid
        container
        spacing={1}
        style={{
          padding: ".5rem 1rem",
        }}
      >
        <Grid item xs={2}>
          <Typography variant="h6">{chefName}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Box display="flex" flexDirection="row">
            {Object.keys(items).map((category) => (
              <Droppable droppableId={`${_id}-${category}`} key={category}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ width: "20%" }}
                  >
                    <List>
                      {items?.[category as keyof typeof items]?.map(
                        (item, index) => (
                          <Draggable
                            key={item.name.EN}
                            draggableId={item.name.EN!}
                            index={index}
                          >
                            {(provided) => (
                              <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Chip
                                  label={
                                    <>
                                      <Typography
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          fontWeight: "bold",
                                          fontSize: 14,
                                          color: "black",
                                        }}
                                      >
                                        {item.name.EN}
                                      </Typography>
                                      <Typography
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          color: "black",
                                          fontSize: 12,
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {`S-${item.units.small} M-${item.units.medium} L-${item.units.large} = ${item.units.total} pax`}
                                      </Typography>
                                    </>
                                  }
                                  style={{
                                    marginRight: "2px",
                                    width: "185px",
                                    height: "70px",
                                    justifyContent: "space-between",
                                  }}
                                />
                              </ListItem>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </List>
                  </Box>
                )}
              </Droppable>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ChefsDropSection;
