import React, { useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
//material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//logo
import dieture_logo_black from "../../../public/images/dieture_logo_black.png";
//types
import { BulkCookingReportProps } from "../Types";

const BulkCookingReportPrint = React.forwardRef<
  HTMLDivElement,
  BulkCookingReportProps
>(({ generateCookingReport, date }, ref) => {
  const bulkCookingData = useMemo(
    () => generateCookingReport?.getBulkCookingReport,
    [generateCookingReport?.getBulkCookingReport]
  );

  const current = new Date();
  const today = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const currentTime =
    current.getHours() +
    ":" +
    current.getMinutes() +
    ":" +
    current.getSeconds();

  const Table = styled.table`
    border-radius: 5px;
  `;

  const Th = styled.th`
    border: 1px solid black;
    border-radius: 5px;
  `;

  const Td = styled.td`
    border: 1px solid black;
    border-radius: 5px;
  `;
  //render
  return (
    <div ref={ref}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography style={{ fontSize: 14 }} fontWeight={600}>
          Bulk Cooking Report
        </Typography>
        <img src={dieture_logo_black} height="50px" alt="dieture_logo_black" />
        <Box>
          <Typography variant="body2" fontWeight={600}>
            Delivery on: {moment(date).format("DD/MM/YYYY-dddd")}
          </Typography>
          <Typography fontSize={8.5}>
            Generated on : {today}-{currentTime}
          </Typography>
        </Box>
      </Box>

      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            color: "#232222",
            fontWeight: 400,
            fontSize: 20,
            paddingLeft: 20,
            justifyContent: "space-between",
            width: "90%",
            margin: "auto",
          }}
        >
          {bulkCookingData?.map((item) => (
            <div key={item.dish?._id}>
              <div style={{ pageBreakInside: "avoid", marginBlockStart: 5 }}>
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: 15,
                    width: 100,
                    height: 95,
                  }}
                >
                  <div>{item?.meal}:</div>
                  <div style={{ width: 180 }}> {item?.dish?.name?.EN}</div>
                </div>

                <Table
                  style={{
                    borderWidth: 1,
                    borderColor: "#232222",
                    borderCollapse: "collapse",
                    marginRight: 8,
                    height: 450,
                    width: 200,
                    marginBlockEnd: 10,
                  }}
                >
                  <tr
                    style={{
                      backgroundColor: "#232222",
                      color: "#FFFF",
                      borderColor: "black",
                    }}
                  >
                    <Th
                      style={{
                        fontSize: 10,
                        textAlignLast: "center",
                        borderColor: "black",
                        paddingLeft: 8,
                      }}
                      colSpan={2}
                    >
                      Total Prep Weight
                    </Th>
                  </tr>

                  {item?.ingredients?.map((el) => (
                    <tr style={{ fontSize: 12 }} key={el.ingredient?.name?.EN}>
                      <Td style={{ textAlign: "center" }}>
                        {" "}
                        {el?.ingredient?.name?.EN}{" "}
                      </Td>
                      <Td style={{ fontWeight: 500, textAlign: "center" }}>
                        {" "}
                        {el?.weight?.toFixed(2)} g{" "}
                      </Td>
                    </tr>
                  ))}

                  <Th colSpan={3}>
                    {" "}
                    <p style={{ fontSize: 14, fontWeight: 500 }}> Program :</p>
                    <p style={{ fontSize: 12, fontWeight: 500 }}>
                      {" "}
                      {item?.program?.name?.EN}{" "}
                    </p>
                    <p style={{ fontSize: 14, fontWeight: 500 }}>
                      Total Cooking Weight:
                    </p>
                    <p style={{ fontSize: 12, fontWeight: 500 }}>
                      {item?.totalCookingWeight?.toFixed(3)}
                    </p>
                  </Th>
                </Table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default BulkCookingReportPrint;
