import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { useSnackbar } from "notistack";
//material ui
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//styles
import { useStylesProgramMenu } from "../../../styles/ProgramMenu_styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//types
import { programMenuItem } from "../Types";
import {
  DishPayload,
  FindProgramMenuByMonthDocument,
  useSwitchDishWithAnotherMutation,
} from "../../../graphql/types";
import { ApolloError } from "@apollo/client";
//utils
import { getMessageError } from "../../Utils";
import { getDisplayType } from "../Utils";

const MenuItem: React.FC<programMenuItem> = ({
  display, //this is the display name of the type,ex:in case of Ramadam this takes the custom names such as iftar kit ... else it is undefined
  deleteDish,
  dishLoading,
  getCategory,
  data,
  pushDish,
  dishList,
  selectedDate,
  type,
  category,
  dishName, //this contains the string the user types when searching for a dish
  setCategory,
  setDishName,
  lunchDishName,
  swapDish,
  menuId,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //state
  const [open, setOpen] = useState<boolean>(false);
  const [selectedForSwapDishName, setSelectedForSwapDishName] =
    useState<string>();
  const [selectedForSwapDishId, setSelectedForSwapDishId] = useState<string>();
  const [dishToSwapTo, setDishToSwapTo] = useState<any | null>();

  //styles
  const MenuClasses = useStylesProgramMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //mutation
  const [swapDishWithAnother, { loading }] = useSwitchDishWithAnotherMutation();

  //functions
  const onDragEnd = (result: DropResult) => {
    swapDish(
      result.source.index,
      result.destination?.index as number,
      type,
      data.date
    );
  };

  const handleCloseModal = () => {
    setOpen(false);
    setDishToSwapTo(null);
  };

  const onSwapIconClickedHandler = (name: string, id: string) => {
    setCategory(type);
    getCategory(category, data?.date, dishName);
    setOpen(true);
    setSelectedForSwapDishName(name);
    setSelectedForSwapDishId(id);
  };

  const swapDishHandler = async () => {
    try {
      await swapDishWithAnother({
        variables: {
          input: {
            date: data.date,
            newDishId: dishToSwapTo._id,
            oldDishId: selectedForSwapDishId || "",
            mealType: type,
            menuId: menuId,
          },
        },
        refetchQueries: [FindProgramMenuByMonthDocument],
        onCompleted() {
          enqueueSnackbar("Dish Swapped Successfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          handleCloseModal();
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };
  return (
    <Box className={MenuClasses.dish_containerV2}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box className={MenuClasses.meal_container}>
          {getDisplayType(type, display)}
        </Box>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <Box
              className={MenuClasses.chip_container}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {data?.meals
                .find((item) => item.typeOfMeal === type)
                ?.dishes?.map((el, index) => {
                  const isFirstItem = index === 0;
                  return (
                    <Draggable key={el.id} draggableId={el.id} index={index}>
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Chip
                            onDelete={() => {
                              deleteDish(el.id, data.date, type);
                            }}
                            style={{
                              marginRight: "2px",
                              width: "195px",
                              height: "70px",
                              justifyContent: "space-between",
                            }}
                            label={
                              <>
                                <Typography
                                  noWrap={false}
                                  sx={{ whiteSpace: "pre-wrap" }}
                                  mt={3}
                                  fontSize="small"
                                >
                                  {el.name}
                                </Typography>
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: 5,
                                  }}
                                >
                                  {el.code}
                                </div>
                                <Tooltip title={"Swap meal"} placement="top">
                                  <>
                                    {isFirstItem && (
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: 4,
                                          fontWeight: "bold",
                                          color: "#6c6c6c",
                                        }}
                                      >
                                        default
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -4,
                                        right: -3,
                                      }}
                                    >
                                      <IconButton
                                        onClick={() =>
                                          onSwapIconClickedHandler(
                                            el.name,
                                            el.id
                                          )
                                        }
                                      >
                                        <CachedIcon
                                          sx={{ width: 20, height: 20 }}
                                        />
                                      </IconButton>
                                    </div>
                                  </>
                                </Tooltip>
                              </>
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Box className={MenuClasses.auto_fill_container}>
          <Autocomplete
            loading={dishLoading}
            loadingText="Loading..."
            sx={{
              display: "inline-block",
            }}
            id="mealChoiceId"
            onFocus={() => {
              setCategory(type);
              getCategory(type, data.date, lunchDishName);
            }}
            onChange={(
              event: React.SyntheticEvent<Element, Event>,
              newValue
            ) => {
              pushDish(type, data.date, newValue as DishPayload);
            }}
            options={
              category === type && selectedDate === data.date && dishList
                ? dishList
                : []
            }
            getOptionLabel={(option) => option?.name?.EN || ""}
            renderInput={(params) => (
              <div
                ref={params.InputProps.ref}
                className={MenuClasses.auto_containerV2}
              >
                <input
                  type="text"
                  {...params.inputProps}
                  placeholder="Enter meal"
                  className={MenuClasses.auto_inputV2}
                  value={dishName}
                  onChange={(e) => {
                    setDishName(e.target.value);
                    setCategory(type);
                  }}
                />
              </div>
            )}
          />
        </Box>
      </DragDropContext>

      {/* SWAP DISH MODAL */}
      <Dialog
        open={open}
        onClose={() => null}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <CachedIcon />
          <span className="alert_dialog_title_text">Swap Dish</span>
        </DialogTitle>
        <DialogContent
          className={DialogClasses.alert_dialog_contentv2}
          sx={{ flexDirection: "column", alignItems: "center" }}
        >
          <DialogContentText fontSize={18}>You are swapping</DialogContentText>
          <DialogContentText
            sx={{ color: "#C72D2D", fontSize: 20, fontWeight: "600" }}
          >
            {selectedForSwapDishName}
          </DialogContentText>
          <DialogContentText fontSize={18}>for</DialogContentText>
          <Box width="100%" marginTop={3}>
            <Autocomplete
              id="dishId"
              loading={dishLoading}
              options={dishList ? dishList : []}
              onChange={(_, value) => {
                setDishToSwapTo(value);
              }}
              value={dishToSwapTo}
              getOptionLabel={(option) => option.name?.EN || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="secondary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.name?.EN}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} placeholder="Dish" label="Dish" />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            className={ButtonsClasses.GreenButton}
            onClick={swapDishHandler}
            color="primary"
            autoFocus
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                size={20}
                style={{ color: "white", marginRight: 6 }}
              />
            )}
            Swap
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MenuItem;
