import { useMemo, useState } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
//material ui
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, FileCopy } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import ExcelIcon from "../../public/icons/ExcelIcon";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//components
import PageHeader from "../pageHeader/PageHeader";
import CustomSplitButton from "../reusable/SplitButton/CustomSpiltButton";
//styles
import { TableContainer } from "../../styles/Table__styles";
import { useStylesMenu } from "../../styles/Menu__styles";
import { useStylesDialog } from "../../styles/Dialog__styles";
import { useStylesButtons } from "../../styles/Buttons__styles";
//utils
import { useIsAuthorized } from "../../utils/Hooks/useIsAuthorized";
import Table from "../layout/commun/Table";
//queries&mutations
import {
  ComplainsStatus,
  ComplainsTagPayload,
  GetComplainsListDocument,
  SortBy,
  useDeleteComplainMutation,
  useGetComplainByIdQuery,
  useGetComplainsListQuery,
  useGetcomplainsTagsListQuery,
  useUpdateComplainStatusMutation,
} from "../../graphql/types";
//utils
import { filterElements, getMessageError } from "../Utils";
import { ComplainStatus, columns } from "./Utils";
import { generateRapport } from "../reports/Utils";
//types
import { ApolloError } from "@apollo/client";
//context api
import { useAuth } from "../../contextAPI";

const ComplainsList = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let auth = useAuth();
  const isAuthorizedToChangeComplaintStatus = useIsAuthorized(
    "MANAGEMENT_COMPLAIN_UPDATE_STATUS"
  );
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();
  const ButtonClasses = useStylesButtons();

  //state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [idComplain, setIdComplain] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortBy>("CREATE_ASC");
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [ticketNumberCopied, setTicketNumberCopied] = useState(false);
  const [type, setType] = useState<ComplainsTagPayload[]>([]);

  //mutations&queries
  const Tags = useGetcomplainsTagsListQuery();
  const [updateStatus] = useUpdateComplainStatusMutation();
  const [deleteComplain] = useDeleteComplainMutation();
  const complainsData = useGetComplainsListQuery({
    variables: {
      getComplainsInput: {
        page: page,
        documentPerPage: rowsPerPage,
        search: search,
        sort: sortBy,
        from: from,
        to: to,
        type: type
          ?.map((el) => el?._id)
          .filter((id): id is string => id !== undefined),
      },
    },
    fetchPolicy: "no-cache",
  });

  const complainData = useGetComplainByIdQuery({
    variables: {
      id: idComplain || "",
    },
    fetchPolicy: "no-cache",
    skip: !openDetailsModal,
  });

  const ComplainsList = useMemo(
    () => complainsData.data?.getComplainsList.data || [],
    [complainsData.data]
  );

  const count = useMemo(
    () => complainsData.data?.getComplainsList.count || 0,
    [complainsData.data]
  );

  const handleSubmitChangeStatus = async (status: string, id: string) => {
    try {
      await updateStatus({
        variables: {
          updateComplainStatusInput: {
            _id: id,
            status: status as ComplainsStatus,
          },
        },
        refetchQueries: [GetComplainsListDocument],
        onCompleted: () => {
          setAnchorEl(null);
          enqueueSnackbar("Complain Status succuessfully Updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 10000);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const DeleteComplainHandler = async () => {
    setOpen(false);
    await deleteComplain({
      variables: { id: idComplain || "" },
      refetchQueries: [GetComplainsListDocument],
      onCompleted() {
        enqueueSnackbar("Complain deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const renderTableRows = () => {
    return (
      ComplainsList?.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          title: (
            <Button
              onClick={() => openDetailsModalHandler(el._id || "")}
              sx={{
                width: "100%",
                textAlign: "left",
                justifyContent: "flex-start",
              }}
            >
              <Box textAlign="left" fontSize={14}>
                {el.title}
              </Box>
            </Button>
          ),
          customer:
            isAuthorizedToSeeProfile && el.customer?.fname ? (
              <a
                style={{ textDecoration: "none" }}
                href={`/admin/customers/view_customer/${el?.customer?._id}`}
              >
                <Button>{`${el.customer?.fname} ${el.customer?.lname}`}</Button>
              </a>
            ) : el.customer?.fname ? (
              el.customer?.fname + " " + el.customer?.lname
            ) : (
              "----"
            ),
          date: moment(el?.date).format("DD/MM/YYYY"),
          ticket: el.ticketId ? (
            <Box display="flex" alignItems="center">
              <Box fontSize={14}>{el.ticketId}</Box>
              <Tooltip
                title={
                  ticketNumberCopied
                    ? "copied"
                    : "Click here to copy the ticket number"
                }
              >
                <IconButton
                  onClick={() => handleCopyClick(el.ticketId || "")}
                  aria-label="Copy"
                >
                  <FileCopy style={{ color: "#000", width: "15px" }} />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            "----"
          ),
          type: el.type?.name,
          image: el.photo ? (
            <a href={el.photo} target="_blank" download>
              <Button
                variant="contained"
                size="medium"
                className={ButtonClasses.button_cancel_form}
              >
                Download
              </Button>
            </a>
          ) : (
            "---"
          ),
          status: isAuthorizedToChangeComplaintStatus ? (
            <CustomSplitButton
              defaultValue={el.status || "PENDING"}
              onStatuschange={handleSubmitChangeStatus}
              id={el._id || ""}
              optionsList={ComplainStatus}
            />
          ) : (
            el.status
          ),
          actions: (
            <IconButton onClick={handleClickMenu(el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (idComplain) {
      navigate(`/admin/complains/update_complain/${idComplain}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
  };

  const handleClickMenu =
    (item: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setIdComplain(item);
    };

  const openDetailsModalHandler = (id: string) => {
    setOpenDetailsModal(true);
    setIdComplain(id);
  };

  const handleCopyClick = (name: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      setTicketNumberCopied(true);
      window.focus();
      navigator.clipboard.writeText(name);
      setTimeout(() => {
        setTicketNumberCopied(false);
      }, 1000);
    }
  };

  const onGenerateHandler = async () => {
    setLoading(true);
    try {
      await generateRapport(auth.token || "", "COMPLAINTS_REPORT", from, to);
    } catch (error) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {useIsAuthorized("MANAGEMENT_COMPLAIN_CREATE") ? (
        <PageHeader
          buttonLink="/admin/complains/add_complain"
          params={{
            state: { CustomerId: null, dishId: null },
          }}
          buttonText="Add Complaint"
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      ) : (
        <PageHeader
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      )}

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" mb={1}>
          <Button
            onClick={onGenerateHandler}
            className={ButtonClasses.button_open_list_page}
            startIcon={<ExcelIcon />}
            disabled={loading}
          >
            Export Excel
            {loading ? (
              <CircularProgress
                size={14}
                color="inherit"
                style={{ marginLeft: 5 }}
              />
            ) : (
              ""
            )}
          </Button>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" mb={5}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={"40%"}
        >
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              value={from}
              inputFormat="DD/MM/YYYY"
              disablePast={false}
              disableFuture={false}
              onChange={(newValue) => {
                setFrom(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="From"
                  sx={{
                    backgroundColor: "#fff",
                  }}
                />
              )}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              value={to}
              inputFormat="DD/MM/YYYY"
              disablePast={false}
              disableFuture={false}
              onChange={(newValue) => {
                setTo(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="To"
                  sx={{
                    backgroundColor: "#fff",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <Box
        marginTop={3}
        marginBottom={3}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Autocomplete
          id="type"
          multiple
          sx={{ width: "40%" }}
          options={Tags.data?.getcomplainsTagsList || []}
          onChange={(_, value) => {
            setType(value);
          }}
          value={type}
          getOptionLabel={(option) => option.name || ""}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                color="secondary"
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select a type" label="Type" />
          )}
        />
      </Box>

      <TableContainer>
        <Table
          columns={columns}
          loading={complainsData.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Complains found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("MANAGEMENT_COMPLAIN_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("MANAGEMENT_COMPLAIN_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}
          <MenuItem
            onClick={handleClose}
            className={classes.menu_container}
            disableRipple={true}
          >
            <div onClick={() => setOpenDetailsModal(true)}>
              <span className="menu_title">Details</span>
            </div>
          </MenuItem>
        </Menu>

        {/* DELETE COMPLAIN MODAL */}
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Complain ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Complain ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={DeleteComplainHandler}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* COMPLAIN DETAILS MODAL */}
        <Dialog
          open={openDetailsModal}
          onClose={handleCloseDetailsModal}
          className={DialogClasses.dialog_renew_container}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            paddingLeft={"10%"}
            paddingRight={"10%"}
          >
            <Box marginTop={10}>
              <Box>
                Title :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.title}
                </Typography>
              </Box>
              <Box>
                Description :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.description}
                </Typography>
              </Box>
              <Box>
                Type :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.type?.name}
                </Typography>
              </Box>
              <Box>
                Date :{" "}
                <Typography color="black">
                  {moment(complainData.data?.getComplainById.date).format(
                    "DD/MM/YYYY"
                  )}
                </Typography>
              </Box>
              <Box>
                Customer :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.customer
                    ? complainData.data?.getComplainById.customer?.fname +
                      " " +
                      complainData.data?.getComplainById.customer?.lname
                    : "---"}
                </Typography>
              </Box>
              <Box>
                Dish :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.dish?.name
                    ? complainData.data?.getComplainById.dish?.name.EN
                    : "---"}
                </Typography>
              </Box>
              <Box>
                Driver :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.driver?.name
                    ? complainData.data?.getComplainById.driver?.name
                    : "---"}
                </Typography>
              </Box>
              <Box>
                Reported By :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.createdBy?.fname +
                    " " +
                    complainData.data?.getComplainById.createdBy?.lname}
                </Typography>
              </Box>
              <Box>
                Status :{" "}
                <Typography color="black">
                  {complainData.data?.getComplainById.status}
                </Typography>
              </Box>
            </Box>
            <Avatar
              alt={complainData.data?.getComplainById.photo || ""}
              src={complainData.data?.getComplainById.photo || ""}
              variant="rounded"
              style={{ width: 400, height: 400, marginTop: "8%" }}
            />
          </Box>
        </Dialog>
      </div>
    </div>
  );
};

export default ComplainsList;
