import { FilterBar } from "../../Utils";
import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Dish Photo",
    accessor: "photoUrl",
  },
  {
    header: "Name",
    accessor: "name",
    cellProps: { align: "center", width: "200px" },
  },
  {
    header: "Tags",
    accessor: "tags",
  },
  {
    header: "Rating",
    accessor: "rating",
  },
  {
    header: "Meal Type",
    accessor: "mealType",
  },
  {
    header: "Description",
    accessor: "description",
  },
];

export const SIDE_FILTER_OPTIONS_DISHES: FilterBar[] = [
  {
    id: "10",
    title: "Meals",
    data: [
      { id: "BREAKFAST", name: "Breakfast", isSelected: false },
      { id: "LUNCH", name: "Lunch/Dinner", isSelected: false },
      { id: "SNACKS", name: "Snack", isSelected: false },
      { id: "ADDON", name: "Add On", isSelected: false },
    ],
  },
  /* {
    id: "11",
    title: "Rating",
    data: [
      { id: "0_1", name: "from 0 to 1", isSelected: false },
      { id: "1_2", name: "from 1 to 2", isSelected: false },
      { id: "2_3", name: "from 2 to 3", isSelected: false },
      { id: "3_4", name: "from 3 to 4", isSelected: false },
      { id: "4_5", name: "from 4 to 5", isSelected: false },
    ],
  }, */
];
