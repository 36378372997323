import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import { ApolloError } from "@apollo/client";
//material
import { Box, TextField, Button, CircularProgress } from "@mui/material";
//styles
import {
  useStylesDelivery,
  Container,
  ContainerForm,
} from "../../styles/Delivery__styles";
import { useStylesButtons } from "../../styles/Buttons__styles";
//components
import PhoneInput from "../layout/PhoneInput";
// mutations and queries
import {
  GetALlChefsDocument,
  useCreateChefMutation,
} from "../../graphql/types";
//types
import { ChefProps } from "./types";
//utils
import { useCountryPrefix } from "../../utils/Hooks/useCountryPrefix";
import { getMessageError } from "../Utils";

const AddChef = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const countryPrefix = useCountryPrefix();
  //styles
  const DeliveryClasses = useStylesDelivery();
  const ButtonClasses = useStylesButtons();

  // queries and mutations
  const [createChef, { loading }] = useCreateChefMutation();

  //react-hook-form
  const methods = useForm<ChefProps>({
    defaultValues: {
      chef__name: "",
      chef__number: "",
      chef__prefix: countryPrefix?.callingCode,
      chef__email: "",
      chef__password: "",
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  //functions
  const onSubmit: SubmitHandler<ChefProps> = async (data) => {
    try {
      const phone = data.chef__number.replace(/\s/g, "");

      await createChef({
        variables: {
          createChefInput: {
            name: data?.chef__name,
            email: data?.chef__email,
            password: data?.chef__password,
            phone: {
              prefix: `+${data.chef__prefix}`,
              number: phone,
            },
          },
        },
        refetchQueries: [{ query: GetALlChefsDocument }],
        onCompleted: () => {
          navigate("/admin/chef/list_chefs");
          enqueueSnackbar("Chef succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };
  return (
    <FormProvider {...methods}>
      <Container>
        <p className={DeliveryClasses.delivery__title}>Create Chef</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContainerForm>
            <Box
              className={DeliveryClasses.delivery__container}
              sx={{
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={DeliveryClasses.delivery__subtitle}>
                    Chef Name*
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Chef Name"
                    sx={{
                      width: "100%",
                    }}
                    {...register("chef__name", {
                      required: "Name is Mandatory",
                      minLength: {
                        value: 2,
                        message: "Please enter a least 2 characters",
                      },
                    })}
                  />
                  <p className={DeliveryClasses.delivery__error__helper}>
                    {errors?.chef__name?.message}
                  </p>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={DeliveryClasses.delivery__subtitle}>
                    Chef Phone Number*
                  </p>
                  <PhoneInput
                    labelFormPrefix="chef__prefix"
                    labelFormNumber="chef__number"
                    placeholder="Phone Number"
                  />
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={DeliveryClasses.delivery__subtitle}>
                    Chef Email*
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder=" Chef Email"
                    sx={{
                      width: "100%",
                    }}
                    {...register("chef__email", {
                      required: "Chef Email is Mandatory",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Enter a valid Email",
                      },
                    })}
                  />
                  <p className={DeliveryClasses.delivery__error__helper}>
                    {errors?.chef__email?.message}
                  </p>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={DeliveryClasses.delivery__subtitle}>
                    Chef Password*
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Chef Password"
                    sx={{
                      width: "100%",
                    }}
                    {...register("chef__password", {
                      required: "Chef Password is Mandatory",
                    })}
                  />
                  <p className={DeliveryClasses.delivery__error__helper}>
                    {errors?.chef__password?.message}
                  </p>
                </Box>
              </Box>
            </Box>
          </ContainerForm>
          <Box
            sx={{
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
            className={DeliveryClasses.buttons__container}
          >
            <Button
              variant="outlined"
              size="large"
              className={ButtonClasses.button_cancel_form}
              onClick={() => navigate("/admin/chef/list_chefs")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={ButtonClasses.button_submit_form}
              type="submit"
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ marginRight: 10, color: "white" }}
                />
              )}
              Create Chef
            </Button>
          </Box>
        </form>
      </Container>
    </FormProvider>
  );
};

export default AddChef;
