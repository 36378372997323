function SwitchingCustomerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="10 10 350 350"
      preserveAspectRatio="xMinYMin meet"
      fill="none"
    >
      <g
        transform="translate(100.000000,290.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path d="M437 2129 c-21 -12 -47 -61 -47 -88 0 -18 -9 -19 -132 -23 -118 -3 -136 -5 -164 -25 -67 -48 -64 2 -64 -970 0 -995 -6 -926 80 -970 l44 -23 641 0 c693 0 748 3 906 56 137 45 258 131 335 241 53 73 93 178 94 241 l0 42 -274 0 -274 0 -6 192 c-4 106 -9 206 -12 221 -9 43 -67 96 -114 103 -22 4 -40 8 -40 9 0 2 16 76 35 166 23 108 31 167 25 173 -16 16 -26 -3 -43 -89 -34 -164 -39 -186 -43 -182 -3 2 -20 62 -39 132 -18 70 -36 130 -39 133 -4 3 -11 1 -17 -5 -8 -8 0 -50 25 -144 19 -73 34 -134 33 -136 -2 -1 -45 47 -97 108 -85 99 -115 123 -115 91 0 -6 49 -68 108 -137 l109 -125 -31 -29 c-35 -32 -51 -68 -51 -112 0 -17 36 -101 85 -199 l85 -170 -360 0 -360 0 0 -42 c1 -100 87 -261 186 -345 62 -54 142 -101 217 -129 l62 -23 -528 0 -529 -1 -29 29 -29 29 0 897 0 897 29 29 29 29 711 0 711 0 32 -29 33 -29 5 -203 c4 -149 8 -203 18 -207 9 -3 12 43 12 206 l0 210 -27 33 c-42 49 -77 59 -213 59 l-120 0 0 34 c0 23 -8 42 -26 60 l-27 26 -391 0 c-247 0 -397 -4 -409 -11z m801 -39 c12 -12 22 -32 22 -45 l0 -25 -415 0 -415 0 0 29 c0 60 5 61 415 61 360 0 372 -1 393 -20z m260 -1029 c31 -31 42 -77 42 -172 0 -45 -2 -48 -30 -54 -18 -4 -30 -13 -30 -22 0 -12 7 -14 30 -9 l30 6 0 -54 c0 -29 3 -74 6 -100 l7 -46 -36 0 c-26 0 -40 6 -51 22 -21 29 -20 35 5 41 27 7 15 27 -16 27 -21 0 -32 14 -71 92 -26 51 -42 94 -38 95 29 10 57 29 55 38 -2 10 -14 11 -60 4 -51 -8 -46 96 6 138 43 35 113 32 151 -6z m595 -488 c3 -5 2 -23 0 -40 l-5 -33 -663 0 -663 0 -5 33 c-2 17 -3 35 0 40 6 9 1330 9 1336 0z m-23 -116 c0 -7 -17 -41 -37 -76 -73 -123 -220 -228 -393 -283 -64 -19 -96 -23 -215 -23 -119 0 -151 4 -215 23 -173 55 -320 160 -392 283 -21 35 -38 69 -38 76 0 10 130 13 645 13 515 0 645 -3 645 -13z" />
        <path d="M890 411 c0 -11 25 -45 55 -77 41 -44 58 -55 66 -47 9 9 -3 28 -47 77 -33 36 -62 66 -66 66 -5 0 -8 -9 -8 -19z" />
        <path d="M1047 273 c-4 -3 -7 -10 -7 -14 0 -21 185 -94 255 -101 21 -2 31 2 33 13 2 11 -13 18 -63 28 -36 7 -97 28 -136 47 -80 38 -73 36 -82 27z" />
        <path d="M192 1812 c-9 -7 -12 -49 -10 -178 l3 -169 160 -3 c88 -1 170 0 183 3 22 5 22 8 22 168 0 113 -4 167 -12 175 -14 14 -325 17 -346 4z m328 -177 l0 -145 -155 0 -155 0 0 145 0 145 155 0 155 0 0 -145z" />
        <path d="M254 1737 c-3 -9 11 -30 38 -55 l43 -41 -42 -43 c-24 -24 -43 -49 -43 -56 0 -23 26 -12 65 28 21 22 44 40 49 40 6 0 30 -19 53 -42 35 -34 45 -40 55 -29 11 10 4 21 -33 56 l-46 43 46 46 c32 32 42 49 35 56 -8 8 -26 -2 -61 -34 -44 -40 -52 -44 -63 -31 -7 9 -30 29 -52 46 -30 25 -39 28 -44 16z" />
        <path d="M755 1719 c-16 -25 18 -29 201 -27 160 3 189 5 189 18 0 13 -29 15 -192 18 -132 2 -194 -1 -198 -9z" />
        <path d="M755 1570 c-11 -18 18 -21 204 -18 137 2 185 6 189 16 5 15 -383 18 -393 2z" />
        <path d="M1070 1491 c-14 -10 -43 -21 -64 -24 -156 -24 -259 -254 -212 -475 31 -146 123 -280 227 -330 61 -30 163 -31 179 -3 14 28 15 27 -33 79 -55 60 -79 100 -110 187 -74 209 -58 453 35 537 17 14 27 31 24 37 -10 15 -17 14 -46 -8z m-60 -95 c-79 -177 -17 -521 121 -670 21 -24 39 -46 39 -50 0 -17 -90 -4 -138 20 -127 63 -212 227 -212 409 0 140 49 249 137 305 66 42 76 39 53 -14z" />
        <path d="M880 1158 c0 -147 63 -359 96 -325 3 3 -6 41 -20 84 -26 84 -49 226 -44 271 2 19 -2 28 -14 30 -16 3 -18 -5 -18 -60z" />
        <path d="M1595 1445 c-14 -13 -25 -33 -25 -45 0 -29 35 -60 68 -60 57 0 83 69 42 110 -27 27 -56 25 -85 -5z m71 -30 c7 -18 -13 -45 -33 -45 -17 0 -27 24 -19 45 7 20 45 19 52 0z" />
        <path d="M1759 1287 c-21 -14 -46 -28 -58 -32 -33 -10 -61 -58 -61 -105 0 -35 6 -48 33 -74 l33 -31 -11 -154 -11 -154 31 -12 c61 -26 116 -38 125 -29 5 5 47 74 93 154 77 131 88 145 110 142 55 -7 117 52 117 110 0 38 -37 88 -70 95 -17 3 -30 12 -30 20 0 8 -15 30 -34 49 -32 32 -39 35 -93 34 -32 -1 -76 2 -98 5 -33 5 -47 2 -76 -18z m120 -32 c11 -10 18 -10 40 1 14 8 32 14 39 14 23 0 72 -57 72 -83 0 -20 4 -24 20 -20 11 3 31 -2 45 -11 30 -20 34 -69 9 -105 -14 -20 -23 -23 -59 -19 l-43 5 -88 -154 c-97 -168 -91 -163 -168 -133 -28 10 -28 12 -23 68 4 31 9 105 13 165 7 104 6 107 -14 107 -25 0 -52 35 -52 68 0 28 42 72 70 72 11 0 20 4 20 10 0 5 12 16 28 25 29 16 69 12 91 -10z" />
        <path d="M1845 998 c-23 -62 -55 -164 -55 -175 0 -7 6 -13 14 -13 15 0 80 176 70 191 -8 13 -24 11 -29 -3z" />
        <path d="M474 1105 c-49 -74 -92 -135 -96 -135 -3 0 -20 24 -37 54 -34 61 -48 74 -57 52 -8 -22 75 -158 95 -154 17 3 213 287 208 302 -9 28 -32 4 -113 -119z" />
        <path d="M317 1200 c-116 -36 -170 -174 -107 -274 91 -147 339 -99 358 70 6 60 -15 57 -34 -5 -52 -175 -314 -152 -314 28 0 83 54 141 139 151 32 3 46 10 46 20 0 18 -46 23 -88 10z" />
        <path d="M1110 1160 c-11 -11 -20 -31 -20 -45 0 -14 9 -34 20 -45 11 -11 31 -20 45 -20 14 0 34 9 45 20 11 11 20 31 20 45 0 14 -9 34 -20 45 -11 11 -31 20 -45 20 -14 0 -34 -9 -45 -20z m69 -24 c25 -30 -16 -67 -44 -40 -11 12 -12 20 -5 35 12 22 33 25 49 5z" />
        <path d="M2004 784 c-43 -42 -15 -104 46 -104 56 0 83 83 38 114 -32 23 -55 20 -84 -10z m72 -29 c7 -18 -13 -45 -33 -45 -17 0 -27 24 -19 45 7 20 45 19 52 0z" />
        <path d="M720 770 c-11 -11 -20 -31 -20 -45 0 -14 9 -34 20 -45 11 -11 31 -20 45 -20 14 0 34 9 45 20 11 11 20 31 20 45 0 14 -9 34 -20 45 -11 11 -31 20 -45 20 -14 0 -34 -9 -45 -20z m68 -22 c18 -18 15 -36 -7 -48 -15 -7 -23 -6 -35 6 -12 12 -13 20 -6 35 12 22 30 25 48 7z" />
        <path d="M187 584 c-13 -13 -8 -329 5 -342 18 -18 332 -17 347 2 8 9 11 65 9 177 l-3 164 -176 3 c-96 1 -178 -1 -182 -4z m333 -169 l0 -145 -155 0 -155 0 0 145 0 145 155 0 155 0 0 -145z" />
        <path d="M254 509 c-3 -6 13 -31 37 -55 l43 -44 -44 -45 c-31 -31 -41 -48 -34 -55 8 -8 26 2 60 33 l49 45 48 -45 c36 -34 52 -42 60 -34 8 8 0 23 -34 57 l-46 46 46 43 c37 35 44 46 33 56 -10 11 -20 5 -55 -29 -23 -23 -47 -42 -53 -42 -5 0 -28 18 -49 40 -41 42 -50 46 -61 29z" />
      </g>
    </svg>
  );
}

export default SwitchingCustomerIcon;
