import { useState, useMemo, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  useWatch,
} from "react-hook-form";
import clsx from "clsx";
import { useSnackbar } from "notistack";
//material
import {
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  TextField,
  Box,
  CircularProgress,
  Select,
  Autocomplete,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesLeads } from "../../../styles/Leads__styles";
import {
  FullContainer,
  useStylesCustomers,
} from "../../../styles/Customers__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesInput } from "../../../styles/Input__styles";
//components
import PhoneInput from "../../layout/PhoneInput";
import TabContentHeader from "../../TabContentHeader/TabContentHeader";
import Table from "../../layout/commun/Table";
import PickDriveFrom from "./PickDriveFrom";
//icons
import Delete from "../../../public/icons/delete";
import DaysIcons from "../../daysIcons/DaysIcons";
//types
import { addTypes } from "../Types";
import { ApolloError } from "@apollo/client";
//utils
import { addColumns, typeOptions, days } from "../Utils";
import { getMessageError } from "../../Utils";
import { React_APP_GOOGLE_API_KEY } from "../../../utils/config";
import { useCountryPrefix } from "../../../utils/Hooks/useCountryPrefix";
import DateAdapter from "@mui/lab/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
//queries & mutations
import {
  useGetCustomerAddressesQuery,
  useDeleteCustomerAddressMutation,
  GetCustomerAddressesDocument,
  useAddAddressToCustomerMutation,
  useUpdateAddressMutation,
  useGetActiveAreasQuery,
  AddressPayload,
  DaysOfTheWeek,
  useGetDriversQuery,
} from "../../../graphql/types";

const LATDOHA = 25.281337;
const LNGDOHA = 51.5132915;
const CustomerDeliveryAddress = () => {
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //state
  const [rightContainerMode, setRightContainerMode] = useState<null | string>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<AddressPayload | null>(null);
  const [shareableLink, setShareableLink] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [pastedLink, setPastedLink] = useState("");
  const [changeLinkButton, setChangeLinkButton] = useState(true);

  //styles
  const ContainersClasses = useStylesContainers();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();
  const CustomersClasses = useStylesCustomers();
  const classes = useStylesMenu();
  const LeadsClasses = useStylesLeads();
  const InputClasses = useStylesInput();

  // queries & mutations
  const { data, loading } = useGetCustomerAddressesQuery({
    variables: {
      customerId: params.id || "",
    },
    skip: !params.id,
  });

  const customerAddresses = useMemo(
    () => data?.getCustomerAddresses?.addresses || null,
    [data?.getCustomerAddresses?.addresses]
  );

  const dataArea = useGetActiveAreasQuery({ skip: !rightContainerMode });

  const customerAreas = useMemo(
    () => dataArea?.data?.getAreas,
    [dataArea?.data]
  );

  const [deleteAddress, dataDeleteAddress] = useDeleteCustomerAddressMutation(
    {}
  );

  const [addAddress, dataAddAddess] = useAddAddressToCustomerMutation({});

  const [updateAddress, dataAddAddessUpdate] = useUpdateAddressMutation({});

  const { data: driversData } = useGetDriversQuery();

  const countryPrefix = useCountryPrefix();

  //react-hooks
  const methods = useForm<addTypes>({
    defaultValues: {
      address__name: "",
      area__name: "",
      building__name: "",
      street__name: "",
      aprt__number: "",
      floor__number: "",
      delivery__number: "",
      delivery__prefix: countryPrefix?.callingCode,
      address__type: [],
      address__kind: [],
      days: [],
      zone: "",
      delivery__time: null,
      delivery__instructions: "",
      updatedFrom: null,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  const onMapClick = useCallback((e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
    setPastedLink(
      `https://maps.google.com/?q=${e.latLng.lat()},${e.latLng.lng()}`
    );
  }, []);

  //functions
  const handleClickMenu =
    (item: AddressPayload) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseForm = () => {
    setRightContainerMode(null);
    setPastedLink("");
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    let deliveryAddresses = selected?.daysOfTheWeek?.map((el) => el);
    setRightContainerMode("update");
    setValue("address__name", selected?.nick || "");
    setValue("address__type", selected?.type || "");
    setValue("aprt__number", selected?.nbrApt || "");
    setValue("area__name", String(selected?.area?._id) || "");
    setValue("building__name", selected?.build || "");
    setValue(
      "days",
      days.filter((el) =>
        deliveryAddresses?.some((day) => day === el.value.toLocaleUpperCase())
      )
    );
    setValue("delivery__number", selected?.deliveryPhone?.number || "");
    setValue(
      "delivery__prefix",
      selected?.deliveryPhone?.prefix?.replace("+", "") || ""
    );
    setValue("floor__number", selected?.floor || "");
    setValue("street__name", selected?.street || "");
    setValue("zone", selected?.zone || "");
    setValue("delivery__time", selected?.deliveryTime || null);
    setValue("delivery__instructions", selected?.deliveryInstructions || "");
    setLat(selected?.location?.coordinates?.[0] ?? 0);
    setLng(selected?.location?.coordinates?.[1] ?? 0);
    setChangeLinkButton(true);
    setPastedLink("");
  };

  const handleDeleteAddress = async () => {
    setOpen(false);
    if (selected) {
      try {
        await deleteAddress({
          variables: {
            addressId: String(selected._id),
          },
          refetchQueries: [
            {
              query: GetCustomerAddressesDocument,
              variables: {
                customerId: params.id || "",
              },
            },
          ],
        });
        enqueueSnackbar("Address successfully deleted", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setSelected(null);
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
        setSelected(null);
      }
    }
  };

  useEffect(() => {
    if (pastedLink) {
      const url = new URL(pastedLink);
      const params = new URLSearchParams(url.search);
      const newLat = parseFloat(params.get("q")!.split(",")[0]);
      const newLng = parseFloat(params.get("q")!.split(",")[1]);
      setLat(newLat);
      setLng(newLng);
      setShareableLink(pastedLink);
    } else {
      setShareableLink(`https://maps.google.com/?q=${lat},${lng}`);
    }
  }, [pastedLink, lat, lng]);

  const handleChangeLink = () => {
    setLat(0);
    setLng(0);
    setChangeLinkButton(false);
    setPastedLink("https://maps.google.com/?q=0,0");
  };

  const onSubmit: SubmitHandler<addTypes> = async (data: addTypes) => {
    try {
      const deliveryPhone = data.delivery__number.replace(/\s/g, "");
      if (rightContainerMode === "update") {
        await updateAddress({
          variables: {
            updateAdressInput: {
              id: selected?._id || "",
              nick: data?.address__name,
              build: data?.building__name,
              nbrApt: data?.aprt__number,
              street: data?.street__name,
              area: data?.area__name,
              floor: data?.floor__number,
              type: data?.address__type,
              daysOfTheWeek:
                customerAddresses && customerAddresses?.length > 0
                  ? (data?.days.map((day) =>
                      day.value.toLocaleUpperCase()
                    ) as DaysOfTheWeek[])
                  : [
                      "FRIDAY",
                      "MONDAY",
                      "SATURDAY",
                      "SUNDAY",
                      "THURSDAY",
                      "TUESDAY",
                      "WEDNESDAY",
                    ],
              deliveryPhone: {
                number: deliveryPhone,
                prefix: `+${data.delivery__prefix}`,
              },
              zone: data.zone,
              deliveryTime: data.delivery__time,
              location: {
                coordinates: [lat, lng],
              },
              deliveryInstructions: data.delivery__instructions,
              updatedFrom: data.updatedFrom,
            },
          },
          refetchQueries: [
            {
              query: GetCustomerAddressesDocument,
              variables: {
                customerId: params.id || "",
              },
            },
          ],
        });
      } else {
        await addAddress({
          variables: {
            addAddressInput: {
              customerId: params.id || "",
              address: {
                nick: data?.address__name,
                build: data?.building__name,
                nbrApt: data?.aprt__number,
                street: data?.street__name,
                area: data?.area__name,
                floor: data?.floor__number,
                type: data?.address__type,
                daysOfTheWeek:
                  customerAddresses && customerAddresses?.length > 0
                    ? (data?.days.map((day) =>
                        day.value.toLocaleUpperCase()
                      ) as DaysOfTheWeek[])
                    : [
                        "FRIDAY",
                        "MONDAY",
                        "SATURDAY",
                        "SUNDAY",
                        "THURSDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                      ],
                deliveryPhone: {
                  number: deliveryPhone,
                  prefix: `+${data.delivery__prefix}`,
                },
                zone: data.zone,
                deliveryTime: data.delivery__time,
                location: {
                  coordinates: [lat, lng],
                },
                deliveryInstructions: data.delivery__instructions,
              },
            },
          },
          refetchQueries: [
            {
              query: GetCustomerAddressesDocument,
              variables: {
                customerId: params.id || "",
              },
            },
          ],
        });
      }
      setRightContainerMode(null);
      enqueueSnackbar("Address successfully added", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const dataWatchDays = methods.watch("days");

  //render list
  const renderTableRows = () => {
    return (
      customerAddresses?.map((el: AddressPayload, index) => {
        const isLoading = selected?._id === el._id && dataDeleteAddress.loading;
        return {
          area: el?.area?.name,
          nick: el?.nick,
          phone:
            el?.deliveryPhone?.number && el?.deliveryPhone?.prefix
              ? `${el?.deliveryPhone?.prefix} ${el?.deliveryPhone?.number}`
              : "--",
          time: el?.deliveryTime ?? "--",
          driver: (
            <PickDriveFrom
              customerId={params.id}
              adress={customerAddresses[index]!}
              driver={customerAddresses[index].driver!}
              driverList={driversData?.getDrivers!}
            />
          ),
          fridayDriver: el?.daysOfTheWeek?.includes("FRIDAY")
            ? el?.fridayDriver?.name ?? ""
            : "---",
          days: (
            <Box display="flex" gap="8px">
              <DaysIcons days={el?.daysOfTheWeek ?? []} />
            </Box>
          ),
          actions: isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <IconButton onClick={handleClickMenu(el)}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <>
      <FullContainer>
        <TabContentHeader
          title={"Delivery Addresses"}
          buttonText={"Add Address"}
          buttonFnc={() => {
            setRightContainerMode("add");
            setValue("address__name", "");
            setValue("address__type", "");
            setValue("aprt__number", "");
            setValue("area__name", "");
            setValue("building__name", "");
            setValue("days", []);
            setValue("delivery__number", "");
            setValue("delivery__prefix", "");
            setValue("floor__number", "");
            setValue("street__name", "");
            setValue("zone", "");
            setValue("delivery__time", null);
            setLat(LATDOHA);
            setLng(LNGDOHA);
            setPastedLink("");
            setChangeLinkButton(true);
          }}
        />
        <TableContainer>
          <Table
            columns={addColumns}
            data={renderTableRows()}
            loading={loading}
            numberOfFakeRow={30}
            emptyMessage="No Addresses found !"
          />
        </TableContainer>

        <div className={classes.menu_container}>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorPosition={{
              left: anchorEl?.getBoundingClientRect().left || 0,
              top: anchorEl?.getBoundingClientRect().top || 0,
            }}
            anchorOrigin={{ horizontal: "left", vertical: "center" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorReference="anchorPosition"
          >
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Update</span>
              </div>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          </Menu>

          {/* DELETE ADDRESS */}
          <Dialog
            open={open}
            onClose={handleCloseModal}
            className={DialogClasses.dialog_container}
          >
            <DialogTitle className={DialogClasses.alert_dialog_title}>
              <Delete />
              <span className="alert_dialog_title_text">Delete Address ?</span>
            </DialogTitle>
            <DialogContent className={DialogClasses.alert_dialog_content}>
              <DialogContentText>
                Are you sure you want to delete this Address ?
              </DialogContentText>
            </DialogContent>
            <DialogActions className={DialogClasses.alert_dialog_actions}>
              <Button
                className={ButtonsClasses.GreyButton}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                className={ButtonsClasses.RedButton}
                onClick={handleDeleteAddress}
                color="primary"
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {rightContainerMode && (
          <div>
            <hr />
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TabContentHeader
                  title={
                    rightContainerMode === "update"
                      ? "Update Delivery Address"
                      : "Add Delivery Address"
                  }
                  buttonText={
                    rightContainerMode === "update" ? "Update" : "Add"
                  }
                  loading={dataAddAddess.loading || dataAddAddessUpdate.loading}
                  leftButtonText="Cancel"
                  cancelButtonFnc={handleCloseForm}
                />
                {rightContainerMode === "update" && (
                  <Box>
                    <p className={clsx(ContainersClasses.section__subtitle)}>
                      Please select a date from which these changes should apply
                    </p>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <Controller
                        name="updatedFrom"
                        control={methods.control}
                        render={({ field: { ref, ...rest } }) => (
                          <DesktopDatePicker
                            {...rest}
                            inputFormat="DD/MM/YYYY"
                            disablePast={true}
                            disableFuture={false}
                            renderInput={(params) => (
                              <TextField {...params} sx={{ width: "100%" }} />
                            )}
                          />
                        )}
                        rules={{
                          required: {
                            message: "Field required",
                            value: true,
                          },
                        }}
                      />
                    </LocalizationProvider>

                    <p
                      className={clsx(ContainersClasses.section__error__helper)}
                    >
                      {methods.formState.errors?.updatedFrom?.message}
                    </p>
                  </Box>
                )}
                <Box>
                  <Box display="flex" gap={2}>
                    <Box flex={1}>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Address Name
                      </p>
                      <TextField
                        placeholder="Address Name"
                        sx={{ width: "100%" }}
                        {...register("address__name", {})}
                      />
                    </Box>
                    <Box flex={1}>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Area Name*
                      </p>
                      <Controller
                        name="area__name"
                        rules={{
                          validate: (value) =>
                            value !== "" || "Select Area Name",
                          required: "Select Area Name",
                        }}
                        control={control}
                        render={({
                          field: { ref, ...rest },
                          fieldState: { error },
                        }) => {
                          return (
                            <>
                              <Select
                                {...rest}
                                sx={{ width: "100%" }}
                                className={LeadsClasses.select_container}
                                displayEmpty
                                renderValue={(selected) => {
                                  if (selected) {
                                    const selectedOption = customerAreas?.find(
                                      (option) => option._id === selected
                                    );
                                    return selectedOption?.name;
                                  }
                                  return "Select Area Name"; // Placeholder text
                                }}
                              >
                                {customerAreas?.map((option) => (
                                  <MenuItem
                                    key={option._id}
                                    onClick={() =>
                                      setValue("area__name", option?._id)
                                    }
                                    value={option?._id}
                                    className={
                                      dataWatch?.area__name === option?._id
                                        ? clsx(
                                            CustomersClasses.option_item,
                                            CustomersClasses.option_item_selected
                                          )
                                        : CustomersClasses.option_item
                                    }
                                  >
                                    {option?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <p className={InputClasses.input__error__helper}>
                                {error?.message}
                              </p>
                            </>
                          );
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" gap={2}>
                    <Box flex={1}>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Building / House Name
                      </p>
                      <TextField
                        placeholder="Building / House Name"
                        sx={{ width: "100%" }}
                        {...register("building__name", {})}
                      />
                    </Box>
                    <Box flex={1}>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Street Name
                      </p>
                      <TextField
                        placeholder="Street Name"
                        sx={{ width: "100%" }}
                        {...register("street__name", {})}
                      />
                    </Box>
                  </Box>

                  <Box display="flex" gap={2}>
                    <Box flex={1}>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Zone*
                      </p>
                      <TextField
                        placeholder="Zone"
                        sx={{ width: "100%" }}
                        {...register(`zone`, {
                          required: {
                            message: "This field is required",
                            value: true,
                          },
                        })}
                      />
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {errors?.zone?.message}
                      </p>
                    </Box>

                    <Box flex={1}>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Delivery Time*
                      </p>
                      <Controller
                        control={control}
                        name={`delivery__time`}
                        rules={{
                          required: {
                            message: "This field is required",
                            value: true,
                          },
                        }}
                        render={({
                          field: { onChange, value, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="delivery_time"
                                name="delivery_time_radio"
                                style={{
                                  flexDirection: "row",
                                  gap: "10px",
                                  height: "54px",
                                  alignItems: "center",
                                }}
                                value={value}
                                onChange={onChange}
                              >
                                <FormControlLabel
                                  value="MORNING"
                                  control={<Radio />}
                                  label="Morning"
                                />
                                <FormControlLabel
                                  value="EVENING"
                                  control={<Radio />}
                                  label="Evening"
                                />
                              </RadioGroup>
                              <p className={InputClasses.input__error__helper}>
                                {error?.message}
                              </p>
                            </FormControl>
                          </>
                        )}
                      />
                      <Box>
                        {changeLinkButton ? (
                          <>
                            <TextField
                              sx={{ width: "530px", marginBottom: "10px" }}
                              value={shareableLink}
                            />
                            <Button
                              className={ButtonsClasses.GreyButton}
                              color="primary"
                              sx={{ margin: "5px" }}
                              onClick={handleChangeLink}
                            >
                              Change Link
                            </Button>
                          </>
                        ) : (
                          <TextField
                            sx={{ width: "530px", marginBottom: "10px" }}
                            placeholder="Paste Google Maps link here"
                            value={pastedLink}
                            onChange={(e) => setPastedLink(e.target.value)}
                          />
                        )}
                        <Button
                          variant="contained"
                          sx={{ margin: "5px" }}
                          onClick={(event) => {
                            event.preventDefault();
                            navigator.clipboard.writeText(shareableLink);
                            enqueueSnackbar("Link copied", {
                              variant: "success",
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                            });
                            setTimeout(() => closeSnackbar(), 2000);
                          }}
                        >
                          Copy Link
                        </Button>
                      </Box>
                      <LoadScript
                        googleMapsApiKey={React_APP_GOOGLE_API_KEY || ""}
                      >
                        <GoogleMap
                          mapContainerStyle={{ width: "100%", height: "400px" }}
                          center={{ lat: lat, lng: lng }}
                          zoom={11}
                          onClick={onMapClick}
                        >
                          <Marker position={{ lat: lat, lng: lng }} />
                        </GoogleMap>
                      </LoadScript>
                    </Box>
                  </Box>
                  <Box display="flex" gap={2}>
                    <Box flex={1}>
                      <p className={clsx(ContainersClasses.section__subtitle)}>
                        Address Type*
                      </p>
                      <Controller
                        name="address__type"
                        control={control}
                        render={({
                          field: { ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <Select
                              {...rest}
                              sx={{ width: "100%" }}
                              className={LeadsClasses.select_container}
                              displayEmpty
                              renderValue={(selected) =>
                                selected
                                  ? typeOptions.find(
                                      (option) => option.menuValue === selected
                                    )?.menuText
                                  : "Select Address Type"
                              }
                            >
                              {typeOptions.map((option) => (
                                <MenuItem
                                  key={option.menuValue}
                                  onClick={() =>
                                    setValue("address__type", option.menuValue)
                                  }
                                  value={option.menuValue}
                                  className={
                                    dataWatch.address__type === option.menuValue
                                      ? clsx(
                                          LeadsClasses.option_item,
                                          LeadsClasses.option_item_selected
                                        )
                                      : LeadsClasses.option_item
                                  }
                                >
                                  {option.menuText}
                                </MenuItem>
                              ))}
                            </Select>
                            {error?.message && (
                              <p className={InputClasses.input__error__helper}>
                                {error.message}
                              </p>
                            )}
                          </>
                        )}
                        rules={{
                          required: "Select Address Type",
                        }}
                      />
                    </Box>

                    {customerAddresses && customerAddresses?.length > 0 && (
                      <Box flex={1}>
                        <p
                          className={clsx(ContainersClasses.section__subtitle)}
                        >
                          Days*
                        </p>
                        <Controller
                          name="days"
                          control={control}
                          rules={{ required: "Choose Tags" }}
                          render={({
                            field: { onChange: Change, value, ref, ...rest },
                            fieldState: { error },
                          }) => (
                            <>
                              <Autocomplete
                                {...rest}
                                multiple
                                id="recipe-outlined"
                                value={dataWatchDays}
                                options={days}
                                getOptionLabel={(option) => option.label}
                                renderTags={(value, getTagProps) => {
                                  return value.map(
                                    (
                                      option: { label: string; value: string },
                                      index: number
                                    ) => {
                                      return (
                                        <Chip
                                          variant="outlined"
                                          label={option.label}
                                          {...getTagProps({ index })}
                                        />
                                      );
                                    }
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputRef={ref}
                                    variant="outlined"
                                    sx={{ maxWidth: "100%" }}
                                    placeholder={
                                      value.length > 0 ? "" : "Select Days"
                                    }
                                  />
                                )}
                                onChange={(_, data) => Change(data)}
                              />
                              <p className={InputClasses.input__error__helper}>
                                {error?.message}
                              </p>
                            </>
                          )}
                        />
                      </Box>
                    )}
                  </Box>

                  {dataWatch?.address__type === "APARTMENT" ? (
                    <div>
                      <Box
                        className={clsx(ContainersClasses.section__container)}
                      >
                        <Box>
                          <p
                            className={clsx(
                              ContainersClasses.section__subtitle
                            )}
                          >
                            Apartment Number
                          </p>
                          <TextField
                            placeholder="Apartment Number"
                            sx={{ width: "100%" }}
                            {...register("aprt__number")}
                          />
                          <p
                            className={clsx(
                              ContainersClasses.section__error__helper
                            )}
                          >
                            {errors?.aprt__number?.message}
                          </p>
                        </Box>
                        <Box>
                          <p
                            className={clsx(
                              ContainersClasses.section__subtitle
                            )}
                          >
                            Floor Number
                          </p>
                          <TextField
                            placeholder="Floor Number"
                            sx={{ width: "100%" }}
                            {...register("floor__number")}
                          />
                          <p
                            className={clsx(
                              ContainersClasses.section__error__helper
                            )}
                          >
                            {errors?.floor__number?.message}
                          </p>
                        </Box>
                      </Box>
                    </div>
                  ) : dataWatch?.address__type === "HOUSE" ? (
                    <div>
                      <Box
                        className={clsx(ContainersClasses.section__container)}
                      >
                        <Box>
                          <p
                            className={clsx(
                              ContainersClasses.section__subtitle
                            )}
                          >
                            House Number
                          </p>
                          <TextField
                            placeholder="House Number"
                            sx={{ width: "100%" }}
                            {...register("aprt__number")}
                          />
                          <p
                            className={clsx(
                              ContainersClasses.section__error__helper
                            )}
                          >
                            {errors?.aprt__number?.message}
                          </p>
                        </Box>
                      </Box>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <Box>
                    <p className={clsx(ContainersClasses.section__subtitle)}>
                      Delivery Phone Number*
                    </p>
                    <PhoneInput
                      labelFormPrefix="delivery__prefix"
                      labelFormNumber="delivery__number"
                      placeholder="Phone Number"
                    />
                  </Box>
                  <Box width="100%">
                    <p className={clsx(ContainersClasses.section__subtitle)}>
                      Delivery Instructions
                    </p>
                    <TextField
                      id="demo-helper-text-misaligned"
                      placeholder="Delivery Instructions"
                      fullWidth
                      {...register("delivery__instructions")}
                    />
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </div>
        )}
      </FullContainer>
    </>
  );
};

export default CustomerDeliveryAddress;
