import { NumberOfDays, PaymentPeriod } from "../../../graphql/types";
import { FilterElementsProps } from "../../Utils";
import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "Si No",
    accessor: "ref",
  },
  {
    header: "Promo Name",
    accessor: "promoName",
  },
  {
    header: "Promo Code",
    accessor: "promoCode",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Times used",
    accessor: "usedBy",
  },
  {
    header: "Users",
    accessor: "users",
  },
  {
    header: "Starting Date",
    accessor: "startingDate",
  },
  {
    header: "End Date",
    accessor: "endDate",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

export const PromocodeUsersTableColumns: ColumnsProps = [
  {
    header: "No",
    accessor: "ref",
  },
  {
    header: "Customer Name",
    accessor: "name",
  },
  {
    header: "Date",
    accessor: "date",
  },
];

export const filterElements: Array<FilterElementsProps> = [
  {
    menuText: "Event",
    menuValue: "EVENT",
    selected: false,
  },
  {
    menuText: "Reduction",
    menuValue: "REDUCTION",
    selected: false,
  },
  {
    menuText: "Extend",
    menuValue: "EXTEND",
    selected: false,
  },
];
export const promoCodeTypes = ["EVENT", "REDUCTION", "EXTEND"];
export const numberOfDays: NumberOfDays[] = [
  "FIVE_DAYS",
  "SEVEN_DAYS",
  "SIX_DAYS",
];
export const duration: PaymentPeriod[] = ["WEEKLY", "MONTHLY", "THREE_MONTHS"];

export const getSubtitleText = (
  type: string,
  extendType: string,
  reductionType: string,
  currency: string
) => {
  if (type === "EVENT" || type === "EXTEND") {
    return `Free ${extendType}`;
  } else if (type === "REDUCTION") {
    return reductionType === "%" ? "Pourcentage %" : `Amount (${currency})`;
  } else {
    return "Mode";
  }
};
