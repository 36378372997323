import { Theme, lighten } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStylesSelect = makeStyles((theme: Theme) => {
  return {
    option_item: {
      padding: "8px 10px",
      width: "100%",
      borderRadius: "6px !important",
      "&:hover": {
        backgroundColor: `${lighten(
          theme.palette.secondary.main,
          0.7
        )} !important`,
      },
      "& .menu_title": {
        fontWeight: `${500} !important`,
      },
    },
    option_item_selected: {
      color: `${theme.palette.secondary.main} !important`,
      backgroundColor: `${lighten(
        theme.palette.secondary.main,
        0.85
      )} !important`,
    },
  };
});
