import { styled } from "@mui/material";

export const StyledID = styled("p")`
  color: ${({ theme }) => theme.palette.warning.main};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.subtitle1.fontWeight};
  margin: 0 0 6px 0;
`;

export const StyledValue = styled("p")`
  /* color: ${({ theme }) => theme.palette.warning.main}; */
  font-size: ${({ theme }) => theme.typography.h4.fontSize};
  font-weight: ${({ theme }) => theme.typography.subtitle1.fontWeight};
  margin: 0;
`;
