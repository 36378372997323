import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Delete";
//styles
import { useStylesContainers } from "../../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../../styles/Recipe__styles";
import { useStylesLeads } from "../../../../styles/Leads__styles";
//components
import PageAddHeader from "../../../pageAddHeader/pageAddHeader";
import DropZoneInput from "../../../layout/commun/DropZoneInput";
//types
import { CountryProps } from "../Types";
import { ApolloError } from "@apollo/client";
//utils
import { getMessageError } from "../../../Utils";
import { RewardsAction } from "../../../customer/Utils";
import { changeOptions, MenuProps, timeZoneList } from "../Utils";
//queries&mutations
import {
  RewardsActionEnum,
  useCreateCountryMutation,
} from "../../../../graphql/types";

const AddCountry = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const ContainersClasses = useStylesContainers();
  const RecipeClasses = useStylesRecipe();
  const LeadsClasses = useStylesLeads();
  // queries and mutations
  const [createCountry] = useCreateCountryMutation();

  //react-hook-form
  const methods = useForm<CountryProps>({
    defaultValues: {
      name: "",
      qurrency: "",
      prefix: "",
      code: "",
      flag: "",
      primaryColor: "",
      secondaryColor: "",
      onePointValue: "",
      rewardsAction: [],
      timeLimit: "0",
      type: null,
      timeZone: "",
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;
  const dataWatch = useWatch({
    control,
  });

  const onSubmit: SubmitHandler<CountryProps> = async (data) => {
    if (data.type === "AFTER_48_HOURS") {
      data.timeLimit = "";
      data.timeZone = "";
    }
    try {
      await createCountry({
        variables: {
          input: {
            name: {
              EN: data.name,
              AR: data.name,
            },
            currency: data.qurrency,
            code: data.code,
            flag: data.flag,
            prefix: "+" + data.prefix,
            isActive: true,
            primaryColor: data.primaryColor,
            secondaryColor: data.secondaryColor,
            onePointValue: parseFloat(data.onePointValue),
            rewardsAction: data.rewardsAction,
            applyChangesAfter: {
              timeLimit: parseFloat(data.timeLimit),
              type: data.type,
              timezone: data.timeZone,
            },
          },
        },
        onCompleted: () => {
          enqueueSnackbar("New Country added succuessfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/data/countries/list_countries");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleDeleteAction = (index: number) => {
    const updatedRewardsActions =
      dataWatch?.rewardsAction?.filter((_, i) => i !== index) || [];
    setValue(
      "rewardsAction",
      updatedRewardsActions.map((action) => ({
        RewardsActionType: action.RewardsActionType || "ADD_ON_PURCHASE",
        points: parseFloat(action.points?.toString() || "0"),
        validityInDays: parseFloat(action.validityInDays?.toString() || ""),
        isReusable: action.isReusable || false,
      }))
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title={"Create Country"}
          buttonText={"Create Country"}
          cancelButtonLink="/data/countries/list_countries"
          loading={false}
        />
        <Box display="grid" gridTemplateColumns="2fr 1fr" columnGap={10}>
          <Box width="100%">
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={4}>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Country Name
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Country Name"
                  fullWidth
                  {...register("name", {
                    required: "Country Name is Mandatory",
                  })}
                />
                {errors?.name?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.name?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Currency</p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Currency"
                  fullWidth
                  {...register("qurrency", {
                    required: "Currency is Mandatory",
                  })}
                />
                {errors?.qurrency?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.qurrency?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Country Code
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Country Code"
                  fullWidth
                  {...register("code", {
                    required: "Country Code is Mandatory",
                  })}
                />
                {errors?.code?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.code?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Country Prefix
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Country Prefix"
                  type="number"
                  fullWidth
                  {...register("prefix", {
                    required: "Country Prefix is Mandatory",
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">+ </InputAdornment>
                    ),
                  }}
                />
                {errors?.prefix?.message && (
                  <p className={RecipeClasses.recipe__error__helper}>
                    {errors?.prefix?.message}
                  </p>
                )}
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Primary Color
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Primary color"
                  type="color"
                  fullWidth
                  {...register("primaryColor")}
                />
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Secondary Color
                </p>
                <TextField
                  id="demo-helper-text-misaligned"
                  placeholder="Secondary color"
                  type="color"
                  fullWidth
                  {...register("secondaryColor")}
                />
              </Box>
            </Box>
            <Box marginTop={5}>
              <p className={ContainersClasses.section__title}>
                TimeLine Configuration
              </p>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                columnGap={4}
              >
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>Type</p>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { ref, onChange, ...rest } }) => (
                      <Select
                        {...rest}
                        sx={{ width: "100%" }}
                        className={LeadsClasses.select_container}
                        onChange={(event) => onChange(event.target.value)}
                      >
                        {changeOptions.map((option) => (
                          <MenuItem
                            key={option.menuValue}
                            value={option.menuValue}
                          >
                            {option.menuText}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors?.type?.message && (
                    <p className={RecipeClasses.recipe__error__helper}>
                      {errors?.type?.message}
                    </p>
                  )}
                </Box>
                {dataWatch?.type !== "AFTER_48_HOURS" && (
                  <>
                    <Box width="100%">
                      <p className={ContainersClasses.section__subtitle}>
                        time Limit
                      </p>
                      <Controller
                        name="timeLimit"
                        control={control}
                        render={({ field: { ref, onChange, ...rest } }) => (
                          <Select
                            {...rest}
                            sx={{ width: "100%" }}
                            id="timeLimit-select"
                            value={dataWatch?.timeLimit || ""}
                            className={LeadsClasses.select_container}
                            onChange={(event) =>
                              setValue("timeLimit", event.target.value)
                            }
                            MenuProps={MenuProps}
                          >
                            {[...Array(24)].map((_, index) => (
                              <MenuItem key={index} value={index.toString()}>
                                {index}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.timeLimit?.message && (
                        <p className={RecipeClasses.recipe__error__helper}>
                          {errors?.timeLimit?.message}
                        </p>
                      )}
                    </Box>
                    <Box width="100%">
                      <p className={ContainersClasses.section__subtitle}>
                        Time Zone
                      </p>
                      <Controller
                        name="timeZone"
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                        }) => (
                          <Autocomplete
                            value={dataWatch?.timeZone}
                            options={timeZoneList}
                            filterSelectedOptions
                            getOptionLabel={(option) => option || ""}
                            renderTags={() => null}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(_, data) => Change(data)}
                          />
                        )}
                      />
                      {errors?.timeZone?.message && (
                        <p className={RecipeClasses.recipe__error__helper}>
                          {errors?.timeZone?.message}
                        </p>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box marginTop={5}>
              <p className={ContainersClasses.section__title}>
                Rewards Configuration
              </p>
              <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={4}>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    One Point Value
                  </p>
                  <Controller
                    name="onePointValue"
                    control={control}
                    rules={{
                      validate: {
                        validNumber: (value) => /^\d*\.?\d*$/.test(value),
                      },
                    }}
                    render={({
                      field: { ref, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          id="demo-helper-text-misaligned"
                          placeholder=" One Point Value"
                          type="text"
                          fullWidth
                          {...register("onePointValue", {
                            required: "One Point Value is Mandatory",
                          })}
                        />
                        {errors?.onePointValue?.message && (
                          <p className={RecipeClasses.recipe__error__helper}>
                            {errors?.onePointValue?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </Box>

                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    rewards Action
                  </p>
                  <Autocomplete
                    multiple
                    sx={{ width: "100%" }}
                    options={RewardsAction}
                    getOptionLabel={(option) => option.menuText}
                    isOptionEqualToValue={(option, value) =>
                      option.menuValue === value.menuValue
                    }
                    value={
                      dataWatch?.rewardsAction?.map((action) => ({
                        menuText: action.RewardsActionType ?? "",
                        menuValue: action.RewardsActionType,
                      })) || []
                    }
                    filterSelectedOptions
                    disableClearable
                    renderTags={() => null}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(_, data) => {
                      data.map((item, index) => {
                        setValue(
                          `rewardsAction.${index}.RewardsActionType`,
                          (item.menuValue as RewardsActionEnum) || undefined
                        );
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box width="100%" marginTop={4}>
            <Box>
              <p className={ContainersClasses.section__subtitle}>
                Upload Country Flag
              </p>
              <Controller
                name="flag"
                control={control}
                render={({ field: { onChange, ref } }) => {
                  return (
                    <DropZoneInput
                      onChange={(e) => {
                        onChange(e.target.files[0]);
                      }}
                      label="Drop File"
                      inputRef={ref}
                      name="flag"
                      errors={errors}
                      multiple={false}
                      disabled={false}
                      accept="image/*"
                      message="Recommended resolution for image 400px*400px"
                    />
                  );
                }}
              />
            </Box>
          </Box>
        </Box>
        {dataWatch?.rewardsAction?.map((v, i) => (
          <Box
            key={i}
            display="grid"
            gridTemplateColumns="0fr 1fr 1fr 1fr 1fr"
            columnGap={4}
          >
            <Box marginTop={6}>
              <IconButton
                style={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "50%",
                }}
                size="small"
                onClick={() => handleDeleteAction(i)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>Type</p>
              <TextField
                id={`demo-helper-text-misaligned-${i}`}
                type="text"
                fullWidth
                value={v.RewardsActionType}
                {...register(`rewardsAction.${i}.RewardsActionType`)}
              />
            </Box>
            <Box width="100%">
              <p className={ContainersClasses.section__subtitle}>
                Validity in Days
              </p>
              <TextField
                id={`demo-helper-text-misaligned-${i}`}
                placeholder="Validity in Days"
                type="number"
                fullWidth
                {...register(`rewardsAction.${i}.validityInDays`, {
                  setValueAs: (value) => parseFloat(value),
                })}
              />
            </Box>
            {v.isAmountBased ? null : (
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>Points</p>
                <TextField
                  id={`demo-helper-text-misaligned-${i}`}
                  placeholder="Points"
                  type="number"
                  fullWidth
                  {...register(`rewardsAction.${i}.points`, {
                    setValueAs: (value) => parseFloat(value),
                  })}
                />
              </Box>
            )}
            <Box marginTop={6}>
              <Controller
                name={`rewardsAction.${i}.isAmountBased`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    checked={value ?? false}
                    onChange={(event) => {
                      onChange((event.target as HTMLInputElement).checked);
                    }}
                    control={<Checkbox color="secondary" />}
                    label="Amount Based"
                    labelPlacement="end"
                  />
                )}
              />
              <Controller
                name={`rewardsAction.${i}.isReusable`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    checked={value ?? false}
                    onChange={(event) => {
                      onChange((event.target as HTMLInputElement).checked);
                    }}
                    control={<Checkbox color="secondary" />}
                    label="reusable"
                    labelPlacement="end"
                  />
                )}
              />
            </Box>
          </Box>
        ))}

        <Box display="flex" marginTop={5}>
          <ErrorIcon />
          <Typography>
            Please logout and login again for changes to take effect
          </Typography>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddCountry;
