import { useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { HexColorPicker } from "react-colorful";
//material
import {
  Box,
  TextField,
  FormControlLabel,
  InputAdornment,
  Switch,
  Checkbox,
  IconButton,
  Button,
  Autocomplete,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//styles
import {
  useStylesContainers,
  ContainersContent,
} from "../../../styles/Containers__styles";
import { useStylesPricingInput } from "../../../styles/PricingInput__styles";
import AddCircle from "../../../public/icons/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//utils
import { formatPacksData } from "../Utils";
import { getMessageError } from "../../Utils";
import { duration } from "../../dataManagement/promoCode/Utils";
//components
import PageAddHeader from "../../pageAddHeader/pageAddHeader";
import DropZoneInput from "../../layout/commun/DropZoneInput";
import Pack from "../Pack";
//types
import { ProgramProps } from "../Types";
import { ApolloError } from "@apollo/client";
//queries&mutations
import {
  GetAllProgramsDocument,
  useCreatePackMutation,
  useDeletePackMutation,
  useEditPackMutation,
  useEditProgramV2Mutation,
  useFindProgramByIdV2Query,
} from "../../../graphql/types";
//context
import { useAuth } from "../../../contextAPI";

const UpdateProgram = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const idProgram = params.id || null;
  let auth = useAuth();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  //state
  const [color, setColor] = useState("#000");

  //styles
  const ContainersClasses = useStylesContainers();
  const PriceClasses = useStylesPricingInput();
  const ButtonClasses = useStylesButtons();

  // queries and mutations
  const { data: dataProgram } = useFindProgramByIdV2Query({
    variables: { input: idProgram || "" },
    skip: !idProgram,
    fetchPolicy: "no-cache",
  });

  const [editProgram, { loading: loadingProgram }] = useEditProgramV2Mutation();
  const [deletePack] = useDeletePackMutation();
  const [createPack] = useCreatePackMutation();
  const [editPack] = useEditPackMutation();

  //react-hook-form
  const methods = useForm<ProgramProps>({
    defaultValues: {
      hasFour: false,
      hasCustomPackage: false,
      canBeMixed: false,
      isLimited: false,
      canCrossSwitch: false,
      startDate: null,
      endDate: null,
      duration: [],
      program__name__english: "",
      program__desription__arabic: "",
      program__desription__english: "",
      program__name__arabic: "",
      subtitle__arabic: "",
      subtitle__english: "",
      photoUrl: "",
      breakfast: "",
      breakfastAR: "",
      lunch: "",
      lunchAR: "",
      dinner: "",
      dinnerAR: "",
      snack: "",
      snackAR: "",
      smallBreakfast: 0,
      mediumBreakfast: 0,
      largeBreakfast: 0,
      smallLunch: 0,
      mediumLunch: 0,
      largeLunch: 0,
      smallSnack: 0,
      mediumSnack: 0,
      largeSnack: 0,
      weeklyBreakfast: 0,
      weeklyLunch: 0,
      weeklySnack: 0,
      monthlyBreakfast: 0,
      monthlyLunch: 0,
      monthlySnack: 0,
      threeMonthsBreakfast: 0,
      threeMonthsLunch: 0,
      threeMonthsSnack: 0,
      packs: [],
      numberOfMeals: [],
      program__menu__prefix: "",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = methods;
  const dataWatch = useWatch({
    control,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "packs",
  });

  useEffect(() => {
    if (dataProgram?.findProgramByIdV2) {
      const {
        name,
        subTitle,
        subTitleColor,
        description,
        photoUrl,
        defaultPricing,
        isLimited,
        calories,
        isActif,
        hasFour,
        canBeMixed,
        offerInfo,
        displayMeals,
        packs,
        hasCustomPackage,
        canCrossSwitch,
        programMenuPrefix,
        paymentPeriods,
      } = dataProgram?.findProgramByIdV2 ?? null;
      setColor(subTitleColor || "#aabbcc");
      reset({
        hasCustomPackage: hasCustomPackage || false,
        canCrossSwitch: canCrossSwitch || false,

        program__name__english: name?.EN || "",
        program__name__arabic: name?.AR || "",
        program__desription__english: description?.EN || "",
        program__desription__arabic: description?.AR || "",
        program__menu__prefix: programMenuPrefix || "",

        subtitle__english: subTitle?.EN || "",
        subtitle__arabic: subTitle?.AR || "",
        photoUrl:
          photoUrl ||
          "https://dieture-dev-app.s3.amazonaws.com/images/59de269b-65fe-4729-9983-c7975886e18e.png",
        smallBreakfast: calories?.small.breakfast,
        smallLunch: calories?.small.lunchDinner,
        smallSnack: calories?.small.snack,

        mediumBreakfast: calories?.medium.breakfast,
        mediumLunch: calories?.medium.lunchDinner,
        mediumSnack: calories?.medium.snack,

        largeBreakfast: calories?.large.breakfast,
        largeLunch: calories?.large.lunchDinner,
        largeSnack: calories?.large.snack,

        monthlyBreakfast: defaultPricing?.monthly?.breakfast || 0,
        monthlyLunch: defaultPricing?.monthly?.lunchDinner || 0,
        monthlySnack: defaultPricing?.monthly?.snack || 0,

        weeklyBreakfast: defaultPricing?.weekly?.breakfast || 0,
        weeklyLunch: defaultPricing?.weekly?.lunchDinner || 0,
        weeklySnack: defaultPricing?.weekly?.snack || 0,

        threeMonthsBreakfast: defaultPricing?.threeMonths?.breakfast || 0,
        threeMonthsLunch: defaultPricing?.threeMonths?.lunchDinner || 0,
        threeMonthsSnack: defaultPricing?.threeMonths?.snack || 0,

        active: isActif || false,
        hasFour: hasFour || false,
        canBeMixed: canBeMixed || false,
        isLimited: isLimited || false,
        startDate: offerInfo?.startDate,
        endDate: offerInfo?.endDate,
        breakfast: displayMeals?.breakfast?.EN || "",
        lunch: displayMeals?.lunch?.EN || "",
        dinner: displayMeals?.dinner?.EN || "",
        snack: displayMeals?.snack?.EN || "",
        breakfastAR: displayMeals?.breakfast?.AR! || "",
        lunchAR: displayMeals?.lunch?.AR! || "",
        dinnerAR: displayMeals?.dinner?.AR! || "",
        snackAR: displayMeals?.snack?.AR! || "",

        packs: formatPacksData(packs || []),
        duration: paymentPeriods || [],
      });
    }
  }, [dataProgram?.findProgramByIdV2]);

  const onSubmit: SubmitHandler<ProgramProps> = async (data: ProgramProps) => {
    try {
      await editProgram({
        variables: {
          input: {
            id: idProgram || "",
            name: {
              AR: data.program__name__arabic,
              EN: data.program__name__english,
            },
            subTitle: {
              EN: data.subtitle__english,
              AR: data.subtitle__arabic,
            },
            subTitleColor: color,
            description: {
              AR: data.program__desription__arabic,
              EN: data.program__desription__english,
            },
            photoUrl:
              data?.photoUrl ||
              "https://dieture-dev-app.s3.amazonaws.com/images/59de269b-65fe-4729-9983-c7975886e18e.png",
            displayMeals: {
              breakfast: {
                EN: data.breakfast!,
                AR: data.breakfastAR,
              },
              lunch: {
                EN: data.lunch!,
                AR: data.lunchAR,
              },
              dinner: {
                EN: data.dinner!,
                AR: data.dinnerAR,
              },
              snack: {
                EN: data.snack!,
                AR: data.snackAR,
              },
            },
            calories: {
              small: {
                breakfast: +data.smallBreakfast,
                lunchDinner: +data.smallLunch,
                snack: +data.smallSnack,
              },
              medium: {
                breakfast: +data.mediumBreakfast,
                lunchDinner: +data.mediumLunch,
                snack: +data.mediumSnack,
              },
              large: {
                breakfast: +data.largeBreakfast,
                lunchDinner: +data.largeLunch,
                snack: +data.largeSnack,
              },
            },

            defaultPricing: {
              monthly: {
                breakfast: +data.monthlyBreakfast,
                lunchDinner: +data.monthlyLunch,
                snack: +data.monthlySnack,
              },
              weekly: {
                breakfast: +data.weeklyBreakfast,
                lunchDinner: +data.weeklyLunch,
                snack: +data.weeklySnack,
              },
              threeMonths: {
                breakfast: +data.threeMonthsBreakfast,
                lunchDinner: +data.threeMonthsLunch,
                snack: +data.threeMonthsSnack,
              },
            },
            hasCustomPackage: data.hasCustomPackage,
            isActif: data.active,
            hasFour: data.hasFour,
            canBeMixed: data.canBeMixed,
            isLimited: data.isLimited,
            canCrossSwitch: data.canCrossSwitch,
            offerInfo: {
              startDate: data.startDate,
              endDate: data.endDate,
            },
            programMenuPrefix: data.program__menu__prefix,
            paymentPeriods: data.duration,
          },
        },
        refetchQueries: [
          {
            query: GetAllProgramsDocument,
            variables: { input: { page: 1, documentsPerPage: 10 } },
          },
        ],
        onCompleted: () => {
          enqueueSnackbar("Program succuessfully updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      }).finally(() => {
        navigate("/food/programs/list_programs");
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleAdd = () => {
    append({});
  };

  const handleDelete = async (index: number, id: string) => {
    remove(index);
    try {
      await deletePack({
        variables: {
          input: { packId: id, programId: idProgram || "" },
        },
        refetchQueries: [
          {
            query: GetAllProgramsDocument,
            variables: { input: { page: 1, documentsPerPage: 10 } },
          },
        ],
        onCompleted: () => {
          enqueueSnackbar("Pack Deleted successfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleCreateUpdatePack = async (id: string, index: number) => {
    const packData = dataWatch?.packs?.[index];

    if (packData?._id) {
      try {
        await editPack({
          variables: {
            input: {
              packId: id,
              programId: idProgram || "",
              isActive: packData?.active || false,
              name: { EN: packData?.nameEN || "", AR: packData?.nameAR || "" },
              meals: packData?.meals,
              packImg: packData?.photoUrl,
              title: packData?.title || "",
              pricing: {
                monthly: {
                  breakfast: parseFloat(packData?.monthlyBreakfast || ""),
                  lunchDinner: parseFloat(packData?.monthlyLunchDinner || ""),
                  snack: parseFloat(packData?.monthlySnacks || ""),
                },
                weekly: {
                  breakfast: parseFloat(packData?.weeklyBreakfast || ""),
                  lunchDinner: parseFloat(packData?.weeklyLunchDinner || ""),
                  snack: parseFloat(packData?.weeklySnacks || ""),
                },
                threeMonths: {
                  breakfast: parseFloat(packData?.threeMonthsBreakfast || ""),
                  lunchDinner: parseFloat(
                    packData?.threeMonthsLunchDinner || ""
                  ),
                  snack: parseFloat(packData?.threeMonthsSnacks || ""),
                },
              },
            },
          },
          refetchQueries: [
            {
              query: GetAllProgramsDocument,
              variables: { input: { page: 1, documentsPerPage: 10 } },
            },
          ],
          onCompleted: () => {
            enqueueSnackbar("Pack Edited successfully", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    } else {
      try {
        await createPack({
          variables: {
            input: {
              programId: idProgram || "",
              isActive: packData?.active || false,
              name: { EN: packData?.nameEN || "", AR: packData?.nameAR || "" },
              meals: packData?.meals,
              packImg: packData?.photoUrl || "",
              title: packData?.title || "",
              pricing: {
                monthly: {
                  breakfast: parseFloat(packData?.monthlyBreakfast || ""),
                  lunchDinner: parseFloat(packData?.monthlyLunchDinner || ""),
                  snack: parseFloat(packData?.monthlySnacks || ""),
                },
                weekly: {
                  breakfast: parseFloat(packData?.weeklyBreakfast || ""),
                  lunchDinner: parseFloat(packData?.weeklyLunchDinner || ""),
                  snack: parseFloat(packData?.weeklySnacks || ""),
                },
                threeMonths: {
                  breakfast: parseFloat(packData?.threeMonthsBreakfast || ""),
                  lunchDinner: parseFloat(
                    packData?.threeMonthsLunchDinner || ""
                  ),
                  snack: parseFloat(packData?.threeMonthsSnacks || ""),
                },
              },
            },
          },
          refetchQueries: [
            {
              query: GetAllProgramsDocument,
              variables: { input: { page: 1, documentsPerPage: 10 } },
            },
          ],
          onCompleted: () => {
            enqueueSnackbar("Pack Created successfully", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
  };

  const onChangeColorHandler = (e: any) => {
    if (e.target.value.includes("#")) {
      setColor(e.target.value);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ position: "relative" }}>
        <PageAddHeader
          title="Update Program"
          buttonText="Update Program"
          cancelButtonLink="/food/programs/list_programs"
          loading={loadingProgram}
        />
        <ContainersContent>
          <Box
            display="grid"
            width="100%"
            gridTemplateColumns="2fr 1fr"
            gap={4}
          >
            <Box
              display="grid"
              width="100%"
              gridTemplateColumns="1fr 1fr"
              columnGap={2}
            >
              <Box className={clsx(ContainersClasses.section__container)}>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Program Name*<span>(English)</span>
                  </p>

                  <TextField
                    placeholder="Program Name (English)"
                    sx={{ width: "100%" }}
                    {...register("program__name__english", {
                      required: "Program Name is Mandatory",
                    })}
                  />
                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {errors?.program__name__english?.message}
                  </p>
                  <Box mt={7}>
                    <p className={ContainersClasses.section__subtitle}>
                      Program Menu prefix *
                    </p>
                    <TextField
                      placeholder="Program Menu prefix"
                      sx={{ width: "100%" }}
                      {...register("program__menu__prefix", {
                        required: "Program menu prefix is Mandatory",
                      })}
                    />
                    <p className={ContainersClasses.section__error__helper}>
                      {errors?.program__menu__prefix?.message}
                    </p>
                  </Box>
                </Box>
              </Box>
              <Box className={clsx(ContainersClasses.section__container)}>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Program Name<span>(Arabic)</span>
                  </p>
                  <TextField
                    placeholder="Program Name (Arabic)"
                    sx={{ width: "100%" }}
                    {...register("program__name__arabic", {})}
                  />
                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {errors?.program__name__arabic?.message}
                  </p>
                  <Box marginTop={6.9}>
                    <p className={ContainersClasses.section__subtitle}>
                      Available Durations
                    </p>
                    <Controller
                      name="duration"
                      rules={{
                        required: "Available Durations",
                      }}
                      control={control}
                      render={({
                        field: { onChange: Change, value, ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            ref={ref}
                            multiple
                            id="duration"
                            options={duration}
                            value={value}
                            filterSelectedOptions
                            onChange={(_, data) => Change(data)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  color="secondary"
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Duration" />
                            )}
                          />
                          <p
                            className={ContainersClasses.section__error__helper}
                          >
                            {error?.message}
                          </p>
                        </>
                      )}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className={ContainersClasses.section__container}>
                <Box>
                  <p className={ContainersClasses.section__subtitle}>
                    Subtitle<span>(English)</span>
                  </p>

                  <TextField
                    placeholder="Subtitle (English)"
                    sx={{ width: "100%" }}
                    {...register("subtitle__english")}
                  />
                  <p className={ContainersClasses.section__error__helper}>
                    {errors?.subtitle__english?.message}
                  </p>
                </Box>
              </Box>

              <Box className={ContainersClasses.section__container}>
                <Box>
                  <p className={ContainersClasses.section__subtitle}>
                    Subtitle<span>(Arabic)</span>
                  </p>
                  <TextField
                    placeholder="Subtitle (Arabic)"
                    sx={{ width: "100%" }}
                    {...register("subtitle__arabic")}
                  />
                  <p className={ContainersClasses.section__error__helper}>
                    {errors?.subtitle__arabic?.message}
                  </p>
                </Box>
              </Box>
              <Box className={clsx(ContainersClasses.section__container)}>
                <Box sx={{ maxWidth: "530px" }}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Description*<span>(English)</span>
                    <TextField
                      multiline
                      rows={3}
                      placeholder="Description (English)"
                      sx={{ width: "100%" }}
                      {...register("program__desription__english", {})}
                    />
                  </p>
                </Box>
              </Box>
              <Box className={clsx(ContainersClasses.section__container)}>
                <Box sx={{ maxWidth: "530px" }}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Description <span>(Arabic)</span>
                    <TextField
                      multiline
                      rows={3}
                      placeholder="Description (Arabic)"
                      sx={{ width: "100%" }}
                      {...register("program__desription__arabic", {})}
                    />
                  </p>
                </Box>
              </Box>
              <Box className={clsx(ContainersClasses.section__container)}>
                <Box sx={{ maxWidth: "100%" }}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Meals name displays as<span>(Optional)</span>
                  </p>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <TextField
                      style={{ marginTop: 10 }}
                      placeholder="BREAKFAST"
                      {...register("breakfast", {})}
                    />
                    <TextField
                      style={{ marginTop: 10 }}
                      placeholder="LUNCH"
                      {...register("lunch", {})}
                    />
                    <TextField
                      style={{ marginTop: 10 }}
                      placeholder="DINNER"
                      {...register("dinner", {})}
                    />
                    <TextField
                      style={{ marginTop: 10 }}
                      placeholder="SNACK"
                      {...register("snack", {})}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={clsx(ContainersClasses.section__container)}>
                <Box sx={{ maxWidth: "100%" }}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Meals name displays as (AR)
                  </p>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <TextField
                      style={{ marginTop: 10 }}
                      placeholder="وجبة الصباح "
                      {...register("breakfastAR", {})}
                    />
                    <TextField
                      style={{ marginTop: 10 }}
                      placeholder="وجبة الغداء "
                      {...register("lunchAR", {})}
                    />
                    <TextField
                      style={{ marginTop: 10 }}
                      placeholder="وجبة العشاء"
                      {...register("dinnerAR", {})}
                    />
                    <TextField
                      style={{ marginTop: 10 }}
                      placeholder="وجبة خفيفة"
                      {...register("snackAR", {})}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Upload Image
                </p>
                <Controller
                  name="photoUrl"
                  control={control}
                  render={({ field: { onChange, ref } }) => {
                    return (
                      <DropZoneInput
                        onChange={(e) => {
                          onChange(e.target.files[0]);
                        }}
                        label="Drop File"
                        inputRef={ref}
                        name="photoUrl"
                        errors={errors}
                        multiple={false}
                        disabled={false}
                        accept="image/*"
                        message="Recommended resolution for image 400px*400px"
                      />
                    );
                  }}
                />
              </Box>
              <Box marginLeft={2} marginTop={5}>
                <Controller
                  name={`active`}
                  control={control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <FormControlLabel
                      {...rest}
                      value={value}
                      control={<Switch checked={value} />}
                      label={value ? "Active" : "Inactive"}
                    />
                  )}
                />
              </Box>
              {dataWatch.program__name__english != "Ramadan" && (
                <Box marginLeft={2} marginTop={5}>
                  <Controller
                    name={`hasFour`}
                    control={control}
                    render={({ field: { ref, value, ...rest } }) => (
                      <FormControlLabel
                        {...rest}
                        value={value}
                        control={<Switch checked={value} />}
                        label={
                          value
                            ? "Four Months with price of 3 Active"
                            : "Four Months with price of 3 Unactive"
                        }
                      />
                    )}
                  />
                </Box>
              )}
              <Box marginLeft={2} marginTop={5}>
                <Controller
                  name={`canBeMixed`}
                  control={control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <FormControlLabel
                      {...rest}
                      value={value}
                      control={<Switch checked={value} />}
                      label={value ? "Can Be Mixed" : "Cannot be Mixed"}
                    />
                  )}
                />
              </Box>

              <Box marginTop={5}>
                <Controller
                  name="hasCustomPackage"
                  control={control}
                  render={({ field: { onChange, ref } }) => {
                    return (
                      <FormControlLabel
                        checked={dataWatch?.hasCustomPackage}
                        onChange={(_, value) => {
                          onChange(value);
                        }}
                        value="start"
                        control={<Checkbox color="secondary" />}
                        label="Has Custom Package"
                        labelPlacement="end"
                      />
                    );
                  }}
                />
              </Box>

              <Box marginTop={5}>
                <Controller
                  name="canCrossSwitch"
                  control={control}
                  render={({ field: { onChange, ref } }) => {
                    return (
                      <FormControlLabel
                        checked={dataWatch?.canCrossSwitch}
                        onChange={(_, value) => {
                          onChange(value);
                        }}
                        value="start"
                        control={<Checkbox color="secondary" />}
                        label="Can Cross Switch"
                        labelPlacement="end"
                      />
                    );
                  }}
                />
              </Box>

              <Box marginTop={5}>
                <Controller
                  name="isLimited"
                  control={control}
                  render={({ field: { onChange, ref } }) => {
                    return (
                      <FormControlLabel
                        checked={dataWatch?.isLimited}
                        onChange={(_, value) => {
                          onChange(value);
                        }}
                        value="start"
                        control={<Checkbox color="secondary" />}
                        label="Is Limited"
                        labelPlacement="end"
                      />
                    );
                  }}
                />
              </Box>

              {dataWatch.isLimited && (
                <>
                  <Box>
                    <p className={ContainersClasses.section__subtitle}>From</p>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DesktopDatePicker
                        value={dataWatch.startDate}
                        inputFormat="DD/MM/YYYY"
                        disablePast={false}
                        disableFuture={false}
                        onChange={(newValue) => {
                          setValue("startDate", newValue || new Date());
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ width: "100%" }}
                            required
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <p className={ContainersClasses.section__subtitle}>To</p>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DesktopDatePicker
                        value={dataWatch.endDate}
                        inputFormat="DD/MM/YYYY"
                        disablePast={false}
                        disableFuture={false}
                        onChange={(newValue) => {
                          setValue("endDate", newValue || new Date());
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </>
              )}

              <Box display="flex" flexDirection="column">
                <p className={ContainersClasses.section__subtitle}>
                  Choose Subtitle color
                </p>
                <HexColorPicker color={color} onChange={setColor} />
                <TextField
                  sx={{ width: 200 }}
                  value={color}
                  onChange={onChangeColorHandler}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              width="60%"
              marginTop={5}
              gap={2}
            >
              <Box></Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Small
                </p>
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Medium
                </p>
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Large
                </p>
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Breakfast
                </p>
              </Box>
              <Box>
                <Controller
                  name="smallBreakfast"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="mediumBreakfast"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="largeBreakfast"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Lunch / Dinner
                </p>
              </Box>
              <Box>
                <Controller
                  name="smallLunch"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="mediumLunch"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="largeLunch"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Snack
                </p>
              </Box>
              <Box>
                <Controller
                  name="smallSnack"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="mediumSnack"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="largeSnack"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...rest}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kcal</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              marginTop={10}
              width="80%"
              border={1}
              borderRadius={2}
              padding={5}
            >
              <p className={ContainersClasses.section__title}>
                Default Meals Pricing
              </p>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr 1fr"
                gap={2}
                style={{
                  marginBottom: "24px",
                  padding: "16px",
                }}
              >
                <Box></Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Breakfast
                  </p>
                </Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Lunch/Dinner
                  </p>
                </Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Snacks
                  </p>
                </Box>

                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Weekly
                  </p>
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`weeklyBreakfast`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`weeklyLunch`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`weeklySnack`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>

                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Monthly
                  </p>
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`monthlyBreakfast`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`monthlyLunch`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`monthlySnack`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Three Months
                  </p>
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`threeMonthsBreakfast`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`threeMonthsLunch`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    className={PriceClasses.priceInput}
                    size="small"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {auth.countryCurrency}
                        </InputAdornment>
                      ),
                    }}
                    {...register(`threeMonthsSnack`, {
                      required: "Price is Mandatory",
                    })}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {fields.map((field, index) => (
              <Box
                sx={{
                  marginTop: 5,
                  display: "flex",
                  alignItems: "flex-start",
                }}
                key={field.id}
              >
                <Pack
                  disabledPack={true}
                  mode="UPDATE"
                  control={control}
                  index={index}
                  onSave={() => handleCreateUpdatePack(field._id, index)}
                  image={field.photoUrl}
                />
                <IconButton onClick={() => handleDelete(index, field._id)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 5,
            }}
          >
            <Button
              startIcon={<AddCircle />}
              className={ButtonClasses.button_plus_add_new}
              onClick={handleAdd}
              sx={{
                alignSelf: "stretch",
              }}
            >
              Create new package
            </Button>
          </Box>
        </ContainersContent>
      </form>
    </FormProvider>
  );
};

export default UpdateProgram;
