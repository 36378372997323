// react
import React, { useMemo, useState } from "react";
// react router
import { useNavigate } from "react-router-dom";
//external
import { useSnackbar } from "notistack";
import clsx from "clsx";
import moment from "moment";
//MUI
import {
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";

//iconsS
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Delete from "../../../../public/icons/delete";
import ExcelIcon from "../../../../public/icons/ExcelIcon";
//components
import Status from "../../../Status/Status";
import Table from "../../../layout/commun/Table";
import PageHeader from "../../../pageHeader/PageHeader";
//Utils
import { areaColumns } from "../../Utils";
import { generateRapport } from "../../../reports/Utils";
import { useIsAuthorized } from "../../../../utils/Hooks/useIsAuthorized";
import { filterElements } from "../../../Utils";
//styles
import { useStylesButtons } from "../../../../styles/Buttons__styles";
import { useStylesMenu } from "../../../../styles/Menu__styles";
import { useStylesDialog } from "../../../../styles/Dialog__styles";
import { TableContainer } from "../../../../styles/Table__styles";
//queries&mutations
import {
  useGetDeliveryAreaQuery,
  useDeleteDeliveryAreaMutation,
  GetDeliveryAreaDocument,
  SortBy,
} from "../../../../graphql/types";
//context
import { useAuth } from "../../../../contextAPI";

const ListAreas = () => {
  let auth = useAuth();
  const navigate = useNavigate();
  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();
  const ButtonClasses = useStylesButtons();

  //states
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [idArea, setIdArea] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortBy | null>(null);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);

  //querise&mutations
  const listAreas = useGetDeliveryAreaQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        name: search,
        sort: sortBy,
      },
    },
    fetchPolicy: "network-only",
  });

  const areasList = useMemo(
    () => listAreas.data?.getDeliveryArea.data || [],
    [listAreas.data]
  );

  const count = useMemo(
    () => listAreas.data?.getDeliveryArea.count || 0,
    [listAreas.data]
  );

  const [deleteArea, { loading }] = useDeleteDeliveryAreaMutation();

  //functions
  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setIdArea(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (idArea) {
      navigate(`/admin/delivery_area/update_area/${idArea}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteArea = () => {
    setOpen(false);
    idArea &&
      deleteArea({
        variables: {
          input: idArea,
        },
        refetchQueries: [GetDeliveryAreaDocument],
        onCompleted: () => {
          enqueueSnackbar("Area succuessfully deleted", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
  };

  const onGenerateHandler = async () => {
    setLoadingReport(true);
    try {
      await generateRapport(auth.token || "", "AREA_REPORT", new Date());
    } catch (error) {
      console.log("error");
    } finally {
      setLoadingReport(false);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  //render
  const renderTableRows = () => {
    return (
      areasList?.map((el) => {
        const isLoading = idArea === el?._id && loading;
        return {
          name: el?.area?.name,
          driver: el?.driver?.name,
          morning: moment(el?.morningTime?.from, "HH:mm").isValid()
            ? `From: ${moment(el?.morningTime?.from, "HH:mm").format(
                "hh:mm A"
              )} To : ${moment(el?.morningTime?.to, "HH:mm").format("hh:mm A")}`
            : "----",
          evening: moment(el?.eveningTime?.from, "HH:mm").isValid()
            ? `From: ${moment(el?.eveningTime?.from, "HH:mm").format(
                "hh:mm A"
              )} To : ${moment(el?.eveningTime?.to, "HH:mm").format("hh:mm A")}`
            : "----",
          status: el?.isActive ? (
            <Status status="ACTIVE" statusText="Active" />
          ) : (
            <Status status="INACTIVE" statusText="Inactive" />
          ),
          fridayDriver: el.fridayDriver?.name,
          fridayMorning: moment(el?.fridayMorningTime?.from, "HH:mm").isValid()
            ? `From: ${moment(el?.fridayMorningTime?.from, "HH:mm").format(
                "hh:mm A"
              )} To : ${moment(el?.fridayMorningTime?.to, "HH:mm").format(
                "hh:mm A"
              )}`
            : "----",
          fridayEvening: moment(el?.fridayEveningTime?.from, "HH:mm").isValid()
            ? `From: ${moment(el?.fridayEveningTime?.from, "HH:mm").format(
                "hh:mm A"
              )} To : ${moment(el?.fridayEveningTime?.to, "HH:mm").format(
                "hh:mm A"
              )}`
            : "----",
          actions: isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <IconButton onClick={(e) => handleClickMenu(e, el?._id)}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <>
      {useIsAuthorized("ADMIN_DELIVERYAREA_CREATE") ? (
        <PageHeader
          buttonLink="/admin/delivery_area/add_area"
          buttonText="Add Area"
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      ) : (
        <PageHeader
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      )}

      <Box display="flex" alignItems="center" mb={1}>
        <Button
          onClick={onGenerateHandler}
          className={clsx(ButtonClasses.button_open_list_page)}
          startIcon={<ExcelIcon />}
          disabled={loadingReport}
        >
          Export Excel
          {loadingReport ? (
            <CircularProgress
              size={14}
              color="inherit"
              style={{ marginLeft: 5 }}
            />
          ) : (
            ""
          )}
        </Button>
      </Box>
      <TableContainer>
        <Table
          columns={areaColumns}
          loading={listAreas?.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Areas found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("ADMIN_DELIVERYAREA_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <div>
                <span className="menu_title">Update</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("ADMIN_DELIVERYAREA_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Area ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Area ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={handleDeleteArea}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ListAreas;
