import { lighten, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStylesInput = makeStyles((theme: Theme) => {
  return {
    inputs__container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start !important",
      gap: "15px",
      position: "relative",
      justifyContent : "space-between"
    },
    input__container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start !important",
      position: "relative",
      justifyContent: "space-between",
    },
    input_supplier_container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start !important",
      position: "relative",
      justifyContent: "space-between",
      borderRadius: "8px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      padding: "0 8px",
    },
    input__subtitle: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      margin: "5px 0",
      "& > span": {
        color: `${theme.palette.success.contrastText} !important`,
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.subtitle2.fontWeight,
      },
    },
    quantity__display: {
      width: "90px",
      minHeight: "30px",
      padding: "9px 45px",
      // margin: "0 15px",
      border: "0.5px solid #F3F4FA",
      textAlign: "center",
      backgroundColor: lighten(theme.palette.secondary.main, 0.8),
      color: `${theme.palette.secondary.main} !important`,
      borderRadius: theme.shape.borderRadius,
    },
    input__error__helper: {
      color: `${theme.palette.error.main}`,
      fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    },
    input__add_container: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "10px",
      width: "fit-content",
    },
    input__add__subtitle: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle1.fontWeight,
      textTransform: "capitalize",
      cursor: "pointer",
    },
    input__clear__icon: {
      top: "-10px !important",
      right: "-17px !important",
      backgroundColor: "#dcdcdc !important",
      zIndex: "1000",
      "& >svg": {
        height: "16px",
        width: "16px",
      },
    },
    input__clear__x: {
      top: "-8px !important",
      right: "-34px !important",
      backgroundColor: "#dcdcdc !important",
      zIndex: "1000",
      "& >svg": {
        height: "16px",
        width: "16px",
      },
    },
    input__clear__weight: {
      top: "0px !important",
      right: "-32px !important",
      backgroundColor: "#dcdcdc !important",
      "& >svg": {
        height: "16px",
        width: "16px",
      },
    },
    input__clear__price: {
      top: "-20px !important",
      right: "-35px !important",
      backgroundColor: "#dcdcdc !important",
      "& >svg": {
        height: "16px",
        width: "16px",
      },
    },
    input__clear__address: {
      top: "-16px !important",
      right: "-17px !important",
      backgroundColor: "#dcdcdc !important",
      "& >svg": {
        height: "16px",
        width: "16px",
      },
    },
    input__unit: {
      color: theme.palette.primary.main,
      fontSize: 14,
    },
  };
});
