import React from "react";

type Props = {
  centralColor: string;
  progressColor: string;
  percentage: number;
};

const ProgressCircle: React.FC<Props> = ({
  centralColor,
  progressColor,
  percentage,
}) => {
  const r = 52.5;
  const circ = 2 * Math.PI * r; //this is the basic formula used to calculate "circumference" of the circle (the length of the borderLine)
  const strokePct = ((100 - percentage) * circ) / 100;
  return (
    <svg width="150" height="150">
      <g transform="rotate(-90 75 75)">
        <circle
          r={r}
          cx="75"
          cy="75"
          fill={centralColor}
          stroke={strokePct !== circ ? centralColor : ""}
          stroke-width="0.5rem"
          stroke-dasharray={circ}
          stroke-dashoffset="0"
        ></circle>
        <circle
          r="52.5"
          cx="75"
          cy="75"
          fill={centralColor}
          stroke={strokePct !== circ ? progressColor : ""}
          stroke-width="0.5rem"
          stroke-dasharray={circ}
          stroke-dashoffset={strokePct}
          stroke-linecap="round"
        ></circle>
      </g>
      <text
        x="50%"
        y="50%"
        dominant-baseline="central"
        text-anchor="middle"
        font-family="Poppins"
        font-size="20px"
      >
        {percentage}%
      </text>
    </svg>
  );
};

export default ProgressCircle;
