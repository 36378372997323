import { useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
//material
import { Box, Chip, Button } from "@mui/material";
//styles
import {
  useStylesIngredient,
  Container,
} from "../../../styles/Ingredient__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesForms } from "../../../styles/Forms__styles";
//querys
import { useGetAllergenByIdQuery } from "../../../graphql/types";
//utils
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";

const ViewAllergenCategory = () => {
  const params = useParams();
  const navigate = useNavigate();
  //styles
  const IngredientClasses = useStylesIngredient();
  const ButtonClasses = useStylesButtons();
  const FormsClasses = useStylesForms();

  //queries
  const dataAllergen = useGetAllergenByIdQuery({
    variables: { allergenId: params.id || "" },
    skip: !params.id,
    fetchPolicy: "network-only",
  });

  const allergen = useMemo(
    () => dataAllergen.data?.getAllergenById || null,
    [dataAllergen.data]
  );

  //functions
  const onPressEdit = () => {
    navigate(`/food/allergens/update_allergen/${params.id}`);
  };

  //render
  const renderListIngredients = () => {
    return allergen?.ingredients?.map((item) => {
      return (
        <Chip
          key={item._id}
          label={item?.name?.EN}
          variant="outlined"
          className={FormsClasses.chips__tags}
        />
      );
    });
  };

  return (
    <div>
      <Container>
        <Box className={IngredientClasses.ingredient__view__titles}>
          <p className={IngredientClasses.ingredient__name__title}>
            {allergen?.name?.EN}
          </p>
          <span className={IngredientClasses.ingredient__id}>
            #{allergen?.ref}
          </span>
        </Box>
        {allergen?.name?.AR && (
          <Box className={IngredientClasses.ingredient__view__titles}>
            <p className={IngredientClasses.ingredient__name__title}>
              {allergen?.name.AR}
            </p>
          </Box>
        )}
        <Box>
          <p className={IngredientClasses.ingredient__title}>Ingredients</p>
          <Box className={IngredientClasses.ingredient__tags__container}>
            {renderListIngredients()}
          </Box>
        </Box>

        {useIsAuthorized("FOOD_ALLERGENS_UPDATE") && (
          <Box className={IngredientClasses.button_container}>
            <Button
              variant="contained"
              className={ButtonClasses.button_submit_form}
              type="submit"
              onClick={onPressEdit}
            >
              Edit Allergen Category
            </Button>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default ViewAllergenCategory;
