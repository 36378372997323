import { useState, useEffect } from "react";
//material ui
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
//components
import UsersList from "./UsersList";
import RolesList from "./RolesList";
//context
import { useAuth } from "../../../contextAPI";

const UsersAndRolesList = () => {
  let auth = useAuth();

  //state
  const [NavigationValue, setNavigationValue] = useState<string | null>(
    "USERS"
  );

  //functions
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    choice: string | null
  ) => {
    setNavigationValue(choice);
  };

  //set the default Tab to users once the page is loaded
  useEffect(() => {
    setNavigationValue("USERS");
  }, []);

  return (
    <>
      <ToggleButtonGroup
        value={NavigationValue}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        color="secondary"
        size="small"
      >
        {auth.permissions.includes("MANAGEMENT_ROLES_LIST_ADMIN") && (
          <ToggleButton value="USERS" aria-label="left aligned">
            Users
          </ToggleButton>
        )}
        {auth.permissions.includes("MANAGEMENT_ROLES_LIST") && (
          <ToggleButton value="ROLES" aria-label="right aligned">
            Roles
          </ToggleButton>
        )}
      </ToggleButtonGroup>
      <Box>{NavigationValue === "USERS" ? <UsersList /> : <RolesList />}</Box>
    </>
  );
};

export default UsersAndRolesList;
