export const formatIngredientsForAPI = (
  ingredients: any,
  size: "SMALL" | "MEDIUM" | "LARGE"
) => {
  let result;
  if (size === "SMALL") {
    const small = ingredients.map((elem: any) => {
      return {
        ingredient: elem.ingredient._id,
        quantity: +elem.quantitySmall,
        cookedWeight: +elem.cookedWeightSmall,
      };
    });
    result = small;
  } else if (size === "MEDIUM") {
    const medium = ingredients.map((elem: any) => {
      return {
        ingredient: elem.ingredient._id,
        quantity: +elem.quantityMedium,
        cookedWeight: +elem.cookedWeightMedium,
      };
    });
    result = medium;
  } else {
    const large = ingredients.map((elem: any) => {
      return {
        ingredient: elem.ingredient._id,
        quantity: +elem.quantityLarge,
        cookedWeight: +elem.cookedWeightLarge,
      };
    });
    result = large;
  }
  return result;
};
