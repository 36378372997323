import { useEffect, useState } from "react";
import moment from "moment";
//material ui
import Box from "@mui/material/Box";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { IconButton, Tooltip } from "@mui/material";
//queries & mutations
import { LunchMeal } from "../../../graphql/types";
//styles
import { useStylesProgramMenu } from "../../../styles/ProgramMenu_styles";
//components
import MenuItem from "./MenuItem";
//types
import { programMenuProps } from "../Types";

const CalendarMenu: React.FC<programMenuProps> = ({
  getCategory,
  pushDish,
  deleteDish,
  data,
  dishLoading,
  selectedDate,
  display,
  dishList,
  swapDish,
  menuId,
  linkCopied,
  handleCopyClick,
  handlePasteMenuInADay,
}) => {
  //styles
  const MenuClasses = useStylesProgramMenu();

  //state
  const [dishName, setDishName] = useState("");
  const [lunchDishName, setLunchDishName] = useState("");
  const [dinnerDishName, setDinnerDishName] = useState("");
  const [snackDishName, setSnackDishName] = useState("");
  const [category, setCategory] = useState<LunchMeal>("BREAKFAST");

  useEffect(() => {
    if (dishName) {
      getCategory(category, data.date, dishName);
      setLunchDishName("");
      setDinnerDishName("");
      setSnackDishName("");
    }
  }, [dishName]);

  useEffect(() => {
    if (lunchDishName) {
      getCategory(category, data.date, lunchDishName);
      setDishName("");
      setDinnerDishName("");
      setSnackDishName("");
    }
  }, [lunchDishName]);

  useEffect(() => {
    if (dinnerDishName) {
      getCategory(category, data.date, dinnerDishName);
      setDishName("");
      setLunchDishName("");
      setSnackDishName("");
    }
  }, [dinnerDishName]);

  useEffect(() => {
    if (snackDishName) {
      getCategory(category, data.date, snackDishName);
      setDishName("");
      setLunchDishName("");
      setDinnerDishName("");
    }
  }, [snackDishName]);

  return (
    <Box sx={{ display: "flex", marginBottom: "9px" }}>
      <Box
        className={MenuClasses.calendar_containerV2}
        sx={{
          background: "#D9D9D9",
        }}
      >
        <Box className={MenuClasses.date_container_odd}>
          <Tooltip
            title={
              linkCopied ? "copied" : "Click here to copy the menu of this day"
            }
          >
            <IconButton onClick={() => handleCopyClick(data)} aria-label="Copy">
              <FileCopyIcon style={{ color: "#000", width: "15px" }} />
            </IconButton>
          </Tooltip>
          <Box>{moment(data.date).format("ddd")}</Box>
          <Box>{moment(data.date).format("DD MMM")}</Box>
          <Box>{moment(data.date).format("YYYY")}</Box>
          <IconButton
            onClick={() => handlePasteMenuInADay(data.date)}
            aria-label="Copy"
          >
            <ContentPasteGoIcon style={{ color: "#000", width: "15px" }} />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ flex: 1, marginLeft: "3px" }}>
        <MenuItem
          data={data}
          deleteDish={deleteDish}
          dishList={dishList}
          dishLoading={dishLoading}
          display={display}
          getCategory={getCategory}
          selectedDate={selectedDate}
          pushDish={pushDish}
          swapDish={swapDish}
          type="BREAKFAST"
          category={category}
          setCategory={setCategory}
          dishName={dishName}
          setDishName={setDishName}
          lunchDishName={lunchDishName}
          menuId={menuId}
        />
        <MenuItem
          data={data}
          deleteDish={deleteDish}
          dishList={dishList}
          dishLoading={dishLoading}
          display={display}
          getCategory={getCategory}
          selectedDate={selectedDate}
          pushDish={pushDish}
          swapDish={swapDish}
          type="LUNCH"
          category={category}
          setCategory={setCategory}
          dishName={lunchDishName}
          setDishName={setLunchDishName}
          lunchDishName={lunchDishName}
          menuId={menuId}
        />
        <MenuItem
          data={data}
          deleteDish={deleteDish}
          dishList={dishList}
          dishLoading={dishLoading}
          display={display}
          getCategory={getCategory}
          selectedDate={selectedDate}
          pushDish={pushDish}
          swapDish={swapDish}
          type="DINNER"
          category={category}
          setCategory={setCategory}
          dishName={dinnerDishName}
          setDishName={setDinnerDishName}
          lunchDishName={dinnerDishName}
          menuId={menuId}
        />
        <MenuItem
          data={data}
          deleteDish={deleteDish}
          dishList={dishList}
          dishLoading={dishLoading}
          display={display}
          getCategory={getCategory}
          selectedDate={selectedDate}
          pushDish={pushDish}
          swapDish={swapDish}
          type="SNACKS"
          category={category}
          setCategory={setCategory}
          dishName={snackDishName}
          setDishName={setSnackDishName}
          lunchDishName={lunchDishName}
          menuId={menuId}
        />
      </Box>
    </Box>
  );
};
export default CalendarMenu;
