import { useMemo, forwardRef } from "react";
import styled from "styled-components";
import moment from "moment";
import QRCode from "react-qr-code";
//material ui
import { Box } from "@mui/material";
//types
import { packagingForBagsReportProps } from "../Types";
//logo
import DietureLogoWhite from "../../../public/images/DietureLogoWhite.png";

const PackingForBagsReportPrint = forwardRef<
  HTMLDivElement,
  packagingForBagsReportProps
>(({ generateLabel, startDate }, ref) => {
  const packagingForBags = useMemo(
    () => generateLabel?.packingBagsReport,
    [generateLabel?.packingBagsReport]
  );

  const Table = styled.table`
    width: 302px;
    justfy-content: "space-between";
  `;
  const Td = styled.td`
    border: 1px solid black;
  `;
  return (
    <div
      ref={ref}
      style={{
        width: 302,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingLeft: 20,
      }}
    >
      {packagingForBags?.map((el) => (
        <Box key={el._id}>
          <Box
            display="flex"
            justifyContent="center"
            marginTop={2.4}
            style={{
              width: 302,
            }}
          >
            <img src={DietureLogoWhite} height="35px" alt="DietureLogoWhite" />
          </Box>
          {/* Ticket header */}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="baseline"
            paddingLeft={0.5}
            paddingRight={1.5}
            paddingTop={1.2}
          >
            <Box flexDirection="column" justifyContent="center">
              <Box fontSize={10}>Customer Name</Box>
              <Box fontSize={14}>
                {el?.customer?.fname + " " + el?.customer?.lname || ""}
              </Box>
            </Box>
            <Box fontSize={12}>
              Customer ID no: <strong>{el?.customer?.ref || ""}</strong>
            </Box>
          </Box>
          {/*  Meal Program */}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            marginRight="15px"
          >
            <Box paddingLeft={0.5}>
              <Box fontSize={14} color="#000">
                {el?.plan?.program?.name?.EN || ""} -{" "}
                {el?.plan?.meals?.length || 0} meals
              </Box>
              <Box fontSize={8}>
                Delivery : {moment(startDate).format("DD/MM/YYYY-dddd")}
              </Box>
            </Box>
            <Box>
              <QRCode
                level="L"
                bgColor="white"
                fgColor="black"
                size={120}
                value={el?._id + ""}
              />
            </Box>
          </Box>
          {/* Meals List */}
          <Box paddingLeft={0.5} paddingRight={1.5} paddingTop={1.2}>
            {el?.boxes?.map((elem) => {
              return (
                <Box
                  key={elem?.meal + Math.random().toString()}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{
                    borderBottomColor: "#C4C4C4",
                    borderBottomWidth: 0.1,
                    borderBottom: "solid",
                    borderWidth: "thin",
                  }}
                >
                  <Box width="80%">
                    <Box fontSize={12}>{elem?.meal}</Box>
                    <Box fontSize={12}>{elem?.dish?.name?.EN}</Box>
                  </Box>
                  <Box fontSize={10}>{elem.code}</Box>

                  <Box fontSize={12}>
                    {elem?.size?.charAt(0) ? elem?.size?.charAt(0) : "S"}
                  </Box>
                </Box>
              );
            })}
          </Box>
          {/* Addons List */}
          <Box paddingLeft={0.5} paddingRight={1.5} paddingTop={1.2}>
            {el?.addons && el?.addons.length > 0 && (
              <Box fontSize={12}>Add on’s</Box>
            )}
            {el?.addons?.map((elem, index) => {
              return (
                <Box
                  key={elem.dish?.name?.EN}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{
                    borderBottomColor: "#C4C4C4",
                    borderBottomWidth: 0.5,
                    borderBottom: "solid",
                  }}
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box fontSize={12} lineHeight={3} marginRight={1.2}>
                      {index + 1}
                    </Box>
                    <Box fontSize={10} lineHeight={3}>
                      {elem?.dish?.name?.EN}
                    </Box>
                  </Box>
                  <Box fontSize={12} lineHeight={3}>
                    {elem?.quantity}
                  </Box>
                </Box>
              );
            })}
          </Box>
          {/* Notes */}
          <Box paddingLeft={0.5} paddingRight={1.5} paddingTop={1.2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <Box fontSize={14}>Notes</Box>
                <Box fontSize={10}>
                  {el?.customer?.withCutlery ? "With cutlery" : "No cutlery"}
                </Box>
                <Box fontSize={8}>Delivered by</Box>
                <Box fontSize={12}>{el?.driver?.name || ""}</Box>
              </Box>
              <Box>
                <Box fontSize={14}>Address </Box>
                <Box fontSize={14}>Area : {el?.address?.area?.name || ""}</Box>
                <Box fontSize={14}>Street : {el?.address?.street || ""}</Box>
                <Box fontSize={14}>Building : {el?.address?.build || ""}</Box>
                <Box fontSize={14}>Zone : {el?.address?.zone || ""}</Box>
                <Box fontSize={14}>Apt Nbr : {el?.address?.nbrApt || ""}</Box>
              </Box>
            </Box>
          </Box>
          {/* Boxes And Delivery */}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            paddingLeft={0.5}
            paddingRight={1.5}
            paddingTop={1.2}
          >
            <Box>
              <Box fontSize={10}>Box no</Box>
              <Box fontSize={12}>
                {el?.consumedBoxes || 0}/{el?.nbrOfBoxes || 0}
              </Box>
            </Box>
            <Box>
              <Box fontSize={10}>Delivery time</Box>
              <Box fontSize={12}>{el?.address?.deliveryTime || ""}</Box>
            </Box>
          </Box>
          {/* Macros */}
          <Box paddingLeft={0.5} paddingRight={1.5} paddingTop={1.2}>
            <Table style={{ borderCollapse: "collapse" }}>
              <tr>
                <Td>
                  <Box fontSize={10}>
                    <Box>Total</Box>
                    <Box>Calories</Box>
                  </Box>
                </Td>
                <Td>
                  <Box fontSize={10}>
                    <Box>Total</Box>
                    <Box>Protein</Box>
                  </Box>
                </Td>
                <Td>
                  <Box fontSize={10}>
                    <Box>Total</Box>
                    <Box>Carbs</Box>
                  </Box>
                </Td>
                <Td>
                  <Box fontSize={10}>
                    <Box>Total</Box>
                    <Box>Fat</Box>
                  </Box>
                </Td>
                <Td>
                  <Box fontSize={10}>
                    <Box>Total</Box>
                    <Box>Fiber</Box>
                  </Box>
                </Td>
              </tr>
              <tr>
                <Td>
                  <Box fontSize={12}>
                    {Math.round(el?.macros?.calories || 0)}kcl
                  </Box>
                </Td>
                <Td>
                  <Box fontSize={12}>{Math.round(el?.macros?.prot || 0)}g</Box>
                </Td>
                <Td>
                  <Box fontSize={12}>{Math.round(el?.macros?.carb || 0)}g</Box>
                </Td>
                <Td>
                  <Box fontSize={12}>{Math.round(el?.macros?.fat || 0)}g</Box>
                </Td>
                <Td>
                  <Box fontSize={12}>{Math.round(el?.macros?.fiber || 0)}g</Box>
                </Td>
              </tr>
            </Table>
          </Box>
          <div style={{ pageBreakAfter: "always" }}></div>
        </Box>
      ))}
    </div>
  );
});

export default PackingForBagsReportPrint;
