import { makeStyles } from "@mui/styles";
import { styled, lighten, Box, Theme } from "@mui/material";

export const useStylesPricingInput = makeStyles((theme: Theme) => {
  return {
    price__title: {
      textAlign: "center",
      margin: "7px 0 8px",
      color: "#1C2843",
      fontWeight: 500,
    },
    meal__title: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
      marginBottom: "0px !important",
    },
    meal__subtitle: {
      textAlign: "center",
      color: "#1C2843",
      fontWeight: 500,
    },
    meal__price: {
      backgroundColor: `${lighten(theme.palette.primary.main, 0.9)}`,
      borderRadius: "10px",
      padding: "5px 0",
    },
    priceInput: {
      width: "100%",
    },
    inputClearPrice: {
      top: "-16px !important",
      right: "-16px !important",
      backgroundColor: "#dcdcdc !important",
      "& > svg": {
        height: "16px",
        width: "16px",
      },
    },
    price__subtitle: {
      color: "#1C2843",
      fontWeight: 500,
      textAlign: "center",
      backgroundColor: `${lighten(theme.palette.secondary.main, 0.7)}`,
      borderRadius: "10px",
      padding: "10px 0",
    },
    price__error__helper: {
      color: `${theme.palette.error.main}`,
      fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    },
  };
});
export const GridContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "0.5fr 1fr 1fr 1fr",
  // gridAutoRows: "minmax(50px, auto)",
  gap: "12px",
  gridTemplateAreas: `
  "time 5D 6D 7D"
  "week WP5 WP6 WP7"
  "twoWeek TW5 TW6 TW7"
  "threeWeek THW5 THW6 THW7"
  "month M5 M6 M7"
  "twoMonth TWM5 TWM6 TWM7"
  "threeMonth TH5 TH6 TH7"`,
  marginTop: "10px",
}));

export const Seperator = styled(Box)`
  height: 20px;
  width: 2px;
  background-color: #666e7e;
`;
