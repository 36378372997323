import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material-ui
import {
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//icons
import Delete from "../../../../public/icons/delete";
//components
import PageHeader from "../../../pageHeader/PageHeader";
import Table from "../../../layout/commun/Table";
//Utils
import { driverColumns } from "../../Utils";
import { useIsAuthorized } from "../../../../utils/Hooks/useIsAuthorized";
//styles
import { useStylesButtons } from "../../../../styles/Buttons__styles";
import { useStylesMenu } from "../../../../styles/Menu__styles";
import { useStylesDialog } from "../../../../styles/Dialog__styles";
import { TableContainer } from "../../../../styles/Table__styles";
//queries&mutations
import {
  useGetDriversQuery,
  useDeleteDriverMutation,
  GetDriversDocument,
} from "../../../../graphql/types";

const ListDrivers = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [idDriver, setIdDriver] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);

  //queries&mutations
  const listDrivers = useGetDriversQuery({ fetchPolicy: "network-only" });
  const [deleteDriver, { loading }] = useDeleteDriverMutation();

  //functions
  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setIdDriver(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (idDriver) {
      navigate(`/admin/delivery_driver/update_driver/${idDriver}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteDriver = () => {
    setOpen(false);
    idDriver &&
      deleteDriver({
        variables: {
          input: idDriver,
        },
        refetchQueries: [{ query: GetDriversDocument }],
        onCompleted: () => {
          listDrivers.refetch();
          enqueueSnackbar("Driver succuessfully deleted", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
  };

  const renderTableRows = () => {
    return (
      listDrivers?.data?.getDrivers?.map((el) => {
        const isLoading = idDriver === el._id && loading;
        return {
          id: el.ref,
          name: el.name,
          email: el.email,
          password: el.password,
          phone: `${el?.phone?.prefix}${el?.phone?.number}`,
          actions: isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <IconButton onClick={(e) => handleClickMenu(e, el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <div>
      {useIsAuthorized("ADMIN_DRIVERS_CREATE") && (
        <PageHeader
          buttonLink={`/admin/delivery_driver/add_driver`}
          buttonText={"Add Driver"}
        />
      )}
      <TableContainer>
        <Table
          columns={driverColumns}
          data={renderTableRows()}
          emptyMessage="No Drivers found !"
          loading={listDrivers?.loading}
          numberOfFakeRow={30}
        />
      </TableContainer>
      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("ADMIN_DRIVERS_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <div>
                <span className="menu_title">Update</span>
              </div>
            </MenuItem>
          )}

          {useIsAuthorized("ADMIN_DRIVERS_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          )}
        </Menu>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Driver ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Driver ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={handleDeleteDriver}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ListDrivers;
