import { Box, styled } from "@mui/material";

export const StyledCommonCustomerInfo = styled(Box)`
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #666e7e;
`;

export const StyledVerticalSeperator = styled("div")`
  height: 20px;
  width: 2px;
  background-color: #666e7e;
  transform: translateY(-1px);
`;

interface StyledSpanProps {
  color?: string;
}
export const StyledSpan = styled("span")<StyledSpanProps>`
  text-transform: capitalize;
  color: ${({ color }) => color || "#666e7e"};
  font-weight: ${({ color }) => (color ? "500" : "400")};
`;
