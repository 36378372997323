import { styled } from "@mui/material";

export const StyledActifDayIcon = styled("div")`
  border-radius: 50%;
  background: linear-gradient(
    165deg,
    rgba(1, 32, 68, 1) 30%,
    rgba(15, 108, 122, 1) 60%
  );
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  height: 20px;
  width: 20px;
  padding: 5px;
  text-transform: uppercase;
  position: relative;
  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 400;
    font-size: 14px;
  }
`;
export const StyledInactifDayIcon = styled("div")`
  border-radius: 50%;
  background: #e6e5e5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  height: 20px;
  width: 20px;
  padding: 5px;
  text-transform: uppercase;
  position: relative;
  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 400;
    font-size: 14px;
  }
`;
