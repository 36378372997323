function ComplaintsIcon() {
  return (
    <svg
      width="34"
      height="28"
      viewBox="0 0 59 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4716 49.0005C28.9444 49.0005 28.4171 48.6723 27.6982 48.0627C27.5544 47.922 27.3627 47.7813 27.1231 47.5938C22.474 43.7488 17.9687 39.8569 12.9842 35.59C10.9232 33.8081 8.19131 31.4636 5.55525 29.0254C0.57068 24.3364 -1.10682 18.522 0.714466 12.1919C2.58368 5.76795 7.13689 1.68853 13.8948 0.328717C19.7421 -0.843532 24.9184 1.03207 29.3278 5.90862C30.1905 5.15838 31.4366 4.1268 32.2514 3.47034C38.1466 -1.26554 46.5341 -1.12487 52.621 3.75168C56.5512 6.89331 58.8997 11.4885 59.0435 16.3182C59.1872 21.101 57.1263 25.7431 53.3879 29.0254C46.63 35.0273 39.6324 40.9823 32.8266 46.7498L31.9159 47.5469C31.9159 47.5469 31.5325 47.8751 31.3887 48.0158C30.6219 48.6723 30.0946 49.0005 29.5674 49.0005H29.4716ZM17.2019 2.34499C16.2912 2.34499 15.3327 2.43877 14.3741 2.62632C8.47888 3.79857 4.6446 7.22154 3.01503 12.8014C1.43339 18.3345 2.82332 23.211 7.23274 27.3373C9.82088 29.7756 12.5049 32.0732 14.5658 33.855C19.5504 38.122 24.0556 41.967 28.7047 45.812C28.7047 45.812 28.7526 45.8589 28.8006 45.9058L31.2929 36.9967L24.9663 30.7134C24.5349 30.2914 24.487 29.6818 24.8704 29.2129L29.9509 22.2732L24.1036 15.0522C23.816 14.677 23.7681 14.1144 24.0077 13.7392L27.7461 7.73733C24.6308 4.17369 21.132 2.39188 17.2498 2.39188L17.2019 2.34499ZM27.3627 29.7756L33.4496 35.8244C33.7372 36.1058 33.881 36.5278 33.7372 36.9498L31.5804 44.6866C38.2425 39.0598 45.0963 33.1986 51.7104 27.3373C54.9695 24.4302 56.695 20.5383 56.5512 16.412C56.4553 12.2388 54.4423 8.30001 51.0394 5.58039C46.6779 2.06365 39.5845 0.610057 33.6893 5.29905C32.5869 6.18996 30.6219 7.878 30.0946 8.3469L26.4521 14.2081L32.3473 21.4761C32.6828 21.8981 32.6828 22.4608 32.3473 22.8828L27.3148 29.7287L27.3627 29.7756Z"
        fill="#fff"
      />
    </svg>
  );
}

export default ComplaintsIcon;
