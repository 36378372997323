import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { NotePiority } from "../../../graphql/types";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";
import { DialogTitleProps } from "../../Utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export type AddNoteParams = {
  priority: NotePiority;
  content: string;
};
type DialogAddNoteProps = {
  open: boolean;
  handleClose: () => void;
  handleSaveChanges: (params: AddNoteParams) => void;
};
const DialogAddNote: React.FC<DialogAddNoteProps> = (props) => {
  const { open, handleClose, handleSaveChanges } = props;
  const [priority, setPriority] = React.useState<NotePiority>("LOW");
  const [content, setContent] = React.useState<string>("");
  //functiosn
  const onClick = (prop: NotePiority) => () => {
    setPriority(prop);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };
  const onClickSaveChanges = async () => {
    await handleSaveChanges({ priority, content });
    setPriority("LOW");
    setContent("");
  };
  const getStyleButtonSelected = (prop: NotePiority) => () => {
    if (priority === prop) {
      return { border: "1px solid #ccc" };
    }
    return {};
  };
  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <BootstrapDialogTitle id="customized-dialog" onClose={handleClose}>
          Add Note
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box minWidth={500} mb={2}>
            <IconButton
              aria-label="low"
              sx={getStyleButtonSelected("LOW")}
              onClick={onClick("LOW")}
            >
              <CircleRoundedIcon fontSize="large" sx={{ color: "#008026" }} />
            </IconButton>
            <IconButton
              aria-label="normal"
              sx={getStyleButtonSelected("NORMAL")}
              onClick={onClick("NORMAL")}
            >
              <CircleRoundedIcon fontSize="large" sx={{ color: "#FEED01" }} />
            </IconButton>
            <IconButton
              aria-label="high"
              sx={getStyleButtonSelected("HIGH")}
              onClick={onClick("HIGH")}
            >
              <CircleRoundedIcon fontSize="large" sx={{ color: "#FF8B00" }} />
            </IconButton>
          </Box>
          <TextField
            id="content"
            label="Content"
            multiline
            fullWidth
            rows={4}
            value={content}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClickSaveChanges}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default DialogAddNote;
