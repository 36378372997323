import React, { useEffect, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Button,
} from "@mui/material";
import {
  BeachAccess,
  Coffee,
  Fastfood,
  Flight,
  Sick,
} from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import {
  LeftContainer,
  RightContainer,
  StyledLoaderContainer,
} from "../../../styles/Customers__styles";
//components
import Table from "../../layout/commun/Table";
import PageHeader from "../../pageHeader/PageHeader";
import TabContentHeader from "../../TabContentHeader/TabContentHeader";
import Status from "../../Status/Status";
//utils
import {
  filterElementsPauses,
  FilterElementsPausesProp,
  getMessageError,
  PausesSort,
} from "../../Utils";
import { columnsPauses, CustomerPausesFormType } from "../Utils";
//queries&mutations
import {
  BreakReason,
  FreezHistoryPayload,
  GetCustomerPausesDocument,
  useAddFreezToCustomerMutation,
  useDeleteFreezMutation,
  useGetCustomerPausesQuery,
} from "../../../graphql/types";
import { ApolloError } from "@apollo/client";

function CustomerPauses() {
  //styles
  const ContainersClasses = useStylesContainers();

  //params
  const { id } = useParams();

  // states
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [sortBy, setSortBy] = useState<PausesSort>("UPCOMING_PAUSES");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [datesToDisable, setDatesToDisable] = useState([]);

  const handleButtonClick = (buttonValue: BreakReason) => {
    preDefinedReason === buttonValue
      ? setValue("preDefinedReason", null)
      : setValue("preDefinedReason", buttonValue);
  };

  //react hook form
  const methods = useForm<CustomerPausesFormType>({
    defaultValues: {
      start_date: null,
      freezing_date: null,
      adminEnforced: false,
      customReason: "",
      preDefinedReason: null,
    },
  });
  const { handleSubmit, control, reset, setValue } = methods;

  const dataWatch = useWatch({
    control,
  });

  const otherReason = dataWatch.customReason;
  const preDefinedReason = dataWatch.preDefinedReason;
  //queries
  const { data: customerPausesData, refetch: refetchCustomerPauses } =
    useGetCustomerPausesQuery({
      variables: {
        customerId: id || "",
      },
      fetchPolicy: "no-cache",
    });

  //mutations
  const [addPause, { loading: loadingAddFreezToCustomer }] =
    useAddFreezToCustomerMutation();

  const [deletePause, { loading: loadingDeleteFreez }] =
    useDeleteFreezMutation();

  //consts
  const CustomerPauses = customerPausesData?.getCustomerPauses;

  const count = CustomerPauses?.length || 0;

  //useEffects
  useEffect(() => {
    if (CustomerPauses) {
      CustomerPauses.sort((a, b) => {
        if (sortBy === "PREVIOUS_PAUSES") {
          if (a.status === "PAUSED" && b.status !== "PAUSED") {
            return -1;
          } else if (a.status !== "PAUSED" && b.status === "PAUSED") {
            return 1;
          }
        }
        if (a.status === "UPCOMMING" && b.status !== "UPCOMMING") {
          return -1;
        } else if (a.status !== "UPCOMMING" && b.status === "UPCOMMING") {
          return 1;
        }
        return 0;
      });
      setDatesToDisable(
        CustomerPauses &&
          (CustomerPauses.map((el) => {
            let diff = moment(el.to).diff(moment(el.from), "days");
            return diff < 0
              ? []
              : Array.from(Array(diff + 1).keys()).map((_, index) =>
                  moment(el.from).add(index, "days")
                );
          }).flat() as any)
      );
    }
  }, [customerPausesData, sortBy]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onSubmit = async (data: CustomerPausesFormType) => {
    try {
      if (moment(data.freezing_date).isSame(data.start_date, "day")) {
        enqueueSnackbar("Please Verify the Start date and Resuming date", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      } else {
        await addPause({
          variables: {
            freezPlanInput: {
              customerId: id || "",
              from: data.start_date ?? new Date(),
              to: moment(data.freezing_date).subtract(1, "day").toDate(),
              forced: data.adminEnforced,
              customReason: data.customReason,
              preDefinedReason: data.preDefinedReason ?? null,
            },
          },
          refetchQueries: [
            {
              query: GetCustomerPausesDocument,
              variables: { customerId: id || "" },
            },
          ],
          onCompleted: () => {
            reset();
            refetchCustomerPauses();
            enqueueSnackbar("Pause succuessfully added", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
          },
        });
      }
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const deleteFreeze = async (freeze: FreezHistoryPayload) => {
    try {
      await deletePause({
        variables: {
          freezId: freeze._id,
          planId: freeze.planId,
        },
        refetchQueries: [
          {
            query: GetCustomerPausesDocument,
            variables: { customerId: id || "" },
          },
        ],
        onCompleted: () => {
          reset();
          refetchCustomerPauses();
          enqueueSnackbar("Pause succuessfully deleted", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  //table rows
  const renderTableRows = () => {
    return (
      CustomerPauses?.map((el) => {
        return {
          starting_date: el?.forced
            ? "A - " + moment(el.from).format("DD/MM/YYYY")
            : " " + moment(el.from).format("DD/MM/YYYY"),
          ending_date: moment(el.to).format("DD/MM/YYYY"),
          resuming_date: moment(el.to).add(1, "day").format("DD/MM/YYYY"),
          reason: el.reason,
          status: (
            <Status
              status={el.status as any}
              statusText={el.status.toLocaleLowerCase()}
            />
          ),
          actions: (
            <>
              <IconButton
                onClick={() => deleteFreeze(el)}
                disabled={loadingDeleteFreez}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </>
          ),
        };
      }) || []
    );
  };

  const disableBookedDays = (date: Date) => {
    const convertedIntoDateObject = datesToDisable.map((bookedDate) => {
      return new Date(bookedDate).getTime();
    });
    return convertedIntoDateObject.includes(
      new Date(date).setTime(new Date(date).getTime() + 1 * 60 * 60 * 1000)
    );
  };

  return (
    <>
      {!loadingDeleteFreez ? (
        <Box display="flex">
          <LeftContainer>
            {/* <TabContentHeader title={"Pausing List"} /> */}
            <PageHeader
              filterElements={filterElementsPauses}
              setSelectedFilter={setSortBy}
              selectedFilter={sortBy}
            />
            <TableContainer>
              <Table
                columns={columnsPauses}
                data={renderTableRows()}
                loading={false}
                numberOfFakeRow={30}
                emptyMessage="No Pauses found !"
                tablePaginationProps={{
                  rowsPerPageOptions: [30, 60, 90],
                  count: count,
                  page: page - 1,
                  onPageChange,
                  rowsPerPage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                paginationProps={{
                  page: page,
                  count: Math.ceil(count / rowsPerPage),
                  showFirstButton: true,
                  showLastButton: true,
                  boundaryCount: 1,
                  siblingCount: 2,
                  onChange: onPageChange,
                }}
              />
            </TableContainer>
          </LeftContainer>
          <RightContainer padding="20px 10px 20px 0px">
            <Box borderRadius="8px" border="2px solid #F3F4FA" padding="15px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <TabContentHeader
                  title="Add Pause"
                  loading={loadingAddFreezToCustomer}
                />
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Start Date
                  </p>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <Controller
                      name="start_date"
                      control={methods.control}
                      render={({ field: { ref, ...rest } }) => (
                        <DesktopDatePicker
                          {...rest}
                          inputFormat="DD/MM/YYYY"
                          disablePast={true}
                          disableFuture={false}
                          shouldDisableDate={(date) => disableBookedDays(date)}
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: "100%" }} />
                          )}
                        />
                      )}
                      rules={{
                        required: {
                          message: "Field required",
                          value: true,
                        },
                      }}
                    />
                  </LocalizationProvider>

                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {methods.formState.errors?.start_date?.message}
                  </p>
                </Box>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Resuming Date
                  </p>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <Controller
                      name="freezing_date"
                      control={methods.control}
                      render={({ field: { ref, ...rest } }) => (
                        <DesktopDatePicker
                          {...rest}
                          inputFormat="DD/MM/YYYY"
                          disablePast={true}
                          disableFuture={false}
                          shouldDisableDate={(date) => disableBookedDays(date)}
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: "100%" }} />
                          )}
                        />
                      )}
                      rules={{
                        required: {
                          message: "Field required",
                          value: true,
                        },
                      }}
                    />
                  </LocalizationProvider>

                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {methods.formState.errors?.freezing_date?.message}
                  </p>
                </Box>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Reason for Pause <span>(Optional)</span>
                </p>
                <Box>
                  <Button
                    variant={
                      preDefinedReason === "TRAVELING"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleButtonClick("TRAVELING")}
                    sx={{ margin: 1 }}
                    disabled={(otherReason ?? "").length > 0}
                  >
                    <Box display="flex" alignItems="center">
                      <Flight fontSize="small" />
                      Traveling
                    </Box>
                  </Button>
                  <Button
                    variant={
                      preDefinedReason === "CHEAT_DAY"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleButtonClick("CHEAT_DAY")}
                    sx={{ margin: 1 }}
                    disabled={(otherReason ?? "").length > 0}
                  >
                    <Box display="flex" alignItems="center">
                      <Fastfood fontSize="small" />
                      Cheat Day
                    </Box>
                  </Button>
                  <Button
                    variant={
                      preDefinedReason === "VACATION" ? "contained" : "outlined"
                    }
                    onClick={() => handleButtonClick("VACATION")}
                    sx={{ margin: 1 }}
                    disabled={(otherReason ?? "").length > 0}
                  >
                    <Box display="flex" alignItems="center">
                      <BeachAccess fontSize="small" />
                      Vacation
                    </Box>
                  </Button>
                  <Button
                    variant={
                      preDefinedReason === "SICK" ? "contained" : "outlined"
                    }
                    onClick={() => handleButtonClick("SICK")}
                    sx={{ margin: 1 }}
                    disabled={(otherReason ?? "").length > 0}
                  >
                    <Box display="flex" alignItems="center">
                      <Sick fontSize="small" />
                      Sick
                    </Box>
                  </Button>
                  <Button
                    variant={
                      preDefinedReason === "TAKING_A_BREAK"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleButtonClick("TAKING_A_BREAK")}
                    sx={{ margin: 1 }}
                    disabled={(otherReason ?? "").length > 0}
                  >
                    <Box display="flex" alignItems="center">
                      <Coffee fontSize="small" />
                      Taking a break
                    </Box>
                  </Button>
                </Box>

                <label
                  className={ContainersClasses.section__subtitle}
                  style={{ marginBottom: "4px" }}
                >
                  Other Reason <span>(Max 50 characters)</span>
                </label>
                <TextField
                  id="reason"
                  placeholder="Reason"
                  multiline
                  rows={3}
                  sx={{ width: "100%" }}
                  inputProps={{ maxLength: 50 }}
                  disabled={dataWatch.preDefinedReason != null}
                  {...methods.register("customReason", {})}
                />

                <Controller
                  name="adminEnforced"
                  control={control}
                  render={({ field: { onChange, ref } }) => {
                    return (
                      <FormControlLabel
                        checked={dataWatch?.adminEnforced}
                        onChange={(_, value) => {
                          onChange(value);
                        }}
                        value="start"
                        control={<Checkbox color="secondary" />}
                        label="Can't delete"
                        labelPlacement="start"
                      />
                    );
                  }}
                />
                <TabContentHeader
                  buttonText="Add Pause"
                  loading={loadingAddFreezToCustomer}
                />
              </form>
            </Box>
          </RightContainer>
        </Box>
      ) : (
        <StyledLoaderContainer>
          <CircularProgress size={40} color="secondary" />
        </StyledLoaderContainer>
      )}
    </>
  );
}

export default CustomerPauses;
