import { PolygonPayload } from "../../graphql/types";
import { ColumnsProps } from "../layout/commun/Table";
//calculate the are of a polygon
export function area(poly: PolygonPayload): number {
  let s = 0.0;
  const ring = poly?.coordinates && poly.coordinates[0];
  if (ring) {
    for (let i = 0; i < ring.length - 1; i++) {
      s += ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1];
    }
    return 0.5 * s;
  }
  return 0;
}

//calculate the center of a polygon
export function centroid(poly: PolygonPayload): number[] {
  let c = [0, 0];
  const ring = poly.coordinates?.[0];
  if (ring) {
    for (let i = 0; i < ring.length - 1; i++) {
      c[0] +=
        (ring[i][0] + ring[i + 1][0]) *
        (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
      c[1] +=
        (ring[i][1] + ring[i + 1][1]) *
        (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
    }
  }
  let a = area(poly);
  c[0] /= a * 6;
  c[1] /= a * 6;
  return c;
}
export const defaultValueOperatingHours = [
  {
    time: "Morning Delivery Time",
    delivery: false,
    from: null,
    to: null,
  },
  {
    time: "Evening Delivey Time",
    delivery: false,
    from: null,
    to: null,
  },
];
export const driverColumns: ColumnsProps = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Driver Name",
    accessor: "name",
  },
  {
    header: "Driver Phone",
    accessor: "phone",
  },
  {
    header: "Driver Email",
    accessor: "email",
  },
  {
    header: "Driver Password",
    accessor: "password",
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];

export const areaCols: ColumnsProps = [
  {
    header: "",
    accessor: "id",
    cellProps: { align: "left", width: "60px", padding: "checkbox" },
  },
  {
    header: "Area Name ",
    accessor: "name",
  },
  {
    header: "Area Name AR",
    accessor: "nameAr",
  },

  {
    header: "Has cordinations",
    accessor: "hasCord",
    cellProps: {
      align: "right",
      width: "250px",
      sx: {
        marginX: "auto",
      },
    },
  },

  { header: "Status", accessor: "status" },

  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "50px" },
  },
];

export const areaColumns: ColumnsProps = [
  {
    header: "Area Name",
    accessor: "name",
  },
  {
    header: "Driver Name",
    accessor: "driver",
  },
  {
    header: "Morning Delivery Time",
    accessor: "morning",
  },
  {
    header: "Evening Delivery Time",
    accessor: "evening",
  },
  {
    header: "Friday Driver",
    accessor: "fridayDriver",
  },
  {
    header: "Friday Morning Delivery Time",
    accessor: "fridayMorning",
  },
  {
    header: "Friday Evening Delivery Time",
    accessor: "fridayEvening",
  },
  {
    header: "Area Status",
    accessor: "status",
    cellProps: { align: "center", width: "110px" },
  },
  {
    header: "Actions",
    accessor: "actions",
    cellProps: { align: "center", width: "30px" },
  },
];
