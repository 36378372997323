import { forwardRef } from "react";
import styled from "styled-components";
//material ui
import { Box, Typography } from "@mui/material";
//types
import { dishReportProps } from "../Types";
//logo
import dieture_logo_black from "../../../public/images/dieture_logo_black.png";
//utils
import { handleMeals } from "../../Utils";

const DishReportPrint = forwardRef<HTMLDivElement, dishReportProps>(
  ({ dishData }, ref) => {
    const current = new Date();
    const today = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const currentTime =
      current.getHours() +
      ":" +
      current.getMinutes() +
      ":" +
      current.getSeconds();

    const Table = styled.table`
      border: 1px solid black;
      border-collapse: collapse;
    `;

    const Th = styled.th`
      border: 1px solid black;
    `;

    const Td = styled.td`
      border: 1px solid black;
    `;
    return (
      <div ref={ref}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          borderBottom={1}
          borderColor="black"
          marginLeft={5}
          marginRight={5}
        >
          <Box>
            <Typography style={{ fontSize: 18 }} fontWeight={600}>
              Recipe Report
            </Typography>
            <Typography style={{ fontSize: 17 }} fontWeight={600}>
              {dishData?.name?.EN}
            </Typography>
            <Typography style={{ fontSize: 16 }} fontWeight={500}>
              {dishData?.dishSuitability === "LUNCH" ||
              dishData?.dishSuitability === "DINNER"
                ? "Lunch/Dinner"
                : handleMeals(dishData?.dishSuitability)}
            </Typography>
          </Box>
          <img
            src={dieture_logo_black}
            height="50px"
            alt="dieture_logo_black"
          />
          <Box>
            <Typography fontSize={8.5}>
              Generated on : {today}-{currentTime}
            </Typography>
            <Typography variant="h3">Program:</Typography>
          </Box>
        </Box>
        <Box paddingLeft={5} paddingRight={5} marginTop={2}>
          <Typography
            style={{ fontSize: 14 }}
            fontWeight={500}
            marginBottom={2}
          >
            Cooking instruction
          </Typography>
          <Typography variant="body1">
            {dishData?.cookingInstruction}
          </Typography>
          <Typography style={{ fontSize: 14 }} fontWeight={500} marginTop={5}>
            Plating instruction
          </Typography>
          <img
            src={dishData?.platingPhotoUrl || ""}
            height="150px"
            alt="dieture_logo_black"
          />
          <Typography variant="body1">
            {dishData?.platingInstruction}
          </Typography>
          <Typography style={{ fontSize: 14 }} fontWeight={500} marginTop={5}>
            Packaging instruction
          </Typography>
          <img
            src={dishData?.packagingPhotoUrl || ""}
            height="150px"
            alt="dieture_logo_black"
          />
          <Typography variant="body1">{dishData?.packagingName}</Typography>
        </Box>
        <div style={{ pageBreakAfter: "always" }}></div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          borderBottom={1}
          borderColor="black"
          marginLeft={5}
          marginRight={5}
        >
          <Box>
            <Typography style={{ fontSize: 18 }} fontWeight={600}>
              Recipe Report
            </Typography>
            <Typography style={{ fontSize: 17 }} fontWeight={600}>
              {dishData?.name?.EN}
            </Typography>
            <Typography style={{ fontSize: 16 }} fontWeight={500}>
              {dishData?.dishSuitability === "LUNCH" ||
              dishData?.dishSuitability === "DINNER"
                ? "Lunch/Dinner"
                : handleMeals(dishData?.dishSuitability)}
            </Typography>
          </Box>
          <img
            src={dieture_logo_black}
            height="50px"
            alt="dieture_logo_black"
          />
          <Box>
            <Typography fontSize={8.5}>
              Generated on : {today}-{currentTime}
            </Typography>
            <Typography variant="h3">Program:</Typography>
          </Box>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          gap={2}
          paddingLeft={5}
          paddingRight={5}
          marginTop={5}
        >
          {/* Table for small */}
          <Table
            style={{
              borderWidth: 1,
              borderColor: "#232222",
              borderCollapse: "collapse",
            }}
          >
            <tr
              style={{
                backgroundColor: "#232222",
                color: "#FFFF",
                borderColor: "black",
              }}
            >
              <Th
                style={{
                  fontSize: 16,
                  textAlignLast: "justify",
                  borderColor: "black",
                  paddingLeft: 16,
                }}
              >
                SMALL
              </Th>
              <Th style={{ fontSize: 15 }}>Raw</Th>
              <Th style={{ fontSize: 15 }}>Cooked</Th>
            </tr>
            <tr style={{ fontSize: 12, fontWeight: 600 }}>
              <Td>Total Calorie</Td>
              <Td>
                {dishData?.small?.nutritionDetails
                  ?.find((el) => el.name === "Energy")
                  ?.quantity.toFixed(2)}
              </Td>
              <Td>
                {dishData?.small?.nutritionDetails
                  ?.find((el) => el.name === "Energy")
                  ?.quantity.toFixed(2)}
              </Td>
            </tr>
            {dishData?.small?.ingredients?.map((el) => (
              <tr style={{ fontSize: 12 }} key={el.ingredient?._id}>
                <Td> {el?.ingredient?.name?.EN} </Td>
                <Td>{el?.quantity}</Td>
                <Td>{el?.cookedWeight || 0}</Td>
              </tr>
            ))}
            <tr style={{ fontSize: 12, fontWeight: 600 }}>
              <Td>Net weight</Td>
              <Td>{dishData?.small?.totalWeight}</Td>
              <Td>{dishData?.small?.totalCookedWeight}</Td>
            </tr>
          </Table>
          {/* Table for medium */}
          <Table
            style={{
              borderWidth: 1,
              borderColor: "#232222",
              borderCollapse: "collapse",
            }}
          >
            <tr
              style={{
                backgroundColor: "#232222",
                color: "#FFFF",
                borderColor: "black",
              }}
            >
              <Th
                style={{
                  fontSize: 16,
                  textAlignLast: "justify",
                  borderColor: "black",
                  paddingLeft: 16,
                }}
              >
                Medium
              </Th>
              <Th style={{ fontSize: 15 }}>Raw</Th>
              <Th style={{ fontSize: 15 }}>Cooked</Th>
            </tr>
            <tr style={{ fontSize: 12, fontWeight: 600 }}>
              <Td>Total Calorie</Td>
              <Td>
                {dishData?.medium?.nutritionDetails
                  ?.find((el) => el.name === "Energy")
                  ?.quantity.toFixed(2)}
              </Td>
              <Td>
                {dishData?.medium?.nutritionDetails
                  ?.find((el) => el.name === "Energy")
                  ?.quantity.toFixed(2)}
              </Td>
            </tr>
            {dishData?.medium?.ingredients?.map((el) => (
              <tr style={{ fontSize: 12 }} key={el.ingredient?._id}>
                <Td> {el?.ingredient?.name?.EN} </Td>
                <Td>{el?.quantity}</Td>
                <Td>{el?.cookedWeight || 0}</Td>
              </tr>
            ))}
            <tr style={{ fontSize: 12, fontWeight: 600 }}>
              <Td>Net weight</Td>
              <Td>{dishData?.medium?.totalWeight}</Td>
              <Td>{dishData?.medium?.totalCookedWeight}</Td>
            </tr>
          </Table>
          {/* Table for large */}
          <Table
            style={{
              borderWidth: 1,
              borderColor: "#232222",
              borderCollapse: "collapse",
            }}
          >
            <tr
              style={{
                backgroundColor: "#232222",
                color: "#FFFF",
                borderColor: "black",
              }}
            >
              <Th
                style={{
                  fontSize: 16,
                  textAlignLast: "justify",
                  borderColor: "black",
                  paddingLeft: 16,
                }}
              >
                Large
              </Th>
              <Th style={{ fontSize: 15 }}>Raw</Th>
              <Th style={{ fontSize: 15 }}>Cooked</Th>
            </tr>
            <tr style={{ fontSize: 12, fontWeight: 600 }}>
              <Td>Total Calorie</Td>
              <Td>
                {dishData?.large?.nutritionDetails
                  ?.find((el) => el.name === "Energy")
                  ?.quantity.toFixed(2)}
              </Td>
              <Td>
                {dishData?.large?.nutritionDetails
                  ?.find((el) => el.name === "Energy")
                  ?.quantity.toFixed(2)}
              </Td>
            </tr>
            {dishData?.large?.ingredients?.map((el) => (
              <tr style={{ fontSize: 12 }} key={el.ingredient?._id}>
                <Td> {el?.ingredient?.name?.EN} </Td>
                <Td>{el?.quantity}</Td>
                <Td>{el?.cookedWeight || 0}</Td>
              </tr>
            ))}
            <tr style={{ fontSize: 12, fontWeight: 600 }}>
              <Td>Net weight</Td>
              <Td>{dishData?.large?.totalWeight}</Td>
              <Td>{dishData?.large?.totalCookedWeight}</Td>
            </tr>
          </Table>
        </Box>
      </div>
    );
  }
);
export default DishReportPrint;
