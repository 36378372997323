import { Droppable, Draggable } from "react-beautiful-dnd";
import { Paper, Typography } from "@material-ui/core";
import { Box, Chip } from "@mui/material";
import { GetAllTasksPayload } from "../../../graphql/types";
interface DishesPoolSectionProps {
  title: string;
  items: GetAllTasksPayload[];
}
const DishesPoolSection: React.FC<DishesPoolSectionProps> = ({
  title,
  items,
}) => {
  return (
    <Droppable droppableId={title} direction="horizontal">
      {(provided) => (
        <Paper
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{ display: "flex", alignItems: "center", width: "100%" }}
        >
          <Typography variant="h6" style={{ minWidth: "8%", marginLeft: 5 }}>
            {title === "lunch" ? "Lunch & dinner" : title}
          </Typography>
          <Box display="flex" flexDirection="row" flexWrap={"wrap"}>
            {items.map((item, index) => (
              <Draggable key={item._id} draggableId={item._id!} index={index}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Chip
                      label={
                        <>
                          <Typography
                            style={{
                              whiteSpace: "pre-wrap",
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {item.name.EN}
                          </Typography>
                          <Typography
                            style={{
                              whiteSpace: "pre-wrap",
                              color: "black",
                              fontSize: 12,
                              textTransform: "uppercase",
                            }}
                          >
                            {`S-${item.units.small} M-${item.units.medium} L-${item.units.large} = ${item.units.total} pax`}
                          </Typography>
                        </>
                      }
                      style={{
                        width: "185px",
                        height: "70px",
                        justifyContent: "space-between",
                        margin: "20px",
                      }}
                    />
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        </Paper>
      )}
    </Droppable>
  );
};

export default DishesPoolSection;
