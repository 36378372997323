import { useMemo, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
//material ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import Table from "../../layout/commun/Table";
import PageHeader from "../../pageHeader/PageHeader";
//utils
import { columns } from "./Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { PromocodeUsersTableColumns } from "../promoCode/Utils";
import { DialogTitleProps, filterElements, getMessageError } from "../../Utils";
//queries&mutations
import {
  GetTrainersListDocument,
  SortBy,
  useActivateDesactivateTrainerMutation,
  useDeleteTrainerMutation,
  useGetPromoCodeUsersLazyQuery,
  useGetTrainersListQuery,
} from "../../../graphql/types";

const TrainersList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isAuthorizedToSeeDetails = useIsAuthorized(
    "MANAGEMENT_TRAINER_DETAILS"
  );

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [search, setSearch] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [trainerId, setTrainerId] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortBy | null>(null);
  const [openPromoCodeUsersModal, setOpenPromoCodeUsersModal] =
    useState<boolean>(false);

  //queries&mutations
  const [deleteTrainer] = useDeleteTrainerMutation();
  const [updateTrainerStatus] = useActivateDesactivateTrainerMutation();
  const TrainersData = useGetTrainersListQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        search: search,
        sort: sortBy,
      },
    },
    fetchPolicy: "no-cache",
  });

  const TrainerList = useMemo(
    () => TrainersData.data?.getTrainersList.data || [],
    [TrainersData.data]
  );

  const count = useMemo(
    () => TrainersData.data?.getTrainersList.count || 0,
    [TrainersData.data]
  );

  const [getPromoCodeUsers, { data: promoCodeUsers, loading }] =
    useGetPromoCodeUsersLazyQuery({
      fetchPolicy: "no-cache",
    });

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClickMenu =
    (item: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setTrainerId(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (trainerId) {
      navigate(`/data/trainers/update_trainer/${trainerId}`);
    }
  };

  const handleCloseDetails = () => {
    setAnchorEl(null);
    if (trainerId) {
      navigate(`/data/trainers/view_trainer/${trainerId}`);
    }
  };

  const DeleteTrainerHandler = async () => {
    setOpen(false);
    await deleteTrainer({
      variables: { input: trainerId || "" },
      refetchQueries: [GetTrainersListDocument],
      onCompleted() {
        enqueueSnackbar("Trainer deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const updateStatusHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    await updateTrainerStatus({
      variables: {
        input: { id: id, isActive: event.target.checked || false },
      },
      refetchQueries: [GetTrainersListDocument],
      onCompleted() {
        enqueueSnackbar("Trainer status changed succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const onLineClickHandler = async (id: string) => {
    setOpenPromoCodeUsersModal(true);
    await getPromoCodeUsers({
      variables: {
        id: id,
      },
    });
  };

  const renderTableRows = () => {
    return (
      TrainerList.map((el, index) => {
        return {
          index: index + 1 + (page - 1) * rowsPerPage,
          ref: el.ref,
          name: isAuthorizedToSeeDetails ? (
            <Button
              onClick={() => navigate(`/data/trainers/view_trainer/${el?._id}`)}
            >
              {`${el?.fname?.EN} ${el?.lname?.EN}`}
            </Button>
          ) : (
            el.fname?.EN + " " + el.lname?.EN
          ),
          phone: el?.phone?.prefix + " " + el?.phone?.number,
          promocode: el?.promoCode?.code ? el?.promoCode?.code : "----",
          usedBy: (
            <Button onClick={() => onLineClickHandler(el.promoCode?._id || "")}>
              {el?.promoCode?.usedBy ? el?.promoCode?.usedBy : "0"}
            </Button>
          ),
          joined: moment(el?.joinedAt).format("DD/MM/YYYY"),
          totalValue: el?.promoCode?.reduction ? el?.promoCode?.reduction : "0",
          status: (
            <Switch
              defaultChecked={el?.isActive || false}
              onChange={(event) => updateStatusHandler(event, el._id || "")}
            />
          ),
          actions: (
            <IconButton onClick={handleClickMenu(el._id || "")}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              height: "fit-content",
            }}
          >
            <CloseIcon
              style={{
                minHeight: "22px",
                height: "22px",
                width: "22px",
                marginBottom: "0px",
              }}
            />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const renderPromoCodeUsersTableRows = () => {
    return (
      promoCodeUsers?.getPromoCodeUsers?.map((el, index) => {
        return {
          ref: index + 1,
          name: el.customer?.fname + " " + el.customer?.lname,
          date: moment(el?.usedAt).format("DD/MM/YYYY"),
        };
      }) || []
    );
  };

  return (
    <>
      {useIsAuthorized("MANAGEMENT_TRAINER_CREATE") ? (
        <PageHeader
          buttonLink="/data/trainers/add_trainer"
          buttonText="Create"
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      ) : (
        <PageHeader
          filterElements={filterElements}
          setSelectedFilter={setSortBy}
          selectedFilter={sortBy}
          setSearchValue={setSearch}
        />
      )}

      <br />
      <TableContainer>
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No Trainers found !"
          loading={TrainersData.loading}
          numberOfFakeRow={30}
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>

      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("MANAGEMENT_TRAINER_DETAILS") && (
            <MenuItem
              onClick={handleCloseDetails}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Details</span>
              </Box>
            </MenuItem>
          )}
          {useIsAuthorized("MANAGEMENT_TRAINER_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple={true}
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}

          {useIsAuthorized("MANAGEMENT_TRAINER_DELETE") && (
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <Box onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>

      {/* DELETE TRAINER */}
      <Dialog
        open={open}
        onClose={handleCloseModal}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <Delete />
          <span className="alert_dialog_title_text">Delete Trainer ?</span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to delete this Trainer ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            className={ButtonsClasses.RedButton}
            onClick={DeleteTrainerHandler}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* LIST OF PROMOCODE USERS */}
      <Dialog
        open={openPromoCodeUsersModal}
        onClose={() => setOpenPromoCodeUsersModal(false)}
        className={DialogClasses.dialog_renew_container}
      >
        <BootstrapDialogTitle
          className={DialogClasses.alert_dialog_title}
          onClose={() => setOpenPromoCodeUsersModal(false)}
        >
          <span
            className="confirm_dialog_title_text"
            style={{ alignSelf: "flex-start" }}
          >
            Promocode Users List
          </span>
        </BootstrapDialogTitle>
        <DialogContent>
          <TableContainer>
            <Table
              columns={PromocodeUsersTableColumns}
              data={renderPromoCodeUsersTableRows()}
              emptyMessage="No users for this promocode !"
              loading={loading}
              numberOfFakeRow={30}
            />
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TrainersList;
