import { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
//material ui
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  IconButton,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import { DropzoneArea } from "material-ui-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
//styles
import {
  ContainerFullWidth,
  ContainerLeft,
  ContainersContent,
  LeftElement,
  useStylesContainers,
} from "../../../../styles/Containers__styles";
//mutations&queries
import {
  useEditTemplateMutation,
  useGetTemplateByIdQuery,
} from "../../../../graphql/types";
//components
import PageAddHeader from "../../../pageAddHeader/pageAddHeader";
//types
import { TemplateProps } from "../Types";
import { ApolloError } from "@apollo/client";
//utils
import { notificationTypesList } from "../Utils";
import { getMessageError } from "../../../Utils";

const UpdateTemplate = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //state
  const [openDialogEn, setOpenDialogEn] = useState(false);
  const [openDialogAr, setOpenDialogAr] = useState(false);
  //styles
  const ContainersClasses = useStylesContainers();

  //react-hook-form
  const methods = useForm<TemplateProps>({
    defaultValues: {
      nameEN: "",
      nameAR: "",
      type: null,
      bodyEn: "",
      bodyAr: "",
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = methods;

  const dataWatch = useWatch({
    control,
  });

  const handleRemoveFileEn = () => {
    setValue("bodyEn", "");
  };

  const handleRemoveFileAr = () => {
    setValue("bodyAr", "");
  };

  //mutations&queries
  const [updateTemplate, { loading }] = useEditTemplateMutation();
  const { data } = useGetTemplateByIdQuery({
    variables: {
      input: id || "",
    },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  const template = useMemo(
    () => data?.getTemplateById || null,
    [data?.getTemplateById]
  );

  //useEffects
  useEffect(() => {
    if (template) {
      setValue("nameAR", template.title?.AR || "");
      setValue("nameEN", template.title?.EN || "");
      setValue("type", template.type || null);
      setValue("bodyEn", template.body?.EN || "");
      setValue("bodyAr", template.body?.AR || "");
    }
  }, [template]);

  //functions
  const onSubmit: SubmitHandler<TemplateProps> = async (
    data: TemplateProps
  ) => {
    try {
      await updateTemplate({
        variables: {
          input: {
            _id: id || "",
            title: {
              EN: data.nameEN,
              AR: data.nameAR,
            },
            type: data.type!,
            body: {
              EN: data.bodyEn,
              AR: data.bodyAr,
            },
          },
        },
        onCompleted: () => {
          enqueueSnackbar("New Template succuessfully updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/data/notification_templates/list_notification_templates");
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageAddHeader
          title="Update Template"
          buttonText="Update Template"
          cancelButtonLink="/data/notification_templates/list_notification_templates"
          loading={loading}
        />
        <ContainersContent>
          <ContainerFullWidth>
            <FormProvider {...methods}>
              <ContainerLeft>
                <LeftElement>
                  <p className={ContainersClasses.section__title}>
                    Template Informations
                  </p>
                  <Box className={ContainersClasses.section__container}>
                    {/* Template Name(EN)*/}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Template Name(EN)
                      </p>
                      <TextField
                        placeholder="Template Name(EN)"
                        sx={{ width: "100%" }}
                        {...register("nameEN", {
                          required: "Enter Template Name",
                        })}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.nameEN?.message}
                      </p>
                    </Box>

                    {/* Template Name(AR)*/}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Template Name(AR)
                      </p>
                      <TextField
                        placeholder="Template Name(AR)"
                        sx={{ width: "100%" }}
                        {...register("nameAR", {
                          required: "Enter Template Name",
                        })}
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {errors?.nameAR?.message}
                      </p>
                    </Box>

                    {/* Type */}
                    <Box>
                      <p className={ContainersClasses.section__subtitle}>
                        Type
                      </p>
                      <Controller
                        name="type"
                        rules={{
                          required: "Select role",
                        }}
                        control={control}
                        render={({
                          field: { onChange: Change, value, ref, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <Autocomplete
                              ref={ref}
                              id="role"
                              options={notificationTypesList}
                              value={dataWatch?.type}
                              getOptionLabel={(option) => option || ""}
                              filterSelectedOptions
                              onChange={(_, data) => Change(data)}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    color="secondary"
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Type" />
                              )}
                            />
                            <p
                              className={
                                ContainersClasses.section__error__helper
                              }
                            >
                              {error?.message}
                            </p>
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                </LeftElement>
                <Box>
                  <p className={ContainersClasses.section__subtitle}>
                    Template Body(EN)
                  </p>
                  <Box
                    display={"flex"}
                    marginTop={"20px"}
                    marginBottom={"30px"}
                  >
                    <div>
                      {dataWatch.bodyEn ? (
                        <div>
                          <IconButton
                            style={{
                              backgroundColor: "black",
                              color: "white",
                              borderRadius: "50%",
                              marginRight: "20px",
                              marginLeft: "20px",
                            }}
                            onClick={handleRemoveFileEn}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => setOpenDialogEn(true)}
                            style={{
                              backgroundColor: "black",
                              color: "white",
                              borderRadius: "50%",
                            }}
                          >
                            <PreviewIcon />
                          </IconButton>
                        </div>
                      ) : (
                        <DropzoneArea
                          onChange={(files) => {
                            if (files && files.length) {
                              const reader = new FileReader();
                              reader.onload = function () {
                                const result = reader.result as string;
                                setValue("bodyEn", result);
                              };
                              reader.readAsText(files[0]);
                            }
                          }}
                          dropzoneText="Drag & Drop your HTML file here"
                          acceptedFiles={[".html"]}
                        />
                      )}

                      <Dialog open={openDialogEn}>
                        <IconButton
                          aria-label="close"
                          onClick={() => setOpenDialogEn(false)}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <iframe
                          srcDoc={dataWatch.bodyEn}
                          style={{ width: "1000px", height: "700px" }}
                        />
                      </Dialog>
                    </div>
                  </Box>

                  <p className={ContainersClasses.section__subtitle}>
                    Template Body(AR)
                  </p>
                  <Box
                    display={"flex"}
                    marginTop={"20px"}
                    marginBottom={"30px"}
                  >
                    <div>
                      {dataWatch.bodyAr ? (
                        <div>
                          <IconButton
                            style={{
                              backgroundColor: "black",
                              color: "white",
                              borderRadius: "50%",
                              marginRight: "20px",
                              marginLeft: "20px",
                            }}
                            onClick={handleRemoveFileAr}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => setOpenDialogAr(true)}
                            style={{
                              backgroundColor: "black",
                              color: "white",
                              borderRadius: "50%",
                            }}
                          >
                            <PreviewIcon />
                          </IconButton>
                        </div>
                      ) : (
                        <DropzoneArea
                          onChange={(files) => {
                            if (files && files.length) {
                              const reader = new FileReader();
                              reader.onload = function () {
                                const result = reader.result as string;
                                setValue("bodyEn", result);
                              };
                              reader.readAsText(files[0]);
                            }
                          }}
                          dropzoneText="Drag & Drop your HTML file here"
                          acceptedFiles={[".html"]}
                        />
                      )}
                      <Dialog open={openDialogAr}>
                        <IconButton
                          aria-label="close"
                          onClick={() => setOpenDialogAr(false)}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <iframe
                          srcDoc={dataWatch.bodyAr}
                          style={{ width: "1000px", height: "700px" }}
                        />
                      </Dialog>
                    </div>
                  </Box>
                </Box>
              </ContainerLeft>
            </FormProvider>
          </ContainerFullWidth>
        </ContainersContent>
      </form>
    </FormProvider>
  );
};

export default UpdateTemplate;
