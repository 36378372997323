import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import moment from "moment";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
//material
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import { Delete } from "@mui/icons-material";
import DiscountIcon from "@mui/icons-material/Discount";
//styles
import {
  useStylesCustomers,
  LeftContainer,
  RightContainer,
} from "../../../styles/Customers__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesLeads } from "../../../styles/Leads__styles";
import { StyledSpan } from "../viewCustomer/ViewCustomer.styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
//queries & mutations
import {
  useGetCustomerProfileByIdQuery,
  Gender,
  HeightPayload,
  WeightPayload,
  Activity,
  QuizPayload,
  CustumerStatus,
  useUpdateProfileInfoMutation,
  GetCustomerProfileByIdDocument,
  useActivateDesactivateCustomerMutation,
  useResetProfileMutation,
  useGetSalesAndSupportsListQuery,
  useAddAssignedAgentToCustomerMutation,
  UserPayload,
  useGetCustomerEventsLazyQuery,
} from "../../../graphql/types";
//utils
import {
  handleGender,
  handleUnits,
  handlePlanCondition,
  getMessageError,
} from "../../Utils";
import { heightOptions, weightOptions } from "../Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { useCountryPrefix } from "../../../utils/Hooks/useCountryPrefix";
//components
import PhoneInput from "../../layout/PhoneInput";
import { countries } from "../../../staticData/staticData";
import CustomSwitch from "../../customSwitch/CustomSwitch";
import EntryGiftVoucherPrint from "./EntryGiftVoucherPrint";
//types
import { ApolloError } from "@apollo/client";
//icons
import Ios from "../../../public/icons/Ios";
import Android from "../../../public/icons/Android";

interface ProfileStatusProps {
  reason: string;
  from: Date | null;
}

type stringsTypes = string | null | undefined;

interface customerProps {
  fname: stringsTypes;
  lname: stringsTypes;
  gender: Gender | null | undefined;
  dob: Date;
  number: string;
  prefix: string;
  email: stringsTypes;
  height: HeightPayload;
  weight: WeightPayload;
  activity: Activity;
  calories: string;
  quiz: QuizPayload;
  picture: string;
  nationality: string;
  customerStatus: CustumerStatus;
  withCutlery: boolean | null | undefined;
  cutleryNumber: number;
  influencer: boolean | null | undefined;
  whatsAppAutomation: boolean | null | undefined;
  whatsappDriverNotification: boolean | null | undefined;
}

const ProfileDetails = () => {
  const printRef = useRef<HTMLDivElement>(null);
  //activate desactivate customer
  const controlRef = useRef();
  const segment1Ref = useRef();
  const segment2Ref = useRef();
  const segments = [
    {
      label: "Activate",
      value: "Activate",
      ref: segment1Ref,
    },
    {
      label: "Long Pause",
      value: "Long Pause",
      ref: segment2Ref,
    },
  ];
  //styles
  const CustomerClasses = useStylesCustomers();
  const ButtonClasses = useStylesButtons();
  const ButtonsClasses = useStylesButtons();
  const LeadsClasses = useStylesLeads();
  const ContainersClasses = useStylesContainers();
  const DialogClasses = useStylesDialog();

  //states
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [Cultery, setCultery] = useState<boolean>();
  const [openWipeDataModal, setOpenWipeDataModal] = useState<boolean>(false);
  const [selectedAgentName, setSelectedAgentName] = useState<UserPayload>();

  // react router params
  const params = useParams();

  //snack
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //hook
  const isAuthorized = useIsAuthorized("ADD_ASSIGNED_TO_TOCUSTOMERS");

  //queries&&mutations
  const [wipeCustomerData] = useResetProfileMutation();

  const [AddAssignedAgentToCustomer] = useAddAssignedAgentToCustomerMutation();

  const { data } = useGetCustomerProfileByIdQuery({
    variables: {
      customerId: params.id || "",
    },
    fetchPolicy: "network-only",
    skip: !params.id,
  });

  const [
    getCustomerVoucher,
    { data: voucherData, loading: CustomerVoucherLoading },
  ] = useGetCustomerEventsLazyQuery();

  const salesSupportListDate = useGetSalesAndSupportsListQuery();

  const assignedToList = useMemo(
    () => salesSupportListDate.data?.getSalesAndSupportsList || [],
    [salesSupportListDate]
  );

  const countryPrefix = useCountryPrefix();

  //consts
  const customerDetails = useMemo(
    () => data?.getCustomerProfileById || null,
    [data?.getCustomerProfileById]
  );

  //react-hook-form
  const methods = useForm<customerProps>({
    defaultValues: {
      fname: customerDetails?.fname || "",
      number: "",
      prefix: countryPrefix?.callingCode,
      nationality: "",
      dob: new Date(),
    },
  });

  const methodsProfileStatus = useForm<ProfileStatusProps>({
    defaultValues: {
      reason: "",
      from: null,
    },
  });

  //watch
  const dataWatch = methods.watch();
  const NationalityWatch = methods.watch("nationality");

  //functions
  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleActionActivation = () => {
    if (customerDetails?.desactivatedAt && selectedValue === "Long Pause") {
      return true;
    } else if (
      !customerDetails?.desactivatedAt &&
      selectedValue === "Activate"
    ) {
      return true;
    }
    return false;
  };

  //useEffects
  useEffect(() => {
    if (customerDetails?.withCutlery) {
      setCultery(customerDetails?.withCutlery);
    }
  }, [customerDetails?.withCutlery]);

  useEffect(() => {
    if (customerDetails?.userAssignedTo) {
      setSelectedAgentName(customerDetails?.userAssignedTo!);
    }
  }, [customerDetails?.userAssignedTo]);

  useEffect(() => {
    if (data) {
      methods.setValue("fname", data?.getCustomerProfileById?.fname);
      methods.setValue("lname", data?.getCustomerProfileById?.lname);
      methods.setValue(
        "withCutlery",
        data?.getCustomerProfileById?.withCutlery
      );
      methods.setValue(
        "cutleryNumber",
        data?.getCustomerProfileById?.cutleryNumber || 0
      );
      methods.setValue("influencer", data?.getCustomerProfileById?.influencer);
      methods.setValue(
        "whatsAppAutomation",
        data?.getCustomerProfileById?.whatsappAutomation
      );
      methods.setValue(
        "whatsappDriverNotification",
        data?.getCustomerProfileById?.whatsappDriverNotification
      );
      methods.setValue(
        "prefix",
        String(data?.getCustomerProfileById?.phone?.prefix).replace("+", "") ||
          ""
      );
      methods.setValue(
        "number",
        data?.getCustomerProfileById?.phone?.number?.replace(/\s/g, "") || ""
      );
      methods.setValue("email", data?.getCustomerProfileById?.email);
      methods.setValue(
        "nationality",
        countries?.find(
          (el) => el === data?.getCustomerProfileById?.nationality
        ) ?? ""
      );
      methods.setValue(
        "dob",
        new Date(data?.getCustomerProfileById?.dob || "")
      );
      methods.setValue("gender", data?.getCustomerProfileById?.gender);
      methods.setValue(
        "height.value",
        Number(data?.getCustomerProfileById?.height?.value)
      );
      methods.setValue(
        "height.unit",
        data?.getCustomerProfileById?.height?.unit
      );
      methods.setValue(
        "weight.value",
        Number(data?.getCustomerProfileById?.weight?.value)
      );
      methods.setValue(
        "weight.unit",
        data?.getCustomerProfileById?.weight?.unit
      );
      setSelectedValue(
        customerDetails?.desactivatedAt ? "Long Pause" : "Activate"
      );
      setActiveIndex(customerDetails?.desactivatedAt ? 1 : 0);
    }
  }, [data]);

  //mutations
  const [updateCustomer] = useUpdateProfileInfoMutation();
  const [handleActivation, upadteActivation] =
    useActivateDesactivateCustomerMutation();

  //functions
  const onSubmitClickSaveActivation = async (data: ProfileStatusProps) => {
    try {
      await handleActivation({
        variables: {
          activateDesactivateCustomerInput: {
            activate: selectedValue === "Activate",
            customerId: customerDetails?._id || "",
            from: data?.from ?? new Date(),
            reason: data?.reason,
          },
        },
        refetchQueries: [
          {
            query: GetCustomerProfileByIdDocument,
            variables: {
              customerId: params.id || "",
            },
          },
        ],
        onCompleted: () => {
          methodsProfileStatus.reset();
          if (selectedValue === "Activate") {
            enqueueSnackbar("Customer Plan Activated successfully", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
          } else if (selectedValue === "Long Pause") {
            enqueueSnackbar("Customer Plan Paused successfully", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
          }
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleChangeAssignAgents = async (agent: UserPayload) => {
    try {
      await AddAssignedAgentToCustomer({
        variables: {
          input: {
            customerId: customerDetails?._id || "",
            agentId: agent?._id ?? "",
          },
        },
        onCompleted: () => {
          enqueueSnackbar("Agent successfully Updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const onSubmit = async (data: customerProps) => {
    const phone = data.number.replace(/\s/g, "");
    try {
      await updateCustomer({
        variables: {
          updateProfileInfoInput: {
            id: params.id || "",
            fname: data.fname,
            lname: data.lname,
            gender: data.gender,
            withCutlery: data.withCutlery,
            cutleryNumber: +data.cutleryNumber,
            influencer: data.influencer,
            dob: data.dob,
            whatsappAutomation: data.whatsAppAutomation,
            whatsappDriverNotification: data.whatsappDriverNotification,
            phone: {
              number: phone,
              prefix: `+${data.prefix}`,
            },
            height: {
              value: Number(data.height.value),
              unit: data.height.unit,
            },
            weight: {
              value: Number(data.weight.value),
              unit: data.weight.unit,
            },
            nationality: data.nationality,
            email: data.email,
          },
        },
        refetchQueries: [
          {
            query: GetCustomerProfileByIdDocument,
            variables: {
              customerId: params.id || "",
            },
          },
        ],
        onCompleted: () => {
          setEditMode(false);
          enqueueSnackbar("Customer Informations succuessfully updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleCloseModal = () => {
    setOpenWipeDataModal(false);
  };

  const wipeCustomerDataHandler = async () => {
    setOpenWipeDataModal(false);
    await wipeCustomerData({
      variables: { input: params.id || "" },
      refetchQueries: [GetCustomerProfileByIdDocument],
      onCompleted() {
        enqueueSnackbar("Customer Data wiped succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    documentTitle:
      "Entry Gift Voucher for " + dataWatch.fname + " " + dataWatch.lname,
  });

  const onGenerateVoucherHandler = () => {
    getCustomerVoucher({
      variables: {
        customerId: params.id || "",
      },
      onCompleted() {
        handlePrint();
      },
    });
  };

  //render
  return (
    <>
      <FormProvider {...methods}>
        <Box display="flex" gap="20px">
          <LeftContainer>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box
                  style={{
                    display: "flex",
                    gap: "5px",
                    flexDirection: "column",
                  }}
                >
                  <p className={CustomerClasses.customer__id}>
                    Dieture ID Number
                    <p className={CustomerClasses.customer__id_value}>
                      #{customerDetails?.ref}
                    </p>
                  </p>
                </Box>
                <Box display={"flex"} gap={"0 10px"}>
                  <Button
                    variant="contained"
                    className={ButtonClasses.button_submit_form}
                    onClick={onGenerateVoucherHandler}
                    disabled={CustomerVoucherLoading}
                    style={{ height: "fit-content" }}
                  >
                    <DiscountIcon />
                  </Button>

                  {useIsAuthorized("UPDATE_CUSTOMER") && (
                    <Button
                      variant="contained"
                      className={ButtonClasses.button_submit_form}
                      type={editMode ? "button" : "submit"}
                      onClick={handleEditMode}
                      style={{ height: "fit-content" }}
                    >
                      {!editMode ? "Edit" : "Save"}
                    </Button>
                  )}

                  {editMode && (
                    <Button
                      variant="contained"
                      className={ButtonClasses.button_submit_form}
                      onClick={handleEditMode}
                      style={{ height: "fit-content" }}
                    >
                      cancel
                    </Button>
                  )}

                  {useIsAuthorized("UPDATE_CUSTOMER") && (
                    <Button
                      variant="contained"
                      className={ButtonClasses.button_submit_form}
                      onClick={() => setOpenWipeDataModal(true)}
                      style={{ height: "fit-content" }}
                    >
                      Wipe customer data
                    </Button>
                  )}

                  {data?.getCustomerProfileById.deviceOS === "IOS" && <Ios />}
                  {data?.getCustomerProfileById.deviceOS === "ANDROID" && (
                    <Android />
                  )}
                </Box>
              </Box>
              <Box
                className={CustomerClasses.customer__units}
                justifyContent={"end"}
              >
                <Box color={"grey"}>Assigned to&nbsp; : &nbsp;</Box>
                {isAuthorized ? (
                  <>
                    <Autocomplete
                      sx={{ width: "20%" }}
                      size="small"
                      options={assignedToList}
                      getOptionLabel={(option) =>
                        option.fname + " " + option.lname
                      }
                      value={{
                        fname: selectedAgentName?.fname ?? "",
                        lname: selectedAgentName?.lname ?? "",
                      }}
                      filterSelectedOptions
                      onChange={(_, data) => {
                        setSelectedAgentName(data);
                        handleChangeAssignAgents(data);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      disableClearable
                    />
                  </>
                ) : (
                  <span>
                    {(customerDetails?.userAssignedTo?.fname ?? "") +
                      " " +
                      (customerDetails?.userAssignedTo?.lname ?? "")}
                  </span>
                )}
              </Box>
              <Box className={CustomerClasses.profile__container}>
                <Box marginTop={4}>
                  <Box className={CustomerClasses.customer__units}>
                    <Box
                      width={160}
                      marginRight={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <p className={CustomerClasses.customer__subtitle}>
                        First Name
                      </p>
                      <span>:</span>
                    </Box>
                    <Box width={400}>
                      {editMode ? (
                        <TextField
                          id="demo-helper-text-misaligned"
                          placeholder="First Name"
                          size="small"
                          sx={{ width: "100%" }}
                          {...methods.register("fname", {
                            required: "First Name is Mandatory",
                          })}
                        />
                      ) : (
                        <p className={CustomerClasses.customer__subtext}>
                          {customerDetails?.fname &&
                            customerDetails?.fname
                              ?.charAt(0)
                              .toLocaleUpperCase() +
                              customerDetails?.fname?.slice(1)}
                        </p>
                      )}
                    </Box>
                  </Box>
                  <Box className={CustomerClasses.customer__units}>
                    <Box
                      width={160}
                      marginRight={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <p className={CustomerClasses.customer__subtitle}>
                        Last Name
                      </p>
                      <span>:</span>
                    </Box>
                    <Box width={400}>
                      {editMode ? (
                        <TextField
                          id="demo-helper-text-misaligned"
                          placeholder="Last Name"
                          size="small"
                          sx={{ width: "100%" }}
                          {...methods.register("lname", {
                            required: "Last Name is Mandatory",
                          })}
                        />
                      ) : (
                        <p className={CustomerClasses.customer__subtext}>
                          {customerDetails?.lname &&
                            customerDetails?.lname
                              ?.charAt(0)
                              .toLocaleUpperCase() +
                              customerDetails?.lname?.slice(1)}
                        </p>
                      )}
                    </Box>
                  </Box>
                  <Box className={CustomerClasses.customer__units}>
                    <Box
                      width={160}
                      marginRight={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <p className={CustomerClasses.customer__subtitle}>
                        Mobile
                      </p>
                      <span>:</span>
                    </Box>
                    <Box width={400}>
                      {editMode ? (
                        <PhoneInput
                          labelFormPrefix="prefix"
                          labelFormNumber="number"
                          placeholder="Phone Number"
                          size="small"
                          stylePhoneNumber={{ width: "100%" }}
                          showErrorMessage={false}
                        />
                      ) : (
                        <p className={CustomerClasses.customer__subtext}>
                          {customerDetails?.phone?.prefix}{" "}
                          {customerDetails?.phone?.number}
                        </p>
                      )}
                    </Box>
                  </Box>
                  <Box className={CustomerClasses.customer__units}>
                    <Box
                      width={160}
                      marginRight={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <p className={CustomerClasses.customer__subtitle}>
                        Email
                      </p>
                      <span>:</span>
                    </Box>
                    <Box width={400}>
                      <>
                        {editMode ? (
                          <TextField
                            placeholder="Email"
                            size="small"
                            sx={{ width: "100%" }}
                            {...methods.register("email", {
                              required: "Email is Mandatory",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Enter a valid Email",
                              },
                            })}
                          />
                        ) : (
                          <>
                            {customerDetails?.email ? (
                              <p className={CustomerClasses.customer__subtext}>
                                {customerDetails?.email}
                              </p>
                            ) : (
                              <p className={CustomerClasses.customer__subtext}>
                                Email not specified
                              </p>
                            )}
                          </>
                        )}
                      </>
                    </Box>
                  </Box>

                  <Box marginRight={1}>
                    <Box className={CustomerClasses.customer__units}>
                      <Box
                        width={160}
                        marginRight={1}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <p className={CustomerClasses.customer__subtitle}>
                          Nationality
                        </p>
                        <span>:</span>
                      </Box>
                      <Box width={400}>
                        {editMode ? (
                          <Controller
                            name="nationality"
                            control={methods.control}
                            render={({
                              field: { onChange: Change, value, ref, ...rest },
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                {...rest}
                                size="small"
                                freeSolo
                                options={countries}
                                value={NationalityWatch}
                                disableCloseOnSelect
                                onChange={(_, data) => Change(data)}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputRef={ref}
                                    variant="outlined"
                                    sx={{ width: "100%" }}
                                    placeholder="Nationality"
                                  />
                                )}
                              />
                            )}
                          />
                        ) : (
                          <>
                            {customerDetails?.nationality ? (
                              <p className={CustomerClasses.customer__subtext}>
                                {customerDetails?.nationality}
                              </p>
                            ) : (
                              <p className={CustomerClasses.customer__subtext}>
                                Nationality not specified
                              </p>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box className={CustomerClasses.customer__units}>
                      <Box
                        width={160}
                        marginRight={1}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <p className={CustomerClasses.customer__subtitle}>
                          D.O.B
                        </p>
                        <span>:</span>
                      </Box>
                      <Box width={400}>
                        {editMode ? (
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <Controller
                              name="dob"
                              control={methods.control}
                              rules={{
                                validate: (value) =>
                                  value === null ||
                                  moment(value).isValid() ||
                                  "Enter a valid Birth Date",
                              }}
                              render={({ field: { ref, ...rest } }) => (
                                <DesktopDatePicker
                                  {...rest}
                                  inputFormat="DD/MM/YYYY"
                                  disablePast={false}
                                  disableFuture={true}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{ width: "100%" }}
                                      size="small"
                                    />
                                  )}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        ) : (
                          <>
                            {customerDetails?.dob ? (
                              <p className={CustomerClasses.customer__subtext}>
                                {moment(customerDetails?.dob).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            ) : (
                              <p className={CustomerClasses.customer__subtext}>
                                Date of Birth not specified
                              </p>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box className={CustomerClasses.customer__units}>
                      <Box
                        width={160}
                        marginRight={1}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <p className={CustomerClasses.customer__subtitle}>
                          Gender
                        </p>
                        <span>:</span>
                      </Box>

                      <Box width={400}>
                        {editMode ? (
                          <Controller
                            name="gender"
                            control={methods.control}
                            render={({
                              field: { onChange: Change, value, ref, ...rest },
                            }) => (
                              <RadioGroup
                                row
                                defaultValue={methods.watch("gender")}
                              >
                                <FormControlLabel
                                  value="MALE"
                                  control={<Radio />}
                                  label="Male"
                                  onClick={() =>
                                    methods.setValue("gender", "MALE")
                                  }
                                />
                                <FormControlLabel
                                  value="FEMALE"
                                  control={<Radio />}
                                  label="Female"
                                  onClick={() =>
                                    methods.setValue("gender", "FEMALE")
                                  }
                                />
                              </RadioGroup>
                            )}
                          />
                        ) : (
                          <>
                            {customerDetails?.gender ? (
                              <p className={CustomerClasses.customer__subtext}>
                                {handleGender(customerDetails?.gender)}
                              </p>
                            ) : (
                              <p className={CustomerClasses.customer__subtext}>
                                Gender not specified
                              </p>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>

                    {/* WITH CUTLERY */}
                    <Box className={CustomerClasses.customer__units}>
                      <Box
                        width={160}
                        marginRight={1}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <p className={CustomerClasses.customer__subtitle}>
                          with Cutlery
                        </p>
                        <span>:</span>
                      </Box>
                      <Box width={400}>
                        {editMode ? (
                          <Controller
                            name="withCutlery"
                            control={methods.control}
                            render={({
                              field: { onChange: Change, value, ref, ...rest },
                            }) => (
                              <RadioGroup
                                row
                                defaultValue={methods.watch("withCutlery")}
                              >
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                  label="No"
                                  onClick={() =>
                                    methods.setValue("withCutlery", false)
                                  }
                                />
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                  label="Yes"
                                  onClick={() =>
                                    methods.setValue("withCutlery", true)
                                  }
                                />
                              </RadioGroup>
                            )}
                          />
                        ) : (
                          <>
                            <p className={CustomerClasses.customer__subtext}>
                              {Cultery ? "Yes" : "No"}
                            </p>
                          </>
                        )}
                      </Box>
                    </Box>

                    {/* NUMBER OF CUTLERY POUCHES */}
                    <Box className={CustomerClasses.customer__units}>
                      <Box
                        width={160}
                        marginRight={1}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <p className={CustomerClasses.customer__subtitle}>
                          Cutlery Number
                        </p>
                        <span>:</span>
                      </Box>
                      <Box width={400}>
                        {editMode ? (
                          <>
                            <TextField
                              placeholder="Number of Cutlery Pouches"
                              sx={{ width: "69%" }}
                              size="small"
                              type="number"
                              {...methods.register("cutleryNumber", {
                                pattern: {
                                  value: /^\d*$/,
                                  message:
                                    "Enter a valid Number for cutlery pouches",
                                },
                              })}
                            />
                          </>
                        ) : (
                          <>
                            {customerDetails?.withCutlery ? (
                              <p className={CustomerClasses.customer__subtext}>
                                {`${customerDetails?.cutleryNumber}`}
                              </p>
                            ) : (
                              <p className={CustomerClasses.customer__subtext}>
                                -----
                              </p>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>

                    {/* HEIGHT */}
                    <Box className={CustomerClasses.customer__units}>
                      <Box
                        width={160}
                        marginRight={1}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <p className={CustomerClasses.customer__subtitle}>
                          Height
                        </p>
                        <span>:</span>
                      </Box>
                      <Box width={400}>
                        {editMode ? (
                          <>
                            <TextField
                              placeholder="Height"
                              sx={{ width: "69%" }}
                              size="small"
                              type="number"
                              {...methods.register("height.value", {
                                pattern: {
                                  value: /^\d$/,
                                  message: "Enter a valid Height",
                                },
                              })}
                            />

                            <Controller
                              name="height.unit"
                              control={methods.control}
                              render={({ field: { ref, ...rest } }) => (
                                <Select
                                  {...rest}
                                  size="small"
                                  sx={{ width: "29%", marginLeft: "8px" }}
                                  className={LeadsClasses.select_container}
                                >
                                  {heightOptions.map((option) => (
                                    <MenuItem
                                      key={option.menuValue}
                                      onClick={() =>
                                        methods.setValue(
                                          "height.unit",
                                          option.menuValue as any
                                        )
                                      }
                                      value={option.menuValue}
                                      className={LeadsClasses.option_item}
                                    >
                                      {option.menuText}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                          </>
                        ) : (
                          <>
                            {customerDetails?.height?.value ? (
                              <p className={CustomerClasses.customer__subtext}>
                                {`${customerDetails?.height?.value}
                ${handleUnits(customerDetails?.height?.unit)}`}
                              </p>
                            ) : (
                              <p className={CustomerClasses.customer__subtext}>
                                Height not specified
                              </p>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>

                    {/* WEIGHT */}
                    <Box className={CustomerClasses.customer__units}>
                      <Box
                        width={160}
                        marginRight={1}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <p className={CustomerClasses.customer__subtitle}>
                          Weight
                        </p>
                        <span>:</span>
                      </Box>
                      <Box width={400}>
                        {editMode ? (
                          <>
                            {" "}
                            <TextField
                              placeholder="Weigth"
                              sx={{ width: "69%" }}
                              size="small"
                              type="number"
                              {...methods.register("weight.value", {
                                pattern: {
                                  value: /^\d*$/,
                                  message: "Enter a valid Weigth",
                                },
                              })}
                            />
                            <Controller
                              name="weight.unit"
                              control={methods.control}
                              render={({ field: { ref, ...rest } }) => (
                                <Select
                                  {...rest}
                                  size="small"
                                  sx={{ width: "29%", marginLeft: "8px" }}
                                  className={LeadsClasses.select_container}
                                >
                                  {weightOptions.map((option) => (
                                    <MenuItem
                                      key={option.menuValue}
                                      onClick={() =>
                                        methods.setValue(
                                          "weight.unit",
                                          option.menuValue as any
                                        )
                                      }
                                      value={option.menuValue}
                                      className={LeadsClasses.option_item}
                                    >
                                      {option.menuText}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />{" "}
                          </>
                        ) : (
                          <>
                            {customerDetails?.weight?.value ? (
                              <p className={CustomerClasses.customer__subtext}>
                                {`${customerDetails?.weight?.value}
                    ${handleUnits(customerDetails?.weight?.unit)}`}
                              </p>
                            ) : (
                              <p className={CustomerClasses.customer__subtext}>
                                Weight not specified
                              </p>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>

                  <Box className={CustomerClasses.customer__units}>
                    <Box
                      width={160}
                      marginRight={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <p className={CustomerClasses.customer__subtitle}>
                        Status
                      </p>
                      <span>:</span>
                    </Box>

                    <StyledSpan
                      color={
                        handlePlanCondition(
                          customerDetails?.plan?.planCondition
                        ).color
                      }
                    >
                      {customerDetails?.plan?.planCondition
                        ? handlePlanCondition(
                            customerDetails?.plan?.planCondition
                          ).text
                        : "No plan"}
                    </StyledSpan>
                  </Box>
                  <Box className={CustomerClasses.customer__units}>
                    <Box
                      width={160}
                      marginRight={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <p className={CustomerClasses.customer__subtitle}>
                        Current meal plan
                      </p>
                      <span>:</span>
                    </Box>
                    {customerDetails?.plan?.program && (
                      <p className={CustomerClasses.customer__subtext}>
                        {customerDetails?.plan?.program?.name?.EN}
                      </p>
                    )}
                  </Box>

                  {/* INFLUENCER */}
                  <Box className={CustomerClasses.customer__units}>
                    <Box
                      width={160}
                      marginRight={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <p className={CustomerClasses.customer__subtitle}>
                        Influencer
                      </p>
                      <span>:</span>
                    </Box>
                    <Box width={400}>
                      {editMode ? (
                        <Controller
                          name="influencer"
                          control={methods.control}
                          render={({
                            field: { onChange: Change, value, ref, ...rest },
                          }) => (
                            <RadioGroup
                              row
                              defaultValue={methods.watch("influencer")}
                            >
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                                onClick={() =>
                                  methods.setValue("influencer", false)
                                }
                              />
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                                onClick={() =>
                                  methods.setValue("influencer", true)
                                }
                              />
                            </RadioGroup>
                          )}
                        />
                      ) : (
                        <>{customerDetails?.influencer ? "Yes" : "No"}</>
                      )}
                    </Box>
                  </Box>

                  {/* WHATSAPP AUTOMATION */}
                  <Box className={CustomerClasses.customer__units}>
                    <Box
                      width={160}
                      marginRight={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <p className={CustomerClasses.customer__subtitle}>
                        WhatsApp Automation
                      </p>
                      <span>:</span>
                    </Box>
                    <Box width={400}>
                      {editMode ? (
                        <Controller
                          name="whatsAppAutomation"
                          control={methods.control}
                          render={({
                            field: { onChange: Change, value, ref, ...rest },
                          }) => (
                            <RadioGroup
                              row
                              defaultValue={methods.watch("whatsAppAutomation")}
                            >
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                                onClick={() =>
                                  methods.setValue("whatsAppAutomation", false)
                                }
                              />
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                                onClick={() =>
                                  methods.setValue("whatsAppAutomation", true)
                                }
                              />
                            </RadioGroup>
                          )}
                        />
                      ) : (
                        <>
                          {customerDetails?.whatsappAutomation ? "Yes" : "No"}
                        </>
                      )}
                    </Box>
                  </Box>
                  {/* WHATSAPP DRIVER NOTIFICATION */}
                  <Box className={CustomerClasses.customer__units}>
                    <Box
                      width={160}
                      marginRight={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <p className={CustomerClasses.customer__subtitle}>
                        WhatsApp Driver Notification
                      </p>
                      <span>:</span>
                    </Box>
                    <Box width={400}>
                      {editMode ? (
                        <Controller
                          name="whatsappDriverNotification"
                          control={methods.control}
                          render={({
                            field: { onChange: Change, value, ref, ...rest },
                          }) => (
                            <RadioGroup
                              row
                              defaultValue={methods.watch(
                                "whatsappDriverNotification"
                              )}
                            >
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                                onClick={() =>
                                  methods.setValue(
                                    "whatsappDriverNotification",
                                    false
                                  )
                                }
                              />
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                                onClick={() =>
                                  methods.setValue(
                                    "whatsappDriverNotification",
                                    true
                                  )
                                }
                              />
                            </RadioGroup>
                          )}
                        />
                      ) : (
                        <>
                          {customerDetails?.whatsappDriverNotification
                            ? "Yes"
                            : "No"}
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </LeftContainer>

          <RightContainer>
            <FormProvider {...methodsProfileStatus}>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                }}
                onSubmit={methodsProfileStatus.handleSubmit(
                  onSubmitClickSaveActivation
                )}
              >
                <CustomSwitch
                  name="group-1"
                  callback={(val) => setSelectedValue(val)}
                  controlRef={controlRef}
                  defaultIndex={customerDetails?.desactivatedAt ? 1 : 0}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  segments={segments}
                />
                <FormControl>
                  <label
                    className={CustomerClasses.customer__subtitle}
                    style={{ marginBottom: "4px" }}
                  >
                    Reason :
                  </label>
                  <TextField
                    id="reason"
                    placeholder="Reason"
                    multiline
                    rows={3}
                    sx={{ width: "100%" }}
                    {...methodsProfileStatus.register("reason", {
                      required: {
                        value: true,
                        message: "Field required",
                      },
                    })}
                  />
                  <p className={ContainersClasses.section__error__helper}>
                    {methodsProfileStatus.formState.errors?.reason?.message}
                  </p>
                </FormControl>
                <Box>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    From :
                  </p>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <Controller
                      name="from"
                      control={methodsProfileStatus.control}
                      defaultValue={null}
                      rules={{
                        validate: (value) =>
                          value === null ||
                          moment(value).isValid() ||
                          "Enter a valid Date",
                        required: {
                          value: true,
                          message: "Field required",
                        },
                      }}
                      render={({ field: { ref, ...rest } }) => (
                        <DesktopDatePicker
                          {...rest}
                          inputFormat="DD/MM/YYYY"
                          disablePast={true}
                          disableFuture={false}
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: "100%" }} />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {methodsProfileStatus.formState.errors?.from?.message}
                  </p>
                </Box>
                <Button
                  variant="contained"
                  className={ButtonClasses.button_submit_form}
                  type="submit"
                  style={{ width: "fit-content", marginLeft: "auto" }}
                  // onClick={handleEditMode}
                  disabled={handleActionActivation()}
                >
                  {upadteActivation.loading && (
                    <CircularProgress
                      size={20}
                      style={{ marginRight: 10, color: "white" }}
                    />
                  )}
                  Save
                </Button>
              </form>
            </FormProvider>
          </RightContainer>

          {/* Wipe Customer data */}
          <Dialog
            open={openWipeDataModal}
            onClose={handleCloseModal}
            className={DialogClasses.dialog_container}
          >
            <DialogTitle className={DialogClasses.alert_dialog_title}>
              <Delete />
              <span className="alert_dialog_title_text">
                Wipe This customer Profile Data ?
              </span>
            </DialogTitle>
            <DialogContent>
              <Typography color="black">
                Are you sure you want to wipe this customert Data ? that would
                delete all his plans, boxes ,addresses , transactions , ratings
                and addons orders while transforming him back to a lead.
              </Typography>
            </DialogContent>
            <DialogActions className={DialogClasses.alert_dialog_actions}>
              <Button
                className={ButtonsClasses.GreyButton}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                className={ButtonsClasses.RedButton}
                onClick={wipeCustomerDataHandler}
                color="primary"
                autoFocus
              >
                Wipe Data
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </FormProvider>

      {/* VOUCHER PRINT */}
      <div style={{ display: "none" }}>
        <EntryGiftVoucherPrint
          customerEvents={voucherData?.getCustomerEvents}
          ref={printRef}
        />
      </div>
    </>
  );
};

export default ProfileDetails;
