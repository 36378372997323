import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Customer",
    accessor: "customer",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Image",
    accessor: "image",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];
