function TotalNumberOfCustomers() {
  return (
    <svg
      width="37"
      height="26"
      viewBox="0 0 74 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_394_60777)">
        <path
          d="M37.219 27.4337C31.7855 27.4337 27.3438 23.0255 27.3438 17.6329V9.80082C27.3438 4.40823 31.7855 0 37.219 0C42.6526 0 47.0943 4.40823 47.0943 9.80082V17.6329C47.0943 23.0255 42.6526 27.4337 37.219 27.4337ZM37.219 2.13992C32.9498 2.13992 29.4999 5.56379 29.4999 9.80082V17.6329C29.4999 21.87 32.9498 25.2938 37.219 25.2938C41.4883 25.2938 44.9382 21.87 44.9382 17.6329V9.80082C44.9382 5.56379 41.4883 2.13992 37.219 2.13992Z"
          fill="#fff"
        />
        <path
          d="M48.0815 51.9994H25.916C22.0349 51.9994 18.8438 48.8751 18.8438 44.9805V36.8916C18.8438 35.308 19.8356 33.7245 21.7762 32.1838C23.3717 30.9426 25.2692 30.001 27.4253 29.4019L34.282 27.4759C34.8426 27.3047 35.4463 27.6471 35.6188 28.2035C35.7913 28.7599 35.4463 29.3591 34.8857 29.5303L28.0291 31.4562C26.1748 31.9698 24.5361 32.7829 23.1561 33.8529C22.1643 34.6233 21.0431 35.736 21.0431 36.8488V44.9377C21.0431 47.634 23.2424 49.8167 25.9591 49.8167H48.1246C50.8414 49.8167 53.0407 47.634 53.0407 44.9377V36.8488C53.0407 35.736 51.8764 34.5805 50.9277 33.8529C49.5477 32.7829 47.909 31.9698 46.0547 31.4562L39.3706 29.6159C38.81 29.4447 38.465 28.8883 38.6375 28.2891C38.81 27.7327 39.4137 27.3903 39.9743 27.5615L46.6584 29.4019C48.8146 30.001 50.7121 30.9426 52.3076 32.1838C54.2482 33.7245 55.24 35.308 55.24 36.8916V44.9805C55.24 48.8323 52.092 51.9994 48.1678 51.9994H48.0815Z"
          fill="#fff"
        />
        <path
          d="M34.625 29.6584C34.0212 29.6584 33.5469 29.1876 33.5469 28.5884V26.3629C33.5469 25.7638 34.0212 25.293 34.625 25.293C35.2287 25.293 35.7031 25.7638 35.7031 26.3629V28.5884C35.7031 29.1876 35.2287 29.6584 34.625 29.6584Z"
          fill="#fff"
        />
        <path
          d="M39.5859 29.701C38.9822 29.701 38.5078 29.2302 38.5078 28.6311V26.32C38.5078 25.7208 38.9822 25.25 39.5859 25.25C40.1896 25.25 40.664 25.7208 40.664 26.32V28.6311C40.664 29.2302 40.1896 29.701 39.5859 29.701Z"
          fill="#fff"
        />
        <path
          d="M37.0029 38.7748C33.553 38.7748 30.75 35.137 30.75 30.6859C30.75 30.1723 30.75 29.6588 30.8362 29.188C30.9225 28.5888 31.44 28.2036 32.0437 28.2892C32.6474 28.3748 33.0355 28.9312 32.9493 29.4876C32.9062 29.8728 32.8631 30.2579 32.8631 30.6859C32.8631 33.8958 34.7174 36.6349 36.9598 36.6349C39.2022 36.6349 41.0565 33.8958 41.0565 30.6859C41.0565 30.3007 41.0565 29.8728 40.9703 29.4876C40.884 28.8884 41.3153 28.3748 41.8759 28.2892C42.4796 28.2036 42.9971 28.6316 43.0833 29.188C43.1696 29.6588 43.1696 30.1723 43.1696 30.6859C43.1696 35.137 40.3666 38.7748 36.9167 38.7748H37.0029Z"
          fill="#fff"
        />
        <path
          d="M40.4932 10.9557C39.5445 10.9557 38.682 10.7845 37.8626 10.3994C36.8277 9.92858 36.1808 9.24381 35.6633 8.68743C35.1027 8.08825 34.7577 7.78866 34.1971 7.66027C32.9466 7.36068 31.2216 8.34504 29.2379 10.485C28.8498 10.9129 28.1598 10.9557 27.7286 10.5278C27.2974 10.1426 27.2542 9.4578 27.6855 9.02981C30.2729 6.24792 32.6016 5.09236 34.7146 5.56315C35.9221 5.81994 36.6121 6.59031 37.2589 7.23228C37.7333 7.70307 38.1645 8.17385 38.8114 8.47344C39.9326 8.98702 41.7006 9.3294 45.4093 7.18948C45.9267 6.8899 46.5736 7.06109 46.8755 7.57467C47.1773 8.08825 47.0048 8.73022 46.4873 9.02981C44.2018 10.3138 42.2612 10.9557 40.5363 10.9557H40.4932Z"
          fill="#fff"
        />
        <path
          d="M57.9578 24.6517C53.0417 24.6517 49.0312 20.6714 49.0312 15.7924V8.81629C49.0312 3.93728 53.0417 -0.0429688 57.9578 -0.0429688C62.8739 -0.0429688 66.8844 3.93728 66.8844 8.81629V15.7924C66.8844 20.6714 62.8739 24.6517 57.9578 24.6517ZM57.9578 2.13975C54.2492 2.13975 51.1874 5.13563 51.1874 8.85909V15.8352C51.1874 19.5159 54.2061 22.5546 57.9578 22.5546C61.7096 22.5546 64.7282 19.5587 64.7282 15.8352V8.85909C64.7282 5.17843 61.7096 2.13975 57.9578 2.13975Z"
          fill="#fff"
        />
        <path
          d="M44.3354 31.0711C44.0335 31.0711 43.7317 30.9427 43.516 30.7287C43.1279 30.3008 43.1711 29.616 43.6023 29.2308C43.7748 29.0596 43.9904 28.9312 44.1629 28.76C45.586 27.6473 47.3109 26.7913 49.2084 26.2777L55.3319 24.5658C55.8925 24.3946 56.4962 24.737 56.6687 25.2934C56.8412 25.8497 56.4962 26.4489 55.9356 26.6201L49.8121 28.332C48.1734 28.8028 46.7072 29.4876 45.4997 30.4292C45.3272 30.5576 45.1979 30.6859 45.0254 30.8143C44.8097 30.9855 44.551 31.0711 44.2923 31.0711H44.3354Z"
          fill="#fff"
        />
        <path
          d="M67.6229 46.5213H54.039C53.4353 46.5213 52.9609 46.0506 52.9609 45.4514C52.9609 44.8522 53.4353 44.3814 54.039 44.3814H67.6229C69.9516 44.3814 71.8491 42.4983 71.8491 40.1872V32.9971C71.8491 32.2695 71.2022 31.3279 69.9947 30.3864C68.7873 29.4448 67.3211 28.7172 65.6824 28.2892L59.7313 26.6201C59.1707 26.4489 58.8257 25.8497 58.9982 25.2934C59.1707 24.737 59.7745 24.3946 60.3351 24.5658L66.2861 26.2349C68.2267 26.7913 69.9085 27.6045 71.3316 28.7172C73.0996 30.0868 74.0052 31.5419 74.0052 32.9971V40.1872C74.0052 43.6966 71.116 46.5213 67.6229 46.5213Z"
          fill="#fff"
        />
        <path
          d="M55.6328 26.6202C55.029 26.6202 54.5547 26.1494 54.5547 25.5502V23.5387C54.5547 22.9395 55.029 22.4688 55.6328 22.4688C56.2365 22.4688 56.7109 22.9395 56.7109 23.5387V25.5502C56.7109 26.1494 56.2365 26.6202 55.6328 26.6202Z"
          fill="#fff"
        />
        <path
          d="M60.0781 26.706C59.4744 26.706 59 26.2352 59 25.636V23.5817C59 22.9825 59.4744 22.5117 60.0781 22.5117C60.6818 22.5117 61.1562 22.9825 61.1562 23.5817V25.636C61.1562 26.2352 60.6818 26.706 60.0781 26.706Z"
          fill="#fff"
        />
        <path
          d="M57.747 34.753C54.599 34.753 52.0547 31.4575 52.0547 27.4345C52.0547 26.9637 52.0547 26.5357 52.1409 26.0649C52.2272 25.4658 52.7878 25.0806 53.3484 25.1662C53.9521 25.2518 54.3402 25.8081 54.254 26.3645C54.2109 26.7069 54.1677 27.0493 54.1677 27.3917C54.1677 30.2164 55.7633 32.5703 57.7039 32.5703C59.6444 32.5703 61.24 30.2164 61.24 27.3917C61.24 27.0493 61.24 26.7069 61.1538 26.3645C61.0675 25.7653 61.4987 25.2518 62.0594 25.1662C62.6631 25.0806 63.1806 25.5086 63.2668 26.0649C63.3099 26.4929 63.3531 26.9637 63.3531 27.4345C63.3531 31.4575 60.8088 34.753 57.6607 34.753H57.747Z"
          fill="#fff"
        />
        <path
          d="M60.8479 10.0149C59.9854 10.0149 59.2092 9.84367 58.433 9.50128C57.4843 9.0733 56.9237 8.47412 56.4493 7.96054C55.9749 7.44696 55.6731 7.19017 55.1987 7.06178C54.1206 6.80499 52.6544 7.66095 50.8864 9.54408C50.4983 9.97206 49.8083 10.0149 49.377 9.58688C48.9458 9.20169 48.9027 8.51692 49.3339 8.08894C51.7057 5.56383 53.7756 4.53667 55.6731 5.00745C56.7943 5.26424 57.4411 5.90622 58.0018 6.5054C58.433 6.93338 58.778 7.31857 59.3386 7.57536C60.3304 8.00334 61.8398 8.30293 65.1171 6.4626C65.6346 6.16301 66.2815 6.3342 66.5833 6.84778C66.8852 7.36136 66.7127 8.00334 66.1952 8.30293C64.1684 9.45848 62.4004 10.0149 60.8479 10.0149Z"
          fill="#fff"
        />
        <path
          d="M16.4734 24.6519C11.5574 24.6519 7.54688 20.6716 7.54688 15.7926V8.81646C7.54688 3.98025 11.5574 0 16.4734 0C21.3895 0 25.4 3.98025 25.4 8.85926V15.8354C25.4 20.7144 21.3895 24.6947 16.4734 24.6947V24.6519ZM16.4734 2.13992C12.7648 2.13992 9.70305 5.1358 9.70305 8.85926V15.8354C9.70305 19.5161 12.7217 22.5547 16.4734 22.5547C20.2252 22.5547 23.2438 19.5588 23.2438 15.8354V8.85926C23.2438 5.1786 20.2252 2.13992 16.4734 2.13992Z"
          fill="#fff"
        />
        <path
          d="M19.9662 46.5212H6.38228C2.84615 46.5212 0 43.6537 0 40.187V32.9969C0 31.5417 0.905594 30.1294 2.67366 28.7171C4.09674 27.6043 5.82168 26.7483 7.71911 26.2347L13.8427 24.5228C14.4033 24.3516 15.007 24.694 15.1795 25.2504C15.352 25.8068 15.007 26.4059 14.4464 26.5771L8.32284 28.2891C6.68415 28.7599 5.21795 29.4446 4.01049 30.3862C2.80303 31.3278 2.15618 32.2265 2.15618 32.9969V40.187C2.15618 42.4981 4.05361 44.3813 6.38228 44.3813H19.9231C20.5268 44.3813 21.0012 44.852 21.0012 45.4512C21.0012 46.0504 20.5268 46.5212 19.9231 46.5212H19.9662Z"
          fill="#fff"
        />
        <path
          d="M29.627 30.9855C29.3683 30.9855 29.1096 30.8999 28.9371 30.7287C28.8077 30.6004 28.6783 30.5148 28.5489 30.3864C27.3415 29.4448 25.8753 28.7172 24.2366 28.2892L18.2855 26.6201C17.7249 26.4489 17.38 25.8497 17.5524 25.2934C17.7249 24.737 18.3287 24.3946 18.8893 24.5658L24.8403 26.2349C26.7809 26.7913 28.4627 27.6045 29.8858 28.7172C30.0583 28.8456 30.2308 28.974 30.3601 29.1024C30.7914 29.4876 30.8345 30.1724 30.4464 30.6004C30.2308 30.8571 29.9289 30.9855 29.627 30.9855Z"
          fill="#fff"
        />
        <path
          d="M14.1484 26.6202C13.5447 26.6202 13.0703 26.1494 13.0703 25.5502V23.5387C13.0703 22.9395 13.5447 22.4688 14.1484 22.4688C14.7521 22.4688 15.2265 22.9395 15.2265 23.5387V25.5502C15.2265 26.1494 14.7521 26.6202 14.1484 26.6202Z"
          fill="#fff"
        />
        <path
          d="M18.5468 26.706C17.9431 26.706 17.4688 26.2352 17.4688 25.636V23.5817C17.4688 22.9825 17.9431 22.5117 18.5468 22.5117C19.1506 22.5117 19.6249 22.9825 19.6249 23.5817V25.636C19.6249 26.2352 19.1506 26.706 18.5468 26.706Z"
          fill="#fff"
        />
        <path
          d="M16.2548 34.751C13.1068 34.751 10.5625 31.4556 10.5625 27.4325C10.5625 26.9617 10.5625 26.5338 10.6487 26.063C10.735 25.4638 11.2956 25.0786 11.8562 25.1642C12.4599 25.2498 12.848 25.8062 12.7618 26.3626C12.7187 26.705 12.6756 27.0473 12.6756 27.3897C12.6756 30.2144 14.2711 32.5683 16.2117 32.5683C18.1522 32.5683 19.7478 30.2144 19.7478 27.3897C19.7478 27.0473 19.7478 26.705 19.6616 26.3626C19.5753 25.7634 20.0066 25.2498 20.5672 25.1642C21.1709 25.0786 21.6884 25.5066 21.7746 26.063C21.8177 26.491 21.8609 26.9617 21.8609 27.4325C21.8609 31.4556 19.3166 34.751 16.1686 34.751H16.2548Z"
          fill="#fff"
        />
        <path
          d="M19.3635 10.0149C18.5011 10.0149 17.7248 9.84367 16.9486 9.50128C15.9999 9.0733 15.4393 8.47412 14.9649 7.96054C14.4906 7.44696 14.1887 7.19017 13.7143 7.06178C12.6363 6.80499 11.1701 7.66095 9.40199 9.54408C9.01388 9.97206 8.32391 10.0149 7.89267 9.58688C7.46144 9.20169 7.41831 8.51692 7.84955 8.08894C10.2213 5.56383 12.2913 4.53667 14.1887 5.00745C15.3099 5.26424 15.9568 5.90622 16.5174 6.5054C16.9486 6.93338 17.2936 7.31857 17.8542 7.57536C18.846 8.00334 20.3554 8.30293 23.6328 6.4626C24.1502 6.16301 24.7971 6.3342 25.099 6.84778C25.4008 7.36136 25.2283 8.00334 24.7109 8.30293C22.684 9.45848 20.916 10.0149 19.3635 10.0149Z"
          fill="#fff"
        />
      </g>
      {/* <defs>
          <clipPath id="clip0_394_60777">
            <rect width="74" height="52" fill="white" />
          </clipPath>
        </defs> */}
    </svg>
  );
}

export default TotalNumberOfCustomers;
