import React, { useState } from "react";
import { useParams, useNavigate } from "react-router";
//material
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
//utils
import { handleGender } from "../../Utils";
//styles
import {
  ContainerFullWidth,
  LeadContainerLeft,
  LeftElement,
  LeadContainerRight,
  RightElement,
} from "../../../styles/Containers__styles";
import { useStylesRecipe } from "../../../styles/Recipe__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import PageAddHeader from "../../pageAddHeader/pageAddHeader";
import Status from "../../Status/Status";
//queries & mutations
import { useGetLeadByIdQuery } from "../../../graphql/types";
//utils
import { getCustomerLoggerToExport, handleStatus } from "../../customer/Utils";
import { StyledID, StyledValue } from "./ViewLead.styled";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { Info } from "@mui/icons-material";
import clsx from "clsx";
import { useAuth } from "../../../contextAPI";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";

const ViewLead = () => {
  let auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const isAuthorizedToConvert = useIsAuthorized("ADMIN_LEADS_CONVERT");
  const isAuthorizedToUpdate = useIsAuthorized("ADMIN_LEADS_UPDATE");
  //styles
  const RecipeClasses = useStylesRecipe();
  const ButtonClasses = useStylesButtons();
  const DialogClasses = useStylesDialog();

  //state
  const [open, setOpen] = useState<boolean>(false);
  const [spinning, setSpinning] = useState<boolean>(false);
  const idLead = useParams().id;
  //queries
  const { data, loading } = useGetLeadByIdQuery({
    variables: {
      leadId: params.id || "",
    },
    skip: !params.id,
  });

  const lead = React.useMemo(
    () => data?.getLeadById || null,
    [data?.getLeadById]
  );

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onConvertLead = () => {
    if (idLead) {
      navigate(`/admin/customers/converted_customer/${idLead}`);
    }
  };

  const handleExport = async () => {
    setSpinning(true);
    await getCustomerLoggerToExport(params.id || "", auth.token || "");
    setSpinning(false);
  };

  //render
  return (
    <>
      <Box>
        <PageAddHeader
          title="Lead Profile Details"
          buttonText={
            isAuthorizedToUpdate && isAuthorizedToConvert
              ? ["Update Lead", "Convert Lead"]
              : isAuthorizedToConvert
              ? ["Convert Lead"]
              : ["Update Lead"]
          }
          buttonLink={
            isAuthorizedToUpdate && isAuthorizedToConvert
              ? [
                  `/admin/leads/update_lead/${params.id}`,
                  `/admin/customers/converted_customer/${idLead}`,
                ]
              : isAuthorizedToConvert
              ? [`/admin/customers/converted_customer/${idLead}`]
              : [`/admin/leads/update_lead/${params.id}`]
          }
        />

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "150px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <ContainerFullWidth>
            <LeadContainerLeft>
              <LeftElement>
                <Box
                  style={{
                    display: "flex",
                    gap: "5px",
                    flexDirection: "column",
                  }}
                >
                  <StyledID>#{lead?.ref}</StyledID>
                  <p className={RecipeClasses.recipe__name__subtitle}>
                    {lead?.fname} {lead?.lname}
                  </p>
                </Box>
                <Box style={{ marginBottom: "26px" }}>
                  <p className={RecipeClasses.recipe__title}>
                    Contact Informations
                  </p>
                  <Box display="flex" alignItems="center" marginTop={"12px"}>
                    <Box width={150}>
                      <p className={RecipeClasses.recipe__name__subtitle}>
                        Email
                      </p>
                    </Box>
                    <StyledValue>: {lead?.email}</StyledValue>
                  </Box>
                  <Box display="flex" alignItems="center" marginTop={"12px"}>
                    <Box width={150}>
                      <p className={RecipeClasses.recipe__name__subtitle}>
                        Phone Number
                      </p>
                    </Box>
                    <StyledValue>
                      : {lead?.phone?.prefix} {lead?.phone?.number}
                    </StyledValue>
                  </Box>
                  <Box display="flex" alignItems="center" marginTop={"12px"}>
                    <Box width={150}>
                      <p className={RecipeClasses.recipe__name__subtitle}>
                        Nationality
                      </p>
                    </Box>
                    <StyledValue>: {lead?.nationality}</StyledValue>
                  </Box>
                  <Box display="flex" alignItems="center" marginTop={"12px"}>
                    <Box width={150}>
                      <p className={RecipeClasses.recipe__name__subtitle}>
                        Gender
                      </p>
                    </Box>
                    <StyledValue>: {handleGender(lead?.gender)}</StyledValue>
                  </Box>
                </Box>
                <Box>
                  <p className={RecipeClasses.recipe__title}>Lead Details</p>
                  <Box display="flex" alignItems="center" marginTop={"12px"}>
                    <Box width={150}>
                      <p className={RecipeClasses.recipe__name__subtitle}>
                        Source
                      </p>
                    </Box>
                    <StyledValue>: {lead?.source?.socialMedia}</StyledValue>
                  </Box>
                  {lead?.source?.friend && lead?.source?.friend?.name !== "" ? (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        marginTop={"12px"}
                      >
                        <Box width={150}>
                          <p className={RecipeClasses.recipe__name__subtitle}>
                            Friend Name
                          </p>
                        </Box>
                        <p className={RecipeClasses.recipe__name__subtitle}>
                          {lead?.source?.friend?.name}
                        </p>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        marginTop={"12px"}
                      >
                        <Box width={150}>
                          <p className={RecipeClasses.recipe__name__subtitle}>
                            Friend Phone Number
                          </p>
                        </Box>
                        <p className={RecipeClasses.recipe__name__subtitle}>
                          +{lead?.source?.friend?.phone?.prefix}
                          {lead?.source?.friend?.phone?.number}
                        </p>
                      </Box>
                    </>
                  ) : (
                    <p></p>
                  )}

                  <Box display="flex" alignItems="center" marginTop={"12px"}>
                    <Box width={150}>
                      <p className={RecipeClasses.recipe__name__subtitle}>
                        Lead Status
                      </p>
                    </Box>
                    <Status
                      status={lead?.leadStatus}
                      statusText={handleStatus(lead?.leadStatus)}
                    />
                  </Box>
                </Box>
              </LeftElement>
            </LeadContainerLeft>
            <LeadContainerRight>
              <RightElement>
                <Box marginTop={0}>
                  <p className={RecipeClasses.recipe__title}>Notes</p>

                  <p>{lead?.note}</p>
                </Box>
                <Button
                  onClick={handleExport}
                  className={clsx(ButtonClasses.button_open_list_page)}
                >
                  Export Log&nbsp;&nbsp;
                  {spinning ? (
                    <CircularProgress size={14} color="inherit" />
                  ) : (
                    ""
                  )}
                </Button>
              </RightElement>
            </LeadContainerRight>
          </ContainerFullWidth>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={handleCloseModal}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <Info />
          <span className="confirm_dialog_title_text">
            Convert Lead to Customer ?
          </span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to convert this Lead to a Customer ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonClasses.GreyButton}
            onClick={handleCloseModal}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={ButtonClasses.BlueButton}
            onClick={onConvertLead}
            color="primary"
            autoFocus
          >
            Convert
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewLead;
