import { useState, useRef, memo } from "react";
import { useReactToPrint } from "react-to-print";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
//material
import { Box, TextField, Button, Autocomplete, Checkbox } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
//styles
import { useStylesButtons } from "../../../styles/Buttons__styles";
//queries and mutations
import { useGetBirthdayReportLazyQuery } from "../../../graphql/types";
//utils
import { getMessageError } from "../../Utils";
//components
import BirthdayReportPrint from "./BirthdayReportPrint";

const BirthdayReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  //state
  const printRef = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState<Moment>(moment.utc());
  const [showGenerate, setShowGenerate] = useState<boolean>(true);

  //styles
  const ButtonClasses = useStylesButtons();

  //functions
  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    documentTitle:
      "Birthday Report " +
      moment(startDate).format("DD/MM/YYYY dddd") +
      " generated on " +
      moment().format("DD/MM/YYYY dddd"),
  });

  //queries

  const [getBirthday, { data: generateBirthday }] =
    useGetBirthdayReportLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted() {
        handlePrint();
        setShowGenerate(true);
      },
      onError(error) {
        const err = getMessageError(error);
        enqueueSnackbar(err, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setShowGenerate(true);
      },
    });

  const onGenerateHandler = async () => {
    setShowGenerate(false);
    await getBirthday({
      variables: {
        date: startDate.toDate(),
      },
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={800}
    >
      <Box
        width="40%"
        height="70%"
        bgcolor="#ffffff73"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection="column"
        borderRadius={5}
        border={1}
        borderColor="#e7e7e773"
      >
        <Box
          fontFamily="Poppins"
          fontSize={20}
          fontWeight="600"
          alignSelf="flex-start"
          marginLeft="15%"
        >
          Birthday Report
        </Box>
        <Box width="70%">
          <Box width="100%">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                value={startDate}
                inputFormat="DD/MM/YYYY"
                disablePast={false}
                disableFuture={false}
                onChange={(newValue) => {
                  const utcDate = moment.utc(newValue);
                  setStartDate(utcDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Delivery Date"
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Box
          width="70%"
          height={100}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            type="submit"
            disabled={!showGenerate || !startDate}
            onClick={onGenerateHandler}
            sx={{ width: "100%", height: "50%" }}
          >
            Generate Reports
          </Button>
          <Box
            fontFamily="Poppins"
            fontSize={10}
            fontWeight="500"
            alignSelf="flex-start"
          >
            Use this table to generate the birthday report .
          </Box>
        </Box>
      </Box>
      <div style={{ display: "none" }}>
        <BirthdayReportPrint
          generateBirthday={generateBirthday}
          ref={printRef}
          date={startDate.toDate()}
        />
      </div>
    </Box>
  );
};

export default memo(BirthdayReport);
