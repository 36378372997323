import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contextAPI";
//material-ui
import { AppBar, Box, Toolbar, IconButton, Breadcrumbs } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
//styles
import { useStylesNavigation } from "../../../styles/Navigation__styles";
//icons
import { FiUser, FiSettings, FiLogOut } from "react-icons/fi";
import Separator from "../../../public/icons/separator";
import Arrow from "../../../public/icons/Arrow";
//Theme
import { useCustomTheme } from "../../../theme/useCustomTheme";

export default function Navigation() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const pathnames = pathname.split("/").filter((el, i) => el && i < 3);

  //styles
  const navigationClasses = useStylesNavigation();

  //functions
  const onClickLogout = () => {
    auth.signOut();
    navigate("/");
  };

  const { theme } = useCustomTheme();

  //render
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          className={navigationClasses.container__appBar}
        >
          <Toolbar>
            <Separator />
            <div className={navigationClasses.breadcrumb_container}>
              <Breadcrumbs separator={<Arrow />} aria-label="breadcrumb">
                {pathnames.map((pathnameValue: string) => (
                  <p
                    key={pathnameValue}
                    className={navigationClasses.breadcrumb_value}
                  >
                    {pathnameValue.replace("_", " ")}
                  </p>
                ))}
              </Breadcrumbs>
            </div>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="medium"
                color={"inherit"}
                className={navigationClasses.container__icons}
              >
                <FiUser />
              </IconButton>
              <IconButton
                size="medium"
                color={"inherit"}
                className={navigationClasses.container__icons}
              >
                <FiSettings />
              </IconButton>
              <IconButton
                size="medium"
                edge="end"
                onClick={onClickLogout}
                color={"inherit"}
                className={navigationClasses.container__icons}
              >
                <FiLogOut />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
