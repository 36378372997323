import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useStylesContainers } from "../../../styles/Containers__styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  AddressPayload,
  DriverPayload,
  GetCustomerAddressesDocument,
  useResetCustomersDriverMutation,
  useUpdateCustomersDriverMutation,
} from "../../../graphql/types";
import { useSnackbar } from "notistack";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useState } from "react";

interface propTypes {
  driver: DriverPayload;
  driverList: DriverPayload[];
  customerId: string | undefined;
  adress: AddressPayload;
}
const PickDriveFrom = ({
  driver,
  driverList,
  customerId,
  adress,
}: propTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  // Apollo hooks

  const [resetDriver, { loading: resetLoading }] =
    useResetCustomersDriverMutation();
  const [updateCustomerDriver, { loading }] =
    useUpdateCustomersDriverMutation();

  const ContainersClasses = useStylesContainers();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  const defaultDriver = adress.driver;
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { driver_name: driver },
  });

  async function onSubmit(driver: DriverPayload) {
    updateCustomerDriver({
      variables: {
        input: {
          driverId: driver._id!,
          customerId: customerId!,
          addressId: adress._id!,
        },
      },
      refetchQueries: [GetCustomerAddressesDocument],
      onCompleted() {
        enqueueSnackbar("Driver updated successfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onError() {
        enqueueSnackbar("updating Driver failed", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    });
  }

  function handleReset() {
    resetDriver({
      variables: {
        input: {
          addressId: adress._id!,
          customerId: customerId!,
        },
      },
      refetchQueries: [GetCustomerAddressesDocument],
      onCompleted: () => {
        reset({ driver_name: defaultDriver! });
        enqueueSnackbar("Reset to default driver succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setIsOpen(false);
      },
      onError: () => {
        enqueueSnackbar("Reset to default driver failed", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    });
  }
  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data.driver_name);
        })}
      >
        <Box
          padding={"8px"}
          paddingRight={4}
          minWidth={200}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Controller
            name="driver_name"
            rules={{
              required: "Please Select a Driver",
            }}
            control={control}
            render={({
              field: { onChange: Change, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <>
                <Autocomplete
                  sx={{ width: "70%" }}
                  ref={ref}
                  id="driver_name"
                  options={driverList}
                  getOptionLabel={(option) => option!.name!}
                  value={value}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  filterSelectedOptions
                  onChange={(_, data) => Change(data)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option.name}</li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Driver" />
                  )}
                />
                <p className={ContainersClasses.section__error__helper}>
                  {error?.message}
                </p>
              </>
            )}
          />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"27%"}
          >
            {loading ? (
              <CircularProgress
                size={20}
                color="secondary"
                style={{ marginLeft: "6px" }}
              />
            ) : (
              <IconButton size="small" aria-label="confirm" type="submit">
                <TaskAltIcon />
              </IconButton>
            )}

            <IconButton
              size="small"
              aria-label="reset"
              disabled={resetLoading}
              onClick={() => setIsOpen(true)}
            >
              <RestartAltIcon />
            </IconButton>
          </Box>
        </Box>
      </form>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <RestartAltIcon />
          <span className="alert_dialog_title_text">Reset Driver</span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to reset Driver to default ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            className={ButtonsClasses.RedButton}
            onClick={handleReset}
            color="primary"
            autoFocus
          >
            {resetLoading && (
              <CircularProgress
                size={20}
                color="info"
                style={{ marginRight: "10px", color: "white" }}
              />
            )}
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PickDriveFrom;
