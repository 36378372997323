import { useState, useMemo, useEffect } from "react";
import moment from "moment";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
//material ui
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DateAdapter from "@mui/lab/AdapterMoment";
import CloseIcon from "@mui/icons-material/Close";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
//components
import Table from "../../layout/commun/Table";
import FilterHeader from "../../pageHeader/FilterHeader";
//queries&mutations
import {
  GetExpiringCustomersDocument,
  LunchMeal,
  NumberOfDays,
  PaymentPeriod,
  PlanCondition,
  ProgramPayload,
  useAddNewCustomerPlanMutation,
  useGetActiveProgramsQuery,
  useGetCustomerProfileByIdQuery,
  useGetExpiringCustomersLazyQuery,
  useGetExpiringCustomersQuery,
  useInitiateChangeMacrosQuery,
  useOnChangeMacrosDashboardMutation,
} from "../../../graphql/types";
//utils
import {
  columns,
  daysPerweekOptions,
  durationsOptions,
  paymentMethodOptions,
  SIDE_FILTER_OPTIONS_EXPIRING_CUSTOMER,
} from "./Utils";
import {
  convertNumberOfDaysEnum,
  getMacros,
  Goal,
  weekDays,
} from "../../customer/Utils";
import {
  CustomPackageMeals,
  DialogTitleProps,
  getMessageError,
  meals,
} from "../../Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//types
import { renewMealPlanDashProps } from "./Types";
import { ApolloError } from "@apollo/client";
//firebase analytics
import { logEvent } from "firebase/analytics";
//context api
import { useAuth } from "../../../contextAPI";

function ExpiringCustomers() {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let auth = useAuth();
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");
  //style
  const ButtonClasses = useStylesButtons();
  const DialogClasses = useStylesDialog();
  const ContainersClasses = useStylesContainers();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [idsProgram, setIdsProgram] = useState<string[]>([]);
  const [duration, setDuration] = useState<string[]>([]);
  const [numberOfDays, setNumberOfDays] = useState<NumberOfDays[]>([]);
  const [numberOfMeals, setNumberOfMeals] = useState<number[]>([]);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [chosenCustomerId, setChosenCustomerId] = useState<string>("");
  const [planStatus, setPlanStatus] = useState<PlanCondition[]>([]);

  //react-hook-form
  const methodsEditMealPlan = useForm<renewMealPlanDashProps>({
    defaultValues: {
      program: null,
      daysPerWeek: null,
      isPaid: false,
      duration: null,
      meals: [],
      daysOff: [],
      startDate: null,
      isCustomPackage: false,
      customPackagesQuantities: {
        breakfast: 0,
        lunchDinner: 0,
        snack: 0,
      },
      calories: "",
      promoCode: "",
      paymentMethod: null,
      carbs: "",
      protein: "",
      fat: "",
      goal: null,
    },
  });
  const { control, register, handleSubmit, setValue, reset } =
    methodsEditMealPlan;

  const dataWatch = useWatch({
    control,
  });

  //queries&mutations
  const dataGetCustomers = useGetExpiringCustomersQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        duration: duration,
        nbrOfDays: numberOfDays,
        meals: numberOfMeals,
        program: idsProgram,
        condition: planStatus,
        from: from,
        to: to,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
    fetchPolicy: "network-only",
  });

  const count = useMemo(
    () => dataGetCustomers.data?.getExpiringCustomers.count || 0,
    [dataGetCustomers.data]
  );

  const [getExpiringCustomersData, { loading: expiringCustomersListLoading }] =
    useGetExpiringCustomersLazyQuery();

  const handleExportExcel = async () => {
    await getExpiringCustomersData({
      variables: {
        input: {
          page: 1,
          documentsPerPage: count,
          duration: duration,
          nbrOfDays: numberOfDays,
          meals: numberOfMeals,
          program: idsProgram,
          condition: planStatus,
          from: from,
          to: to,
        },
      },
    })
      .then((data) => {
        if (count != 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const fileExtension = ".xlsx";
          const filteredCustomers = data?.data?.getExpiringCustomers?.data?.map(
            (el, index) => {
              return {
                id: (index + 1).toString(),
                name: el.fname + " " + el.lname,
                phone: el.phone?.prefix + " " + el.phone?.number,
                email: el.email ? el.email : "null@null.com",
                date: moment(el.plan?.expiryDate).format("DD-MMM-YYYY"),
                plan:
                  el.plan?.program?.name?.EN +
                  " , " +
                  convertNumberOfDaysEnum(el?.plan?.nbrOfDays || "FIVE_DAYS") +
                  " , " +
                  el?.plan?.meals?.length +
                  " meals " +
                  " , " +
                  el?.plan?.paymentPeriod,
              };
            }
          );
          const ws = XLSX.utils.json_to_sheet(filteredCustomers || []);
          ws["!cols"] = [
            { wpx: 40 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 200 },
            { wpx: 120 },
            { wpx: 250 },
          ];
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const res = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            res,
            "expiring customers report" +
              " generated on " +
              moment().format("DD/MM/YYYY dddd LT") +
              fileExtension
          );
          return "Done";
        }
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };

  const expiringCustomers = useMemo(
    () => dataGetCustomers.data?.getExpiringCustomers.data || [],
    [dataGetCustomers.data]
  );

  const [AddNewCustomerPlan, { loading: laodingAddNewCustomerPlan }] =
    useAddNewCustomerPlanMutation();

  const [updateCalories] = useOnChangeMacrosDashboardMutation();

  const { data: activeProgramsData } = useGetActiveProgramsQuery({
    fetchPolicy: "network-only",
    skip: !open,
  });

  const activePrograms = useMemo(
    () => activeProgramsData?.getActivePrograms || [],
    [activeProgramsData]
  );

  const { data } = useGetCustomerProfileByIdQuery({
    variables: {
      customerId: chosenCustomerId || "",
    },
    fetchPolicy: "network-only",
    skip: !chosenCustomerId,
  });

  //used for the min and max calories for each customer
  const { data: caloriesData } = useInitiateChangeMacrosQuery({
    variables: {
      input: {
        meals: data?.getCustomerProfileById.plan?.meals || [],
        programId: data?.getCustomerProfileById.plan?.program?._id || "",
      },
    },
    fetchPolicy: "network-only",
    skip: !data,
  });

  //needed for calories/nutritions calculations
  const customerDetails = useMemo(
    () => data?.getCustomerProfileById || null,
    [data?.getCustomerProfileById]
  );

  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const RenewHandler = (id: string) => {
    setOpen(true);
    setChosenCustomerId(id);
  };

  const handleCloseModal = () => {
    reset();
    setOpen(false);
  };

  const renderTableRows = () => {
    return expiringCustomers.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        id: item?.ref,
        name: isAuthorizedToSeeProfile ? (
          <a
            style={{ textDecoration: "none" }}
            href={`/admin/customers/view_customer/${item?._id}`}
          >
            <Button>{`${item?.fname} ${item?.lname}`}</Button>
          </a>
        ) : (
          item.fname + " " + item.lname
        ),
        mobile: item?.phone?.prefix + " " + item?.phone?.number,
        email: item?.email,
        plan: (
          <>
            <Typography style={{ fontSize: 12 }}>
              {item.plan?.program?.name?.EN +
                ", " +
                convertNumberOfDaysEnum(item?.plan?.nbrOfDays || "FIVE_DAYS")}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {item?.plan?.meals?.length +
                " meals, " +
                item?.plan?.paymentPeriod}
            </Typography>
          </>
        ),
        date: (
          <Typography style={{ color: "red" }}>
            {moment(item?.plan?.expiryDate).format("DD-MMM-YYYY")}
          </Typography>
        ),
        actions: (
          <Box>
            <Button
              style={{ marginRight: 4 }}
              size="medium"
              className={clsx(ButtonClasses.GreenButton)}
              onClick={() => RenewHandler(item?._id)}
            >
              Renew
            </Button>
            {isAuthorizedToSeeProfile && (
              <Button
                variant="contained"
                size="medium"
                className={clsx(ButtonClasses.button_cancel_form)}
                onClick={() =>
                  navigate(`/admin/customers/view_customer/${item?._id}`)
                }
              >
                Profile
              </Button>
            )}
          </Box>
        ),
      };
    });
  };

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              height: "fit-content",
            }}
          >
            <CloseIcon
              style={{
                minHeight: "22px",
                height: "22px",
                width: "22px",
                marginBottom: "0px",
              }}
            />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  //to prefill the form
  useEffect(() => {
    if (data?.getCustomerProfileById) {
      setValue("calories", data?.getCustomerProfileById?.calories || "");
      setValue("meals", data?.getCustomerProfileById?.plan?.meals || []);
      setValue("daysOff", data?.getCustomerProfileById?.plan?.daysOff || []);
      setValue(
        "program",
        activePrograms.find(
          (el) => el._id === data?.getCustomerProfileById?.plan?.program?._id
        ) as ProgramPayload
      );
      setValue("daysPerWeek", data?.getCustomerProfileById?.plan?.nbrOfDays);
      setValue(
        "duration",
        data?.getCustomerProfileById?.plan?.paymentPeriod as PaymentPeriod
      );
      setValue(
        "isCustomPackage",
        data?.getCustomerProfileById?.plan?.isCustom || false
      );
      if (data?.getCustomerProfileById?.plan?.isCustom) {
        let meals = data?.getCustomerProfileById?.plan?.meals || [];
        let breakfast = 0;
        let lunchDinner = 0;
        let snack = 0;
        for (let i = 0; i < meals.length; i++) {
          if (meals[i] === "BREAKFAST") {
            breakfast++;
          }
          if (meals[i] === "LUNCH" || meals[i] === "DINNER") {
            lunchDinner++;
          }
          if (meals[i] === "EVENING_SNACK" || meals[i] === "MORNING_SNACK") {
            snack++;
          }
        }
        setValue("meals", []);
        setValue("customPackagesQuantities.breakfast", breakfast);
        setValue("customPackagesQuantities.lunchDinner", lunchDinner);
        setValue("customPackagesQuantities.snack", snack);
      }
      handleCaloriesChange(data?.getCustomerProfileById?.calories || "");
    }
  }, [data?.getCustomerProfileById]);

  //setting the payment method to null whenever isPaid is checked
  useEffect(() => {
    if (dataWatch.isPaid) {
      setValue("paymentMethod", null);
    }
  }, [dataWatch.isPaid]);

  //empty meals array whenever custom package is checked
  useEffect(() => {
    if (dataWatch.isCustomPackage) {
      setValue("meals", []);
    }
  }, [dataWatch.isCustomPackage]);

  //set the goal that we use to calculate nutritions
  useEffect(() => {
    if (customerDetails?.goal) {
      setValue("goal", customerDetails?.goal as Goal);
    }
  }, [customerDetails?.goal]);

  const updateMealsQuantityHandler = (
    config: "INCREMENT" | "DECREMENT",
    mealType: string,
    currentQuantity: number
  ) => {
    if (config === "INCREMENT") {
      currentQuantity++;
    } else {
      currentQuantity--;
    }
    if (mealType === "Breakfast") {
      setValue("customPackagesQuantities.breakfast", currentQuantity);
    } else if (mealType === "Lunch/Dinner") {
      setValue("customPackagesQuantities.lunchDinner", currentQuantity);
    } else if (mealType === "Snack") {
      setValue("customPackagesQuantities.snack", currentQuantity);
    }
  };

  const handleCaloriesChange = (event: string) => {
    const calories = parseInt(event);
    const macros = getMacros(
      calories,
      dataWatch.goal as Goal,
      customerDetails?.plan?.program?.name?.EN?.toLowerCase().includes("keto")
    );
    setValue("carbs", macros?.carb);
    setValue("protein", macros?.prot);
    setValue("fat", macros?.fat);
  };

  const onSubmitRenewPlan: SubmitHandler<renewMealPlanDashProps> = async (
    data
  ) => {
    let mealsArray: LunchMeal[] = [];
    let checkIsCustom = false;
    if (dataWatch.isCustomPackage) {
      checkIsCustom = true;
      if (data.customPackagesQuantities?.breakfast > 0) {
        mealsArray.push(
          ...[...Array(dataWatch.customPackagesQuantities?.breakfast)].fill(
            "BREAKFAST"
          )
        );
      }
      if (data.customPackagesQuantities?.lunchDinner > 0) {
        for (
          let i = 1;
          i < data.customPackagesQuantities?.lunchDinner + 1;
          i++
        ) {
          if (i % 2 === 0) {
            mealsArray.push("DINNER");
          } else {
            mealsArray.push("LUNCH");
          }
        }
      }
      if (data.customPackagesQuantities?.snack > 0) {
        for (let i = 1; i < data.customPackagesQuantities?.snack + 1; i++) {
          if (i % 2 === 0) {
            mealsArray.push("EVENING_SNACK");
          } else {
            mealsArray.push("MORNING_SNACK");
          }
        }
      }
    } else {
      mealsArray = [...(dataWatch.meals || [])];
    }
    try {
      if (mealsArray.length < 3 && checkIsCustom) {
        enqueueSnackbar(
          "Your need to have at least 3 meals for a custom plan",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }
        );
        return;
      }
      //test whether the combination between days per week and days off is correct
      if (
        (dataWatch.daysPerWeek === "FIVE_DAYS" &&
          dataWatch?.daysOff?.length != 2) ||
        (dataWatch.daysPerWeek === "SIX_DAYS" &&
          dataWatch?.daysOff?.length != 1) ||
        (dataWatch.daysPerWeek === "SEVEN_DAYS" &&
          dataWatch?.daysOff?.length != 0)
      ) {
        enqueueSnackbar(
          "Please Verify the combination between Days per week and Days off",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }
        );
        return;
      }

      //send firebase analytics event
      logEvent(auth.analytics, "Selected_Pack", {
        packName: checkIsCustom ? "Custom package" : mealsArray.toString(),
        meals: mealsArray.toString(),
      });

      await AddNewCustomerPlan({
        variables: {
          addNewCustomerPlanInput: {
            customerId: chosenCustomerId || "",
            isPaid: data.isPaid,
            nbrOfDays: data.daysPerWeek || "FIVE_DAYS",
            meals: mealsArray,
            isCustom: checkIsCustom,
            paymentMethod: data.paymentMethod || "CASH",
            paymentPeriod: data?.duration || "MONTHLY",
            programId: data.program?._id || "",
            startDate: data.startDate || new Date(),
            withDelivery: true,
            promoCode: dataWatch.promoCode,
            daysOff: data.daysOff,
          },
        },
        refetchQueries: [GetExpiringCustomersDocument],
        onCompleted: () => {
          enqueueSnackbar("New plan succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
          updateCalories({
            variables: {
              input: {
                customer: chosenCustomerId || "",
                calories: data.calories,
                macros: {
                  prot: data.protein,
                  fat: data.fat,
                  carb: data.carbs,
                },
              },
            },
            onCompleted: () => {
              reset();
              setOpen(false);
              enqueueSnackbar("Calories succuessfully updated", {
                variant: "success",
                anchorOrigin: { vertical: "bottom", horizontal: "center" },
              });
              setTimeout(() => closeSnackbar(), 5000);
            },
          });
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <>
      <Box marginBottom={5}>
        <FilterHeader
          count={count}
          showSearch={false}
          pageTitle="Customer Expiring"
          pageInfo="This is a page displaying customers who have plans expiring soon (the next 3 days )"
          setDuration={setDuration}
          durationData={duration}
          setNumberOfDays={setNumberOfDays}
          daysData={numberOfDays}
          setNumberOfMeals={setNumberOfMeals}
          mealsData={numberOfMeals}
          setIdsProgram={setIdsProgram}
          programIdData={idsProgram}
          setPlanStatus={setPlanStatus}
          planStatus={planStatus}
          setFrom={setFrom}
          from={from}
          setTo={setTo}
          to={to}
          filterConfig={SIDE_FILTER_OPTIONS_EXPIRING_CUSTOMER}
          loadExcel={expiringCustomersListLoading}
          generateExcel={handleExportExcel}
        />
      </Box>

      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetCustomers.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Expiring Customers found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>

      {/* RENEW MODAL */}
      <Dialog
        open={open}
        onClose={handleCloseModal}
        className={DialogClasses.dialog_renew_container}
      >
        <BootstrapDialogTitle
          className={DialogClasses.alert_dialog_title}
          onClose={handleCloseModal}
        >
          <span
            className="confirm_dialog_title_text"
            style={{ alignSelf: "flex-start" }}
          >
            Renew Meal Plan
          </span>
        </BootstrapDialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmitRenewPlan)}>
            <Box display="flex" flexDirection="column">
              <Box display="grid" gridTemplateColumns="1fr 2fr" columnGap={4}>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Meal plan
                  </p>
                  <Controller
                    name="program"
                    rules={{
                      required: "Select Program",
                    }}
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          ref={ref}
                          id="program"
                          value={dataWatch.program as ProgramPayload}
                          options={activePrograms || []}
                          getOptionLabel={(option) => option.name?.EN || ""}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          filterSelectedOptions
                          onChange={(_, data) => Change(data)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name?.EN}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Meal Program" />
                          )}
                        />
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>Meals</p>
                  <Controller
                    name="meals"
                    control={control}
                    rules={
                      dataWatch.isCustomPackage
                        ? { required: false }
                        : { required: "Select Meals" }
                    }
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          multiple
                          disabled={dataWatch.isCustomPackage}
                          id="meals"
                          options={meals}
                          value={dataWatch?.meals || []}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Meals" />
                          )}
                          onChange={(_, data) => Change(data)}
                        />
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
              </Box>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                columnGap={4}
              >
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Days per week
                  </p>
                  <Controller
                    name="daysPerWeek"
                    rules={{
                      required: "Select number of days per week",
                    }}
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          ref={ref}
                          id="daysPerWeek"
                          options={daysPerweekOptions}
                          value={dataWatch.daysPerWeek}
                          getOptionLabel={(option) => option}
                          filterSelectedOptions
                          onChange={(_, data) => Change(data)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Days per week"
                            />
                          )}
                        />
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Days off
                  </p>
                  <Controller
                    name="daysOff"
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          multiple
                          id="daysOff"
                          options={weekDays}
                          value={dataWatch?.daysOff}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Days off" />
                          )}
                          onChange={(_, data) => Change(data)}
                        />
                      </>
                    )}
                  />
                </Box>
                {dataWatch.isCustomPackage ? (
                  <Box width="70%">
                    {CustomPackageMeals?.map((elem) => {
                      let quantity = 1;
                      if (elem === "Breakfast") {
                        quantity =
                          dataWatch?.customPackagesQuantities?.breakfast || 0;
                      } else if (elem === "Lunch/Dinner") {
                        quantity =
                          dataWatch?.customPackagesQuantities?.lunchDinner || 0;
                      } else if (elem === "Snack") {
                        quantity =
                          dataWatch?.customPackagesQuantities?.snack || 0;
                      }
                      return (
                        <Box
                          key={elem}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          marginTop={1}
                        >
                          <Box
                            fontFamily="Poppins"
                            fontSize={14}
                            fontWeight="500"
                            width={200}
                          >
                            {elem}
                          </Box>
                          <Box
                            height={25}
                            width={25}
                            borderRadius={12}
                            bgcolor={
                              !dataWatch.isCustomPackage ? "#C4C4C4" : "#000"
                            }
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="#fff"
                            sx={{
                              cursor: !dataWatch.isCustomPackage
                                ? "default"
                                : "pointer",
                              "&:hover": {
                                backgroundColor: "#C4C4C4",
                                transition: "0.3s",
                              },
                            }}
                            onClick={() =>
                              quantity === 0
                                ? () => null
                                : updateMealsQuantityHandler(
                                    "DECREMENT",
                                    elem,
                                    quantity
                                  )
                            }
                          >
                            -
                          </Box>
                          <Box
                            height={25}
                            width={40}
                            borderRadius={1}
                            bgcolor="#C4C4C4"
                            marginLeft={1}
                            marginRight={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {quantity}
                          </Box>
                          <Box
                            height={25}
                            width={25}
                            borderRadius={12}
                            bgcolor={
                              !dataWatch.isCustomPackage ? "#C4C4C4" : "#000"
                            }
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="#fff"
                            sx={{
                              cursor: !dataWatch.isCustomPackage
                                ? "default"
                                : "pointer",
                              "&:hover": {
                                backgroundColor: "#C4C4C4",
                                transition: "0.3s",
                              },
                            }}
                            onClick={() =>
                              updateMealsQuantityHandler(
                                "INCREMENT",
                                elem,
                                quantity
                              )
                            }
                          >
                            +
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box height={100}></Box>
                )}

                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Duration
                  </p>
                  <Controller
                    name="duration"
                    rules={{
                      required: "Select the duration",
                    }}
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          ref={ref}
                          id="duration"
                          options={durationsOptions}
                          value={dataWatch.duration}
                          getOptionLabel={(option) => option}
                          filterSelectedOptions
                          onChange={(_, data) => Change(data)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Duration" />
                          )}
                        />
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Start date
                  </p>
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{
                      required: "Field required",
                    }}
                    render={({
                      field: { ref, onChange, value, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <Stack style={{ fontSize: 20 }}>
                            <DesktopDatePicker
                              value={value}
                              inputFormat="DD/MM/YYYY"
                              disablePast={false}
                              disableFuture={false}
                              onChange={(newValue) => {
                                onChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                        <p
                          className={clsx(
                            ContainersClasses.section__error__helper
                          )}
                        >
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
                <Box width="100%">
                  <Controller
                    name="isCustomPackage"
                    control={control}
                    render={({ field: { onChange, ref } }) => {
                      return (
                        <FormControlLabel
                          checked={dataWatch?.isCustomPackage}
                          onChange={(_, value) => {
                            onChange(value);
                          }}
                          value="start"
                          control={<Checkbox color="secondary" />}
                          label="Custom Package"
                          labelPlacement="start"
                          sx={{ marginLeft: 5.5, marginTop: 5 }}
                        />
                      );
                    }}
                  />
                </Box>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Payment method
                  </p>
                  <Controller
                    name="paymentMethod"
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          ref={ref}
                          disabled={dataWatch.isPaid}
                          id="paymentMethod"
                          value={dataWatch.paymentMethod}
                          options={paymentMethodOptions}
                          getOptionLabel={(option) => option}
                          filterSelectedOptions
                          onChange={(_, data) => Change(data)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Payment Method"
                            />
                          )}
                        />
                      </>
                    )}
                  />
                </Box>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Promo code
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Promo code"
                    fullWidth
                    {...register("promoCode")}
                  />
                </Box>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Calories
                  </p>
                  <Box>
                    <Controller
                      name="calories"
                      control={control}
                      render={({
                        field: { ref, onChange, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextField
                            {...rest}
                            onChange={(ev) => {
                              handleCaloriesChange(ev?.target?.value);
                              onChange(ev?.target?.value);
                            }}
                            name="calories"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Kcal
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {caloriesData?.initiateChangeMacros.min || "-"}-
                      {caloriesData?.initiateChangeMacros.max || "-"}
                    </FormHelperText>
                  </Box>
                </Box>
                <Box></Box>
                <Box></Box>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <FormControlLabel
                    style={{ marginTop: 35 }}
                    control={
                      <Switch
                        {...register("isPaid")}
                        checked={dataWatch.isPaid}
                        color="secondary"
                      />
                    }
                    label="Is Paid"
                  />
                  <Button
                    sx={{ marginTop: 5 }}
                    variant="contained"
                    className={clsx(ButtonClasses.button_open_list_page)}
                    type="submit"
                  >
                    {laodingAddNewCustomerPlan && (
                      <CircularProgress
                        size={20}
                        style={{ color: "white", marginRight: 6 }}
                      />
                    )}
                    Renew
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ExpiringCustomers;
