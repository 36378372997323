import { useState, useMemo } from "react";
import { useParams } from "react-router";
import { useForm, SubmitHandler, useWatch, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
//material
import {
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  Autocomplete,
  Checkbox,
  TextField,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
import { FullContainer } from "../../../styles/Customers__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import TabContentHeader from "../../TabContentHeader/TabContentHeader";
import Table from "../../layout/commun/Table";
//icons
import Delete from "../../../public/icons/delete";
//types
import { AddAllergyTypes, allergyTypes } from "../Types";
import { ApolloError } from "@apollo/client";
//Utils
import { allColumns } from "../Utils";
import { getMessageError } from "../../Utils";
//queries & mutations
import {
  useGetCustomerAllergiesQuery,
  useGetAllergenQuery,
  useDeleteCustomerAllergieMutation,
  GetCustomerAllergiesDocument,
  useAddAllergiesToCustomerV2Mutation,
  useGetAllIngredientsQuery,
  useAddCustomAllergensToCustomerMutation,
  AllergenGroupPayload,
} from "../../../graphql/types";

const CustomerlistAllergies = () => {
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //state
  const [showContainer, setDisplayContainer] = useState<null | string>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<AllergenGroupPayload | null>(null);

  //styles
  const ContainersClasses = useStylesContainers();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();
  const classes = useStylesMenu();

  // queries & mutations
  const [addAllergens, dataAddAllergens] =
    useAddAllergiesToCustomerV2Mutation();

  const [addIngredient] = useAddCustomAllergensToCustomerMutation();

  const [deleteAllergen, dataDeleteAllergen] =
    useDeleteCustomerAllergieMutation({});

  const { data, loading } = useGetCustomerAllergiesQuery({
    variables: {
      customerId: params.id || "",
    },
    skip: !params.id,
  });

  const dataAllergen = useGetAllergenQuery({ skip: !showContainer });

  const { data: ingredientsList } = useGetAllIngredientsQuery({
    variables: { input: "" },
    fetchPolicy: "no-cache",
    skip: !showContainer,
  });

  const customerAllergies = useMemo(
    () => data?.getCustomerAllergies?.allergens || null,
    [data?.getCustomerAllergies?.allergens]
  );

  const dataAllergens = useMemo(
    () => dataAllergen?.data?.getAllergen || [],
    [dataAllergen?.data]
  );

  //react-hook-forms
  const methods = useForm<AddAllergyTypes>({
    defaultValues: {
      allergen__name: [],
      ingredients: [],
    },
  });
  const { control, handleSubmit, getValues, reset } = methods;
  const dataWatch = useWatch({ control, name: "allergen__name" });
  const dataWatchIngredients = useWatch({ control, name: "ingredients" });

  //functions
  const handleClickMenu =
    (item: AllergenGroupPayload) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const validateAtLeastOneFilled = () => {
    const values = getValues();
    if (
      (!values.allergen__name || values.allergen__name.length === 0) &&
      (!values.ingredients || values.ingredients.length === 0)
    ) {
      return "At least one of Allergy or Ingredient is required";
    }
    return true;
  };

  const handleDeleteAllergen = async () => {
    setOpen(false);
    if (selected) {
      try {
        await deleteAllergen({
          variables: {
            deleteCustomerAllergieInput: {
              allergieId: selected?._id || "",
              customerId: params.id || "",
            },
          },
          refetchQueries: [
            {
              query: GetCustomerAllergiesDocument,
              variables: {
                customerId: params.id || "",
              },
            },
          ],
        });
        enqueueSnackbar("Allergen successfully deleted", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setSelected(null);
      } catch (err) {
        setSelected(null);
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
  };

  const onSubmit: SubmitHandler<allergyTypes> = async (data) => {
    const allergiesSelected = dataWatch.map((el) => {
      return el._id || "";
    });
    const ingredientsSelected = dataWatchIngredients.map((el) => {
      return el._id;
    });
    try {
      await addAllergens({
        variables: {
          input: { allergens: allergiesSelected, customerId: params.id || "" },
        },
        refetchQueries: [
          {
            query: GetCustomerAllergiesDocument,
            variables: {
              customerId: params.id || "",
            },
          },
        ],
      });
      await addIngredient({
        variables: {
          input: {
            ingredient: ingredientsSelected as any,
            customerId: params.id || "",
          },
        },
        refetchQueries: [
          {
            query: GetCustomerAllergiesDocument,
            variables: {
              customerId: params.id || "",
            },
          },
        ],
      });
      setDisplayContainer(null);
      reset();
      enqueueSnackbar("Allergen succuessfully added", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    } catch (error) {
      enqueueSnackbar("Failed to add an allergy", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  //render list
  const renderTableRows = () => {
    return (
      customerAllergies?.map((el) => {
        const isLoading =
          selected?._id === el._id && dataDeleteAllergen.loading;
        return {
          id: el.ref,
          name: el?.name?.EN,
          actions: isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <IconButton onClick={handleClickMenu(el)}>
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  };

  return (
    <>
      <FullContainer>
        <TabContentHeader
          title={"Allergies List"}
          buttonText={"Add Allergy"}
          buttonFnc={() => {
            setDisplayContainer("add");
          }}
        />
        <TableContainer>
          <Table
            columns={allColumns}
            data={renderTableRows()}
            loading={loading}
            numberOfFakeRow={30}
            emptyMessage="No Allergies found !"
          />
        </TableContainer>
        <div className={classes.menu_container}>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorPosition={{
              left: anchorEl?.getBoundingClientRect()?.left || 0,
              top: anchorEl?.getBoundingClientRect()?.top || 0,
            }}
            anchorOrigin={{ horizontal: "left", vertical: "center" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorReference="anchorPosition"
          >
            <MenuItem
              onClick={handleClose}
              className={classes.menu_delete_container}
              disableRipple={true}
            >
              <div onClick={() => setOpen(true)}>
                <span className="menu_title">Delete</span>
              </div>
            </MenuItem>
          </Menu>
        </div>

        {/* DELETE ALLERGY */}
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Delete />
            <span className="alert_dialog_title_text">Delete Allergy ?</span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to delete this Allergy ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.RedButton}
              onClick={handleDeleteAllergen}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {showContainer && (
          <div>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)}>
              <TabContentHeader
                title="Add Allergy"
                buttonText="Add"
                loading={dataAddAllergens.loading}
              />
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Select Allergy*
                </p>
                <Controller
                  name="allergen__name"
                  control={control}
                  render={({
                    field: { onChange: Change, value, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        ref={ref}
                        multiple
                        id="allergen__name"
                        options={dataAllergens}
                        getOptionLabel={(option) => option?.name?.EN || ""}
                        value={value}
                        filterSelectedOptions
                        onChange={(_, data) => Change(data)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              color="secondary"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option?.name?.EN}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Allergy" />
                        )}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                  rules={{ validate: validateAtLeastOneFilled }}
                />
              </Box>
              <Box width="100%">
                <p className={ContainersClasses.section__subtitle}>
                  Select Ingredient
                </p>
                <Controller
                  name="ingredients"
                  control={control}
                  render={({
                    field: { onChange: Change, value, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        ref={ref}
                        multiple
                        id="ingredients"
                        options={ingredientsList?.getAllIngredients || []}
                        getOptionLabel={(option) => option?.name?.EN || ""}
                        value={value}
                        filterSelectedOptions
                        onChange={(_, data) => Change(data)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              color="secondary"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option?.name?.EN}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Ingredients" />
                        )}
                      />
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                  rules={{ validate: validateAtLeastOneFilled }}
                />
              </Box>
            </form>
          </div>
        )}
      </FullContainer>
    </>
  );
};

export default CustomerlistAllergies;
