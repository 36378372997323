import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { useSnackbar } from "notistack";
import moment from "moment";
import CountUp from "react-countup";
//material
import {
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FileCopy } from "@mui/icons-material";
//styles
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import Table from "../../layout/commun/Table";
import SplitButton from "../../reusable/SplitButton/SplitButton";
import FilterHeader from "../../pageHeader/FilterHeader";
//icons
import Info from "../../../public/icons/Info";
//Utils
import { SIDE_FILTER_LEADS, columns } from "../Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { getMessageError } from "../../Utils";
//mutation & queries
import {
  GetLeadsDocument,
  LeadPayload,
  LeadStatus,
  useEditLeadMutation,
  useGetLeadsLazyQuery,
  useGetLeadsQuery,
  useGetLeadsCountByStatusQuery,
} from "../../../graphql/types";

const ListLeads = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isAuthorizedToSeeDetails = useIsAuthorized("ADMIN_LEADS_DETAILS");
  const isAuthorizedToSeeLeadStats = useIsAuthorized("ADMIN_LEADS_BY_STATUS");

  //styles
  const classes = useStylesMenu();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [idLead, setIdLead] = useState<string | null>(null);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [status, setStatus] = useState<LeadStatus[]>([]);
  const [hasNote, setHasNote] = useState<boolean | null>(null);
  const [mine, setMine] = useState<boolean | null>(null);
  const [unattended, setUnattended] = useState<boolean | null>(null);
  const [linkCopied, setLinkCopied] = useState(false);

  //queries and muations
  const [updateLead] = useEditLeadMutation();
  const leadsCountByStatus = useGetLeadsCountByStatusQuery({
    variables: { input: { from: from, to: to } },
  });

  const dataGetLead = useGetLeadsQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        nameCustomer: search,
        status: status,
        from: from,
        to: to,
        hasANote: hasNote,
        assignedToMe: mine,
        Unattended: unattended,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
    fetchPolicy: "network-only",
  });

  const leads = useMemo(
    () => dataGetLead.data?.getLeads.data || [],
    [dataGetLead.data]
  );

  const count = useMemo(
    () => dataGetLead.data?.getLeads.count || 0,
    [dataGetLead.data]
  );

  //to get the list of all leads for excel export
  const [getLeadsData, { loading: leadListLoading }] = useGetLeadsLazyQuery();

  const handleExportExcel = async () => {
    await getLeadsData({
      variables: {
        input: {
          page: 1,
          documentsPerPage: count,
          nameCustomer: search,
          from: from,
          to: to,
          hasANote: hasNote,
          assignedToMe: mine,
          Unattended: unattended,
        },
      },
    })
      .then((data) => {
        if (count != 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const fileExtension = ".xlsx";
          const filteredLeads = data?.data?.getLeads?.data?.map((el, index) => {
            return {
              id: (index + 1).toString(),
              name: el.fname + " " + el.lname,
              gender: el.gender,
              age: moment().diff(el.dob, "years").toString(),
              phone: el.phone?.prefix + " " + el.phone?.number,
              email: el.email ? el.email : "null@null.com",
              identifier: el._id,
              assignedTo:
                (el?.assignedTo?.fname ?? "----") +
                " " +
                (el?.assignedTo?.lname ?? "----"),
              status: el.leadStatus,
              calories: el.calories,
            };
          });
          const ws = XLSX.utils.json_to_sheet(filteredLeads || []);
          ws["!cols"] = [
            { wpx: 40 },
            { wpx: 150 },
            { wpx: 70 },
            { wpx: 50 },
            { wpx: 100 },
            { wpx: 200 },
            { wpx: 180 },
            { wpx: 180 },
            { wpx: 180 },
            { wpx: 180 },
          ];
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const res = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(res, "leads_export" + fileExtension);
          return "Done";
        }
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };

  //functions
  const handleClickMenu =
    (item: LeadPayload) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setIdLead(item._id);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseConvert = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleCloseDetails = () => {
    setAnchorEl(null);
    if (idLead) {
      navigate(`/admin/leads/view_lead/${idLead}`);
    }
  };

  const handleCloseUpdate = () => {
    setAnchorEl(null);
    if (idLead) {
      navigate(`/admin/leads/update_lead/${idLead}`);
    }
  };

  const onConvertLead = () => {
    setAnchorEl(null);
    if (idLead) {
      navigate(`/admin/customers/converted_customer/${idLead}`);
    }
  };

  const handleCopyClick = (name: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      setLinkCopied(true);
      window.focus();
      navigator.clipboard.writeText(name);
      setTimeout(() => {
        setLinkCopied(false);
      }, 1000);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const updateStatusHandler = async (status: LeadStatus, id: string) => {
    await updateLead({
      variables: {
        editLeadInput: {
          id: id,
          leadStatus: status,
        },
      },
      refetchQueries: [GetLeadsDocument],
      onCompleted() {
        enqueueSnackbar("Lead status changed successfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
      onError(err) {
        const error = getMessageError(err);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      },
    });
  };

  //render
  const renderTableRows = () => {
    return leads.map((item) => {
      return {
        id: item?.ref,
        name: (
          <Box display="flex" alignItems="center" justifyContent="center">
            {isAuthorizedToSeeDetails ? (
              <a
                style={{ textDecoration: "none" }}
                href={`/admin/leads/view_lead/${item._id}`}
              >
                <Button>{`${item?.fname} ${item?.lname}`}</Button>
              </a>
            ) : (
              item.fname + " " + item.lname
            )}
            <Tooltip
              title={linkCopied ? "copied" : "Click here to copy the name"}
            >
              <IconButton
                onClick={() => handleCopyClick(item?.fname + " " + item?.lname)}
                aria-label="Copy"
              >
                <FileCopy style={{ color: "#000", width: "15px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
        lead__status: (
          <SplitButton
            defaultValue={item?.leadStatus || "INFORMATION_REQUIRED"}
            onStatuschange={updateStatusHandler}
            id={item._id}
          />
        ),
        mobile: item.phone?.prefix + " " + item.phone?.number,
        date: moment(item?.signedUpDate).format("DD-MMM-YYYY"),
        otp: item?.otp ? item?.otp : "---",
        calories: item.calories,
        contacted_by:
          (item?.assignedTo?.fname ?? "----") +
          " " +
          (item?.assignedTo?.lname ?? "----"),
        actions: (
          <IconButton onClick={handleClickMenu(item)}>
            <MoreVertIcon />
          </IconButton>
        ),
      };
    });
  };

  return (
    <>
      <FilterHeader
        buttonLink={
          useIsAuthorized("ADMIN_LEADS_CREATE")
            ? "/admin/leads/add_lead"
            : undefined
        }
        buttonText={
          useIsAuthorized("ADMIN_LEADS_CREATE") ? "Create" : undefined
        }
        showSearch={true}
        pageTitle="Leads"
        pageInfo="This is the list of all leads in the system"
        setIdsProgram={() => null}
        setStatus={setStatus}
        statusdata={status}
        setFrom={setFrom}
        from={from}
        setTo={setTo}
        to={to}
        hasNote={hasNote}
        setHasNote={setHasNote}
        setSearchValue={setSearch}
        filterConfig={SIDE_FILTER_LEADS}
        hideProgram={true}
        loadExcel={leadListLoading}
        generateExcel={handleExportExcel}
      />

      {isAuthorizedToSeeLeadStats && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            marginBottom={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              fontFamily="Poppins"
              fontWeight="400"
              fontSize={16}
              height="80px"
              width="150px"
              marginRight={2}
              borderRadius={2.5}
              boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
              textAlign="center"
            >
              TOTAL
              <Typography variant="h2" fontSize={22} fontWeight="600">
                <CountUp end={count || 0} duration={0.7} />
              </Typography>
            </Box>
            {leadsCountByStatus.data?.getLeadsCountByStatus.map((el, index) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  fontFamily="Poppins"
                  fontWeight="400"
                  fontSize={16}
                  height="80px"
                  width="150px"
                  marginRight={2}
                  borderRadius={2.5}
                  boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
                  textAlign="center"
                >
                  {el.leadStatus === "INFORMATION_REQUIRED"
                    ? "INFO_REQUIRED"
                    : el.leadStatus}
                  <Typography variant="h2" fontSize={22} fontWeight="600">
                    <CountUp end={el.count || 0} duration={0.7} />
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={mine || false}
            onChange={(e) => setMine(e.target.checked)}
          />
        }
        label="Mine"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={unattended || false}
            onChange={(e) => setUnattended(e.target.checked)}
          />
        }
        label="Unattended"
      />
      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetLead.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No Leads found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>

      <Box className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {useIsAuthorized("ADMIN_LEADS_DETAILS") && (
            <MenuItem
              onClick={handleCloseDetails}
              className={classes.menu_container}
              disableRipple
            >
              <Box>
                <span className="menu_title">Details</span>
              </Box>
            </MenuItem>
          )}

          {useIsAuthorized("ADMIN_LEADS_UPDATE") && (
            <MenuItem
              onClick={handleCloseUpdate}
              className={classes.menu_container}
              disableRipple
            >
              <Box>
                <span className="menu_title">Update</span>
              </Box>
            </MenuItem>
          )}

          {useIsAuthorized("ADMIN_LEADS_CONVERT") && (
            <MenuItem
              onClick={handleCloseConvert}
              className={classes.menu_container}
              disableRipple
            >
              <Box>
                <span className="menu_title">Convert</span>
              </Box>
            </MenuItem>
          )}
        </Menu>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          className={DialogClasses.dialog_container}
        >
          <DialogTitle className={DialogClasses.alert_dialog_title}>
            <Info />
            <span className="confirm_dialog_title_text">
              Convert Lead to Customer ?
            </span>
          </DialogTitle>
          <DialogContent className={DialogClasses.alert_dialog_content}>
            <DialogContentText>
              Are you sure you want to convert this Lead to a Customer ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={DialogClasses.alert_dialog_actions}>
            <Button
              className={ButtonsClasses.GreyButton}
              onClick={handleCloseModal}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              className={ButtonsClasses.BlueButton}
              onClick={onConvertLead}
              color="primary"
              autoFocus
            >
              Convert
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ListLeads;
