import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import clsx from "clsx";
//Material Ui
import {
  Box,
  Button,
  DialogContentText,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Autocomplete,
  Checkbox,
  InputAdornment,
  CircularProgress,
  Typography,
  Tooltip,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DiscountIcon from "@mui/icons-material/Discount";
//Components
import Table from "../../layout/commun/Table";
import TabContentHeader from "../../TabContentHeader/TabContentHeader";
//Styles
import { StatusContainer } from "../../../styles/Statuts__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { FullContainer } from "../../../styles/Customers__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//types
import {
  CustomerTransactionPayload,
  GetAllCustomerTransactionsDocument,
  PaymentMethod,
  PaymentPeriod,
  useActivateTransactionMutation,
  useApproveTransactionMutation,
  useChangeSuccessToPendingTransactionMutation,
  useCreateCustomTransactionMutation,
  useEditTransactionAmountMutation,
  useGetActifCustomerPlansQuery,
  useGetAllCustomerTransactionsQuery,
  useRegenerateDibsyLinkTransactionMutation,
  useRegenerateDipsyNapsLinkTransactionMutation,
  useSetPromoCodeFromTransactionMutation,
  useSetSecondPromoCodeFromTransactionMutation,
  useSwitchTransactionToCashMutation,
  useChangeTransactionsPaymentMethodMutation,
  useChangeTransactionStatusToActivatedMutation,
  useGetAddonDetailsFromTransactionLazyQuery,
  GetAddonDetailsFromTransactionPayload,
} from "../../../graphql/types";
import { ApolloError } from "@apollo/client";
//Utils
import { transactions } from "../Utils";
import {
  DialogTitleProps,
  getMessageError,
  handleDaysPerWeek,
  handlePayment,
  handlePaymentPeriod,
  handleTransactionStatus,
} from "../../Utils";
import { handleKind } from "../../transaction/Utils";
import { paymentMethodOptions } from "../../dashboard/ExpiringCustomers/Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { PaymentList } from "./Utils";
//icons²
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
//components
import ReceiptPrint from "./ReceiptPrint";
import { CustomerContext } from "../viewCustomer/ViewCustomer";
import InvoicePrint from "./InvoicePrint";
import AddOnsReceiptPrint from "./AddOnsReceiptPrint";
import CustomSplitButton from "../../reusable/SplitButton/CustomSpiltButton";
//styles
import { useStylesLeads } from "../../../styles/Leads__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesSelect } from "../../../styles/Select__styles";
import { useStylesRecipe } from "../../../styles/Recipe__styles";
import { useStylesDelivery } from "../../../styles/Delivery__styles";
import { StyledMenuItem } from "../CustomerMenuProgram/CustomerMenuProgram.styles";
//context
import { useAuth } from "../../../contextAPI";

const CustomerTransactions = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const auth = useAuth();

  //state
  const [linkCopied, setLinkCopied] = useState(false);
  const [napslinkCopied, setNapsLinkCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<CustomerTransactionPayload | null>(
    null
  );
  const [openPromoCodeModal, setOpenPromoCodeModal] = useState(false);
  const [openSecondPromoCodeModal, setOpenSecondPromoCodeModal] =
    useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [secondpromoCode, setSecondPromoCode] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openNapsModal, setOpenNapsModal] = useState<boolean>(false);
  const [openTransactionModal, setOpenTransactionModal] =
    useState<boolean>(false);
  const printRef = useRef<HTMLDivElement>(null);
  const printRefInvoice = useRef<HTMLDivElement>(null);
  const printRefAddOnReceipt = useRef<HTMLDivElement>(null);
  const [openAddonDetailsModal, setOpenAddonDetailsModal] = useState(false);
  const [addonDetails, setAddonDetails] =
    useState<GetAddonDetailsFromTransactionPayload>();
  // Pagination states
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setrowsPerPage] = useState<number>(30);

  //params
  const { id } = useParams();

  //context
  const context = useContext(CustomerContext);

  //styles
  const classes = useStylesMenu();
  const ButtonsClasses = useStylesButtons();
  const DialogClasses = useStylesDialog();
  const LeadsClasses = useStylesLeads();
  const ContainersClasses = useStylesContainers();
  const SelectClasses = useStylesSelect();
  const ButtonClasses = useStylesButtons();
  const RecipeClasses = useStylesRecipe();
  const DeliveryClasses = useStylesDelivery();

  //react hook form
  const methods = useForm<{ price: string }>({
    defaultValues: {
      price: "",
    },
  });

  const isAuthorizedToChangeMethod = useIsAuthorized(
    "ADMIN_TRANSACRION_CHANGE_METHOD"
  );

  const isAuthorizedToChangeToProcessing = useIsAuthorized(
    "CHANGE_TRANSACTION_STATUS_TO_PROCESS"
  );

  const customTransactionMethods = useForm<{
    customerProgram: string | null;
    paymentMethod: PaymentMethod | null;
    amount: string;
  }>({
    defaultValues: {
      customerProgram: null,
      paymentMethod: null,
      amount: "",
    },
  });

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = customTransactionMethods;

  const customTransactionDataWatch = useWatch({
    control,
  });

  //queries
  const {
    data: allCustomerTransactionsData,
    loading: LoadingallCustomerTransactionsData,
  } = useGetAllCustomerTransactionsQuery({
    variables: {
      getAllCustomerTransactionsInput: {
        customerId: id || "",
        documentsPerPage: rowsPerPage,
        page: page,
      },
    },
    fetchPolicy: "network-only",
  });

  const count = useMemo(
    () => allCustomerTransactionsData?.getAllCustomerTransactions.count || 0,
    [allCustomerTransactionsData?.getAllCustomerTransactions.count]
  );
  //mutations
  const [
    generatePaymentLink,
    { loading: loadingGeneratePaymentLink, data: dataGeneratePaymentLink },
  ] = useRegenerateDibsyLinkTransactionMutation();

  const [
    generateNapsPaymentLink,
    {
      loading: loadingGenerateNapsPaymentLink,
      data: dataGenerateNapsPaymentLink,
    },
  ] = useRegenerateDipsyNapsLinkTransactionMutation();

  const [approveTransaction, { loading: loadingApproveTransaction }] =
    useApproveTransactionMutation();

  const [editTransactionAmount] = useEditTransactionAmountMutation();

  const [changeTransactionPayment] =
    useChangeTransactionsPaymentMethodMutation();

  const [
    changeSuccessToPendingTransaction,
    { loading: loadingChangeSuccessToPendingTransaction },
  ] = useChangeSuccessToPendingTransactionMutation();

  const [
    changeTransactionStatusToActivated,
    { loading: loadingChangeTransactionStatusToActivated },
  ] = useChangeTransactionStatusToActivatedMutation();

  const [activateTransaction, { loading }] = useActivateTransactionMutation();
  const [switchToCashOnDelivery] = useSwitchTransactionToCashMutation();

  const [createCustomTransaction, { loading: loadingCreateCustomtransaction }] =
    useCreateCustomTransactionMutation();

  const [
    setPromoCodeToTransaction,
    { loading: loadingSetPromocodeToTransaction },
  ] = useSetPromoCodeFromTransactionMutation();
  const [
    setSecondPromoCodeToTransaction,
    { loading: loadingSetSecondPromocodeToTransaction },
  ] = useSetSecondPromoCodeFromTransactionMutation();

  const { data: CustomerActifPlansData } = useGetActifCustomerPlansQuery({
    variables: {
      customerId: id || "",
    },
    skip: !openTransactionModal,
  });

  const [AddonDetails] = useGetAddonDetailsFromTransactionLazyQuery();
  //consts
  const allCustomerTransactions =
    allCustomerTransactionsData?.getAllCustomerTransactions?.data;
  const CustomerActifPlans = CustomerActifPlansData?.getActifCustomerPlans;
  const dataWatch = useWatch({ control: methods.control });
  let textInput = useRef<HTMLTextAreaElement>();

  //functions
  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: CustomerTransactionPayload
  ) => {
    event.preventDefault();
    // item?.transactionStatus !== "SUCCESS" &&
    setAnchorEl(event.currentTarget);
    setSelected(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditMode = (item: CustomerTransactionPayload) => {
    setEditMode(true);
    setSelected(item);
    methods.setValue("price", String(item?.amount));
  };

  const cancelEditPrice = () => {
    setEditMode(false);
    setSelected(null);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseNapsModal = () => {
    setOpenNapsModal(false);
  };

  const handleClosePromoCodeModal = () => {
    setPromoCode("");
    setOpenPromoCodeModal(false);
  };

  const handleCloseSecondPromoCodeModal = () => {
    setSecondPromoCode("");
    setOpenSecondPromoCodeModal(false);
  };

  const handleCloseTransactionModal = () => {
    setOpenTransactionModal(false);
  };

  const handleOpenPromoCodeModal = () => {
    setAnchorEl(null);
    setOpenPromoCodeModal(true);
  };

  const handleOpenSecondPromoCodeModal = () => {
    setAnchorEl(null);
    setOpenSecondPromoCodeModal(true);
  };
  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              height: "fit-content",
            }}
          >
            <CloseIcon
              style={{
                minHeight: "22px",
                height: "22px",
                width: "22px",
                marginBottom: "0px",
              }}
            />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleGeneratePaymentLink = async () => {
    setAnchorEl(null);
    try {
      await generatePaymentLink({
        variables: {
          transactionId: selected?._id || "",
        },
        refetchQueries: [
          {
            query: GetAllCustomerTransactionsDocument,
            variables: {
              getAllCustomerTransactionsInput: {
                customerId: id || "",
                documentsPerPage: 30,
                page: 1,
              },
            },
          },
        ],
        onCompleted: () => {
          setAnchorEl(null);
          setOpen(true);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const handleGenerateNapsPaymentLink = async () => {
    setAnchorEl(null);
    try {
      await generateNapsPaymentLink({
        variables: {
          transactionId: selected?._id || "",
        },
        refetchQueries: [
          {
            query: GetAllCustomerTransactionsDocument,
            variables: {
              getAllCustomerTransactionsInput: {
                customerId: id || "",
                documentsPerPage: 30,
                page: 1,
              },
            },
          },
        ],
        onCompleted: () => {
          setAnchorEl(null);
          setOpenNapsModal(true);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const handleChangeSuccessToPendingTransaction = async () => {
    setAnchorEl(null);
    try {
      await changeSuccessToPendingTransaction({
        variables: {
          transactionId: selected?._id || "",
        },
        refetchQueries: [
          {
            query: GetAllCustomerTransactionsDocument,
            variables: {
              getAllCustomerTransactionsInput: {
                customerId: id || "",
                documentsPerPage: 30,
                page: 1,
              },
            },
          },
        ],
        onCompleted: () => {
          setAnchorEl(null);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const handleChangeTransactionStatusToActivated = async () => {
    setAnchorEl(null);
    try {
      await changeTransactionStatusToActivated({
        variables: {
          transactionId: selected?._id || "",
        },
        refetchQueries: [
          {
            query: GetAllCustomerTransactionsDocument,
            variables: {
              getAllCustomerTransactionsInput: {
                customerId: id || "",
                documentsPerPage: 30,
                page: 1,
              },
            },
          },
        ],
        onCompleted: () => {
          setAnchorEl(null);
          enqueueSnackbar(
            "Transaction status successfully changed to Activated",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const handleTransaction = async (approve: boolean) => {
    setAnchorEl(null);
    try {
      await approveTransaction({
        variables: {
          changeTransactionStatusInput: {
            approve,
            transactionId: selected?._id || "",
          },
        },
        refetchQueries: [
          {
            query: GetAllCustomerTransactionsDocument,
            variables: {
              getAllCustomerTransactionsInput: {
                customerId: id || "",
                documentsPerPage: 30,
                page: 1,
              },
            },
          },
        ],
        onCompleted: () => {
          setAnchorEl(null);
          enqueueSnackbar(
            approve
              ? "Payment succuessfully approved"
              : "Payment succuessfully canceled",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
          setTimeout(() => closeSnackbar(), 10000);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const handleActivateTransaction = async () => {
    setAnchorEl(null);
    try {
      await activateTransaction({
        variables: {
          input: {
            approve: true,
            transactionId: selected?._id || "",
          },
        },
        refetchQueries: [
          {
            query: GetAllCustomerTransactionsDocument,
            variables: {
              getAllCustomerTransactionsInput: {
                customerId: id || "",
                documentsPerPage: 30,
                page: 1,
              },
            },
          },
        ],
        onCompleted: () => {
          setAnchorEl(null);
          enqueueSnackbar(
            "Transaction status succuessfully changed to active",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
          setTimeout(() => closeSnackbar(), 10000);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const handleSwitchToCashOnDelivery = async () => {
    setAnchorEl(null);
    try {
      await switchToCashOnDelivery({
        variables: {
          input: selected?._id || "",
        },
        refetchQueries: [
          {
            query: GetAllCustomerTransactionsDocument,
            variables: {
              getAllCustomerTransactionsInput: {
                customerId: id || "",
                documentsPerPage: 30,
                page: 1,
              },
            },
          },
        ],
        onCompleted: () => {
          setAnchorEl(null);
          enqueueSnackbar(
            "Transaction succuessfully changed to cash on delivery",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
          setTimeout(() => closeSnackbar(), 10000);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const handleSubmitChangePaymentMethod = async (
    status: string,
    id: string
  ) => {
    try {
      await changeTransactionPayment({
        variables: {
          input: {
            transactionId: id,
            paymentMethod: status as PaymentMethod,
          },
        },
        refetchQueries: [GetAllCustomerTransactionsDocument],
        onCompleted: () => {
          setAnchorEl(null);
          enqueueSnackbar("Payment Method succuessfully Updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 10000);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const handleSubmitEditTransactionAmount = async (data: { price: string }) => {
    setEditMode(false);
    try {
      await editTransactionAmount({
        variables: {
          amount: Number(data.price || selected?.amount),
          transactionId: selected?._id || "",
        },
        refetchQueries: [
          {
            query: GetAllCustomerTransactionsDocument,
            variables: {
              getAllCustomerTransactionsInput: {
                customerId: id || "",
                documentsPerPage: 30,
                page: 1,
              },
            },
          },
        ],
        onCompleted: () => {
          setAnchorEl(null);
          enqueueSnackbar("Transaction price succuessfully Updated", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 10000);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
    }
  };

  const handleShowAddonDetails = async () => {
    setAnchorEl(null);
    await AddonDetails({
      variables: {
        transactionId: selected?._id || "",
      },
      onCompleted: (data) => {
        setAnchorEl(null);
        if (data?.getAddonDetailsFromTransaction) {
          setAddonDetails(data.getAddonDetailsFromTransaction);
          setOpenAddonDetailsModal(true);
        }
      },
      onError: (error) => {
        setAnchorEl(null);
        const errorMessage = getMessageError(error as ApolloError);
        enqueueSnackbar(errorMessage, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 10000);
      },
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    documentTitle: "Receipt generated on " + moment().format("DD/MM/YYYY dddd"),
  });

  const handlePrintInvoice = useReactToPrint({
    content: () => printRefInvoice?.current,
    documentTitle: "Invoice generated on " + moment().format("DD/MM/YYYY dddd"),
  });

  const handlePrintAddOnReceipt = useReactToPrint({
    content: () => printRefAddOnReceipt?.current,
    documentTitle:
      "Addons receipt generated on " + moment().format("DD/MM/YYYY dddd"),
  });

  const onCreateCustomTransaction: SubmitHandler<{
    customerProgram: string | null;
    paymentMethod: PaymentMethod | null;
    amount: string;
  }> = async (data) => {
    try {
      await createCustomTransaction({
        variables: {
          input: {
            customer: context?.getCustomerProfileById._id || "",
            customerPlan: data.customerProgram || "",
            paymentMethod: data.paymentMethod as PaymentMethod,
            amount: +data.amount,
          },
        },
        refetchQueries: [GetAllCustomerTransactionsDocument],
        onCompleted: () => {
          setOpenTransactionModal(false);
          enqueueSnackbar("Custom Transaction created successfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
          reset();
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  const handleApplyPromoCodeToTransaction = async () => {
    setAnchorEl(null);
    try {
      await setPromoCodeToTransaction({
        variables: {
          planId: selected?.customerPlan?._id || "",
          promoCode: promoCode,
        },
        refetchQueries: [
          {
            query: GetAllCustomerTransactionsDocument,
            variables: {
              getAllCustomerTransactionsInput: {
                customerId: id || "",
                documentsPerPage: 30,
                page: 1,
              },
            },
          },
        ],
        onCompleted: () => {
          setAnchorEl(null);
          enqueueSnackbar("PromoCode applied to transaction successfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 10000);
          setPromoCode("");
          setOpenPromoCodeModal(false);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
      setPromoCode("");
    }
  };
  const handleApplySecondPromoCodeToTransaction = async () => {
    setAnchorEl(null);
    try {
      await setSecondPromoCodeToTransaction({
        variables: {
          planId: selected?.customerPlan?._id || "",
          promoCode: secondpromoCode,
        },
        refetchQueries: [
          {
            query: GetAllCustomerTransactionsDocument,
            variables: {
              getAllCustomerTransactionsInput: {
                customerId: id || "",
                documentsPerPage: 30,
                page: 1,
              },
            },
          },
        ],
        onCompleted: () => {
          setAnchorEl(null);
          enqueueSnackbar(
            "Second PromoCode applied to transaction successfully",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
          setTimeout(() => closeSnackbar(), 10000);
          setSecondPromoCode("");
          setOpenSecondPromoCodeModal(false);
        },
      });
    } catch (err) {
      setAnchorEl(null);
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 10000);
      setSecondPromoCode("");
    }
  };

  //render
  const renderTableRows = useCallback(() => {
    return (
      allCustomerTransactions?.map((el) => {
        return {
          idTransaction: el.merchantReference,
          planName: el?.customerPlan?.program?.name?.EN,
          meals: `${el.customerPlan?.meals.length} meals`,
          frequency: `${handleDaysPerWeek(el?.customerPlan?.nbrOfDays)}/Week`,
          duration: handlePaymentPeriod(
            el?.customerPlan?.paymentPeriod as PaymentPeriod
          ),
          startDate: (
            <Typography
              style={{ fontSize: 12, fontWeight: "600", color: "green" }}
            >
              {moment(el?.customerPlan?.startDate).format("DD-MMM-YYYY")}
            </Typography>
          ),
          price: (
            <>
              <form
                onSubmit={methods.handleSubmit(
                  handleSubmitEditTransactionAmount
                )}
              >
                <Box display="flex" justifyContent="center">
                  {editMode && selected?._id === el._id ? (
                    <TextField
                      inputRef={textInput}
                      placeholder="Transaction price"
                      defaultValue={dataWatch.price}
                      size="small"
                      {...methods.register("price", {
                        required: "Enter transaction price",
                      })}
                      autoFocus
                    />
                  ) : (
                    `${el.amount} ${el.currency}`
                  )}

                  {editMode && selected?._id === el._id ? (
                    <>
                      <IconButton
                        type="submit"
                        style={{
                          width: "26px",
                          height: "26px",
                        }}
                      >
                        <SaveAltIcon
                          style={{ width: "16px", height: "16px" }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={cancelEditPrice}
                        style={{
                          width: "26px",
                          height: "26px",
                        }}
                      >
                        <HighlightOffIcon
                          style={{ width: "18px", height: "18px" }}
                        />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      onClick={() => handleEditMode(el)}
                      style={{
                        width: "26px",
                        height: "26px",
                      }}
                    >
                      <EditIcon style={{ width: "16px", height: "16px" }} />
                    </IconButton>
                  )}
                </Box>
              </form>
            </>
          ),

          promoCode: (
            <Box
              display="flex"
              alignItems="center"
              gap={0.5}
              justifyContent="center"
            >
              {el?.kind === "ADDON"
                ? el?.order?.promoCode
                : el?.customerPlan?.promoCode ?? "N/A"}
            </Box>
          ),

          secondpromoCode: el?.customerPlan?.secondPromoCode ?? "N/A",
          balance: (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={0.5}
            >
              {el?.customerPlan?.nbrOfBoxes}{" "}
              {el?.customerPlan?.nbrOfBoxes === 1 ? "Box" : "Boxes"}
            </Box>
          ),
          method: isAuthorizedToChangeMethod ? (
            <>
              <CustomSplitButton
                defaultValue={el?.paymentMethod || "NAPS"}
                onStatuschange={handleSubmitChangePaymentMethod}
                id={el._id || ""}
                optionsList={PaymentList}
              />
            </>
          ) : (
            handlePayment(el.paymentMethod || "UNSPECIFIED")
          ),

          transDate: moment(el.date).format("DD/MM/YYYY"),
          transferred: el.transferred ? "Yes" : "No",
          kind: handleKind(el.kind),
          status: (
            <StatusContainer
              sx={{
                backgroundColor: handleTransactionStatus(el.transactionStatus)
                  .color,
                fontSize: "12px",
              }}
            >
              {handleTransactionStatus(el.transactionStatus).text}
            </StatusContainer>
          ),
          actions: (
            <IconButton
              onClick={(e) => {
                handleClickMenu(e, el);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          ),
        };
      }) || []
    );
  }, [allCustomerTransactionsData, editMode, dataWatch.price]);

  const renderItems = () => {
    return CustomerActifPlans?.map((item, index: number) => {
      return (
        <StyledMenuItem
          key={item._id}
          value={item?._id || ""}
          className={
            customTransactionDataWatch?.customerProgram === item._id
              ? clsx(
                  SelectClasses.option_item,
                  SelectClasses.option_item_selected
                )
              : SelectClasses.option_item
          }
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <span>{item?.program?.name?.EN}</span>
            <div className="left-side-container">
              <span>
                {`(${moment(item.startDate).format("DD/MM/YYYY")} - ${moment(
                  item.expiryDate
                ).format("DD/MM/YYYY")})`}{" "}
              </span>
            </div>
          </Box>
        </StyledMenuItem>
      );
    });
  };

  const handleCopyClick = () => {
    setLinkCopied(true);
    navigator.clipboard.writeText(
      dataGeneratePaymentLink?.regenerateDibsyLinkTransaction || ""
    );
    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  const handleCopyNapsLink = () => {
    setNapsLinkCopied(true);
    navigator.clipboard.writeText(
      dataGenerateNapsPaymentLink?.regenerateDipsyNapsLinkTransaction || ""
    );
    setTimeout(() => {
      setNapsLinkCopied(false);
    }, 3000);
  };

  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setrowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  //render
  return (
    <FullContainer>
      <TabContentHeader
        title={"Payment"}
        buttonText="Create Custom Transaction"
        buttonFnc={() => setOpenTransactionModal(true)}
      />

      <Table
        columns={transactions}
        loading={LoadingallCustomerTransactionsData}
        numberOfFakeRow={30}
        data={renderTableRows()}
        emptyMessage="No Payment found !"
        tablePaginationProps={{
          rowsPerPageOptions: [30, 60, 90],
          count: count,
          page: page - 1,
          onPageChange,
          rowsPerPage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
        paginationProps={{
          page: page,
          count: Math.ceil(count / rowsPerPage),
          showFirstButton: true,
          showLastButton: true,
          boundaryCount: 1,
          siblingCount: 2,
          onChange: onPageChange,
        }}
      />

      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          <Box display="flex" flexDirection="column">
            {selected && (
              <MenuItem
                onClick={handlePrintInvoice}
                className={classes.menu_container}
                disableRipple={true}
                disabled={loadingGeneratePaymentLink}
              >
                <div>
                  <span className="menu_title">Download Invoice</span>
                </div>
              </MenuItem>
            )}
            {selected && selected.transactionStatus !== "SUCCESS" && (
              <MenuItem
                onClick={handleGeneratePaymentLink}
                className={classes.menu_container}
                disableRipple={true}
                disabled={loadingGeneratePaymentLink}
              >
                <div>
                  <span className="menu_title">
                    Generate Dibsy payment link
                  </span>
                </div>
              </MenuItem>
            )}
            {selected && selected.transactionStatus !== "SUCCESS" && (
              <MenuItem
                onClick={handleGenerateNapsPaymentLink}
                className={classes.menu_container}
                disableRipple={true}
                disabled={loadingGenerateNapsPaymentLink}
              >
                <div>
                  <span className="menu_title">
                    Generate Dibsy Naps payment link
                  </span>
                </div>
              </MenuItem>
            )}
            {selected && selected.transactionStatus === "SUCCESS" && (
              <MenuItem
                onClick={handleChangeSuccessToPendingTransaction}
                className={classes.menu_container}
                disableRipple={true}
                disabled={loadingChangeSuccessToPendingTransaction}
              >
                <div>
                  <span className="menu_title">
                    Change transaction status to pending
                  </span>
                </div>
              </MenuItem>
            )}
            {selected &&
              isAuthorizedToChangeToProcessing &&
              selected.transactionStatus === "PENDING" && (
                <MenuItem
                  onClick={handleChangeTransactionStatusToActivated}
                  className={classes.menu_container}
                  disableRipple={true}
                  disabled={loadingChangeTransactionStatusToActivated}
                >
                  <div>
                    <span className="menu_title">
                      Change transaction status to activated
                    </span>
                  </div>
                </MenuItem>
              )}
            {selected &&
              selected.transactionStatus === "SUCCESS" &&
              selected.kind != "ADDON" && (
                <MenuItem
                  onClick={handlePrint}
                  className={classes.menu_container}
                  disableRipple={true}
                  disabled={loadingChangeSuccessToPendingTransaction}
                >
                  <div>
                    <span className="menu_title">Download Receipt</span>
                  </div>
                </MenuItem>
              )}
            {selected &&
              selected.transactionStatus === "SUCCESS" &&
              selected.kind === "ADDON" && (
                <MenuItem
                  onClick={handlePrintAddOnReceipt}
                  className={classes.menu_container}
                  disableRipple={true}
                  disabled={loadingChangeSuccessToPendingTransaction}
                >
                  <div>
                    <span className="menu_title">Download AddOn Receipt</span>
                  </div>
                </MenuItem>
              )}

            {selected &&
              (selected.transactionStatus === "SUCCESS" ||
                selected.transactionStatus === "PENDING") &&
              selected.kind === "PLAN" &&
              !selected.customerPlan?.promoCode && (
                <MenuItem
                  onClick={handleOpenPromoCodeModal}
                  className={classes.menu_container}
                  disableRipple={true}
                  disabled={loadingChangeSuccessToPendingTransaction}
                >
                  <div>
                    <span className="menu_title">Apply promocode</span>
                  </div>
                </MenuItem>
              )}
            {selected &&
              (selected.transactionStatus === "SUCCESS" ||
                selected.transactionStatus === "PENDING") &&
              selected.kind === "PLAN" && (
                <MenuItem
                  onClick={handleOpenSecondPromoCodeModal}
                  className={classes.menu_container}
                  disableRipple={true}
                  disabled={loadingChangeSuccessToPendingTransaction}
                >
                  <div>
                    <span className="menu_title">
                      Apply second promocode (wallet promocode)
                    </span>
                  </div>
                </MenuItem>
              )}
            {selected &&
              (selected.transactionStatus === "SCHEDULED_PAYMENT" ||
                selected.transactionStatus === "FAILED" ||
                selected.transactionStatus === "ACTIVATED" ||
                selected.transactionStatus === "PENDING") && (
                <MenuItem
                  onClick={() => handleTransaction(true)}
                  className={classes.menu_container}
                  disableRipple={true}
                  disabled={loadingApproveTransaction}
                >
                  <div>
                    <span className="menu_title">Payment Received</span>
                  </div>
                </MenuItem>
              )}
            {selected &&
              selected.transactionStatus === "SCHEDULED_PAYMENT" &&
              !loading && (
                <MenuItem
                  onClick={handleActivateTransaction}
                  className={classes.menu_container}
                  disableRipple={true}
                  disabled={loadingApproveTransaction}
                >
                  <div>
                    <span className="menu_title">Activation</span>
                  </div>
                </MenuItem>
              )}
            {selected &&
              (selected.transactionStatus === "FAILED" ||
                selected.transactionStatus === "CREATED") && (
                <MenuItem
                  onClick={handleSwitchToCashOnDelivery}
                  className={classes.menu_container}
                  disableRipple={true}
                  disabled={loadingApproveTransaction}
                >
                  <div>
                    <span className="menu_title">
                      Switch to cash on delivery
                    </span>
                  </div>
                </MenuItem>
              )}
            {selected &&
              (selected.transactionStatus === "SCHEDULED_PAYMENT" ||
                selected.transactionStatus === "PENDING") && (
                <MenuItem
                  onClick={() => handleTransaction(false)}
                  className={classes.menu_container}
                  disableRipple={true}
                  disabled={loadingApproveTransaction}
                >
                  <div>
                    <span className="menu_title">Cancel Transaction</span>
                  </div>
                </MenuItem>
              )}
            {selected && selected.kind === "ADDON" && (
              <MenuItem
                onClick={handleShowAddonDetails}
                className={classes.menu_container}
                disableRipple={true}
              >
                <div>
                  <span className="menu_title">Add on Details</span>
                </div>
              </MenuItem>
            )}
          </Box>
        </Menu>
      </div>

      {/* Dibsy payment modal */}
      <Dialog
        open={open}
        onClose={handleCloseModal}
        className={DialogClasses.dialog_container_generate_link}
      >
        <BootstrapDialogTitle
          className={DialogClasses.alert_dialog_title}
          onClose={handleCloseModal}
        >
          <span className="confirm_dialog_title_text">
            Regeneration payment link
          </span>
        </BootstrapDialogTitle>
        <DialogContent
          className={DialogClasses.dialog_content_transaction_link}
        >
          <DialogContentText>
            {dataGeneratePaymentLink?.regenerateDibsyLinkTransaction ? (
              <>
                Payment link for the "{selected?._id}" transaction:
                <br />
                <br />
                <Box>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    value={
                      dataGeneratePaymentLink?.regenerateDibsyLinkTransaction
                    }
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <Tooltip
                          title={
                            linkCopied
                              ? "copied"
                              : "Click here to copy the payment link"
                          }
                        >
                          <IconButton
                            onClick={handleCopyClick}
                            aria-label="Copy"
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Box>
              </>
            ) : (
              `An error occurred while generating the payment link for this transaction: ${selected?._id}`
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseModal}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Naps payment modal */}
      <Dialog
        open={openNapsModal}
        onClose={handleCloseNapsModal}
        className={DialogClasses.dialog_container_generate_link}
      >
        <BootstrapDialogTitle
          className={DialogClasses.alert_dialog_title}
          onClose={handleCloseNapsModal}
        >
          <span className="confirm_dialog_title_text">
            Regeneration Naps payment link
          </span>
        </BootstrapDialogTitle>
        <DialogContent
          className={DialogClasses.dialog_content_transaction_link}
        >
          <DialogContentText>
            {dataGenerateNapsPaymentLink?.regenerateDipsyNapsLinkTransaction ? (
              <>
                Payment link for the "{selected?._id}" transaction:
                <br />
                <br />
                <Box>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    value={
                      dataGenerateNapsPaymentLink?.regenerateDipsyNapsLinkTransaction
                    }
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <Tooltip
                          title={
                            napslinkCopied
                              ? "copied"
                              : "Click here to copy the payment link"
                          }
                        >
                          <IconButton
                            onClick={handleCopyNapsLink}
                            aria-label="Copy"
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Box>
              </>
            ) : (
              `An error occurred while generating the payment link for this transaction: ${selected?._id}`
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseNapsModal}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Custom transaction modal */}
      <Dialog
        open={openTransactionModal}
        onClose={handleCloseTransactionModal}
        className={DialogClasses.dialog_CustomTransaction_container}
      >
        <BootstrapDialogTitle
          className={DialogClasses.alert_dialog_title}
          onClose={handleCloseTransactionModal}
        >
          <span
            className="confirm_dialog_title_text"
            style={{ alignSelf: "flex-start" }}
          >
            Create Custom Transaction for{" "}
            {context?.getCustomerProfileById.fname +
              " " +
              context?.getCustomerProfileById.lname}
          </span>
        </BootstrapDialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onCreateCustomTransaction)}>
            <Box display="flex" flexDirection="column">
              <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={4}>
                <Box width="100%">
                  <Box display="flex" flexDirection="column">
                    <p className={ContainersClasses.section__subtitle}>
                      Choose the plan
                    </p>
                    <Controller
                      name="customerProgram"
                      control={control}
                      render={({
                        field: { ref, onChange, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Select
                            {...rest}
                            sx={{ minWidth: "400px" }}
                            className={LeadsClasses.select_container}
                            onChange={(event) => {
                              let plan = event.target.value;
                              onChange(plan);
                            }}
                            value={customTransactionDataWatch.customerProgram}
                          >
                            {renderItems()}
                          </Select>
                          <p
                            className={ContainersClasses.section__error__helper}
                          >
                            {error?.message}
                          </p>
                        </>
                      )}
                      rules={{
                        required: {
                          message: "The Plan is Required",
                          value: true,
                        },
                      }}
                    />
                  </Box>
                  <Box width="100%">
                    <p className={ContainersClasses.section__subtitle}>
                      Amount
                    </p>
                    <TextField
                      id="demo-helper-text-misaligned"
                      placeholder="Amount"
                      fullWidth
                      type="number"
                      {...register("amount", {
                        required: "Amount is Mandatory",
                      })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {auth.countryCurrency}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors?.amount?.message && (
                      <p className={RecipeClasses.recipe__error__helper}>
                        {errors?.amount?.message}
                      </p>
                    )}
                  </Box>
                </Box>
                <Box width="100%">
                  <Box>
                    <p className={ContainersClasses.section__subtitle}>
                      Payment method
                    </p>
                    <Controller
                      name="paymentMethod"
                      rules={{
                        required: "Select Payment method",
                      }}
                      control={control}
                      render={({
                        field: { onChange: Change, value, ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            ref={ref}
                            id="paymentMethod"
                            value={customTransactionDataWatch.paymentMethod}
                            options={paymentMethodOptions}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={(_, data) => Change(data)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  color="secondary"
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Payment Method"
                              />
                            )}
                          />
                          <p
                            className={ContainersClasses.section__error__helper}
                          >
                            {error?.message}
                          </p>
                        </>
                      )}
                    />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="right"
                    marginTop={9}
                  >
                    <Button
                      variant="contained"
                      className={ButtonClasses.button_open_list_page}
                      type="submit"
                    >
                      {loadingCreateCustomtransaction && (
                        <CircularProgress
                          size={20}
                          style={{ color: "white", marginRight: 6 }}
                        />
                      )}
                      Create Transaction
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      {/* Apply promocode to transaction modal */}
      <Dialog
        open={openPromoCodeModal}
        onClose={handleClosePromoCodeModal}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <DiscountIcon />
          <span className="alert_dialog_title_text">Apply PromoCode</span>
        </DialogTitle>
        <DialogContent>
          <Box>
            <p className={DeliveryClasses.delivery__subtitle__checkbox}>
              PromoCode*
            </p>
            <TextField
              required
              id="demo-helper-text-misaligned"
              placeholder="PromoCode"
              fullWidth
              label="PromoCode"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
            />
          </Box>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleClosePromoCodeModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            onClick={handleApplyPromoCodeToTransaction}
            disabled={
              loadingSetPromocodeToTransaction || promoCode.length === 0
            }
          >
            {loadingSetPromocodeToTransaction && (
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "white" }}
              />
            )}
            Apply PromoCode
          </Button>
        </DialogActions>
      </Dialog>
      {/* Apply second promocode to transaction modal */}
      <Dialog
        open={openSecondPromoCodeModal}
        onClose={handleCloseSecondPromoCodeModal}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <DiscountIcon />
          <span className="alert_dialog_title_text">
            Apply Second PromoCode
          </span>
        </DialogTitle>
        <DialogContent>
          <Box>
            <p className={DeliveryClasses.delivery__subtitle__checkbox}>
              Second PromoCode*
            </p>
            <TextField
              required
              id="demo-helper-text-misaligned"
              placeholder="Second PromoCode"
              fullWidth
              label="Second PromoCode"
              value={secondpromoCode}
              onChange={(e) => setSecondPromoCode(e.target.value.toUpperCase())}
            />
          </Box>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseSecondPromoCodeModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            onClick={handleApplySecondPromoCodeToTransaction}
            disabled={
              loadingSetSecondPromocodeToTransaction ||
              secondpromoCode.length === 0
            }
          >
            {loadingSetSecondPromocodeToTransaction && (
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "white" }}
              />
            )}
            Apply Second PromoCode
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddonDetailsModal}
        onClose={() => setOpenAddonDetailsModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Addon Details</DialogTitle>
        <DialogContent>
          {addonDetails && (
            <Box display="flex" flexDirection="column" gap={2}>
              {addonDetails.addons?.map((addon, index) => (
                <Box key={index} display="flex" flexDirection="column" gap={2}>
                  <Typography variant="body1">
                    Name:{" "}
                    {Array.isArray(addon.name)
                      ? addon.name.join(", ")
                      : addon.name}
                  </Typography>
                  <Typography variant="body1">
                    Quantity: {addon.quantity}
                  </Typography>
                </Box>
              ))}
              <Typography variant="body1">
                Date: {moment(addonDetails?.date).format("DD/MM/YYYY")}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenAddonDetailsModal(false)}
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ display: "none" }}>
        <ReceiptPrint
          receiptData={selected}
          ref={printRef}
          clientName={
            context?.getCustomerProfileById.fname +
            " " +
            context?.getCustomerProfileById.lname
          }
          clientMobile={
            context?.getCustomerProfileById.phone?.prefix +
            " " +
            context?.getCustomerProfileById.phone?.number
          }
        />
      </div>

      <div style={{ display: "none" }}>
        <InvoicePrint
          receiptData={selected}
          ref={printRefInvoice}
          clientName={
            context?.getCustomerProfileById.fname +
            " " +
            context?.getCustomerProfileById.lname
          }
          clientMobile={
            context?.getCustomerProfileById.phone?.prefix +
            " " +
            context?.getCustomerProfileById.phone?.number
          }
        />
      </div>

      <div style={{ display: "none" }}>
        <AddOnsReceiptPrint
          receiptData={selected}
          ref={printRefAddOnReceipt}
          clientName={
            context?.getCustomerProfileById.fname +
            " " +
            context?.getCustomerProfileById.lname
          }
          clientMobile={
            context?.getCustomerProfileById.phone?.prefix +
            " " +
            context?.getCustomerProfileById.phone?.number
          }
        />
      </div>
    </FullContainer>
  );
};

export default CustomerTransactions;
