import { useNavigate, useParams } from "react-router";
//material
import {
  Box,
  Chip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
//styles
import {
  useStylesIngredient,
  Container,
} from "../../../styles/Ingredient__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesForms } from "../../../styles/Forms__styles";
//types
import { useGetIngredientByIdQuery } from "../../../graphql/types";
import { Column } from "../Types";
//utils
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
//context
import { useAuth } from "../../../contextAPI";

const columns: Column[] = [
  { id: "nutrition", label: "Nutrition Information" },
  { id: "value", label: "Value" },
];
const ViewIngredient = () => {
  const navigate = useNavigate();
  const params = useParams();
  const idIngredient = params.id || null;
  const auth = useAuth();

  //styles
  const IngredientClasses = useStylesIngredient();
  const ButtonClasses = useStylesButtons();
  const FormsClasses = useStylesForms();

  //queries & mutations
  const { data: dataIngredient, loading: loadingIngredient } =
    useGetIngredientByIdQuery({
      variables: { ingredientId: idIngredient || "" },
      skip: !idIngredient,
      fetchPolicy: "network-only",
    });

  //render
  return (
    <div>
      <Container>
        {loadingIngredient ? (
          <Box className={IngredientClasses.ingredient__view__titles}>
            <Skeleton
              variant="text"
              height={25}
              width={120}
              className={IngredientClasses.ingredient__name__title}
            />
            <Skeleton
              variant="text"
              height={25}
              width={100}
              className={IngredientClasses.ingredient__id}
            />
          </Box>
        ) : (
          <Box className={IngredientClasses.ingredient__view__titles}>
            <p className={IngredientClasses.ingredient__name__title}>
              {dataIngredient?.getIngredientById?.name?.EN}
            </p>
            <span className={IngredientClasses.ingredient__id}>
              #{dataIngredient?.getIngredientById?.ref}
            </span>
          </Box>
        )}
        {dataIngredient?.getIngredientById?.name?.AR && (
          <Box className={IngredientClasses.ingredient__view__titles}>
            <p className={IngredientClasses.ingredient__name__title}>
              {dataIngredient?.getIngredientById?.name?.AR}
            </p>
          </Box>
        )}
        <Box className={IngredientClasses.alergen_cost_container}>
          {dataIngredient?.getIngredientById?.allergenGroups &&
          dataIngredient?.getIngredientById?.allergenGroups?.length > 0 ? (
            <Box>
              <p className={IngredientClasses.ingredient__title}>
                Allergen Categories
              </p>
              {loadingIngredient ? (
                <Box className={IngredientClasses.ingredient__tags__container}>
                  <Skeleton variant="text" height={25} width={90} />
                  <Skeleton variant="text" height={25} width={90} />
                  <Skeleton variant="text" height={25} width={90} />
                  <Skeleton variant="text" height={25} width={90} />
                </Box>
              ) : (
                <Box className={IngredientClasses.ingredient__tags__container}>
                  {dataIngredient?.getIngredientById?.allergenGroups?.map(
                    (el) => (
                      <Chip
                        key={el._id}
                        label={el.name.EN}
                        variant="outlined"
                        className={FormsClasses.chips__tags}
                      />
                    )
                  )}
                </Box>
              )}
            </Box>
          ) : (
            <div></div>
          )}

          {dataIngredient?.getIngredientById?.cost ? (
            <Box>
              <p className={IngredientClasses.ingredient__title}>
                Ingredient Cost
              </p>
              <p className={IngredientClasses.cost_font}>
                {auth.countryCurrency} {dataIngredient?.getIngredientById?.cost}
              </p>
            </Box>
          ) : (
            <Box>
              <p className={IngredientClasses.ingredient__title}>
                Ingredient Cost
              </p>
              <p className={IngredientClasses.cost_font}>
                {auth.countryCurrency} 0
              </p>
            </Box>
          )}
        </Box>
        <Box>
          <p className={IngredientClasses.ingredient__title}>
            Nutrition Informations
          </p>
          <TableContainer>
            <Table sx={{ minWidth: 100 }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className={IngredientClasses.ingredient__cell}
                      key={column.id}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {loadingIngredient ? (
                <TableBody>
                  {columns.map((column) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={column.id}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={IngredientClasses.ingredient__cell}
                        style={{ color: "grey" }}
                      >
                        <Skeleton
                          variant="text"
                          height={25}
                          width={90}
                          style={{ color: "grey" }}
                        />
                      </TableCell>
                      <TableCell
                        className={IngredientClasses.ingredient__cell}
                        style={{ color: "grey" }}
                      >
                        <Skeleton
                          variant="text"
                          height={25}
                          width={90}
                          style={{ color: "grey" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  {dataIngredient?.getIngredientById?.nutritions?.map((row) => (
                    <TableRow
                      key={row.nutrition._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={IngredientClasses.ingredient__cell}
                      >
                        {row.nutrition.name}
                      </TableCell>
                      <TableCell className={IngredientClasses.ingredient__cell}>
                        {`${row.quantity} ${row.nutrition.unit}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>

        {useIsAuthorized("FOOD_INGREDIENTS_UPDATE") && (
          <Box className={IngredientClasses.button_container}>
            <Button
              variant="contained"
              className={ButtonClasses.button_submit_form}
              type="submit"
              onClick={() =>
                navigate(`/food/ingredients/update_ingredient/${idIngredient}`)
              }
            >
              Edit Ingredient
            </Button>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default ViewIngredient;
