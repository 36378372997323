import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useForm,
  useWatch,
  SubmitHandler,
  FormProvider,
  Controller,
} from "react-hook-form";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import moment from "moment";
//material
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DateAdapter from "@mui/lab/AdapterMoment";
import { DesktopTimePicker, LocalizationProvider } from "@mui/lab";
//icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
//styles
import {
  useStylesDelivery,
  Container,
} from "../../../../styles/Delivery__styles";
import { useStylesButtons } from "../../../../styles/Buttons__styles";
import { useStylesContainers } from "../../../../styles/Containers__styles";
import { useStylesDialog } from "../../../../styles/Dialog__styles";
// mutations and queries
import {
  useGetDriversQuery,
  useCreateDeliveryAreaMutation,
  useGetActiveAreasQuery,
  useDeleteAreaMutation,
  useUpdateAreaMutation,
  AreaPayload,
  GetActiveAreasDocument,
} from "../../../../graphql/types";
//types
import { AreaProps } from "../../Types";
import { ApolloError } from "@apollo/client";
//utils
import { getMessageError } from "../../../Utils";

const AddArea = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  //state
  const [openDeleteArea, setOpenDeleteArea] = useState<boolean>(false);
  const [openUpdateArea, setOpenUpdateArea] = useState<boolean>(false);
  const [chosenToDeleteAreaName, setChosenToDeleteAreaName] =
    useState<string>("");
  const [chosenToDeleteAreaId, setChosenToDeleteAreaId] = useState<string>("");
  const [chosenToUpdateAreaName, setChosenToUpdateAreaName] =
    useState<string>("");
  const [chosenToUpdateAreaId, setChosenToUpdateAreaId] = useState<string>("");

  //style
  const DeliveryClasses = useStylesDelivery();
  const ButtonClasses = useStylesButtons();
  const ContainersClasses = useStylesContainers();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  // queries and mutations
  const [createDeliveryArea, { loading }] = useCreateDeliveryAreaMutation();

  const [deleteArea, { loading: loadingDeleteArea }] = useDeleteAreaMutation();

  const [EditArea, { loading: loadingEditArea }] = useUpdateAreaMutation();

  const dataArea = useGetActiveAreasQuery();

  const dataDrivers = useGetDriversQuery();

  const customerAreas = React.useMemo(
    () => dataArea?.data?.getAreas,
    [dataArea?.data]
  );

  const drivers = React.useMemo(
    () => dataDrivers?.data?.getDrivers || [],
    [dataDrivers?.data]
  );

  //react-hook-form
  const methods = useForm<AreaProps>({
    defaultValues: {
      area__name: null,
      driver__name: null,
      fridayDriver__name: null,
      active: true,
      morningDelivery: false,
      eveningDelivery: false,
      morningFrom: null,
      morningTo: null,
      eveningFrom: null,
      eveningTo: null,
      fridayMorningFrom: null,
      fridayMorningTo: null,
      fridayEveningFrom: null,
      fridayEveningTo: null,
    },
  });
  const { handleSubmit, setValue, getValues, control } = methods;

  const dataWatch = useWatch({
    control,
  });

  const activeWatch = useWatch({
    control,
    name: "active",
  });

  //functions
  const onSubmit: SubmitHandler<AreaProps> = async (data) => {
    const morningDeliveryTimeSet = data.morningFrom || data.morningTo;
    const fridayMorningTimeSet = data.fridayMorningFrom || data.fridayMorningTo;
    const eveningDeliveryTimeSet = data.eveningFrom || data.eveningTo;
    const fridayEveningTimeSet = data.fridayEveningFrom || data.fridayEveningTo;
    if (
      data.morningDelivery &&
      !morningDeliveryTimeSet &&
      !fridayMorningTimeSet
    ) {
      enqueueSnackbar(
        "Please set at least one time for Morning Delivery (From or To) or a Friday Morning Delivery Time (From or To).",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
      setTimeout(() => closeSnackbar(), 5000);
      return;
    }

    if (
      data.eveningDelivery &&
      !eveningDeliveryTimeSet &&
      !fridayEveningTimeSet
    ) {
      enqueueSnackbar(
        "Please set at least one time for Evening Delivery (From or To) or a Friday Morning Delivery Time (From or To).",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
      setTimeout(() => closeSnackbar(), 5000);
      return;
    }
    try {
      await createDeliveryArea({
        variables: {
          createDeliveryAreaInput: {
            area: data?.area__name?._id || "",
            driver: data?.driver__name?._id || "",
            fridayDriver: data?.fridayDriver__name?._id || "",
            isActive: data?.active,

            morningDelivery: data.morningDelivery
              ? data.morningDelivery
              : false,
            eveningDelivery: data.eveningDelivery
              ? data.eveningDelivery
              : false,

            morningTime: {
              from: moment(data?.morningFrom).format("HH:mm A"),
              to: moment(data?.morningTo).format("HH:mm A"),
            },
            eveningTime: {
              from: moment(data?.eveningFrom).format("HH:mm A"),
              to: moment(data?.eveningTo).format("HH:mm A"),
            },
            fridayMorningTime: {
              from: moment(data?.fridayMorningFrom).format("HH:mm A"),
              to: moment(data?.fridayMorningTo).format("HH:mm A"),
            },
            fridayEveningTime: {
              from: moment(data?.fridayEveningFrom).format("HH:mm A"),
              to: moment(data?.fridayEveningTo).format("HH:mm A"),
            },
          },
        },
        onCompleted: () => {
          navigate("/admin/delivery_area/list_areas");
          enqueueSnackbar("Delivery Area succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (error) {
      const err = getMessageError(error as ApolloError);
      enqueueSnackbar(err, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    }
  };

  const handleCloseDeleteAreaModal = () => {
    setOpenDeleteArea(false);
  };

  const handleCloseEditModal = () => {
    setOpenUpdateArea(false);
  };

  const handleclickDeleteArea = (
    ToBeDeletedAreaName: string,
    toBeDeletedAreaId: string
  ) => {
    setOpenDeleteArea(true);
    setChosenToDeleteAreaName(ToBeDeletedAreaName);
    setChosenToDeleteAreaId(toBeDeletedAreaId);
  };

  const handleclickEditArea = (
    ToBeUpdatedAreaName: string,
    toBeUpdatedAreaId: string
  ) => {
    setOpenUpdateArea(true);
    setChosenToUpdateAreaName(ToBeUpdatedAreaName);
    setChosenToUpdateAreaId(toBeUpdatedAreaId);
  };

  const onDeleteArea = async () => {
    await deleteArea({
      variables: {
        input: chosenToDeleteAreaId,
      },
      refetchQueries: [GetActiveAreasDocument],
      onCompleted: () => {
        enqueueSnackbar("Area deleted succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
        handleCloseDeleteAreaModal();
        setValue("area__name", null);
      },
      onError(error) {
        const err = getMessageError(error as ApolloError);
        enqueueSnackbar(err, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        handleCloseDeleteAreaModal();
      },
    });
  };

  const onUpdateArea = async () => {
    await EditArea({
      variables: {
        input: { id: chosenToUpdateAreaId, name: chosenToUpdateAreaName },
      },
      refetchQueries: [GetActiveAreasDocument],
      onCompleted: () => {
        enqueueSnackbar("Area Edited succuessfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
        handleCloseEditModal();
        setValue("area__name", null);
        setChosenToUpdateAreaName("");
      },
      onError(error) {
        const err = getMessageError(error as ApolloError);
        enqueueSnackbar(err, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        handleCloseEditModal();
        setChosenToUpdateAreaName("");
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <Container>
        <p className={clsx(DeliveryClasses.delivery__title)}>Create Area</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={clsx(DeliveryClasses.area__container)}>
            <Box
              sx={{
                width: "85%",
              }}
            >
              <p className={ContainersClasses.section__subtitle}>Area Name*</p>
              <Controller
                name={`area__name`}
                control={control}
                rules={{ required: "Select Area Name" }}
                render={({
                  field: { ref, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      onChange={(_, value) => {
                        onChange(value);
                      }}
                      ref={ref}
                      disablePortal
                      id="area__name"
                      options={customerAreas || []}
                      value={dataWatch?.area__name as AreaPayload}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Checkbox
                            color="secondary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                          <Box display="flex" flexDirection="row">
                            <Box
                              onClick={() =>
                                handleclickDeleteArea(
                                  option.name || "",
                                  option._id || ""
                                )
                              }
                            >
                              <DeleteIcon color="error" />
                            </Box>
                            <Box
                              onClick={() =>
                                handleclickEditArea(
                                  option.name || "",
                                  option._id || ""
                                )
                              }
                            >
                              <EditIcon />
                            </Box>
                          </Box>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Area Name" />
                      )}
                    />
                    <p
                      className={clsx(ContainersClasses.section__error__helper)}
                    >
                      {error?.message}
                    </p>
                  </>
                )}
              />
            </Box>
            <Box
              sx={{
                width: "85%",
              }}
            >
              <p className={ContainersClasses.section__subtitle}>
                Driver Name*
              </p>
              <Controller
                name="driver__name"
                rules={{ required: "Select Driver Name" }}
                control={control}
                render={({
                  field: { ref, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      onChange={(_, value) => {
                        onChange(value);
                      }}
                      ref={ref}
                      disablePortal
                      id="driver__name"
                      options={drivers || []}
                      value={dataWatch?.driver__name}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            color="secondary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Driver Name" />
                      )}
                    />
                    <p
                      className={clsx(ContainersClasses.section__error__helper)}
                    >
                      {error?.message}
                    </p>
                  </>
                )}
              />
            </Box>
            <Box
              sx={{
                width: "85%",
              }}
            >
              <p className={ContainersClasses.section__subtitle}>
                Friday Driver name *
              </p>
              <Controller
                name="fridayDriver__name"
                rules={{ required: "Select Friday Driver name " }}
                control={control}
                render={({
                  field: { ref, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Autocomplete
                      onChange={(_, value) => {
                        onChange(value);
                      }}
                      ref={ref}
                      disablePortal
                      id="fridayDriver__name"
                      options={drivers || []}
                      value={dataWatch?.fridayDriver__name}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            color="secondary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Friday Driver Name"
                        />
                      )}
                    />
                    <p
                      className={clsx(ContainersClasses.section__error__helper)}
                    >
                      {error?.message}
                    </p>
                  </>
                )}
              />
            </Box>
          </Box>
          <Box>
            <Box display="flex" flexDirection="row">
              <Checkbox
                color="primary"
                checked={dataWatch.morningDelivery}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("morningDelivery", !getValues("morningDelivery"));
                }}
              />
              <p className={DeliveryClasses.delivery__subtitle__checkbox}>
                Morning Delivery Time
              </p>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mx={5}
            >
              <Box>
                <p
                  className={clsx(ContainersClasses.section__subtitle)}
                  style={{ marginTop: "5px" }}
                >
                  From*
                </p>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopTimePicker
                    value={dataWatch.morningFrom}
                    inputFormat="hh:mm a"
                    disabled={!dataWatch.morningDelivery}
                    onChange={(newValue) => {
                      setValue("morningFrom", newValue || new Date());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="From"
                        sx={{
                          width: "100%",
                          backgroundColor: "#fff",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <p
                  className={clsx(ContainersClasses.section__subtitle)}
                  style={{ marginTop: "5px" }}
                >
                  To*
                </p>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopTimePicker
                    value={dataWatch.morningTo}
                    inputFormat="hh:mm a"
                    disabled={!dataWatch.morningDelivery}
                    onChange={(newValue) => {
                      setValue("morningTo", newValue || new Date());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="To"
                        sx={{
                          width: "100%",
                          backgroundColor: "#fff",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box
              className={DeliveryClasses.delivery__subtitle__checkbox}
              mx={5}
              my={3}
            >
              Friday Morning Delivery Time
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mx={5}
            >
              <Box>
                <p
                  className={clsx(ContainersClasses.section__subtitle)}
                  style={{ marginTop: "5px" }}
                >
                  From*
                </p>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopTimePicker
                    value={dataWatch.fridayMorningFrom}
                    inputFormat="hh:mm a"
                    disabled={!dataWatch.morningDelivery}
                    onChange={(newValue) => {
                      setValue("fridayMorningFrom", newValue || new Date());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="From"
                        sx={{
                          width: "100%",
                          backgroundColor: "#fff",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <p
                  className={clsx(ContainersClasses.section__subtitle)}
                  style={{ marginTop: "5px" }}
                >
                  To*
                </p>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopTimePicker
                    value={dataWatch.fridayMorningTo}
                    inputFormat="hh:mm a"
                    disabled={!dataWatch.morningDelivery}
                    onChange={(newValue) => {
                      setValue("fridayMorningTo", newValue || new Date());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="To"
                        sx={{
                          width: "100%",
                          backgroundColor: "#fff",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" marginTop={5}>
              <Checkbox
                color="primary"
                checked={dataWatch.eveningDelivery}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("eveningDelivery", !getValues("eveningDelivery"));
                }}
              />
              <p className={DeliveryClasses.delivery__subtitle__checkbox}>
                Evening Delivery Time
              </p>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mx={5}
            >
              <Box>
                <p
                  className={clsx(ContainersClasses.section__subtitle)}
                  style={{ marginTop: "5px" }}
                >
                  From*
                </p>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopTimePicker
                    value={dataWatch.eveningFrom}
                    inputFormat="hh:mm a"
                    disabled={!dataWatch.eveningDelivery}
                    onChange={(newValue) => {
                      setValue("eveningFrom", newValue || new Date());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="From"
                        sx={{
                          width: "100%",
                          backgroundColor: "#fff",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <p
                  className={clsx(ContainersClasses.section__subtitle)}
                  style={{ marginTop: "5px" }}
                >
                  To*
                </p>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopTimePicker
                    value={dataWatch.eveningTo}
                    inputFormat="hh:mm a"
                    disabled={!dataWatch.eveningDelivery}
                    onChange={(newValue) => {
                      setValue("eveningTo", newValue || new Date());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="To"
                        sx={{
                          width: "100%",
                          backgroundColor: "#fff",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
          <Box
            className={DeliveryClasses.delivery__subtitle__checkbox}
            mx={5}
            my={3}
          >
            Friday Evening Delivery Time
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mx={5}
            mb={5}
          >
            <Box>
              <p
                className={clsx(ContainersClasses.section__subtitle)}
                style={{ marginTop: "5px" }}
              >
                From*
              </p>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopTimePicker
                  value={dataWatch.fridayEveningFrom}
                  inputFormat="hh:mm a"
                  disabled={!dataWatch.eveningDelivery}
                  onChange={(newValue) => {
                    setValue("fridayEveningFrom", newValue || new Date());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="From"
                      sx={{
                        width: "100%",
                        backgroundColor: "#fff",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <p
                className={clsx(ContainersClasses.section__subtitle)}
                style={{ marginTop: "5px" }}
              >
                To*
              </p>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopTimePicker
                  value={dataWatch.fridayEveningTo}
                  inputFormat="hh:mm a"
                  disabled={!dataWatch.eveningDelivery}
                  onChange={(newValue) => {
                    setValue("fridayEveningTo", newValue || new Date());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="To"
                      sx={{
                        width: "100%",
                        backgroundColor: "#fff",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box>
            <p className={clsx(ContainersClasses.section__subtitle)}>
              Activate / Deactivate Delivery Area
            </p>
            <Controller
              name="active"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <FormControlLabel
                  {...rest}
                  value={activeWatch}
                  control={<Switch defaultChecked />}
                  label={activeWatch ? "Active" : "Inactive"}
                />
              )}
            />
          </Box>

          <Box
            sx={{
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
            className={clsx(DeliveryClasses.buttons__container)}
          >
            <Button
              variant="outlined"
              size="large"
              className={clsx(ButtonClasses.button_cancel_form)}
              onClick={() => navigate("/admin/delivery_area/list_areas")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={ButtonClasses.button_submit_form}
              type="submit"
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ marginRight: 10, color: "white" }}
                />
              )}
              Create Area
            </Button>
          </Box>
        </form>
      </Container>

      {/* Delete Area */}
      <Dialog
        open={openDeleteArea}
        onClose={handleCloseDeleteAreaModal}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <Delete />
          <span className="alert_dialog_title_text">
            Delete Area "{chosenToDeleteAreaName}" ?
          </span>
        </DialogTitle>
        <DialogContent className={DialogClasses.alert_dialog_content}>
          <DialogContentText>
            Are you sure you want to delete this Area ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseDeleteAreaModal}
          >
            Cancel
          </Button>
          <Button
            className={ButtonsClasses.RedButton}
            onClick={onDeleteArea}
            disabled={loadingDeleteArea}
          >
            {loadingDeleteArea && (
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "white" }}
              />
            )}
            Delete Area
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Area */}
      <Dialog
        open={openUpdateArea}
        onClose={handleCloseEditModal}
        className={DialogClasses.dialog_container}
      >
        <DialogTitle className={DialogClasses.alert_dialog_title}>
          <LocationOnIcon />
          <span className="alert_dialog_title_text">Edit Area name</span>
        </DialogTitle>
        <DialogContent>
          <Box>
            <p className={DeliveryClasses.delivery__subtitle__checkbox}>
              Area Name*
            </p>

            <TextField
              required
              id="demo-helper-text-misaligned"
              placeholder="Area Name"
              fullWidth
              label="Area Name"
              value={chosenToUpdateAreaName}
              onChange={(e) => setChosenToUpdateAreaName(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions className={DialogClasses.alert_dialog_actions}>
          <Button
            className={ButtonsClasses.GreyButton}
            onClick={handleCloseEditModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={ButtonClasses.button_submit_form}
            onClick={onUpdateArea}
            disabled={loadingEditArea || chosenToUpdateAreaName.length === 0}
          >
            {loadingEditArea && (
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "white" }}
              />
            )}
            Edit Area
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default AddArea;
