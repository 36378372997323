import { useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
import { useReactToPrint } from "react-to-print";
import { useSnackbar } from "notistack";
//material ui
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
//style
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesMenu } from "../../../styles/Menu__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
//components
import Table from "../../layout/commun/Table";
import FilterHeader from "../../pageHeader/FilterHeader";
import EntryGiftVoucherPrint from "../../customer/ProfileDetails/EntryGiftVoucherPrint";
import EntryGiftVoucherListPrint from "./EntryGiftVoucherListPrint";
import Status from "../../Status/Status";
//mutations&querries
import {
  CustomerProfilePayload,
  NumberOfDays,
  PlanCondition,
  TransactionStatus,
  useGetCustomerEventsLazyQuery,
  useGetFirstBoxCustomersLazyQuery,
  useGetFirstBoxCustomersQuery,
} from "../../../graphql/types";
//utils
import { columns, SIDE_FILTER_OPTIONS_FIRST_BOX_DELIVERED } from "./Utils";
import { convertNumberOfDaysEnum } from "../../customer/Utils";
import { handleStatus } from "../../transaction/Utils";
import { useIsAuthorized } from "../../../utils/Hooks/useIsAuthorized";
import { getMessageError } from "../../Utils";
//context
import { useAuth } from "../../../contextAPI";

function FirstBoxDelivered() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);
  const printVoucherListRef = useRef<HTMLDivElement>(null);
  const isAuthorizedToSeeProfile = useIsAuthorized("GET_CUSTOMER_INFO");
  let auth = useAuth();

  //styles
  const classes = useStylesMenu();
  const ButtonClasses = useStylesButtons();

  //state
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const [idsProgram, setIdsProgram] = useState<string[]>([]);
  const [duration, setDuration] = useState<string[]>([]);
  const [numberOfDays, setNumberOfDays] = useState<NumberOfDays[]>([]);
  const [numberOfMeals, setNumberOfMeals] = useState<number[]>([]);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [planStatus, setPlanStatus] = useState<PlanCondition[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<CustomerProfilePayload | null>(null);

  //queries&mutations
  const dataGetCustomers = useGetFirstBoxCustomersQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
        duration: duration,
        nbrOfDays: numberOfDays,
        meals: numberOfMeals,
        program: idsProgram,
        condition: planStatus,
        from: from,
        to: to,
      },
    },
    // Ensure the API is called only once when both 'from' and 'to' are set, but not when only one of them is set.
    skip: (from !== null && to === null) || (from === null && to !== null),
    fetchPolicy: "network-only",
  });

  const [getCustomerEventsData, { data: customerEventsData }] =
    useGetCustomerEventsLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted() {
        handlePrint();
      },
      onError(error) {
        const err = getMessageError(error);
        enqueueSnackbar(err, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    });

  const onGenerateHandler = async () => {
    setAnchorEl(null);
    await getCustomerEventsData({
      variables: {
        customerId: selected?._id || "",
      },
    });
  };

  const firstBoxDeliveredCustomers = useMemo(
    () => dataGetCustomers.data?.getFirstBoxCustomers.data || [],
    [dataGetCustomers.data]
  );

  const count = useMemo(
    () => dataGetCustomers.data?.getFirstBoxCustomers.count || 0,
    [dataGetCustomers.data]
  );

  //export excel logic
  const [getFirstBoxCustomersData, { loading: firstBoxCustomersListLoading }] =
    useGetFirstBoxCustomersLazyQuery();

  const handleExportExcel = async () => {
    await getFirstBoxCustomersData({
      variables: {
        input: {
          page: 1,
          documentsPerPage: count,
          duration: duration,
          nbrOfDays: numberOfDays,
          meals: numberOfMeals,
          program: idsProgram,
          condition: planStatus,
          from: from,
          to: to,
        },
      },
    })
      .then((data) => {
        if (count != 0) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const fileExtension = ".xlsx";
          const filteredCustomers = data?.data?.getFirstBoxCustomers?.data?.map(
            (el, index) => {
              return {
                Customer_Name: el.fname + " " + el.lname,
                Driver_Name: el.driver,
                Phone_Number: el.phone?.prefix + " " + el.phone?.number,
                Email: el.email ? el.email : "null@null.com",
                Starting_Date: moment(el.plan?.startDate).format("DD-MMM-YYYY"),
                Ending_Date: moment(el.plan?.expiryDate).format("DD-MMM-YYYY"),
                Price: auth.countryCurrency + " " + el?.plan?.price?.toFixed(0),
                Customer_Promo_Code: el.customerPromoCode,
                Promo_Code_Used_by_Customer: el?.plan?.promoCode || "---",
                Type:
                  el?.plan?.promoCode === "UPGRADE"
                    ? "Upgrade"
                    : el?.plan?.switchedFrom
                    ? "Switch"
                    : "New Plan",
                Plan_Details: el.plan?.program?.name?.EN,
                Number_of_Days: convertNumberOfDaysEnum(
                  el?.plan?.nbrOfDays || "FIVE_DAYS"
                ),
                Frequency: el?.plan?.paymentPeriod,
              };
            }
          );

          // Getting the list of drivers as a Set
          const driversSet = new Set<string>();
          data?.data?.getFirstBoxCustomers.data?.map((el) => {
            if (el.driver) {
              driversSet.add(el.driver);
            }
          });

          //sort the driver names list in alphabetical order
          const sortedArray = Array.from(driversSet).sort((a, b) => {
            return a.localeCompare(b, undefined, { sensitivity: "base" });
          });

          // Create a workbook with multiple sheets
          const wb: {
            Sheets: { [key: string]: XLSX.WorkSheet };
            SheetNames: string[];
          } = {
            Sheets: {},
            SheetNames: [],
          };

          // Create a worksheet for the "All" sheet that contains the data of all drivers
          const allDataSheet = XLSX.utils.json_to_sheet(
            filteredCustomers?.map((ele, index) => {
              return {
                Index_Number: index + 1,
                ...ele,
              };
            }) || []
          );
          allDataSheet["!cols"] = [
            { wpx: 75 },
            { wpx: 150 },
            { wpx: 80 },
            { wpx: 100 },
            { wpx: 150 },
            { wpx: 70 },
            { wpx: 80 },
            { wpx: 50 },
            { wpx: 120 },
            { wpx: 150 },
          ];
          wb.SheetNames.push("All");
          wb.Sheets["All"] = allDataSheet;

          // Create a worksheet for the "NoDriver" sheet that contains data of customers with no driver
          const noDriverData = filteredCustomers
            ?.filter((el) => !el.Driver_Name)
            .map((ele, index) => {
              return {
                index: index + 1,
                ...ele,
              };
            });
          const noDriverSheet = XLSX.utils.json_to_sheet(noDriverData || []);
          noDriverSheet["!cols"] = [
            { wpx: 40 },
            { wpx: 150 },
            { wpx: 80 },
            { wpx: 100 },
            { wpx: 120 },
            { wpx: 70 },
            { wpx: 80 },
            { wpx: 50 },
          ];
          wb.SheetNames.push("NoDriver");
          wb.Sheets["NoDriver"] = noDriverSheet;

          // Loop through the sorted driver list and create a sheet for each driver
          sortedArray.forEach((driver) => {
            const driverData = filteredCustomers
              ?.filter((el) => el.Driver_Name === driver)
              //adding index number for each line
              .map((ele, index) => {
                return {
                  index: index + 1,
                  ...ele,
                };
              });
            const ws = XLSX.utils.json_to_sheet(driverData || []);
            ws["!cols"] = [
              { wpx: 40 },
              { wpx: 150 },
              { wpx: 80 },
              { wpx: 100 },
              { wpx: 120 },
              { wpx: 70 },
              { wpx: 80 },
              { wpx: 50 },
            ];
            // Set the sheet name to the driver's name
            wb.SheetNames.push(driver);
            wb.Sheets[driver] = ws;
          });

          // Convert the workbook to Excel file and save it
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const res = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(
            res,
            "first box customers report" +
              " generated on " +
              moment().format("DD/MM/YYYY dddd LT") +
              fileExtension
          );

          return "Done";
        }
      })
      .catch((err) => {
        throw new Error("error while download file");
      });
  };

  //generate voucher
  const [
    getFirstBoxCustomersVouchers,
    { data: voucherData, loading: voucherLoading },
  ] = useGetFirstBoxCustomersLazyQuery();

  const handlePrintGiftVouchers = async () => {
    await getFirstBoxCustomersVouchers({
      variables: {
        input: {
          page: 0,
          documentsPerPage: 0,
          voucher: true,
          duration: duration,
          nbrOfDays: numberOfDays,
          meals: numberOfMeals,
          program: idsProgram,
          condition: planStatus,
          from: from,
          to: to,
        },
      },
      fetchPolicy: "network-only",
      onCompleted() {
        handlePrintGiftVouchersList();
      },
    });
  };
  //functions
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderTableRows = () => {
    return firstBoxDeliveredCustomers.map((item, index) => {
      return {
        index: index + 1 + (page - 1) * rowsPerPage,
        name: isAuthorizedToSeeProfile ? (
          <a
            style={{ textDecoration: "none" }}
            href={`/admin/customers/view_customer/${item?._id}`}
          >
            <Button>{`${item?.fname} ${item?.lname}`}</Button>
          </a>
        ) : (
          item.fname + " " + item.lname
        ),
        mobile: item?.phone?.prefix + " " + item?.phone?.number,
        email: item?.email,
        date: moment(item.plan?.startDate).format("DD-MMM-YYYY"),
        exDate: moment(item.plan?.expiryDate).format("DD-MMM-YYYY"),
        driver: item.driver,
        plan: (
          <>
            <Typography style={{ fontSize: 12 }}>
              {item.plan?.program?.name?.EN +
                ", " +
                convertNumberOfDaysEnum(item?.plan?.nbrOfDays || "FIVE_DAYS")}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {item?.plan?.meals?.length +
                " meals, " +
                item?.plan?.paymentPeriod}
            </Typography>
          </>
        ),
        price: auth.countryCurrency + " " + item?.plan?.price?.toFixed(0),
        promoCode: item?.plan?.promoCode || "---",
        ownPromoCode: item?.customerPromoCode || "---",
        Type:
          item?.plan?.promoCode === "UPGRADE"
            ? "Upgrade"
            : item?.plan?.switchedFrom
            ? "Switch"
            : "New Plan",
        status: (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Status
              status={
                item?.transactionStatus === "CREATED"
                  ? "PENDING"
                  : item?.transactionStatus
              }
              statusText={handleStatus(
                item?.transactionStatus === "CREATED"
                  ? "PENDING"
                  : (item?.transactionStatus as TransactionStatus)
              )}
            />
          </Box>
        ),
        actions: (
          <IconButton onClick={handleClickMenu(item)}>
            <MoreVertIcon />
          </IconButton>
        ),
      };
    });
  };

  const handleClickMenu =
    (item: CustomerProfilePayload) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelected(item);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToCustomerProfileHandler = () => {
    navigate(`/admin/customers/view_customer/${selected?._id}`);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    documentTitle:
      "Entry Gift Voucher for " + selected?.fname + " " + selected?.lname,
  });

  const handlePrintGiftVouchersList = useReactToPrint({
    content: () => printVoucherListRef?.current,
    documentTitle: "Entry Gift Vouchers",
  });

  return (
    <>
      <Box marginBottom={2}>
        <FilterHeader
          count={count}
          showSearch={false}
          pageTitle="1st box delivered"
          pageInfo="These are the customers who's receiving their first box today"
          setDuration={setDuration}
          durationData={duration}
          setNumberOfDays={setNumberOfDays}
          daysData={numberOfDays}
          setNumberOfMeals={setNumberOfMeals}
          mealsData={numberOfMeals}
          setIdsProgram={setIdsProgram}
          programIdData={idsProgram}
          setPlanStatus={setPlanStatus}
          planStatus={planStatus}
          setFrom={setFrom}
          from={from}
          setTo={setTo}
          to={to}
          filterConfig={SIDE_FILTER_OPTIONS_FIRST_BOX_DELIVERED}
          loadExcel={firstBoxCustomersListLoading}
          generateExcel={handleExportExcel}
        />
      </Box>

      <Button
        onClick={() => handlePrintGiftVouchers()}
        className={ButtonClasses.button_export_excel}
        startIcon={<PictureAsPdfIcon />}
        disabled={voucherLoading}
      >
        Print Gift Vouchers
        {voucherLoading ? <CircularProgress size={14} color="inherit" /> : ""}
      </Button>

      <TableContainer>
        <Table
          columns={columns}
          loading={dataGetCustomers.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No 1st box Customers found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>

      <div className={classes.menu_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorPosition={{
            left: anchorEl?.getBoundingClientRect().left || 0,
            top: anchorEl?.getBoundingClientRect().top || 0,
          }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorReference="anchorPosition"
        >
          {isAuthorizedToSeeProfile && (
            <MenuItem
              onClick={goToCustomerProfileHandler}
              className={classes.menu_container}
              disableRipple
            >
              <div>
                <span className="menu_title">Go to profile</span>
              </div>
            </MenuItem>
          )}
          <MenuItem
            onClick={onGenerateHandler}
            className={classes.menu_container}
            disableRipple
          >
            <div>
              <span className="menu_title">Print Entry Gift Voucher</span>
            </div>
          </MenuItem>
        </Menu>
      </div>

      <div style={{ display: "none" }}>
        <EntryGiftVoucherPrint
          customerEvents={customerEventsData?.getCustomerEvents}
          ref={printRef}
        />
      </div>

      <div style={{ display: "none" }}>
        <EntryGiftVoucherListPrint
          customersEvents={voucherData?.getFirstBoxCustomers.codes}
          ref={printVoucherListRef}
        />
      </div>
    </>
  );
}

export default FirstBoxDelivered;
