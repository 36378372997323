import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import moment from "moment";
import { useParams } from "react-router-dom";
//Material Ui
import { Box, Button, Typography } from "@mui/material";
//component
import NoteCard from "./NoteCard";
import DialogAddNote, { AddNoteParams } from "./DialogAddNote";
import DialogDelete from "./DialogDelete";
//querys & mutations
import {
  NotePayload,
  NotePiority,
  useCreateNoteMutation,
  useDeleteNoteMutation,
  useGetListNotesQuery,
  useUpdateNoteMutation,
} from "../../../graphql/types";
//Styles
import {
  DroppableContainer,
  NotesContainer,
} from "../../../styles/Customers__styles";
import { useStylesAuth } from "../../../styles/Auth__styles";

type CustomerNotesState = {
  itemsInProgress: Array<NotePayload>;
  itemsComplete: Array<NotePayload>;
};

const CustomerNotes = () => {
  const { id } = useParams();
  //styles
  const AuthClasses = useStylesAuth();

  //state
  const [state, setState] = useState<CustomerNotesState>({
    itemsInProgress: [],
    itemsComplete: [],
  });
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<NotePayload | null>(null);

  //querys & mutations
  const dataListNotes = useGetListNotesQuery({
    variables: { customerId: id || "" },
    skip: !id,
  });
  const [createNote] = useCreateNoteMutation();
  const [updateNote] = useUpdateNoteMutation();
  const [deleteNote] = useDeleteNoteMutation();

  useEffect(() => {
    const listNotes = dataListNotes.data?.getListNotes.list || [];
    let itemsComplete = listNotes.filter((o) => o.status === "COMPLETED");
    let itemsInProgress = listNotes.filter((o) => o.status === "IN_PROGRESS");
    setState({ itemsInProgress, itemsComplete });
  }, [dataListNotes.data]);

  //functions
  const onDragEnd = useCallback(
    async (result) => {
      if (result.reason === "DROP") {
        if (!result.destination) {
          return;
        }
        const from = result.source.droppableId;
        const to = result.destination.droppableId;
        if (from === to) {
          return;
        }
        const fromIndex = result.source.index;
        const toIndex = result.destination.index;
        let newState: any = Object.assign({}, state);
        newState[from] = newState[from] || [];
        newState[to] = newState[to] || [];
        const [removed] = newState[from].splice(fromIndex, 1);
        newState[to].splice(toIndex, 0, removed);
        try {
          const status = to === "itemsComplete" ? "COMPLETED" : "IN_PROGRESS";
          await updateNote({
            variables: {
              input: {
                noteId: removed._id,
                body: {
                  content: removed.content,
                  date: removed.date,
                  priority: removed.priority,
                  status,
                  customerId: id,
                },
              },
            },
          });
          const target = newState.find((o: any) => o._id === removed._id);
          if (target) {
            target.status =
              target.status === "COMPLETED" ? "COMPLETED" : "IN_PROGRESS";
          }
          setState(newState);
        } catch (error) {}
      }
    },
    [id, state]
  );

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    ...draggableStyle,
    userSelect: "none",
    position: "static",
  });

  const handleAddNotes = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveChanges = async (params: AddNoteParams) => {
    if (!id) {
      return;
    }
    try {
      setOpen(false);
      const res = await createNote({
        variables: {
          input: {
            content: params.content,
            date: new Date(),
            priority: params.priority,
            status: "IN_PROGRESS",
            customerId: id,
          },
        },
      });
      const payload = res.data?.createNote || null;
      if (payload) {
        setState((prevState) => ({
          ...prevState,
          itemsInProgress: [...prevState.itemsInProgress, payload],
        }));
      }
    } catch (error) {}
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const onClickDelete = (item: NotePayload) => () => {
    setOpenDelete(true);
    setSelected(item);
  };

  const handleSaveDelete = async () => {
    if (!selected?._id) {
      return;
    }
    setOpenDelete(false);
    try {
      await deleteNote({ variables: { noteId: selected._id } });
      let newState: CustomerNotesState = Object.assign({}, state);
      if (selected.status === "IN_PROGRESS") {
        newState = {
          ...newState,
          itemsInProgress: newState.itemsInProgress.filter(
            (o) => o._id !== selected._id
          ),
        };
      } else if (selected.status === "COMPLETED") {
        newState = {
          ...newState,
          itemsComplete: newState.itemsComplete.filter(
            (o) => o._id !== selected._id
          ),
        };
      }
      setState(newState);
      setSelected(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <Droppable
            direction="horizontal"
            droppableId="itemsInProgress"
            type="CARD"
          >
            {(provided, snapshot) => {
              return (
                <NotesContainer>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography color="#B27D3F" fontSize={18}>
                      Notes
                    </Typography>
                    <Button
                      className={AuthClasses.auth__button__submit}
                      type="submit"
                      sx={{ height: 40 }}
                      onClick={handleAddNotes}
                    >
                      New Note
                    </Button>
                  </Box>
                  <DroppableContainer
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {state.itemsInProgress.map((item, index: number) => {
                      return (
                        <Draggable
                          key={item._id}
                          draggableId={item._id || index.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <NoteCard
                                  date={moment(item.date).format("DD/MM/YYYY")}
                                  text={item.content}
                                  priority={item.priority as NotePiority}
                                  isCompleted={item.status === "COMPLETED"}
                                  onClickDelete={onClickDelete(item)}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </DroppableContainer>
                </NotesContainer>
              );
            }}
          </Droppable>
          <Droppable
            direction="horizontal"
            droppableId="itemsComplete"
            type="CARD"
          >
            {(provided, snapshot) => {
              return (
                <NotesContainer>
                  <Typography mb={2} color="#B27D3F" fontSize={18}>
                    Completed
                  </Typography>
                  <DroppableContainer
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {state.itemsComplete.map((item, index: number) => {
                      return (
                        <Draggable
                          key={item._id}
                          draggableId={item._id || index.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <NoteCard
                                  date={moment(item.date).format("DD/MM/YYYY")}
                                  text={item.content}
                                  priority={item.priority as NotePiority}
                                  isCompleted={item.status === "COMPLETED"}
                                  onClickDelete={onClickDelete(item)}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </DroppableContainer>
                </NotesContainer>
              );
            }}
          </Droppable>
        </Box>
      </DragDropContext>
      <DialogAddNote
        open={open}
        handleClose={handleClose}
        handleSaveChanges={handleSaveChanges}
      />
      <DialogDelete
        open={openDelete}
        handleClose={handleCloseDelete}
        handleSaveDelete={handleSaveDelete}
      />
    </React.Fragment>
  );
};
export default CustomerNotes;
