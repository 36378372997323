import { useEffect, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import clsx from "clsx";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
//icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesLeads } from "../../../styles/Leads__styles";
import { useStylesSelect } from "../../../styles/Select__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { StyledMenuItem } from "../CustomerMenuProgram/CustomerMenuProgram.styles";
//types
import { EditPlanState } from "../Types";
import { ApolloError } from "@apollo/client";
import { CustomerActionsTypes } from "./Types";
//queries&mutations
import {
  GetCustomerProfileByIdDocument,
  useEditPlanStartDateAndNumberOfBoxesMutation,
  useGetActifCustomerPlansQuery,
} from "../../../graphql/types";
//utils
import { getMessageError } from "../../Utils";
//context
import { CustomerContext } from "../viewCustomer/ViewCustomer";

const EditPlan: React.FC<CustomerActionsTypes> = ({
  expanded,
  handleChange,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id } = useParams();

  //styles
  const SelectClasses = useStylesSelect();
  const LeadsClasses = useStylesLeads();
  const ButtonClasses = useStylesButtons();
  const ContainersClasses = useStylesContainers();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [open, setOpen] = useState<boolean>(false);

  //context
  const context = useContext(CustomerContext);

  //react hook form
  const methodsEditMealPlan = useForm<EditPlanState>({
    defaultValues: {
      plan: "",
      nbrOfBoxes: "",
      startDate: null,
    },
  });
  const {
    control,
    reset,
    formState: { errors },
    register,
    handleSubmit,
    setValue,
  } = methodsEditMealPlan;

  const dataWatch = useWatch({
    control,
  });

  const {
    data: CustomerActifPlansData,
    refetch: refetchCustomerActifPlansData,
  } = useGetActifCustomerPlansQuery({
    variables: {
      customerId: id || "",
    },
    fetchPolicy: "network-only",
    skip: !id || expanded != "EDIT_START_DATE_NOMBER_BOXES",
  });

  //useEffects
  useEffect(() => {
    if (dataWatch.plan) {
      setValue("nbrOfBoxes", dataWatch?.plan?.nbrOfBoxes);
      setValue("startDate", dataWatch?.plan?.startDate);
    }
  }, [dataWatch.plan]);

  //consts
  const CustomerActifPlans = CustomerActifPlansData?.getActifCustomerPlans;

  //mutations
  const [
    EditPlanStartDateAndNumberOfBoxes,
    { loading: loadingEditPlanStartDateAndNumberOfBoxes },
  ] = useEditPlanStartDateAndNumberOfBoxesMutation();

  //functions
  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSubmitEditMealPlan: SubmitHandler<EditPlanState> = async (data) => {
    try {
      await EditPlanStartDateAndNumberOfBoxes({
        variables: {
          editPlanStartDateAndNumberOfBoxesInput: {
            planId: dataWatch.plan?._id || "",
            nbrOfBoxes: Number(dataWatch.nbrOfBoxes),
            startDate: moment(dataWatch.startDate).set({ hours: 12 }).toDate(),
          },
        },
        refetchQueries: [
          {
            query: GetCustomerProfileByIdDocument,
            variables: {
              customerId: context?.getCustomerProfileById._id || "",
            },
          },
        ],
        onCompleted: () => {
          reset();
          refetchCustomerActifPlansData({
            customerId: id || "",
          });
          enqueueSnackbar(
            "Plan start date and number of boxes succuessfully updated",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  //render
  const renderItems = () => {
    return CustomerActifPlans?.map((item) => {
      return (
        <StyledMenuItem
          key={item._id}
          value={item as string}
          className={SelectClasses.option_item}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <span>{item?.program?.name?.EN}</span>
            <div className="left-side-container">
              <span>
                {`(${moment(item.startDate).format("DD/MM/YYYY")} - ${moment(
                  item.expiryDate
                ).format("DD/MM/YYYY")})`}{" "}
              </span>
            </div>
          </Box>
        </StyledMenuItem>
      );
    });
  };

  return (
    <Accordion
      expanded={expanded === "EDIT_START_DATE_NOMBER_BOXES"}
      onChange={handleChange("EDIT_START_DATE_NOMBER_BOXES")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="#B27D3F" fontSize={18}>
          Change plan start date and number of boxes
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormProvider {...methodsEditMealPlan}>
          <form
            onSubmit={handleSubmit(onSubmitEditMealPlan)}
            id="CHANGE_PLAN_START_DATE_AND_NUMBER_OF_BOXES_FORM"
          >
            <Box display="flex" flexWrap="wrap" gap="0px 20px">
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Plan to edit
                </p>
                <Controller
                  name="plan"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        {...rest}
                        sx={{ minWidth: "350px" }}
                        className={LeadsClasses.select_container}
                        onChange={(event) => {
                          onChange(event.target.value);
                        }}
                        value={dataWatch.plan?._id}
                      >
                        {renderItems()}
                      </Select>
                      <p
                        className={clsx(
                          ContainersClasses.section__error__helper
                        )}
                      >
                        {error?.message}
                      </p>
                    </>
                  )}
                  rules={{
                    required: {
                      message: "Field Required",
                      value: true,
                    },
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Start date*
                </p>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <DesktopDatePicker
                        {...rest}
                        inputFormat="DD/MM/YYYY"
                        disablePast={true}
                        disableFuture={false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ minWidth: "350px", flexGrow: 1 }}
                          />
                        )}
                      />
                    )}
                    rules={{
                      required: "Field required",
                      validate: (value) =>
                        value === null ||
                        moment(value).isValid() ||
                        "Enter a valid Start Date",
                    }}
                  />
                </LocalizationProvider>
                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.startDate?.message}
                </p>
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={clsx(ContainersClasses.section__subtitle)}>
                  Number of boxes
                </p>
                <TextField
                  placeholder="Number of boxes"
                  sx={{ minWidth: "350px" }}
                  {...register("nbrOfBoxes", {
                    required: "Field required",
                  })}
                />
                <p className={clsx(ContainersClasses.section__error__helper)}>
                  {errors?.nbrOfBoxes?.message}
                </p>
              </Box>
            </Box>
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="flex-end"
              mt={1}
            >
              <Button
                variant="contained"
                className={clsx(ButtonClasses.button_open_list_page)}
                onClick={() => setOpen(true)}
                disabled={loadingEditPlanStartDateAndNumberOfBoxes}
              >
                {loadingEditPlanStartDateAndNumberOfBoxes && (
                  <CircularProgress
                    size={20}
                    style={{ color: "white", marginRight: 6 }}
                  />
                )}
                Edit
              </Button>
            </Box>

            {/* CONFIRMATION MODAL */}
            <Dialog
              open={open}
              onClose={handleCloseModal}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.alert_dialog_title}>
                <span className="alert_dialog_title_text">Confirm Action</span>
              </DialogTitle>
              <DialogContent className={DialogClasses.alert_dialog_content}>
                <DialogContentText>
                  Are you sure you want to change start date and number of boxes
                  of this meal plan ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={DialogClasses.alert_dialog_actions}>
                <Button
                  className={ButtonsClasses.GreyButton}
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  className={ButtonsClasses.GreenButton}
                  type="submit"
                  form="CHANGE_PLAN_START_DATE_AND_NUMBER_OF_BOXES_FORM"
                  color="primary"
                  autoFocus
                  onClick={() => setOpen(false)}
                >
                  Edit
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default EditPlan;
