import { useFormContext, useWatch } from "react-hook-form";
//material ui
import { Avatar, Box, Typography } from "@mui/material";
//context
import { useAuth } from "../../../contextAPI";
//utils
import { calculatePackCost } from "./Utils";
//types
import { PackPayload } from "../../../graphql/types";

type props = {
  pack: PackPayload;
  selected?: PackPayload;
  setSelectedPack: React.Dispatch<React.SetStateAction<PackPayload>>;
};

const PackCard: React.FC<props> = ({ pack, selected, setSelectedPack }) => {
  const { control } = useFormContext();
  let auth = useAuth();
  const dataWatch = useWatch({
    control,
  });

  return (
    <Box
      borderRadius={2}
      padding={2}
      marginTop={2}
      marginBottom={2}
      marginLeft={1}
      marginRight={1}
      boxShadow="rgba(50, 50, 93, 0.25) 1px 2px 5px 1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
      sx={{
        cursor: "pointer",
        border: selected?._id === pack._id ? "2px solid lightGreen" : undefined,
        "&:hover": {
          backgroundColor: "#F4EEE0",
          transition: "0.3s",
          transform: "scale(1.03)",
        },
      }}
      onClick={() => setSelectedPack(pack)}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          {pack?.meals?.map((el) => (
            <Typography key={el} fontWeight="bold">
              {el}
            </Typography>
          ))}
        </Box>
        <Box>
          <Avatar
            alt={pack.packImg || ""}
            src={pack.packImg || ""}
            variant="rounded"
            style={{ width: 120, height: 120 }}
          />
        </Box>
      </Box>
      <Box marginTop={3}>
        <Typography fontSize={13}>
          Serves Between {pack.minMax?.min} and {pack.minMax?.max} calories
        </Typography>
      </Box>
      {dataWatch.dayPerWeek && (
        <Box marginTop={3}>
          <Box display="flex" flexDirection="row">
            <Typography fontWeight="bold">
              {calculatePackCost(
                dataWatch.dayPerWeek,
                "WEEKLY",
                pack?.prices || undefined
              )}
              {auth.countryCurrency}
            </Typography>
            <Typography>/week </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Typography fontWeight="bold">
              {calculatePackCost(
                dataWatch.dayPerWeek,
                "MONTHLY",
                pack?.prices || undefined
              )}
              {auth.countryCurrency}
            </Typography>
            <Typography>/month</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Typography fontWeight="bold">
              {calculatePackCost(
                dataWatch.dayPerWeek,
                "THREE_MONTHS",
                pack?.prices || undefined
              )}
              {auth.countryCurrency}
            </Typography>
            <Typography>/three Months </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PackCard;
