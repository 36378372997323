import { useContext, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import clsx from "clsx";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateAdapter from "@mui/lab/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
//styles
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesLeads } from "../../../styles/Leads__styles";
import { useStylesSelect } from "../../../styles/Select__styles";
import { useStylesInput } from "../../../styles/Input__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
import { StyledMenuItem } from "../CustomerMenuProgram/CustomerMenuProgram.styles";
//types
import { EditMealPlanState } from "../Types";
import { ApolloError } from "@apollo/client";
import { CustomerActionsTypes } from "./Types";
//components
import { CustomerContext } from "../viewCustomer/ViewCustomer";
//queries&mutations
import {
  GetCustomerProfileByIdDocument,
  LunchMeal,
  PaymentPeriod,
  useCalculatePlanPriceMutation,
  useEditCustomerPlanWithPayementMutation,
  useGetActifCustomerPlansQuery,
  useGetActiveProgramsQuery,
} from "../../../graphql/types";
//utils
import {
  durationOptions,
  handleDays,
  handleMealsNumber,
  isDayOff,
  nbrDaysOptions,
  weekDays,
} from "../Utils";
import {
  CustomPackageMeals,
  getMessageError,
  handleMealsEditMealPlan,
  meals,
} from "../../Utils";
//context api
import { useAuth } from "../../../contextAPI";

const EditMealPlanWithPayment: React.FC<CustomerActionsTypes> = ({
  expanded,
  handleChange,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id } = useParams();
  const auth = useAuth();

  //styles
  const SelectClasses = useStylesSelect();
  const LeadsClasses = useStylesLeads();
  const ButtonClasses = useStylesButtons();
  const ContainersClasses = useStylesContainers();
  const InputClasses = useStylesInput();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [open, setOpen] = useState<boolean>(false);

  //react hook form
  const methodsEditMealPlan = useForm<EditMealPlanState>({
    defaultValues: {
      customerProgram: null,
      program: null,
      mealPlan: "",
      dayPerWeek: null,
      paid: false,
      numberOfMeals: null,
      duration: null,
      balance: "",
      meals: [],
      daysOff: [],
      startDate: null,
      isCustomPackage: false,
      initialPrice: 0,
      netPlanPrice: 0,
      returnedToWallet: 0,
      numberOfBoxes: 0,
      customPackagesQuantities: {
        breakfast: 0,
        lunchDinner: 0,
        snack: 0,
      },
    },
  });
  const { control, reset, handleSubmit, setValue, getValues } =
    methodsEditMealPlan;

  const dataWatch = useWatch({
    control,
  });

  //context
  const context = useContext(CustomerContext);

  //queries
  const { data: activeProgramsData } = useGetActiveProgramsQuery({
    skip: expanded !== "EDIT_MEAL_PLAN_WITH_PAYEMENT",
  });

  const { data: CustomerActifPlansData } = useGetActifCustomerPlansQuery({
    variables: {
      customerId: id || "",
    },
    skip: !id || expanded !== "EDIT_MEAL_PLAN_WITH_PAYEMENT",
  });

  //mutations
  const [EditCustomerPlan, { loading: loadingEditCustomerPlan }] =
    useEditCustomerPlanWithPayementMutation();

  const [calculatePlanPrice, { loading: loadingCalculatePlan }] =
    useCalculatePlanPriceMutation();

  const calculatePrice = async () => {
    const values = getValues();
    const isCustom = values.isCustomPackage;
    let customMeals: LunchMeal[] = [];

    if (isCustom) {
      for (let i = 0; i < values.customPackagesQuantities.breakfast; i++) {
        customMeals.push("BREAKFAST");
      }
      for (let i = 0; i < values.customPackagesQuantities.lunchDinner; i++) {
        customMeals.push(i % 2 === 0 ? "LUNCH" : "DINNER");
      }
      for (let i = 0; i < values.customPackagesQuantities.snack; i++) {
        customMeals.push(i % 2 === 0 ? "EVENING_SNACK" : "MORNING_SNACK");
      }
    } else {
      customMeals = [...(dataWatch.meals || [])];
    }

    if (values.customerProgram) {
      try {
        const { data } = await calculatePlanPrice({
          variables: {
            editCustomerPlanInput: {
              planId: values.customerProgram,
              numberOfMeal: values.numberOfMeals || "FIVE_MEALS",
              meals: customMeals,
              nbrOfDays: values.dayPerWeek || "FIVE_DAYS",
              daysOff: values.daysOff || [],
              programId: values.program || "",
              paymentPeriod: values.duration || "MONTHLY",
              startingDate: values.startDate || new Date(),
              isCustom: isCustom,
            },
          },
        });
        setValue("initialPrice", data?.calculatePlanPrice.initialPrice ?? 0);
        setValue("netPlanPrice", data?.calculatePlanPrice.netPlanPrice ?? 0);
        setValue(
          "returnedToWallet",
          data?.calculatePlanPrice.returnedToWalette ?? 0
        );
        setValue("numberOfBoxes", data?.calculatePlanPrice.numberOfBoxes ?? 0);
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
  };

  const updateMealsQuantityHandler = (
    config: "INCREMENT" | "DECREMENT",
    mealType: string,
    currentQuantity: number
  ) => {
    if (config === "INCREMENT") {
      currentQuantity++;
    } else {
      currentQuantity--;
    }
    if (mealType === "Breakfast") {
      setValue("customPackagesQuantities.breakfast", currentQuantity);
    } else if (mealType === "Lunch/Dinner") {
      setValue("customPackagesQuantities.lunchDinner", currentQuantity);
    } else if (mealType === "Snack") {
      setValue("customPackagesQuantities.snack", currentQuantity);
    }
  };

  //initaite current program values
  useEffect(() => {
    setValue(
      "customerProgram",
      context?.getCustomerProfileById?.plan?._id || ""
    );
    setValue("meals", context?.getCustomerProfileById?.plan?.meals || []);
    setValue("daysOff", context?.getCustomerProfileById?.plan?.daysOff || []);
    setValue(
      "program",
      context?.getCustomerProfileById?.plan?.program?._id || ""
    );
    setValue("dayPerWeek", context?.getCustomerProfileById?.plan?.nbrOfDays);
    setValue(
      "duration",
      context?.getCustomerProfileById?.plan?.paymentPeriod as PaymentPeriod
    );
    setValue(
      "numberOfMeals",
      handleMealsNumber(
        context?.getCustomerProfileById?.plan?.meals?.length || 0
      )
    );
    setValue(
      "isCustomPackage",
      context?.getCustomerProfileById?.plan?.isCustom || false
    );
    if (context?.getCustomerProfileById?.plan?.isCustom) {
      let meals = context?.getCustomerProfileById?.plan?.meals || [];
      let breakfast = 0;
      let lunchDinner = 0;
      let snack = 0;
      for (let meal of meals) {
        if (meal === "BREAKFAST") {
          breakfast++;
        }
        if (meal === "LUNCH" || meal === "DINNER") {
          lunchDinner++;
        }
        if (meal === "EVENING_SNACK" || meal === "MORNING_SNACK") {
          snack++;
        }
      }
      setValue("customPackagesQuantities.breakfast", breakfast);
      setValue("customPackagesQuantities.lunchDinner", lunchDinner);
      setValue("customPackagesQuantities.snack", snack);
    }
  }, []);

  useEffect(() => {
    if (dataWatch.meals && expanded === "EDIT_MEAL_PLAN_WITH_PAYEMENT") {
      setValue(
        "numberOfMeals",
        handleMealsNumber(dataWatch.meals?.length || 0)
      );
    }
  }, [dataWatch.meals, setValue, expanded]);

  //consts
  const activePrograms = activeProgramsData?.getActivePrograms;
  const CustomerActifPlans = CustomerActifPlansData?.getActifCustomerPlans;

  //functions
  const emptyFields = () => {
    setValue("dayPerWeek", null);
    setValue("meals", []);
    setValue("daysOff", []);
    setValue("numberOfMeals", null);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSubmitEditMealPlan: SubmitHandler<EditMealPlanState> = async (
    data
  ) => {
    //restriction for create a new plan
    let startDateIsDayOff = false;
    if (data.startDate) {
      const daysOff = dataWatch.daysOff || [];
      startDateIsDayOff = isDayOff(data.startDate, daysOff);
    }
    if (startDateIsDayOff) {
      enqueueSnackbar(
        "Selected start date is a day off. Please choose another date.",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
      return;
    }
    //test whether the combination between days per week and days off is correct
    if (
      (dataWatch.dayPerWeek === "FIVE_DAYS" &&
        dataWatch?.daysOff?.length != 2) ||
      (dataWatch.dayPerWeek === "SIX_DAYS" &&
        dataWatch?.daysOff?.length != 1) ||
      (dataWatch.dayPerWeek === "SEVEN_DAYS" && dataWatch?.daysOff?.length != 0)
    ) {
      enqueueSnackbar(
        "Please Verify the combination between Days per week and Days off",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
    } else {
      let mealsArray: LunchMeal[] = [];
      let checkIsCustom = false;
      if (dataWatch.isCustomPackage) {
        checkIsCustom = true;
        if (data.customPackagesQuantities?.breakfast > 0) {
          mealsArray.push(
            ...[...Array(dataWatch.customPackagesQuantities?.breakfast)].fill(
              "BREAKFAST"
            )
          );
        }
        if (data.customPackagesQuantities?.lunchDinner > 0) {
          for (
            let i = 1;
            i < data.customPackagesQuantities?.lunchDinner + 1;
            i++
          ) {
            if (i % 2 === 0) {
              mealsArray.push("DINNER");
            } else {
              mealsArray.push("LUNCH");
            }
          }
        }
        if (data.customPackagesQuantities?.snack > 0) {
          for (let i = 1; i < data.customPackagesQuantities?.snack + 1; i++) {
            if (i % 2 === 0) {
              mealsArray.push("EVENING_SNACK");
            } else {
              mealsArray.push("MORNING_SNACK");
            }
          }
        }
      } else {
        mealsArray = [...(dataWatch.meals || [])];
      }
      //test whether the user has chosen a minimum of 3 meals for custom plan
      if (mealsArray.length < 3 && checkIsCustom) {
        enqueueSnackbar(
          "Your need to have at least 3 meals for a custom plan",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }
        );
        return;
      }
      try {
        await EditCustomerPlan({
          variables: {
            editCustomerPlanInput: {
              planId: dataWatch.customerProgram || "",
              nbrOfDays: dataWatch.dayPerWeek || "FIVE_DAYS",
              numberOfMeal: dataWatch.numberOfMeals || "FIVE_MEALS",
              paymentPeriod: dataWatch.duration || "MONTHLY",
              programId: dataWatch.program || "",
              daysOff: dataWatch?.daysOff || [],
              meals: mealsArray || [],
              startingDate: dataWatch.startDate || new Date(),
              isCustom: checkIsCustom,
              // promoCode: dataWatch.promoCode,
            },
          },
          refetchQueries: [
            {
              query: GetCustomerProfileByIdDocument,
              variables: {
                customerId: context?.getCustomerProfileById._id || "",
              },
            },
          ],
          onCompleted: () => {
            reset();
            enqueueSnackbar("Meal plan succuessfully Edited", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
          },
        });
      } catch (err) {
        const error = getMessageError(err as ApolloError);
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
      }
    }
  };

  //render
  const renderItems = () => {
    return CustomerActifPlans?.map((item) => {
      return (
        <StyledMenuItem
          key={item._id}
          value={item?._id || ""}
          className={SelectClasses.option_item}
          onClick={() => {
            if (item?.isCustom) {
              setValue("meals", []);
            } else {
              setValue("meals", item?.meals || []);
            }
            setValue("daysOff", item?.daysOff || []);
            setValue("program", item?.program?._id || "");
            setValue("duration", item?.paymentPeriod as PaymentPeriod);
            setValue("dayPerWeek", item?.nbrOfDays);
            setValue("isCustomPackage", item?.isCustom || false);
            if (item?.isCustom) {
              let meals = item?.meals || [];
              let breakfast = 0;
              let lunchDinner = 0;
              let snack = 0;
              for (let meal of meals) {
                if (meal === "BREAKFAST") {
                  breakfast++;
                }
                if (meal === "LUNCH" || meal === "DINNER") {
                  lunchDinner++;
                }
                if (meal === "EVENING_SNACK" || meal === "MORNING_SNACK") {
                  snack++;
                }
              }
              setValue("customPackagesQuantities.breakfast", breakfast);
              setValue("customPackagesQuantities.lunchDinner", lunchDinner);
              setValue("customPackagesQuantities.snack", snack);
            }
          }}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <span>{item?.program?.name?.EN}</span>
            <div className="left-side-container">
              <span>
                {`(${moment(item.startDate).format("DD/MM/YYYY")} - ${moment(
                  item.expiryDate
                ).format("DD/MM/YYYY")})`}{" "}
              </span>
            </div>
          </Box>
        </StyledMenuItem>
      );
    });
  };

  return (
    <Accordion
      expanded={expanded === "EDIT_MEAL_PLAN_WITH_PAYEMENT"}
      onChange={handleChange("EDIT_MEAL_PLAN_WITH_PAYEMENT")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="#B27D3F" fontSize={18}>
          Edit Meal Plan With Payment
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormProvider {...methodsEditMealPlan}>
          <form
            onSubmit={handleSubmit(onSubmitEditMealPlan)}
            id="EDIT_MEAL_PLAN_WITH_PAYEMENT_FORM"
          >
            <Box display="flex" flexDirection="column" width="32%">
              <p className={ContainersClasses.section__subtitle}>
                Plan to edit
              </p>
              <Controller
                name="customerProgram"
                control={control}
                render={({
                  field: { ref, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      {...rest}
                      sx={{ minWidth: "400px" }}
                      className={LeadsClasses.select_container}
                      onChange={(event) => {
                        let plan = event.target.value;
                        onChange(plan);
                      }}
                      value={dataWatch.customerProgram}
                    >
                      {renderItems()}
                    </Select>
                    <p className={ContainersClasses.section__error__helper}>
                      {error?.message}
                    </p>
                  </>
                )}
                rules={{
                  required: {
                    message: "Field Required",
                    value: true,
                  },
                }}
              />
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 2fr" columnGap={5}>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={ContainersClasses.section__subtitle}>
                  Change meal plan to
                </p>
                <Controller
                  name="program"
                  control={control}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        {...rest}
                        sx={{ minWidth: "400px" }}
                        className={LeadsClasses.select_container}
                        onChange={(event) => {
                          emptyFields();
                          onChange(event.target.value);
                        }}
                        value={dataWatch.program}
                      >
                        {activePrograms?.map((el) => (
                          <MenuItem
                            key={el._id}
                            className={SelectClasses.option_item}
                            value={el?._id || ""}
                          >
                            {el?.name?.EN}
                          </MenuItem>
                        ))}
                      </Select>
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                  rules={{
                    required: {
                      message: "Field Required",
                      value: true,
                    },
                  }}
                />
              </Box>

              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={ContainersClasses.section__subtitle}>Meals</p>
                <Controller
                  name="meals"
                  control={control}
                  render={({
                    field: { onChange: Change, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        {...rest}
                        multiple
                        disabled={dataWatch.isCustomPackage}
                        id="meals"
                        value={dataWatch?.meals || []}
                        options={meals}
                        getOptionLabel={(option) =>
                          handleMealsEditMealPlan(option) ?? ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputRef={ref}
                            variant="outlined"
                            sx={{ minWidth: "400px" }}
                          />
                        )}
                        onChange={(_, data) => {
                          Change(data);
                        }}
                      />
                      <p className={InputClasses.input__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap={5}>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={ContainersClasses.section__subtitle}>
                  Days per week
                </p>
                <Controller
                  name="dayPerWeek"
                  control={control}
                  rules={{
                    required: "Field required",
                  }}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        {...rest}
                        sx={{ minWidth: "400px" }}
                        className={LeadsClasses.select_container}
                        onChange={(event) => {
                          onChange(event.target.value);
                        }}
                      >
                        {nbrDaysOptions?.map((option) => {
                          return (
                            <MenuItem
                              key={option.menuValue}
                              value={option.menuValue}
                              className={SelectClasses.option_item}
                            >
                              {option.menuText}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={ContainersClasses.section__subtitle}>Duration</p>
                <Controller
                  name="duration"
                  control={control}
                  rules={{
                    required: "Field required",
                  }}
                  render={({
                    field: { ref, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        disabled
                        {...rest}
                        sx={{ minWidth: "400px" }}
                        className={LeadsClasses.select_container}
                        onChange={(event) => onChange(event.target.value)}
                      >
                        {durationOptions
                          ?.filter((el) => el.menuValue !== "ONE_DAY")
                          ?.map((option) => {
                            return (
                              <MenuItem
                                key={option.menuValue}
                                value={option.menuValue}
                                className={
                                  dataWatch?.duration === option.menuValue
                                    ? clsx(
                                        LeadsClasses.option_item,
                                        LeadsClasses.option_item_selected
                                      )
                                    : LeadsClasses.option_item
                                }
                              >
                                {option.menuText}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
              <Box>
                {dataWatch.isCustomPackage ? (
                  <Box width="70%">
                    {CustomPackageMeals?.map((elem) => {
                      let quantity = 1;
                      if (elem === "Breakfast") {
                        quantity =
                          dataWatch?.customPackagesQuantities?.breakfast || 0;
                      } else if (elem === "Lunch/Dinner") {
                        quantity =
                          dataWatch?.customPackagesQuantities?.lunchDinner || 0;
                      } else if (elem === "Snack") {
                        quantity =
                          dataWatch?.customPackagesQuantities?.snack || 0;
                      }
                      return (
                        <Box
                          key={elem}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          marginTop={1}
                        >
                          <Box
                            fontFamily="Poppins"
                            fontSize={14}
                            fontWeight="500"
                            width={200}
                          >
                            {elem}
                          </Box>
                          <Box
                            height={25}
                            width={25}
                            borderRadius={12}
                            bgcolor={
                              !dataWatch.isCustomPackage ? "#C4C4C4" : "#000"
                            }
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="#fff"
                            sx={{
                              cursor: !dataWatch.isCustomPackage
                                ? "default"
                                : "pointer",
                              "&:hover": {
                                backgroundColor: "#C4C4C4",
                                transition: "0.3s",
                              },
                            }}
                            onClick={() =>
                              quantity === 0
                                ? () => null
                                : updateMealsQuantityHandler(
                                    "DECREMENT",
                                    elem,
                                    quantity
                                  )
                            }
                          >
                            -
                          </Box>
                          <Box
                            height={25}
                            width={40}
                            borderRadius={1}
                            bgcolor="#C4C4C4"
                            marginLeft={1}
                            marginRight={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {quantity}
                          </Box>
                          <Box
                            height={25}
                            width={25}
                            borderRadius={12}
                            bgcolor={
                              !dataWatch.isCustomPackage ? "#C4C4C4" : "#000"
                            }
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="#fff"
                            sx={{
                              cursor: !dataWatch.isCustomPackage
                                ? "default"
                                : "pointer",
                              "&:hover": {
                                backgroundColor: "#C4C4C4",
                                transition: "0.3s",
                              },
                            }}
                            onClick={() =>
                              updateMealsQuantityHandler(
                                "INCREMENT",
                                elem,
                                quantity
                              )
                            }
                          >
                            +
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box height={100}></Box>
                )}
                <Controller
                  name="isCustomPackage"
                  control={control}
                  render={({ field: { onChange, ref } }) => {
                    return (
                      <FormControlLabel
                        checked={dataWatch?.isCustomPackage}
                        onChange={(_, value) => {
                          onChange(value);
                        }}
                        value="start"
                        control={<Checkbox color="secondary" />}
                        label="Custom Package"
                        labelPlacement="start"
                        sx={{ marginLeft: 5.5, marginTop: "auto" }}
                      />
                    );
                  }}
                />
              </Box>

              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={ContainersClasses.section__subtitle}>Days off</p>
                <Controller
                  name="daysOff"
                  control={control}
                  render={({
                    field: { onChange: Change, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        {...rest}
                        multiple
                        id="daysOff"
                        value={dataWatch.daysOff || []}
                        options={weekDays}
                        getOptionLabel={(option) => handleDays(option) ?? ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputRef={ref}
                            variant="outlined"
                            sx={{ minWidth: "400px" }}
                          />
                        )}
                        onChange={(_, data) => {
                          Change(data);
                        }}
                      />
                      <p className={InputClasses.input__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={ContainersClasses.section__subtitle}>
                  Modification Start date
                </p>
                <Controller
                  name="startDate"
                  control={control}
                  rules={{
                    required: "Field required",
                  }}
                  render={({
                    field: { ref, onChange, value, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <Stack style={{ fontSize: 20 }}>
                          <DesktopDatePicker
                            value={value}
                            inputFormat="DD/MM/YYYY"
                            disablePast={false}
                            disableFuture={false}
                            onChange={(newValue) => {
                              onChange(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                      <p className={ContainersClasses.section__error__helper}>
                        {error?.message}
                      </p>
                    </>
                  )}
                />
              </Box>
            </Box>
            <Box>
              <p>Price Informations</p>
              <Button
                variant="contained"
                className={ButtonClasses.button_submit_form}
                disabled={loadingCalculatePlan}
                onClick={calculatePrice}
              >
                {loadingCalculatePlan && (
                  <CircularProgress
                    size={20}
                    style={{ marginRight: 10, color: "white" }}
                  />
                )}
                calculate Price
              </Button>
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              columnGap={5}
            >
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={ContainersClasses.section__subtitle}>Price</p>
                <TextField
                  value={`${dataWatch.initialPrice} ${auth.countryCurrency}`}
                />
              </Box>
              {dataWatch.netPlanPrice !== 0 && (
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={ContainersClasses.section__subtitle}>
                    Missing Amount
                  </p>
                  <TextField
                    value={`${dataWatch.netPlanPrice} ${auth.countryCurrency}`}
                  />
                </Box>
              )}
              {dataWatch.returnedToWallet !== 0 && (
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={ContainersClasses.section__subtitle}>
                    Returned To Wallet
                  </p>
                  <TextField
                    value={`${dataWatch.returnedToWallet} ${auth.countryCurrency}`}
                  />
                </Box>
              )}
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <p className={ContainersClasses.section__subtitle}>
                  Number Of Boxes
                </p>
                <TextField value={dataWatch.numberOfBoxes} />
              </Box>
            </Box>
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="flex-end"
              mt={3}
            >
              <Button
                variant="contained"
                className={ButtonClasses.button_open_list_page}
                onClick={() => setOpen(true)}
                disabled={loadingEditCustomerPlan}
              >
                {loadingEditCustomerPlan && (
                  <CircularProgress
                    size={20}
                    style={{ color: "white", marginRight: 6 }}
                  />
                )}
                Edit
              </Button>
            </Box>

            {/* CONFIRMATION MODAL */}
            <Dialog
              open={open}
              onClose={handleCloseModal}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.alert_dialog_title}>
                <span className="alert_dialog_title_text">Confirm Action</span>
              </DialogTitle>
              <DialogContent className={DialogClasses.alert_dialog_content}>
                <DialogContentText>
                  Are you sure you want to edit this meal plan ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={DialogClasses.alert_dialog_actions}>
                <Button
                  className={ButtonsClasses.GreyButton}
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  className={ButtonsClasses.GreenButton}
                  type="submit"
                  form="EDIT_MEAL_PLAN_WITH_PAYEMENT_FORM"
                  color="primary"
                  autoFocus
                  onClick={() => setOpen(false)}
                >
                  Edit
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default EditMealPlanWithPayment;
