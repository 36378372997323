import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { useSnackbar } from "notistack";
//material
import { Box, TextField, Button, CircularProgress } from "@mui/material";
//styles
import {
  useStylesDelivery,
  Container,
  ContainerForm,
} from "../../../../styles/Delivery__styles";
import { useStylesButtons } from "../../../../styles/Buttons__styles";
//components
import PhoneInput from "../../../layout/PhoneInput";
import { transformNumber } from "../../../layout/PhoneInput/CountryList";
// mutations and queries
import {
  GetDriversDocument,
  useEditDriverMutation,
  useGetDriverByIdQuery,
} from "../../../../graphql/types";
//types
import { DriverProps } from "../../Types";
//utils
import { useCountryPrefix } from "../../../../utils/Hooks/useCountryPrefix";

const UpdateDriver = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const idDriver = params.id || null;
  const countryPrefix = useCountryPrefix();
  //styles
  const DeliveryClasses = useStylesDelivery();
  const ButtonClasses = useStylesButtons();
  // queries and mutations
  const { data: dataDriver } = useGetDriverByIdQuery({
    variables: {
      driverId: idDriver || "",
    },
    skip: !idDriver,
  });

  const [editDriver, { loading }] = useEditDriverMutation();
  //react-hook-form
  const methods = useForm<DriverProps>({
    defaultValues: {
      driver__name: "",
      delivery__number: "",
      delivery__prefix: countryPrefix?.callingCode,
      driver__email: "",
      driver__password: "",
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;
  //useEffect
  useEffect(() => {
    if (dataDriver) {
      const dataPhone = transformNumber({
        prefix: dataDriver?.getDriverById?.phone?.prefix,
        phone: dataDriver?.getDriverById?.phone?.number,
      });
      setValue("driver__name", dataDriver?.getDriverById?.name || "");
      setValue("driver__email", dataDriver?.getDriverById?.email || "");
      setValue("driver__password", dataDriver?.getDriverById?.password || "");
      setValue("delivery__number", dataPhone.phoneNumber.replace(/\s/g, ""));
      setValue("delivery__prefix", dataPhone.prefix);
    }
  }, [dataDriver]);
  //functions
  const onSubmit: SubmitHandler<DriverProps> = async (data) => {
    if (idDriver)
      try {
        const phone = data.delivery__number.replace(/\s/g, "");
        await editDriver({
          variables: {
            updateDriverInput: {
              id: idDriver,
              name: data?.driver__name,
              email: data?.driver__email,
              password: data?.driver__password,
              phone: {
                prefix: `+${data.delivery__prefix}`,
                number: phone,
              },
            },
          },
          refetchQueries: [{ query: GetDriversDocument }],
          onCompleted: () => {
            navigate("/admin/delivery_driver/list_drivers");
            enqueueSnackbar("Driver succuessfully added", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setTimeout(() => closeSnackbar(), 5000);
          },
        });
      } catch (error) {
        console.log("#error", error);
      }
  };
  return (
    <FormProvider {...methods}>
      <Container>
        <p className={clsx(DeliveryClasses.delivery__title)}>Create Driver</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContainerForm>
            <Box
              className={clsx(DeliveryClasses.delivery__container)}
              sx={{
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={clsx(DeliveryClasses.delivery__subtitle)}>
                    Driver Name*
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Driver Name"
                    sx={{
                      width: "100%",
                    }}
                    {...register("driver__name", {
                      required: "Name is Mandatory",
                    })}
                  />
                  <p className={clsx(DeliveryClasses.delivery__error__helper)}>
                    {errors?.driver__name?.message}
                  </p>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={clsx(DeliveryClasses.delivery__subtitle)}>
                    Driver Phone Number*
                  </p>
                  <PhoneInput
                    labelFormPrefix="delivery__prefix"
                    labelFormNumber="delivery__number"
                    placeholder="Phone Number"
                  />
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={clsx(DeliveryClasses.delivery__subtitle)}>
                    Driver Email*
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Driver Email"
                    sx={{
                      width: "100%",
                    }}
                    {...register("driver__email", {
                      required: "Driver Email is Mandatory",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Enter a valid Email",
                      },
                    })}
                  />
                  <p className={clsx(DeliveryClasses.delivery__error__helper)}>
                    {errors?.driver__email?.message}
                  </p>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={clsx(DeliveryClasses.delivery__subtitle)}>
                    Driver Password*
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Driver Password"
                    sx={{
                      width: "100%",
                    }}
                    {...register("driver__password", {
                      required: "Driver Password is Mandatory",
                    })}
                  />
                  <p className={clsx(DeliveryClasses.delivery__error__helper)}>
                    {errors?.driver__password?.message}
                  </p>
                </Box>
              </Box>
            </Box>
          </ContainerForm>
          <Box
            sx={{
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
            className={clsx(DeliveryClasses.buttons__container)}
          >
            <Button
              variant="outlined"
              size="large"
              className={clsx(ButtonClasses.button_cancel_form)}
              onClick={() => navigate("/admin/delivery_driver/list_drivers")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={ButtonClasses.button_submit_form}
              type="submit"
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ marginRight: 10, color: "white" }}
                />
              )}
              Update Driver
            </Button>
          </Box>
        </form>
      </Container>
    </FormProvider>
  );
};

export default UpdateDriver;
