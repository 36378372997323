function RemindersIcon() {
  return (
    <svg
      width="38"
      height="26"
      viewBox="0 0 68 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_394_60723)">
        <path
          d="M44.1109 37.2242H23.9381C22.998 37.2242 22.1754 36.7574 21.6662 35.9795C21.1962 35.2015 21.1178 34.2291 21.5487 33.4122C23.3505 29.8336 24.3298 25.866 24.3298 21.8595V21.5873C24.3298 18.9811 25.3482 16.5305 27.2284 14.7023C29.1086 12.8741 31.5763 11.9017 34.2008 11.9406C39.4496 12.0184 43.7192 16.4916 43.7192 21.8595C43.7192 25.8271 44.6593 29.8336 46.5003 33.4122C46.9312 34.2291 46.892 35.2015 46.3828 35.9795C45.8736 36.7574 45.051 37.2242 44.1109 37.2242ZM34.0049 13.8855C31.968 13.8855 30.0487 14.6634 28.5602 16.1027C27.0717 17.5808 26.2491 19.5257 26.2491 21.5873V21.8595C26.2491 26.1383 25.2307 30.456 23.2722 34.268C23.1155 34.5792 23.2722 34.8514 23.2722 34.9681C23.3505 35.0848 23.5072 35.3182 23.8597 35.3182H44.0326C44.3851 35.3182 44.5809 35.0848 44.6201 34.9681C44.6985 34.8514 44.816 34.618 44.6201 34.268C42.6616 30.456 41.6432 26.1383 41.6432 21.8595C41.6432 17.5808 38.2353 13.9633 34.0441 13.8855C34.0049 13.8855 33.9657 13.8855 33.8874 13.8855H34.0049Z"
          fill="#fff"
        />
        <path
          d="M34.0041 41.5438C31.6147 41.5438 29.6953 39.6378 29.6953 37.265V36.2537C29.6953 35.7091 30.1262 35.2812 30.6746 35.2812C31.223 35.2812 31.6538 35.7091 31.6538 36.2537V37.265C31.6538 38.5487 32.7114 39.5989 34.0041 39.5989C35.2967 39.5989 36.3543 38.5487 36.3543 37.265V36.2537C36.3543 35.7091 36.7852 35.2812 37.3336 35.2812C37.8819 35.2812 38.3128 35.7091 38.3128 36.2537V37.265C38.3128 39.6378 36.3935 41.5438 34.0041 41.5438Z"
          fill="#fff"
        />
        <path
          d="M34.0104 13.8864C31.9343 13.8864 30.25 12.2138 30.25 10.1522C30.25 8.09058 31.9343 6.41797 34.0104 6.41797C36.0864 6.41797 37.7707 8.09058 37.7707 10.1522C37.7707 12.2138 36.0864 13.8864 34.0104 13.8864ZM34.0104 8.40176C33.0311 8.40176 32.2085 9.21862 32.2085 10.1911C32.2085 11.1635 33.0311 11.9804 34.0104 11.9804C34.9896 11.9804 35.8122 11.2024 35.8122 10.1911C35.8122 9.17972 34.9896 8.40176 34.0104 8.40176Z"
          fill="#fff"
        />
        <path
          d="M57.3113 47.9994C57.0763 47.9994 56.8021 47.9216 56.6063 47.7271C56.2146 47.3381 56.2146 46.7157 56.6063 46.3656C62.6777 40.3365 66.0464 32.3235 66.0464 23.8049C66.0464 15.2862 62.8736 7.66224 57.0763 1.67197C56.6846 1.28299 56.7238 0.660623 57.0763 0.310542C57.468 -0.0784366 58.0948 -0.0395388 58.4473 0.310542C64.5971 6.6509 67.9657 14.975 67.9657 23.766C67.9657 32.5569 64.4012 41.3089 57.9772 47.7271C57.7814 47.9216 57.5464 47.9994 57.2722 47.9994H57.3113Z"
          fill="#fff"
        />
        <path
          d="M50.7645 41.5032C50.5295 41.5032 50.2553 41.4254 50.0594 41.2309C49.6677 40.842 49.6677 40.2196 50.0594 39.8695C54.4073 35.5518 56.7576 29.8728 56.7576 23.8047C56.7576 17.7366 54.4857 12.3298 50.3728 8.08994C49.9811 7.70096 50.0202 7.07859 50.3728 6.72851C50.7645 6.33953 51.3912 6.37843 51.7437 6.72851C56.2092 11.3574 58.6377 17.4254 58.6377 23.8047C58.6377 30.1839 56.0525 36.6021 51.352 41.2698C51.1562 41.4643 50.9212 41.5421 50.647 41.5421L50.7645 41.5032Z"
          fill="#fff"
        />
        <path
          d="M10.6935 48.0001C10.4585 48.0001 10.1843 47.9223 9.98848 47.7278C3.56452 41.3096 0 32.791 0 23.7667C0 14.7424 3.36866 6.65162 9.51843 0.311265C9.91014 -0.0777135 10.4977 -0.0777135 10.8894 0.311265C11.2811 0.700244 11.2811 1.28371 10.8894 1.67269C5.13134 7.62407 1.95853 15.4814 1.95853 23.7667C1.95853 32.0519 5.28802 40.2983 11.3986 46.3275C11.7903 46.7164 11.7903 47.3388 11.3986 47.6889C11.2028 47.8834 10.9677 47.9612 10.6935 47.9612V48.0001Z"
          fill="#fff"
        />
        <path
          d="M17.2408 41.5038C17.0058 41.5038 16.7316 41.426 16.5357 41.2315C11.8353 36.5638 9.25 30.379 9.25 23.7663C9.25 17.1537 11.7177 11.319 16.144 6.69017C16.5357 6.30119 17.1233 6.30119 17.515 6.69017C17.9067 7.07915 17.9067 7.66262 17.515 8.0516C13.4021 12.2915 11.1302 17.8928 11.1302 23.7663C11.1302 29.6399 13.5196 35.5524 17.8283 39.8312C18.22 40.2202 18.22 40.8425 17.8283 41.1926C17.6325 41.3871 17.3975 41.4649 17.1233 41.4649L17.2408 41.5038Z"
          fill="#fff"
        />
      </g>
      {/* <defs>
          <clipPath id="clip0_394_60723">
            <rect width="68" height="48" fill="white" />
          </clipPath>
        </defs> */}
    </svg>
  );
}

export default RemindersIcon;
