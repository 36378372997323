//material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//styles
import { ContainerStyled } from "../../../../styles/Containers__styles";
import {
  MacrosColor,
  useStylesMacrosDetails,
  InputBaseStyled,
} from "../../../../styles/Macros__styles";
//types
import { MacrosDetailsProps, MacrosProps } from "../../Types";

const Macros: React.FC<MacrosProps> = ({
  caloriesSmall,
  caloriesMedium,
  caloriesLarge,
  proteinSmall,
  proteinMedium,
  proteinLarge,
  carbsSmall,
  carbsMedium,
  carbsLarge,
  fatSmall,
  fatMedium,
  fatLarge,
  fiberSmall,
  fiberMedium,
  fiberLarge,
}): JSX.Element => {
  const percentageProteinSmall = (proteinSmall * 4) / caloriesSmall;
  const percentageProteinMedium = (proteinMedium * 4) / caloriesMedium;
  const percentageProteinLarge = (proteinLarge * 4) / caloriesLarge;

  const percentageCarbsSmall = (carbsSmall * 4) / caloriesSmall;
  const percentageCarbsMedium = (carbsMedium * 4) / caloriesMedium;
  const percentageCarbsLarge = (carbsLarge * 4) / caloriesLarge;

  const percentageFatSmall = (fatSmall * 9) / caloriesSmall;
  const percentageFatMedium = (fatMedium * 9) / caloriesMedium;
  const percentageFatLarge = (fatLarge * 9) / caloriesLarge;

  const percentageFiberSmall = (fiberSmall * 2) / caloriesSmall;
  const percentageFiberMedium = (fiberMedium * 2) / caloriesMedium;
  const percentageFiberLarge = (fiberLarge * 2) / caloriesLarge;

  return (
    <ContainerStyled>
      <Box
        display="grid"
        width="100%"
        gridTemplateColumns="1fr 1fr 1fr 1fr"
        alignItems="center"
        rowGap={1}
        columnGap={4}
        pt={2}
      >
        {/* header */}
        <Typography>Dish Size :</Typography>
        <Typography align="center" sx={{ color: "text.primary" }}>
          Small
        </Typography>
        <Typography align="center" sx={{ color: "text.primary" }}>
          Medium
        </Typography>
        <Typography align="center" sx={{ color: "text.primary" }}>
          Large
        </Typography>
        {/* values */}
        <Typography variant="h3">Calories</Typography>
        <InputBaseStyled type="number" value={caloriesSmall} />
        <InputBaseStyled type="number" value={caloriesMedium} />
        <InputBaseStyled type="number" value={caloriesLarge} />
        {/* Protein (%) g */}
        <Box
          display="flex"
          width="200px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Protein (%) g</Typography>
          <MacrosColor bg="#F6C104" />
        </Box>
        <MacrosDetails
          weight={proteinSmall.toFixed(2) + "g"}
          percentage={
            percentageProteinSmall
              ? percentageProteinSmall.toFixed(2) + "%"
              : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#F6C104"
        />
        <MacrosDetails
          weight={proteinMedium.toFixed(2) + "g"}
          percentage={
            percentageProteinMedium
              ? percentageProteinMedium.toFixed(2) + "%"
              : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#F6C104"
        />
        <MacrosDetails
          weight={proteinLarge.toFixed(2) + "g"}
          percentage={
            percentageProteinLarge
              ? percentageProteinLarge.toFixed(2) + "%"
              : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#F6C104"
        />

        {/* Carbs (%) g */}
        <Box
          display="flex"
          width="200px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Carbs (%) g</Typography>
          <MacrosColor bg="#1BA9A5" />
        </Box>
        <MacrosDetails
          weight={carbsSmall.toFixed(2) + "g"}
          percentage={
            percentageCarbsSmall ? percentageCarbsSmall.toFixed(2) + "%" : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#1BA9A5"
        />
        <MacrosDetails
          weight={carbsMedium.toFixed(2) + "g"}
          percentage={
            percentageCarbsMedium
              ? percentageCarbsMedium.toFixed(2) + "%"
              : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#1BA9A5"
        />
        <MacrosDetails
          weight={carbsLarge.toFixed(2) + "g"}
          percentage={
            percentageCarbsLarge ? percentageCarbsLarge.toFixed(2) + "%" : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#1BA9A5"
        />
        {/* Fat (%) g */}
        <Box
          display="flex"
          width="200px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Fat (%) g</Typography>
          <MacrosColor bg="#232222" />
        </Box>
        <MacrosDetails
          weight={fatSmall.toFixed(2) + "g"}
          percentage={
            percentageFatSmall ? percentageFatSmall.toFixed(2) + "%" : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#232222"
        />
        <MacrosDetails
          weight={fatMedium.toFixed(2) + "g"}
          percentage={
            percentageFatMedium ? percentageFatMedium.toFixed(2) + "%" : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#232222"
        />
        <MacrosDetails
          weight={fatLarge.toFixed(2) + "g"}
          percentage={
            percentageFatLarge ? percentageFatLarge.toFixed(2) + "%" : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#232222"
        />
        {/* Fiber (%) g */}
        <Box
          display="flex"
          width="200px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Fiber (%) g</Typography>
          <MacrosColor bg="#E787D6" />
        </Box>
        <MacrosDetails
          weight={fiberSmall.toFixed(2) + "g"}
          percentage={
            percentageFiberSmall ? percentageFiberSmall.toFixed(2) + "%" : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#E787D6"
        />
        <MacrosDetails
          weight={fiberMedium.toFixed(2) + "g"}
          percentage={
            percentageFiberMedium
              ? percentageFiberMedium.toFixed(2) + "%"
              : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#E787D6"
        />
        <MacrosDetails
          weight={fiberLarge.toFixed(2) + "g"}
          percentage={
            percentageFiberLarge ? percentageFiberLarge.toFixed(2) + "%" : "0%"
          }
          targetWeight="Target 160g"
          targetpercentage="40%"
          color="#E787D6"
        />
      </Box>
    </ContainerStyled>
  );
};

export default Macros;

const MacrosDetails = ({
  color,
  percentage,
  weight,
  targetWeight,
  targetpercentage,
}: MacrosDetailsProps): JSX.Element => {
  const stylesMacrosDetails = useStylesMacrosDetails();
  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box className={stylesMacrosDetails.macros__value} borderColor={color}>
        <Box
          width="50%"
          className={stylesMacrosDetails.macros__box}
          borderColor={color}
        >
          <Typography align="center" variant="h4">
            {weight}
          </Typography>
        </Box>
        <Box width="50%">
          <Typography align="center" variant="h4">
            {percentage}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography className={stylesMacrosDetails.macros__label}>
          {targetWeight}
        </Typography>
        <Typography className={stylesMacrosDetails.macros__label}>
          {targetpercentage}
        </Typography>
      </Box>
    </Box>
  );
};
