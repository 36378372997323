import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "CUSTOMER NAME",
    accessor: "name",
  },
  {
    header: "MOBILE",
    accessor: "mobile",
  },
  {
    header: "EMAIL",
    accessor: "email",
  },
  {
    header: "PLAN",
    accessor: "plan",
  },
  {
    header: "Promo code",
    accessor: "promoCode",
  },
  {
    header: "Price",
    accessor: "price",
  },
  {
    header: "Payment Method",
    accessor: "payment",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "STATUS",
    accessor: "status",
  },
  {
    header: "",
    accessor: "actions",
  },
];
