// react
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// react router
import { useNavigate, useParams } from "react-router-dom";

// MUI
import { Box, TextField, Button, CircularProgress } from "@mui/material";

// google map
import GeoMap from "../components/GeoMap";
import { useLoadScript } from "@react-google-maps/api";

// styles
import { useStylesContainers } from "../../../../styles/Containers__styles";
import { StyledLoaderContainer } from "../../../../styles/Customers__styles";
import { useStylesButtons } from "../../../../styles/Buttons__styles";
import { Container } from "../../../../styles/Ingredient__styles";
import { useStylesDelivery } from "../../../../styles/Delivery__styles";

// useForm
import { SubmitHandler, useForm } from "react-hook-form";

// graphql
import {
  AreaPayload,
  CreateAreaDto,
  GetAllAreasDocument,
  PolygonIput,
  useCreateAreaMutation,
  useGetAllAreasQuery,
  useUpdateAreaMutation,
} from "../../../../graphql/types";

// external
import { useSnackbar } from "notistack";
import clsx from "clsx";
import { React_APP_GOOGLE_API_KEY } from "../../../../utils/config";

interface formData {
  name: string;
  nameAr: string;
}
const AddEditArea = () => {
  // react hooks
  const [polygonCord, setpolygonCord] = useState<
    PolygonIput["coordinates"] | []
  >([]);

  const navigate = useNavigate();
  const param = useParams();

  // custom style hooks
  const DeliveryClasses = useStylesDelivery();
  const ButtonClasses = useStylesButtons();
  const ContainersClasses = useStylesContainers();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: React_APP_GOOGLE_API_KEY || "",
    libraries: ["drawing", "places"],
  });

  // apollo custom hooks
  const {
    data: areasData,
    loading,
    refetch: refetchAreas,
  } = useGetAllAreasQuery({
    variables: {
      input: { page: 0, documentPerPage: 0, name: "", sortBy: "NAME_A_Z" },
    },
  });

  const areaToUpdate: AreaPayload | undefined =
    areasData?.getAllAreas.data.find((area) => area._id === param.id);

  const [updateArea, { loading: upLoading }] = useUpdateAreaMutation();
  const [createArea, { loading: crLoading }] = useCreateAreaMutation();

  // useFrom
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      nameAr: "",
    },
  });

  // set the polygon from the areaToUpdate variable to a state, to then manipulate it
  useEffect(() => {
    if (areaToUpdate?.cordinates) {
      setpolygonCord(areaToUpdate.cordinates?.coordinates);
      reset({ name: areaToUpdate.name!, nameAr: areaToUpdate.areaName?.AR! });
    }
  }, [areaToUpdate, reset]);

  const loadingStates = [!loading, isLoaded].every((elem) => !!elem);

  const center = useMemo(() => ({ lat: 25.2975, lng: 51.5495 }), []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // save polygon path on polygon complete
  const handlePolygonComplete = (polygon: google.maps.Polygon) => {
    const polygonPaths = polygon.getPath().getArray();

    const coordinates: number[][] = polygonPaths.map(
      (latLng: google.maps.LatLng) => [latLng.lat(), latLng.lng()]
    );

    coordinates.push(coordinates[0]);
    setpolygonCord([coordinates]);
  };

  // Edit Polygon
  const polygonRef = useRef<null | google.maps.Polygon>(null);
  const listenersRef = useRef<google.maps.MapsEventListener[]>([]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng: google.maps.LatLng) => {
          return [latLng.lat(), latLng.lng()];
        });

      setpolygonCord([nextPath]);
    }
  }, [setpolygonCord]);

  const onLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onSubmit: SubmitHandler<formData> = async (data): Promise<void> => {
    try {
      const area: CreateAreaDto = {
        name: data.name,
        areaName: {
          AR: data.nameAr,
          EN: data.name,
        },
        cordinates: {
          type: "POLYGON",
          coordinates: polygonCord!,
        },
      };

      if (areaToUpdate) {
        // if the url has an id (update mode) call the update mutation
        await updateArea({
          variables: {
            input: { id: areaToUpdate._id, ...area },
          },
          refetchQueries: [GetAllAreasDocument],
        });
        enqueueSnackbar("Area Updated Successfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
        navigate("/admin/area/list_areas");
      } else {
        // if the url dosen't have and id  (Create mode ) call the Create mutation

        await createArea({
          variables: {
            input: area,
          },
          refetchQueries: [GetAllAreasDocument],
        });
        enqueueSnackbar("Area Added Successfully", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setTimeout(() => closeSnackbar(), 5000);
        refetchAreas();
        navigate("/admin/area/list_areas");
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong, ", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
  };

  if (!loadingStates)
    return (
      <StyledLoaderContainer>
        <CircularProgress size={40} color="secondary" />
      </StyledLoaderContainer>
    );

  return (
    <Container>
      <Box
        sx={{
          width: "80%",
          maxWidth: 1200,
          height: 400,
          border: "1px dashed grey",
          marginX: "auto",
          marginBottom: 6,
        }}
      >
        {!isLoaded && (
          <StyledLoaderContainer>
            <CircularProgress size={40} color="secondary" />
          </StyledLoaderContainer>
        )}
        {isLoaded && (
          <GeoMap
            polygonCord={polygonCord!}
            onLoad={onLoad}
            onEdit={onEdit}
            onUnmount={onUnmount}
            update={areaToUpdate}
            center={center}
            containerClass="map-container"
            zoom={8}
            areas={areasData?.getAllAreas.data!}
            onPolygonComplete={handlePolygonComplete}
          />
        )}
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            width: "80%",
            maxWidth: 1200,
            marginX: "auto",
          }}
        >
          <Box
            width={"40%"}
            minWidth={400}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Box minWidth={170}>
              <p className={ContainersClasses.section__subtitle}>Area Name</p>
              <TextField
                {...register("name")}
                id="demo-helper-text-misaligned"
                placeholder="e.g Doha"
              />
            </Box>
            <Box minWidth={170}>
              <p className={ContainersClasses.section__subtitle}>
                Area Name (AR)
              </p>
              <TextField
                {...register("nameAr")}
                id="demo-helper-text-misaligned"
                placeholder="e.g الدوحة "
              />
            </Box>
          </Box>
          <Box
            sx={{
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
            className={clsx(DeliveryClasses.buttons__container)}
          >
            <Button
              variant="outlined"
              size="large"
              className={clsx(ButtonClasses.button_cancel_form)}
              onClick={() => navigate("/admin/area/list_areas")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={ButtonClasses.button_submit_form}
              type="submit"
              disabled={crLoading || upLoading}
            >
              {(crLoading || upLoading) && (
                <CircularProgress
                  size={20}
                  style={{ marginRight: 10, color: "white" }}
                />
              )}
              Save Area
            </Button>
          </Box>
        </Box>
      </form>
    </Container>
  );
};

export default AddEditArea;
