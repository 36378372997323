import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ChefMeals, ChefsMeals, Dishes } from "./Types";
import DishesPoolSection from "./DishesPoolSection";
import ChefsDropSection from "./ChefsDropSection";
import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import {
  GetAllTasksPayload,
  useAssignTaskToChefMutation,
  useGetALlChefsQuery,
  useGetAndRecordTasksIfFirstCallQuery,
} from "../../../graphql/types";
import { useSnackbar } from "notistack";
import { getMessageError } from "../../Utils";
import { ApolloError } from "@apollo/client";

type Props = {
  date?: Date;
  program?: string;
};
const Preparations: React.FC<Props> = ({ date, program }) => {
  const initialDishes: Dishes = {
    breakfast: [],
    lunch: [],
    snacks: [],
    sauce: [],
    addon: [],
  };
  const [dishes, setDishes] = useState<Dishes>({});
  const [chefs, setChefs] = useState<ChefsMeals>({});

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const chefsList = useGetALlChefsQuery();

  const getAlltasks = useGetAndRecordTasksIfFirstCallQuery({
    variables: { date: date?.toDateString() || "", program: program || "" },
    fetchPolicy: "no-cache",
  });

  const [assign, { loading }] = useAssignTaskToChefMutation();

  const onAssign = async ({ chefId, _id }: { chefId: string; _id: string }) => {
    try {
      await assign({
        variables: {
          chefId,
          date: date?.toISOString() || "",
          _id,
        },
        onCompleted: () => {
          enqueueSnackbar("Tasks Assigned succuessfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 3000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 3000);
      console.error(error);
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    const sourceIds = source.droppableId.split("-");
    const destinationsIds = destination.droppableId.split("-");

    if (source.droppableId === destination.droppableId) {
      // Reorder within the same pool
      const items = Array.from(
        dishes[sourceIds[0] as keyof Dishes] ||
          chefs[sourceIds[0] as keyof ChefsMeals][
            sourceIds[1] as keyof ChefMeals
          ]
      );
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      if (dishes[sourceIds[0] as keyof Dishes]) {
        setDishes((prev) => ({
          ...prev,
          [sourceIds[0]]: items,
        }));
      } else {
        setChefs((prev) => ({
          ...prev,
          [sourceIds[0]]: {
            ...prev[sourceIds[0]],
            [sourceIds[1]]: items,
          },
        }));
      }
    } else {
      // move from pool to pool

      // forbid move from chef pool to dish pool
      if (!destinationsIds[1]) return;

      // forbid draging within the same chef
      if (chefs?.[sourceIds[0]] === chefs[destinationsIds[0]]) return;

      const items = Array.from(
        dishes[sourceIds[0] as keyof Dishes] ||
          chefs[sourceIds[0] as keyof ChefsMeals][
            sourceIds[1] as keyof ChefMeals
          ]
      );
      const [movedItem] = items.splice(source.index, 1);

      // move from chef to chef
      if (chefs[sourceIds[0]]) {
        const newChefs = {
          ...chefs,
          [sourceIds[0]]: { ...chefs[sourceIds[0]], [sourceIds[1]]: items },
          [destinationsIds[0]]: {
            ...chefs[destinationsIds[0]],
            [sourceIds[1]]: [
              ...chefs[destinationsIds[0]][sourceIds[1] as keyof ChefMeals],
              movedItem,
            ],
          },
        };
        setChefs(newChefs);
      } else {
        // move from dish pool to chef pool
        setDishes((prev) => ({
          ...prev,
          [sourceIds[0]]: items,
        }));

        setChefs((prev) => ({
          ...prev,
          [destinationsIds[0]]: {
            ...prev[destinationsIds[0]],
            [sourceIds[0]]: [
              ...prev[destinationsIds[0] as keyof ChefMeals][
                sourceIds[0] as keyof Dishes
              ],
              movedItem,
            ],
          },
        }));
      }
      onAssign({ _id: movedItem._id!, chefId: destinationsIds[0] });
    }
  };

  const intitalStates = (
    data: GetAllTasksPayload[],
    intitalChefs: ChefsMeals
  ) => {
    const assignedTasks = data?.filter((task) => task.isAssigned);
    const unassignedTasks = data?.filter((task) => !task.isAssigned);

    const formatedChefs = assignedTasks.reduce<ChefsMeals>((acc, task) => {
      let taskType = task.type;
      if (taskType === "DINNER") taskType = "LUNCH";
      acc[task.chef!][taskType.toLowerCase() as keyof ChefMeals]?.push(task);
      return acc;
    }, intitalChefs);

    const formatedDishes = unassignedTasks.reduce<Dishes>((acc, task) => {
      let taskType = task.type;
      if (taskType === "DINNER") taskType = "LUNCH";

      acc[taskType.toLowerCase() as keyof Dishes]?.push(task);
      return acc;
    }, initialDishes);
    return { formatedChefs, formatedDishes };
  };

  useEffect(() => {
    const initialChefs = chefsList.data?.getALlChefs.reduce<ChefsMeals>(
      (acc, chef) => {
        const chefKey = chef._id!;
        acc[chefKey] = {
          breakfast: [],
          lunch: [],
          snacks: [],
          sauce: [],
          addon: [],
        };
        return acc;
      },
      {}
    );

    if (getAlltasks.data?.getAndRecordTasksIfFirstCall) {
      const { formatedChefs, formatedDishes } = intitalStates(
        getAlltasks.data?.getAndRecordTasksIfFirstCall,
        initialChefs!
      );

      setDishes(formatedDishes);
      setChefs((prev) => ({ ...prev, ...formatedChefs }));
    }
  }, [
    getAlltasks.data?.getAndRecordTasksIfFirstCall,
    chefsList.data?.getALlChefs,
  ]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom={2}
        borderColor="lightgrey"
        paddingBottom={3}
        marginBottom={3}
      >
        <Typography fontSize={20}>To prepare</Typography>
        <Typography>{moment(date).format("Do MMMM YYYY, dddd")}</Typography>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={3}>
          <Box width={"100%"}>
            {Object.keys(dishes).map((section) => (
              <Box key={section} marginBottom={2}>
                <DishesPoolSection
                  title={section}
                  items={dishes[section as keyof Dishes]}
                />
              </Box>
            ))}
          </Box>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "black",
              padding: ".5rem 1rem",
              textAlign: "center",
            }}
          >
            <Grid item xs={2} key={1}>
              <Typography
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Chef Name
              </Typography>
            </Grid>
            <Grid item xs={2} key={2}>
              <Typography
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Breakfast
              </Typography>
            </Grid>
            <Grid item xs={2} key={3}>
              <Typography
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Lunch & Dinner
              </Typography>
            </Grid>{" "}
            <Grid item xs={2} key={4}>
              <Typography
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Snack
              </Typography>
            </Grid>{" "}
            <Grid item xs={2} key={5}>
              <Typography
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Sauces
              </Typography>
            </Grid>{" "}
            <Grid item xs={2} key={6}>
              <Typography
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Addons
              </Typography>
            </Grid>{" "}
          </Grid>
          <Box
            width={"100%"}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {Object.keys(chefs).map((chef) => (
              <>
                <Box marginTop={3} key={chef}>
                  <ChefsDropSection
                    chefList={chefsList?.data?.getALlChefs!}
                    _id={chef}
                    items={chefs[chef]}
                    loading={loading}
                  />
                </Box>
              </>
            ))}
          </Box>
        </Grid>
      </DragDropContext>
    </>
  );
};

export default Preparations;
