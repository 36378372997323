import { Theme, lighten } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStylesRecipe = makeStyles((theme: Theme) => {
  return {
    recipe__container: {
      display: "flex",
      flexDirection: "row",
      gap: "15px",
      width: "100%",
      "& > div": {
        width: "100%",
      },
    },
    recipe__accordion: {
      padding: "5px 20px !important",
      "& .MuiAccordion-gutters": {
        boxShadow: "none",
        "& >div": {
          padding: "0px !important",
        },
      },
    },
    recipe__accordion__supplier: {
      padding: "5px 0px !important",
      "& .MuiAccordion-gutters": {
        boxShadow: "none",
        border: `1px solid ${lighten(
          theme.palette.text.primary,
          0.7
        )} !important`,
        "& >div": {
          padding: "0px 15px !important",
        },
      },
    },
    recipe__title: {
      color: `${theme.palette.secondary.main} !important`,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
      margin: "5px 0 !important",
    },
    recipe__subtitle: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
      marginBottom: "0px !important",
      "& > span": {
        color: `${theme.palette.success.contrastText} !important`,
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.subtitle2.fontWeight,
      },
    },
    recipe__subtext: {
      color: `${theme.palette.text.secondary} !important`,
      fontSize: theme.typography.h4.fontSize,
      fontWeight: theme.typography.h4.fontWeight,
      marginBottom: "0px !important",
      "& > span": {
        color: `${theme.palette.success.contrastText} !important`,
        fontSize: theme.typography.subtitle2.fontSize,
      },
    },
    recipe__quantity__display: {
      color: `${theme.palette.secondary.main} !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      marginBottom: "5px",
    },
    recipe__error__helper: {
      color: `${theme.palette.error.main}`,
      fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    },
    recipe__container__tags: {
      display: "flex",
      flexWrap: "wrap",
      overflowX: "auto",
      gap: "5px",
      maxWidth: "700px",
      maxHeight: "120px",
    },
    recipe__tags: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
    },
    recipe__tags__icon: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      fill: `${theme.palette.primary.contrastText} !important`,
      fontSize: `18px !important`,
    },
    recipe__add__container: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "10px",
    },
    recipe__add__subtitle: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle1.fontWeight,
      textTransform: "capitalize",
    },
    recipe__view__titles: {
      display: "flex",
      alignItems: "center",
    },
    recipe__name__title: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.h2.fontSize} !important`,
      fontWeight: theme.typography.h1.fontWeight,
      margin: "0px !important",
    },
    recipe__name__subtitle: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.h4.fontSize} !important`,
      fontWeight: 600,
      margin: "0px !important",
    },
    recipe__id: {
      color: `${theme.palette.warning.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle1.fontWeight,
      marginLeft: "15px",
    },
    recipe__cell: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle2.fontWeight,
      padding: "12px 0 !important",
    },
    recipe__tags__container: {
      maxWidth: "400px",
    },
    // recipe__tags: {
    //   backgroundColor: `${theme.palette.primary.main} !important`,
    //   color: `${theme.palette.primary.contrastText} !important`,
    //   fontSize: `${theme.typography.subtitle1.fontSize} !important`,
    // },
    // recipe__title: {
    //     color: `${theme.palette.secondary.main} !important`,
    //     fontSize: theme.typography.h3.fontSize,
    //     fontWeight: theme.typography.h3.fontWeight,
    //   },
  };
});
