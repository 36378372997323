import React, { useEffect } from "react";
import { useLocation } from "react-router";
import PropTypes from "prop-types";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import AppMenuItemComponent from "../AppMenuItemComponent/AppMenuItemComponent";
import { useAuth } from "../../../contextAPI";

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type AppMenusItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenusItemPropTypes, "items">;

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[];
};

const AppMenuItem: React.FC<AppMenuItemProps> = (props) => {
  const {
    name,
    link,
    Icon,
    items = [],
    rootLink,
    isOpen,
    handleItemClick,
    openItemDefault,
  } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  function handleClick() {
    handleItemClick();
  }

  const { pathname } = useLocation();

  useEffect(() => {
    if (rootLink) {
      if (pathname?.toLowerCase().includes(rootLink.toLocaleLowerCase())) {
        openItemDefault();
      }
    }
  }, [pathname, rootLink]);

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem}
      link={link}
      onClick={handleClick}
    >
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>{Icon}</ListItemIcon>
      )}
      <ListItemText primary={name} inset={!Icon} />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !isOpen && <ExpandMore />}
      {isExpandable && isOpen && <ExpandLess />}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse
      in={isOpen}
      timeout="auto"
      unmountOnExit
      className={classes.menuItemSub}
    >
      <List component="div" disablePadding>
        {items.map((item) => (
          <AppMenuItem {...item} key={item.name} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const auth = useAuth();
  return {
    menuItem: {
      "& .MuiListItemIcon-root": {
        minWidth: 40,
      },
    },
    menuItemIcon: {
      display: "flex",
      width: 40,
      height: 40,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: "50%",
      marginRight: "12px",
      paddingTop: "0px",
    },
    menuItemSub: {
      "& .MuiList-root": {
        padding: 0,
        marginBottom: -8,
        "& .MuiListItem-root": {
          margin: "8px 0 14px 0",
        },
      },
    },
  };
});

export default AppMenuItem;

// React runtime PropTypes
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  rootLink: PropTypes.string,
  Icon: PropTypes.any,
  items: PropTypes.array,
  isOpen: PropTypes.any,
  handleItemClick: PropTypes.any,
  openItemDefault: PropTypes.any,
};
