import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStylesDelivery = makeStyles((theme: Theme) => {
  return {
    delivery__container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    area__container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "15px",
    },
    buttons__container: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
      gap: "15px",
    },
    delivery__title: {
      color: `${theme.palette.secondary.main} !important`,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
      margin: "0px 0 20px !important",
    },
    delivery__subtitle: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      "& > span": {
        color: `${theme.palette.success.contrastText} !important`,
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.subtitle2.fontWeight,
      },
    },
    delivery__subtitle__checkbox: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
    },
    delivery__text: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: theme.typography.subtitle2.fontWeight,
    },
    delivery__error__helper: {
      color: `${theme.palette.error.main}`,
      fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    },
    delivery__view__titles: {
      display: "flex",
      alignItems: "center",
    },
    delivery__name__title: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.h1.fontSize} !important`,
      fontWeight: theme.typography.h1.fontWeight,
      margin: "0px !important",
    },
    delivery__id: {
      color: `${theme.palette.warning.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle1.fontWeight,
      marginLeft: "15px",
    },
    delivery__cell: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle2.fontWeight,
    },
  };
});
export const Container = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.primary.contrastText,
  maxWidth: "40vw",
  height: "auto",
  minHeight: "40vh",
  margin: "45px auto",
  padding: "30px",
  boxSizing: "border-box",
}));
export const ContainerForm = styled("div")(() => ({
  margin: "45px auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
