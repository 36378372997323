import React, { forwardRef } from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";

export interface AppMenuItemComponentProps {
  className?: string;
  link?: string | null; // because the InferProps props allows alows null value
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const AppMenuItemComponent: React.FC<AppMenuItemComponentProps> = (props) => {
  const { className, onClick, link, children } = props;

  const { pathname } = useLocation();

  // If link is not set return the orinary ListItem
  if (!link || typeof link !== "string") {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={onClick}
      />
    );
  }

  const baseLink = link
    ?.split("/")
    .filter((el, i) => el && i < link?.split("/").length - 1)
    .join("/");

  // Return a LitItem with a link component
  return (
    <ListItem
      button
      className={className}
      children={children}
      component={forwardRef((props: NavLinkProps, ref: any) => (
        <NavLink
          {...props}
          style={() =>
            pathname.toLocaleLowerCase().includes(baseLink?.toLocaleLowerCase())
              ? {
                  color: `#b27d3f`,
                }
              : {}
          }
        />
      ))}
      to={link}
    />
  );
};

export default AppMenuItemComponent;
