function UploadIcon({ fill }: { fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="12"
      fill="none"
      viewBox="0 0 17 12"
    >
      <path
        fill={fill ?? "#fff"}
        d="M14.28 5.213c.109-.287.17-.6.17-.927a2.561 2.561 0 00-2.55-2.572c-.523 0-1.012.161-1.416.434A4.24 4.24 0 006.8 0C4.452 0 2.55 1.918 2.55 4.286c0 .072.003.144.005.217A3.858 3.858 0 000 8.143C0 10.273 1.713 12 3.825 12H13.6c1.878 0 3.4-1.535 3.4-3.429a3.421 3.421 0 00-2.72-3.358zm-3.83 1.644H8.713v3a.428.428 0 01-.425.429H7.011a.428.428 0 01-.424-.429v-3H4.85a.428.428 0 01-.3-.731l2.8-2.823a.424.424 0 01.6 0l2.8 2.823a.429.429 0 01-.3.731z"
      ></path>
    </svg>
  );
}

export default UploadIcon;
