import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import clsx from "clsx";
import { useSnackbar } from "notistack";
//material
import { Box, TextField, Button, CircularProgress } from "@mui/material";
//styles
import {
  useStylesDelivery,
  Container,
  ContainerForm,
} from "../../../../styles/Delivery__styles";
import { useStylesButtons } from "../../../../styles/Buttons__styles";
//components
import PhoneInput from "../../../layout/PhoneInput";
// mutations and queries
import {
  GetDriversDocument,
  useCreateDriverMutation,
} from "../../../../graphql/types";
//types
import { DriverProps } from "../../Types";
//utils
import { useCountryPrefix } from "../../../../utils/Hooks/useCountryPrefix";

const AddDriver = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const countryPrefix = useCountryPrefix();
  //styles
  const DeliveryClasses = useStylesDelivery();
  const ButtonClasses = useStylesButtons();

  // queries and mutations
  const [createDriver, { loading }] = useCreateDriverMutation();

  //react-hook-form
  const methods = useForm<DriverProps>({
    defaultValues: {
      driver__name: "",
      delivery__number: "",
      delivery__prefix: countryPrefix?.callingCode,
      driver__email: "",
      driver__password: "",
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  //functions
  const onSubmit: SubmitHandler<DriverProps> = async (data) => {
    try {
      const phone = data.delivery__number.replace(/\s/g, "");

      await createDriver({
        variables: {
          createDriverInput: {
            name: data?.driver__name,
            email: data?.driver__email,
            password: data?.driver__password,
            phone: {
              prefix: `+${data.delivery__prefix}`,
              number: phone,
            },
            pinCode: "",
          },
        },
        refetchQueries: [{ query: GetDriversDocument }],
        onCompleted: () => {
          navigate("/admin/delivery_driver/list_drivers");
          enqueueSnackbar("Delivery Driver succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (error) {
      console.log("#error", error);
    }
  };
  return (
    <FormProvider {...methods}>
      <Container>
        <p className={clsx(DeliveryClasses.delivery__title)}>Create Driver</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContainerForm>
            <Box
              className={clsx(DeliveryClasses.delivery__container)}
              sx={{
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={clsx(DeliveryClasses.delivery__subtitle)}>
                    Driver Name*
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Driver Name"
                    sx={{
                      width: "100%",
                    }}
                    {...register("driver__name", {
                      required: "Name is Mandatory",
                      minLength: {
                        value: 2,
                        message: "Please enter a least 2 characters",
                      },
                    })}
                  />
                  <p className={clsx(DeliveryClasses.delivery__error__helper)}>
                    {errors?.driver__name?.message}
                  </p>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={clsx(DeliveryClasses.delivery__subtitle)}>
                    Driver Phone Number*
                  </p>
                  <PhoneInput
                    labelFormPrefix="delivery__prefix"
                    labelFormNumber="delivery__number"
                    placeholder="Phone Number"
                  />
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={clsx(DeliveryClasses.delivery__subtitle)}>
                    Driver Email*
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Driver Email"
                    sx={{
                      width: "100%",
                    }}
                    {...register("driver__email", {
                      required: "Driver Email is Mandatory",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Enter a valid Email",
                      },
                    })}
                  />
                  <p className={clsx(DeliveryClasses.delivery__error__helper)}>
                    {errors?.driver__email?.message}
                  </p>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <p className={clsx(DeliveryClasses.delivery__subtitle)}>
                    Driver Password*
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Driver Password"
                    sx={{
                      width: "100%",
                    }}
                    {...register("driver__password", {
                      required: "Driver Password is Mandatory",
                    })}
                  />
                  <p className={clsx(DeliveryClasses.delivery__error__helper)}>
                    {errors?.driver__password?.message}
                  </p>
                </Box>
              </Box>
            </Box>
          </ContainerForm>
          <Box
            sx={{
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
            className={clsx(DeliveryClasses.buttons__container)}
          >
            <Button
              variant="outlined"
              size="large"
              className={clsx(ButtonClasses.button_cancel_form)}
              onClick={() => navigate("/admin/delivery_driver/list_drivers")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={ButtonClasses.button_submit_form}
              type="submit"
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ marginRight: 10, color: "white" }}
                />
              )}
              Create Driver
            </Button>
          </Box>
        </form>
      </Container>
    </FormProvider>
  );
};

export default AddDriver;
