import { Theme, lighten } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStylesCustomers = makeStyles((theme: Theme) => {
  return {
    customer_view_container: {
      display: "flex",
      flexDirection: "row",
      gap: 20,
    },
    report_view_container: {
      "& .MuiBox-root": {
        padding: "0 !important",
      },
    },
    customer_tabs_container: {
      padding: "0 !important",
      width: "100%",
    },
    report_tabs_container: {
      maxWidth: "100%",
      padding: "0 !important",
    },
    swipeable_views_container: {
      width: "100%",
    },
    info_container: {
      margin: "0 0 16px",
      display: "flex",
      alignItems: "baseline",
    },
    info_label: {
      marginRight: "10px",
      minWidth: "190px",
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: "16px",
    },
    info_label_special: {
      color: theme.palette.warning.main,
    },
    info_value: {
      fontWeight: 600,
      fontSize: "16px",
      maxWidth: "54%",
    },
    profile__container: {
      // display: "flex",
      // gap: "15%",
    },
    delivery_time: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: `${lighten(
        theme.palette.secondary.main,
        0.7
      )} !important`,
      color: theme.palette.secondary.main,
      padding: "5px 10px",
      marginLeft: "10px",
    },
    customer__title: {
      color: `${theme.palette.secondary.main} !important`,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
      margin: "5px 0 14px !important",
    },
    customer__name: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
      margin: "0 !important",
    },
    customer__id_value: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 600,
      margin: "0 !important",
    },
    customer__subtitle: {
      color: `${theme.palette.text.primary} !important`,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      justifyContent: "flex-start",
      marginTop: 0,
      marginBottom: 0,
      "& > span": {
        color: `${theme.palette.success.contrastText} !important`,
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.subtitle2.fontWeight,
      },
      minWidth: 150,
    },
    customer__id: {
      color: `${theme.palette.warning.main} !important`,
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      fontWeight: theme.typography.subtitle1.fontWeight,
      margin: "0px !important",
    },
    customer__subtext: {
      margin: 0,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle2.fontWeight,
      height: "25px",
    },
    customer__unit: {
      backgroundColor: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.success.contrastText}`,
      borderRadius: theme.shape.borderRadius,
      position: "relative",
      padding: "25px",
      top: "-40px",
    },
    customer__subunit: {
      display: "flex",
      alignItems: "center",
      marginTop: 16,
    },
    customer__avatar: {
      color: `${theme.palette.warning.main} !important`,
      border: `2px solid ${theme.palette.warning.main}`,
      width: `80px !important`,
      height: `80px !important`,
      position: "absolute",
      margin: "0 auto",
      zIndex: 1,
    },
    customer__units: {
      display: "flex",
      marginBottom: "6px",
      minHeight: "37px",
      "& span": {
        transform: "translateY(-2px)",
      },
    },
    customer__menutitle: {
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      color: theme.palette.primary.main,
      margin: 0,
    },
    option_item: {
      padding: "8px 10px",
      width: "100%",
      borderRadius: "6px !important",
      "&:hover": {
        backgroundColor: `${lighten(
          theme.palette.secondary.main,
          0.7
        )} !important`,
      },
      "& .menu_title": {
        fontWeight: `${500} !important`,
      },
    },
    option_item_selected: {
      color: `${theme.palette.secondary.main} !important`,
      backgroundColor: `${lighten(
        theme.palette.secondary.main,
        0.85
      )} !important`,
    },
  };
});

export const ContainerCustomerView = styled("div")(({ theme }) => ({
  maxHeight: "calc(100vh - 193px)",
  minHeight: "calc(100vh - 193px)",
  boxSizing: "border-box",
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const FullContainer = styled("div")(({ theme }) => ({
  height: "79.2vh",
  width: "100%",
  backgroundColor: "white",
  padding: "20px 20px 25px 25px ",
  boxSizing: "border-box",
  overflowY: "auto",
}));

export const LeftContainer = styled("div")(({ theme }) => ({
  height: "79.2vh",
  width: "65%",
  backgroundColor: "white",
  padding: "20px 20px 25px 25px ",
  boxSizing: "border-box",
  overflowY: "auto",
}));

export const CustomerMenuProgramHeader = styled("div")`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const DroppableContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
  minHeight: "240px",
  width: "100%",
}));

export const NotesContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: theme.spacing(3),
  backgroundColor: "#fff",
  borderRadius: "10px",
}));
export const RightContainer = styled("div")<{ padding?: string }>(
  ({ theme, padding }) => ({
    height: "79.2vh",
    maxWidth: "35%",
    width: "35%",
    backgroundColor: "white",
    padding: `${padding || "20px 20px 25px 25px"}`,
    boxSizing: "border-box",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  })
);
export const StyledProgramInfosContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 16px;
  .program_data {
    display: flex;
    gap: 6px;
  }
  .program_date_label {
  }
  .program_date_value {
    font-weight: 500;
  }
`;

export const StyledLoaderContainer = styled("div")`
  width: 100%;
  height: 30vh;
  position: relative;
  span {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
  }
`;
