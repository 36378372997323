import { FilterBar } from "../../Utils";
import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "CUSTOMER NAME",
    accessor: "name",
  },
  {
    header: "MOBILE",
    accessor: "mobile",
  },
  {
    header: "EMAIL",
    accessor: "email",
  },
  {
    header: "Plan",
    accessor: "plan",
  },
  {
    header: "Consumed",
    accessor: "consumed",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Date of Birth",
    accessor: "date",
  },
  /* {
    header: "Custom",
    accessor: "custom",
  }, */
];
export const SIDE_FILTER_OPTIONS_BIRTHDAYS: FilterBar[] = [
  {
    id: "03",
    title: "Duration",
    data: [
      { id: "TWO_MONTHS", name: "Two Months", isSelected: false },
      { id: "THREE_MONTHS", name: "Three Months", isSelected: false },
      { id: "MONTHLY", name: "Monthly", isSelected: false },
      { id: "WEEKLY", name: "Weekly", isSelected: false },
      { id: "ONE_DAY", name: "One Day", isSelected: false },
      /* { id: "ALL_DURATIONS", name: "All", isSelected: false }, */
    ],
  },
  {
    id: "04",
    title: "Meal plan",
    data: [
      { id: "5", name: "5 meals", isSelected: false },
      { id: "4", name: "4 meals", isSelected: false },
      { id: "3", name: "3 meals", isSelected: false },
      { id: "2", name: "Business Lunch", isSelected: false },
      /* { id: "CUSTOM", name: "Custom", isSelected: false }, */
      /* { id: "ALL_PLANS", name: "All", isSelected: false }, */
    ],
  },
  {
    id: "05",
    title: "No of Days",
    data: [
      { id: "FIVE_DAYS", name: "5 days", isSelected: false },
      { id: "SIX_DAYS", name: "6 days", isSelected: false },
      { id: "SEVEN_DAYS", name: "7 days", isSelected: false },
      /* { id: "ALL_DAYS", name: "All", isSelected: false }, */
    ],
  },
];
