import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
//material ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Carousel from "react-carousel-mui";
//queries & mutations
import {
  useAddNewCustomerPlanMutation,
  useGetActiveProgramsQuery,
  LunchMeal,
  PackPayload,
} from "../../../graphql/types";
//types
import { NewMealPlanState } from "../Types";
import { ApolloError } from "@apollo/client";
import { CustomerActionsTypes } from "./Types";
//styles
import { useStylesLeads } from "../../../styles/Leads__styles";
import { useStylesContainers } from "../../../styles/Containers__styles";
import { useStylesInput } from "../../../styles/Input__styles";
import { useStylesButtons } from "../../../styles/Buttons__styles";
import { useStylesDialog } from "../../../styles/Dialog__styles";
//utils
import { handleDays, isDayOff, weekDays } from "../Utils";
import { CustomPackageMeals, getMessageError } from "../../Utils";
import { numberOfDays } from "../../dataManagement/promoCode/Utils";
//context
import { useAuth } from "../../../contextAPI";
//firebase analytics
import { logEvent } from "firebase/analytics";
//components
import PackCard from "./PackCard";

const NewMealPlant: React.FC<CustomerActionsTypes> = ({
  expanded,
  handleChange,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id } = useParams();
  let auth = useAuth();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  //styles
  const LeadsClasses = useStylesLeads();
  const ContainersClasses = useStylesContainers();
  const InputClasses = useStylesInput();
  const ButtonClasses = useStylesButtons();
  const DialogClasses = useStylesDialog();
  const ButtonsClasses = useStylesButtons();

  //state
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPack, setSelectedPack] = useState<PackPayload>();

  //react hook form
  const methodsNewMealPlan = useForm<NewMealPlanState>({
    defaultValues: {
      program: null,
      dayPerWeek: null,
      startDate: null,
      paid: false,
      numberOfMeals: null,
      duration: null,
      paymentMethod: null,
      withDelivery: true,
      meals: [],
      promoCode: "",
      customPackagesQuantities: {
        breakfast: 0,
        lunchDinner: 0,
        snack: 0,
      },
      isCustomPackage: false,
      daysOff: [],
    },
  });

  const {
    control,
    reset,
    formState: { errors },
    register,
    handleSubmit,
    setValue,
  } = methodsNewMealPlan;

  const dataWatch = useWatch({
    control,
  });

  //queries
  const { data: activeProgramsData } = useGetActiveProgramsQuery({
    fetchPolicy: "cache-first",
    skip: expanded != "NEW_MEAL_PLAN",
  });

  const activePrograms = activeProgramsData?.getActivePrograms || [];

  //mutations
  const [AddNewCustomerPlan, { loading: laodingAddNewCustomerPlan }] =
    useAddNewCustomerPlanMutation();

  //function
  const updateMealsQuantityHandler = (
    config: "INCREMENT" | "DECREMENT",
    mealType: string,
    currentQuantity: number
  ) => {
    if (config === "INCREMENT") {
      currentQuantity++;
    } else {
      currentQuantity--;
    }
    if (mealType === "Breakfast") {
      setValue("customPackagesQuantities.breakfast", currentQuantity);
    } else if (mealType === "Lunch/Dinner") {
      setValue("customPackagesQuantities.lunchDinner", currentQuantity);
    } else if (mealType === "Snack") {
      setValue("customPackagesQuantities.snack", currentQuantity);
    }
  };

  //empty the list of meals when isCustomPackage is true
  useEffect(() => {
    if (dataWatch.isCustomPackage) {
      setSelectedPack(undefined);
    }
  }, [dataWatch.isCustomPackage]);

  //setting the payment method to null whenever isPaid is checked
  useEffect(() => {
    if (dataWatch.paid) {
      setValue("paymentMethod", null);
    }
  }, [dataWatch.paid]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  //functions
  const onSubmitNewMealPlan: SubmitHandler<NewMealPlanState> = async (data) => {
    //if no pack was selected
    if (!selectedPack && !dataWatch.isCustomPackage) {
      enqueueSnackbar("Select a pack", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      return;
    }

    //restriction for create a new plan
    let startDateIsDayOff = false;
    if (data.startDate) {
      const daysOff = dataWatch.daysOff || [];
      startDateIsDayOff = isDayOff(data.startDate, daysOff);
    }
    if (startDateIsDayOff) {
      enqueueSnackbar(
        "Selected start date is a day off. Please choose another date.",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
      return;
    }
    let mealsArray: LunchMeal[] = [];
    let checkIsCustom = false;
    if (dataWatch.isCustomPackage) {
      checkIsCustom = true;
      if (data.customPackagesQuantities?.breakfast > 0) {
        mealsArray.push(
          ...[...Array(dataWatch.customPackagesQuantities?.breakfast)].fill(
            "BREAKFAST"
          )
        );
      }
      if (data.customPackagesQuantities?.lunchDinner > 0) {
        for (
          let i = 1;
          i < data.customPackagesQuantities?.lunchDinner + 1;
          i++
        ) {
          if (i % 2 === 0) {
            mealsArray.push("DINNER");
          } else {
            mealsArray.push("LUNCH");
          }
        }
      }
      if (data.customPackagesQuantities?.snack > 0) {
        for (let i = 1; i < data.customPackagesQuantities?.snack + 1; i++) {
          if (i % 2 === 0) {
            mealsArray.push("EVENING_SNACK");
          } else {
            mealsArray.push("MORNING_SNACK");
          }
        }
      }
    } else {
      mealsArray = [...(selectedPack?.meals || [])];
    }
    try {
      if (mealsArray.length < 3 && checkIsCustom) {
        enqueueSnackbar(
          "Your need to have at least 3 meals for a custom plan",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }
        );
        return;
      }
      //test whether the combination between days per week and days off is correct
      if (
        (dataWatch.dayPerWeek === "FIVE_DAYS" &&
          dataWatch?.daysOff?.length != 2) ||
        (dataWatch.dayPerWeek === "SIX_DAYS" &&
          dataWatch?.daysOff?.length != 1) ||
        (dataWatch.dayPerWeek === "SEVEN_DAYS" &&
          dataWatch?.daysOff?.length != 0)
      ) {
        enqueueSnackbar(
          "Please Verify the combination between Days per week and Days off",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }
        );
        return;
      }
      //send firebase analytics event
      logEvent(auth.analytics, "Selected_Pack", {
        packName: checkIsCustom ? "Custom package" : mealsArray.toString(),
        meals: mealsArray.toString(),
      });

      await AddNewCustomerPlan({
        variables: {
          addNewCustomerPlanInput: {
            customerId: id || "",
            isPaid: data.paid,
            nbrOfDays: data.dayPerWeek || "FIVE_DAYS",
            meals: mealsArray,
            isCustom: checkIsCustom,
            paymentMethod: data.paymentMethod || "CASH",
            paymentPeriod: data?.duration || "MONTHLY",
            programId: data.program?._id || "",
            startDate: data.startDate || new Date(),
            withDelivery: true,
            promoCode: dataWatch.promoCode,
            daysOff: data.daysOff,
          },
        },
        onCompleted: () => {
          reset();
          enqueueSnackbar("New plan succuessfully added", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setTimeout(() => closeSnackbar(), 5000);
        },
      });
    } catch (err) {
      const error = getMessageError(err as ApolloError);
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => closeSnackbar(), 5000);
    }
  };

  return (
    <Accordion
      expanded={expanded === "NEW_MEAL_PLAN"}
      onChange={handleChange("NEW_MEAL_PLAN")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="#B27D3F" fontSize={18}>
          New Meal Plan
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormProvider {...methodsNewMealPlan}>
          <form
            id="NEW_MEAL_PLAN_FORM"
            style={{ padding: "8px 16px" }}
            onSubmit={handleSubmit(onSubmitNewMealPlan)}
          >
            <Box>
              <Box display="grid" gridTemplateColumns="1fr 2fr" gap={4}>
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Meal Plan
                  </p>
                  <Controller
                    name="program"
                    rules={{
                      required: "Select Program",
                    }}
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          ref={ref}
                          id="program"
                          options={activePrograms || []}
                          value={dataWatch?.program as any}
                          getOptionLabel={(option) => option.name?.EN || ""}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          filterSelectedOptions
                          onChange={(_, data) => Change(data)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name?.EN}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Meal Plan" />
                          )}
                        />
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
              </Box>
              {!dataWatch.isCustomPackage && (
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Packs
                  </p>
                  <Carousel
                    items={
                      dataWatch.program?.packs?.filter((el) => {
                        return el.isActive;
                      }) || []
                    }
                    itemsPerPage={{
                      xs: 2,
                      sm: 2,
                      tablet: 2,
                      md: 3,
                      lg: 3,
                      xl: 3,
                    }}
                    itemRenderer={(item) => (
                      <PackCard
                        pack={item}
                        selected={selectedPack}
                        setSelectedPack={setSelectedPack as any}
                      />
                    )}
                  />
                </Box>
              )}

              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                rowGap={0}
                columnGap={4}
              >
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Days per week
                  </p>
                  <Controller
                    name="dayPerWeek"
                    rules={{
                      required: "Select number of days",
                    }}
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          ref={ref}
                          id="numberDays"
                          options={numberOfDays}
                          value={value}
                          filterSelectedOptions
                          onChange={(_, data) => Change(data)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Number of days"
                            />
                          )}
                        />
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
                <Box display="flex" flexDirection="column">
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Days off
                  </p>
                  <Controller
                    name="daysOff"
                    control={control}
                    render={({
                      field: { onChange: Change, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          {...rest}
                          multiple
                          id="daysOff"
                          value={dataWatch.daysOff || []}
                          options={weekDays}
                          getOptionLabel={(option) => handleDays(option) ?? ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputRef={ref}
                              variant="outlined"
                              sx={{ minWidth: "400px" }}
                            />
                          )}
                          onChange={(_, data) => {
                            Change(data);
                          }}
                        />
                        <p className={InputClasses.input__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
                {dataWatch.isCustomPackage ? (
                  <Box width="70%">
                    {CustomPackageMeals?.map((elem) => {
                      let quantity = 1;
                      if (elem === "Breakfast") {
                        quantity =
                          dataWatch?.customPackagesQuantities?.breakfast || 0;
                      } else if (elem === "Lunch/Dinner") {
                        quantity =
                          dataWatch?.customPackagesQuantities?.lunchDinner || 0;
                      } else if (elem === "Snack") {
                        quantity =
                          dataWatch?.customPackagesQuantities?.snack || 0;
                      }
                      return (
                        <Box
                          key={elem}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          marginTop={1}
                        >
                          <Box
                            fontFamily="Poppins"
                            fontSize={14}
                            fontWeight="500"
                            width={200}
                          >
                            {elem}
                          </Box>
                          <Box
                            height={25}
                            width={25}
                            borderRadius={12}
                            bgcolor={
                              !dataWatch.isCustomPackage ? "#C4C4C4" : "#000"
                            }
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="#fff"
                            sx={{
                              cursor: !dataWatch.isCustomPackage
                                ? "default"
                                : "pointer",
                              "&:hover": {
                                backgroundColor: "#C4C4C4",
                                transition: "0.3s",
                              },
                            }}
                            onClick={() =>
                              quantity === 0
                                ? () => null
                                : updateMealsQuantityHandler(
                                    "DECREMENT",
                                    elem,
                                    quantity
                                  )
                            }
                          >
                            -
                          </Box>
                          <Box
                            height={25}
                            width={40}
                            borderRadius={1}
                            bgcolor="#C4C4C4"
                            marginLeft={1}
                            marginRight={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {quantity}
                          </Box>
                          <Box
                            height={25}
                            width={25}
                            borderRadius={12}
                            bgcolor={
                              !dataWatch.isCustomPackage ? "#C4C4C4" : "#000"
                            }
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="#fff"
                            sx={{
                              cursor: !dataWatch.isCustomPackage
                                ? "default"
                                : "pointer",
                              "&:hover": {
                                backgroundColor: "#C4C4C4",
                                transition: "0.3s",
                              },
                            }}
                            onClick={() =>
                              updateMealsQuantityHandler(
                                "INCREMENT",
                                elem,
                                quantity
                              )
                            }
                          >
                            +
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box></Box>
                )}
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Duration
                  </p>
                  <Controller
                    name="duration"
                    rules={{
                      required: "Select Duration",
                    }}
                    control={control}
                    render={({
                      field: { onChange: Change, value, ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          ref={ref}
                          id="duration"
                          options={dataWatch.program?.paymentPeriods || []}
                          value={value}
                          filterSelectedOptions
                          onChange={(_, data) => Change(data)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                color="secondary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Duration" />
                          )}
                        />
                        <p className={ContainersClasses.section__error__helper}>
                          {error?.message}
                        </p>
                      </>
                    )}
                  />
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Start date
                  </p>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <Controller
                      name="startDate"
                      control={methodsNewMealPlan.control}
                      render={({
                        field: { ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <DesktopDatePicker
                            {...rest}
                            inputFormat="DD/MM/YYYY"
                            disablePast={false}
                            disableFuture={false}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ minWidth: "400px" }}
                              />
                            )}
                          />
                          <p
                            className={clsx(
                              ContainersClasses.section__error__helper
                            )}
                          >
                            {error?.message}
                          </p>
                        </>
                      )}
                      rules={{
                        required: {
                          message: "Field Required",
                          value: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box width="100%">
                  <Controller
                    name="isCustomPackage"
                    control={control}
                    render={({ field: { onChange, ref } }) => {
                      return (
                        <FormControlLabel
                          checked={dataWatch?.isCustomPackage}
                          onChange={(_, value) => {
                            onChange(value);
                          }}
                          value="start"
                          control={<Checkbox color="secondary" />}
                          label="Custom Package"
                          labelPlacement="start"
                          sx={{ marginLeft: 5.5, marginTop: "auto" }}
                        />
                      );
                    }}
                  />
                </Box>
                <Box width="100%">
                  <p className={ContainersClasses.section__subtitle}>
                    Promo code
                  </p>
                  <TextField
                    id="demo-helper-text-misaligned"
                    placeholder="Promo code"
                    fullWidth
                    {...register("promoCode")}
                  />
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <p className={clsx(ContainersClasses.section__subtitle)}>
                    Payment method
                  </p>
                  <Controller
                    name="paymentMethod"
                    control={control}
                    render={({ field: { ref, onChange, ...rest } }) => (
                      <Select
                        disabled={dataWatch.paid}
                        {...rest}
                        sx={{ minWidth: "400px" }}
                        className={LeadsClasses.select_container}
                        onChange={(event) => onChange(event.target.value)}
                      >
                        <MenuItem
                          className={
                            dataWatch?.paymentMethod === "DEBIT_CARD"
                              ? clsx(
                                  LeadsClasses.option_item,
                                  LeadsClasses.option_item_selected
                                )
                              : LeadsClasses.option_item
                          }
                          value="DEBIT_CARD"
                        >
                          Debit Card
                        </MenuItem>
                        <MenuItem
                          className={
                            dataWatch?.paymentMethod === "CREDIT_CARD"
                              ? clsx(
                                  LeadsClasses.option_item,
                                  LeadsClasses.option_item_selected
                                )
                              : LeadsClasses.option_item
                          }
                          value="CREDIT_CARD"
                        >
                          Credit Card
                        </MenuItem>
                        <MenuItem
                          className={
                            dataWatch?.paymentMethod === "CASH"
                              ? clsx(
                                  LeadsClasses.option_item,
                                  LeadsClasses.option_item_selected
                                )
                              : LeadsClasses.option_item
                          }
                          value="CASH"
                        >
                          Cash
                        </MenuItem>
                      </Select>
                    )}
                  />
                  <p className={clsx(ContainersClasses.section__error__helper)}>
                    {errors?.paymentMethod?.message}
                  </p>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <FormControlLabel
                    style={{ marginTop: 30 }}
                    control={
                      <Switch
                        {...register("paid")}
                        checked={dataWatch.paid}
                        color="secondary"
                      />
                    }
                    label="Is Paid"
                  />
                  <Button
                    sx={{ marginTop: 5 }}
                    variant="contained"
                    className={clsx(ButtonClasses.button_open_list_page)}
                    onClick={() => setOpen(true)}
                    disabled={laodingAddNewCustomerPlan}
                  >
                    {laodingAddNewCustomerPlan && (
                      <CircularProgress
                        size={20}
                        style={{ color: "white", marginRight: 6 }}
                      />
                    )}
                    Activate
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* CONFIRMATION MODAL */}
            <Dialog
              open={open}
              onClose={handleCloseModal}
              className={DialogClasses.dialog_container_brand}
            >
              <DialogTitle className={DialogClasses.alert_dialog_title}>
                <span className="alert_dialog_title_text">Confirm Action</span>
              </DialogTitle>
              <DialogContent className={DialogClasses.alert_dialog_content}>
                <DialogContentText>
                  Are you sure you want to create this new meal plan ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={DialogClasses.alert_dialog_actions}>
                <Button
                  className={ButtonsClasses.GreyButton}
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  className={ButtonsClasses.GreenButton}
                  type="submit"
                  form="NEW_MEAL_PLAN_FORM"
                  color="primary"
                  autoFocus
                  onClick={() => setOpen(false)}
                  disabled={laodingAddNewCustomerPlan}
                >
                  Activate
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default NewMealPlant;
