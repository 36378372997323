import React, { useMemo } from "react";
import QRCode from "react-qr-code";
//types
import { SauceReportProps } from "../Types";
const DISH_DETAILS_PAGE_URL = "https://dish.dieture.com/";

const SaucesReportPrint = React.forwardRef<HTMLDivElement, SauceReportProps>(
  ({ generateLabel }, ref) => {
    const mealData = useMemo(
      () => generateLabel?.getSaucesLabels,
      [generateLabel?.getSaucesLabels]
    );

    //render
    return (
      <div ref={ref}>
        {mealData?.map((el) => (
          <div key={el.boxeId}>
            <div
              style={{
                width: 302,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  {/* SAUCE CODE AND QR CODE */}
                  <div
                    style={{
                      fontSize: 10,
                      lineBreak: "strict",
                      fontFamily: "Poppins",
                      width: 260,
                      maxHeight: 110,
                      minHeight: 110,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 32,
                        fontFamily: "Poppins",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      {el?.code}-
                      {el.meal === "ADDON" ? "S" : el?.size?.charAt(0)}
                    </div>
                    <QRCode
                      level="L"
                      bgColor="white"
                      fgColor="black"
                      size={80}
                      value={
                        el.meal === "ADDON"
                          ? DISH_DETAILS_PAGE_URL + "S" + el?._id
                          : DISH_DETAILS_PAGE_URL +
                            el?.size?.charAt(0) +
                            "" +
                            el?._id
                      }
                    />
                  </div>

                  {/* SAUCE NAME */}
                  <div
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      lineHeight: 1,
                      minHeight: 15,
                      maxHeight: 15,
                    }}
                  >
                    {el?.name?.EN}
                  </div>
                </div>
              </div>
            </div>
            {/* LINE BREAK */}
            <div style={{ pageBreakAfter: "always" }}></div>
          </div>
        ))}
      </div>
    );
  }
);

export default SaucesReportPrint;
