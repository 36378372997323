import { useState, useMemo } from "react";
//material ui
import { Avatar, Box, Chip, IconButton, Switch } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//style
import { TableContainer } from "../../../styles/Table__styles";
import { useStylesForms } from "../../../styles/Forms__styles";
//components
import Table from "../../layout/commun/Table";
//queries&mutations
import {
  AllergenGroupPayload,
  useGetAddonsWithPaginationQuery,
} from "../../../graphql/types";
//utils
import { columns } from "../Utils";

function AddOnsList() {
  const [page, setPage] = useState<number>(1);
  const FormsClasses = useStylesForms();
  const [rowsPerPage, setRowsPerPage] = useState<number>(30);
  const dataAddOns = useGetAddonsWithPaginationQuery({
    variables: {
      input: {
        page: page,
        documentsPerPage: rowsPerPage,
      },
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.log(error);
    },
  });
  const addOnsList = useMemo(
    () => dataAddOns.data?.getAddonsWithPagination.data || [],
    [dataAddOns.data]
  );

  const count = useMemo(
    () => dataAddOns.data?.getAddonsWithPagination.count || 0,
    [dataAddOns.data]
  );
  const onPageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderAllergens = (allergens: AllergenGroupPayload[]) => {
    return allergens?.map((item, index) => {
      if (index < 4) {
        return (
          <Chip
            key={item._id}
            label={item?.name?.EN}
            variant="outlined"
            className={FormsClasses.chips__tags}
          />
        );
      }
      return null;
    });
  };

  const renderTableRows = () => {
    return addOnsList.map((item) => {
      return {
        id: item?.ref,
        photo: (
          <Avatar
            alt={item?.name?.EN?.toString()}
            src={item.pictureUrl?.toString()}
            variant="rounded"
            style={{ width: 55, height: 55 }}
          />
        ),
        name: item?.name?.EN,
        rated: item?.rating?.average,
        selected: "---",
        complains: "---",
        status: <Switch defaultChecked />,
        actions: (
          <IconButton onClick={() => null}>
            <MoreVertIcon />
          </IconButton>
        ),
        allergies: item?.allergens ? (
          <Box>{renderAllergens(item?.allergens)}</Box>
        ) : (
          "--"
        ),
      };
    });
  };
  return (
    <div>
      <TableContainer>
        <Table
          columns={columns}
          loading={dataAddOns.loading}
          numberOfFakeRow={30}
          data={renderTableRows()}
          emptyMessage="No addons found !"
          tablePaginationProps={{
            rowsPerPageOptions: [30, 60, 90],
            count: count,
            page: page - 1,
            onPageChange,
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          paginationProps={{
            page: page,
            count: Math.ceil(count / rowsPerPage),
            showFirstButton: true,
            showLastButton: true,
            boundaryCount: 1,
            siblingCount: 2,
            onChange: onPageChange,
          }}
        />
      </TableContainer>
    </div>
  );
}

export default AddOnsList;
