import { FilterBar } from "../../Utils";
import { ColumnsProps } from "../../layout/commun/Table";

export const columns: ColumnsProps = [
  {
    header: "",
    accessor: "index",
  },
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "CUSTOMER NAME",
    accessor: "name",
  },
  {
    header: "MOBILE",
    accessor: "mobile",
  },
  {
    header: "OTP",
    accessor: "otp",
  },
  {
    header: "Target Calories",
    accessor: "calories",
  },
  {
    header: "Date",
    accessor: "date",
  },
  {
    header: "LEAD STATUS",
    accessor: "status",
  },
  {
    header: "",
    accessor: "actions",
  },
];
export const SIDE_FILTER_OPTIONS_UNATTENDED_LEADS: FilterBar[] = [
  {
    id: "06",
    title: "Status",
    data: [
      { id: "PENDING", name: "Pending", isSelected: false },
      { id: "CLOSED", name: "Closed", isSelected: false },
      { id: "CONTACTED", name: "Contacted", isSelected: false },
      {
        id: "INFORMATION_REQUIRED",
        name: "Information required",
        isSelected: false,
      },
      /* { id: "ALL_STATUS", name: "All", isSelected: false }, */
    ],
  },
];
